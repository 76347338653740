export default [
    {
        id: 1,
        title: 'Replace front zip',
        translations: {
            en: 'Replace front zip',
            de: 'Vorderer Reißverschluss erneuern',
        },
        price: [55],
    },
    {
        id: 2,
        title: 'Replacing the slider',
        translations: {
            en: 'Replacing the slider',
            de: 'Reißverschluss Schlitten austauschen',
        },
        price: [12],
    },
    {
        id: 3,
        title: 'Replace pocket zip',
        translations: {
            en: 'Replace pocket zip',
            de: 'Taschenreißverschluss ersetzen',
        },
        price: [28],
    },
    {
        id: 4,
        title: 'Thread the zip slider and fixing the pocket opening',
        translations: {
            en: 'Thread the zip slider and fixing the pocket opening',
            de: 'Reißverschluss Schieber einfädeln und Tascheneingriff fixieren',
        },
        price: [15],
    },
    {
        id: 5,
        title: 'Repairing a small tears / cut / hole - max 1,5cm (patching or taping a hole)',
        translations: {
            en: 'Repairing a small tears / cut / hole - max 1,5cm (patching or taping a hole)',
            de: 'kleines Loch / Riss / Schnitt reparieren - max 1,5cm (Loch patchen oder tapen) ',
        },
        price: [14],
    },
    {
        id: 6,
        title: 'construction not waterproof: Repairing medium tears / cut / hole 1,5cm - 5cm (patching or taping)',
        translations: {
            en: 'construction not waterproof: Repairing medium tears / cut / hole 1,5cm - 5cm (patching or taping)',
            de: 'Konstruktion nicht wasserdicht: mittleres Loch / Riss / Schnitt reparieren > 1,5cm - 5cm (leichter Nähprozess / +Ersatzmaterial)',
        },
        price: [23],
    },
    {
        id: 7,
        title: '1 layer - material - construction not waterproof: repair medium hole / tear / cut > 1.5 - 5cm cm (with original fabric that could be removed from the internal part and replaced with a replacement material',
        translations: {
            en: '1 layer - material - construction not waterproof: repair medium hole / tear / cut > 1.5 - 5cm cm (with original fabric that could be removed from the internal part and replaced with a replacement material',
            de: '1 Lage - Material - Konstruktion nicht wasserdicht: mittleres Loch / Riss / Schnitt > 1.5 - 5cm (mit originalem Material, das aus dem Innenteil entfernt werden kann und durch ein Ersatzmaterial ersetzt werden kann)',
        },
        price: [46],
    },
    {
        id: 8,
        title: 'Repairing big tears / cut / hole  - from 10cm (complex patching or taping process)',
        translations: {
            en: 'Repairing big tears / cut / hole  - from 10cm (complex patching or taping process)',
            de: 'großes Loch / Riss / Schnitt reparieren - ab 10cm (aufwändiger Nähprozess)',
        },
        price: [42],
    },
    {
        id: 9,
        title: 'Replace buttons/press studs/eyelets/hooks',
        translations: {
            en: 'Replace buttons/press studs/eyelets/hooks',
            de: 'Knopf / Druckknopf / Öse / Haken erneuern',
        },
        price: [9],
    },
    {
        id: 10,
        title: 'Replace short Velcro fastener (under 15cm length)',
        translations: {
            en: 'Replace short Velcro fastener (under 15cm length)',
            de: 'kurzer Klettverschluss ersetzen (unter 15cm Länge)',
        },
        price: [12],
    },
    {
        id: 11,
        title: 'Replace long Velcro fastener (from 15cm length)',
        translations: {
            en: 'Replace long Velcro fastener (from 15cm length)',
            de: 'langer Klettverschluss ersetzen (ab 15cm Länge)',
        },
        price: [17],
    },
    {
        id: 12,
        title: 'Sew and repair various seams (1 - 30cm seam length)',
        translations: {
            en: 'Sew and repair various seams (1 - 30cm seam length)',
            de: 'diverse Nähte nachnähen und reparieren (1- 30cm)',
        },
        price: [11],
    },
    {
        id: 13,
        title: 'Replace cord, elastic, elastic - simple (e.g. jacket hem, hood or both sleeve hems)',
        translations: {
            en: 'Replace cord, elastic, elastic - simple (e.g. jacket hem, hood or both sleeve hems)',
            de: 'Kordel, Gummizug ersetzen (z.B. Jackensaum, Kapuze oder beide Ärmelsäume)',
        },
        price: [33],
    },
    {
        id: 14,
        title: 'Replace Venting zip (jackets with inner lining f.i. down- padded Jackets (elaborate processing)',
        translations: {
            en: 'Replace Venting zip (jackets with inner lining f.i. down- padded Jackets (elaborate processing)',
            de: 'Ventilation Reißverschluss austauschen von gefütterter Jacke (aufwendig und schwierig zum auftrennen, z.B. Daunenjacke, Wattierte Jacke)',
        },
        price: [45],
    },
]
