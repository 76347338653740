import { RepairGuideType } from './types'

export default [
    {
        id: 56143,
        url: 'https://inspector.brakeable.com/ufixit/56143',
        title: 'How do I replace the wheels on my Tobago trolley?',
        image: 'https://guide-images.cdn.ifixit.com/igi/PNJhwXsKMWGn5aYA.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie man die Räder auf meinem Tobago-Trolley ersetzt',
            en: 'How do I replace the wheels on my Tobago trolley?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 56151,
        url: 'https://inspector.brakeable.com/ufixit/56151',
        title: 'How do I replace the wheels on my Melbourne trolley?',
        image: 'https://guide-images.cdn.ifixit.com/igi/32qfanSN2GByWGBO.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie man die Räder auf meinem Melbourne-Trolley ersetzt',
            en: 'How do I replace the wheels on my Melbourne trolley?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 67698,
        url: 'https://inspector.brakeable.com/ufixit/67698',
        title: 'How do I remove pilling from my Vaude pullover?',
        image: 'https://guide-images.cdn.ifixit.com/igi/vUIWxPQhbfVDXOn2.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie entferne ich Pilling an meinem Vaude Pullover?',
            en: 'How do I remove pilling from my Vaude pullover?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 67699,
        url: 'https://inspector.brakeable.com/ufixit/67699',
        title: 'How do I replace the thread plate on my bike bag?',
        image: 'https://guide-images.cdn.ifixit.com/igi/pVQROYy4ksBaGXnC.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie man die Nadelplatte auf meinem Fahrrad-Beutel ersetzt',
            en: 'How do I replace the thread plate on my bike bag?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69098,
        url: 'https://inspector.brakeable.com/ufixit/69098',
        title: 'How do I replace the Klickfix mounting plate on my Aqua Box?',
        image: 'https://guide-images.cdn.ifixit.com/igi/2ECAFTUWmMRByULW.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie man die Klickfix-Montageplatte auf meinem Aqua Box ersetzt',
            en: 'How do I replace the Klickfix mounting plate on my Aqua Box?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69099,
        url: 'https://inspector.brakeable.com/ufixit/69099',
        title: 'How do I repair taped seams on my Vaude jacket?',
        image: 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie fixiere ich ein abgelöstes Tape an meiner Vaude Jacke?',
            en: 'How do I repair taped seams on my Vaude jacket?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69100,
        url: 'https://inspector.brakeable.com/ufixit/69100',
        title: 'How can I seal the seam stitching on my ultralight tent?',
        image: 'https://guide-images.cdn.ifixit.com/igi/kaZJqFMROV2nRoqI.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie man Litzen auf meiner ultralighten Tent repariert',
            en: 'How can I seal the seam stitching on my ultralight tent?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69101,
        url: 'https://inspector.brakeable.com/ufixit/69101',
        title: 'How do I replace a cord stopper on my Vaude jacket drawstring?',
        image: 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie ersetze ich einen Kordelstopper am Gummizug meiner Vaude Jacke?',
            en: 'How do I replace a cord stopper on my Vaude jacket drawstring?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69102,
        url: 'https://inspector.brakeable.com/ufixit/69102',
        title: 'How do I replace a broken zip slider on my Vaude clothing?',
        image: 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie ersetze ich einen defekten Reißverschluss-Schieber an meiner Vaude Bekleidung?',
            en: 'How do I replace a broken zip slider on my Vaude clothing?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69103,
        url: 'https://inspector.brakeable.com/ufixit/69103',
        title: 'How to replace the extension handle on my trolley?',
        image: 'https://guide-images.cdn.ifixit.com/igi/bBTLYeSZWyjfQVZP.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie man Litzen auf meiner Vaude-Jacke repariert',
            en: 'How to replace the extension handle on my trolley?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69105,
        url: 'https://inspector.brakeable.com/ufixit/69105',
        title: 'How do I lubricate a sticky zip on my Vaude clothing?',
        image: 'https://guide-images.cdn.ifixit.com/igi/6draOBRknNP2fuNG.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie schmiere ich einen schwergängigen Reißverschluss an meiner Vaude Bekleidung?',
            en: 'How do I lubricate a sticky zip on my Vaude clothing?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69108,
        url: 'https://inspector.brakeable.com/ufixit/69108',
        title: 'How do I replace a QMR Rail Hook on the hardback panel of my bike bag?',
        image: 'https://guide-images.cdn.ifixit.com/igi/cB2OHa3C4WYZuHAO.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie man Litzen auf meiner Vaude-Jacke repariert',
            en: 'How do I replace a QMR Rail Hook on the hardback panel of my bike bag?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69111,
        url: 'https://inspector.brakeable.com/ufixit/69111',
        title: 'How do I replace a broken buckle on my backpack?',
        image: 'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie man Litzen auf meiner Vaude-Jacke repariert',
            en: 'How do I replace a broken buckle on my backpack?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69115,
        url: 'https://inspector.brakeable.com/ufixit/69115',
        title: 'How do I replace a broken zip on my Vaude jacket?',
        image: 'https://guide-images.cdn.ifixit.com/igi/HnFokidbJhDKAVMV.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie repariere ich einen defekten Frontreißverschluss an meiner Vaude Jacke?',
            en: 'How do I replace a broken zip on my Vaude jacket?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69120,
        url: 'https://inspector.brakeable.com/ufixit/69120',
        title: 'How do I replace a sternum strap on my backpack?',
        image: 'https://guide-images.cdn.ifixit.com/igi/3CWd5ZwgV4rkrfyM.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie man Litzen auf meiner Vaude-Jacke repariert',
            en: 'How do I replace a sternum strap on my backpack?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69122,
        url: 'https://inspector.brakeable.com/ufixit/69122',
        title: 'How do I replace a broken segment of my tent pole?',
        image: 'https://guide-images.cdn.ifixit.com/igi/M5IFySdZ2xSKqtrU.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie man Litzen auf meiner Vaude-Jacke repariert',
            en: 'How do I replace a broken segment of my tent pole?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69137,
        url: 'https://inspector.brakeable.com/ufixit/69137',
        title: 'How do I repair a two-way zip slider on my Vaude jacket on a split zip?',
        image: 'https://guide-images.cdn.ifixit.com/igi/YUnbHtRArnHNQ2KY.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie repariere ich einen Zwei-Wege-Reißverschluss meiner Vaude Jacke bei einer falsch verzahnten Spirale?',
            en: 'How do I repair a two-way zip slider on my Vaude jacket on a split zip?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69138,
        url: 'https://inspector.brakeable.com/ufixit/69138',
        title: 'How can I resew a loose Velcro on a Vaude jacket?',
        image: 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie kann ich einen losen Klett an einer Vaude Jacke wieder annähen?',
            en: 'How can I resew a loose Velcro on a Vaude jacket?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69140,
        url: 'https://inspector.brakeable.com/ufixit/69140',
        title: 'When do I need to re-waterproof my Vaude garment?',
        image: 'https://guide-images.cdn.ifixit.com/igi/WyUIZt1dlCJ1QqAO.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wann muss ich mein Vaude Kleidungsstück neu imprägnieren?',
            en: 'When do I need to re-waterproof my Vaude garment?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69141,
        url: 'https://inspector.brakeable.com/ufixit/69141',
        title: 'What’s the right way to wash and dry my Vaude down jacket?',
        image: 'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie wasche und trockne ich meine Vaude Daunenjacke richtig?',
            en: 'What’s the right way to wash and dry my Vaude down jacket?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69144,
        url: 'https://inspector.brakeable.com/ufixit/69144',
        title: 'How do I wash my Vaude wool product?',
        image: 'https://guide-images.cdn.ifixit.com/igi/XMU1l5kdrDjQuBbK.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie wasche ich mein Vaude Produkt aus Wolle?',
            en: 'How do I wash my Vaude wool product?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69150,
        url: 'https://inspector.brakeable.com/ufixit/69150',
        title: 'How do I wash and dry my Vaude membrane jacket or trousers?',
        image: 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie wasche und trockne ich meine Vaude Membran-Jacke/-Hose?',
            en: 'How do I wash and dry my Vaude membrane jacket or trousers?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69155,
        url: 'https://inspector.brakeable.com/ufixit/69155',
        title: 'What’s the best way to wash and dry my Vaude softshell jacket?',
        image: 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie wasche und trockne ich meine Vaude Softshell-Jacke am besten?',
            en: 'What’s the best way to wash and dry my Vaude softshell jacket?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69157,
        url: 'https://inspector.brakeable.com/ufixit/69157',
        title: 'How do I wash and dry my Vaude down sleeping bag?',
        image: 'https://guide-images.cdn.ifixit.com/igi/qYkLfUqRuQ6srFhu.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie wasche und trockne ich meinen Vaude Daunenschlafsack?',
            en: 'How do I wash and dry my Vaude down sleeping bag?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 69208,
        url: 'https://inspector.brakeable.com/ufixit/69208',
        title: 'How do I sew a button onto my Vaude shirt?',
        image: 'https://guide-images.cdn.ifixit.com/igi/cKDLpcLLvBqaXrIu.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie nähe ich einen Knopf an mein Vaude Oberteil an?',
            en: 'How do I sew a button onto my Vaude shirt?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 71477,
        url: 'https://inspector.brakeable.com/ufixit/71477',
        title: 'How do I replace a sternum strap with a hydration tube holder on my backpack?',
        image: 'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie ersetze ich einen Kordelstopper am Gummizug meiner Vaude Jacke?',
            en: 'How do I replace a sternum strap with a hydration tube holder on my backpack?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 74498,
        url: 'https://inspector.brakeable.com/ufixit/74498',
        title: 'How do I waterproof my tent?',
        image: 'https://guide-images.cdn.ifixit.com/igi/sWaVfUhBQ1uQPNvq.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie mache ich mein Zelt wasserdicht?',
            en: 'How do I waterproof my tent?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 74517,
        url: 'https://inspector.brakeable.com/ufixit/74517',
        title: 'How do I attach a new snap?',
        image: 'https://guide-images.cdn.ifixit.com/igi/rTyQjDhPMJQmaMRE.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie bringe ich einen Vaude Druckknopf an?',
            en: 'How do I attach a new snap?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 74524,
        url: 'https://inspector.brakeable.com/ufixit/74524',
        title: 'How do I replace a brittle or broken shock cord?',
        image: 'https://guide-images.cdn.ifixit.com/igi/p1Bk3iaKf4JCpBGW.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie ersetze ich ein sprödes oder defektes Shockcord?',
            en: 'How do I replace a brittle or broken shock cord?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 74532,
        url: 'https://inspector.brakeable.com/ufixit/74532',
        title: 'How do I patch a garment?',
        image: 'https://guide-images.cdn.ifixit.com/igi/nshdoEAeS5MjKUHh.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie flicke ich ein Vaude Bekleidungsstück?',
            en: 'How do I patch a garment?',
        },
        type: RepairGuideType.TEXT,
    },
    {
        id: 74786,
        url: 'https://inspector.brakeable.com/ufixit/74786',
        title: 'How do I stuff a hole on my Vaude knit sweater?',
        image: 'https://guide-images.cdn.ifixit.com/igi/VUcNXXVZHwEWUBNs.full',
        brandId: '3vk3eo',
        translations: {
            de: 'Wie stopfe ich ein Loch an meinem Vaude Strickpullover?',
            en: 'How do I stuff a hole on my Vaude knit sweater?',
        },
        type: RepairGuideType.TEXT,
    },
]
