export default [
    {
        id: 1,
        title: 'Reparing a Slowpuncture',
        price: [40],
        translations: {
            en: 'Reparing a Slowpuncture',
            de: 'Reparieren eines langsamen Luftverlustes',
        },
    },
    {
        id: 2,
        title: 'Reparing a Leading edge blow out',
        price: [70],
        translations: {
            en: 'Reparing a Leading edge blow out',
            de: 'Reparieren eines Lochs in der Leading Edge',
        },
    },
    {
        id: 3,
        title: 'Reparing a small Tear/Hole in the canopy',
        price: [70],
        translations: {
            en: 'Reparing a small Tear/Hole in the canopy',
            de: 'Reparieren eines kleinen Risses im Segel',
        },
    },
    {
        id: 4,
        title: 'Bridle repair / replacement',
        price: [15],
        translations: {
            en: 'Bridle repair / replacement',
            de: 'Reparieren der Bridles',
        },
    },
    {
        id: 5,
        title: 'Repair / Replace lines',
        price: [15],
        translations: {
            en: 'Repair / Replace lines',
            de: 'Reparieren / Austauschen der Leinen',
        },
    },
    {
        id: 6,
        title: 'Change depower rope / safety line',
        price: [50],
        translations: {
            en: 'Change depower rope / safety line',
            de: 'Austauschen der Depower-Leine / Sicherheitsleine',
        },
    },
    {
        id: 7,
        title: 'Locating and repairing a slow leak in a Strut bladder',
        price: [50],
        translations: {
            en: 'Locating and repairing a slow leak in a Strut bladder',
            de: 'Lokalisierung und Reparatur eines langsamen Lecks in einer Strut-Blase',
        },
    },
    {
        id: 8,
        title: 'Replacing Strut bladders that are beyond repair',
        price: [60],
        translations: {
            en: 'Replacing Strut bladders that are beyond repair',
            de: 'Austauschen von Strut-Blasen, die nicht mehr zu reparieren sind',
        },
    },
    {
        id: 9,
        title: 'Reparing a big Tear/Hole in the canopy',
        price: [120],
        translations: {
            en: 'Reparing a big Tear/Hole in the canopy',
            de: 'Reparieren eines großen Risses im Segel',
        },
    },
]
