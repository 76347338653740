import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useForm } from 'react-hook-form'
import { useIsMobile } from '../../../../mixins/media-query'
import T from '../../../../components/typography/t'
import { useTranslation } from 'react-i18next'

type FormData = {
    email: string
    name: string
    lang: string
}

const NewEmployeeForm: React.FC<{ onSubmit: (formData: FormData) => void }> = ({ onSubmit }) => {
    const { t } = useTranslation()
    const isMobile = useIsMobile()
    const { register, handleSubmit, reset } = useForm<FormData>({
        defaultValues: {
            name: '',
            email: '',
            lang: 'de',
        },
    })

    const submit = (formData: FormData) => {
        onSubmit(formData)
        reset()
    }

    return (
        <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
            <TextField
                fullWidth
                {...register('name')}
                label={t('company.companySettings.account.newEmployeeForm.name' as any)}
                size="small"
            />
            <TextField
                fullWidth
                {...register('email')}
                label={t('company.companySettings.account.newEmployeeForm.email' as any)}
                size="small"
            />
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="language-swticher-label">
                    <T i18nKey="common.language" />
                </InputLabel>
                <Select
                    labelId="language-swticher-label"
                    id="language-swticher-select"
                    defaultValue="de"
                    {...register('lang')}
                    label={t('common.language' as any)}
                    autoWidth
                >
                    <MenuItem value="de" defaultChecked>
                        Deutsch
                    </MenuItem>
                    <MenuItem value="en">English</MenuItem>
                </Select>
            </FormControl>
            <Button
                variant="contained"
                onClick={handleSubmit(submit)}
                sx={{ flexShrink: 0 }}
                size="small"
            >
                <T i18nKey="company.companySettings.account.newEmployeeForm.button" />
            </Button>
        </Stack>
    )
}

export default NewEmployeeForm
