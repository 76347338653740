export const isLongEnough = (password: string) => password.length >= 8
export const hasLowerCase = (password: string) => /[a-z]/.test(password)
export const hasUpperCase = (password: string) => /[A-Z]/.test(password)
export const hasNumber = (password: string) => /[0-9]/.test(password)
export const hasSpecialCharacter = (password: string) => /[^a-zA-Z0-9]/.test(password)

export const validateSecurePassword = (password: string) => {
    if (!isLongEnough(password)) {
        return 'Password must be at least 8 characters long'
    }
    if (!hasLowerCase(password)) {
        return 'Password must contain at least one lowercase letter'
    }
    if (!hasUpperCase(password)) {
        return 'Password must contain at least one uppercase letter'
    }
    if (!hasNumber(password)) {
        return 'Password must contain at least one number'
    }
    if (!hasSpecialCharacter(password)) {
        return 'Password must contain at least one special character'
    }
    return null
}

export default validateSecurePassword
