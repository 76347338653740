import React from 'react'
import { InspectorMultiStepFormContext } from '../inspector-multi-step-form'
import T from '../../../../components/typography/t'

const Outro = () => {
    const { ctx } = React.useContext(InspectorMultiStepFormContext)

    React.useEffect(() => {
        setTimeout(() => {
            ctx.postInspectionAction()
        }, 1000)
    }, [])

    return (
        <T
            i18nKey="inspector.mobile.outro.title"
            variant="h4"
            component="h1"
            textAlign="center"
            alignSelf="center"
            color={'primary'}
            my={8}
        />
    )
}

export default Outro
