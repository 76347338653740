import { ErrorBoundary, useRollbarPerson } from '@rollbar/react'
import useAuth from '../mixins/use-auth'

const RollbarWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { user } = useAuth()
    useRollbarPerson(user ?? {})
    return <ErrorBoundary>{children}</ErrorBoundary>
}

export default RollbarWrapper
