import Cookie from 'js-cookie'

const useCookie = (
    name: string
): [() => string | undefined, (value: string, options?: Cookies.CookieAttributes) => string] => {
    const getCookie = () => {
        return Cookie.get(name)
    }

    const setCookie = (value: string, options?: Cookies.CookieAttributes) => {
        Cookie.set(name, value, options)
        return value
    }

    return [getCookie, setCookie]
}

export default useCookie
