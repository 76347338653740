import styled from 'styled-components'
import theme from '../../../../../theme'

const StepContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: calc(100% - 60px);
    padding: 32px 16px;

    ${theme.breakpoints.down('sm')} {
        height: calc(100% - 80px);
        padding: 20px 10px;
    }
`

export default StepContainer
