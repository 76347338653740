import React from 'react'
import { SupportMultiStepFormContext, StepProps } from '../../context'
import T, { I18nKey } from '../../../../../../components/typography/t'
import { useIsDesktop } from '../../../../../../mixins/media-query'
import { styled, Stack, Button } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

export type MultiStepNavigationProps = {
    prevButtonDisabled: boolean
    prevButtonLabelKey: I18nKey
    nextButtonDisabled: boolean
    nextButtonLabelKey: I18nKey
    onValidPrevClick?: () => void
    onValidNextClick?: () => void
    isWhiteLabelled: boolean
    hideNextButton?: boolean
}

const noop = () => {}

export const DumbMultiStepNavigation = ({
    prevButtonDisabled,
    prevButtonLabelKey,
    nextButtonDisabled,
    nextButtonLabelKey,
    hideNextButton,
    onValidPrevClick,
    onValidNextClick,
}: StepProps & MultiStepNavigationProps) => {
    const isDesktop = useIsDesktop()
    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                component="nav"
                mb={1}
                width="100%"
            >
                <Button
                    variant="outlined"
                    disabled={prevButtonDisabled}
                    onClick={prevButtonDisabled ? noop : onValidPrevClick}
                >
                    <ArrowBackIcon />
                    {isDesktop && <T i18nKey={prevButtonLabelKey} ml={1} variant="body2" />}
                </Button>
                <Button
                    variant={nextButtonDisabled ? 'outlined' : 'contained'}
                    sx={{ opacity: hideNextButton ? 0 : 1 }}
                    disabled={nextButtonDisabled}
                    onClick={nextButtonDisabled ? noop : onValidNextClick}
                >
                    {isDesktop && <T i18nKey={nextButtonLabelKey} mr={1} variant="body2" />}
                    <ArrowForwardIcon />
                </Button>
            </Stack>
        </>
    )
}

const MultiStepNavigation = ({
    prevButtonDisabled = false,
    prevButtonLabelKey = 'common.back',
    nextButtonDisabled = false,
    nextButtonLabelKey = undefined,
    onValidNextClick = undefined,
    onValidPrevClick = undefined,
    hideNextButton: hideNextButton = false,
}: Partial<MultiStepNavigationProps>) => {
    const { props, ctx } = React.useContext(SupportMultiStepFormContext)

    const nextBtnLabelKey = nextButtonLabelKey
        ? nextButtonLabelKey
        : props.currentStep >= props.maxSteps - 2 // minus 1 bc of 0 index and minus 1 bc of intro slide
        ? 'common.submit'
        : 'common.next'

    return (
        <DumbMultiStepNavigation
            {...props}
            isWhiteLabelled={ctx.isWhiteLabelled}
            prevButtonDisabled={prevButtonDisabled}
            prevButtonLabelKey={prevButtonLabelKey}
            nextButtonDisabled={nextButtonDisabled}
            nextButtonLabelKey={nextBtnLabelKey}
            onValidPrevClick={onValidPrevClick ?? props.prevSlide}
            onValidNextClick={onValidNextClick ?? props.nextSlide}
            hideNextButton={hideNextButton}
        />
    )
}

export default MultiStepNavigation
