import React from 'react'
import Button from '@mui/material/Button'
import { InspectorMultiStepFormContext } from '../../inspector-multi-step-form'
import StyledErrorAlert from '../../../../../components/alert/styled-error-alert'

export const ErrorStack = () => {
    const { error } = React.useContext(InspectorMultiStepFormContext)
    return (
        <>
            {error.errors.map((e, index) => (
                <StyledErrorAlert
                    severity="error"
                    key={index}
                    sx={{ width: '100%' }}
                    action={
                        e.action && (
                            <Button color="inherit" size="small" onClick={e.action.onClick}>
                                {e.action.label}
                            </Button>
                        )
                    }
                >
                    {e.message}
                </StyledErrorAlert>
            ))}
        </>
    )
}
