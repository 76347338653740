export enum RepairGuideType {
    VIDEO = 'video',
    TEXT = 'text',
}

export type RepairGuide = {
    id: number
    url: string
    title: string
    image: string
    brandId: string
    type: RepairGuideType
    translations?: {
        [key: string]: string
    }
}
