export default [
    {
        id: 1,
        title: 'Large Blade Replacement',
        price: [40],
        translations: {
            en: 'Large Blade Replacement',
            de: 'Austauschen der großen Klinge',
        },
    },
    {
        id: 2,
        title: 'Sharpening large blades (low wear)',
        price: [15],
        translations: {
            en: 'Sharpening large blades (low wear)',
            de: 'Große Klinge schärfen (geringer Verschleiss)',
        },
    },
    {
        id: 3,
        title: 'Sharpening large blades (heavy wear)',
        price: [25],
        translations: {
            en: 'Sharpening large blades (heavy wear)',
            de: 'Große Klinge schärfen (hoher Verschleiss)',
        },
    },
    {
        id: 4,
        title: 'Sharpening small blades (low wear)',
        price: [12],
        translations: {
            en: 'Sharpening small blades (low wear)',
            de: 'Kleine Klinge schärfen (geringer Verschleiss)',
        },
    },
    {
        id: 5,
        title: 'Sharpening small blades (heavy wear)',
        price: [20],
        translations: {
            en: 'Sharpening small blades (heavy wear)',
            de: 'Kleine Klinge schärfen (hoher Verschleiss)',
        },
    },
    {
        id: 7,
        title: 'Small Blade Replacement',
        price: [30],
        translations: {
            en: 'Small Blade Replacement',
            de: 'Austauschen der kleinen Klinge',
        },
    },
    {
        id: 8,
        title: 'Scissors Replacement',
        price: [45],
        translations: {
            en: 'Scissors Replacement',
            de: 'Austauschen der Schere',
        },
    },
    {
        id: 9,
        title: 'Scissors Sharpening',
        price: [17],
        translations: {
            en: 'Scissors Sharpening',
            de: 'Schere schärfen',
        },
    },
    {
        id: 10,
        title: 'Corkscrew Replacement',
        price: [15],
        translations: {
            en: 'Corkscrew Replacement',
            de: 'Austauschen des Korkenziehers',
        },
    },
    {
        id: 11,
        title: 'Case Replacement',
        price: [15],
        translations: {
            en: 'Case Replacement',
            de: 'Austauschen der Schale',
        },
    },
]
