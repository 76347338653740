import ImageListItem from '@mui/material/ImageListItem'
import NewBadge from '../../../components/badge/new-badge'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import { Label } from '../../../../shared/types/submission/label'
import React from 'react'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import RepairCountIcon from '@mui/icons-material/HandymanOutlined'
import FeedbackCountIcon from '@mui/icons-material/FeedbackOutlined'
import { Box } from '@mui/material'
import InspectionOrigin from '../../../../shared/inspection/inspection-origin'

const InspectionImageTile = React.forwardRef<
    HTMLLIElement,
    {
        imageUrl: string
        isUnseenByUser: boolean
        labels: Label[]
        isFadedOut?: boolean
        onClick?: () => void
        onMouseEnter?: () => void
        onMouseLeave?: () => void
        itemBar?: React.ReactNode
        inspectionOrigin: InspectionOrigin
    }
>(
    (
        {
            imageUrl,
            isUnseenByUser,
            labels = [],
            isFadedOut = false,
            onClick,
            onMouseLeave,
            onMouseEnter,
            itemBar,
            inspectionOrigin,
        },
        ref
    ) => {
        const OriginIcon =
            inspectionOrigin === InspectionOrigin.Feedback ? FeedbackCountIcon : RepairCountIcon

        return (
            <ImageListItem
                ref={ref}
                onClick={onClick}
                sx={{
                    cursor: 'pointer',
                    overflow: 'hidden',
                    position: 'relative',
                    opacity: isFadedOut ? 0.25 : 1,
                    transition: 'opacity 0.2s',
                }}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <NewBadge show={isUnseenByUser} />
                <Box
                    sx={{
                        position: 'absolute',
                        top: 4,
                        right: 4,
                        background: 'rgba(255, 255, 255, 0.5)',
                        borderRadius: '100px',
                        padding: 0.5,
                    }}
                >
                    <OriginIcon />
                </Box>
                <img
                    src={imageUrl}
                    style={{
                        height: '100%',
                        transition: 'transform 0.2s',
                        transformOrigin: 'center',
                    }}
                />
                <Stack
                    sx={{ p: 1, position: 'absolute', bottom: !!itemBar ? 44 : 0 }}
                    spacing={1}
                    direction="row"
                    useFlexGap
                    flexWrap="wrap"
                >
                    {labels.map((label) => (
                        <Chip label={label.value} key={label.id} color="primary" size="small" />
                    ))}
                </Stack>
                {!!itemBar && <ImageListItemBar title={itemBar} />}
            </ImageListItem>
        )
    }
)

export default InspectionImageTile
