import React from 'react'
import { useParams } from 'react-router-dom'
import whitelabels from '../../../../shared/data/whitelabel'
import AlterationsMultiStep from './alterations-multi-step'
import uploadcareClient from '../../../mixins/uploadcare-client'
import { ThemeProvider, createTheme } from '@mui/material'
import mergeObjects from '../../../mixins/merge-object'
import { useAnalytics } from 'use-analytics'
import { AlterationsRequestPageViewEvent } from '../../../../shared/analytics'
import { useIsMobile } from '../../../mixins/media-query'
import parseQueryString from '../../../mixins/parse-query-string'
import { FormValues, WhitelabelWithAlterationsConfig } from './context'

const AlterationsModule = () => {
    const { company } = useParams()
    const urlParams = company?.split('-')
    const companyId = urlParams?.[urlParams?.length - 1]
    const utmData = parseQueryString()
    const analytics = useAnalytics()
    const isMobile = useIsMobile()

    const whitelabel = whitelabels.find((w) => w.id === companyId)

    if (!companyId || !whitelabel || !whitelabel.config.alterations) {
        window.location.href = '//www.brakeable.com'
        return null
    }

    React.useEffect(() => {
        analytics.track(AlterationsRequestPageViewEvent, { isMobile, brandId: companyId, utmData })
    }, [])

    const handleSubmit = async (formValues: FormValues) => {
        console.log(formValues)
    }

    return (
        <ThemeProvider
            theme={(theme: any) =>
                createTheme({
                    ...theme,
                    palette: {
                        ...theme.palette,
                        ...whitelabel?.theme.palette,
                    },
                    components: mergeObjects(theme.components, whitelabel?.theme.components),
                } as any)
            }
        >
            <AlterationsMultiStep
                uploadCareClient={uploadcareClient}
                whitelabel={whitelabel as WhitelabelWithAlterationsConfig}
                onSubmit={handleSubmit}
            />
        </ThemeProvider>
    )
}

export default AlterationsModule
