import React from 'react'
import useDialog from '../../../../mixins/use-dialog'
import Backdrop from '@mui/material/Backdrop'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import ShareIcon from '@mui/icons-material/Share'
import PrintIcon from '@mui/icons-material/Print'
import SupportIcon from '@mui/icons-material/Construction'
import CopyIcon from '@mui/icons-material/ContentCopy'
import ExportCsvIcon from '@mui/icons-material/FileDownload'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import useAuth from '../../../../mixins/use-auth'
import { useTranslation } from 'react-i18next'
import useCopyToClipboard from '../../../../mixins/use-copy-to-clipboard'

const ShareSpeedDial: React.FC<{ companyOverviewLink: string; companySupportLink: string }> = ({
    companyOverviewLink,
    companySupportLink,
}) => {
    const copyToClipboard = useCopyToClipboard()
    const { isAdmin } = useAuth()
    const { t } = useTranslation()
    const [textJustCopied, setTextJustCopied] = React.useState({ col: false, crl: false })
    const { isOpen, open, close } = useDialog()

    const actions = [
        {
            key: 'copy company overview link',
            Icon: CopyIcon,
            tooltipTitle: textJustCopied.col
                ? t('company.inspector.home.share.companyLinkCopied' as any)
                : t('company.inspector.home.share.copyCompanyLink' as any),
            onClick: () =>
                copyToClipboard(companyOverviewLink).then(() => {
                    setTextJustCopied({ ...textJustCopied, col: true })
                    setTimeout(() => setTextJustCopied((s) => ({ ...s, col: false })), 1000)
                }),
        },
        {
            key: 'copy company support link',
            Icon: SupportIcon,
            tooltipTitle: textJustCopied.crl
                ? t('company.inspector.home.share.companyLinkCopied' as any)
                : t('company.inspector.home.share.copyCompanySupportLink' as any),
            onClick: () =>
                copyToClipboard(companySupportLink).then(() => {
                    setTextJustCopied({ ...textJustCopied, crl: true })
                    setTimeout(() => setTextJustCopied((s) => ({ ...s, crl: false })), 1000)
                }),
        },
    ]

    if (isAdmin) {
        actions.push.apply(actions, [
            {
                key: 'order company card',
                Icon: LocalShippingIcon,
                tooltipTitle: t('company.inspector.home.share.orderCompanyCard' as any),
                onClick: async () => {},
            },
            {
                key: 'print product card',
                Icon: PrintIcon,
                tooltipTitle: t('company.inspector.home.share.printProductCards' as any),
                onClick: async () => {},
            },
            {
                key: 'export product list',
                Icon: ExportCsvIcon,
                tooltipTitle: t('company.inspector.home.share.exportProductList' as any),
                onClick: async () => {},
            },
        ])
    }

    return (
        <>
            <Backdrop open={isOpen} sx={{ zIndex: 2 }} />
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                icon={<ShareIcon fontSize="small" />}
                direction="up"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                onOpen={open}
                onClose={close}
            >
                {actions.map(({ key, Icon, tooltipTitle, onClick }) => (
                    <SpeedDialAction
                        key={key}
                        icon={<Icon />}
                        sx={{ textWrap: 'nowrap', whiteSpace: 'nowrap' }}
                        tooltipTitle={tooltipTitle}
                        onClick={onClick}
                        tooltipOpen
                    />
                ))}
            </SpeedDial>
        </>
    )
}

export default ShareSpeedDial
