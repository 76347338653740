import React from 'react'
import './css-devices.min.css'

const DevicePreview = (props: React.PropsWithChildren<{ scale?: number }>) => {
    const scale = props.scale ?? 1
    return (
        <div
            className="marvel-device iphone-x"
            style={{
                width: 375 * scale,
                height: 812 * scale,
                borderRadius: 66 * scale,
                padding: 26 * scale,
            }}
        >
            <div
                className="iphone-x-before"
                style={{
                    width: `calc(100% - ${10 * scale})`,
                    height: `calc(100% - ${10 * scale})`,
                    top: 5 * scale,
                    left: 5 * scale,
                    borderRadius: 61 * scale,
                }}
            ></div>
            <div
                className="notch"
                style={{
                    width: 210 * scale,
                    height: 30 * scale,
                    top: 26 * scale,
                    left: 108 * scale,
                    borderBottomLeftRadius: 24 * scale,
                    borderBottomRightRadius: 24 * scale,
                }}
            >
                <div
                    className="notch-before"
                    style={{
                        right: -8 * scale,
                        height: 8 * scale,
                        width: 8 * scale,
                    }}
                ></div>
                <div
                    className="camera"
                    style={{
                        width: 6 * scale,
                        height: 6 * scale,
                        top: 9 * scale,
                        left: 154 * scale,
                    }}
                ></div>
                <div
                    className="speaker"
                    style={{
                        width: 60 * scale,
                        height: 6 * scale,
                        top: 9 * scale,
                    }}
                ></div>
                <div
                    className="notch-after"
                    style={{
                        left: -8 * scale,
                        height: 8 * scale,
                        width: 8 * scale,
                    }}
                ></div>
            </div>
            <div
                className="top-bar"
                style={{
                    height: 3 * scale,
                    top: 80 * scale,
                }}
            ></div>
            <div
                className="sleep"
                style={{
                    width: 3 * scale,
                    height: 96 * scale,
                    top: 200 * scale,
                    right: -3 * scale,
                }}
            ></div>
            <div
                className="bottom-bar"
                style={{
                    height: 8 * scale,
                    bottom: 80 * scale,
                }}
            ></div>
            <div
                className="volume"
                style={{ width: 3 * scale, left: -3 * scale, top: 116 * scale, height: 32 * scale }}
            >
                <div
                    className="volume-before"
                    style={{
                        width: 3 * scale,
                        height: 62 * scale,
                        top: 62 * scale,
                    }}
                ></div>
                <div
                    className="volume-after"
                    style={{
                        width: 3 * scale,
                        height: 62 * scale,
                        top: 140 * scale,
                    }}
                ></div>
            </div>
            <div className="overflow" style={{ borderRadius: 66 * scale }}>
                <div
                    className="shadow shadow--tr"
                    style={{ top: -20 * scale, right: -20 * scale }}
                ></div>
                <div
                    className="shadow shadow--tl"
                    style={{ top: -20 * scale, right: -20 * scale }}
                ></div>
                <div
                    className="shadow shadow--br"
                    style={{ top: -20 * scale, right: -20 * scale }}
                ></div>
                <div
                    className="shadow shadow--bl"
                    style={{ top: -20 * scale, right: -20 * scale }}
                ></div>
            </div>
            <div
                className="inner-shadow"
                style={{
                    width: `calc(100% - ${20 * scale}px)`,
                    height: `calc(100% - ${20 * scale}px)`,
                    top: 10 * scale,
                    left: 10 * scale,
                    borderRadius: 56 * scale,
                }}
            >
                <div
                    className="inner-shadow-before"
                    style={{
                        borderRadius: 56 * scale,
                    }}
                ></div>
            </div>
            <div
                className="screen"
                style={{
                    borderRadius: 40 * scale,
                }}
            >
                {props.children}
            </div>
        </div>
    )
}

export default DevicePreview
