import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { useIsMobile } from '../../mixins/media-query'

const NewBadge: React.FC<{ show: boolean; unseenCount?: number; positionRelative?: boolean }> = ({
    show,
    unseenCount,
    positionRelative = false,
}) => {
    const isMobile = useIsMobile()
    if (!show) return null

    return (
        <Box
            sx={
                positionRelative
                    ? {}
                    : {
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          mt: isMobile ? 0 : 1,
                          ml: isMobile ? 0 : 1,
                          zIndex: 1,
                      }
            }
        >
            <Chip
                size={isMobile ? 'small' : 'medium'}
                label={`${unseenCount ? `${unseenCount} ` : ''}New`}
                color="error"
            ></Chip>
        </Box>
    )
}
export default NewBadge
