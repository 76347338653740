import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Root from '../../../../src/client/root.tsx'

import createAuthRequiredPage from "./auth/pages/createAuthRequiredPage"

import Home from '../../../../src/client/pages/routing/home'
import CompanyIntroductionPage from '../../../../src/client/pages/company/onboarding/introduction-page'
import LoginPage from '../../../../src/client/pages/auth/login-page'
import SignupPage from '../../../../src/client/pages/auth/signup-page'
import CompanyOnboardingPage from '../../../../src/client/pages/company/onboarding/onboarding-page'
import { EmailVerification as EmailVerificationPage } from '../../../../src/client/pages/auth/email-verification-page'
import { PasswordReset as PasswordResetPage } from '../../../../src/client/pages/auth/password-reset-page'
import { RequestPasswordReset as RequestPasswordResetPage } from '../../../../src/client/pages/auth/request-password-reset-page'
import InspectorAppPage from '../../../../src/client/pages/company/inspector/index'
import ProductDetail from '../../../../src/client/pages/company/inspector/product-dashboard/index'
import CompanyProductList from '../../../../src/client/pages/customer/company/products/page'
import CompanySupportService from '../../../../src/client/pages/customer/company/support/page'
import ReportAppPage from '../../../../src/client/pages/company/report/index'
import UserManagementAppPage from '../../../../src/client/pages/company/user-management/index'
import SupportAppPage from '../../../../src/client/pages/company/support/index'
import CompanySettingsPage from '../../../../src/client/pages/company/settings/index'
import UserInspectorPage from '../../../../src/client/pages/customer/inspector/inspector-page'
import SupportInspectorPage from '../../../../src/client/pages/customer/support/inspector-page'
import Playground from '../../../../src/client/pages/playground'
import Ufixit from '../../../../src/client/pages/poc/ufixit/index'
import Analysator from '../../../../src/client/pages/service-desk/analysator/index'
import POCMobilePhotoUpload from '../../../../src/client/pages/poc/support/mobile-photo-upload'
import POCSupportForm from '../../../../src/client/pages/poc/support/index'
import ServiceDeskRepairPage from '../../../../src/client/pages/service-desk/repair/index'
import ServiceDeskWarrantyPage from '../../../../src/client/pages/service-desk/warranty/index'
import ServiceDeskAlterationPage from '../../../../src/client/pages/service-desk/alterations/index'
import MobilePhotoUpload from '../../../../src/client/pages/service-desk/mobile-photo-upload'
import ServiceDeskExplorerPage from '../../../../src/client/pages/explorer/index'
import AIVerifierPage from '../../../../src/client/pages/admin/ai-verifier/index'
import AnalyticsLogPage from '../../../../src/client/pages/admin/analytics-log/index'


import { DefaultRootErrorBoundary } from './components/DefaultRootErrorBoundary'

import { routes } from 'wasp/client/router'

export const routeNameToRouteComponent = {
  HomeRoute: createAuthRequiredPage(Home),
  CompanyIntroductionRoute: createAuthRequiredPage(CompanyIntroductionPage),
  LoginRoute: LoginPage,
  SignupRoute: SignupPage,
  CompanyOnboardingRoute: CompanyOnboardingPage,
  EmailVerificationRoute: EmailVerificationPage,
  PasswordResetRoute: PasswordResetPage,
  RequestPasswordResetRoute: RequestPasswordResetPage,
  InspectorAppRoute: createAuthRequiredPage(InspectorAppPage),
  AdminProductDetailRoute: createAuthRequiredPage(ProductDetail),
  ProductDetailRoute: createAuthRequiredPage(ProductDetail),
  CompanyProductListRoute: CompanyProductList,
  CompanySupportServiceRoute: CompanySupportService,
  ReportAppRoute: createAuthRequiredPage(ReportAppPage),
  UserManagementAppRoute: createAuthRequiredPage(UserManagementAppPage),
  SupportAppRoute: createAuthRequiredPage(SupportAppPage),
  CompanySettingsRoute: createAuthRequiredPage(CompanySettingsPage),
  UserInspectorRoute: UserInspectorPage,
  SupportInspectorRoute: SupportInspectorPage,
  PlaygroundRoute: Playground,
  UfixitRoute: Ufixit,
  AnalysatorRoute: createAuthRequiredPage(Analysator),
  POCMobilePhotoUploadRoute: POCMobilePhotoUpload,
  POCSupportFormRoute: POCSupportForm,
  ServiceDeskRepairRoute: ServiceDeskRepairPage,
  ServiceDeskWarrantyRoute: ServiceDeskWarrantyPage,
  ServiceDeskAlterationRoute: ServiceDeskAlterationPage,
  MobilePhotoUploadRoute: MobilePhotoUpload,
  ServiceDeskExplorerRoute: ServiceDeskExplorerPage,
  AIVerifierRoute: createAuthRequiredPage(AIVerifierPage),
  AnalyticsLogRoute: createAuthRequiredPage(AnalyticsLogPage),
} as const;

const waspDefinedRoutes = [
]
const userDefinedRoutes = Object.entries(routes).map(([routeKey, route]) => {
  return {
    path: route.to,
    Component: routeNameToRouteComponent[routeKey],
  }
})

const browserRouter = createBrowserRouter([{
  path: '/',
  element: <Root />,
  ErrorBoundary: DefaultRootErrorBoundary,
  children: [
    ...waspDefinedRoutes,
    ...userDefinedRoutes,
  ],
}])

export const router = <RouterProvider router={browserRouter} />
