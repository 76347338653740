import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    ProductCategoryNode,
    ProductTypeId,
    ProductCategoryId,
    ProductCategory,
} from '../../../../../../shared/data/products/types'
import {
    isProductCategory,
    findParentById,
    isProductType,
} from '../../../../../../shared/data/products/helpers'
import { styled, useTheme } from '@mui/material'
import T from '../../../../../components/typography/t'
import { useForm } from 'react-hook-form'
import InputField from '../../../../../components/form/input-field'
import LabelledDelimiter from '../../components/labelled-delimiter'
import categoryIcon from './category-icon'

const Wrapper = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    ${({ theme }) => theme.breakpoints.down('md')} {
        grid-template-columns: 1fr 1fr;
    }
`

const Grid = styled('div')`
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: 1fr;
    grid-template-columns: calc(25% - 6px) calc(25% - 6px) calc(25% - 6px) calc(25% - 6px);
    gap: 8px 8px;

    ${({ theme }) => theme.breakpoints.down('md')} {
        grid-template-columns: calc(50% - 4px) calc(50% - 4px);
    }
`

const CategoryTile = styled('a')<{ $deactivated: boolean }>`
    padding: 0.75em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    gap: 10px;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    font-weight: 500;
    font-size: 1.2em;
    transition: background-color 0.2s, box-shadow 0.2s;
    ${({ theme, $deactivated }) =>
        $deactivated
            ? `
            border-color: ${theme.palette.grey[100]};
            color: ${theme.palette.grey[100]};
        `
            : `
        cursor: pointer;
        
        &:hover {
            background: ${theme.palette.grey[50]};
        }
        `}
`

type FormData = {
    productReference: string
}

export const ProductSelectionStep = React.forwardRef(
    (
        {
            onSelectedProductTypeId,
            onSelectedProductCategoryId,
            setItemReference,
            initialValue,
            productCategories,
        }: {
            onSelectedProductTypeId: (productId?: ProductTypeId) => void
            onSelectedProductCategoryId: (categoryId?: ProductCategoryId) => void
            setItemReference?: (productReference?: string) => void
            initialValue?: ProductCategoryNode | null
            productCategories: ProductCategoryNode
        },
        ref
    ) => {
        const theme = useTheme()
        const [t] = useTranslation()
        const { register, watch } = useForm<FormData>()
        const [currentTreeEntry, setCurrentTreeEntry] = React.useState(
            initialValue ?? productCategories
        )
        const onTileClick = (item: ProductCategoryNode) => {
            if (isProductCategory(item)) {
                setCurrentTreeEntry(item)
                onSelectedProductCategoryId(item.id)
            } else {
                onSelectedProductTypeId(item.id)
            }
        }

        const goBackInterceptor = (og: () => void) => {
            const parent = findParentById(currentTreeEntry!.id, productCategories) as
                | ProductCategory
                | undefined
            if (parent) {
                setCurrentTreeEntry(parent)
                onSelectedProductCategoryId(parent.id)
            } else {
                og()
            }
        }

        React.useImperativeHandle(ref, () => ({
            goBackInterceptor,
        }))

        const productReferenceEntered = !!watch('productReference')

        const referenceMatcher = () => {
            setItemReference!(watch('productReference'))
        }

        return (
            <>
                {!!setItemReference && (
                    <>
                        <InputField
                            fullWidth
                            {...register('productReference', { onChange: referenceMatcher })}
                            label={t(
                                'serviceDesk.shared.productStep.form.productReferenceLabel' as any
                            )}
                        />

                        <LabelledDelimiter sx={{ my: 4 }}>
                            <T i18nKey="common.or" />
                        </LabelledDelimiter>
                    </>
                )}

                <Wrapper>
                    <Grid>
                        {isProductCategory(currentTreeEntry)
                            ? currentTreeEntry.subItems.map((listItem) => {
                                  const isType = isProductType(listItem)
                                  const deactivated =
                                      productReferenceEntered ||
                                      (isType && !(listItem.views.length > 0))
                                  const Icon = categoryIcon(listItem.Icon)
                                  return (
                                      <CategoryTile
                                          key={listItem.id}
                                          onClick={() =>
                                              deactivated ? null : onTileClick(listItem)
                                          }
                                          $deactivated={deactivated}
                                      >
                                          <Icon
                                              fill={
                                                  deactivated
                                                      ? theme.palette.grey[100]
                                                      : theme.palette.primary.main
                                              }
                                              stroke={
                                                  deactivated
                                                      ? theme.palette.grey[100]
                                                      : theme.palette.primary.main
                                              }
                                              style={{
                                                  maxWidth: 102,
                                                  maxHeight: 102,
                                                  filter: deactivated
                                                      ? 'opacity(0.5)'
                                                      : 'opacity(1)',
                                              }}
                                          ></Icon>
                                          <T i18nKey={listItem.name as any} />
                                      </CategoryTile>
                                  )
                              })
                            : null}
                    </Grid>
                </Wrapper>
            </>
        )
    }
)

export default ProductSelectionStep
