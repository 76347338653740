import { useQuery, getContactRequests, updateSupportRequestBoard } from 'wasp/client/operations'

import React from 'react'
import LoadingSkeleton from '../../../../components/loading/skeleton'
import ContactRequests from './contact-requests'
import CompanyId from '../../../../../shared/types/company/id'
import NoContactRequestsFallback from './no-contact-requests-fallback'

const ContactRequestsWrapper = ({ companyId }: { companyId?: CompanyId }) => {
    const { data, isFetching, error } = useQuery(getContactRequests, { companyId })

    if (isFetching) return <LoadingSkeleton large />
    if (error) return <div>Error: {error.message}</div>

    if (data!.length === 0) return <NoContactRequestsFallback />

    return (
        <ContactRequests
            contactRequests={data!}
            updateSupportRequestBoard={updateSupportRequestBoard}
        />
    )
}

export default ContactRequestsWrapper
