import theme from '../../theme'
import Dialog from '@mui/material/Dialog'
import styled from 'styled-components'

const StyledUploadCareDialog = styled(Dialog)`
    .uploadcare--widget__button_type_open {
        background-color: ${theme.palette.primary.main};

        :hover {
            background-color: ${theme.palette.primary.dark};
        }
    }
`

export default StyledUploadCareDialog
