import { UploadClient } from '@uploadcare/upload-client'
import { UploadedFileInfo } from '../steps/photo-step'

const useUploadCareUpload = ({
    setUploadProgress,
    setUploadedFileInfo,
    onPhotoUploadFailed,
    setImageUrl,
    resetUpload,
    uploadCareClient,
}: {
    setUploadProgress: (progression: number) => void
    setUploadedFileInfo: (info?: UploadedFileInfo) => void
    onPhotoUploadFailed: (msg: string) => void
    setImageUrl: (url?: string) => void
    resetUpload: () => void
    uploadCareClient: UploadClient
}) => {
    const onProgress = ({ isComputable, value }: any) => {
        if (isComputable) {
            setUploadProgress(Math.min(value, 0.99))
        }
    }

    return (photo?: File) => {
        if (!photo) {
            return
        }
        resetUpload()
        setUploadedFileInfo({ name: photo.name, size: photo.size })
        uploadCareClient
            .uploadFile(photo, { onProgress })
            .then(async (file) => {
                if (file.cdnUrl === null) {
                    onPhotoUploadFailed('Uploadcare returned null CDN URL')
                } else {
                    setImageUrl(file.cdnUrl!)
                    setUploadProgress(1)
                }
            })
            .catch(onPhotoUploadFailed)
    }
}

export default useUploadCareUpload
