import styled from 'styled-components'
import theme from '../../theme'

export const NavBar = styled.div`
    padding: 10px 0 0 0;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    align-content: center;
    justify-content: center;

    ${theme.breakpoints.down('sm')} {
        padding: 5px 0 0 0;
    }
`

export default NavBar
