import React from 'react'
import {
    Chip,
    Stack,
    styled,
    Typography,
    Button,
    Link,
    SwipeableDrawer,
    IconButton,
} from '@mui/material'
import { Difficulty, DifficultyEnum, IfixitGuide } from './data/ifixit-types'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import useDialog from '../../../mixins/use-dialog'
import CloseIcon from '@mui/icons-material/Close'
import T from '../../../components/typography/t'

const HeroImg = styled('img')`
border-radius: 8px;
width: 100%
height: auto;
max-height: 300px;
object-fit: cover;
`

const InnerDrawer = styled('div')`
    padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(4)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1em;
`

const ToolList = styled('ul')`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const ToolEntry = styled('li')`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75em;
    border-radius: 8px;
    padding: 0.5em;

    &::before {
        display: block;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #000000;
    }

    a {
        text-decoration: underline;
    }
`

const getDifficultyColor = (difficulty: Difficulty) => {
    switch (difficulty) {
        case DifficultyEnum.veryEasy:
        case DifficultyEnum.easy:
            return 'success'
        case DifficultyEnum.moderate:
            return 'warning'
        case DifficultyEnum.difficult:
            return 'error'
        default:
            return undefined
    }
}

const GuideIntroPage: React.FC<{ guide: IfixitGuide; onStartClick: () => void }> = ({
    guide,
    onStartClick,
}) => {
    const { isOpen: isToolsOpen, close: closeTools, open: openTools } = useDialog()
    const { isOpen: isPartsOpen, close: closeParts, open: openParts } = useDialog()

    return (
        <>
            <HeroImg
                src={guide.image.large ?? guide.image.medium ?? ''}
                sx={{ mb: 3, aspectRatio: (guide.image.width ?? 1) / (guide.image.height ?? 1) }}
            />
            <Stack direction="row" gap={1} mb={2}>
                <Chip
                    icon={<AccessTimeIcon />}
                    label={guide.difficulty}
                    color={getDifficultyColor(guide.difficulty)}
                />
                <Chip icon={<AccessTimeIcon />} label={guide.time_required} />
            </Stack>
            <Typography variant="h5" component="h1" fontSize={16} fontWeight="600" mb={2}>
                {guide.title}
            </Typography>
            <Typography
                variant="body1"
                component="p"
                mb={4}
                dangerouslySetInnerHTML={{ __html: guide.introduction_rendered }}
                sx={{ '& a': { textDecoration: 'underline' } }}
            ></Typography>
            {guide.tools.length > 0 && (
                <Link sx={{ mb: 4, textAlign: 'center' }} onClick={openTools}>
                    <T i18nKey="serviceDesk.ufixit.intro.toolsNeededLink" />
                </Link>
            )}
            {guide.parts.length > 0 && (
                <Link sx={{ mb: 4, textAlign: 'center' }} onClick={openParts}>
                    <T i18nKey="serviceDesk.ufixit.intro.sparePartsNeededLink" />
                </Link>
            )}
            <Button
                variant="contained"
                color="primary"
                sx={{ alignSelf: 'center', position: 'sticky', bottom: '2em', boxShadow: 4 }}
                onClick={onStartClick}
            >
                <T i18nKey="serviceDesk.ufixit.intro.startAction" />
            </Button>

            <SwipeableDrawer
                open={isToolsOpen}
                onOpen={openTools}
                onClose={closeTools}
                PaperProps={{ sx: { borderRadius: '24px 24px 0 0' } }}
            >
                <InnerDrawer>
                    <Typography component="h2" fontWeight={500} color="primary">
                        <T i18nKey="serviceDesk.ufixit.intro.toolsNeededTitle" />
                    </Typography>
                    <ToolList>
                        {guide.tools.map((tool) => (
                            <ToolEntry key={tool.text}>
                                {tool.url?.startsWith('http') ? (
                                    <a
                                        href={tool.url.replace('http://', 'https://')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {tool.text}
                                    </a>
                                ) : (
                                    tool.text
                                )}
                            </ToolEntry>
                        ))}
                    </ToolList>
                    <IconButton
                        onClick={(e: any) => {
                            e.target.blur()
                            closeTools()
                        }}
                        sx={{ position: 'absolute', top: '.25em', right: '.5em' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </InnerDrawer>
            </SwipeableDrawer>
            <SwipeableDrawer
                open={isPartsOpen}
                onOpen={openParts}
                onClose={closeParts}
                PaperProps={{ sx: { borderRadius: '24px 24px 0 0' } }}
            >
                <InnerDrawer>
                    <Typography component="h2" fontWeight={500} color="primary">
                        <T i18nKey="serviceDesk.ufixit.intro.sparePartsNeededLink" />
                    </Typography>
                    <ToolList>
                        {guide.parts.map((part) => (
                            <ToolEntry key={part.text}>
                                {part.url?.startsWith('http') ? (
                                    <a
                                        href={part.url.replace('http://', 'https://')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {part.text}
                                    </a>
                                ) : (
                                    part.text
                                )}
                            </ToolEntry>
                        ))}
                    </ToolList>
                    <IconButton
                        onClick={(e: any) => {
                            e.target.blur()
                            closeParts()
                        }}
                        sx={{ position: 'absolute', top: '.25em', right: '.5em' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </InnerDrawer>
            </SwipeableDrawer>
        </>
    )
}

export default GuideIntroPage
