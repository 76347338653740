import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogContent, Dialog, IconButton, Stack, DialogTitle, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { BrandLogo } from '../../../../components/layout'
import T from '../../../../components/typography/t'

const StoreFinderIFrameDialog = ({
    storeFinderIframeUrl,
    isOpen,
    close,
    logo,
}: {
    storeFinderIframeUrl: string
    isOpen: boolean
    close: () => void
    logo: string
}) => {
    const { i18n } = useTranslation()

    return (
        <Dialog onClose={close} open={isOpen} fullScreen>
            <DialogTitle
                sx={{
                    borderBottom: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.getProgressbarColor?.() ?? theme.palette.primary.main,
                    px: 2,
                }}
            >
                <Stack direction="row" alignItems="center" gap={1} justifyContent="space-between">
                    <Box sx={{ width: '50%', flexShrink: 1, height: '100%' }}>
                        <BrandLogo src={logo} />
                    </Box>
                    <T
                        mb={0}
                        variant="h3"
                        component="span"
                        i18nKey="serviceDesk.repair.solutionStep.linktree.storeFinderTitle"
                        textAlign="center"
                        sx={{ flexShrink: 0, flexGrow: 1 }}
                    />
                    <Box sx={{ width: '50%', flexShrink: 1, height: '1px' }} />
                </Stack>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={close}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <iframe
                    style={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        minHeight: '620px',
                    }}
                    src={`${storeFinderIframeUrl}${i18n.language.slice(0, 2)}`}
                ></iframe>
            </DialogContent>
        </Dialog>
    )
}

export default StoreFinderIFrameDialog
