import { styled } from '@mui/material'

const StepContainer = styled('div')`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 32px 16px 112px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        padding: 20px 10px 100px;
    }
`

export default StepContainer
