import * as React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Snackbar from '@mui/material/Snackbar'
import EditIcon from '@mui/icons-material/Edit'
import FormHelperText from '@mui/material/FormHelperText'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Widget as UploadCareWidget } from '@uploadcare/react-widget'
import useDialog from '../../../../mixins/use-dialog'
import StyledUploadCareDialog from '../../../../components/dialog/styled-upload-care-dialog'
import StyledSuccessAlert from '../../../../components/alert/styled-success-alert'
import StyledErrorAlert from '../../../../components/alert/styled-error-alert'
import routeBuilder from '../../../../routes'
import T from '../../../../components/typography/t'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'

const EditOverlay = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;
`

const Wrapper = styled('a')`
    position: relative;
    display: flex;

    &:hover {
        cursor: pointer;

        ${EditOverlay} {
            opacity: 1;
            pointer-events: all;
        }
    }
`

const Logo = styled('img')`
    width: 96px;
    height: 96px;
    object-fit: contain;
    object-position: center;
`

const LogoForm = ({
    logoUrl,
    onFileUpload,
}: {
    logoUrl: string
    onFileUpload: (fileUrl: string, onSave: () => void, onError: () => void) => void
}) => {
    const { isOpen, open, close } = useDialog()
    const [wasRecentlySaved, setWasRecentlySaved] = React.useState(false)
    const [hasRecentlyFailed, setHasRecentlyFailed] = React.useState(false)
    const [isSaving, setIsSaving] = React.useState(false)
    const { i18n } = useTranslation()

    const saveFile = (fileUrl: string) => {
        setIsSaving(true)
        onFileUpload(
            fileUrl,
            () => {
                setIsSaving(false)
                setWasRecentlySaved(true)
                setTimeout(() => setWasRecentlySaved(false), 2000)
                close()
            },
            () => {
                setIsSaving(false)
                setHasRecentlyFailed(true)
                setTimeout(() => setHasRecentlyFailed(false), 2000)
                close()
            }
        )
    }

    return (
        <>
            <Wrapper onClick={open}>
                <Logo src={logoUrl ? logoUrl : routeBuilder.companyFallbackLogo()} />
                <EditOverlay>
                    <Typography variant="body1" component="span" color="white">
                        <EditIcon />
                    </Typography>
                </EditOverlay>
            </Wrapper>
            <StyledUploadCareDialog open={isOpen} onClose={close}>
                <DialogTitle>
                    <T i18nKey="company.companySettings.account.logoForm.title" />
                </DialogTitle>
                <DialogContent>
                    <Box m={4}>
                        <UploadCareWidget
                            publicKey="cbd638df7b70b9034329"
                            imagesOnly={true}
                            tabs="file"
                            locale={i18n.language as any}
                            onFileSelect={(file: any) => {
                                if (file) {
                                    file.done((info: any) => {
                                        saveFile(info.cdnUrl)
                                    })
                                }
                            }}
                        />
                        <Box m={1}>
                            <FormHelperText>
                                <T i18nKey="company.companySettings.account.logoForm.helperText" />
                            </FormHelperText>
                        </Box>
                        {isSaving && (
                            <T
                                i18nKey="company.companySettings.account.logoForm.loading"
                                variant="body1"
                                component="p"
                            />
                        )}
                    </Box>
                </DialogContent>
            </StyledUploadCareDialog>
            <Snackbar
                open={wasRecentlySaved}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <StyledSuccessAlert>
                    <T i18nKey="company.companySettings.account.logoForm.success" />
                </StyledSuccessAlert>
            </Snackbar>
            <Snackbar
                open={hasRecentlyFailed}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <StyledErrorAlert severity="error">
                    <T i18nKey="company.companySettings.account.logoForm.failed" />
                </StyledErrorAlert>
            </Snackbar>
        </>
    )
}

export default LogoForm
