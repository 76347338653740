import { useQuery, getAdminCompanyList } from 'wasp/client/operations'
import React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CompanyRowEntry from './company-row-entry'
import LoadingSkeleton from '../../../../../components/loading/skeleton'
import CardTitle from '../../../../../components/typography/card-title'
import ReportLogo from '../../../../../assets/img/report-logo.png'
import styled from 'styled-components'
import routeBuilder from '../../../../../routes'
import HeaderBar from '../../../../company/inspector/products/components/header-bar'
import T from '../../../../../components/typography/t'
import { Button, Stack } from '@mui/material'

const AppLogo = styled.img`
    width: 32px;
`

const CompanyList = () => {
    const { data: companies, isFetching, error } = useQuery(getAdminCompanyList)

    if (isFetching) return <LoadingSkeleton large />
    if (error) return <div> Error! </div>
    if (companies?.length === 0) return null
    return (
        <Box>
            <HeaderBar>
                <CardTitle>
                    <T i18nKey="admin.companies.title" />
                </CardTitle>
                <Stack direction="row" spacing={2} alignItems="center">
                    <a href={routeBuilder.reportApp()}>
                        <AppLogo src={ReportLogo} />
                    </a>
                </Stack>
            </HeaderBar>
            <Card sx={{ overflowX: 'auto' }}>
                <CardContent>
                    <Table size="small" aria-label="product table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                                    <T i18nKey="admin.companies.table.id" />
                                </TableCell>
                                <TableCell sx={{ px: { xs: 0, md: 1 } }}>
                                    <T i18nKey="admin.companies.table.company" />
                                </TableCell>
                                <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                                    <T i18nKey="admin.companies.table.representative" />
                                </TableCell>
                                <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                                    <T i18nKey="admin.companies.table.representativeEmail" />
                                </TableCell>
                                <TableCell sx={{ px: { xs: 0, md: 1 } }}>
                                    <T i18nKey="admin.companies.table.apps" />
                                </TableCell>
                                <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                                    <T i18nKey="admin.companies.table.productCount" />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {companies &&
                                companies.map((company) => (
                                    <CompanyRowEntry key={company.id} company={company} />
                                ))}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </Box>
    )
}

export default CompanyList
