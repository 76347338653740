import React from 'react'
import MultiStepNavigation, { MultiStepNavigationProps } from './navigation'
import StepContainer from './step-container'
import { ErrorStack } from './error-stack'
import styled from 'styled-components'

export const StepContentContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ErrorStackWrapper = styled.div`
    position: absolute;
    bottom: 100px;
    left: 5%;
    width: 90%;
`

type StepFrameworkWrapperProps = {
    children: React.ReactNode
    navigationProps?: Partial<MultiStepNavigationProps>
}

const StepFrameworkWrapper = ({ navigationProps, children }: StepFrameworkWrapperProps) => {
    return (
        <StepContainer>
            <StepContentContainer>{children}</StepContentContainer>
            <ErrorStackWrapper>
                <ErrorStack />
            </ErrorStackWrapper>
            <MultiStepNavigation {...navigationProps} />
        </StepContainer>
    )
}

export default StepFrameworkWrapper
