import React from 'react'
import TextField from '@mui/material/TextField'
import { InspectorMultiStepFormContext } from '../../inspector-multi-step-form'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../components/typography/inspector-step-subtitle'
import { useTranslation } from 'react-i18next'
import DescriptionIcon from '../../../../../assets/img/inspector-icons/description-icon.svg'

export const DescriptionStep = ({
    onDescriptionChange,
    initialDescription,
}: {
    onDescriptionChange: (description: string) => void
    initialDescription?: string
}) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle
                mb={2}
                i18nKey="support.mobile.description.title"
                Icon={DescriptionIcon}
            />
            <InspectorStepSubtitle mb={2} i18nKey="support.mobile.description.description" />
            <TextField
                multiline
                fullWidth
                defaultValue={initialDescription}
                label={t('support.mobile.description.label' as any)}
                placeholder={t('support.mobile.description.placeholder' as any)}
                minRows={5}
                maxRows={15}
                onChange={(e) => onDescriptionChange(e.target.value)}
            ></TextField>
        </>
    )
}

const DescriptionStepContext = () => {
    const { form } = React.useContext(InspectorMultiStepFormContext)
    return (
        <StepFrameworkWrapper
            navigationProps={{
                nextButtonDisabled: !form.formValues.inspectionDescription,
            }}
        >
            <DescriptionStep
                onDescriptionChange={form.setInspectionDescription}
                initialDescription={form.formValues.inspectionDescription}
            />
        </StepFrameworkWrapper>
    )
}

export default DescriptionStepContext
