import React from 'react'
import { RepairMultiStepFormContext } from '../../context'
import StepFrameworkWrapper from '../../../shared/components/step-framework-wrapper'
import DIYStep from '../../../shared/steps/diy-guides-step'
import NoEstimationScreen from './no-estimation-screen'
import OneEstimationScreen from './one-estimation'
import Linktree from './linktree'
import CostEstimation from './cost-estimation'
import {
    Dialog,
    DialogContent,
    DialogContentText,
    LinearProgress,
    Box,
    Typography,
} from '@mui/material'
import T from '../../../../../components/typography/t'
import { useForm } from 'react-hook-form'
import FormData from '../../../../../../shared/types/common/form-data'

const SolutionStepContext = () => {
    const { form, props, ctx, meta } = React.useContext(RepairMultiStepFormContext)
    const useFormReturn = useForm<FormData>({ values: form.formValues.contactFormValues })
    const [isLoading, setIsloading] = React.useState(false)
    const [hasSubmitted, setHasSubmitted] = React.useState(false)
    const repairGuides = ctx.repairGuides.filter((repairGuide) =>
        form.formValues.aiSelectedRepairGuides.includes(repairGuide.id)
    )
    const selectedRepairServices = ctx.whitelabel.repairServices.filter((s) =>
        form.formValues.aiSelectedServices.includes(s.id)
    )
    const showLinkTree =
        repairGuides.length > 0 || !!ctx.whitelabel.config.repair.storeFinderIframeUrl

    const [selectedApproach, setSelectedApproach] = React.useState(showLinkTree ? 0 : 2)

    const formSubmit = () => {
        if (!hasSubmitted) {
            form.submitForm()
            props.nextSlide()
        }
    }

    const handleServiceAcceptance = (serviceId: number) => {
        const service = selectedRepairServices.find((s) => s.id === serviceId)
        if (!service) return
        form.setValue('acceptedServices', [...form.formValues.acceptedServices, service.id])
    }

    const handleServiceRejection = (serviceId: number) => {
        const service = selectedRepairServices.find((s) => s.id === serviceId)
        if (!service) return
        form.setValue(
            'acceptedServices',
            form.formValues.acceptedServices.filter((id: number) => id !== serviceId)
        )
    }

    const fileuploadProgress = meta.value.fileUpload?.progress * 100

    const onRepairFormSubmit = (data: any) => {
        form.setValue('contactFormValues', data)
        console.log('onRepairFormSubmit', data)

        const trySubmit = () => {
            meta.setMetaValue((meta: any) => {
                if (meta.fileUpload.progress >= 1) {
                    setIsloading(false)
                    clearInterval(intervalId)
                    formSubmit()
                    setHasSubmitted(true)
                } else {
                    setIsloading(true)
                }
                return meta
            })
        }
        const intervalId = setInterval(trySubmit, 1000)
    }

    const addMoreDefectsClick = () => {
        form.resetForm()
        props.setSlide(0)
    }

    return (
        <StepFrameworkWrapper
            wide
            navigationProps={{
                nextButtonLabelKey: 'common.next',
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled: true,
                onValidPrevClick: () => {
                    form.setValue('contactFormValues', useFormReturn.getValues())
                    if (selectedApproach === 0 || !showLinkTree) {
                        props.prevSlide()
                    } else {
                        setSelectedApproach(0)
                    }
                },
            }}
        >
            {selectedApproach === 0 && (
                <Linktree
                    onDiyClick={() => setSelectedApproach(1)}
                    repairGuideCount={repairGuides.length}
                    selectedServices={selectedRepairServices}
                    onServicesClick={() => setSelectedApproach(2)}
                    storeFinderIframeUrl={ctx.whitelabel.config.repair.storeFinderIframeUrl}
                    logo={ctx.whitelabel.logo}
                />
            )}
            {selectedApproach === 1 && (
                <DIYStep
                    repairGuides={repairGuides}
                    brandId={ctx.whitelabel?.id}
                    onServicesClick={() => setSelectedApproach(2)}
                />
            )}
            {selectedApproach === 2 && (
                <>
                    {selectedRepairServices.length === 0 && (
                        <NoEstimationScreen
                            onSubmit={onRepairFormSubmit}
                            onAddMoreDefectsClick={addMoreDefectsClick}
                            contactForm={ctx.whitelabel.config.repair.contactForm}
                            useForm={useFormReturn}
                            showDiySolutions={
                                repairGuides.length > 0 ? () => setSelectedApproach(1) : undefined
                            }
                        />
                    )}
                    {selectedRepairServices.length === 1 && (
                        <OneEstimationScreen
                            selectedService={selectedRepairServices[0]}
                            onDiyClick={() => setSelectedApproach(1)}
                            repairGuideCount={repairGuides.length}
                            onSubmit={onRepairFormSubmit}
                            contactForm={ctx.whitelabel.config.repair.contactForm}
                            useForm={useFormReturn}
                        />
                    )}
                    {selectedRepairServices.length > 1 && (
                        <CostEstimation
                            selectedServices={selectedRepairServices}
                            onDiyClick={() => setSelectedApproach(1)}
                            repairGuideCount={repairGuides.length}
                            onSubmit={onRepairFormSubmit}
                            onAddMoreDefectsClick={addMoreDefectsClick}
                            contactForm={ctx.whitelabel.config.repair.contactForm}
                            useForm={useFormReturn}
                            acceptedServiceIds={form.formValues.acceptedServices}
                            handleServiceAcceptance={handleServiceAcceptance}
                            handleServiceRejection={handleServiceRejection}
                        />
                    )}
                </>
            )}
            <Dialog open={isLoading}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <T i18nKey="serviceDesk.repair.solutionStep.waitingForPhotoUpload" />
                    </DialogContentText>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress variant="determinate" value={fileuploadProgress} />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                            <Typography
                                variant="body2"
                                sx={{ color: 'text.secondary' }}
                            >{`${Math.round(fileuploadProgress)}%`}</Typography>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </StepFrameworkWrapper>
    )
}

export default SolutionStepContext
