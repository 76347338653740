import React from 'react'
import ImageList from '@mui/material/ImageList'
import Submission from '../../../../../../../shared/types/product/dashboard-submission'
import SubmissionId from '../../../../../../../shared/types/submission/id'
import createUploadcareImageSrc from '../../../../../../mixins/create-uploadcare-image-src'
import InspectionImageTile from '../../../../common/inspection-image-tile'

const MobileInspectionImageGrid: React.FC<{
    submissions: Array<Submission>
    openSubmission: (submissionId: SubmissionId) => void
}> = ({ submissions, openSubmission }) => {
    const maxCols = 2
    const cols = Math.min(maxCols, submissions.length)
    const rowHeight = 320
    return (
        <ImageList cols={cols} rowHeight={rowHeight} sx={{ width: '100%' }}>
            {submissions.map((submission) => (
                <InspectionImageTile
                    key={submission.id}
                    onClick={() => openSubmission(submission.id)}
                    imageUrl={createUploadcareImageSrc(submission.image, {
                        preview: '500x660',
                    })}
                    isUnseenByUser={submission.unseenByUser}
                    labels={submission.labels}
                    inspectionOrigin={submission.inspectionOrigin}
                />
            ))}
        </ImageList>
    )
}

export default MobileInspectionImageGrid
