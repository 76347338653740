import Skeleton, { SkeletonOwnProps } from '@mui/material/Skeleton'

const LoadingSkeleton = ({ large = false, ...props }: { large?: boolean } & SkeletonOwnProps) => {
    return (
        <Skeleton
            animation="wave"
            variant="rounded"
            width="100%"
            height={large ? 256 : 118}
            {...props}
        />
    )
}

export default LoadingSkeleton
