import Typography from '@mui/material/Typography'
import { OverridableComponent, OverridableTypeMap } from '@mui/material/OverridableComponent'
import styled from 'styled-components'
import BrushStroke from '../../assets/img/brush-stroke.svg'

const Typo: OverridableComponent<OverridableTypeMap> = styled(Typography)`
    position: relative;

    img {
        z-index: -1;
        position: absolute;
        width: 120%;
        height: 160%;
        left: -20%;
        bottom: -40%;
        transform: rotate(-5deg);
    }
`

const Title = ({ children }: { children: any }) => {
    return (
        <Typo variant="h1" component="h1" color="primary">
            <img src={BrushStroke} />
            {children}
        </Typo>
    )
}

export default Title
