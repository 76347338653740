import { RepairGuide } from '../repair-guides/types'
import type RepairService from '../repair-services/types'
import { ProductCategoryNode } from '../products/types'
import { type ThemeOptions, type SxProps, type OutlinedTextFieldProps } from '@mui/material'
import { RepairPlugins } from '../../types/repair/plugins'
import { WarrantyPlugins } from '../../types/warranty/plugins'
import AlterationService from '../alteration-services/types'

export type ItemReferenceIdentification = {
    apiUrl: string
}

export type ServiceDeskContactForm = {
    fields: Field[][]
}

export enum FieldType {
    TextField,
    Autocomplete,
}

export type HelperPopup = {
    title: string
    text?: string
    imgUrl?: string
}

type FieldProps = Partial<
    {
        fieldControlSx: SxProps
    } & Omit<OutlinedTextFieldProps, 'variant'>
>

export type FieldParams = Partial<{
    required: boolean
    disabled: boolean
    helperPopup: HelperPopup
}>

export type Field =
    | {
          key: string
          label: string
          type: FieldType.TextField
          params?: FieldParams
          fieldProps?: FieldProps
      }
    | {
          key: string
          label: string
          type: FieldType.Autocomplete
          params?: FieldParams
          options: Array<{
              value: string
              [key: string]: any
          }>
          fieldProps?: FieldProps
      }

export enum WhitelabelStatus {
    InternalOnly,
    ExternalDemoAccess,
    Live,
}

export enum WarrantyInformationStepType {
    Text = 'Text',
    Photo = 'Photo',
}

export type WarrantyInformationTextStep = {
    fieldKey: string
    title: string
    type: WarrantyInformationStepType.Text
    fields: Field[][]
}

export type WarrantyInformationPhotoStep = {
    fieldKey: string
    type: WarrantyInformationStepType.Photo
    photo: {
        key: string
        label: string
        options?: Partial<{
            required: boolean
            helperPopup: HelperPopup
        }>
    }
    required: boolean
    alternativeFields?: Field[][]
    translations: {
        title: string
        photoTitle: string
        photoSubtitle: string
        textTitle: string
        textSubtitle: string
        notAvailableQuestion?: string
        notAvailableAction?: string
    }
}

export type Configuration = {
    repair?: {
        itemReferenceIdentification?: ItemReferenceIdentification
        contactForm: ServiceDeskContactForm
        displayRepairOptions?: boolean
        activateCostEstimation: boolean
        includeDiyGuides: boolean
        outro: {
            lead: string
            links: {
                href: string
                label: string
            }[]
        }
        storeFinderIframeUrl?: string
        backendPlugins: RepairPlugins[]
    }
    warranty?: {
        contactForm: ServiceDeskContactForm
        warrantyInformationSteps?: Array<WarrantyInformationTextStep | WarrantyInformationPhotoStep>
        activateWarrantyEstimation: boolean
        outro: {
            lead: string
            links: {
                href: string
                label: string
            }[]
        }
        backendPlugins: WarrantyPlugins[]
    }
    alterations?: {
        contactForm: ServiceDeskContactForm
        outro: {
            lead: string
            links: {
                href: string
                label: string
            }[]
        }
        backendPlugins: WarrantyPlugins[]
    }
    ufixit?: {
        outro: {
            assistanceIframeUrl?: string
        }
    }
}

export type Whitelabel = {
    id: string
    status: WhitelabelStatus
    name: string
    theme: Partial<ThemeOptions>
    logo: string
    links?: Partial<{
        storeFinder: string
    }>
    repairGuides: RepairGuide[]
    repairServices: RepairService[]
    alterationServices: AlterationService[]
    productCategoryTree: ProductCategoryNode
    config: Configuration
}
