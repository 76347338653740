import { ProductCategoryId, ProductCategoryNode, ProductTypeId } from './types'

export const productCategories: ProductCategoryNode = {
    id: 0 as ProductCategoryId,
    name: 'All',
    Icon: () => null,
    subItems: [
        {
            id: 201 as ProductTypeId,
            name: 'Männer Shell Hose',
            Icon: (props: any) => (
                <img
                    {...props}
                    src="https://ucarecdn.com/79b3b621-7199-45d2-81da-6aeb99bb96ed/-/preview/46x200/"
                />
            ),
            applicableGuides: [
                // Intersport
                169514, 169517, 169519, 169520, 169521, 169522, 169525, 169526, 169527, 169531,
                169940, 170249,
                // Vaude
                69099, 69101, 69102, 69105, 69115, 69137, 69138, 69141, 69150, 69155, 74532, 74517,
            ],
            applicableServices: [
                // Gore-Tex
                3, 6, 9, 11, 14, 16, 17,
            ],
            views: [
                {
                    label: 'poc.support.productStep.views.front',
                    sketchUrl:
                        'https://ucarecdn.com/79b3b621-7199-45d2-81da-6aeb99bb96ed/-/preview/113x500/',
                    details: [],
                },
            ],
        },
        {
            id: 202 as ProductTypeId,
            name: 'Frauen Shell Hose',
            Icon: (props: any) => (
                <img
                    {...props}
                    src="https://ucarecdn.com/84cb0160-1ee2-47da-b5ad-8106d2d96b2f/-/preview/46x200/"
                />
            ),
            applicableGuides: [
                // Intersport
                169514, 169517, 169519, 169520, 169521, 169522, 169525, 169526, 169527, 169531,
                169940, 170249,
                // Vaude
                69099, 69101, 69102, 69105, 69115, 69137, 69138, 69141, 69150, 69155, 74532, 74517,
            ],
            applicableServices: [
                // Gore-Tex
                3, 6, 9, 11, 14, 16, 17,
            ],
            views: [
                {
                    label: 'poc.support.productStep.views.front',
                    sketchUrl:
                        'https://ucarecdn.com/84cb0160-1ee2-47da-b5ad-8106d2d96b2f/-/preview/113x500/',
                    details: [],
                },
            ],
        },
        {
            id: 203 as ProductTypeId,
            name: 'Männer Shell Jacke',
            Icon: (props: any) => (
                <img
                    {...props}
                    src="https://ucarecdn.com/6b382e3e-d31d-4ce8-8ec3-4e1733b8417a/-/preview/158x200/"
                />
            ),
            applicableGuides: [
                // Intersport
                169514, 169517, 169519, 169520, 169521, 169522, 169525, 169526, 169527, 169531,
                169940, 170249,
                // Vaude
                69099, 69101, 69102, 69105, 69115, 69137, 69138, 69141, 69150, 69155, 74532, 74517,
            ],
            applicableServices: [
                // Gore-Tex
                1, 2, 4, 5, 7, 8, 10, 12, 13, 15, 18,
            ],
            views: [
                {
                    label: 'poc.support.productStep.views.front',
                    sketchUrl:
                        'https://ucarecdn.com/6b382e3e-d31d-4ce8-8ec3-4e1733b8417a/-/preview/391x500/',
                    details: [],
                },
            ],
        },
        {
            id: 204 as ProductTypeId,
            name: 'Frauen Shell Jacke',
            Icon: (props: any) => (
                <img
                    {...props}
                    src="https://ucarecdn.com/aaf25029-fb8c-43a2-a63c-32c23651fdb1/-/preview/131x200/"
                />
            ),
            applicableGuides: [
                // Intersport
                169514, 169517, 169519, 169520, 169521, 169522, 169525, 169526, 169527, 169531,
                169940, 170249,
                // Vaude
                69099, 69101, 69102, 69105, 69115, 69137, 69138, 69141, 69150, 69155, 74532, 74517,
            ],
            applicableServices: [
                // Gore-Tex
                1, 2, 4, 5, 7, 8, 10, 12, 13, 15, 18,
            ],
            views: [
                {
                    label: 'poc.support.productStep.views.front',
                    sketchUrl:
                        'https://ucarecdn.com/aaf25029-fb8c-43a2-a63c-32c23651fdb1/-/preview/326x500/',
                    details: [],
                },
            ],
        },
    ],
}
export default productCategories
