import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import styled from 'styled-components'
import CardTitle from '../../../../../components/typography/card-title'
import T from '../../../../../components/typography/t'

const StyledCard = styled(Card)`
    width: 100%;
`

const ProductOnboarding = ({ openNewProductDialog }: { openNewProductDialog: () => void }) => {
    return (
        <StyledCard>
            <CardContent>
                <CardTitle>
                    <T i18nKey="company.inspector.home.onboarding.title" />
                </CardTitle>
                <Box display="flex" alignItems="center" flexDirection="column">
                    <Button variant="contained" color="primary" onClick={openNewProductDialog}>
                        <T i18nKey="company.inspector.home.onboarding.action" />
                    </Button>
                </Box>
            </CardContent>
        </StyledCard>
    )
}

export default ProductOnboarding
