import React from 'react'
import Stack from '@mui/material/Stack'
import Badge from '@mui/material/Badge'
import RepairCountIcon from '@mui/icons-material/HandymanOutlined'
import FeedbackCountIcon from '@mui/icons-material/FeedbackOutlined'

const DifferentOriginCount: React.FC<{ repairCount: number; feedbackCount: number }> = ({
    repairCount,
    feedbackCount,
}) => {
    return (
        <Stack
            direction="column"
            spacing={1}
            sx={{
                position: 'absolute',
                mt: 2,
                mr: 2,
                top: 0,
                right: 0,
                zIndex: 1,
            }}
        >
            <Badge color="primary" badgeContent={repairCount} showZero>
                <RepairCountIcon />
            </Badge>
            <Badge color="primary" badgeContent={feedbackCount} showZero>
                <FeedbackCountIcon />
            </Badge>
        </Stack>
    )
}

export default DifferentOriginCount
