import { ProductCategoryId, ProductCategoryNode, ProductTypeId } from './types'

export const productCategories: ProductCategoryNode = {
    id: 0 as ProductCategoryId,
    name: 'All',
    Icon: 'null',
    subItems: [
        {
            id: 101 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.tshirt',
            internalName: 'T-Shirt',
            Icon: 'TShirtIcon',
            applicableGuides: [],
            applicableServices: [],
            applicableAlterations: [],
            views: [
                {
                    label: 'serviceDesk.shared.pinpointStep.views.front',
                    sketchUrl: 'https://ucarecdn.com/a6df08e7-9f77-4dd7-84b4-91636d07a608/',
                    details: [],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.back',
                    sketchUrl: 'https://ucarecdn.com/9bf4dd17-da98-4c2c-9e67-6beb3196752c/',
                    details: [],
                },
            ],
        },
        {
            id: 102 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.pant',
            internalName: 'Pants',
            Icon: 'PantsIcon',
            applicableGuides: [],
            applicableServices: [],
            applicableAlterations: [],
            views: [
                {
                    label: 'serviceDesk.shared.pinpointStep.views.front',
                    sketchUrl: 'https://ucarecdn.com/e48d0353-db7e-474d-aab0-71393d1a60d3/',
                    details: [],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.back',
                    sketchUrl: 'https://ucarecdn.com/aee04e2e-cba5-45c9-a5e2-b1dcdef3bd74/',
                    details: [],
                },
            ],
        },
        {
            id: 103 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.leggings',
            internalName: 'Leggings',
            Icon: 'LeggingIcon',
            applicableGuides: [],
            applicableServices: [],
            applicableAlterations: [],
            views: [
                {
                    label: 'serviceDesk.shared.pinpointStep.views.front',
                    sketchUrl: 'https://ucarecdn.com/6c305389-bc41-4ba5-80d7-e7a3122d84b4/',
                    details: [],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.back',
                    sketchUrl: 'https://ucarecdn.com/d9764938-1d1b-402f-becf-42e1858be24a/',
                    details: [],
                },
            ],
        },
        {
            id: 104 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.longsleeve',
            internalName: 'Longsleeve',
            Icon: 'LongsleeveIcon',
            applicableGuides: [],
            applicableServices: [],
            applicableAlterations: [],
            views: [
                {
                    label: 'serviceDesk.shared.pinpointStep.views.front',
                    sketchUrl: 'https://ucarecdn.com/4760577f-0b4b-4be7-aaa7-608bdd86d88f/',
                    details: [],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.back',
                    sketchUrl: 'https://ucarecdn.com/466eecf4-85f8-4edf-937e-5898c3d5af58/',
                    details: [],
                },
            ],
        },
        {
            id: 105 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.shorts',
            internalName: 'Shorts',
            Icon: 'ShortsIcon',
            applicableGuides: [],
            applicableServices: [],
            applicableAlterations: [],
            views: [
                {
                    label: 'serviceDesk.shared.pinpointStep.views.front',
                    sketchUrl: 'https://ucarecdn.com/e262e381-d1df-4fd9-9cd0-c7210f42f7eb/',
                    details: [],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.back',
                    sketchUrl: 'https://ucarecdn.com/7a9d021c-b5be-4ebe-918a-d04af266357e/',
                    details: [],
                },
            ],
        },
        {
            id: 106 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.pullover',
            internalName: 'Sweater',
            Icon: 'SweaterIcon',
            applicableGuides: [],
            applicableServices: [],
            applicableAlterations: [],
            views: [
                {
                    label: 'serviceDesk.shared.pinpointStep.views.front',
                    sketchUrl: 'https://ucarecdn.com/2a686085-f78f-4b08-b125-e480fc5fbe8b/',
                    details: [],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.back',
                    sketchUrl: 'https://ucarecdn.com/4cf11879-022e-4492-851d-aa7a2e91ff61/',
                    details: [],
                },
            ],
        },
        {
            id: 107 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.tanktop',
            internalName: 'Tanktop',
            Icon: 'TanktopIcon',
            applicableGuides: [],
            applicableServices: [],
            applicableAlterations: [],
            views: [
                {
                    label: 'serviceDesk.shared.pinpointStep.views.front',
                    sketchUrl: 'https://ucarecdn.com/7798f2b3-03c9-48d6-acb9-70b3d0c26275/',
                    details: [],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.back',
                    sketchUrl: 'https://ucarecdn.com/85d9be69-adb6-4d65-805f-82d62670d034/',
                    details: [],
                },
            ],
        },
        {
            id: 108 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.top',
            internalName: 'Top',
            Icon: 'TopIcon',
            applicableGuides: [],
            applicableServices: [],
            applicableAlterations: [],
            views: [
                {
                    label: 'serviceDesk.shared.pinpointStep.views.front',
                    sketchUrl: 'https://ucarecdn.com/1c0b4c47-230b-4c7c-b9c8-205b412981b1/',
                    details: [],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.back',
                    sketchUrl: 'https://ucarecdn.com/b9022e49-3618-476f-ae0d-8d937af72844/',
                    details: [],
                },
            ],
        },
        {
            id: 109 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.hoodie',
            internalName: 'Hoodie',
            Icon: 'PulloverIcon',
            applicableGuides: [],
            applicableServices: [],
            applicableAlterations: [],
            views: [
                {
                    label: 'serviceDesk.shared.pinpointStep.views.front',
                    sketchUrl: 'https://ucarecdn.com/491688b6-9c70-454a-97ee-26b322c6ee85/',
                    details: [],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.back',
                    sketchUrl: 'https://ucarecdn.com/305fc03a-4fdf-489e-8f07-11b1afa15559/',
                    details: [],
                },
            ],
        },
    ],
}
export default productCategories
