import { SignupForm } from "wasp/client/auth";
import theme from '../../theme'
import styled from 'styled-components'
import AuthLayout from '../../components/layout/auth-layout'

const FormWrapper = styled.div`
    width: 100%;
    max-width: 340px;
`

const appearance = {
    colors: {
        brand: theme.palette.primary.main,
        brandAccent: theme.palette.secondary.main,
        submitButtonText: 'white',
    },
}

const SignupPage = () => {
    if ((import.meta as any).env.REACT_APP_ENV === 'prod') {
        return null
    }
    return (
        <AuthLayout>
            <FormWrapper>
                <SignupForm appearance={appearance} />
            </FormWrapper>
        </AuthLayout>
    )
}

export default SignupPage
