import React from 'react'
import Carousel, { CarouselProps, StateCallBack } from 'react-multi-carousel'
import './carousel.scss'

const responsive = {
    all: {
        breakpoint: { max: 10000, min: 0 },
        items: 1,
    },
}
export type CarouselRef = {
    previous: () => void
    next: () => void
}

type Props = {
    carouselProps?: CarouselProps
    children: React.ReactNode
    afterSlideChange: (dataset: DOMStringMap, oldSlide: number, state: StateCallBack) => void
    slideSelector?: string
}

const SubmissionCarousel = React.forwardRef<CarouselRef, Props>(
    ({ carouselProps, children, afterSlideChange, slideSelector = undefined }, ref) => {
        const carouselRef = React.useRef<Carousel>(null)
        const previous = () => {
            if (!carouselRef.current) return
            carouselRef.current.previous(0)
        }
        const next = () => {
            if (!carouselRef.current) return
            carouselRef.current?.next(0)
        }

        React.useImperativeHandle(
            ref,
            () => ({
                previous,
                next,
            }),
            [carouselRef]
        )

        // Bind keyboard events left and right to previous and next
        React.useEffect(() => {
            const onKeyDown = (event: KeyboardEvent) => {
                if (event.key === 'ArrowLeft') {
                    previous()
                } else if (event.key === 'ArrowRight') {
                    next()
                }
            }
            document.addEventListener('keydown', onKeyDown)
            return () => {
                document.removeEventListener('keydown', onKeyDown)
            }
        }, [previous, next])
        return (
            <Carousel
                draggable={false}
                responsive={responsive}
                showDots={false}
                arrows={false}
                keyBoardControl={false /* already custom defined */}
                infinite={true}
                ref={carouselRef}
                afterChange={(oldSlide, state) =>
                    afterSlideChange(
                        (
                            carouselRef.current?.containerRef.current?.querySelector(
                                slideSelector ?? "[aria-hidden='false'] > *"
                            ) as HTMLElement
                        ).dataset,
                        oldSlide,
                        state
                    )
                }
                {...carouselProps}
            >
                {children}
            </Carousel>
        )
    }
)

export default SubmissionCarousel
