import * as React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import Snackbar from '@mui/material/Snackbar'
import StyledSuccessAlert from '../../../../components/alert/styled-success-alert'
import StyledErrorAlert from '../../../../components/alert/styled-error-alert'
import { useForm } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import PostInspectionStrategy from '../../../../../shared/company/post-inspection-strategy'
import T from '../../../../components/typography/t'

const urlRegex =
    /^(https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/

const validateUrl = (url: string) => {
    if (!url || url.length === 0)
        return 'company.companySettings.settings.customerSettings.postInspectionRedirect.companyPageValidation.required'
    if (!urlRegex.test(url))
        return 'company.companySettings.settings.customerSettings.postInspectionRedirect.companyPageValidation.invalid'
    return undefined
}

type FormData = {
    postInspectionStrategy: number
    allowHappySubmissions: boolean
    allowRemindMeLater: boolean
    allowContactRequests: boolean
    showDashboardAnalytics: boolean
    websiteUrl: string
}

const CustomerSettingsForm: React.FC<
    FormData & {
        canEdit: boolean
        onSave: (data: FormData, onSaved: () => void, onFailed: () => void) => void
    }
> = ({
    postInspectionStrategy,
    allowHappySubmissions,
    allowRemindMeLater,
    allowContactRequests,
    showDashboardAnalytics,
    websiteUrl,
    canEdit,
    onSave,
}) => {
    const [t] = useTranslation()
    const [wasRecentlySaved, setWasRecentlySaved] = React.useState(false)
    const [hasRecentlyFailed, setHasRecentlyFailed] = React.useState(false)
    const [isSaving, setIsSaving] = React.useState(false)

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors, isValid, isDirty },
    } = useForm<FormData>({
        mode: 'onBlur',
        defaultValues: {
            postInspectionStrategy,
            allowHappySubmissions,
            allowRemindMeLater,
            allowContactRequests,
            showDashboardAnalytics,
            websiteUrl,
        },
    })

    const onSubmit = (data: FormData) => {
        setIsSaving(true)
        onSave(
            data,
            () => {
                setIsSaving(false)
                setWasRecentlySaved(true)
                setTimeout(() => setWasRecentlySaved(false), 3000)
            },
            () => {
                setIsSaving(false)
                setHasRecentlyFailed(true)
                setTimeout(() => setHasRecentlyFailed(false), 3000)
            }
        )
    }

    return (
        <>
            <Box mb={2}>
                <T
                    i18nKey="company.companySettings.settings.customerSettings.postInspectionRedirect.title"
                    variant="h5"
                    component="h2"
                />
                <T
                    i18nKey="company.companySettings.settings.customerSettings.postInspectionRedirect.lead"
                    variant="body2"
                    component="p"
                />
                <FormControl sx={{ mt: 2 }}>
                    <InputLabel id="redirect-after-inspection-label">
                        <T i18nKey="company.companySettings.settings.customerSettings.postInspectionRedirect.label" />
                    </InputLabel>
                    <Select
                        {...register('postInspectionStrategy')}
                        value={watch('postInspectionStrategy')}
                        disabled={!canEdit}
                        labelId="redirect-after-inspection-label"
                        label="Weiterleitung nach Inspection"
                    >
                        <MenuItem value={PostInspectionStrategy.Redirect}>
                            <T i18nKey="company.companySettings.settings.customerSettings.postInspectionRedirect.companyPage" />
                        </MenuItem>
                        <MenuItem value={PostInspectionStrategy.Restart}>
                            <T i18nKey="company.companySettings.settings.customerSettings.postInspectionRedirect.restart" />
                        </MenuItem>
                    </Select>
                </FormControl>

                {watch('postInspectionStrategy') === PostInspectionStrategy.Redirect && (
                    <TextField
                        fullWidth
                        {...register('websiteUrl', { validate: validateUrl })}
                        label={t(
                            'company.companySettings.settings.customerSettings.postInspectionRedirect.companyPage' as any
                        )}
                        disabled={!canEdit}
                        error={!!errors.websiteUrl}
                        sx={{ mt: 2 }}
                        helperText={
                            !!errors.websiteUrl ? t(errors.websiteUrl.message as any) : null
                        }
                    />
                )}
            </Box>
            <Box mb={2}>
                <T
                    i18nKey="company.companySettings.settings.customerSettings.allowHappySubmissions.title"
                    variant="h5"
                    component="h2"
                />
                <T
                    i18nKey="company.companySettings.settings.customerSettings.allowHappySubmissions.lead"
                    variant="body2"
                    component="p"
                />
                <FormControlLabel
                    control={
                        <Switch
                            disabled={!canEdit}
                            checked={watch('allowHappySubmissions') ?? allowHappySubmissions}
                            {...register('allowHappySubmissions')}
                        />
                    }
                    label={
                        watch('allowHappySubmissions')
                            ? t(
                                  'company.companySettings.settings.customerSettings.allowHappySubmissions.enabled' as any
                              )
                            : t(
                                  'company.companySettings.settings.customerSettings.allowHappySubmissions.disabled' as any
                              )
                    }
                />
            </Box>
            <Box mb={2}>
                <T
                    i18nKey="company.companySettings.settings.customerSettings.allowRemindMeLater.title"
                    variant="h5"
                    component="h2"
                />
                <T
                    i18nKey="company.companySettings.settings.customerSettings.allowRemindMeLater.lead"
                    variant="body2"
                    component="p"
                />
                <FormControlLabel
                    control={
                        <Switch
                            disabled={!canEdit}
                            checked={watch('allowRemindMeLater') ?? allowRemindMeLater}
                            {...register('allowRemindMeLater')}
                        />
                    }
                    label={
                        watch('allowRemindMeLater')
                            ? t(
                                  'company.companySettings.settings.customerSettings.allowRemindMeLater.enabled' as any
                              )
                            : t(
                                  'company.companySettings.settings.customerSettings.allowRemindMeLater.disabled' as any
                              )
                    }
                />
            </Box>
            <Box mb={2}>
                <T
                    i18nKey="company.companySettings.settings.customerSettings.allowContactRequests.title"
                    variant="h5"
                    component="h2"
                />
                <T
                    i18nKey="company.companySettings.settings.customerSettings.allowContactRequests.lead"
                    variant="body2"
                    component="p"
                />
                <FormControlLabel
                    control={
                        <Switch
                            disabled={!canEdit}
                            checked={watch('allowContactRequests') ?? allowContactRequests}
                            {...register('allowContactRequests')}
                        />
                    }
                    label={
                        watch('allowContactRequests')
                            ? t(
                                  'company.companySettings.settings.customerSettings.allowContactRequests.enabled' as any
                              )
                            : t(
                                  'company.companySettings.settings.customerSettings.allowContactRequests.disabled' as any
                              )
                    }
                />
            </Box>
            <Box>
                <T
                    i18nKey="company.companySettings.settings.customerSettings.showDashboardAnalytics.title"
                    variant="h5"
                    component="h2"
                />
                <T
                    i18nKey="company.companySettings.settings.customerSettings.showDashboardAnalytics.lead"
                    variant="body2"
                    component="p"
                />
                <FormControlLabel
                    control={
                        <Switch
                            disabled={!canEdit}
                            checked={watch('showDashboardAnalytics') ?? showDashboardAnalytics}
                            {...register('showDashboardAnalytics')}
                        />
                    }
                    label={
                        watch('showDashboardAnalytics')
                            ? t(
                                  'company.companySettings.settings.customerSettings.showDashboardAnalytics.enabled' as any
                              )
                            : t(
                                  'company.companySettings.settings.customerSettings.showDashboardAnalytics.disabled' as any
                              )
                    }
                />
            </Box>
            <Box mt={1}>
                <Button
                    disabled={!canEdit || isSaving || !isValid}
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                >
                    {isSaving
                        ? t('company.companySettings.settings.saving' as any)
                        : t('company.companySettings.settings.save' as any)}
                </Button>
            </Box>
            <Snackbar
                open={wasRecentlySaved}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <StyledSuccessAlert>
                    <T i18nKey="company.companySettings.settings.success" />
                </StyledSuccessAlert>
            </Snackbar>
            <Snackbar
                open={hasRecentlyFailed}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <StyledErrorAlert severity="error">
                    <T i18nKey="company.companySettings.settings.error" />
                </StyledErrorAlert>
            </Snackbar>
        </>
    )
}

export default CustomerSettingsForm
