import React from 'react'
import Intro from './steps/intro'
import PinpointStep from './steps/pinpoint-step'
import PhotoStep from './steps/photo-step'
import DescriptionStep from './steps/description-step'
import ContextDescriptionStep from './steps/context-description-step'
import { UploadClient } from '@uploadcare/upload-client'
import {
    ErrorCtx,
    Form,
    FormValues,
    InspectorMultiStepFormContextType,
    Meta,
    MultiStepFormError,
    StepCtx,
    StepProps,
} from './types'
import Outro from './steps/outro'
import { useAnalytics } from 'use-analytics'
import { InspectorPageViewMobileEvent } from '../../../../shared/analytics/index'
import useInspectorEmailcookie from '../email-cookie'
import {
    BrandLogo,
    Content,
    Footer,
    Header,
    Page,
    PBB,
    PoweredBy,
    PoweredByBrakeable,
    ProgressBar,
    WidthContainer,
} from '../../../components/layout'
import LanguageSwitcher from '../../poc/language-switcher'
import brakeableWhitelabel from '../../../../shared/data/whitelabel/brakeable-whitelabel'

let slides: Array<() => JSX.Element> = [
    () => <Intro />,
    () => <PinpointStep />,
    () => <PhotoStep />,
    () => <DescriptionStep />,
    () => <ContextDescriptionStep />,
    () => <Outro />,
]

const defaultContextValues: InspectorMultiStepFormContextType = {
    ctx: {} as StepCtx,
    props: {} as StepProps,
    form: {} as Form,
    meta: {} as Meta,
    error: {} as ErrorCtx,
    uploadCareClient: {} as UploadClient,
}

export const InspectorMultiStepFormContext =
    React.createContext<InspectorMultiStepFormContextType>(defaultContextValues)

const InspectorMultiStepForm = ({
    onSubmit,
    source,
    uploadCareClient,
    ...ctx
}: StepCtx & {
    onSubmit: (formValues: FormValues, anonymousUserId: string) => Promise<void>
    source: string
    uploadCareClient: UploadClient
}) => {
    const analytics = useAnalytics()
    const [getEmailAddressFromCookies] = useInspectorEmailcookie()
    const [meta, setMeta] = React.useState<any>({})
    const [slideIndex, setSlideIndex] = React.useState(0)
    const [errors, setErrors] = React.useState<MultiStepFormError[]>([])
    const [formValues, setFormValues] = React.useState<FormValues>({
        inspectionImageUrl: undefined,
        inspectionImagePinpoint: undefined,
        inspectionDescription: undefined,
        inspectionContextDescription: undefined,
        inspectionEmail: getEmailAddressFromCookies() ?? '',
        followUpQuestion: undefined,
    })
    const nextSlide = () => setSlideIndex(Math.min(slideIndex + 1, maxSteps - 1))
    const prevSlide = () => setSlideIndex(Math.max(slideIndex - 1, 0))
    const setSlide = (index: number) => setSlideIndex(index)
    const maxSteps = slides.length
    const gotoEnd = () => setSlideIndex(maxSteps - 1)
    const currentStep = slideIndex

    const setInspectionImageUrl = (url?: string) =>
        setFormValues((v) => ({ ...v, inspectionImageUrl: url }))
    const setInspectionImagePinpoint = (pinpoint: { x: number; y: number }) => {
        setFormValues((v) => ({ ...v, inspectionImagePinpoint: pinpoint }))
    }
    const setInspectionDescription = (description?: string) => {
        setFormValues((v) => ({ ...v, inspectionDescription: description }))
    }
    const setInspectionContextDescription = (contextDescription?: string) => {
        setFormValues((v) => ({ ...v, inspectionContextDescription: contextDescription }))
    }
    const setInspectionEmail = (email: string) => {
        setFormValues((v) => ({ ...v, inspectionEmail: email }))
    }
    const setContactRequested = (flag: boolean) => {
        setFormValues((v) => ({ ...v, contactRequested: flag }))
    }
    const submitForm = async () => {
        const user = analytics.user()
        await onSubmit(formValues, user.anonymousId)
    }
    const pushError = (error: MultiStepFormError) => {
        setErrors((errors) => [...errors, error])
    }
    const popError = () => {
        setErrors((errors) => errors.slice(0, errors.length - 1))
    }

    React.useEffect(() => {
        analytics.track(InspectorPageViewMobileEvent, {
            questionaireUuid: ctx.questionaireUuid,
            source,
        })
    }, [])

    return (
        <InspectorMultiStepFormContext.Provider
            value={{
                ctx: ctx,
                props: {
                    currentStep,
                    maxSteps,
                    nextSlide,
                    prevSlide,
                    setSlide,
                    gotoEnd,
                },
                form: {
                    setInspectionImageUrl,
                    setInspectionImagePinpoint,
                    setInspectionDescription,
                    setInspectionContextDescription,
                    setInspectionEmail,
                    setRequestContact: setContactRequested,
                    submitForm,
                    formValues,
                },
                meta: {
                    value: meta,
                    setMetaValue: setMeta,
                },
                error: {
                    errors,
                    pushError,
                    popError,
                },
                uploadCareClient,
            }}
        >
            <Page>
                <Header>
                    <WidthContainer sx={{ justifyContent: 'space-between', maxWidth: 1200 }}>
                        <BrandLogo src={brakeableWhitelabel.logo} alt="logo" />
                        <LanguageSwitcher />
                    </WidthContainer>
                </Header>
                <ProgressBar $progress={currentStep / (maxSteps - 1)} />
                <Content sx={{ mt: 1, mb: 4 }}>{slides[slideIndex]()}</Content>
                <Footer>
                    <WidthContainer sx={{ justifyContent: 'flex-end', maxWidth: 1200 }}>
                        <PBB>
                            <PoweredBy>Powered by</PoweredBy>{' '}
                            <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                        </PBB>
                    </WidthContainer>
                </Footer>
            </Page>
        </InspectorMultiStepFormContext.Provider>
    )
}

export default InspectorMultiStepForm
