import styled from 'styled-components'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Submission from '../../../../../shared/types/product/dashboard-submission'
import theme from '../../../../theme'
import ImageMarker from '../../../../components/image-marker'
import createUploadcareImageSrc from '../../../../mixins/create-uploadcare-image-src'
import T from '../../../../components/typography/t'
import RoleProtector from '../../../../components/auth/role-protector'
import { Role } from '../../../../../shared/auth/role'
import SubmissionId from '../../../../../shared/types/submission/id'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'

const Wrapper = styled.div`
    display: flex;
    max-height: 700px;
`

const LeftSide = styled.div`
    width: 50%;
    padding: 0 20px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const SubmissionContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
`

const Description = styled.p`
    margin: 0;
    font-size: 1.2rem;
    font-style: italic;
    color: ${() => theme.palette.grey[500]};

    &::before {
        content: '"';
    }

    &::after {
        content: '"';
    }
`

const Email = styled.div`
    margin-top: 1em;
    font-size: 0.8em;
`

const EmailLink = styled.a`
    color: ${theme.palette.grey[500]};
    text-decoration: none;

    &:hover {
        color: ${theme.palette.grey[800]};
        text-decoration: underline;
    }
`

const MarkerWrapper = styled.div`
    max-width: 200px;
    max-height: 200px;
    align-self: center;
`

const RightSide = styled.div`
    width: 50%;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const DefectImage = styled.img`
    max-width: 100%;
    max-height: 100%;
`

const DesktopSubmissionDetailContent = ({
    submission,
    productImgUrl,
    deleteSubmission,
}: {
    submission: Submission
    productImgUrl: string
    deleteSubmission?: (submissionId: SubmissionId) => void
}) => {
    return (
        <Wrapper>
            <LeftSide>
                <SubmissionContent>
                    <T
                        i18nKey="company.inspector.productDashboard.detail.defectDescription"
                        variant="h6"
                        component="h2"
                    />
                    <Description>
                        {submission.description ?? (
                            <T i18nKey="company.inspector.productDashboard.noDescription" />
                        )}
                    </Description>
                    <T
                        i18nKey="company.inspector.productDashboard.detail.contextDescription"
                        variant="h6"
                        component="h2"
                        mt={2}
                    />
                    <Description>
                        {submission.contextDescription ?? (
                            <T i18nKey="company.inspector.productDashboard.noDescription" />
                        )}
                    </Description>

                    {!!submission.email && (
                        <Email>
                            <T i18nKey="company.inspector.productDashboard.detail.contact" />{' '}
                            <EmailLink href={`mailto:${submission.email}`}>
                                {submission.email}
                            </EmailLink>
                            <IconButton size="small" color="primary" sx={{ ml: 1 }}>
                                <QuestionAnswerIcon />
                            </IconButton>
                        </Email>
                    )}
                    <Box display="flex" flexWrap="wrap" mt={1}>
                        {submission.labels.map((label) => (
                            <Chip
                                sx={{ marginRight: theme.spacing(1) }}
                                label={label.value}
                                key={label.id}
                                color="primary"
                            />
                        ))}
                    </Box>
                </SubmissionContent>
                <MarkerWrapper>
                    <ImageMarker
                        markers={[{ id: submission.id, ...submission.imagePinpoint }]}
                        imageUrl={createUploadcareImageSrc(productImgUrl, { preview: '400x400' })}
                        highlightedSubmissionId={{ id: submission.id, source: 'marker' }}
                    />
                </MarkerWrapper>
            </LeftSide>
            <RightSide>
                <DefectImage
                    src={createUploadcareImageSrc(submission.image, { preview: '900x1200' })}
                />
            </RightSide>
            {deleteSubmission && (
                <RoleProtector roles={[Role.Admin]}>
                    <Box sx={{ position: 'absolute', right: '10px', bottom: '10px' }}>
                        <IconButton
                            color={'error'}
                            onClick={() => deleteSubmission(submission.id)}
                            size="small"
                        >
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    </Box>
                </RoleProtector>
            )}
        </Wrapper>
    )
}

export default DesktopSubmissionDetailContent
