import React from 'react'
import { useSocket, useSocketListener } from 'wasp/client/webSocket'
import T from '../../../../components/typography/t'
import MobilePhotoInput from '../components/photo-input/mobile-photo-input'
import PhotoDragDrop from '../components/photo-input/photo-drag-drop'
import QrMobilePhotoUpload from '../components/photo-input/qr-mobile-photo-upload'
import { UploadClient } from '@uploadcare/upload-client'
import { Stack } from '@mui/material'
import PhotoPreview from '../components/photo-preview'
import LabelledDelimiter from '../components/labelled-delimiter'
import useUploadCareUpload from '../helper/use-upload-care-upload'

export type UploadedFileInfo = { name: string; size: number }

export type PhotoStepHandle = { goNextInterceptor: () => void }

export const PhotoStep = React.forwardRef<
    PhotoStepHandle,
    {
        brandId: string
        brandName: string
        setOriginalImageUrl: (url?: string) => void
        onPhotoUploadFailed: (msg: string) => void
        setUploadProgress: (progression: number) => void
        setUploadedFileInfo: (info?: UploadedFileInfo) => void
        uploadCareClient: UploadClient
        uploadedPhotoUrl?: string
        uploadProgress?: number
        uploadedFileInfo?: UploadedFileInfo
        mobileUploadId: string
        mobilePhotoUploadStarted: boolean
        setMobilePhotoUploadStarted: (started: boolean) => void
    }
>(
    (
        {
            brandId,
            brandName,
            setOriginalImageUrl,
            onPhotoUploadFailed,
            setUploadProgress,
            setUploadedFileInfo,
            uploadCareClient,
            uploadedPhotoUrl,
            mobileUploadId,
            uploadProgress = 0,
            uploadedFileInfo = undefined,
            mobilePhotoUploadStarted,
            setMobilePhotoUploadStarted,
        },
        ref
    ) => {
        const isMobile = screen.width < 800
        const { socket, isConnected } = useSocket()

        const resetUpload = () => {
            setUploadProgress(0)
            setUploadedFileInfo(undefined)
            setOriginalImageUrl(undefined)
        }

        const choosePhoto = useUploadCareUpload({
            setUploadProgress,
            setUploadedFileInfo,
            onPhotoUploadFailed,
            setImageUrl: setOriginalImageUrl,
            resetUpload,
            uploadCareClient,
        })

        const onMobilePhotoUploadStarted = ({
            photoName,
            photoSize,
        }: {
            photoName: string
            photoSize: number
        }) => {
            resetUpload()
            setUploadProgress(0.1)
            setUploadedFileInfo({ name: photoName, size: photoSize })
            setMobilePhotoUploadStarted(true)
        }

        const setPhotoFromMobile = ({
            photoUrl,
            photoName,
            photoSize,
        }: {
            photoUrl: string
            photoName: string
            photoSize: number
        }) => {
            resetUpload()
            setOriginalImageUrl(photoUrl)
            setUploadedFileInfo({ name: photoName, size: photoSize })
            setUploadProgress(1)
            setMobilePhotoUploadStarted(false)
        }

        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const photo = e.target.files?.[0]
            choosePhoto(photo)
        }

        React.useEffect(() => {
            if (!isConnected) {
                socket.connect()
            }

            return () => {
                if (isConnected) {
                    socket.disconnect()
                }
            }
        }, [])

        const goNextInterceptor = () => {
            if (isConnected) {
                socket.emit('disconnectMobileUploadRoom', mobileUploadId)
            }
        }

        React.useImperativeHandle(ref, () => ({
            goNextInterceptor,
        }))

        React.useEffect(() => {
            if (isConnected) {
                socket.emit('joinMobileUploadRoom', mobileUploadId)
            }
        }, [isConnected])

        useSocketListener('photoUploadStarted', ({ photoName, photoSize }) => {
            onMobilePhotoUploadStarted({ photoName, photoSize })
        })
        useSocketListener('photoUploaded', ({ photoUrl, photoName, photoSize }) => {
            setPhotoFromMobile({ photoUrl, photoName, photoSize })
        })

        return (
            <>
                {!uploadedPhotoUrl && uploadProgress === 0 && (
                    <Stack
                        spacing={2}
                        direction="row"
                        width="100%"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {isMobile ? (
                            <>
                                <MobilePhotoInput chosePhoto={onChange} />
                            </>
                        ) : (
                            <Stack width="100%" gap={4}>
                                <PhotoDragDrop choosePhoto={choosePhoto} />

                                <LabelledDelimiter>
                                    <T component="p" i18nKey="common.or" />
                                </LabelledDelimiter>
                                <QrMobilePhotoUpload
                                    mobileUploadId={mobileUploadId}
                                    brandId={brandId}
                                    brandName={brandName}
                                />
                            </Stack>
                        )}
                    </Stack>
                )}
                {(uploadProgress ?? 0) > 0 && (
                    <PhotoPreview
                        uploadedPhotoUrl={uploadedPhotoUrl}
                        uploadedFileInfo={uploadedFileInfo}
                        uploadProgress={uploadProgress}
                        progressUnknown={mobilePhotoUploadStarted}
                        resetUpload={resetUpload}
                    />
                )}
            </>
        )
    }
)

export default PhotoStep
