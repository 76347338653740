import repairServices from '../repair-services/victorinox-support-services'
import repairGuides from '../repair-guides/fake-victorinox-repair-guides'
import productCategoryTree from '../products/victorinox-products'
import {
    Field,
    FieldType,
    WarrantyInformationStepType,
    WhitelabelStatus,
    type Whitelabel,
} from './types'

const contactFields: Field[][] = [
    [
        {
            key: 'Firstname',
            label: 'serviceDesk.shared.contactStep.form.firstnameLabel',
            type: FieldType.TextField,
            params: { required: true },
        },
        {
            key: 'Lastname',
            label: 'serviceDesk.shared.contactStep.form.lastnameLabel',
            type: FieldType.TextField,
            params: { required: true },
        },
    ],
    [
        {
            key: 'Email',
            label: 'serviceDesk.shared.contactStep.form.emailLabel',
            type: FieldType.TextField,
            params: { required: true },
            fieldProps: { type: 'email' },
        },
    ],
    [
        {
            key: 'Address',
            label: 'serviceDesk.shared.contactStep.form.addressLabel',
            type: FieldType.TextField,
            params: { required: true },
        },
    ],
    [
        {
            key: 'ZipCode',
            label: 'serviceDesk.shared.contactStep.form.zipCodeLabel',
            type: FieldType.TextField,
            params: { required: true },
            fieldProps: {
                type: 'number',
                fieldControlSx: { width: 150, flexShrink: 0 },
            },
        },
        {
            key: 'City',
            label: 'serviceDesk.shared.contactStep.form.cityLabel',
            type: FieldType.TextField,
            params: { required: true },
        },
    ],
]

/*
    TODO:
    - Adjust ID
    - Adjust name (variable and brand name)
    - Adjust theme
    - Adjust logo
    - Adjust repair guides DE / EN
    - Adjust repair services DE / EN
    - Define product category tree
    - Define outro links
    - Adjust plugin config (email recipients)
*/
const victorinoxWhitelabel: Whitelabel = {
    id: '409p8d',
    status: WhitelabelStatus.ExternalDemoAccess,
    name: 'Victorinox',
    theme: {
        palette: {
            primary: {
                main: '#B20034',
                contrastText: '#fff',
            },
            secondary: {
                main: '#B20034',
                contrastText: '#fff',
            },
            getCrosshairColors: () => ({ stroke: '#B20034', fill: '#fff' }),
            getIllustrationColors: () => ({
                primary: '#343434',
                accent: '#B20034',
            }),
            getProgressbarColor: () => '#B20034',
            getTextColor: () => '#343434',
        },
    },
    logo: 'https://ucarecdn.com/d17de5c6-eebd-42ea-b6dd-bfc04bf52054/',
    repairGuides: repairGuides,
    repairServices: repairServices,
    alterationServices: [],
    productCategoryTree: productCategoryTree,
    config: {
        repair: {
            storeFinderIframeUrl:
                'https://brandwidgets.outtra.com/brand-widget/vaude-23/shop-map/?outtrabrandId=158&language=',
            contactForm: {
                fields: contactFields,
            },
            activateCostEstimation: true,
            includeDiyGuides: true,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [
                    {
                        href: '//www.victorinox.com',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: ['marc@brakeable.com', 'stefan@brakeable.com'],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
        warranty: {
            contactForm: {
                fields: contactFields,
            },
            warrantyInformationSteps: [
                {
                    fieldKey: 'proofOfPurchase',
                    type: WarrantyInformationStepType.Photo,
                    photo: {
                        key: 'proofOfPurchasePhoto',
                        label: 'serviceDesk.warranty.warrantyInformationSteps.warrantyInformationPhotoStep.label',
                        options: { required: true },
                    },
                    alternativeFields: [
                        [
                            {
                                key: 'Orderreference',
                                label: 'serviceDesk.warranty.purchaseConfirmationStep.form.orderReferenceLabel',
                                type: FieldType.TextField,
                                params: { required: true },
                            },
                        ],
                        [
                            {
                                key: 'ArticleNumber',
                                label: 'serviceDesk.warranty.purchaseConfirmationStep.form.articleNumberLabel',
                                type: FieldType.TextField,
                                params: { required: true },
                            },
                        ],
                    ],
                    required: false,
                    translations: {
                        title: 'serviceDesk.warranty.purchaseConfirmationStep.linktree.title',
                        photoTitle:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.photoTitle',
                        photoSubtitle:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.photoSubtitle',
                        textTitle:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.manualTitle',
                        textSubtitle:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.manualSubtitle',
                        notAvailableQuestion:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.notAvailable.delimiter',
                        notAvailableAction:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.notAvailable.button',
                    },
                },
            ],
            activateWarrantyEstimation: false,
            outro: {
                lead: 'serviceDesk.warranty.outroStep.subtitle',
                links: [
                    {
                        href: '//www.victorinox.com',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: ['marc@brakeable.com', 'stefan@brakeable.com'],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
        ufixit: {
            outro: {
                assistanceIframeUrl:
                    'https://brandwidgets.outtra.com/brand-widget/vaude-23/shop-map/?outtrabrandId=158&language=',
            },
        },
    },
}

export default victorinoxWhitelabel
