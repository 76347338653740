import { useAuth as useAuthWasp } from 'wasp/client/auth'
import { isAdmin, isCompanyRepresentative, isLoggedIn } from '../../shared/auth/role-helpers'
import { Role } from '../../shared/auth/role'

const useAuth = () => {
    const { data: u } = useAuthWasp()
    const user = u ?? { role: Role.Unknown }

    return {
        user: u,
        isAdmin: isAdmin(user),
        isCompanyRepresentative: isCompanyRepresentative(user),
        isLoggedIn: isLoggedIn(user),
    }
}

export default useAuth
