import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { SupportMultiStepFormContext } from '../../context'
import useFakeFeatures from '../../helper/use-fake-features'
import InspectorStepTitle from '../../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../components/step-framework-wrapper'
import { useSocket, useSocketListener } from 'wasp/client/webSocket'
import useCookie from '../../../../../../mixins/use-cookie'
import T from '../../../../../../components/typography/t'
import { MobilePhotoInput, PhotoDragDrop, QrMobilePhotoUpload } from './photo-input'
import { useTranslation } from 'react-i18next'
import { UploadClient } from '@uploadcare/upload-client'
import { Box, IconButton, Stack, useTheme, styled, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import createUploadcareImageSrc from '../../../../../../mixins/create-uploadcare-image-src'
import LinearProgress from '@mui/material/LinearProgress'
// @ts-ignore
import PhotoIcon from '../../../../../../assets/img/photo.svg?react'

const PreviewImg = styled('img')`
    max-width: 100%;
    max-height: 100%;
`

const PreviewBox = styled('div')`
    padding: ${({ theme }) => theme.spacing(4)};
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing(4)};
    width: 100%;
`

const PhotoPlaceholder = styled('div')`
    width: 72px;
    height: 72px;
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
`

const PreviewPhotoIcon = styled(PhotoIcon)`
    width: 60%;
    height: 60%;
    fill: ${({ theme }) => theme.palette.primary.main};
`

const PreviewContent = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 1;
    color: ${({ theme }) => theme.palette.primary.main};
`

const DeleteWrapper = styled('div')`
    width: 50px;
    flex-shrink: 0;
`

const LabelledDelimiter = styled('div')`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;

    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 0;
        top: 50%;
        left: 0;
        border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
        z-index: -1;
    }
`

const DelimiterLabel = styled(T)`
    color: ${({ theme }) => theme.palette.primary.main};
    padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
    background: #fff;
    width: auto;
    text-transform: uppercase;
`

type UploadedFileInfo = { name: string; size: number }

type PhotoStepHandle = { goNextInterceptor: () => void }

export const PhotoStep = React.forwardRef<
    PhotoStepHandle,
    {
        onPhotoChosen: () => void
        brandId: string
        setOriginalImageUrl: (url?: string) => void
        onPhotoUploadFailed: (msg: string) => void
        setUploadProgress: (progression: number) => void
        setUploadedFileInfo: (info?: UploadedFileInfo) => void
        uploadCareClient: UploadClient
        uploadedPhotoUrl?: string
        uploadProgress?: number
        uploadedFileInfo?: UploadedFileInfo
    }
>(
    (
        {
            onPhotoChosen,
            brandId,
            setOriginalImageUrl,
            onPhotoUploadFailed,
            setUploadProgress,
            setUploadedFileInfo,
            uploadCareClient,
            uploadedPhotoUrl,
            uploadProgress = 0,
            uploadedFileInfo = undefined,
        },
        ref
    ) => {
        const isMobile = screen.width < 800
        const showFakeFeatures = useFakeFeatures()
        const [mobilePhotoUploadStarted, setMobilePhotoUploadStarted] =
            React.useState<boolean>(false)
        const { socket, isConnected } = useSocket()
        const [getMobileUploadIdFromCookies, setMobileUploadIdToCookies] =
            useCookie('mobile-photo-upload-id')
        const mobileUploadId =
            getMobileUploadIdFromCookies() ??
            setMobileUploadIdToCookies(uuidv4(), { expires: 1 / 96 })

        const onProgress = ({ isComputable, value }: any) => {
            if (isComputable) {
                setUploadProgress(Math.min(value, 0.99))
            }
        }

        const choosePhoto = (photo?: File) => {
            if (!photo) {
                return
            }
            resetUpload()
            setUploadedFileInfo({ name: photo.name, size: photo.size })
            uploadCareClient
                .uploadFile(photo, { onProgress })
                .then(async (file) => {
                    if (file.cdnUrl === null) {
                        onPhotoUploadFailed('Uploadcare returned null CDN URL')
                    } else {
                        setOriginalImageUrl(file.cdnUrl!)
                        setUploadProgress(1)
                    }
                })
                .catch(onPhotoUploadFailed)
            // onPhotoChosen()
        }

        const onMobilePhotoUploadStarted = ({
            photoName,
            photoSize,
        }: {
            photoName: string
            photoSize: number
        }) => {
            resetUpload()
            setUploadProgress(0.1)
            setUploadedFileInfo({ name: photoName, size: photoSize })
            setMobilePhotoUploadStarted(true)
        }

        const setPhotoFromMobile = ({
            photoUrl,
            photoName,
            photoSize,
        }: {
            photoUrl: string
            photoName: string
            photoSize: number
        }) => {
            resetUpload()
            setOriginalImageUrl(photoUrl)
            setUploadedFileInfo({ name: photoName, size: photoSize })
            setUploadProgress(1)
            setMobilePhotoUploadStarted(false)
        }

        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const photo = e.target.files?.[0]
            choosePhoto(photo)
        }

        const resetUpload = () => {
            setUploadProgress(0)
            setUploadedFileInfo(undefined)
            setOriginalImageUrl(undefined)
        }

        const fakeUpload = () => {
            setUploadProgress(0.1)
            setUploadedFileInfo({ name: 'photo.png', size: 23000 })
            let interactiveProgress = uploadProgress
            const timer = setInterval(() => {
                interactiveProgress = Math.min(interactiveProgress + Math.random() / 5, 1)
                setUploadProgress(interactiveProgress)
                if (interactiveProgress >= 1) {
                    finishUpload()
                }
            }, 100)
            const finishUpload = () => {
                setOriginalImageUrl('https://ucarecdn.com/3ce78ad8-588b-4ccc-bd73-dde7c343f6a9/')
                setUploadProgress(1)
                clearInterval(timer)
                // onPhotoChosen()
            }
        }

        React.useEffect(() => {
            if (!isConnected) {
                socket.connect()
            }

            return () => {
                if (isConnected) {
                    socket.disconnect()
                }
            }
        }, [])

        const goNextInterceptor = () => {
            if (isConnected) {
                socket.emit('disconnectMobileUploadRoom', mobileUploadId)
            }
        }

        React.useImperativeHandle(ref, () => ({
            goNextInterceptor,
        }))

        React.useEffect(() => {
            if (isConnected) {
                socket.emit('joinMobileUploadRoom', mobileUploadId)
            }
        }, [isConnected])

        useSocketListener('photoUploadStarted', ({ photoName, photoSize }) => {
            onMobilePhotoUploadStarted({ photoName, photoSize })
        })
        useSocketListener('photoUploaded', ({ photoUrl, photoName, photoSize }) => {
            setPhotoFromMobile({ photoUrl, photoName, photoSize })
        })

        return (
            <>
                <InspectorStepTitle i18nKey="poc.support.PhotoStep.title" mb={4} />
                {!uploadedPhotoUrl && uploadProgress === 0 && (
                    <Stack
                        spacing={2}
                        direction="row"
                        width="100%"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {isMobile ? (
                            <>
                                <MobilePhotoInput chosePhoto={onChange} />
                            </>
                        ) : (
                            <Stack width="100%" gap={4}>
                                <PhotoDragDrop
                                    choosePhoto={choosePhoto}
                                    showFakeFeatures={showFakeFeatures}
                                    fakeUpload={fakeUpload}
                                />

                                <LabelledDelimiter>
                                    <DelimiterLabel i18nKey="common.or" />
                                </LabelledDelimiter>
                                <QrMobilePhotoUpload
                                    mobileUploadId={mobileUploadId}
                                    brandId={brandId}
                                />
                            </Stack>
                        )}
                    </Stack>
                )}
                {(uploadProgress ?? 0) > 0 && (
                    <PreviewBox>
                        <PhotoPlaceholder>
                            {!!uploadedPhotoUrl ? (
                                <PreviewImg
                                    src={createUploadcareImageSrc(uploadedPhotoUrl, {
                                        preview: '380x600',
                                    })}
                                />
                            ) : (
                                <PreviewPhotoIcon />
                            )}
                        </PhotoPlaceholder>
                        <PreviewContent>
                            {!!uploadedFileInfo && (
                                <>
                                    <Typography sx={{ fontWeight: 600, mb: 0 }}>
                                        {uploadedFileInfo.name}
                                    </Typography>
                                    <Typography sx={{ mb: 1 }}>
                                        {Math.floor(uploadedFileInfo.size / 1000)} Kb
                                    </Typography>
                                </>
                            )}
                            {uploadProgress < 1 && (
                                <LinearProgress
                                    variant={mobilePhotoUploadStarted ? undefined : 'determinate'}
                                    value={
                                        mobilePhotoUploadStarted ? undefined : uploadProgress * 100
                                    }
                                />
                            )}
                        </PreviewContent>
                        <DeleteWrapper>
                            {!!uploadedPhotoUrl && (
                                <IconButton onClick={resetUpload}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </DeleteWrapper>
                    </PreviewBox>
                )}
            </>
        )
    }
)

const PhotoStepContext = () => {
    const childRef = React.useRef<PhotoStepHandle>(null)
    const { props, form, meta, error, uploadCareClient, ctx } = React.useContext(
        SupportMultiStepFormContext
    )
    const onPhotoChosen = () => {
        props.nextSlide()
    }

    const onPhotoUploadFailed = (err: any) => {
        console.error(err)
        meta.setMetaValue({ ...meta, fileUpload: { progress: 0 } })
        error.pushError({
            message: 'Foto konnte nicht hochgeladen werden',
            action: {
                label: 'Erneut versuchen',
                onClick: () => {
                    error.popError()
                    props.setSlide(0)
                },
            },
        })
    }

    const setUploadProgress = (progression: number) => {
        meta.setMetaValue((m: any) => ({ ...m, fileUpload: { progress: progression } }))
    }

    const setUploadedFileInfo = (info?: UploadedFileInfo) => {
        meta.setMetaValue((m: any) => ({ ...m, uploadedFileInfo: info }))
    }

    return (
        <StepFrameworkWrapper
            navigationProps={{
                nextButtonDisabled:
                    !form.formValues.uploadedPhotoUrl && !meta.value?.fileUpload?.progress,
                onValidNextClick: () => {
                    childRef.current?.goNextInterceptor()
                    props.nextSlide()
                },
            }}
        >
            <PhotoStep
                ref={childRef}
                onPhotoChosen={onPhotoChosen}
                setOriginalImageUrl={form.setUploadedPhotoUrl}
                onPhotoUploadFailed={onPhotoUploadFailed}
                setUploadProgress={setUploadProgress}
                setUploadedFileInfo={setUploadedFileInfo}
                uploadProgress={meta.value?.fileUpload?.progress}
                uploadedFileInfo={meta.value?.uploadedFileInfo}
                uploadedPhotoUrl={form.formValues.uploadedPhotoUrl}
                uploadCareClient={uploadCareClient}
                brandId={ctx.whitelabel.id}
            />
        </StepFrameworkWrapper>
    )
}

export default PhotoStepContext
