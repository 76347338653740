export default [
    {
        id: '1',
        ort: 'Aach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78267',
    },
    {
        id: '2',
        ort: 'Aach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54298',
    },
    {
        id: '3',
        ort: 'Aachen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52062',
    },
    {
        id: '4',
        ort: 'Aachen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52064',
    },
    {
        id: '5',
        ort: 'Aachen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52066',
    },
    {
        id: '6',
        ort: 'Aachen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52068',
    },
    {
        id: '7',
        ort: 'Aachen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52070',
    },
    {
        id: '8',
        ort: 'Aachen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52072',
    },
    {
        id: '9',
        ort: 'Aachen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52074',
    },
    {
        id: '10',
        ort: 'Aachen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52076',
    },
    {
        id: '11',
        ort: 'Aachen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52078',
    },
    {
        id: '12',
        ort: 'Aachen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52080',
    },
    {
        id: '13',
        ort: 'Aalen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73430',
    },
    {
        id: '14',
        ort: 'Aalen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73431',
    },
    {
        id: '15',
        ort: 'Aalen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73432',
    },
    {
        id: '16',
        ort: 'Aalen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73433',
    },
    {
        id: '17',
        ort: 'Aalen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73434',
    },
    {
        id: '18',
        ort: 'Aarbergen',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65326',
    },
    {
        id: '19',
        ort: 'Aasbüttel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25560',
    },
    {
        id: '20',
        ort: 'Abenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Roth',
        plz: '91183',
    },
    {
        id: '21',
        ort: 'Abensberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '93326',
    },
    {
        id: '22',
        ort: 'Abentheuer',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '23',
        ort: 'Absberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91720',
    },
    {
        id: '24',
        ort: 'Abstatt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74232',
    },
    {
        id: '25',
        ort: 'Abtsbessingen',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '99713',
    },
    {
        id: '26',
        ort: 'Abtsgmünd',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73453',
    },
    {
        id: '27',
        ort: 'Abtsteinach',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '69518',
    },
    {
        id: '28',
        ort: 'Abtswind',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97355',
    },
    {
        id: '29',
        ort: 'Abtweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55568',
    },
    {
        id: '30',
        ort: 'Achberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88147',
    },
    {
        id: '31',
        ort: 'Achern',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77855',
    },
    {
        id: '32',
        ort: 'Achim',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Verden',
        plz: '28832',
    },
    {
        id: '33',
        ort: 'Achslach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94250',
    },
    {
        id: '34',
        ort: 'Achstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88480',
    },
    {
        id: '35',
        ort: 'Acht',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '36',
        ort: 'Achtelsbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '37',
        ort: 'Achterwehr',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24239',
    },
    {
        id: '38',
        ort: 'Achtrup',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25917',
    },
    {
        id: '39',
        ort: 'Adelberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73099',
    },
    {
        id: '40',
        ort: 'Adelebsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37139',
    },
    {
        id: '41',
        ort: 'Adelheidsdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29352',
    },
    {
        id: '42',
        ort: 'Adelmannsfelden',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73486',
    },
    {
        id: '43',
        ort: 'Adelschlag',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85111',
    },
    {
        id: '44',
        ort: 'Adelsdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91325',
    },
    {
        id: '45',
        ort: 'Adelsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74740',
    },
    {
        id: '46',
        ort: 'Adelshofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91587',
    },
    {
        id: '47',
        ort: 'Adelshofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82276',
    },
    {
        id: '48',
        ort: 'Adelsried',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86477',
    },
    {
        id: '49',
        ort: 'Adelzhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86559',
    },
    {
        id: '50',
        ort: 'Adenau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53518',
    },
    {
        id: '51',
        ort: 'Adenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67742',
    },
    {
        id: '52',
        ort: 'Adenbüttel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38528',
    },
    {
        id: '53',
        ort: 'Adendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21365',
    },
    {
        id: '54',
        ort: 'Adlkofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84166',
    },
    {
        id: '55',
        ort: 'Admannshagen-Bargeshagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18211',
    },
    {
        id: '56',
        ort: 'Adorf/Vogtland',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08626',
    },
    {
        id: '57',
        ort: 'Aebtissinwisch',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25572',
    },
    {
        id: '58',
        ort: 'Aerzen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hameln-Pyrmont',
        plz: '31855',
    },
    {
        id: '59',
        ort: 'Affalterbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71563',
    },
    {
        id: '60',
        ort: 'Affing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86444',
    },
    {
        id: '61',
        ort: 'Affinghausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27257',
    },
    {
        id: '62',
        ort: 'Affler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54689',
    },
    {
        id: '63',
        ort: 'Agathenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21684',
    },
    {
        id: '64',
        ort: 'Agethorst',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25560',
    },
    {
        id: '65',
        ort: 'Aglasterhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74858',
    },
    {
        id: '66',
        ort: 'Aham',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84168',
    },
    {
        id: '67',
        ort: 'Ahaus',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '48683',
    },
    {
        id: '68',
        ort: 'Ahausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27367',
    },
    {
        id: '69',
        ort: 'Ahlbeck',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17375',
    },
    {
        id: '70',
        ort: 'Ahlden',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29693',
    },
    {
        id: '71',
        ort: 'Ahlefeld-Bistensee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24358',
    },
    {
        id: '72',
        ort: 'Ahlen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Warendorf',
        plz: '59227',
    },
    {
        id: '73',
        ort: 'Ahlen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Warendorf',
        plz: '59229',
    },
    {
        id: '74',
        ort: 'Ahlerstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21702',
    },
    {
        id: '75',
        ort: 'Ahlsdorf',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06313',
    },
    {
        id: '76',
        ort: 'Ahlstädt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98553',
    },
    {
        id: '77',
        ort: 'Ahnatal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34292',
    },
    {
        id: '78',
        ort: 'Ahneby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24996',
    },
    {
        id: '79',
        ort: 'Ahnsbeck',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29353',
    },
    {
        id: '80',
        ort: 'Ahnsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31708',
    },
    {
        id: '81',
        ort: 'Aholfing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94345',
    },
    {
        id: '82',
        ort: 'Aholming',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94527',
    },
    {
        id: '83',
        ort: 'Ahorn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Main-Tauber-Kreis',
        plz: '74744',
    },
    {
        id: '84',
        ort: 'Ahorn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Coburg',
        plz: '96482',
    },
    {
        id: '85',
        ort: 'Ahorntal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95491',
    },
    {
        id: '86',
        ort: 'Ahrbrück',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53506',
    },
    {
        id: '87',
        ort: 'Ahrensbök',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23623',
    },
    {
        id: '88',
        ort: 'Ahrensburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22926',
    },
    {
        id: '89',
        ort: 'Ahrensfelde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16356',
    },
    {
        id: '90',
        ort: 'Ahrenshagen-Daskow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18320',
    },
    {
        id: '91',
        ort: 'Ahrenshöft',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25853',
    },
    {
        id: '92',
        ort: 'Ahrenshoop',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18347',
    },
    {
        id: '93',
        ort: 'Ahrenviöl',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25885',
    },
    {
        id: '94',
        ort: 'Ahrenviölfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '24896',
    },
    {
        id: '95',
        ort: 'Ahrenviölfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25885',
    },
    {
        id: '96',
        ort: 'Aichach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86551',
    },
    {
        id: '97',
        ort: 'Aicha vorm Wald',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94529',
    },
    {
        id: '98',
        ort: 'Aichelberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73101',
    },
    {
        id: '99',
        ort: 'Aichen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '86479',
    },
    {
        id: '100',
        ort: 'Aichhalden',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78733',
    },
    {
        id: '101',
        ort: 'Aichstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88317',
    },
    {
        id: '102',
        ort: 'Aichtal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '72631',
    },
    {
        id: '103',
        ort: 'Aichwald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73773',
    },
    {
        id: '104',
        ort: 'Aidenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94501',
    },
    {
        id: '105',
        ort: 'Aidhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '97491',
    },
    {
        id: '106',
        ort: 'Aidlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71134',
    },
    {
        id: '107',
        ort: 'Aiglsbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '84089',
    },
    {
        id: '108',
        ort: 'Ailertchen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '109',
        ort: 'Aindling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86447',
    },
    {
        id: '110',
        ort: 'Ainring',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Berchtesgadener Land',
        plz: '83404',
    },
    {
        id: '111',
        ort: 'Aislingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89344',
    },
    {
        id: '112',
        ort: 'Aiterhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94330',
    },
    {
        id: '113',
        ort: 'Aitern',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79677',
    },
    {
        id: '114',
        ort: 'Aitrach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88319',
    },
    {
        id: '115',
        ort: 'Aitrang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87648',
    },
    {
        id: '116',
        ort: 'Aken (Elbe)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06385',
    },
    {
        id: '117',
        ort: 'Aken (Elbe)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06386',
    },
    {
        id: '118',
        ort: 'Aland',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39615',
    },
    {
        id: '119',
        ort: 'Albaching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83544',
    },
    {
        id: '120',
        ort: 'Albbruck',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79774',
    },
    {
        id: '121',
        ort: 'Albersdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25767',
    },
    {
        id: '122',
        ort: 'Albersdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '123',
        ort: 'Albershausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73095',
    },
    {
        id: '124',
        ort: 'Albersweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76857',
    },
    {
        id: '125',
        ort: 'Albertshofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97320',
    },
    {
        id: '126',
        ort: 'Albessen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66871',
    },
    {
        id: '127',
        ort: 'Albig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '128',
        ort: 'Albisheim (Pfrimm)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67308',
    },
    {
        id: '129',
        ort: 'Albsfelde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23909',
    },
    {
        id: '130',
        ort: 'Albstadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72458',
    },
    {
        id: '131',
        ort: 'Albstadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72459',
    },
    {
        id: '132',
        ort: 'Albstadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72461',
    },
    {
        id: '133',
        ort: 'Aldenhoven',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Düren',
        plz: '52457',
    },
    {
        id: '134',
        ort: 'Aldersbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94501',
    },
    {
        id: '135',
        ort: 'Aldingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78554',
    },
    {
        id: '136',
        ort: 'Alerheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86733',
    },
    {
        id: '137',
        ort: 'Alesheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91793',
    },
    {
        id: '138',
        ort: 'Aletshausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '86480',
    },
    {
        id: '139',
        ort: 'Alf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56859',
    },
    {
        id: '140',
        ort: 'Alfdorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '73553',
    },
    {
        id: '141',
        ort: 'Alfdorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '73655',
    },
    {
        id: '142',
        ort: 'Alfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91236',
    },
    {
        id: '143',
        ort: 'Alfeld (Leine)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31061',
    },
    {
        id: '144',
        ort: 'Alfhausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49594',
    },
    {
        id: '145',
        ort: 'Alflen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56828',
    },
    {
        id: '146',
        ort: 'Alfstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27432',
    },
    {
        id: '147',
        ort: 'Alfter',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53347',
    },
    {
        id: '148',
        ort: 'Algermissen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31191',
    },
    {
        id: '149',
        ort: 'Alheim',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36211',
    },
    {
        id: '150',
        ort: 'Alken',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56332',
    },
    {
        id: '151',
        ort: 'Alkersleben',
        bundesland: 'Thüringen',
        landkreis: 'Ilm-Kreis',
        plz: '99310',
    },
    {
        id: '152',
        ort: 'Alkersum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25938',
    },
    {
        id: '153',
        ort: 'Allenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '154',
        ort: 'Allendorf',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35469',
    },
    {
        id: '155',
        ort: 'Allendorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56370',
    },
    {
        id: '156',
        ort: 'Allendorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07426',
    },
    {
        id: '157',
        ort: 'Allendorf (Eder)',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '35108',
    },
    {
        id: '158',
        ort: 'Allenfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '159',
        ort: 'Allensbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78476',
    },
    {
        id: '160',
        ort: 'Allersberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Roth',
        plz: '90584',
    },
    {
        id: '161',
        ort: 'Allershausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85391',
    },
    {
        id: '162',
        ort: 'Alleshausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88422',
    },
    {
        id: '163',
        ort: 'Alling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82239',
    },
    {
        id: '164',
        ort: 'Allmannshofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86695',
    },
    {
        id: '165',
        ort: 'Allmannsweiler',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88348',
    },
    {
        id: '166',
        ort: 'Allmendingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89604',
    },
    {
        id: '167',
        ort: 'Allmersbach im Tal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71573',
    },
    {
        id: '168',
        ort: 'Allstedt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06542',
    },
    {
        id: '169',
        ort: 'Almdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25821',
    },
    {
        id: '170',
        ort: 'Almersbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57610',
    },
    {
        id: '171',
        ort: 'Alpen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '46519',
    },
    {
        id: '172',
        ort: 'Alpenrod',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57642',
    },
    {
        id: '173',
        ort: 'Alpenrod',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57647',
    },
    {
        id: '174',
        ort: 'Alperstedt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99195',
    },
    {
        id: '175',
        ort: 'Alpirsbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Freudenstadt',
        plz: '72275',
    },
    {
        id: '176',
        ort: 'Alsbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56237',
    },
    {
        id: '177',
        ort: 'Alsbach-Hähnlein',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64665',
    },
    {
        id: '178',
        ort: 'Alsdorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52477',
    },
    {
        id: '179',
        ort: 'Alsdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57518',
    },
    {
        id: '180',
        ort: 'Alsdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54668',
    },
    {
        id: '181',
        ort: 'Alsenz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67821',
    },
    {
        id: '182',
        ort: 'Alsfeld',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '36304',
    },
    {
        id: '183',
        ort: 'Alsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67577',
    },
    {
        id: '184',
        ort: 'Alsleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '06425',
    },
    {
        id: '185',
        ort: 'Altbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73776',
    },
    {
        id: '186',
        ort: 'Alt Bennebek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24848',
    },
    {
        id: '187',
        ort: 'Alt Bukow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18233',
    },
    {
        id: '188',
        ort: 'Altdöbern',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '03229',
    },
    {
        id: '189',
        ort: 'Altdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84032',
    },
    {
        id: '190',
        ort: 'Altdorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71155',
    },
    {
        id: '191',
        ort: 'Altdorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '72655',
    },
    {
        id: '192',
        ort: 'Altdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '67482',
    },
    {
        id: '193',
        ort: 'Altdorf bei Nürnberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '90518',
    },
    {
        id: '194',
        ort: 'Alt Duvenstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24782',
    },
    {
        id: '195',
        ort: 'Alt Duvenstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24791',
    },
    {
        id: '196',
        ort: 'Alt Duvenstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24811',
    },
    {
        id: '197',
        ort: 'Altefähr',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18573',
    },
    {
        id: '198',
        ort: 'Alteglofsheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93087',
    },
    {
        id: '199',
        ort: 'Altena',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58762',
    },
    {
        id: '200',
        ort: 'Altenahr',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53505',
    },
    {
        id: '201',
        ort: 'Altenbamberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55585',
    },
    {
        id: '202',
        ort: 'Altenbeken',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Paderborn',
        plz: '33184',
    },
    {
        id: '203',
        ort: 'Altenberg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01773',
    },
    {
        id: '204',
        ort: 'Altenberg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01778',
    },
    {
        id: '205',
        ort: 'Altenberga',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07768',
    },
    {
        id: '206',
        ort: 'Altenberge',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '48341',
    },
    {
        id: '207',
        ort: 'Altenbeuthen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07338',
    },
    {
        id: '208',
        ort: 'Altenbuch',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '97901',
    },
    {
        id: '209',
        ort: 'Altenburg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04600',
    },
    {
        id: '210',
        ort: 'Altendiez',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65624',
    },
    {
        id: '211',
        ort: 'Altendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92540',
    },
    {
        id: '212',
        ort: 'Altendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96146',
    },
    {
        id: '213',
        ort: 'Altenglan',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66885',
    },
    {
        id: '214',
        ort: 'Altenhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17091',
    },
    {
        id: '215',
        ort: 'Altenhausen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39343',
    },
    {
        id: '216',
        ort: 'Altenhof',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24340',
    },
    {
        id: '217',
        ort: 'Altenhof',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17209',
    },
    {
        id: '218',
        ort: 'Altenholz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24161',
    },
    {
        id: '219',
        ort: 'Altenkirchen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66903',
    },
    {
        id: '220',
        ort: 'Altenkirchen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18556',
    },
    {
        id: '221',
        ort: 'Altenkirchen (Westerwald)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57610',
    },
    {
        id: '222',
        ort: 'Altenkrempe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23730',
    },
    {
        id: '223',
        ort: 'Altenkunstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lichtenfels',
        plz: '96264',
    },
    {
        id: '224',
        ort: 'Altenmarkt an der Alz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83352',
    },
    {
        id: '225',
        ort: 'Altenmedingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29575',
    },
    {
        id: '226',
        ort: 'Altenmoor',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25335',
    },
    {
        id: '227',
        ort: 'Altenmünster',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86450',
    },
    {
        id: '228',
        ort: 'Altenpleen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18445',
    },
    {
        id: '229',
        ort: 'Altenriet',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '72657',
    },
    {
        id: '230',
        ort: 'Altenstadt',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '63674',
    },
    {
        id: '231',
        ort: 'Altenstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '86972',
    },
    {
        id: '232',
        ort: 'Altenstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89281',
    },
    {
        id: '233',
        ort: 'Altenstadt an der Waldnaab',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92665',
    },
    {
        id: '234',
        ort: 'Altensteig',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '72213',
    },
    {
        id: '235',
        ort: 'Altenthann',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93177',
    },
    {
        id: '236',
        ort: 'Altentreptow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17087',
    },
    {
        id: '237',
        ort: 'Alterkülz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56288',
    },
    {
        id: '238',
        ort: 'Altertheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97237',
    },
    {
        id: '239',
        ort: 'Altfraunhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84169',
    },
    {
        id: '240',
        ort: 'Althegnenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82278',
    },
    {
        id: '241',
        ort: 'Altheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88499',
    },
    {
        id: '242',
        ort: 'Altheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89605',
    },
    {
        id: '243',
        ort: 'Altheim (Alb)',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89174',
    },
    {
        id: '244',
        ort: 'Althengstett',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '75382',
    },
    {
        id: '245',
        ort: 'Althornbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66484',
    },
    {
        id: '246',
        ort: 'Althütte',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71566',
    },
    {
        id: '247',
        ort: 'Althüttendorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16247',
    },
    {
        id: '248',
        ort: 'Altkalen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17179',
    },
    {
        id: '249',
        ort: 'Alt Krenzlin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19288',
    },
    {
        id: '250',
        ort: 'Altlandsberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15345',
    },
    {
        id: '251',
        ort: 'Altlay',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56858',
    },
    {
        id: '252',
        ort: 'Altleiningen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67316',
    },
    {
        id: '253',
        ort: 'Altleiningen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67317',
    },
    {
        id: '254',
        ort: 'Altlußheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '68804',
    },
    {
        id: '255',
        ort: 'Altmannstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '93336',
    },
    {
        id: '256',
        ort: 'Altmärkische Höhe',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39606',
    },
    {
        id: '257',
        ort: 'Altmärkische Höhe',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39615',
    },
    {
        id: '258',
        ort: 'Altmärkische Wische',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39615',
    },
    {
        id: '259',
        ort: 'Alt Meteln',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19069',
    },
    {
        id: '260',
        ort: 'Altmittweida',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09648',
    },
    {
        id: '261',
        ort: 'Alt Mölln',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23881',
    },
    {
        id: '262',
        ort: 'Altomünster',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '85250',
    },
    {
        id: '263',
        ort: 'Altötting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84503',
    },
    {
        id: '264',
        ort: 'Altrich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '265',
        ort: 'Altrip',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67122',
    },
    {
        id: '266',
        ort: 'Altscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '267',
        ort: 'Alt Schwerin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17214',
    },
    {
        id: '268',
        ort: 'Altshausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88361',
    },
    {
        id: '269',
        ort: 'Altstrimmig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56858',
    },
    {
        id: '270',
        ort: 'Alt Sührkow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17166',
    },
    {
        id: '271',
        ort: 'Alt Tellin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17129',
    },
    {
        id: '272',
        ort: 'Alt Tucheband',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15328',
    },
    {
        id: '273',
        ort: 'Altusried',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87452',
    },
    {
        id: '274',
        ort: 'Altwarp',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17375',
    },
    {
        id: '275',
        ort: 'Altweidelbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55469',
    },
    {
        id: '276',
        ort: 'Altwigshagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17379',
    },
    {
        id: '277',
        ort: 'Alt Zachun',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '278',
        ort: 'Alt Zauche-Wußwerk',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15913',
    },
    {
        id: '279',
        ort: 'Alveslohe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '25486',
    },
    {
        id: '280',
        ort: 'Alzenau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63755',
    },
    {
        id: '281',
        ort: 'Alzey',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55232',
    },
    {
        id: '282',
        ort: 'Amberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '92224',
    },
    {
        id: '283',
        ort: 'Amberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '86854',
    },
    {
        id: '284',
        ort: 'Amelinghausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21385',
    },
    {
        id: '285',
        ort: 'Amerang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83123',
    },
    {
        id: '286',
        ort: 'Amerdingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86735',
    },
    {
        id: '287',
        ort: 'Am Ettersberg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99439',
    },
    {
        id: '288',
        ort: 'Am Großen Bruch',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39393',
    },
    {
        id: '289',
        ort: 'Ammeldingen an der Our',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '290',
        ort: 'Ammeldingen bei Neuerburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '291',
        ort: 'Am Mellensee',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Teltow-Fläming',
        plz: '15838',
    },
    {
        id: '292',
        ort: 'Ammerbuch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72119',
    },
    {
        id: '293',
        ort: 'Ammerndorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürth',
        plz: '90614',
    },
    {
        id: '294',
        ort: 'Ammersbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22949',
    },
    {
        id: '295',
        ort: 'Ammerthal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92260',
    },
    {
        id: '296',
        ort: 'Am Ohmberg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37345',
    },
    {
        id: '297',
        ort: 'Amöneburg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35287',
    },
    {
        id: '298',
        ort: 'Amorbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63916',
    },
    {
        id: '299',
        ort: 'Ampfing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84539',
    },
    {
        id: '300',
        ort: 'Am Salzhaff',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18233',
    },
    {
        id: '301',
        ort: 'Amstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '73340',
    },
    {
        id: '302',
        ort: 'Amt Creuzburg',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '99831',
    },
    {
        id: '303',
        ort: 'Amt Neuhaus',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '19273',
    },
    {
        id: '304',
        ort: 'Amtsberg',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09439',
    },
    {
        id: '305',
        ort: 'Amt Wachsenburg',
        bundesland: 'Thüringen',
        landkreis: 'Ilm-Kreis',
        plz: '99334',
    },
    {
        id: '306',
        ort: 'Amtzell',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88279',
    },
    {
        id: '307',
        ort: 'Andechs',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Starnberg',
        plz: '82346',
    },
    {
        id: '308',
        ort: 'Anderlingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27446',
    },
    {
        id: '309',
        ort: 'Andernach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56626',
    },
    {
        id: '310',
        ort: 'An der Poststraße',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06647',
    },
    {
        id: '311',
        ort: 'An der Schmücke',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '06577',
    },
    {
        id: '312',
        ort: 'Andervenne',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49832',
    },
    {
        id: '313',
        ort: 'Andisleben',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99189',
    },
    {
        id: '314',
        ort: 'Angelbachtal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '74918',
    },
    {
        id: '315',
        ort: 'Angelburg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35719',
    },
    {
        id: '316',
        ort: 'Anger',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Berchtesgadener Land',
        plz: '83454',
    },
    {
        id: '317',
        ort: 'Angermünde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '16278',
    },
    {
        id: '318',
        ort: 'Angern',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39326',
    },
    {
        id: '319',
        ort: 'Angern',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39517',
    },
    {
        id: '320',
        ort: 'Anhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56584',
    },
    {
        id: '321',
        ort: 'Ankershagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17219',
    },
    {
        id: '322',
        ort: 'Anklam',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17389',
    },
    {
        id: '323',
        ort: 'Ankum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49577',
    },
    {
        id: '324',
        ort: 'Annaberg-Buchholz',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09456',
    },
    {
        id: '325',
        ort: 'Annaburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Wittenberg',
        plz: '06925',
    },
    {
        id: '326',
        ort: 'Annweiler am Trifels',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76855',
    },
    {
        id: '327',
        ort: 'Anröchte',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Soest',
        plz: '59609',
    },
    {
        id: '328',
        ort: 'Ansbach',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '91522',
    },
    {
        id: '329',
        ort: 'Anschau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '330',
        ort: 'Antdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82387',
    },
    {
        id: '331',
        ort: 'Antrifttal',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '36326',
    },
    {
        id: '332',
        ort: 'Antweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53533',
    },
    {
        id: '333',
        ort: 'Anzing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85646',
    },
    {
        id: '334',
        ort: 'Apelern',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31552',
    },
    {
        id: '335',
        ort: 'Apen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Ammerland',
        plz: '26689',
    },
    {
        id: '336',
        ort: 'Apenburg-Winterfeld',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Altmarkkreis Salzwedel',
        plz: '38486',
    },
    {
        id: '337',
        ort: 'Apensen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21641',
    },
    {
        id: '338',
        ort: 'Apfeldorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86974',
    },
    {
        id: '339',
        ort: 'Apfeltrach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87742',
    },
    {
        id: '340',
        ort: 'Apolda',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99510',
    },
    {
        id: '341',
        ort: 'Appel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21279',
    },
    {
        id: '342',
        ort: 'Appen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25482',
    },
    {
        id: '343',
        ort: 'Appenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55437',
    },
    {
        id: '344',
        ort: 'Appenweier',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77767',
    },
    {
        id: '345',
        ort: 'Arbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '56769',
    },
    {
        id: '346',
        ort: 'Arberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91722',
    },
    {
        id: '347',
        ort: 'Aremberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53533',
    },
    {
        id: '348',
        ort: 'Arendsee (Altmark)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Altmarkkreis Salzwedel',
        plz: '39619',
    },
    {
        id: '349',
        ort: 'Arenrath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '350',
        ort: 'Arenshausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '351',
        ort: 'Aresing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neuburg-Schrobenhausen',
        plz: '86561',
    },
    {
        id: '352',
        ort: 'Arft',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '353',
        ort: 'Argenbühl',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88260',
    },
    {
        id: '354',
        ort: 'Argenschwang',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '355',
        ort: 'Argenthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55496',
    },
    {
        id: '356',
        ort: 'Arholzen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37627',
    },
    {
        id: '357',
        ort: 'Arkebek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25767',
    },
    {
        id: '358',
        ort: 'Arlewatt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25860',
    },
    {
        id: '359',
        ort: 'Armsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55288',
    },
    {
        id: '360',
        ort: 'Armstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24616',
    },
    {
        id: '361',
        ort: 'Armstorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21769',
    },
    {
        id: '362',
        ort: 'Arnbruck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '93471',
    },
    {
        id: '363',
        ort: 'Arneburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39596',
    },
    {
        id: '364',
        ort: 'Arnis',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24399',
    },
    {
        id: '365',
        ort: 'Arnsberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Hochsauerlandkreis',
        plz: '59755',
    },
    {
        id: '366',
        ort: 'Arnsberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Hochsauerlandkreis',
        plz: '59757',
    },
    {
        id: '367',
        ort: 'Arnsberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Hochsauerlandkreis',
        plz: '59759',
    },
    {
        id: '368',
        ort: 'Arnsberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Hochsauerlandkreis',
        plz: '59821',
    },
    {
        id: '369',
        ort: 'Arnsberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Hochsauerlandkreis',
        plz: '59823',
    },
    {
        id: '370',
        ort: 'Arnschwang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93473',
    },
    {
        id: '371',
        ort: 'Arnsdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01477',
    },
    {
        id: '372',
        ort: 'Arnshöfen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '373',
        ort: 'Arnstadt',
        bundesland: 'Thüringen',
        landkreis: 'Ilm-Kreis',
        plz: '99310',
    },
    {
        id: '374',
        ort: 'Arnstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97450',
    },
    {
        id: '375',
        ort: 'Arnstein',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06456',
    },
    {
        id: '376',
        ort: 'Arnstorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '94424',
    },
    {
        id: '377',
        ort: 'Arpsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24634',
    },
    {
        id: '378',
        ort: 'Arrach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93474',
    },
    {
        id: '379',
        ort: 'Artern',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '06556',
    },
    {
        id: '380',
        ort: 'Artlenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21380',
    },
    {
        id: '381',
        ort: 'Arzbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56337',
    },
    {
        id: '382',
        ort: 'Arzberg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04886',
    },
    {
        id: '383',
        ort: 'Arzberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Wunsiedel im Fichtelgebirge',
        plz: '95659',
    },
    {
        id: '384',
        ort: 'Arzfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54687',
    },
    {
        id: '385',
        ort: 'Asbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53567',
    },
    {
        id: '386',
        ort: 'Asbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '387',
        ort: 'Asbach-Bäumenheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86663',
    },
    {
        id: '388',
        ort: 'Asbach-Sickenberg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '389',
        ort: 'Ascha',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94347',
    },
    {
        id: '390',
        ort: 'Aschaffenburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '63739',
    },
    {
        id: '391',
        ort: 'Aschaffenburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '63741',
    },
    {
        id: '392',
        ort: 'Aschaffenburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '63743',
    },
    {
        id: '393',
        ort: 'Aschau am Inn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84544',
    },
    {
        id: '394',
        ort: 'Aschau im Chiemgau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83229',
    },
    {
        id: '395',
        ort: 'Aschbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67753',
    },
    {
        id: '396',
        ort: 'Ascheberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Coesfeld',
        plz: '59387',
    },
    {
        id: '397',
        ort: 'Ascheberg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24326',
    },
    {
        id: '398',
        ort: 'Ascheffel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24358',
    },
    {
        id: '399',
        ort: 'Aschersleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '06449',
    },
    {
        id: '400',
        ort: 'Aschheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '85609',
    },
    {
        id: '401',
        ort: 'Asendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21271',
    },
    {
        id: '402',
        ort: 'Asendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27330',
    },
    {
        id: '403',
        ort: 'Aspach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71546',
    },
    {
        id: '404',
        ort: 'Asperg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71679',
    },
    {
        id: '405',
        ort: 'Aspisheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55459',
    },
    {
        id: '406',
        ort: 'Assamstadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Main-Tauber-Kreis',
        plz: '97959',
    },
    {
        id: '407',
        ort: 'Asselfingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89176',
    },
    {
        id: '408',
        ort: 'Aßlar',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35614',
    },
    {
        id: '409',
        ort: 'Aßling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85617',
    },
    {
        id: '410',
        ort: 'Astert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57627',
    },
    {
        id: '411',
        ort: 'Attendorn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Olpe',
        plz: '57439',
    },
    {
        id: '412',
        ort: 'Attenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56370',
    },
    {
        id: '413',
        ort: 'Attenhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '84091',
    },
    {
        id: '414',
        ort: 'Attenkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85395',
    },
    {
        id: '415',
        ort: 'Attenweiler',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88448',
    },
    {
        id: '416',
        ort: 'Atting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94348',
    },
    {
        id: '417',
        ort: 'Atzelgift',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57629',
    },
    {
        id: '418',
        ort: 'Au',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79280',
    },
    {
        id: '419',
        ort: 'Au am Rhein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '76474',
    },
    {
        id: '420',
        ort: 'Aub',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97239',
    },
    {
        id: '421',
        ort: 'Aubstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97633',
    },
    {
        id: '422',
        ort: 'Auderath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56766',
    },
    {
        id: '423',
        ort: 'Aue-Bad Schlema',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '08280',
    },
    {
        id: '424',
        ort: 'Aue-Bad Schlema',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '08301',
    },
    {
        id: '425',
        ort: 'Auel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '426',
        ort: 'Auen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55569',
    },
    {
        id: '427',
        ort: 'Auengrund',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98673',
    },
    {
        id: '428',
        ort: 'Auenwald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71549',
    },
    {
        id: '429',
        ort: 'Auerbach',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09392',
    },
    {
        id: '430',
        ort: 'Auerbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94530',
    },
    {
        id: '431',
        ort: 'Auerbach in der Oberpfalz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '91275',
    },
    {
        id: '432',
        ort: 'Auerbach/Vogtland',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08209',
    },
    {
        id: '433',
        ort: 'Auetal',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31749',
    },
    {
        id: '434',
        ort: 'Aufhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93089',
    },
    {
        id: '435',
        ort: 'Aufseß',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '91347',
    },
    {
        id: '436',
        ort: 'Auggen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79424',
    },
    {
        id: '437',
        ort: 'Augsburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '86150',
    },
    {
        id: '438',
        ort: 'Augsburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '86152',
    },
    {
        id: '439',
        ort: 'Augsburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '86153',
    },
    {
        id: '440',
        ort: 'Augsburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '86154',
    },
    {
        id: '441',
        ort: 'Augsburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '86156',
    },
    {
        id: '442',
        ort: 'Augsburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '86157',
    },
    {
        id: '443',
        ort: 'Augsburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '86159',
    },
    {
        id: '444',
        ort: 'Augsburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '86161',
    },
    {
        id: '445',
        ort: 'Augsburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '86163',
    },
    {
        id: '446',
        ort: 'Augsburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '86165',
    },
    {
        id: '447',
        ort: 'Augsburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '86167',
    },
    {
        id: '448',
        ort: 'Augsburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '86169',
    },
    {
        id: '449',
        ort: 'Augsburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '86179',
    },
    {
        id: '450',
        ort: 'Augsburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '86199',
    },
    {
        id: '451',
        ort: 'Augustdorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '32832',
    },
    {
        id: '452',
        ort: 'Augustusburg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09573',
    },
    {
        id: '453',
        ort: 'Auhagen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31553',
    },
    {
        id: '454',
        ort: 'Auhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86736',
    },
    {
        id: '455',
        ort: 'Au in der Hallertau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '84072',
    },
    {
        id: '456',
        ort: 'Aukrug',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24613',
    },
    {
        id: '457',
        ort: 'Aulendorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88326',
    },
    {
        id: '458',
        ort: 'Aull',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65582',
    },
    {
        id: '459',
        ort: 'Auma-Weidatal',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07955',
    },
    {
        id: '460',
        ort: 'Aumühle',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21521',
    },
    {
        id: '461',
        ort: 'Aura a.d.Saale',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97717',
    },
    {
        id: '462',
        ort: 'Aurach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91589',
    },
    {
        id: '463',
        ort: 'Aurachtal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91086',
    },
    {
        id: '464',
        ort: 'Aura im Sinngrund',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97773',
    },
    {
        id: '465',
        ort: 'Aurich',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26603',
    },
    {
        id: '466',
        ort: 'Aurich',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26605',
    },
    {
        id: '467',
        ort: 'Aurich',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26607',
    },
    {
        id: '468',
        ort: 'Ausacker',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24975',
    },
    {
        id: '469',
        ort: 'Ausleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39393',
    },
    {
        id: '470',
        ort: 'Außernzell',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94532',
    },
    {
        id: '471',
        ort: 'Auufer',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25548',
    },
    {
        id: '472',
        ort: 'Auw an der Kyll',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54664',
    },
    {
        id: '473',
        ort: 'Auw bei Prüm',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '474',
        ort: 'Aventoft',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25927',
    },
    {
        id: '475',
        ort: 'Averlak',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25715',
    },
    {
        id: '476',
        ort: 'Axstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osterholz',
        plz: '27729',
    },
    {
        id: '477',
        ort: 'Aying',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '85653',
    },
    {
        id: '478',
        ort: 'Ayl',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54441',
    },
    {
        id: '479',
        ort: 'Aystetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86482',
    },
    {
        id: '480',
        ort: 'Baabe',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18586',
    },
    {
        id: '481',
        ort: 'Baar',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86674',
    },
    {
        id: '482',
        ort: 'Baar',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '483',
        ort: 'Baar-Ebenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85107',
    },
    {
        id: '484',
        ort: 'Babenhausen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64832',
    },
    {
        id: '485',
        ort: 'Babenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87727',
    },
    {
        id: '486',
        ort: 'Babensham',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83547',
    },
    {
        id: '487',
        ort: 'Bach an der Donau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93090',
    },
    {
        id: '488',
        ort: 'Bacharach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55422',
    },
    {
        id: '489',
        ort: 'Bachenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57610',
    },
    {
        id: '490',
        ort: 'Bachhagel',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89429',
    },
    {
        id: '491',
        ort: 'Bächingen a.d.Brenz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89431',
    },
    {
        id: '492',
        ort: 'Backnang',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71522',
    },
    {
        id: '493',
        ort: 'Bad Abbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '93077',
    },
    {
        id: '494',
        ort: 'Bad Aibling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83043',
    },
    {
        id: '495',
        ort: 'Bad Alexandersbad',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Wunsiedel im Fichtelgebirge',
        plz: '95680',
    },
    {
        id: '496',
        ort: 'Bad Arolsen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '34454',
    },
    {
        id: '497',
        ort: 'Bad Bayersoien',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82435',
    },
    {
        id: '498',
        ort: 'Bad Bellingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79415',
    },
    {
        id: '499',
        ort: 'Bad Belzig',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14806',
    },
    {
        id: '500',
        ort: 'Bad Bentheim',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '48455',
    },
    {
        id: '501',
        ort: 'Badbergen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49635',
    },
    {
        id: '502',
        ort: 'Bad Bergzabern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76887',
    },
    {
        id: '503',
        ort: 'Bad Berka',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99438',
    },
    {
        id: '504',
        ort: 'Bad Berleburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Siegen-Wittgenstein',
        plz: '57319',
    },
    {
        id: '505',
        ort: 'Bad Berneck im Fichtelgebirge',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95460',
    },
    {
        id: '506',
        ort: 'Bad Bertrich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56864',
    },
    {
        id: '507',
        ort: 'Bad Bevensen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29549',
    },
    {
        id: '508',
        ort: 'Bad Bibra',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06647',
    },
    {
        id: '509',
        ort: 'Bad Birnbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84364',
    },
    {
        id: '510',
        ort: 'Bad Blankenburg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07422',
    },
    {
        id: '511',
        ort: 'Bad Bocklet',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97708',
    },
    {
        id: '512',
        ort: 'Bad Bodenteich',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29389',
    },
    {
        id: '513',
        ort: 'Bad Boll',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73087',
    },
    {
        id: '514',
        ort: 'Bad Brambach',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08648',
    },
    {
        id: '515',
        ort: 'Bad Bramstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24576',
    },
    {
        id: '516',
        ort: 'Bad Breisig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53498',
    },
    {
        id: '517',
        ort: 'Bad Brückenau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97769',
    },
    {
        id: '518',
        ort: 'Bad Buchau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88422',
    },
    {
        id: '519',
        ort: 'Bad Camberg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65520',
    },
    {
        id: '520',
        ort: 'Baddeckenstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38271',
    },
    {
        id: '521',
        ort: 'Bad Ditzenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73312',
    },
    {
        id: '522',
        ort: 'Bad Ditzenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73342',
    },
    {
        id: '523',
        ort: 'Bad Ditzenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73345',
    },
    {
        id: '524',
        ort: 'Bad Doberan',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18209',
    },
    {
        id: '525',
        ort: 'Bad Driburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Höxter',
        plz: '33014',
    },
    {
        id: '526',
        ort: 'Bad Düben',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04849',
    },
    {
        id: '527',
        ort: 'Bad Dürkheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67098',
    },
    {
        id: '528',
        ort: 'Bad Dürrenberg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06231',
    },
    {
        id: '529',
        ort: 'Bad Dürrheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78073',
    },
    {
        id: '530',
        ort: 'Bad Eilsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31707',
    },
    {
        id: '531',
        ort: 'Bad Elster',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08645',
    },
    {
        id: '532',
        ort: 'Badem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54657',
    },
    {
        id: '533',
        ort: 'Bad Ems',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56130',
    },
    {
        id: '534',
        ort: 'Bad Emstal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34308',
    },
    {
        id: '535',
        ort: 'Baden-Baden',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '76530',
    },
    {
        id: '536',
        ort: 'Baden-Baden',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '76532',
    },
    {
        id: '537',
        ort: 'Baden-Baden',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '76534',
    },
    {
        id: '538',
        ort: 'Bad Endbach',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35080',
    },
    {
        id: '539',
        ort: 'Badendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23619',
    },
    {
        id: '540',
        ort: 'Bad Endorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83093',
    },
    {
        id: '541',
        ort: 'Badenhard',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56291',
    },
    {
        id: '542',
        ort: 'Badenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55576',
    },
    {
        id: '543',
        ort: 'Badenweiler',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79410',
    },
    {
        id: '544',
        ort: 'Bad Essen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49152',
    },
    {
        id: '545',
        ort: 'Bad Fallingbostel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29683',
    },
    {
        id: '546',
        ort: 'Bad Feilnbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83075',
    },
    {
        id: '547',
        ort: 'Bad Frankenhausen/Kyffhäuser',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '06567',
    },
    {
        id: '548',
        ort: 'Bad Freienwalde (Oder)',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '16259',
    },
    {
        id: '549',
        ort: 'Bad Friedrichshall',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74177',
    },
    {
        id: '550',
        ort: 'Bad Füssing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94072',
    },
    {
        id: '551',
        ort: 'Bad Gandersheim',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Northeim',
        plz: '37581',
    },
    {
        id: '552',
        ort: 'Bad Gottleuba-Berggießhübel',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01816',
    },
    {
        id: '553',
        ort: 'Bad Griesbach i.Rottal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94086',
    },
    {
        id: '554',
        ort: 'Bad Grönenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87730',
    },
    {
        id: '555',
        ort: 'Bad Grund',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37539',
    },
    {
        id: '556',
        ort: 'Bad Harzburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Goslar',
        plz: '38667',
    },
    {
        id: '557',
        ort: 'Bad Heilbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '83670',
    },
    {
        id: '558',
        ort: 'Bad Herrenalb',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '76332',
    },
    {
        id: '559',
        ort: 'Bad Hersfeld',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36251',
    },
    {
        id: '560',
        ort: 'Bad Hindelang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87541',
    },
    {
        id: '561',
        ort: 'Bad Homburg vor der Höhe',
        bundesland: 'Hessen',
        landkreis: 'Hochtaunuskreis',
        plz: '61348',
    },
    {
        id: '562',
        ort: 'Bad Homburg vor der Höhe',
        bundesland: 'Hessen',
        landkreis: 'Hochtaunuskreis',
        plz: '61350',
    },
    {
        id: '563',
        ort: 'Bad Homburg vor der Höhe',
        bundesland: 'Hessen',
        landkreis: 'Hochtaunuskreis',
        plz: '61352',
    },
    {
        id: '564',
        ort: 'Bad Honnef',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53604',
    },
    {
        id: '565',
        ort: 'Bad Hönningen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53547',
    },
    {
        id: '566',
        ort: 'Bad Hönningen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53557',
    },
    {
        id: '567',
        ort: 'Bad Iburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49186',
    },
    {
        id: '568',
        ort: 'Bad Karlshafen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34385',
    },
    {
        id: '569',
        ort: 'Bad Kissingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97688',
    },
    {
        id: '570',
        ort: 'Bad Kleinen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23996',
    },
    {
        id: '571',
        ort: 'Bad Klosterlausnitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07639',
    },
    {
        id: '572',
        ort: 'Bad Kohlgrub',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82433',
    },
    {
        id: '573',
        ort: 'Bad König',
        bundesland: 'Hessen',
        landkreis: 'Odenwaldkreis',
        plz: '64732',
    },
    {
        id: '574',
        ort: 'Bad Königshofen im Grabfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97631',
    },
    {
        id: '575',
        ort: 'Bad Köstritz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07586',
    },
    {
        id: '576',
        ort: 'Bad Kötzting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93444',
    },
    {
        id: '577',
        ort: 'Bad Kreuznach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55543',
    },
    {
        id: '578',
        ort: 'Bad Kreuznach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55545',
    },
    {
        id: '579',
        ort: 'Bad Kreuznach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55583',
    },
    {
        id: '580',
        ort: 'Bad Krozingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79189',
    },
    {
        id: '581',
        ort: 'Bad Laasphe',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Siegen-Wittgenstein',
        plz: '57334',
    },
    {
        id: '582',
        ort: 'Bad Laer',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49196',
    },
    {
        id: '583',
        ort: 'Bad Langensalza',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99947',
    },
    {
        id: '584',
        ort: 'Bad Lausick',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04651',
    },
    {
        id: '585',
        ort: 'Bad Lauterberg im Harz',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37431',
    },
    {
        id: '586',
        ort: 'Bad Liebenstein',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '36448',
    },
    {
        id: '587',
        ort: 'Bad Liebenwerda',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04895',
    },
    {
        id: '588',
        ort: 'Bad Liebenwerda',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04924',
    },
    {
        id: '589',
        ort: 'Bad Liebenwerda',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04931',
    },
    {
        id: '590',
        ort: 'Bad Liebenzell',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '75378',
    },
    {
        id: '591',
        ort: 'Bad Lippspringe',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Paderborn',
        plz: '33175',
    },
    {
        id: '592',
        ort: 'Bad Lobenstein',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07356',
    },
    {
        id: '593',
        ort: 'Bad Marienberg (Westerwald)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56470',
    },
    {
        id: '594',
        ort: 'Bad Mergentheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Main-Tauber-Kreis',
        plz: '97980',
    },
    {
        id: '595',
        ort: 'Bad Münder am Deister',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hameln-Pyrmont',
        plz: '31848',
    },
    {
        id: '596',
        ort: 'Bad Münstereifel',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Euskirchen',
        plz: '53902',
    },
    {
        id: '597',
        ort: 'Bad Muskau - Mužakow',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02953',
    },
    {
        id: '598',
        ort: 'Bad Nauheim',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '61231',
    },
    {
        id: '599',
        ort: 'Bad Nenndorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31542',
    },
    {
        id: '600',
        ort: 'Bad Neualbenreuth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95698',
    },
    {
        id: '601',
        ort: 'Bad Neuenahr-Ahrweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53474',
    },
    {
        id: '602',
        ort: 'Bad Neustadt an der Saale',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97616',
    },
    {
        id: '603',
        ort: 'Bad Oeynhausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Minden-Lübbecke',
        plz: '32545',
    },
    {
        id: '604',
        ort: 'Bad Oeynhausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Minden-Lübbecke',
        plz: '32547',
    },
    {
        id: '605',
        ort: 'Bad Oeynhausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Minden-Lübbecke',
        plz: '32549',
    },
    {
        id: '606',
        ort: 'Bad Oldesloe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23843',
    },
    {
        id: '607',
        ort: 'Bad Orb',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63619',
    },
    {
        id: '608',
        ort: 'Bad Peterstal-Griesbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '72250',
    },
    {
        id: '609',
        ort: 'Bad Peterstal-Griesbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77740',
    },
    {
        id: '610',
        ort: 'Bad Pyrmont',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hameln-Pyrmont',
        plz: '31812',
    },
    {
        id: '611',
        ort: 'Bad Rappenau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74906',
    },
    {
        id: '612',
        ort: 'Bad Reichenhall',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Berchtesgadener Land',
        plz: '83435',
    },
    {
        id: '613',
        ort: 'Bad Rippoldsau-Schapbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Freudenstadt',
        plz: '77776',
    },
    {
        id: '614',
        ort: 'Bad Rodach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Coburg',
        plz: '96476',
    },
    {
        id: '615',
        ort: 'Bad Rothenfelde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49214',
    },
    {
        id: '616',
        ort: 'Bad Saarow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15526',
    },
    {
        id: '617',
        ort: 'Bad Sachsa',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37441',
    },
    {
        id: '618',
        ort: 'Bad Säckingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79713',
    },
    {
        id: '619',
        ort: 'Bad Salzdetfurth',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31162',
    },
    {
        id: '620',
        ort: 'Bad Salzschlirf',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36364',
    },
    {
        id: '621',
        ort: 'Bad Salzuflen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '32105',
    },
    {
        id: '622',
        ort: 'Bad Salzuflen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '32107',
    },
    {
        id: '623',
        ort: 'Bad Salzuflen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '32108',
    },
    {
        id: '624',
        ort: 'Bad Salzungen',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '36433',
    },
    {
        id: '625',
        ort: 'Bad Salzungen',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '36469',
    },
    {
        id: '626',
        ort: 'Bad Sassendorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Soest',
        plz: '59505',
    },
    {
        id: '627',
        ort: 'Bad Saulgau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '88348',
    },
    {
        id: '628',
        ort: 'Bad Schandau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01814',
    },
    {
        id: '629',
        ort: 'Bad Schmiedeberg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Wittenberg',
        plz: '06905',
    },
    {
        id: '630',
        ort: 'Bad Schönborn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76669',
    },
    {
        id: '631',
        ort: 'Bad Schussenried',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88427',
    },
    {
        id: '632',
        ort: 'Bad Schwalbach',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65307',
    },
    {
        id: '633',
        ort: 'Bad Schwartau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23611',
    },
    {
        id: '634',
        ort: 'Bad Segeberg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23795',
    },
    {
        id: '635',
        ort: 'Bad Sobernheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55566',
    },
    {
        id: '636',
        ort: 'Bad Soden am Taunus',
        bundesland: 'Hessen',
        landkreis: 'Main-Taunus-Kreis',
        plz: '65812',
    },
    {
        id: '637',
        ort: 'Bad Soden-Salmünster',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63628',
    },
    {
        id: '638',
        ort: 'Bad Sooden-Allendorf',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37242',
    },
    {
        id: '639',
        ort: 'Bad Staffelstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lichtenfels',
        plz: '96231',
    },
    {
        id: '640',
        ort: 'Bad Steben',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95138',
    },
    {
        id: '641',
        ort: 'Bad Sulza',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99518',
    },
    {
        id: '642',
        ort: 'Bad Sülze',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18334',
    },
    {
        id: '643',
        ort: 'Bad Tabarz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99891',
    },
    {
        id: '644',
        ort: 'Bad Teinach-Zavelstein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '75385',
    },
    {
        id: '645',
        ort: 'Bad Tennstedt',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99955',
    },
    {
        id: '646',
        ort: 'Bad Tölz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '83646',
    },
    {
        id: '647',
        ort: 'Bad Überkingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73337',
    },
    {
        id: '648',
        ort: 'Bad Urach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72574',
    },
    {
        id: '649',
        ort: 'Bad Vilbel',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '61118',
    },
    {
        id: '650',
        ort: 'Bad Waldsee',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88339',
    },
    {
        id: '651',
        ort: 'Bad Wiessee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miesbach',
        plz: '83707',
    },
    {
        id: '652',
        ort: 'Bad Wildbad',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '75323',
    },
    {
        id: '653',
        ort: 'Bad Wildungen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '34537',
    },
    {
        id: '654',
        ort: 'Bad Wilsnack',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '19336',
    },
    {
        id: '655',
        ort: 'Bad Wimpfen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74206',
    },
    {
        id: '656',
        ort: 'Bad Windsheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91438',
    },
    {
        id: '657',
        ort: 'Bad Wörishofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '86825',
    },
    {
        id: '658',
        ort: 'Bad Wünnenberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Paderborn',
        plz: '33181',
    },
    {
        id: '659',
        ort: 'Bad Wurzach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88410',
    },
    {
        id: '660',
        ort: 'Bad Zwesten',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34596',
    },
    {
        id: '661',
        ort: 'Bad Zwischenahn',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Ammerland',
        plz: '26160',
    },
    {
        id: '662',
        ort: 'Baesweiler',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52499',
    },
    {
        id: '663',
        ort: 'Bahlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79353',
    },
    {
        id: '664',
        ort: 'Bahrdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38459',
    },
    {
        id: '665',
        ort: 'Bahrenborstel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27245',
    },
    {
        id: '666',
        ort: 'Bahrenfleth',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25569',
    },
    {
        id: '667',
        ort: 'Bahrenhof',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23845',
    },
    {
        id: '668',
        ort: 'Bahretal',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01819',
    },
    {
        id: '669',
        ort: 'Baienfurt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88255',
    },
    {
        id: '670',
        ort: 'Baierbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84171',
    },
    {
        id: '671',
        ort: 'Baierbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '82065',
    },
    {
        id: '672',
        ort: 'Baiern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85625',
    },
    {
        id: '673',
        ort: 'Baiersbronn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Freudenstadt',
        plz: '72270',
    },
    {
        id: '674',
        ort: 'Baiersdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91083',
    },
    {
        id: '675',
        ort: 'Baindt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88255',
    },
    {
        id: '676',
        ort: 'Baisweil',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87650',
    },
    {
        id: '677',
        ort: 'Bäk',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23909',
    },
    {
        id: '678',
        ort: 'Bakum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Vechta',
        plz: '49456',
    },
    {
        id: '679',
        ort: 'Bälau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23881',
    },
    {
        id: '680',
        ort: 'Balderschwang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87538',
    },
    {
        id: '681',
        ort: 'Baldringen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54314',
    },
    {
        id: '682',
        ort: 'Balduinstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65558',
    },
    {
        id: '683',
        ort: 'Balesfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '684',
        ort: 'Balge',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31609',
    },
    {
        id: '685',
        ort: 'Balgheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78582',
    },
    {
        id: '686',
        ort: 'Balgstädt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06632',
    },
    {
        id: '687',
        ort: 'Balingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72336',
    },
    {
        id: '688',
        ort: 'Balje',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21730',
    },
    {
        id: '689',
        ort: 'Ballendorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89177',
    },
    {
        id: '690',
        ort: 'Ballenstedt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '06493',
    },
    {
        id: '691',
        ort: 'Ballhausen',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99955',
    },
    {
        id: '692',
        ort: 'Ballrechten-Dottingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79282',
    },
    {
        id: '693',
        ort: 'Ballstedt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99439',
    },
    {
        id: '694',
        ort: 'Balow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19300',
    },
    {
        id: '695',
        ort: 'Baltmannsweiler',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73666',
    },
    {
        id: '696',
        ort: 'Baltrum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26579',
    },
    {
        id: '697',
        ort: 'Balve',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58802',
    },
    {
        id: '698',
        ort: 'Balzhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '86483',
    },
    {
        id: '699',
        ort: 'Balzheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '88481',
    },
    {
        id: '700',
        ort: 'Bamberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '96047',
    },
    {
        id: '701',
        ort: 'Bamberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '96049',
    },
    {
        id: '702',
        ort: 'Bamberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '96050',
    },
    {
        id: '703',
        ort: 'Bamberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '96052',
    },
    {
        id: '704',
        ort: 'Bammental',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69245',
    },
    {
        id: '705',
        ort: 'Bandelin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17506',
    },
    {
        id: '706',
        ort: 'Bandenitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '707',
        ort: 'Bann',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66851',
    },
    {
        id: '708',
        ort: 'Bannberscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56424',
    },
    {
        id: '709',
        ort: 'Bannewitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01728',
    },
    {
        id: '710',
        ort: 'Banzkow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19079',
    },
    {
        id: '711',
        ort: 'Barbelroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76889',
    },
    {
        id: '712',
        ort: 'Barbing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93092',
    },
    {
        id: '713',
        ort: 'Barby',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '39240',
    },
    {
        id: '714',
        ort: 'Barby',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '39249',
    },
    {
        id: '715',
        ort: 'Barchfeld-Immelborn',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '36456',
    },
    {
        id: '716',
        ort: 'Bardowick',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21357',
    },
    {
        id: '717',
        ort: 'Bärenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55483',
    },
    {
        id: '718',
        ort: 'Bärenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55758',
    },
    {
        id: '719',
        ort: 'Barenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27245',
    },
    {
        id: '720',
        ort: 'Barendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21397',
    },
    {
        id: '721',
        ort: 'Bärenstein',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09471',
    },
    {
        id: '722',
        ort: 'Bärenthal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78580',
    },
    {
        id: '723',
        ort: 'Bargenstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25704',
    },
    {
        id: '724',
        ort: 'Bargfeld-Stegen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23863',
    },
    {
        id: '725',
        ort: 'Bargischow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17398',
    },
    {
        id: '726',
        ort: 'Bargstall',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24806',
    },
    {
        id: '727',
        ort: 'Bargstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21698',
    },
    {
        id: '728',
        ort: 'Bargstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24793',
    },
    {
        id: '729',
        ort: 'Bargteheide',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22941',
    },
    {
        id: '730',
        ort: 'Bargum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25842',
    },
    {
        id: '731',
        ort: 'Bark',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23826',
    },
    {
        id: '732',
        ort: 'Barkelsby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24360',
    },
    {
        id: '733',
        ort: 'Barkenholm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25791',
    },
    {
        id: '734',
        ort: 'Barkhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19395',
    },
    {
        id: '735',
        ort: 'Barleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39179',
    },
    {
        id: '736',
        ort: 'Barlt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25719',
    },
    {
        id: '737',
        ort: 'Barmissen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24245',
    },
    {
        id: '738',
        ort: 'Barmstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25355',
    },
    {
        id: '739',
        ort: 'Bärnau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95671',
    },
    {
        id: '740',
        ort: 'Barnekow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23968',
    },
    {
        id: '741',
        ort: 'Barnin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19089',
    },
    {
        id: '742',
        ort: 'Barnitz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23858',
    },
    {
        id: '743',
        ort: 'Barnstädt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06268',
    },
    {
        id: '744',
        ort: 'Barnstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21406',
    },
    {
        id: '745',
        ort: 'Barnstorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49406',
    },
    {
        id: '746',
        ort: 'Barntrup',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '32683',
    },
    {
        id: '747',
        ort: 'Barsbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24217',
    },
    {
        id: '748',
        ort: 'Barsbüttel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22885',
    },
    {
        id: '749',
        ort: 'Barsinghausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30890',
    },
    {
        id: '750',
        ort: 'Barßel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cloppenburg',
        plz: '26676',
    },
    {
        id: '751',
        ort: 'Bartenshagen-Parkentin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18209',
    },
    {
        id: '752',
        ort: 'Barth',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18356',
    },
    {
        id: '753',
        ort: 'Bartholomä',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73566',
    },
    {
        id: '754',
        ort: 'Bartow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17089',
    },
    {
        id: '755',
        ort: 'Barum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29576',
    },
    {
        id: '756',
        ort: 'Barum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21357',
    },
    {
        id: '757',
        ort: 'Baruth/Mark',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Teltow-Fläming',
        plz: '15837',
    },
    {
        id: '758',
        ort: 'Barver',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49453',
    },
    {
        id: '759',
        ort: 'Barwedel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38476',
    },
    {
        id: '760',
        ort: 'Barweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53534',
    },
    {
        id: '761',
        ort: 'Bärweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55606',
    },
    {
        id: '762',
        ort: 'Basberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54578',
    },
    {
        id: '763',
        ort: 'Basdahl',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27432',
    },
    {
        id: '764',
        ort: 'Basedow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17139',
    },
    {
        id: '765',
        ort: 'Basedow',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21483',
    },
    {
        id: '766',
        ort: 'Bassenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56220',
    },
    {
        id: '767',
        ort: 'Bassum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27211',
    },
    {
        id: '768',
        ort: 'Bastheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97654',
    },
    {
        id: '769',
        ort: 'Basthorst',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21493',
    },
    {
        id: '770',
        ort: 'Bastorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18230',
    },
    {
        id: '771',
        ort: 'Battenberg (Eder)',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '35088',
    },
    {
        id: '772',
        ort: 'Battenberg (Pfalz)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67271',
    },
    {
        id: '773',
        ort: 'Battweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66484',
    },
    {
        id: '774',
        ort: 'Baudenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91460',
    },
    {
        id: '775',
        ort: 'Bauler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53534',
    },
    {
        id: '776',
        ort: 'Bauler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '777',
        ort: 'Baumgarten',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18246',
    },
    {
        id: '778',
        ort: 'Baumholder',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55774',
    },
    {
        id: '779',
        ort: 'Baunach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96148',
    },
    {
        id: '780',
        ort: 'Baunatal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34225',
    },
    {
        id: '781',
        ort: 'Bausendorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54538',
    },
    {
        id: '782',
        ort: 'Baustert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '783',
        ort: 'Bautzen - Budyšin',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02625',
    },
    {
        id: '784',
        ort: 'Bawinkel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49844',
    },
    {
        id: '785',
        ort: 'Bayerbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '94137',
    },
    {
        id: '786',
        ort: 'Bayerbach b. Ergoldsbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84092',
    },
    {
        id: '787',
        ort: 'Bayerfeld-Steckweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67808',
    },
    {
        id: '788',
        ort: 'Bayerfeld-Steckweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67822',
    },
    {
        id: '789',
        ort: 'Bayerisch Eisenstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94252',
    },
    {
        id: '790',
        ort: 'Bayerisch Gmain',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Berchtesgadener Land',
        plz: '83457',
    },
    {
        id: '791',
        ort: 'Bayreuth',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '95444',
    },
    {
        id: '792',
        ort: 'Bayreuth',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '95445',
    },
    {
        id: '793',
        ort: 'Bayreuth',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '95447',
    },
    {
        id: '794',
        ort: 'Bayreuth',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '95448',
    },
    {
        id: '795',
        ort: 'Bayrischzell',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miesbach',
        plz: '83735',
    },
    {
        id: '796',
        ort: 'Bebensee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23816',
    },
    {
        id: '797',
        ort: 'Bebra',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36179',
    },
    {
        id: '798',
        ort: 'Becheln',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56132',
    },
    {
        id: '799',
        ort: 'Bechenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '800',
        ort: 'Becherbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '67827',
    },
    {
        id: '801',
        ort: 'Becherbach bei Kirn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55608',
    },
    {
        id: '802',
        ort: 'Bechhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91572',
    },
    {
        id: '803',
        ort: 'Bechhofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66894',
    },
    {
        id: '804',
        ort: 'Bechstedt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07426',
    },
    {
        id: '805',
        ort: 'Bechtheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67595',
    },
    {
        id: '806',
        ort: 'Bechtolsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '807',
        ort: 'Bechtsrieth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92699',
    },
    {
        id: '808',
        ort: 'Beckdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21643',
    },
    {
        id: '809',
        ort: 'Beckedorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31699',
    },
    {
        id: '810',
        ort: 'Beckeln',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Oldenburg',
        plz: '27243',
    },
    {
        id: '811',
        ort: 'Beckingen',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Merzig-Wadern',
        plz: '66701',
    },
    {
        id: '812',
        ort: 'Beckum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Warendorf',
        plz: '59269',
    },
    {
        id: '813',
        ort: 'Bedburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Erft-Kreis',
        plz: '50181',
    },
    {
        id: '814',
        ort: 'Bedburg-Hau',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '47551',
    },
    {
        id: '815',
        ort: 'Bedesbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66885',
    },
    {
        id: '816',
        ort: 'Beedenbostel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29355',
    },
    {
        id: '817',
        ort: 'Beelen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Warendorf',
        plz: '48361',
    },
    {
        id: '818',
        ort: 'Beelitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14547',
    },
    {
        id: '819',
        ort: 'Beendorf',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39343',
    },
    {
        id: '820',
        ort: 'Beeskow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15848',
    },
    {
        id: '821',
        ort: 'Beesten',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49832',
    },
    {
        id: '822',
        ort: 'Beetzendorf',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Altmarkkreis Salzwedel',
        plz: '38489',
    },
    {
        id: '823',
        ort: 'Beetzsee',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14778',
    },
    {
        id: '824',
        ort: 'Beetzseeheide',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14778',
    },
    {
        id: '825',
        ort: 'Beggerow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17111',
    },
    {
        id: '826',
        ort: 'Behlendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23919',
    },
    {
        id: '827',
        ort: 'Behrendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25850',
    },
    {
        id: '828',
        ort: 'Behrenhoff',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17498',
    },
    {
        id: '829',
        ort: 'Behren-Lübchin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17179',
    },
    {
        id: '830',
        ort: 'Behrensdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24321',
    },
    {
        id: '831',
        ort: 'Beidenfleth',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25573',
    },
    {
        id: '832',
        ort: 'Beiersdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02736',
    },
    {
        id: '833',
        ort: 'Beiersdorf-Freudenberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '16259',
    },
    {
        id: '834',
        ort: 'Beierstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38382',
    },
    {
        id: '835',
        ort: 'Beilingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54662',
    },
    {
        id: '836',
        ort: 'Beilngries',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '92339',
    },
    {
        id: '837',
        ort: 'Beilrode',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04886',
    },
    {
        id: '838',
        ort: 'Beilstein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '71543',
    },
    {
        id: '839',
        ort: 'Beilstein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '71717',
    },
    {
        id: '840',
        ort: 'Beilstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56814',
    },
    {
        id: '841',
        ort: 'Beimerstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89179',
    },
    {
        id: '842',
        ort: 'Beindersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67259',
    },
    {
        id: '843',
        ort: 'Beinerstadt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98660',
    },
    {
        id: '844',
        ort: 'Beinhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '845',
        ort: 'Bekdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25554',
    },
    {
        id: '846',
        ort: 'Bekmünde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25524',
    },
    {
        id: '847',
        ort: 'Bekond',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54340',
    },
    {
        id: '848',
        ort: 'Belau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24601',
    },
    {
        id: '849',
        ort: 'Beldorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '25557',
    },
    {
        id: '850',
        ort: 'Beldorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '25767',
    },
    {
        id: '851',
        ort: 'Belg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56858',
    },
    {
        id: '852',
        ort: 'Belgern-Schildau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04874',
    },
    {
        id: '853',
        ort: 'Belgern-Schildau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04889',
    },
    {
        id: '854',
        ort: 'Belgershain',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04683',
    },
    {
        id: '855',
        ort: 'Belgweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55469',
    },
    {
        id: '856',
        ort: 'Bell',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56745',
    },
    {
        id: '857',
        ort: 'Bellenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89287',
    },
    {
        id: '858',
        ort: 'Bellheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76756',
    },
    {
        id: '859',
        ort: 'Bell (Hunsrück)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56288',
    },
    {
        id: '860',
        ort: 'Bellingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '861',
        ort: 'Bellstedt',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '99713',
    },
    {
        id: '862',
        ort: 'Belm',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49191',
    },
    {
        id: '863',
        ort: 'Belrieth',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98617',
    },
    {
        id: '864',
        ort: 'Belsch',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '865',
        ort: 'Beltheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56290',
    },
    {
        id: '866',
        ort: 'Belum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21785',
    },
    {
        id: '867',
        ort: 'Bempflingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '72658',
    },
    {
        id: '868',
        ort: 'Bendestorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21227',
    },
    {
        id: '869',
        ort: 'Bendfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24217',
    },
    {
        id: '870',
        ort: 'Bendorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56170',
    },
    {
        id: '871',
        ort: 'Bendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '25557',
    },
    {
        id: '872',
        ort: 'Benediktbeuern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '83671',
    },
    {
        id: '873',
        ort: 'Bengel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54538',
    },
    {
        id: '874',
        ort: 'Bengerstorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19258',
    },
    {
        id: '875',
        ort: 'Benitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18258',
    },
    {
        id: '876',
        ort: 'Benndorf',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06308',
    },
    {
        id: '877',
        ort: 'Bennewitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04828',
    },
    {
        id: '878',
        ort: 'Bennhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67808',
    },
    {
        id: '879',
        ort: 'Benningen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87734',
    },
    {
        id: '880',
        ort: 'Benningen am Neckar',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71726',
    },
    {
        id: '881',
        ort: 'Bensdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14789',
    },
    {
        id: '882',
        ort: 'Bensheim',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '64625',
    },
    {
        id: '883',
        ort: 'Bentwisch',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18182',
    },
    {
        id: '884',
        ort: 'Bentzin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17129',
    },
    {
        id: '885',
        ort: 'Benz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17429',
    },
    {
        id: '886',
        ort: 'Benz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23970',
    },
    {
        id: '887',
        ort: 'Benzweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55494',
    },
    {
        id: '888',
        ort: 'Beratzhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93176',
    },
    {
        id: '889',
        ort: 'Berching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92334',
    },
    {
        id: '890',
        ort: 'Berchtesgaden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Berchtesgadener Land',
        plz: '83471',
    },
    {
        id: '891',
        ort: 'Bereborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '56769',
    },
    {
        id: '892',
        ort: 'Berenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '56766',
    },
    {
        id: '893',
        ort: 'Berg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '894',
        ort: 'Berg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Starnberg',
        plz: '82335',
    },
    {
        id: '895',
        ort: 'Berg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95180',
    },
    {
        id: '896',
        ort: 'Berg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53505',
    },
    {
        id: '897',
        ort: 'Berg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88276',
    },
    {
        id: '898',
        ort: 'Berga',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06536',
    },
    {
        id: '899',
        ort: 'Berga/Elster',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07980',
    },
    {
        id: '900',
        ort: 'Bergatreute',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88368',
    },
    {
        id: '901',
        ort: 'Berg b.Neumarkt i.d.OPf.',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92348',
    },
    {
        id: '902',
        ort: 'Berge',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '19348',
    },
    {
        id: '903',
        ort: 'Berge',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49626',
    },
    {
        id: '904',
        ort: 'Bergen',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08239',
    },
    {
        id: '905',
        ort: 'Bergen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83346',
    },
    {
        id: '906',
        ort: 'Bergen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91790',
    },
    {
        id: '907',
        ort: 'Bergen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55608',
    },
    {
        id: '908',
        ort: 'Bergen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29303',
    },
    {
        id: '909',
        ort: 'Bergen an der Dumme',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29468',
    },
    {
        id: '910',
        ort: 'Bergen auf Rügen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18528',
    },
    {
        id: '911',
        ort: 'Bergenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55469',
    },
    {
        id: '912',
        ort: 'Bergenhusen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24861',
    },
    {
        id: '913',
        ort: 'Bergewöhrden',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25779',
    },
    {
        id: '914',
        ort: 'Bergfeld',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38467',
    },
    {
        id: '915',
        ort: 'Berghaupten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77791',
    },
    {
        id: '916',
        ort: 'Berghausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56368',
    },
    {
        id: '917',
        ort: 'Bergheim',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Erft-Kreis',
        plz: '50126',
    },
    {
        id: '918',
        ort: 'Bergheim',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Erft-Kreis',
        plz: '50127',
    },
    {
        id: '919',
        ort: 'Bergheim',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Erft-Kreis',
        plz: '50129',
    },
    {
        id: '920',
        ort: 'Bergheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neuburg-Schrobenhausen',
        plz: '86673',
    },
    {
        id: '921',
        ort: 'Bergholz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17321',
    },
    {
        id: '922',
        ort: 'Berghülen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89180',
    },
    {
        id: '923',
        ort: 'Berg im Gau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neuburg-Schrobenhausen',
        plz: '86562',
    },
    {
        id: '924',
        ort: 'Bergisch Gladbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rheinisch-Bergischer Kreis',
        plz: '51427',
    },
    {
        id: '925',
        ort: 'Bergisch Gladbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rheinisch-Bergischer Kreis',
        plz: '51429',
    },
    {
        id: '926',
        ort: 'Bergisch Gladbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rheinisch-Bergischer Kreis',
        plz: '51465',
    },
    {
        id: '927',
        ort: 'Bergisch Gladbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rheinisch-Bergischer Kreis',
        plz: '51467',
    },
    {
        id: '928',
        ort: 'Bergisch Gladbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rheinisch-Bergischer Kreis',
        plz: '51469',
    },
    {
        id: '929',
        ort: 'Bergkamen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Unna',
        plz: '59192',
    },
    {
        id: '930',
        ort: 'Bergkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '85232',
    },
    {
        id: '931',
        ort: 'Berglangenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55776',
    },
    {
        id: '932',
        ort: 'Berglen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '73663',
    },
    {
        id: '933',
        ort: 'Berglern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '85459',
    },
    {
        id: '934',
        ort: 'Berglicht',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54426',
    },
    {
        id: '935',
        ort: 'Bergneustadt',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Oberbergischer Kreis',
        plz: '51702',
    },
    {
        id: '936',
        ort: 'Berg (Pfalz)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76768',
    },
    {
        id: '937',
        ort: 'Bergrheinfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97493',
    },
    {
        id: '938',
        ort: 'Bergtheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97241',
    },
    {
        id: '939',
        ort: 'Bergweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '940',
        ort: 'Beringstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '25575',
    },
    {
        id: '941',
        ort: 'Berkatal',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37297',
    },
    {
        id: '942',
        ort: 'Berka vor dem Hainich',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '99826',
    },
    {
        id: '943',
        ort: 'Berkenbrück',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15518',
    },
    {
        id: '944',
        ort: 'Berkenthin',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23919',
    },
    {
        id: '945',
        ort: 'Berkheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88450',
    },
    {
        id: '946',
        ort: 'Berkoth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '947',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10115',
    },
    {
        id: '948',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10117',
    },
    {
        id: '949',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10119',
    },
    {
        id: '950',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10178',
    },
    {
        id: '951',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10179',
    },
    {
        id: '952',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10243',
    },
    {
        id: '953',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10245',
    },
    {
        id: '954',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10247',
    },
    {
        id: '955',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10249',
    },
    {
        id: '956',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10315',
    },
    {
        id: '957',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10317',
    },
    {
        id: '958',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10318',
    },
    {
        id: '959',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10319',
    },
    {
        id: '960',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10365',
    },
    {
        id: '961',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10367',
    },
    {
        id: '962',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10369',
    },
    {
        id: '963',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10405',
    },
    {
        id: '964',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10407',
    },
    {
        id: '965',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10409',
    },
    {
        id: '966',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10435',
    },
    {
        id: '967',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10437',
    },
    {
        id: '968',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10439',
    },
    {
        id: '969',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10551',
    },
    {
        id: '970',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10553',
    },
    {
        id: '971',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10555',
    },
    {
        id: '972',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10557',
    },
    {
        id: '973',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10559',
    },
    {
        id: '974',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10585',
    },
    {
        id: '975',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10587',
    },
    {
        id: '976',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10589',
    },
    {
        id: '977',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10623',
    },
    {
        id: '978',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10625',
    },
    {
        id: '979',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10627',
    },
    {
        id: '980',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10629',
    },
    {
        id: '981',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10707',
    },
    {
        id: '982',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10709',
    },
    {
        id: '983',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10711',
    },
    {
        id: '984',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10713',
    },
    {
        id: '985',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10715',
    },
    {
        id: '986',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10717',
    },
    {
        id: '987',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10719',
    },
    {
        id: '988',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10777',
    },
    {
        id: '989',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10779',
    },
    {
        id: '990',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10781',
    },
    {
        id: '991',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10783',
    },
    {
        id: '992',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10785',
    },
    {
        id: '993',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10787',
    },
    {
        id: '994',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10789',
    },
    {
        id: '995',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10823',
    },
    {
        id: '996',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10825',
    },
    {
        id: '997',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10827',
    },
    {
        id: '998',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10829',
    },
    {
        id: '999',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10961',
    },
    {
        id: '1000',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10963',
    },
    {
        id: '1001',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10965',
    },
    {
        id: '1002',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10967',
    },
    {
        id: '1003',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10969',
    },
    {
        id: '1004',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10997',
    },
    {
        id: '1005',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '10999',
    },
    {
        id: '1006',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12043',
    },
    {
        id: '1007',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12045',
    },
    {
        id: '1008',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12047',
    },
    {
        id: '1009',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12049',
    },
    {
        id: '1010',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12051',
    },
    {
        id: '1011',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12053',
    },
    {
        id: '1012',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12055',
    },
    {
        id: '1013',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12057',
    },
    {
        id: '1014',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12059',
    },
    {
        id: '1015',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12099',
    },
    {
        id: '1016',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12101',
    },
    {
        id: '1017',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12103',
    },
    {
        id: '1018',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12105',
    },
    {
        id: '1019',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12107',
    },
    {
        id: '1020',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12109',
    },
    {
        id: '1021',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12157',
    },
    {
        id: '1022',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12159',
    },
    {
        id: '1023',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12161',
    },
    {
        id: '1024',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12163',
    },
    {
        id: '1025',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12165',
    },
    {
        id: '1026',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12167',
    },
    {
        id: '1027',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12169',
    },
    {
        id: '1028',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12203',
    },
    {
        id: '1029',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12205',
    },
    {
        id: '1030',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12207',
    },
    {
        id: '1031',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12209',
    },
    {
        id: '1032',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12247',
    },
    {
        id: '1033',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12249',
    },
    {
        id: '1034',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12277',
    },
    {
        id: '1035',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12279',
    },
    {
        id: '1036',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12305',
    },
    {
        id: '1037',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12307',
    },
    {
        id: '1038',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12309',
    },
    {
        id: '1039',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12347',
    },
    {
        id: '1040',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12349',
    },
    {
        id: '1041',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12351',
    },
    {
        id: '1042',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12353',
    },
    {
        id: '1043',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12355',
    },
    {
        id: '1044',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12357',
    },
    {
        id: '1045',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12359',
    },
    {
        id: '1046',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12435',
    },
    {
        id: '1047',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12437',
    },
    {
        id: '1048',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12439',
    },
    {
        id: '1049',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12459',
    },
    {
        id: '1050',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12487',
    },
    {
        id: '1051',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12489',
    },
    {
        id: '1052',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12524',
    },
    {
        id: '1053',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12526',
    },
    {
        id: '1054',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12527',
    },
    {
        id: '1055',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12555',
    },
    {
        id: '1056',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12557',
    },
    {
        id: '1057',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12559',
    },
    {
        id: '1058',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12587',
    },
    {
        id: '1059',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12589',
    },
    {
        id: '1060',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12619',
    },
    {
        id: '1061',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12621',
    },
    {
        id: '1062',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12623',
    },
    {
        id: '1063',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12627',
    },
    {
        id: '1064',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12629',
    },
    {
        id: '1065',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12679',
    },
    {
        id: '1066',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12681',
    },
    {
        id: '1067',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12683',
    },
    {
        id: '1068',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12685',
    },
    {
        id: '1069',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12687',
    },
    {
        id: '1070',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '12689',
    },
    {
        id: '1071',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13051',
    },
    {
        id: '1072',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13053',
    },
    {
        id: '1073',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13055',
    },
    {
        id: '1074',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13057',
    },
    {
        id: '1075',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13059',
    },
    {
        id: '1076',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13086',
    },
    {
        id: '1077',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13088',
    },
    {
        id: '1078',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13089',
    },
    {
        id: '1079',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13125',
    },
    {
        id: '1080',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13127',
    },
    {
        id: '1081',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13129',
    },
    {
        id: '1082',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13156',
    },
    {
        id: '1083',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13158',
    },
    {
        id: '1084',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13159',
    },
    {
        id: '1085',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13187',
    },
    {
        id: '1086',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13189',
    },
    {
        id: '1087',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13347',
    },
    {
        id: '1088',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13349',
    },
    {
        id: '1089',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13351',
    },
    {
        id: '1090',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13353',
    },
    {
        id: '1091',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13355',
    },
    {
        id: '1092',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13357',
    },
    {
        id: '1093',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13359',
    },
    {
        id: '1094',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13403',
    },
    {
        id: '1095',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13405',
    },
    {
        id: '1096',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13407',
    },
    {
        id: '1097',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13409',
    },
    {
        id: '1098',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13435',
    },
    {
        id: '1099',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13437',
    },
    {
        id: '1100',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13439',
    },
    {
        id: '1101',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13465',
    },
    {
        id: '1102',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13467',
    },
    {
        id: '1103',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13469',
    },
    {
        id: '1104',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13503',
    },
    {
        id: '1105',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13505',
    },
    {
        id: '1106',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13507',
    },
    {
        id: '1107',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13509',
    },
    {
        id: '1108',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13581',
    },
    {
        id: '1109',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13583',
    },
    {
        id: '1110',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13585',
    },
    {
        id: '1111',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13587',
    },
    {
        id: '1112',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13589',
    },
    {
        id: '1113',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13591',
    },
    {
        id: '1114',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13593',
    },
    {
        id: '1115',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13595',
    },
    {
        id: '1116',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13597',
    },
    {
        id: '1117',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13599',
    },
    {
        id: '1118',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13627',
    },
    {
        id: '1119',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '13629',
    },
    {
        id: '1120',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '14050',
    },
    {
        id: '1121',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '14052',
    },
    {
        id: '1122',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '14053',
    },
    {
        id: '1123',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '14055',
    },
    {
        id: '1124',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '14057',
    },
    {
        id: '1125',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '14059',
    },
    {
        id: '1126',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '14089',
    },
    {
        id: '1127',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '14109',
    },
    {
        id: '1128',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '14129',
    },
    {
        id: '1129',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '14163',
    },
    {
        id: '1130',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '14165',
    },
    {
        id: '1131',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '14167',
    },
    {
        id: '1132',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '14169',
    },
    {
        id: '1133',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '14193',
    },
    {
        id: '1134',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '14195',
    },
    {
        id: '1135',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '14197',
    },
    {
        id: '1136',
        ort: 'Berlin',
        bundesland: 'Berlin',
        landkreis: '',
        plz: '14199',
    },
    {
        id: '1137',
        ort: 'Berlingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '1138',
        ort: 'Berlingerode',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37339',
    },
    {
        id: '1139',
        ort: 'Bermatingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88697',
    },
    {
        id: '1140',
        ort: 'Bermel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '1141',
        ort: 'Bermersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67593',
    },
    {
        id: '1142',
        ort: 'Bermersheim vor der Höhe',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '1143',
        ort: 'Bernau',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16321',
    },
    {
        id: '1144',
        ort: 'Bernau am Chiemsee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83233',
    },
    {
        id: '1145',
        ort: 'Bernau im Schwarzwald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79872',
    },
    {
        id: '1146',
        ort: 'Bernbeuren',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '86975',
    },
    {
        id: '1147',
        ort: 'Bernburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '06406',
    },
    {
        id: '1148',
        ort: 'Berndorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54578',
    },
    {
        id: '1149',
        ort: 'Berndroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56370',
    },
    {
        id: '1150',
        ort: 'Berne',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wesermarsch',
        plz: '27804',
    },
    {
        id: '1151',
        ort: 'Berngau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92361',
    },
    {
        id: '1152',
        ort: 'Bernhardswald',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93170',
    },
    {
        id: '1153',
        ort: 'Bernitt',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18246',
    },
    {
        id: '1154',
        ort: 'Bernitt',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18249',
    },
    {
        id: '1155',
        ort: 'Bernkastel-Kues',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54470',
    },
    {
        id: '1156',
        ort: 'Bernkastel-Kues',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54492',
    },
    {
        id: '1157',
        ort: 'Bernried',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94505',
    },
    {
        id: '1158',
        ort: 'Bernried am Starnberger See',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82347',
    },
    {
        id: '1159',
        ort: 'Bernsdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '09337',
    },
    {
        id: '1160',
        ort: 'Bernsdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02994',
    },
    {
        id: '1161',
        ort: 'Bernstadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89182',
    },
    {
        id: '1162',
        ort: 'Bernstadt auf dem Eigen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02748',
    },
    {
        id: '1163',
        ort: 'Bernstorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23936',
    },
    {
        id: '1164',
        ort: 'Berod bei Hachenburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57614',
    },
    {
        id: '1165',
        ort: 'Berod bei Wallmerod',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56414',
    },
    {
        id: '1166',
        ort: 'Berscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '1167',
        ort: 'Berschweiler bei Baumholder',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55777',
    },
    {
        id: '1168',
        ort: 'Berschweiler bei Kirn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55608',
    },
    {
        id: '1169',
        ort: 'Bersenbrück',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49593',
    },
    {
        id: '1170',
        ort: 'Bersteland',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15910',
    },
    {
        id: '1171',
        ort: 'Bertsdorf-Hörnitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02763',
    },
    {
        id: '1172',
        ort: 'Berumbur',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26524',
    },
    {
        id: '1173',
        ort: 'Berzhahn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '1174',
        ort: 'Berzhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57632',
    },
    {
        id: '1175',
        ort: 'Bescheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54413',
    },
    {
        id: '1176',
        ort: 'Beschendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23738',
    },
    {
        id: '1177',
        ort: 'Besdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25584',
    },
    {
        id: '1178',
        ort: 'Beselich',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65614',
    },
    {
        id: '1179',
        ort: 'Besenthal',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23899',
    },
    {
        id: '1180',
        ort: 'Beseritz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17039',
    },
    {
        id: '1181',
        ort: 'Besigheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '74354',
    },
    {
        id: '1182',
        ort: 'Besitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19258',
    },
    {
        id: '1183',
        ort: 'Bessenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63856',
    },
    {
        id: '1184',
        ort: 'Bestensee',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15741',
    },
    {
        id: '1185',
        ort: 'Bestwig',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Hochsauerlandkreis',
        plz: '59909',
    },
    {
        id: '1186',
        ort: 'Bethenhausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07554',
    },
    {
        id: '1187',
        ort: 'Betteldorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '1188',
        ort: 'Bettendorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56355',
    },
    {
        id: '1189',
        ort: 'Bettenfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54533',
    },
    {
        id: '1190',
        ort: 'Bettingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54646',
    },
    {
        id: '1191',
        ort: 'Betzdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57518',
    },
    {
        id: '1192',
        ort: 'Betzendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21386',
    },
    {
        id: '1193',
        ort: 'Betzenstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '91282',
    },
    {
        id: '1194',
        ort: 'Betzenweiler',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88422',
    },
    {
        id: '1195',
        ort: 'Betzigau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87488',
    },
    {
        id: '1196',
        ort: 'Beulich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56283',
    },
    {
        id: '1197',
        ort: 'Beuren',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56825',
    },
    {
        id: '1198',
        ort: 'Beuren',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '72660',
    },
    {
        id: '1199',
        ort: 'Beuren (Hochwald)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54413',
    },
    {
        id: '1200',
        ort: 'Beuron',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '88631',
    },
    {
        id: '1201',
        ort: 'Beutelsbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94501',
    },
    {
        id: '1202',
        ort: 'Bevern',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25355',
    },
    {
        id: '1203',
        ort: 'Bevern',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37639',
    },
    {
        id: '1204',
        ort: 'Beverstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '27616',
    },
    {
        id: '1205',
        ort: 'Beverungen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Höxter',
        plz: '37688',
    },
    {
        id: '1206',
        ort: 'Bexbach',
        bundesland: 'Saarland',
        landkreis: 'Saarpfalz-Kreis',
        plz: '66450',
    },
    {
        id: '1207',
        ort: 'Biberach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77781',
    },
    {
        id: '1208',
        ort: 'Biberach an der Riß',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88400',
    },
    {
        id: '1209',
        ort: 'Biberbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86485',
    },
    {
        id: '1210',
        ort: 'Bibertal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89346',
    },
    {
        id: '1211',
        ort: 'Biblis',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '68647',
    },
    {
        id: '1212',
        ort: 'Bibow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19417',
    },
    {
        id: '1213',
        ort: 'Bibra',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07768',
    },
    {
        id: '1214',
        ort: 'Biburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '93354',
    },
    {
        id: '1215',
        ort: 'Bichl',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '83673',
    },
    {
        id: '1216',
        ort: 'Bickenbach',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64404',
    },
    {
        id: '1217',
        ort: 'Bickenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56291',
    },
    {
        id: '1218',
        ort: 'Bickendorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '1219',
        ort: 'Bidingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87651',
    },
    {
        id: '1220',
        ort: 'Biebelnheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '1221',
        ort: 'Biebelried',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97318',
    },
    {
        id: '1222',
        ort: 'Biebelsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55546',
    },
    {
        id: '1223',
        ort: 'Bieberehren',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97243',
    },
    {
        id: '1224',
        ort: 'Biebergemünd',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63599',
    },
    {
        id: '1225',
        ort: 'Biebern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55471',
    },
    {
        id: '1226',
        ort: 'Biebertal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35444',
    },
    {
        id: '1227',
        ort: 'Biebesheim',
        bundesland: 'Hessen',
        landkreis: 'Kreis Groß-Gerau',
        plz: '64584',
    },
    {
        id: '1228',
        ort: 'Biebrich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56370',
    },
    {
        id: '1229',
        ort: 'Biedenkopf',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35216',
    },
    {
        id: '1230',
        ort: 'Biederbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79215',
    },
    {
        id: '1231',
        ort: 'Biederitz',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Jerichower Land',
        plz: '39175',
    },
    {
        id: '1232',
        ort: 'Biedershausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66917',
    },
    {
        id: '1233',
        ort: 'Biedesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67308',
    },
    {
        id: '1234',
        ort: 'Bielefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '33602',
    },
    {
        id: '1235',
        ort: 'Bielefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '33604',
    },
    {
        id: '1236',
        ort: 'Bielefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '33605',
    },
    {
        id: '1237',
        ort: 'Bielefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '33607',
    },
    {
        id: '1238',
        ort: 'Bielefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '33609',
    },
    {
        id: '1239',
        ort: 'Bielefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '33611',
    },
    {
        id: '1240',
        ort: 'Bielefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '33613',
    },
    {
        id: '1241',
        ort: 'Bielefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '33615',
    },
    {
        id: '1242',
        ort: 'Bielefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '33617',
    },
    {
        id: '1243',
        ort: 'Bielefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '33619',
    },
    {
        id: '1244',
        ort: 'Bielefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '33647',
    },
    {
        id: '1245',
        ort: 'Bielefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '33649',
    },
    {
        id: '1246',
        ort: 'Bielefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '33659',
    },
    {
        id: '1247',
        ort: 'Bielefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '33689',
    },
    {
        id: '1248',
        ort: 'Bielefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '33699',
    },
    {
        id: '1249',
        ort: 'Bielefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '33719',
    },
    {
        id: '1250',
        ort: 'Bielefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '33729',
    },
    {
        id: '1251',
        ort: 'Bielefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '33739',
    },
    {
        id: '1252',
        ort: 'Biendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18230',
    },
    {
        id: '1253',
        ort: 'Bienenbüttel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29553',
    },
    {
        id: '1254',
        ort: 'Bienstädt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99100',
    },
    {
        id: '1255',
        ort: 'Biersdorf am See',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '1256',
        ort: 'Biesdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '1257',
        ort: 'Biesenthal',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16359',
    },
    {
        id: '1258',
        ort: 'Biessenhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87640',
    },
    {
        id: '1259',
        ort: 'Bietigheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '76467',
    },
    {
        id: '1260',
        ort: 'Bietigheim-Bissingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '74321',
    },
    {
        id: '1261',
        ort: 'Bilkheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56414',
    },
    {
        id: '1262',
        ort: 'Billerbeck',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Coesfeld',
        plz: '48727',
    },
    {
        id: '1263',
        ort: 'Billigheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74842',
    },
    {
        id: '1264',
        ort: 'Billigheim-Ingenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76831',
    },
    {
        id: '1265',
        ort: 'Bilsen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25485',
    },
    {
        id: '1266',
        ort: 'Bilshausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37434',
    },
    {
        id: '1267',
        ort: 'Bimöhlen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24576',
    },
    {
        id: '1268',
        ort: 'Binau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74862',
    },
    {
        id: '1269',
        ort: 'Bindlach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95463',
    },
    {
        id: '1270',
        ort: 'Bingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '72511',
    },
    {
        id: '1271',
        ort: 'Bingen am Rhein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55411',
    },
    {
        id: '1272',
        ort: 'Binnen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31619',
    },
    {
        id: '1273',
        ort: 'Binningen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56754',
    },
    {
        id: '1274',
        ort: 'Binsfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '1275',
        ort: 'Binswangen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '86637',
    },
    {
        id: '1276',
        ort: 'Binz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18609',
    },
    {
        id: '1277',
        ort: 'Binzen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79589',
    },
    {
        id: '1278',
        ort: 'Bippen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49626',
    },
    {
        id: '1279',
        ort: 'Birenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73102',
    },
    {
        id: '1280',
        ort: 'Birgel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54587',
    },
    {
        id: '1281',
        ort: 'Birgland',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92262',
    },
    {
        id: '1282',
        ort: 'Birkenau',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '69488',
    },
    {
        id: '1283',
        ort: 'Birkenbeul',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57589',
    },
    {
        id: '1284',
        ort: 'Birkenfeld',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75217',
    },
    {
        id: '1285',
        ort: 'Birkenfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97834',
    },
    {
        id: '1286',
        ort: 'Birkenfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55765',
    },
    {
        id: '1287',
        ort: 'Birkenfelde',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '1288',
        ort: 'Birkenheide',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67134',
    },
    {
        id: '1289',
        ort: 'Birken-Honigsessen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57587',
    },
    {
        id: '1290',
        ort: 'Birkenhördt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76889',
    },
    {
        id: '1291',
        ort: 'Birkenwerder',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16547',
    },
    {
        id: '1292',
        ort: 'Birkheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56291',
    },
    {
        id: '1293',
        ort: 'Birkweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76831',
    },
    {
        id: '1294',
        ort: 'Birlenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65626',
    },
    {
        id: '1295',
        ort: 'Birnbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57612',
    },
    {
        id: '1296',
        ort: 'Birresborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54574',
    },
    {
        id: '1297',
        ort: 'Birstein',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63633',
    },
    {
        id: '1298',
        ort: 'Birtlingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54634',
    },
    {
        id: '1299',
        ort: 'Birx',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98634',
    },
    {
        id: '1300',
        ort: 'Bischberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96120',
    },
    {
        id: '1301',
        ort: 'Bischbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97836',
    },
    {
        id: '1302',
        ort: 'Bischbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97852',
    },
    {
        id: '1303',
        ort: 'Bischheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67294',
    },
    {
        id: '1304',
        ort: 'Bischoffen',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35649',
    },
    {
        id: '1305',
        ort: 'Bischofrod',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98553',
    },
    {
        id: '1306',
        ort: 'Bischofroda',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '99826',
    },
    {
        id: '1307',
        ort: 'Bischofsgrün',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95493',
    },
    {
        id: '1308',
        ort: 'Bischofsheim',
        bundesland: 'Hessen',
        landkreis: 'Kreis Groß-Gerau',
        plz: '65474',
    },
    {
        id: '1309',
        ort: 'Bischofsheim i.d.Rhön',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97653',
    },
    {
        id: '1310',
        ort: 'Bischofsmais',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94253',
    },
    {
        id: '1311',
        ort: 'Bischofswerda',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01877',
    },
    {
        id: '1312',
        ort: 'Bischofswiesen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Berchtesgadener Land',
        plz: '83483',
    },
    {
        id: '1313',
        ort: 'Bischweier',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '76476',
    },
    {
        id: '1314',
        ort: 'Bisingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72406',
    },
    {
        id: '1315',
        ort: 'Bismark (Altmark)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39628',
    },
    {
        id: '1316',
        ort: 'Bismark (Altmark)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39629',
    },
    {
        id: '1317',
        ort: 'Bispingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29646',
    },
    {
        id: '1318',
        ort: 'Bissee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24582',
    },
    {
        id: '1319',
        ort: 'Bissendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49143',
    },
    {
        id: '1320',
        ort: 'Bissersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67281',
    },
    {
        id: '1321',
        ort: 'Bissingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '86657',
    },
    {
        id: '1322',
        ort: 'Bissingen an der Teck',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73266',
    },
    {
        id: '1323',
        ort: 'Bisterschied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67806',
    },
    {
        id: '1324',
        ort: 'Bitburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54634',
    },
    {
        id: '1325',
        ort: 'Bitterfeld-Wolfen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06749',
    },
    {
        id: '1326',
        ort: 'Bitterfeld-Wolfen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06766',
    },
    {
        id: '1327',
        ort: 'Bitterfeld-Wolfen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06803',
    },
    {
        id: '1328',
        ort: 'Bitterfeld-Wolfen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06808',
    },
    {
        id: '1329',
        ort: 'Bitz',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72475',
    },
    {
        id: '1330',
        ort: 'Bitzen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57539',
    },
    {
        id: '1331',
        ort: 'Blaibach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93476',
    },
    {
        id: '1332',
        ort: 'Blaichach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87544',
    },
    {
        id: '1333',
        ort: 'Blankenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63825',
    },
    {
        id: '1334',
        ort: 'Blankenberg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19412',
    },
    {
        id: '1335',
        ort: 'Blankenburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '06502',
    },
    {
        id: '1336',
        ort: 'Blankenburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38889',
    },
    {
        id: '1337',
        ort: 'Blankenburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38895',
    },
    {
        id: '1338',
        ort: 'Blankenburg',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99955',
    },
    {
        id: '1339',
        ort: 'Blankenfelde-Mahlow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Teltow-Fläming',
        plz: '15827',
    },
    {
        id: '1340',
        ort: 'Blankenfelde-Mahlow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Teltow-Fläming',
        plz: '15831',
    },
    {
        id: '1341',
        ort: 'Blankenhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18182',
    },
    {
        id: '1342',
        ort: 'Blankenhain',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99444',
    },
    {
        id: '1343',
        ort: 'Blankenheim',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Euskirchen',
        plz: '53945',
    },
    {
        id: '1344',
        ort: 'Blankenheim',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06528',
    },
    {
        id: '1345',
        ort: 'Blankenhof',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17039',
    },
    {
        id: '1346',
        ort: 'Blankenrath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56865',
    },
    {
        id: '1347',
        ort: 'Blankensee',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17322',
    },
    {
        id: '1348',
        ort: 'Blankensee',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17237',
    },
    {
        id: '1349',
        ort: 'Blaubach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66869',
    },
    {
        id: '1350',
        ort: 'Blaubeuren',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89143',
    },
    {
        id: '1351',
        ort: 'Blaufelden',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74572',
    },
    {
        id: '1352',
        ort: 'Blaustein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89134',
    },
    {
        id: '1353',
        ort: 'Bleckede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '19273',
    },
    {
        id: '1354',
        ort: 'Bleckede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21354',
    },
    {
        id: '1355',
        ort: 'Bleckhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '1356',
        ort: 'Bleialf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54608',
    },
    {
        id: '1357',
        ort: 'Bleicherode',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Nordhausen',
        plz: '99752',
    },
    {
        id: '1358',
        ort: 'Blekendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24327',
    },
    {
        id: '1359',
        ort: 'Blender',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Verden',
        plz: '27337',
    },
    {
        id: '1360',
        ort: 'Blesewitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17392',
    },
    {
        id: '1361',
        ort: 'Bleyen-Genschmar',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15328',
    },
    {
        id: '1362',
        ort: 'Bliedersdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21640',
    },
    {
        id: '1363',
        ort: 'Bliesdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '16269',
    },
    {
        id: '1364',
        ort: 'Blieskastel',
        bundesland: 'Saarland',
        landkreis: 'Saarpfalz-Kreis',
        plz: '66440',
    },
    {
        id: '1365',
        ort: 'Bliestorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23847',
    },
    {
        id: '1366',
        ort: 'Blievenstorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19306',
    },
    {
        id: '1367',
        ort: 'Blindheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89434',
    },
    {
        id: '1368',
        ort: 'Blomberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '32825',
    },
    {
        id: '1369',
        ort: 'Blomberg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26487',
    },
    {
        id: '1370',
        ort: 'Blomesche Wildnis',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25348',
    },
    {
        id: '1371',
        ort: 'Blowatz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23974',
    },
    {
        id: '1372',
        ort: 'Blumberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78176',
    },
    {
        id: '1373',
        ort: 'Blumenholz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17237',
    },
    {
        id: '1374',
        ort: 'Blumenthal',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24241',
    },
    {
        id: '1375',
        ort: 'Blunk',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23813',
    },
    {
        id: '1376',
        ort: 'Bobeck',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '1377',
        ort: 'Bobenheim am Berg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67273',
    },
    {
        id: '1378',
        ort: 'Bobenheim-Roxheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67240',
    },
    {
        id: '1379',
        ort: 'Bobenthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '76891',
    },
    {
        id: '1380',
        ort: 'Böbing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82389',
    },
    {
        id: '1381',
        ort: 'Bobingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86399',
    },
    {
        id: '1382',
        ort: 'Böbingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '67482',
    },
    {
        id: '1383',
        ort: 'Böbingen an der Rems',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73560',
    },
    {
        id: '1384',
        ort: 'Bobitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23966',
    },
    {
        id: '1385',
        ort: 'Bobitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23996',
    },
    {
        id: '1386',
        ort: 'Böblingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71032',
    },
    {
        id: '1387',
        ort: 'Böblingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71034',
    },
    {
        id: '1388',
        ort: 'Böbrach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94255',
    },
    {
        id: '1389',
        ort: 'Bobritzsch-Hilbersdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09627',
    },
    {
        id: '1390',
        ort: 'Bobzin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '1391',
        ort: 'Böchingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76833',
    },
    {
        id: '1392',
        ort: 'Bocholt',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '46395',
    },
    {
        id: '1393',
        ort: 'Bocholt',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '46397',
    },
    {
        id: '1394',
        ort: 'Bocholt',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '46399',
    },
    {
        id: '1395',
        ort: 'Bochum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44787',
    },
    {
        id: '1396',
        ort: 'Bochum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44789',
    },
    {
        id: '1397',
        ort: 'Bochum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44791',
    },
    {
        id: '1398',
        ort: 'Bochum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44793',
    },
    {
        id: '1399',
        ort: 'Bochum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44795',
    },
    {
        id: '1400',
        ort: 'Bochum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44797',
    },
    {
        id: '1401',
        ort: 'Bochum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44799',
    },
    {
        id: '1402',
        ort: 'Bochum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44801',
    },
    {
        id: '1403',
        ort: 'Bochum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44803',
    },
    {
        id: '1404',
        ort: 'Bochum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44805',
    },
    {
        id: '1405',
        ort: 'Bochum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44807',
    },
    {
        id: '1406',
        ort: 'Bochum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44809',
    },
    {
        id: '1407',
        ort: 'Bochum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44866',
    },
    {
        id: '1408',
        ort: 'Bochum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44867',
    },
    {
        id: '1409',
        ort: 'Bochum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44869',
    },
    {
        id: '1410',
        ort: 'Bochum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44879',
    },
    {
        id: '1411',
        ort: 'Bochum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44892',
    },
    {
        id: '1412',
        ort: 'Bochum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44894',
    },
    {
        id: '1413',
        ort: 'Bocka',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07589',
    },
    {
        id: '1414',
        ort: 'Bockau',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '08324',
    },
    {
        id: '1415',
        ort: 'Bockenau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '1416',
        ort: 'Bockenem',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31167',
    },
    {
        id: '1417',
        ort: 'Bockenheim an der Weinstraße',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67278',
    },
    {
        id: '1418',
        ort: 'Bockhorn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '85461',
    },
    {
        id: '1419',
        ort: 'Bockhorn',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Friesland',
        plz: '26345',
    },
    {
        id: '1420',
        ort: 'Bockhorst',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26897',
    },
    {
        id: '1421',
        ort: 'Bodelshausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72411',
    },
    {
        id: '1422',
        ort: 'Bodelwitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07381',
    },
    {
        id: '1423',
        ort: 'Boden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '1424',
        ort: 'Bodenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '53539',
    },
    {
        id: '1425',
        ort: 'Bodenfelde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Northeim',
        plz: '37194',
    },
    {
        id: '1426',
        ort: 'Bodenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55294',
    },
    {
        id: '1427',
        ort: 'Bodenkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84155',
    },
    {
        id: '1428',
        ort: 'Bodenmais',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94249',
    },
    {
        id: '1429',
        ort: 'Bodenrode-Westhausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37308',
    },
    {
        id: '1430',
        ort: 'Bodensee',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37434',
    },
    {
        id: '1431',
        ort: 'Bodenwerder',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37619',
    },
    {
        id: '1432',
        ort: 'Bodenwöhr',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92439',
    },
    {
        id: '1433',
        ort: 'Bodman-Ludwigshafen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78351',
    },
    {
        id: '1434',
        ort: 'Bodnegg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88285',
    },
    {
        id: '1435',
        ort: 'Bodolz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88131',
    },
    {
        id: '1436',
        ort: 'Böel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24401',
    },
    {
        id: '1437',
        ort: 'Böel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24405',
    },
    {
        id: '1438',
        ort: 'Böel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24891',
    },
    {
        id: '1439',
        ort: 'Boffzen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37691',
    },
    {
        id: '1440',
        ort: 'Bogel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '1441',
        ort: 'Bogen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94327',
    },
    {
        id: '1442',
        ort: 'Böhen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87736',
    },
    {
        id: '1443',
        ort: 'Böhlen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04564',
    },
    {
        id: '1444',
        ort: 'Böhl-Iggelheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67459',
    },
    {
        id: '1445',
        ort: 'Böhme',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29693',
    },
    {
        id: '1446',
        ort: 'Böhmenkirch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '89558',
    },
    {
        id: '1447',
        ort: 'Böhmfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85113',
    },
    {
        id: '1448',
        ort: 'Bohmstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25853',
    },
    {
        id: '1449',
        ort: 'Bohmte',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49163',
    },
    {
        id: '1450',
        ort: 'Böhnhusen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24220',
    },
    {
        id: '1451',
        ort: 'Boiensdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23974',
    },
    {
        id: '1452',
        ort: 'Boitze',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21368',
    },
    {
        id: '1453',
        ort: 'Boitzenburger Land',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17268',
    },
    {
        id: '1454',
        ort: 'Boizenburg/Elbe',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19258',
    },
    {
        id: '1455',
        ort: 'Bokel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25364',
    },
    {
        id: '1456',
        ort: 'Bokel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24802',
    },
    {
        id: '1457',
        ort: 'Bokelrehm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25596',
    },
    {
        id: '1458',
        ort: 'Bokensdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38556',
    },
    {
        id: '1459',
        ort: 'Bokholt-Hanredder',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25335',
    },
    {
        id: '1460',
        ort: 'Bokhorst',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25560',
    },
    {
        id: '1461',
        ort: 'Bokhorst',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25572',
    },
    {
        id: '1462',
        ort: 'Böklund',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24860',
    },
    {
        id: '1463',
        ort: 'Boksee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24220',
    },
    {
        id: '1464',
        ort: 'Bolanden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67295',
    },
    {
        id: '1465',
        ort: 'Boldekow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17392',
    },
    {
        id: '1466',
        ort: 'Böllen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79677',
    },
    {
        id: '1467',
        ort: 'Bollenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55624',
    },
    {
        id: '1468',
        ort: 'Böllenborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76887',
    },
    {
        id: '1469',
        ort: 'Bollendorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54669',
    },
    {
        id: '1470',
        ort: 'Bollewick',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17207',
    },
    {
        id: '1471',
        ort: 'Bollingstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24855',
    },
    {
        id: '1472',
        ort: 'Bollschweil',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79283',
    },
    {
        id: '1473',
        ort: 'Bölsberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57648',
    },
    {
        id: '1474',
        ort: 'Bolsterlang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87538',
    },
    {
        id: '1475',
        ort: 'Boltenhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23946',
    },
    {
        id: '1476',
        ort: 'Boms',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88361',
    },
    {
        id: '1477',
        ort: 'Bondelum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25850',
    },
    {
        id: '1478',
        ort: 'Bondorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71149',
    },
    {
        id: '1479',
        ort: 'Bönebüttel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24620',
    },
    {
        id: '1480',
        ort: 'Bonefeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56579',
    },
    {
        id: '1481',
        ort: 'Bönen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Unna',
        plz: '59199',
    },
    {
        id: '1482',
        ort: 'Bonerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54316',
    },
    {
        id: '1483',
        ort: 'Bongard',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '53539',
    },
    {
        id: '1484',
        ort: 'Bonn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '53111',
    },
    {
        id: '1485',
        ort: 'Bonn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '53113',
    },
    {
        id: '1486',
        ort: 'Bonn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '53115',
    },
    {
        id: '1487',
        ort: 'Bonn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '53117',
    },
    {
        id: '1488',
        ort: 'Bonn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '53119',
    },
    {
        id: '1489',
        ort: 'Bonn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '53121',
    },
    {
        id: '1490',
        ort: 'Bonn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '53123',
    },
    {
        id: '1491',
        ort: 'Bonn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '53125',
    },
    {
        id: '1492',
        ort: 'Bonn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '53127',
    },
    {
        id: '1493',
        ort: 'Bonn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '53129',
    },
    {
        id: '1494',
        ort: 'Bonn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '53173',
    },
    {
        id: '1495',
        ort: 'Bonn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '53175',
    },
    {
        id: '1496',
        ort: 'Bonn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '53177',
    },
    {
        id: '1497',
        ort: 'Bonn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '53179',
    },
    {
        id: '1498',
        ort: 'Bonn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '53225',
    },
    {
        id: '1499',
        ort: 'Bonn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '53227',
    },
    {
        id: '1500',
        ort: 'Bonn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '53229',
    },
    {
        id: '1501',
        ort: 'Bonndorf im Schwarzwald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79848',
    },
    {
        id: '1502',
        ort: 'Bönnigheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '74357',
    },
    {
        id: '1503',
        ort: 'Bönningstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25474',
    },
    {
        id: '1504',
        ort: 'Bonstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86486',
    },
    {
        id: '1505',
        ort: 'Boock',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17322',
    },
    {
        id: '1506',
        ort: 'Boos',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '1507',
        ort: 'Boos',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87737',
    },
    {
        id: '1508',
        ort: 'Boos',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '1509',
        ort: 'Boostedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24598',
    },
    {
        id: '1510',
        ort: 'Bopfingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73441',
    },
    {
        id: '1511',
        ort: 'Boppard',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56154',
    },
    {
        id: '1512',
        ort: 'Borchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Paderborn',
        plz: '33178',
    },
    {
        id: '1513',
        ort: 'Bördeaue',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '39435',
    },
    {
        id: '1514',
        ort: 'Börde-Hakel',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '39448',
    },
    {
        id: '1515',
        ort: 'Bördeland',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '39221',
    },
    {
        id: '1516',
        ort: 'Bordelum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25821',
    },
    {
        id: '1517',
        ort: 'Bordelum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25842',
    },
    {
        id: '1518',
        ort: 'Bordelum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25852',
    },
    {
        id: '1519',
        ort: 'Bordesholm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24582',
    },
    {
        id: '1520',
        ort: 'Boren',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24392',
    },
    {
        id: '1521',
        ort: 'Börfink',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '54422',
    },
    {
        id: '1522',
        ort: 'Borgdorf-Seedorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24589',
    },
    {
        id: '1523',
        ort: 'Borgentreich',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Höxter',
        plz: '34434',
    },
    {
        id: '1524',
        ort: 'Börger',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26904',
    },
    {
        id: '1525',
        ort: 'Börgerende-Rethwisch',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18211',
    },
    {
        id: '1526',
        ort: 'Borgholzhausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Gütersloh',
        plz: '33829',
    },
    {
        id: '1527',
        ort: 'Borgstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24794',
    },
    {
        id: '1528',
        ort: 'Borgsum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25938',
    },
    {
        id: '1529',
        ort: 'Borgwedel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24857',
    },
    {
        id: '1530',
        ort: 'Borken',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '46325',
    },
    {
        id: '1531',
        ort: 'Borken (Hessen)',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34582',
    },
    {
        id: '1532',
        ort: 'Borkheide',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14822',
    },
    {
        id: '1533',
        ort: 'Borkow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19406',
    },
    {
        id: '1534',
        ort: 'Borkum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26757',
    },
    {
        id: '1535',
        ort: 'Borkwalde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14822',
    },
    {
        id: '1536',
        ort: 'Borler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '53539',
    },
    {
        id: '1537',
        ort: 'Börm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24863',
    },
    {
        id: '1538',
        ort: 'Borna',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04552',
    },
    {
        id: '1539',
        ort: 'Born a. Darß',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18375',
    },
    {
        id: '1540',
        ort: 'Borne',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '39435',
    },
    {
        id: '1541',
        ort: 'Bornhagen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '1542',
        ort: 'Bornheim',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53332',
    },
    {
        id: '1543',
        ort: 'Bornheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55237',
    },
    {
        id: '1544',
        ort: 'Bornheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76879',
    },
    {
        id: '1545',
        ort: 'Bornholt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '25557',
    },
    {
        id: '1546',
        ort: 'Bornholt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '25725',
    },
    {
        id: '1547',
        ort: 'Bornhöved',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24619',
    },
    {
        id: '1548',
        ort: 'Bornich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56348',
    },
    {
        id: '1549',
        ort: 'Börnichen',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09437',
    },
    {
        id: '1550',
        ort: 'Börnsen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21039',
    },
    {
        id: '1551',
        ort: 'Bornstedt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06295',
    },
    {
        id: '1552',
        ort: 'Borod',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57614',
    },
    {
        id: '1553',
        ort: 'Borrentin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17111',
    },
    {
        id: '1554',
        ort: 'Börrstadt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67725',
    },
    {
        id: '1555',
        ort: 'Börsborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66904',
    },
    {
        id: '1556',
        ort: 'Borsdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04451',
    },
    {
        id: '1557',
        ort: 'Borsfleth',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25376',
    },
    {
        id: '1558',
        ort: 'Börslingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89177',
    },
    {
        id: '1559',
        ort: 'Börßum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38312',
    },
    {
        id: '1560',
        ort: 'Borstel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24616',
    },
    {
        id: '1561',
        ort: 'Borstel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27246',
    },
    {
        id: '1562',
        ort: 'Borstel-Hohenraden',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25494',
    },
    {
        id: '1563',
        ort: 'Borstorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23881',
    },
    {
        id: '1564',
        ort: 'Börtlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73104',
    },
    {
        id: '1565',
        ort: 'Borxleben',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '06556',
    },
    {
        id: '1566',
        ort: 'Bosau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23715',
    },
    {
        id: '1567',
        ort: 'Bosbüll',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25899',
    },
    {
        id: '1568',
        ort: 'Bösdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24306',
    },
    {
        id: '1569',
        ort: 'Bösel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cloppenburg',
        plz: '26219',
    },
    {
        id: '1570',
        ort: 'Bosenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66887',
    },
    {
        id: '1571',
        ort: 'Bösenbrunn',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08606',
    },
    {
        id: '1572',
        ort: 'Bösingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78662',
    },
    {
        id: '1573',
        ort: 'Bösleben-Wüllersleben',
        bundesland: 'Thüringen',
        landkreis: 'Ilm-Kreis',
        plz: '99310',
    },
    {
        id: '1574',
        ort: 'Bötersen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27367',
    },
    {
        id: '1575',
        ort: 'Bothel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27386',
    },
    {
        id: '1576',
        ort: 'Bothkamp',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24250',
    },
    {
        id: '1577',
        ort: 'Bottenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66504',
    },
    {
        id: '1578',
        ort: 'Böttingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78583',
    },
    {
        id: '1579',
        ort: 'Bottrop',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '46236',
    },
    {
        id: '1580',
        ort: 'Bottrop',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '46238',
    },
    {
        id: '1581',
        ort: 'Bottrop',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '46240',
    },
    {
        id: '1582',
        ort: 'Bottrop',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '46242',
    },
    {
        id: '1583',
        ort: 'Bottrop',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '46244',
    },
    {
        id: '1584',
        ort: 'Bötzingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79268',
    },
    {
        id: '1585',
        ort: 'Bous',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Saarlouis',
        plz: '66359',
    },
    {
        id: '1586',
        ort: 'Bovenau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24796',
    },
    {
        id: '1587',
        ort: 'Bovenden',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37120',
    },
    {
        id: '1588',
        ort: 'Boxberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Main-Tauber-Kreis',
        plz: '97944',
    },
    {
        id: '1589',
        ort: 'Boxberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '1590',
        ort: 'Boxberg/O.L. - Hamor',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02943',
    },
    {
        id: '1591',
        ort: 'Böxlund',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24994',
    },
    {
        id: '1592',
        ort: 'Braak',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22145',
    },
    {
        id: '1593',
        ort: 'Brachbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57555',
    },
    {
        id: '1594',
        ort: 'Brachtendorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56761',
    },
    {
        id: '1595',
        ort: 'Brachttal',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63636',
    },
    {
        id: '1596',
        ort: 'Brackel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21438',
    },
    {
        id: '1597',
        ort: 'Brackenheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74336',
    },
    {
        id: '1598',
        ort: 'Braderup',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25923',
    },
    {
        id: '1599',
        ort: 'Brahlstorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19273',
    },
    {
        id: '1600',
        ort: 'Brahmenau',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07554',
    },
    {
        id: '1601',
        ort: 'Brake',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wesermarsch',
        plz: '26919',
    },
    {
        id: '1602',
        ort: 'Brakel',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Höxter',
        plz: '33034',
    },
    {
        id: '1603',
        ort: 'Brammer',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24793',
    },
    {
        id: '1604',
        ort: 'Bramsche',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49565',
    },
    {
        id: '1605',
        ort: 'Bramstedtlund',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25926',
    },
    {
        id: '1606',
        ort: 'Brand',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95682',
    },
    {
        id: '1607',
        ort: 'Brande-Hörnerkirchen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25364',
    },
    {
        id: '1608',
        ort: 'Brandenburg an der Havel',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '14770',
    },
    {
        id: '1609',
        ort: 'Brandenburg an der Havel',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '14772',
    },
    {
        id: '1610',
        ort: 'Brandenburg an der Havel',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '14774',
    },
    {
        id: '1611',
        ort: 'Brandenburg an der Havel',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '14776',
    },
    {
        id: '1612',
        ort: 'Brand-Erbisdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09618',
    },
    {
        id: '1613',
        ort: 'Brandis',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04821',
    },
    {
        id: '1614',
        ort: 'Brandis',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04824',
    },
    {
        id: '1615',
        ort: 'Brandscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '1616',
        ort: 'Brandscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54608',
    },
    {
        id: '1617',
        ort: 'Brannenburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83098',
    },
    {
        id: '1618',
        ort: 'Braubach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56338',
    },
    {
        id: '1619',
        ort: 'Brauneberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54470',
    },
    {
        id: '1620',
        ort: 'Brauneberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54472',
    },
    {
        id: '1621',
        ort: 'Braunfels',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35619',
    },
    {
        id: '1622',
        ort: 'Braunichswalde',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07580',
    },
    {
        id: '1623',
        ort: 'Braunlage',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Goslar',
        plz: '37444',
    },
    {
        id: '1624',
        ort: 'Braunlage',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Goslar',
        plz: '38700',
    },
    {
        id: '1625',
        ort: 'Bräunlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78199',
    },
    {
        id: '1626',
        ort: 'Braunsbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74542',
    },
    {
        id: '1627',
        ort: 'Braunsbedra',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06242',
    },
    {
        id: '1628',
        ort: 'Braunsbedra',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06259',
    },
    {
        id: '1629',
        ort: 'Braunschweig',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38100',
    },
    {
        id: '1630',
        ort: 'Braunschweig',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38102',
    },
    {
        id: '1631',
        ort: 'Braunschweig',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38104',
    },
    {
        id: '1632',
        ort: 'Braunschweig',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38106',
    },
    {
        id: '1633',
        ort: 'Braunschweig',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38108',
    },
    {
        id: '1634',
        ort: 'Braunschweig',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38110',
    },
    {
        id: '1635',
        ort: 'Braunschweig',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38112',
    },
    {
        id: '1636',
        ort: 'Braunschweig',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38114',
    },
    {
        id: '1637',
        ort: 'Braunschweig',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38116',
    },
    {
        id: '1638',
        ort: 'Braunschweig',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38118',
    },
    {
        id: '1639',
        ort: 'Braunschweig',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38120',
    },
    {
        id: '1640',
        ort: 'Braunschweig',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38122',
    },
    {
        id: '1641',
        ort: 'Braunschweig',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38124',
    },
    {
        id: '1642',
        ort: 'Braunschweig',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38126',
    },
    {
        id: '1643',
        ort: 'Braunshorn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56288',
    },
    {
        id: '1644',
        ort: 'Braunweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '1645',
        ort: 'Brauweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55606',
    },
    {
        id: '1646',
        ort: 'Brechen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65611',
    },
    {
        id: '1647',
        ort: 'Brecht',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '1648',
        ort: 'Breckerfeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Ennepe-Ruhr-Kreis',
        plz: '58339',
    },
    {
        id: '1649',
        ort: 'Breddenberg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26897',
    },
    {
        id: '1650',
        ort: 'Breddin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16845',
    },
    {
        id: '1651',
        ort: 'Breddorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27412',
    },
    {
        id: '1652',
        ort: 'Breddorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27726',
    },
    {
        id: '1653',
        ort: 'Bredenbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24796',
    },
    {
        id: '1654',
        ort: 'Bredenfelde',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17153',
    },
    {
        id: '1655',
        ort: 'Bredstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25821',
    },
    {
        id: '1656',
        ort: 'Breege',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18556',
    },
    {
        id: '1657',
        ort: 'Breese',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '19322',
    },
    {
        id: '1658',
        ort: 'Breesen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17091',
    },
    {
        id: '1659',
        ort: 'Breest',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17089',
    },
    {
        id: '1660',
        ort: 'Brehme',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37339',
    },
    {
        id: '1661',
        ort: 'Breidenbach',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35236',
    },
    {
        id: '1662',
        ort: 'Breiholz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24797',
    },
    {
        id: '1663',
        ort: 'Breisach am Rhein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79206',
    },
    {
        id: '1664',
        ort: 'Breit',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54426',
    },
    {
        id: '1665',
        ort: 'Breitbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '96151',
    },
    {
        id: '1666',
        ort: 'Breitbrunn am Chiemsee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83254',
    },
    {
        id: '1667',
        ort: 'Breitenau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56237',
    },
    {
        id: '1668',
        ort: 'Breitenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66916',
    },
    {
        id: '1669',
        ort: 'Breitenbach am Herzberg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36287',
    },
    {
        id: '1670',
        ort: 'Breitenberg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25597',
    },
    {
        id: '1671',
        ort: 'Breitenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94139',
    },
    {
        id: '1672',
        ort: 'Breitenbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87739',
    },
    {
        id: '1673',
        ort: 'Breitenbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92363',
    },
    {
        id: '1674',
        ort: 'Breitenbrunn/Erzgebirge',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '08359',
    },
    {
        id: '1675',
        ort: 'Breitenburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25524',
    },
    {
        id: '1676',
        ort: 'Breitenfelde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23881',
    },
    {
        id: '1677',
        ort: 'Breitengüßbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96149',
    },
    {
        id: '1678',
        ort: 'Breitenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55592',
    },
    {
        id: '1679',
        ort: 'Breitenthal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '86488',
    },
    {
        id: '1680',
        ort: 'Breitenthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '1681',
        ort: 'Breitenworbis',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37339',
    },
    {
        id: '1682',
        ort: 'Breitingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89183',
    },
    {
        id: '1683',
        ort: 'Breitnau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79874',
    },
    {
        id: '1684',
        ort: 'Breitscheid',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35767',
    },
    {
        id: '1685',
        ort: 'Breitscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53547',
    },
    {
        id: '1686',
        ort: 'Breitscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55422',
    },
    {
        id: '1687',
        ort: 'Breitscheidt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57539',
    },
    {
        id: '1688',
        ort: 'Breitungen/Werra',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98597',
    },
    {
        id: '1689',
        ort: 'Brekendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24811',
    },
    {
        id: '1690',
        ort: 'Breklum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25821',
    },
    {
        id: '1691',
        ort: 'Bremberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56370',
    },
    {
        id: '1692',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '27568',
    },
    {
        id: '1693',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28195',
    },
    {
        id: '1694',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28197',
    },
    {
        id: '1695',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28199',
    },
    {
        id: '1696',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28201',
    },
    {
        id: '1697',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28203',
    },
    {
        id: '1698',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28205',
    },
    {
        id: '1699',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28207',
    },
    {
        id: '1700',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28209',
    },
    {
        id: '1701',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28211',
    },
    {
        id: '1702',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28213',
    },
    {
        id: '1703',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28215',
    },
    {
        id: '1704',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28217',
    },
    {
        id: '1705',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28219',
    },
    {
        id: '1706',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28237',
    },
    {
        id: '1707',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28239',
    },
    {
        id: '1708',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28259',
    },
    {
        id: '1709',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28277',
    },
    {
        id: '1710',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28279',
    },
    {
        id: '1711',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28307',
    },
    {
        id: '1712',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28309',
    },
    {
        id: '1713',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28325',
    },
    {
        id: '1714',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28327',
    },
    {
        id: '1715',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28329',
    },
    {
        id: '1716',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28355',
    },
    {
        id: '1717',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28357',
    },
    {
        id: '1718',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28359',
    },
    {
        id: '1719',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28717',
    },
    {
        id: '1720',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28719',
    },
    {
        id: '1721',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28755',
    },
    {
        id: '1722',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28757',
    },
    {
        id: '1723',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28759',
    },
    {
        id: '1724',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28777',
    },
    {
        id: '1725',
        ort: 'Bremen',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '28779',
    },
    {
        id: '1726',
        ort: 'Bremerhaven',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '27568',
    },
    {
        id: '1727',
        ort: 'Bremerhaven',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '27570',
    },
    {
        id: '1728',
        ort: 'Bremerhaven',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '27572',
    },
    {
        id: '1729',
        ort: 'Bremerhaven',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '27574',
    },
    {
        id: '1730',
        ort: 'Bremerhaven',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '27576',
    },
    {
        id: '1731',
        ort: 'Bremerhaven',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '27578',
    },
    {
        id: '1732',
        ort: 'Bremerhaven',
        bundesland: 'Bremen',
        landkreis: '',
        plz: '27580',
    },
    {
        id: '1733',
        ort: 'Bremervörde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27432',
    },
    {
        id: '1734',
        ort: 'Bremm',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56814',
    },
    {
        id: '1735',
        ort: 'Bremsnitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '1736',
        ort: 'Brenk',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '56651',
    },
    {
        id: '1737',
        ort: 'Brennberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93179',
    },
    {
        id: '1738',
        ort: 'Brensbach',
        bundesland: 'Hessen',
        landkreis: 'Odenwaldkreis',
        plz: '64395',
    },
    {
        id: '1739',
        ort: 'Brenz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19306',
    },
    {
        id: '1740',
        ort: 'Bresegard bei Eldena',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19294',
    },
    {
        id: '1741',
        ort: 'Bresegard bei Picher',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '1742',
        ort: 'Brest',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21698',
    },
    {
        id: '1743',
        ort: 'Bretten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '75015',
    },
    {
        id: '1744',
        ort: 'Bretthausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '1745',
        ort: 'Bretzenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55559',
    },
    {
        id: '1746',
        ort: 'Bretzfeld',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Hohenlohekreis',
        plz: '74626',
    },
    {
        id: '1747',
        ort: 'Breuberg',
        bundesland: 'Hessen',
        landkreis: 'Odenwaldkreis',
        plz: '64747',
    },
    {
        id: '1748',
        ort: 'Breuna',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34479',
    },
    {
        id: '1749',
        ort: 'Breunigweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67725',
    },
    {
        id: '1750',
        ort: 'Brevörde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37647',
    },
    {
        id: '1751',
        ort: 'Brey',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56321',
    },
    {
        id: '1752',
        ort: 'Breydin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16230',
    },
    {
        id: '1753',
        ort: 'Brickeln',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25712',
    },
    {
        id: '1754',
        ort: 'Briedel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56850',
    },
    {
        id: '1755',
        ort: 'Briedel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56867',
    },
    {
        id: '1756',
        ort: 'Brieden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56829',
    },
    {
        id: '1757',
        ort: 'Briedern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56820',
    },
    {
        id: '1758',
        ort: 'Brieselang',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14656',
    },
    {
        id: '1759',
        ort: 'Briesen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03096',
    },
    {
        id: '1760',
        ort: 'Briesen (Mark)',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15518',
    },
    {
        id: '1761',
        ort: 'Brieskow-Finkenheerd',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15295',
    },
    {
        id: '1762',
        ort: 'Brietlingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21382',
    },
    {
        id: '1763',
        ort: 'Brietzig',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17309',
    },
    {
        id: '1764',
        ort: 'Brigachtal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78086',
    },
    {
        id: '1765',
        ort: 'Briggow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17153',
    },
    {
        id: '1766',
        ort: 'Brilon',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Hochsauerlandkreis',
        plz: '59929',
    },
    {
        id: '1767',
        ort: 'Brimingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54646',
    },
    {
        id: '1768',
        ort: 'Brinjahe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24816',
    },
    {
        id: '1769',
        ort: 'Brinkum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26835',
    },
    {
        id: '1770',
        ort: 'Britz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16230',
    },
    {
        id: '1771',
        ort: 'Bröbberow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18258',
    },
    {
        id: '1772',
        ort: 'Brockel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27386',
    },
    {
        id: '1773',
        ort: 'Bröckel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29356',
    },
    {
        id: '1774',
        ort: 'Brockscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '1775',
        ort: 'Brockum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49448',
    },
    {
        id: '1776',
        ort: 'Brodenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56332',
    },
    {
        id: '1777',
        ort: 'Brodersby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24398',
    },
    {
        id: '1778',
        ort: 'Brodersby-Goltoft',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24864',
    },
    {
        id: '1779',
        ort: 'Brodersdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24235',
    },
    {
        id: '1780',
        ort: 'Broderstorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18184',
    },
    {
        id: '1781',
        ort: 'Brohl',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56754',
    },
    {
        id: '1782',
        ort: 'Brohl-Lützing',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '56656',
    },
    {
        id: '1783',
        ort: 'Brokdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25554',
    },
    {
        id: '1784',
        ort: 'Brokdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25576',
    },
    {
        id: '1785',
        ort: 'Brokstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '24616',
    },
    {
        id: '1786',
        ort: 'Brombachtal',
        bundesland: 'Hessen',
        landkreis: 'Odenwaldkreis',
        plz: '64753',
    },
    {
        id: '1787',
        ort: 'Brome',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38465',
    },
    {
        id: '1788',
        ort: 'Bronkow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '03205',
    },
    {
        id: '1789',
        ort: 'Bröthen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21514',
    },
    {
        id: '1790',
        ort: 'Brotterode-Trusetal',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98596',
    },
    {
        id: '1791',
        ort: 'Bruch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '1792',
        ort: 'Bruchertseifen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57539',
    },
    {
        id: '1793',
        ort: 'Bruchhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53572',
    },
    {
        id: '1794',
        ort: 'Bruchhausen-Vilsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27305',
    },
    {
        id: '1795',
        ort: 'Bruchköbel',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63486',
    },
    {
        id: '1796',
        ort: 'Bruchmühlbach-Miesau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66892',
    },
    {
        id: '1797',
        ort: 'Bruchsal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76646',
    },
    {
        id: '1798',
        ort: 'Bruchstedt',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99955',
    },
    {
        id: '1799',
        ort: 'Bruchweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '1800',
        ort: 'Bruchweiler-Bärenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '76891',
    },
    {
        id: '1801',
        ort: 'Bruck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85567',
    },
    {
        id: '1802',
        ort: 'Brück',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14822',
    },
    {
        id: '1803',
        ort: 'Bruckberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84079',
    },
    {
        id: '1804',
        ort: 'Bruckberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91590',
    },
    {
        id: '1805',
        ort: 'Brücken',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '1806',
        ort: 'Brücken-Hackpfüffel',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06528',
    },
    {
        id: '1807',
        ort: 'Brücken (Pfalz)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66904',
    },
    {
        id: '1808',
        ort: 'Bruck i.d.OPf.',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92436',
    },
    {
        id: '1809',
        ort: 'Bruckmühl',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83052',
    },
    {
        id: '1810',
        ort: 'Brücktal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '53539',
    },
    {
        id: '1811',
        ort: 'Brüel',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19412',
    },
    {
        id: '1812',
        ort: 'Brügge',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24582',
    },
    {
        id: '1813',
        ort: 'Brüggen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Viersen',
        plz: '41379',
    },
    {
        id: '1814',
        ort: 'Brühl',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Erft-Kreis',
        plz: '50321',
    },
    {
        id: '1815',
        ort: 'Brühl',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '68782',
    },
    {
        id: '1816',
        ort: 'Brunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93164',
    },
    {
        id: '1817',
        ort: 'Brunn',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17039',
    },
    {
        id: '1818',
        ort: 'Brunnen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neuburg-Schrobenhausen',
        plz: '86564',
    },
    {
        id: '1819',
        ort: 'Brunnthal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '85649',
    },
    {
        id: '1820',
        ort: 'Brünn/Thür.',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98673',
    },
    {
        id: '1821',
        ort: 'Brunow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19357',
    },
    {
        id: '1822',
        ort: 'Brunow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19372',
    },
    {
        id: '1823',
        ort: 'Brunsbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22946',
    },
    {
        id: '1824',
        ort: 'Brunsbüttel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25541',
    },
    {
        id: '1825',
        ort: 'Brunsmark',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23883',
    },
    {
        id: '1826',
        ort: 'Brunstorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21524',
    },
    {
        id: '1827',
        ort: 'Brünzow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17509',
    },
    {
        id: '1828',
        ort: 'Bruschied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55606',
    },
    {
        id: '1829',
        ort: 'Brüsewitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19071',
    },
    {
        id: '1830',
        ort: 'Brüssow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17326',
    },
    {
        id: '1831',
        ort: 'Bruttig-Fankel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56814',
    },
    {
        id: '1832',
        ort: 'Bubach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56288',
    },
    {
        id: '1833',
        ort: 'Bubenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55270',
    },
    {
        id: '1834',
        ort: 'Bubenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67308',
    },
    {
        id: '1835',
        ort: 'Bubenreuth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91088',
    },
    {
        id: '1836',
        ort: 'Bubesheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89347',
    },
    {
        id: '1837',
        ort: 'Buborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67742',
    },
    {
        id: '1838',
        ort: 'Bubsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78585',
    },
    {
        id: '1839',
        ort: 'Buch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '1840',
        ort: 'Buch',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89290',
    },
    {
        id: '1841',
        ort: 'Buch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56290',
    },
    {
        id: '1842',
        ort: 'Bucha',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07751',
    },
    {
        id: '1843',
        ort: 'Buch am Buchrain',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '85656',
    },
    {
        id: '1844',
        ort: 'Buch am Erlbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84172',
    },
    {
        id: '1845',
        ort: 'Buch am Wald',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91592',
    },
    {
        id: '1846',
        ort: 'Buchbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84428',
    },
    {
        id: '1847',
        ort: 'Buchbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97320',
    },
    {
        id: '1848',
        ort: 'Buchdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86675',
    },
    {
        id: '1849',
        ort: 'Büchel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56823',
    },
    {
        id: '1850',
        ort: 'Büchel',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99638',
    },
    {
        id: '1851',
        ort: 'Büchen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21514',
    },
    {
        id: '1852',
        ort: 'Buchenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79256',
    },
    {
        id: '1853',
        ort: 'Büchenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Roth',
        plz: '91186',
    },
    {
        id: '1854',
        ort: 'Buchenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87474',
    },
    {
        id: '1855',
        ort: 'Büchenbeuren',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55491',
    },
    {
        id: '1856',
        ort: 'Buchen (Odenwald)',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74722',
    },
    {
        id: '1857',
        ort: 'Buchet',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54608',
    },
    {
        id: '1858',
        ort: 'Buchfart',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99438',
    },
    {
        id: '1859',
        ort: 'Buchheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78567',
    },
    {
        id: '1860',
        ort: 'Buchheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '88637',
    },
    {
        id: '1861',
        ort: 'Buchhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94533',
    },
    {
        id: '1862',
        ort: 'Buchholz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25712',
    },
    {
        id: '1863',
        ort: 'Buchholz',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31710',
    },
    {
        id: '1864',
        ort: 'Buchholz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23911',
    },
    {
        id: '1865',
        ort: 'Buchholz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17209',
    },
    {
        id: '1866',
        ort: 'Buchholz (Aller)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29690',
    },
    {
        id: '1867',
        ort: 'Buchholz in der Nordheide',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21244',
    },
    {
        id: '1868',
        ort: 'Buchholz (Westerwald)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53567',
    },
    {
        id: '1869',
        ort: 'Buchhorst',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21481',
    },
    {
        id: '1870',
        ort: 'Büchlberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94124',
    },
    {
        id: '1871',
        ort: 'Buchloe',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '86807',
    },
    {
        id: '1872',
        ort: 'Buckautal',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14793',
    },
    {
        id: '1873',
        ort: 'Bückeburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31675',
    },
    {
        id: '1874',
        ort: 'Bücken',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '27333',
    },
    {
        id: '1875',
        ort: 'Buckenhof',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91054',
    },
    {
        id: '1876',
        ort: 'Buckow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15377',
    },
    {
        id: '1877',
        ort: 'Büdelsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24782',
    },
    {
        id: '1878',
        ort: 'Budenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55469',
    },
    {
        id: '1879',
        ort: 'Budenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55257',
    },
    {
        id: '1880',
        ort: 'Büdesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54610',
    },
    {
        id: '1881',
        ort: 'Büdingen',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '63654',
    },
    {
        id: '1882',
        ort: 'Büdlich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54426',
    },
    {
        id: '1883',
        ort: 'Bugewitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17398',
    },
    {
        id: '1884',
        ort: 'Buggenhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17440',
    },
    {
        id: '1885',
        ort: 'Buggingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79426',
    },
    {
        id: '1886',
        ort: 'Bühl',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '77815',
    },
    {
        id: '1887',
        ort: 'Buhla',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37339',
    },
    {
        id: '1888',
        ort: 'Buhlenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '1889',
        ort: 'Bühlertal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '77830',
    },
    {
        id: '1890',
        ort: 'Bühlertann',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74424',
    },
    {
        id: '1891',
        ort: 'Bühlerzell',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74426',
    },
    {
        id: '1892',
        ort: 'Bühnsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23845',
    },
    {
        id: '1893',
        ort: 'Bühren',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37127',
    },
    {
        id: '1894',
        ort: 'Bülkau',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21782',
    },
    {
        id: '1895',
        ort: 'Bullay',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56859',
    },
    {
        id: '1896',
        ort: 'Bullenkuhlen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25355',
    },
    {
        id: '1897',
        ort: 'Bülow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19089',
    },
    {
        id: '1898',
        ort: 'Bülstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27412',
    },
    {
        id: '1899',
        ort: 'Bülstringen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39345',
    },
    {
        id: '1900',
        ort: 'Bunde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26831',
    },
    {
        id: '1901',
        ort: 'Bünde',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Herford',
        plz: '32257',
    },
    {
        id: '1902',
        ort: 'Bundenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55626',
    },
    {
        id: '1903',
        ort: 'Bundenthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '76891',
    },
    {
        id: '1904',
        ort: 'Bundorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '97494',
    },
    {
        id: '1905',
        ort: 'Bünsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24794',
    },
    {
        id: '1906',
        ort: 'Bunsoh',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25767',
    },
    {
        id: '1907',
        ort: 'Burbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Siegen-Wittgenstein',
        plz: '57299',
    },
    {
        id: '1908',
        ort: 'Burbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '1909',
        ort: 'Bürdenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '56593',
    },
    {
        id: '1910',
        ort: 'Büren',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Paderborn',
        plz: '33142',
    },
    {
        id: '1911',
        ort: 'Burg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Jerichower Land',
        plz: '39288',
    },
    {
        id: '1912',
        ort: 'Burg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54646',
    },
    {
        id: '1913',
        ort: 'Burgau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89331',
    },
    {
        id: '1914',
        ort: 'Burgberg i.Allgäu',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87545',
    },
    {
        id: '1915',
        ort: 'Burgbernheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91593',
    },
    {
        id: '1916',
        ort: 'Burgbrohl',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '56659',
    },
    {
        id: '1917',
        ort: 'Burg (Dithmarschen)',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25712',
    },
    {
        id: '1918',
        ort: 'Burgdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '31303',
    },
    {
        id: '1919',
        ort: 'Burgdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38272',
    },
    {
        id: '1920',
        ort: 'Burgebrach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96138',
    },
    {
        id: '1921',
        ort: 'Bürgel',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07616',
    },
    {
        id: '1922',
        ort: 'Burgen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56332',
    },
    {
        id: '1923',
        ort: 'Burgen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54472',
    },
    {
        id: '1924',
        ort: 'Burggen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '86977',
    },
    {
        id: '1925',
        ort: 'Burghaslach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '96152',
    },
    {
        id: '1926',
        ort: 'Burghaun',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36151',
    },
    {
        id: '1927',
        ort: 'Burghausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84489',
    },
    {
        id: '1928',
        ort: 'Burgheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neuburg-Schrobenhausen',
        plz: '86666',
    },
    {
        id: '1929',
        ort: 'Burgkirchen an der Alz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84508',
    },
    {
        id: '1930',
        ort: 'Burgkunstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lichtenfels',
        plz: '96224',
    },
    {
        id: '1931',
        ort: 'Burglahr',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57632',
    },
    {
        id: '1932',
        ort: 'Burglauer',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97724',
    },
    {
        id: '1933',
        ort: 'Burglengenfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '93133',
    },
    {
        id: '1934',
        ort: 'Burg (Mosel)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '56843',
    },
    {
        id: '1935',
        ort: 'Burgoberbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91595',
    },
    {
        id: '1936',
        ort: 'Burgpreppach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '97496',
    },
    {
        id: '1937',
        ort: 'Burgrieden',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88483',
    },
    {
        id: '1938',
        ort: 'Burgsalach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91790',
    },
    {
        id: '1939',
        ort: 'Burgschwalbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65558',
    },
    {
        id: '1940',
        ort: 'Burgsinn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97775',
    },
    {
        id: '1941',
        ort: 'Burgsponheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '1942',
        ort: 'Burg (Spreewald)',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03096',
    },
    {
        id: '1943',
        ort: 'Burgstädt',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09217',
    },
    {
        id: '1944',
        ort: 'Bürgstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63927',
    },
    {
        id: '1945',
        ort: 'Burgstall',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39517',
    },
    {
        id: '1946',
        ort: 'Burg Stargard',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17094',
    },
    {
        id: '1947',
        ort: 'Burgstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71576',
    },
    {
        id: '1948',
        ort: 'Burgthann',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '90559',
    },
    {
        id: '1949',
        ort: 'Burgwald',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '35099',
    },
    {
        id: '1950',
        ort: 'Burgwalde',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '1951',
        ort: 'Burgwedel',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30938',
    },
    {
        id: '1952',
        ort: 'Burgwindheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96154',
    },
    {
        id: '1953',
        ort: 'Burk',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91596',
    },
    {
        id: '1954',
        ort: 'Burkardroth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97705',
    },
    {
        id: '1955',
        ort: 'Burkau - Porchow',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01906',
    },
    {
        id: '1956',
        ort: 'Burkhardtsdorf',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09235',
    },
    {
        id: '1957',
        ort: 'Burladingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72393',
    },
    {
        id: '1958',
        ort: 'Burow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17089',
    },
    {
        id: '1959',
        ort: 'Burrweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76835',
    },
    {
        id: '1960',
        ort: 'Burscheid',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rheinisch-Bergischer Kreis',
        plz: '51399',
    },
    {
        id: '1961',
        ort: 'Bürstadt',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '68642',
    },
    {
        id: '1962',
        ort: 'Burtenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89349',
    },
    {
        id: '1963',
        ort: 'Burtscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54424',
    },
    {
        id: '1964',
        ort: 'Burweg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21709',
    },
    {
        id: '1965',
        ort: 'Buschvitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18528',
    },
    {
        id: '1966',
        ort: 'Busdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24866',
    },
    {
        id: '1967',
        ort: 'Buseck',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35418',
    },
    {
        id: '1968',
        ort: 'Busenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66996',
    },
    {
        id: '1969',
        ort: 'Busenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '76891',
    },
    {
        id: '1970',
        ort: 'Busenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57612',
    },
    {
        id: '1971',
        ort: 'Busenwurth',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25719',
    },
    {
        id: '1972',
        ort: 'Büsingen am Hochrhein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78266',
    },
    {
        id: '1973',
        ort: 'Büsum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25761',
    },
    {
        id: '1974',
        ort: 'Büsumer Deichhausen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25761',
    },
    {
        id: '1975',
        ort: 'Butjadingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wesermarsch',
        plz: '26969',
    },
    {
        id: '1976',
        ort: 'Bütow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17209',
    },
    {
        id: '1977',
        ort: 'Büttel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25572',
    },
    {
        id: '1978',
        ort: 'Büttelborn',
        bundesland: 'Hessen',
        landkreis: 'Kreis Groß-Gerau',
        plz: '64572',
    },
    {
        id: '1979',
        ort: 'Buttenheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96155',
    },
    {
        id: '1980',
        ort: 'Buttenwiesen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '86647',
    },
    {
        id: '1981',
        ort: 'Bütthard',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97244',
    },
    {
        id: '1982',
        ort: 'Buttlar',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '36419',
    },
    {
        id: '1983',
        ort: 'Buttstädt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99628',
    },
    {
        id: '1984',
        ort: 'Büttstedt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37359',
    },
    {
        id: '1985',
        ort: 'Butzbach',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '35510',
    },
    {
        id: '1986',
        ort: 'Butzow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17392',
    },
    {
        id: '1987',
        ort: 'Bützow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18246',
    },
    {
        id: '1988',
        ort: 'Buxheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87740',
    },
    {
        id: '1989',
        ort: 'Buxheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85114',
    },
    {
        id: '1990',
        ort: 'Buxtehude',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21614',
    },
    {
        id: '1991',
        ort: 'Byhleguhre-Byhlen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15913',
    },
    {
        id: '1992',
        ort: 'Caan',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56237',
    },
    {
        id: '1993',
        ort: 'Caaschwitz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07586',
    },
    {
        id: '1994',
        ort: 'Cadenberge',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21781',
    },
    {
        id: '1995',
        ort: 'Cadolzburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürth',
        plz: '90556',
    },
    {
        id: '1996',
        ort: 'Calau',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '03205',
    },
    {
        id: '1997',
        ort: 'Calberlah',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38547',
    },
    {
        id: '1998',
        ort: 'Calbe (Saale)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '39240',
    },
    {
        id: '1999',
        ort: 'Calden',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34379',
    },
    {
        id: '2000',
        ort: 'Callbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '67829',
    },
    {
        id: '2001',
        ort: 'Callenberg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '09337',
    },
    {
        id: '2002',
        ort: 'Calvörde',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39359',
    },
    {
        id: '2003',
        ort: 'Calvörde',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39638',
    },
    {
        id: '2004',
        ort: 'Calw',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '75365',
    },
    {
        id: '2005',
        ort: 'Cambs',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19067',
    },
    {
        id: '2006',
        ort: 'Cammin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18195',
    },
    {
        id: '2007',
        ort: 'Cappeln (Oldenburg)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cloppenburg',
        plz: '49692',
    },
    {
        id: '2008',
        ort: 'Carinerland',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18233',
    },
    {
        id: '2009',
        ort: 'Carlow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19217',
    },
    {
        id: '2010',
        ort: 'Carlsberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67316',
    },
    {
        id: '2011',
        ort: 'Carmzow-Wallmow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17291',
    },
    {
        id: '2012',
        ort: 'Carpin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17237',
    },
    {
        id: '2013',
        ort: 'Casekow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '16306',
    },
    {
        id: '2014',
        ort: 'Castell',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97355',
    },
    {
        id: '2015',
        ort: 'Castrop-Rauxel',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '44575',
    },
    {
        id: '2016',
        ort: 'Castrop-Rauxel',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '44577',
    },
    {
        id: '2017',
        ort: 'Castrop-Rauxel',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '44579',
    },
    {
        id: '2018',
        ort: 'Castrop-Rauxel',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '44581',
    },
    {
        id: '2019',
        ort: 'Cavertitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04758',
    },
    {
        id: '2020',
        ort: 'Celle',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29221',
    },
    {
        id: '2021',
        ort: 'Celle',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29223',
    },
    {
        id: '2022',
        ort: 'Celle',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29225',
    },
    {
        id: '2023',
        ort: 'Celle',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29227',
    },
    {
        id: '2024',
        ort: 'Celle',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29229',
    },
    {
        id: '2025',
        ort: 'Cham',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93413',
    },
    {
        id: '2026',
        ort: 'Chamerau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93466',
    },
    {
        id: '2027',
        ort: 'Charlottenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56379',
    },
    {
        id: '2028',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09111',
    },
    {
        id: '2029',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09112',
    },
    {
        id: '2030',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09113',
    },
    {
        id: '2031',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09114',
    },
    {
        id: '2032',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09116',
    },
    {
        id: '2033',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09117',
    },
    {
        id: '2034',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09119',
    },
    {
        id: '2035',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09120',
    },
    {
        id: '2036',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09122',
    },
    {
        id: '2037',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09123',
    },
    {
        id: '2038',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09125',
    },
    {
        id: '2039',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09126',
    },
    {
        id: '2040',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09127',
    },
    {
        id: '2041',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09128',
    },
    {
        id: '2042',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09130',
    },
    {
        id: '2043',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09131',
    },
    {
        id: '2044',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09224',
    },
    {
        id: '2045',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09228',
    },
    {
        id: '2046',
        ort: 'Chemnitz',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '09247',
    },
    {
        id: '2047',
        ort: 'Chieming',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83339',
    },
    {
        id: '2048',
        ort: 'Chiemsee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83209',
    },
    {
        id: '2049',
        ort: 'Chiemsee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83256',
    },
    {
        id: '2050',
        ort: 'Chorin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16230',
    },
    {
        id: '2051',
        ort: 'Christes',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98547',
    },
    {
        id: '2052',
        ort: 'Christiansholm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24799',
    },
    {
        id: '2053',
        ort: 'Christinenthal',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25593',
    },
    {
        id: '2054',
        ort: 'Clausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66978',
    },
    {
        id: '2055',
        ort: 'Claußnitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09236',
    },
    {
        id: '2056',
        ort: 'Clausthal-Zellerfeld',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Goslar',
        plz: '38667',
    },
    {
        id: '2057',
        ort: 'Clausthal-Zellerfeld',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Goslar',
        plz: '38678',
    },
    {
        id: '2058',
        ort: 'Clausthal-Zellerfeld',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Goslar',
        plz: '38707',
    },
    {
        id: '2059',
        ort: 'Clausthal-Zellerfeld',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Goslar',
        plz: '38709',
    },
    {
        id: '2060',
        ort: 'Cleebronn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74389',
    },
    {
        id: '2061',
        ort: 'Clenze',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29459',
    },
    {
        id: '2062',
        ort: 'Clingen',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '99718',
    },
    {
        id: '2063',
        ort: 'Cloppenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cloppenburg',
        plz: '49661',
    },
    {
        id: '2064',
        ort: 'Coburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '96450',
    },
    {
        id: '2065',
        ort: 'Cochem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56812',
    },
    {
        id: '2066',
        ort: 'Coesfeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Coesfeld',
        plz: '48653',
    },
    {
        id: '2067',
        ort: 'Cölbe',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35091',
    },
    {
        id: '2068',
        ort: 'Colbitz',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39326',
    },
    {
        id: '2069',
        ort: 'Colditz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04680',
    },
    {
        id: '2070',
        ort: 'Collenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '97896',
    },
    {
        id: '2071',
        ort: 'Collenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '97903',
    },
    {
        id: '2072',
        ort: 'Colmberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91598',
    },
    {
        id: '2073',
        ort: 'Colnrade',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Oldenburg',
        plz: '27243',
    },
    {
        id: '2074',
        ort: 'Cölpin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17094',
    },
    {
        id: '2075',
        ort: 'Contwig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66497',
    },
    {
        id: '2076',
        ort: 'Coppenbrügge',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hameln-Pyrmont',
        plz: '31863',
    },
    {
        id: '2077',
        ort: 'Cornberg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36219',
    },
    {
        id: '2078',
        ort: 'Coswig',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01640',
    },
    {
        id: '2079',
        ort: 'Coswig (Anhalt)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Wittenberg',
        plz: '06868',
    },
    {
        id: '2080',
        ort: 'Coswig (Anhalt)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Wittenberg',
        plz: '06869',
    },
    {
        id: '2081',
        ort: 'Cottbus - Chóśebuz',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '03042',
    },
    {
        id: '2082',
        ort: 'Cottbus - Chóśebuz',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '03044',
    },
    {
        id: '2083',
        ort: 'Cottbus - Chóśebuz',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '03046',
    },
    {
        id: '2084',
        ort: 'Cottbus - Chóśebuz',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '03048',
    },
    {
        id: '2085',
        ort: 'Cottbus - Chóśebuz',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '03050',
    },
    {
        id: '2086',
        ort: 'Cottbus - Chóśebuz',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '03051',
    },
    {
        id: '2087',
        ort: 'Cottbus - Chóśebuz',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '03052',
    },
    {
        id: '2088',
        ort: 'Cottbus - Chóśebuz',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '03053',
    },
    {
        id: '2089',
        ort: 'Cottbus - Chóśebuz',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '03054',
    },
    {
        id: '2090',
        ort: 'Cottbus - Chóśebuz',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '03055',
    },
    {
        id: '2091',
        ort: 'Crailsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74564',
    },
    {
        id: '2092',
        ort: 'Crailsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74597',
    },
    {
        id: '2093',
        ort: 'Cramberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65558',
    },
    {
        id: '2094',
        ort: 'Cramme',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38312',
    },
    {
        id: '2095',
        ort: 'Cramonshagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19071',
    },
    {
        id: '2096',
        ort: 'Creglingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Main-Tauber-Kreis',
        plz: '97993',
    },
    {
        id: '2097',
        ort: 'Cremlingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38162',
    },
    {
        id: '2098',
        ort: 'Creußen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95473',
    },
    {
        id: '2099',
        ort: 'Crimla',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07557',
    },
    {
        id: '2100',
        ort: 'Crimmitschau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08451',
    },
    {
        id: '2101',
        ort: 'Crinitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '03246',
    },
    {
        id: '2102',
        ort: 'Crinitzberg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08147',
    },
    {
        id: '2103',
        ort: 'Crivitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19089',
    },
    {
        id: '2104',
        ort: 'Cronenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67744',
    },
    {
        id: '2105',
        ort: 'Crossen an der Elster',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07613',
    },
    {
        id: '2106',
        ort: 'Crostwitz - Chrósćicy',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01920',
    },
    {
        id: '2107',
        ort: 'Crottendorf',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09474',
    },
    {
        id: '2108',
        ort: 'Cumlosen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '19322',
    },
    {
        id: '2109',
        ort: 'Cunewalde',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02733',
    },
    {
        id: '2110',
        ort: 'Cursdorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '98744',
    },
    {
        id: '2111',
        ort: 'Cuxhaven',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '27472',
    },
    {
        id: '2112',
        ort: 'Cuxhaven',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '27474',
    },
    {
        id: '2113',
        ort: 'Cuxhaven',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '27476',
    },
    {
        id: '2114',
        ort: 'Cuxhaven',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '27478',
    },
    {
        id: '2115',
        ort: 'Daaden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57567',
    },
    {
        id: '2116',
        ort: 'Dabel',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19406',
    },
    {
        id: '2117',
        ort: 'Dabergotz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16818',
    },
    {
        id: '2118',
        ort: 'Daberkow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17129',
    },
    {
        id: '2119',
        ort: 'Dachau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '85221',
    },
    {
        id: '2120',
        ort: 'Dachsbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91462',
    },
    {
        id: '2121',
        ort: 'Dachsberg (Südschwarzwald)',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79837',
    },
    {
        id: '2122',
        ort: 'Dachsberg (Südschwarzwald)',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79875',
    },
    {
        id: '2123',
        ort: 'Dachsenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56340',
    },
    {
        id: '2124',
        ort: 'Dachwig',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99100',
    },
    {
        id: '2125',
        ort: 'Dackenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67273',
    },
    {
        id: '2126',
        ort: 'Dackscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54649',
    },
    {
        id: '2127',
        ort: 'Dagebüll',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25899',
    },
    {
        id: '2128',
        ort: 'Dägeling',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25578',
    },
    {
        id: '2129',
        ort: 'Dahlem',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Euskirchen',
        plz: '53949',
    },
    {
        id: '2130',
        ort: 'Dahlem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '2131',
        ort: 'Dahlem',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21368',
    },
    {
        id: '2132',
        ort: 'Dahlen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04774',
    },
    {
        id: '2133',
        ort: 'Dahlenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21368',
    },
    {
        id: '2134',
        ort: 'Dahlheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56348',
    },
    {
        id: '2135',
        ort: 'Dahlum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38170',
    },
    {
        id: '2136',
        ort: 'Dahme',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23747',
    },
    {
        id: '2137',
        ort: 'Dahme/Mark',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Teltow-Fläming',
        plz: '15936',
    },
    {
        id: '2138',
        ort: 'Dahmen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17166',
    },
    {
        id: '2139',
        ort: 'Dahmetal',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Teltow-Fläming',
        plz: '15936',
    },
    {
        id: '2140',
        ort: 'Dahmker',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '22946',
    },
    {
        id: '2141',
        ort: 'Dahn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66994',
    },
    {
        id: '2142',
        ort: 'Dahnen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54689',
    },
    {
        id: '2143',
        ort: 'Dähre',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Altmarkkreis Salzwedel',
        plz: '29413',
    },
    {
        id: '2144',
        ort: 'Daisendorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88718',
    },
    {
        id: '2145',
        ort: 'Daiting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86653',
    },
    {
        id: '2146',
        ort: 'Dalberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '2147',
        ort: 'Dalberg-Wendelstorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19071',
    },
    {
        id: '2148',
        ort: 'Daldorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24635',
    },
    {
        id: '2149',
        ort: 'Daleiden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54689',
    },
    {
        id: '2150',
        ort: 'Dalheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55278',
    },
    {
        id: '2151',
        ort: 'Dalkendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17166',
    },
    {
        id: '2152',
        ort: 'Dalldorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21483',
    },
    {
        id: '2153',
        ort: 'Dallgow-Döberitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14624',
    },
    {
        id: '2154',
        ort: 'Dambach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55765',
    },
    {
        id: '2155',
        ort: 'Dambeck',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19357',
    },
    {
        id: '2156',
        ort: 'Damendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24361',
    },
    {
        id: '2157',
        ort: 'Damflos',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54413',
    },
    {
        id: '2158',
        ort: 'Damlos',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23738',
    },
    {
        id: '2159',
        ort: 'Dammbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63874',
    },
    {
        id: '2160',
        ort: 'Damme',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Vechta',
        plz: '49401',
    },
    {
        id: '2161',
        ort: 'Dammfleth',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25554',
    },
    {
        id: '2162',
        ort: 'Dammfleth',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25573',
    },
    {
        id: '2163',
        ort: 'Damnatz',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29472',
    },
    {
        id: '2164',
        ort: 'Damp',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24351',
    },
    {
        id: '2165',
        ort: 'Damscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55432',
    },
    {
        id: '2166',
        ort: 'Damsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23824',
    },
    {
        id: '2167',
        ort: 'Damshagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23936',
    },
    {
        id: '2168',
        ort: 'Damshagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23948',
    },
    {
        id: '2169',
        ort: 'Dänischenhagen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24229',
    },
    {
        id: '2170',
        ort: 'Dankerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53520',
    },
    {
        id: '2171',
        ort: 'Dannau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24329',
    },
    {
        id: '2172',
        ort: 'Danndorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38461',
    },
    {
        id: '2173',
        ort: 'Dannenberg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29451',
    },
    {
        id: '2174',
        ort: 'Dannenfels',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67814',
    },
    {
        id: '2175',
        ort: 'Dannewerk',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24867',
    },
    {
        id: '2176',
        ort: 'Dannstadt-Schauernheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67125',
    },
    {
        id: '2177',
        ort: 'Dargelin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17498',
    },
    {
        id: '2178',
        ort: 'Dargen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17419',
    },
    {
        id: '2179',
        ort: 'Dargen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17429',
    },
    {
        id: '2180',
        ort: 'Dargun',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17159',
    },
    {
        id: '2181',
        ort: 'Darmstadt',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '64283',
    },
    {
        id: '2182',
        ort: 'Darmstadt',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '64285',
    },
    {
        id: '2183',
        ort: 'Darmstadt',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '64287',
    },
    {
        id: '2184',
        ort: 'Darmstadt',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '64289',
    },
    {
        id: '2185',
        ort: 'Darmstadt',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '64291',
    },
    {
        id: '2186',
        ort: 'Darmstadt',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '64293',
    },
    {
        id: '2187',
        ort: 'Darmstadt',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '64295',
    },
    {
        id: '2188',
        ort: 'Darmstadt',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '64297',
    },
    {
        id: '2189',
        ort: 'Darscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '2190',
        ort: 'Darstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '76848',
    },
    {
        id: '2191',
        ort: 'Dasburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54689',
    },
    {
        id: '2192',
        ort: 'Dasing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86453',
    },
    {
        id: '2193',
        ort: 'Dassel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Northeim',
        plz: '37586',
    },
    {
        id: '2194',
        ort: 'Dassendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21521',
    },
    {
        id: '2195',
        ort: 'Dassow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23942',
    },
    {
        id: '2196',
        ort: 'Dätgen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24589',
    },
    {
        id: '2197',
        ort: 'Datteln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '45711',
    },
    {
        id: '2198',
        ort: 'Dattenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53547',
    },
    {
        id: '2199',
        ort: 'Datzeroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56589',
    },
    {
        id: '2200',
        ort: 'Datzetal',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17099',
    },
    {
        id: '2201',
        ort: 'Daubach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '2202',
        ort: 'Daubach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55566',
    },
    {
        id: '2203',
        ort: 'Dauchingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78083',
    },
    {
        id: '2204',
        ort: 'Daun',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54550',
    },
    {
        id: '2205',
        ort: 'Dausenau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56132',
    },
    {
        id: '2206',
        ort: 'Dautmergen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72356',
    },
    {
        id: '2207',
        ort: 'Dautphetal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35232',
    },
    {
        id: '2208',
        ort: 'Dauwelshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '2209',
        ort: 'Daxweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55442',
    },
    {
        id: '2210',
        ort: 'Dechow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19217',
    },
    {
        id: '2211',
        ort: 'Deckenpfronn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '75392',
    },
    {
        id: '2212',
        ort: 'Dedelstorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '29386',
    },
    {
        id: '2213',
        ort: 'Dedenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53426',
    },
    {
        id: '2214',
        ort: 'Deensen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37627',
    },
    {
        id: '2215',
        ort: 'Deesbach',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '98744',
    },
    {
        id: '2216',
        ort: 'Deesen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56237',
    },
    {
        id: '2217',
        ort: 'Deggendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94469',
    },
    {
        id: '2218',
        ort: 'Deggenhausertal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88693',
    },
    {
        id: '2219',
        ort: 'Deggingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73312',
    },
    {
        id: '2220',
        ort: 'Deggingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73326',
    },
    {
        id: '2221',
        ort: 'Deidesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67146',
    },
    {
        id: '2222',
        ort: 'Deidesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67157',
    },
    {
        id: '2223',
        ort: 'Deidesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67435',
    },
    {
        id: '2224',
        ort: 'Deidesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67466',
    },
    {
        id: '2225',
        ort: 'Deilingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78586',
    },
    {
        id: '2226',
        ort: 'Deimberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67742',
    },
    {
        id: '2227',
        ort: 'Deining',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92364',
    },
    {
        id: '2228',
        ort: 'Deiningen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86738',
    },
    {
        id: '2229',
        ort: 'Deinste',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21717',
    },
    {
        id: '2230',
        ort: 'Deinstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27446',
    },
    {
        id: '2231',
        ort: 'Deisenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '86489',
    },
    {
        id: '2232',
        ort: 'Deißlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78652',
    },
    {
        id: '2233',
        ort: 'Deizisau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73779',
    },
    {
        id: '2234',
        ort: 'Delbrück',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Paderborn',
        plz: '33129',
    },
    {
        id: '2235',
        ort: 'Delingsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22941',
    },
    {
        id: '2236',
        ort: 'Delitzsch',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04509',
    },
    {
        id: '2237',
        ort: 'Dellfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66503',
    },
    {
        id: '2238',
        ort: 'Delligsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '31073',
    },
    {
        id: '2239',
        ort: 'Dellstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25786',
    },
    {
        id: '2240',
        ort: 'Delmenhorst',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '27749',
    },
    {
        id: '2241',
        ort: 'Delmenhorst',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '27751',
    },
    {
        id: '2242',
        ort: 'Delmenhorst',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '27753',
    },
    {
        id: '2243',
        ort: 'Delmenhorst',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '27755',
    },
    {
        id: '2244',
        ort: 'Delve',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25788',
    },
    {
        id: '2245',
        ort: 'Demen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19089',
    },
    {
        id: '2246',
        ort: 'Demerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '2247',
        ort: 'Demitz-Thumitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01877',
    },
    {
        id: '2248',
        ort: 'Demmin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17109',
    },
    {
        id: '2249',
        ort: 'Denkendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85095',
    },
    {
        id: '2250',
        ort: 'Denkendorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73770',
    },
    {
        id: '2251',
        ort: 'Denkingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78554',
    },
    {
        id: '2252',
        ort: 'Denkingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78588',
    },
    {
        id: '2253',
        ort: 'Denklingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86920',
    },
    {
        id: '2254',
        ort: 'Denkte',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38321',
    },
    {
        id: '2255',
        ort: 'Dennheritz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08393',
    },
    {
        id: '2256',
        ort: 'Dennweiler-Frohnbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66871',
    },
    {
        id: '2257',
        ort: 'Densborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '2258',
        ort: 'Dentlein am Forst',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91599',
    },
    {
        id: '2259',
        ort: 'Denzlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79211',
    },
    {
        id: '2260',
        ort: 'Derental',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37691',
    },
    {
        id: '2261',
        ort: 'Dermbach',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '36466',
    },
    {
        id: '2262',
        ort: 'Dernau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53507',
    },
    {
        id: '2263',
        ort: 'Dernbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56307',
    },
    {
        id: '2264',
        ort: 'Dernbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76857',
    },
    {
        id: '2265',
        ort: 'Dernbach (Westerwald)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56428',
    },
    {
        id: '2266',
        ort: 'Dersau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24326',
    },
    {
        id: '2267',
        ort: 'Derschen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57520',
    },
    {
        id: '2268',
        ort: 'Dersekow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17498',
    },
    {
        id: '2269',
        ort: 'Dersenow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19260',
    },
    {
        id: '2270',
        ort: 'Dersum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26906',
    },
    {
        id: '2271',
        ort: 'Desloch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55592',
    },
    {
        id: '2272',
        ort: 'Dessau-Roßlau',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06842',
    },
    {
        id: '2273',
        ort: 'Dessau-Roßlau',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06844',
    },
    {
        id: '2274',
        ort: 'Dessau-Roßlau',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06846',
    },
    {
        id: '2275',
        ort: 'Dessau-Roßlau',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06847',
    },
    {
        id: '2276',
        ort: 'Dessau-Roßlau',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06849',
    },
    {
        id: '2277',
        ort: 'Dessau-Roßlau',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06861',
    },
    {
        id: '2278',
        ort: 'Dessau-Roßlau',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06862',
    },
    {
        id: '2279',
        ort: 'Dessighofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '2280',
        ort: 'Detern',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26847',
    },
    {
        id: '2281',
        ort: 'Detmold',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '32756',
    },
    {
        id: '2282',
        ort: 'Detmold',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '32758',
    },
    {
        id: '2283',
        ort: 'Detmold',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '32760',
    },
    {
        id: '2284',
        ort: 'Dettelbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97337',
    },
    {
        id: '2285',
        ort: 'Dettenhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72135',
    },
    {
        id: '2286',
        ort: 'Dettenheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76706',
    },
    {
        id: '2287',
        ort: 'Dettighofen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79802',
    },
    {
        id: '2288',
        ort: 'Dettingen an der Erms',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72581',
    },
    {
        id: '2289',
        ort: 'Dettingen an der Iller',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88451',
    },
    {
        id: '2290',
        ort: 'Dettingen unter Teck',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73265',
    },
    {
        id: '2291',
        ort: 'Dettmannsdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18334',
    },
    {
        id: '2292',
        ort: 'Dettum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38173',
    },
    {
        id: '2293',
        ort: 'Detzem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54340',
    },
    {
        id: '2294',
        ort: 'Deudesfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '2295',
        ort: 'Deuerling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93180',
    },
    {
        id: '2296',
        ort: 'Deuselbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54411',
    },
    {
        id: '2297',
        ort: 'Deutsch Evern',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21407',
    },
    {
        id: '2298',
        ort: 'Deutschneudorf',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09548',
    },
    {
        id: '2299',
        ort: 'Dexheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55278',
    },
    {
        id: '2300',
        ort: 'Deyelsdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18513',
    },
    {
        id: '2301',
        ort: 'Dhronecken',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54426',
    },
    {
        id: '2302',
        ort: 'Dichtelbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55494',
    },
    {
        id: '2303',
        ort: 'Dickel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49453',
    },
    {
        id: '2304',
        ort: 'Dickendorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57520',
    },
    {
        id: '2305',
        ort: 'Dickenschied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55483',
    },
    {
        id: '2306',
        ort: 'Dickesbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '2307',
        ort: 'Didderse',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38530',
    },
    {
        id: '2308',
        ort: 'Diebach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91583',
    },
    {
        id: '2309',
        ort: 'Dieblich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56332',
    },
    {
        id: '2310',
        ort: 'Dieburg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64807',
    },
    {
        id: '2311',
        ort: 'Diedorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86420',
    },
    {
        id: '2312',
        ort: 'Diefenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54538',
    },
    {
        id: '2313',
        ort: 'Diekholzen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31199',
    },
    {
        id: '2314',
        ort: 'Diekhusen-Fahrstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25709',
    },
    {
        id: '2315',
        ort: 'Dielheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69234',
    },
    {
        id: '2316',
        ort: 'Dielkirchen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67811',
    },
    {
        id: '2317',
        ort: 'Dielmissen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37633',
    },
    {
        id: '2318',
        ort: 'Diemelsee',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '34519',
    },
    {
        id: '2319',
        ort: 'Diemelstadt',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '34474',
    },
    {
        id: '2320',
        ort: 'Dienethal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56379',
    },
    {
        id: '2321',
        ort: 'Dienheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55276',
    },
    {
        id: '2322',
        ort: 'Diensdorf-Radlow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15864',
    },
    {
        id: '2323',
        ort: 'Dienstweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55765',
    },
    {
        id: '2324',
        ort: 'Diepenau',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31603',
    },
    {
        id: '2325',
        ort: 'Diepholz',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49356',
    },
    {
        id: '2326',
        ort: 'Diera-Zehren',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01665',
    },
    {
        id: '2327',
        ort: 'Dierbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76889',
    },
    {
        id: '2328',
        ort: 'Dierdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56269',
    },
    {
        id: '2329',
        ort: 'Dierfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54533',
    },
    {
        id: '2330',
        ort: 'Dierhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18347',
    },
    {
        id: '2331',
        ort: 'Dierscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54523',
    },
    {
        id: '2332',
        ort: 'Diesdorf',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Altmarkkreis Salzwedel',
        plz: '29413',
    },
    {
        id: '2333',
        ort: 'Diespeck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91456',
    },
    {
        id: '2334',
        ort: 'Dießen am Ammersee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86911',
    },
    {
        id: '2335',
        ort: 'Dietenheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89165',
    },
    {
        id: '2336',
        ort: 'Dietenhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '90599',
    },
    {
        id: '2337',
        ort: 'Dieterode',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '2338',
        ort: 'Dietersburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84378',
    },
    {
        id: '2339',
        ort: 'Dietersheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91463',
    },
    {
        id: '2340',
        ort: 'Dieterskirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92542',
    },
    {
        id: '2341',
        ort: 'Dietfurt an der Altmühl',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92345',
    },
    {
        id: '2342',
        ort: 'Diethardt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56355',
    },
    {
        id: '2343',
        ort: 'Dietingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78661',
    },
    {
        id: '2344',
        ort: 'Dietmannsried',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87463',
    },
    {
        id: '2345',
        ort: 'Dietramszell',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '83623',
    },
    {
        id: '2346',
        ort: 'Dietrichingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66484',
    },
    {
        id: '2347',
        ort: 'Dietzenbach',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Offenbach',
        plz: '63128',
    },
    {
        id: '2348',
        ort: 'Dietzenrode/Vatterode',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '2349',
        ort: 'Dietzhölztal',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35716',
    },
    {
        id: '2350',
        ort: 'Diez',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65582',
    },
    {
        id: '2351',
        ort: 'Dill',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55487',
    },
    {
        id: '2352',
        ort: 'Dillenburg',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35683',
    },
    {
        id: '2353',
        ort: 'Dillenburg',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35684',
    },
    {
        id: '2354',
        ort: 'Dillenburg',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35685',
    },
    {
        id: '2355',
        ort: 'Dillenburg',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35686',
    },
    {
        id: '2356',
        ort: 'Dillenburg',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35687',
    },
    {
        id: '2357',
        ort: 'Dillenburg',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35688',
    },
    {
        id: '2358',
        ort: 'Dillenburg',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35689',
    },
    {
        id: '2359',
        ort: 'Dillenburg',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35690',
    },
    {
        id: '2360',
        ort: 'Dillendorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55481',
    },
    {
        id: '2361',
        ort: 'Dillingen a.d.Donau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89407',
    },
    {
        id: '2362',
        ort: 'Dillingen/Saar',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Saarlouis',
        plz: '66763',
    },
    {
        id: '2363',
        ort: 'Dillstädt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98530',
    },
    {
        id: '2364',
        ort: 'Dimbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '76848',
    },
    {
        id: '2365',
        ort: 'Dingdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54614',
    },
    {
        id: '2366',
        ort: 'Dingelstädt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37351',
    },
    {
        id: '2367',
        ort: 'Dingen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25715',
    },
    {
        id: '2368',
        ort: 'Dingolfing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dingolfing-Landau',
        plz: '84130',
    },
    {
        id: '2369',
        ort: 'Dingolshausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97497',
    },
    {
        id: '2370',
        ort: 'Dingsleben',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98646',
    },
    {
        id: '2371',
        ort: 'Dinkelsbühl',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91550',
    },
    {
        id: '2372',
        ort: 'Dinkelscherben',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86424',
    },
    {
        id: '2373',
        ort: 'Dinklage',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Vechta',
        plz: '49413',
    },
    {
        id: '2374',
        ort: 'Dinslaken',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '46535',
    },
    {
        id: '2375',
        ort: 'Dinslaken',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '46537',
    },
    {
        id: '2376',
        ort: 'Dinslaken',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '46539',
    },
    {
        id: '2377',
        ort: 'Dintesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '2378',
        ort: 'Dipperz',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36160',
    },
    {
        id: '2379',
        ort: 'Dippoldiswalde',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01744',
    },
    {
        id: '2380',
        ort: 'Dirlewang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87742',
    },
    {
        id: '2381',
        ort: 'Dirlewang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87782',
    },
    {
        id: '2382',
        ort: 'Dirmstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67246',
    },
    {
        id: '2383',
        ort: 'Dischingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heidenheim',
        plz: '89561',
    },
    {
        id: '2384',
        ort: 'Dissen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49201',
    },
    {
        id: '2385',
        ort: 'Dissen-Striesow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03096',
    },
    {
        id: '2386',
        ort: 'Ditfurt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '06484',
    },
    {
        id: '2387',
        ort: 'Ditscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '2388',
        ort: 'Dittelbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97456',
    },
    {
        id: '2389',
        ort: 'Dittelsheim-Heßloch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67596',
    },
    {
        id: '2390',
        ort: 'Dittenheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91723',
    },
    {
        id: '2391',
        ort: 'Dittersdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07907',
    },
    {
        id: '2392',
        ort: 'Dittweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66903',
    },
    {
        id: '2393',
        ort: 'Ditzingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71254',
    },
    {
        id: '2394',
        ort: 'Divitz-Spoldershagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18314',
    },
    {
        id: '2395',
        ort: 'Dobbertin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19399',
    },
    {
        id: '2396',
        ort: 'Dobbin-Linstow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18292',
    },
    {
        id: '2397',
        ort: 'Dobel',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '75335',
    },
    {
        id: '2398',
        ort: 'Döbeln',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '04720',
    },
    {
        id: '2399',
        ort: 'Doberlug-Kirchhain',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '03253',
    },
    {
        id: '2400',
        ort: 'Döbern',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03159',
    },
    {
        id: '2401',
        ort: 'Doberschau-Gaußig - Dobruša-Huska',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01877',
    },
    {
        id: '2402',
        ort: 'Doberschau-Gaußig - Dobruša-Huska',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02633',
    },
    {
        id: '2403',
        ort: 'Doberschau-Gaußig - Dobruša-Huska',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02692',
    },
    {
        id: '2404',
        ort: 'Doberschütz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04838',
    },
    {
        id: '2405',
        ort: 'Dobersdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24232',
    },
    {
        id: '2406',
        ort: 'Dobin am See',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19067',
    },
    {
        id: '2407',
        ort: 'Dobitschen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04626',
    },
    {
        id: '2408',
        ort: 'Döbritschen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99441',
    },
    {
        id: '2409',
        ort: 'Döbritz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07381',
    },
    {
        id: '2410',
        ort: 'Dockendorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '2411',
        ort: 'Dockweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '2412',
        ort: 'Dodenburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '2413',
        ort: 'Dogern',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79804',
    },
    {
        id: '2414',
        ort: 'Döhlau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95182',
    },
    {
        id: '2415',
        ort: 'Dohma',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01796',
    },
    {
        id: '2416',
        ort: 'Dohm-Lammersdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54576',
    },
    {
        id: '2417',
        ort: 'Dohna',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01809',
    },
    {
        id: '2418',
        ort: 'Dohr',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56812',
    },
    {
        id: '2419',
        ort: 'Dohren',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21255',
    },
    {
        id: '2420',
        ort: 'Dohren',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49770',
    },
    {
        id: '2421',
        ort: 'Dolgen am See',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18299',
    },
    {
        id: '2422',
        ort: 'Dolgesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55278',
    },
    {
        id: '2423',
        ort: 'Dollern',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21739',
    },
    {
        id: '2424',
        ort: 'Dollerup',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24989',
    },
    {
        id: '2425',
        ort: 'Dollnstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '91795',
    },
    {
        id: '2426',
        ort: 'Döllstädt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99100',
    },
    {
        id: '2427',
        ort: 'Dombühl',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91601',
    },
    {
        id: '2428',
        ort: 'Dömitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19303',
    },
    {
        id: '2429',
        ort: 'Dommershausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56290',
    },
    {
        id: '2430',
        ort: 'Dommitzsch',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04880',
    },
    {
        id: '2431',
        ort: 'Domsühl',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19374',
    },
    {
        id: '2432',
        ort: 'Donaueschingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78166',
    },
    {
        id: '2433',
        ort: 'Donaustauf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93093',
    },
    {
        id: '2434',
        ort: 'Donauwörth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86609',
    },
    {
        id: '2435',
        ort: 'Donnersdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97499',
    },
    {
        id: '2436',
        ort: 'Donsieders',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66978',
    },
    {
        id: '2437',
        ort: 'Donzdorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73072',
    },
    {
        id: '2438',
        ort: 'Dörentrup',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '32694',
    },
    {
        id: '2439',
        ort: 'Dorfchemnitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09619',
    },
    {
        id: '2440',
        ort: 'Dorfen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '84405',
    },
    {
        id: '2441',
        ort: 'Dorfhain',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01738',
    },
    {
        id: '2442',
        ort: 'Dörfles-Esbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Coburg',
        plz: '96487',
    },
    {
        id: '2443',
        ort: 'Dorf Mecklenburg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23972',
    },
    {
        id: '2444',
        ort: 'Dorfprozelten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '97904',
    },
    {
        id: '2445',
        ort: 'Dormagen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41539',
    },
    {
        id: '2446',
        ort: 'Dormagen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41540',
    },
    {
        id: '2447',
        ort: 'Dormagen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41541',
    },
    {
        id: '2448',
        ort: 'Dormagen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41542',
    },
    {
        id: '2449',
        ort: 'Dormettingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72358',
    },
    {
        id: '2450',
        ort: 'Dormitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91077',
    },
    {
        id: '2451',
        ort: 'Dörnberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56377',
    },
    {
        id: '2452',
        ort: 'Dörnberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56379',
    },
    {
        id: '2453',
        ort: 'Dornburg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65599',
    },
    {
        id: '2454',
        ort: 'Dornburg-Camburg',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07774',
    },
    {
        id: '2455',
        ort: 'Dorn-Dürkheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '67585',
    },
    {
        id: '2456',
        ort: 'Dornhan',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '72175',
    },
    {
        id: '2457',
        ort: 'Dornheim',
        bundesland: 'Thüringen',
        landkreis: 'Ilm-Kreis',
        plz: '99310',
    },
    {
        id: '2458',
        ort: 'Dornholzhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '2459',
        ort: 'Dörnick',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24326',
    },
    {
        id: '2460',
        ort: 'Dornstadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89160',
    },
    {
        id: '2461',
        ort: 'Dornstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Freudenstadt',
        plz: '72280',
    },
    {
        id: '2462',
        ort: 'Dornum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26553',
    },
    {
        id: '2463',
        ort: 'Dörpen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26892',
    },
    {
        id: '2464',
        ort: 'Dörphof',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24398',
    },
    {
        id: '2465',
        ort: 'Dörpling',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25794',
    },
    {
        id: '2466',
        ort: 'Dörpstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24869',
    },
    {
        id: '2467',
        ort: 'Dörrebach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55444',
    },
    {
        id: '2468',
        ort: 'Dörrenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76889',
    },
    {
        id: '2469',
        ort: 'Dörrmoschel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67806',
    },
    {
        id: '2470',
        ort: 'Dörscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56348',
    },
    {
        id: '2471',
        ort: 'Dörsdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56370',
    },
    {
        id: '2472',
        ort: 'Dorsel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53533',
    },
    {
        id: '2473',
        ort: 'Dorsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55452',
    },
    {
        id: '2474',
        ort: 'Dorstadt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38312',
    },
    {
        id: '2475',
        ort: 'Dorsten',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '46282',
    },
    {
        id: '2476',
        ort: 'Dorsten',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '46284',
    },
    {
        id: '2477',
        ort: 'Dorsten',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '46286',
    },
    {
        id: '2478',
        ort: 'Dörth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56281',
    },
    {
        id: '2479',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44135',
    },
    {
        id: '2480',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44137',
    },
    {
        id: '2481',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44139',
    },
    {
        id: '2482',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44141',
    },
    {
        id: '2483',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44143',
    },
    {
        id: '2484',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44145',
    },
    {
        id: '2485',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44147',
    },
    {
        id: '2486',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44149',
    },
    {
        id: '2487',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44225',
    },
    {
        id: '2488',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44227',
    },
    {
        id: '2489',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44229',
    },
    {
        id: '2490',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44263',
    },
    {
        id: '2491',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44265',
    },
    {
        id: '2492',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44267',
    },
    {
        id: '2493',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44269',
    },
    {
        id: '2494',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44287',
    },
    {
        id: '2495',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44289',
    },
    {
        id: '2496',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44309',
    },
    {
        id: '2497',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44319',
    },
    {
        id: '2498',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44328',
    },
    {
        id: '2499',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44329',
    },
    {
        id: '2500',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44339',
    },
    {
        id: '2501',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44357',
    },
    {
        id: '2502',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44359',
    },
    {
        id: '2503',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44369',
    },
    {
        id: '2504',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44379',
    },
    {
        id: '2505',
        ort: 'Dortmund',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44388',
    },
    {
        id: '2506',
        ort: 'Dörverden',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Verden',
        plz: '27313',
    },
    {
        id: '2507',
        ort: 'Dörzbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Hohenlohekreis',
        plz: '74677',
    },
    {
        id: '2508',
        ort: 'Döschnitz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07429',
    },
    {
        id: '2509',
        ort: 'Dossenheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69221',
    },
    {
        id: '2510',
        ort: 'Dötlingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Oldenburg',
        plz: '27801',
    },
    {
        id: '2511',
        ort: 'Dotternhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72359',
    },
    {
        id: '2512',
        ort: 'Döttesfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56305',
    },
    {
        id: '2513',
        ort: 'Drachhausen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03185',
    },
    {
        id: '2514',
        ort: 'Drachselsried',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94256',
    },
    {
        id: '2515',
        ort: 'Drackenstein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73345',
    },
    {
        id: '2516',
        ort: 'Drage',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21423',
    },
    {
        id: '2517',
        ort: 'Drage',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25582',
    },
    {
        id: '2518',
        ort: 'Drage',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25840',
    },
    {
        id: '2519',
        ort: 'Drage',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25878',
    },
    {
        id: '2520',
        ort: 'Dragun',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19205',
    },
    {
        id: '2521',
        ort: 'Drahnsdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15938',
    },
    {
        id: '2522',
        ort: 'Drakenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31623',
    },
    {
        id: '2523',
        ort: 'Dransfeld',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37127',
    },
    {
        id: '2524',
        ort: 'Dranske',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18556',
    },
    {
        id: '2525',
        ort: 'Drebach',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09430',
    },
    {
        id: '2526',
        ort: 'Drebber',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49457',
    },
    {
        id: '2527',
        ort: 'Drebkau',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03116',
    },
    {
        id: '2528',
        ort: 'Drechow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18465',
    },
    {
        id: '2529',
        ort: 'Drees',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '53520',
    },
    {
        id: '2530',
        ort: 'Dreetz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16845',
    },
    {
        id: '2531',
        ort: 'Dreetz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18249',
    },
    {
        id: '2532',
        ort: 'Dreggers',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23845',
    },
    {
        id: '2533',
        ort: 'Drehnow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03185',
    },
    {
        id: '2534',
        ort: 'Dreieich',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Offenbach',
        plz: '63303',
    },
    {
        id: '2535',
        ort: 'Dreifelden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57629',
    },
    {
        id: '2536',
        ort: 'Drei Gleichen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99869',
    },
    {
        id: '2537',
        ort: 'Dreiheide',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04860',
    },
    {
        id: '2538',
        ort: 'Dreikirchen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56414',
    },
    {
        id: '2539',
        ort: 'Dreis',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '2540',
        ort: 'Dreisbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56472',
    },
    {
        id: '2541',
        ort: 'Dreis-Brück',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '2542',
        ort: 'Dreisen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67816',
    },
    {
        id: '2543',
        ort: 'Dreitzsch',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07819',
    },
    {
        id: '2544',
        ort: 'Drelsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25853',
    },
    {
        id: '2545',
        ort: 'Drensteinfurt',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Warendorf',
        plz: '48317',
    },
    {
        id: '2546',
        ort: 'Drentwede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49406',
    },
    {
        id: '2547',
        ort: 'Dreschvitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18573',
    },
    {
        id: '2548',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01067',
    },
    {
        id: '2549',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01069',
    },
    {
        id: '2550',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01097',
    },
    {
        id: '2551',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01099',
    },
    {
        id: '2552',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01108',
    },
    {
        id: '2553',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01109',
    },
    {
        id: '2554',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01127',
    },
    {
        id: '2555',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01129',
    },
    {
        id: '2556',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01139',
    },
    {
        id: '2557',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01156',
    },
    {
        id: '2558',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01157',
    },
    {
        id: '2559',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01159',
    },
    {
        id: '2560',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01169',
    },
    {
        id: '2561',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01187',
    },
    {
        id: '2562',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01189',
    },
    {
        id: '2563',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01217',
    },
    {
        id: '2564',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01219',
    },
    {
        id: '2565',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01237',
    },
    {
        id: '2566',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01239',
    },
    {
        id: '2567',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01257',
    },
    {
        id: '2568',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01259',
    },
    {
        id: '2569',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01277',
    },
    {
        id: '2570',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01279',
    },
    {
        id: '2571',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01307',
    },
    {
        id: '2572',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01309',
    },
    {
        id: '2573',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01324',
    },
    {
        id: '2574',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01326',
    },
    {
        id: '2575',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01328',
    },
    {
        id: '2576',
        ort: 'Dresden',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '01465',
    },
    {
        id: '2577',
        ort: 'Drestedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21255',
    },
    {
        id: '2578',
        ort: 'Drestedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21279',
    },
    {
        id: '2579',
        ort: 'Driedorf',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35759',
    },
    {
        id: '2580',
        ort: 'Drochtersen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21706',
    },
    {
        id: '2581',
        ort: 'Drognitz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07338',
    },
    {
        id: '2582',
        ort: 'Drolshagen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Olpe',
        plz: '57489',
    },
    {
        id: '2583',
        ort: 'Droyßig',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06722',
    },
    {
        id: '2584',
        ort: 'Düchelsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23847',
    },
    {
        id: '2585',
        ort: 'Ducherow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17398',
    },
    {
        id: '2586',
        ort: 'Duchroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55585',
    },
    {
        id: '2587',
        ort: 'Duchroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '67826',
    },
    {
        id: '2588',
        ort: 'Dudeldorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54647',
    },
    {
        id: '2589',
        ort: 'Düdenbüttel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21709',
    },
    {
        id: '2590',
        ort: 'Dudenhofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67373',
    },
    {
        id: '2591',
        ort: 'Duderstadt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37115',
    },
    {
        id: '2592',
        ort: 'Duggendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93182',
    },
    {
        id: '2593',
        ort: 'Duggendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93195',
    },
    {
        id: '2594',
        ort: 'Duingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31089',
    },
    {
        id: '2595',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47051',
    },
    {
        id: '2596',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47053',
    },
    {
        id: '2597',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47055',
    },
    {
        id: '2598',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47057',
    },
    {
        id: '2599',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47058',
    },
    {
        id: '2600',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47059',
    },
    {
        id: '2601',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47119',
    },
    {
        id: '2602',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47137',
    },
    {
        id: '2603',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47138',
    },
    {
        id: '2604',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47139',
    },
    {
        id: '2605',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47166',
    },
    {
        id: '2606',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47167',
    },
    {
        id: '2607',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47169',
    },
    {
        id: '2608',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47178',
    },
    {
        id: '2609',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47179',
    },
    {
        id: '2610',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47198',
    },
    {
        id: '2611',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47199',
    },
    {
        id: '2612',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47226',
    },
    {
        id: '2613',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47228',
    },
    {
        id: '2614',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47229',
    },
    {
        id: '2615',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47239',
    },
    {
        id: '2616',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47249',
    },
    {
        id: '2617',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47259',
    },
    {
        id: '2618',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47269',
    },
    {
        id: '2619',
        ort: 'Duisburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47279',
    },
    {
        id: '2620',
        ort: 'Dülmen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Coesfeld',
        plz: '48249',
    },
    {
        id: '2621',
        ort: 'Dümmer',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19073',
    },
    {
        id: '2622',
        ort: 'Dummerstorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18196',
    },
    {
        id: '2623',
        ort: 'Dümpelfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53520',
    },
    {
        id: '2624',
        ort: 'Dünfus',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56754',
    },
    {
        id: '2625',
        ort: 'Düngenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56761',
    },
    {
        id: '2626',
        ort: 'Dunningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78655',
    },
    {
        id: '2627',
        ort: 'Dunningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78713',
    },
    {
        id: '2628',
        ort: 'Dünsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Oldenburg',
        plz: '27243',
    },
    {
        id: '2629',
        ort: 'Dunsum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25938',
    },
    {
        id: '2630',
        ort: 'Dunum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26427',
    },
    {
        id: '2631',
        ort: 'Dunzweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66916',
    },
    {
        id: '2632',
        ort: 'Duppach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54597',
    },
    {
        id: '2633',
        ort: 'Durach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87471',
    },
    {
        id: '2634',
        ort: 'Durbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77770',
    },
    {
        id: '2635',
        ort: 'Dürbheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78589',
    },
    {
        id: '2636',
        ort: 'Durchhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78591',
    },
    {
        id: '2637',
        ort: 'Düren',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Düren',
        plz: '52349',
    },
    {
        id: '2638',
        ort: 'Düren',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Düren',
        plz: '52351',
    },
    {
        id: '2639',
        ort: 'Düren',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Düren',
        plz: '52353',
    },
    {
        id: '2640',
        ort: 'Düren',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Düren',
        plz: '52355',
    },
    {
        id: '2641',
        ort: 'Durlangen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73568',
    },
    {
        id: '2642',
        ort: 'Dürmentingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88525',
    },
    {
        id: '2643',
        ort: 'Durmersheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '76448',
    },
    {
        id: '2644',
        ort: 'Dürnau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73105',
    },
    {
        id: '2645',
        ort: 'Dürnau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88422',
    },
    {
        id: '2646',
        ort: 'Dürrhennersdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02708',
    },
    {
        id: '2647',
        ort: 'Dürrholz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56307',
    },
    {
        id: '2648',
        ort: 'Dürrlauingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89350',
    },
    {
        id: '2649',
        ort: 'Dürrröhrsdorf-Dittersbach',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01833',
    },
    {
        id: '2650',
        ort: 'Dürrwangen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91602',
    },
    {
        id: '2651',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40210',
    },
    {
        id: '2652',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40211',
    },
    {
        id: '2653',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40212',
    },
    {
        id: '2654',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40213',
    },
    {
        id: '2655',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40215',
    },
    {
        id: '2656',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40217',
    },
    {
        id: '2657',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40219',
    },
    {
        id: '2658',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40221',
    },
    {
        id: '2659',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40223',
    },
    {
        id: '2660',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40225',
    },
    {
        id: '2661',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40227',
    },
    {
        id: '2662',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40229',
    },
    {
        id: '2663',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40231',
    },
    {
        id: '2664',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40233',
    },
    {
        id: '2665',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40235',
    },
    {
        id: '2666',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40237',
    },
    {
        id: '2667',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40239',
    },
    {
        id: '2668',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40468',
    },
    {
        id: '2669',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40470',
    },
    {
        id: '2670',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40472',
    },
    {
        id: '2671',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40474',
    },
    {
        id: '2672',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40476',
    },
    {
        id: '2673',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40477',
    },
    {
        id: '2674',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40479',
    },
    {
        id: '2675',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40489',
    },
    {
        id: '2676',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40545',
    },
    {
        id: '2677',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40547',
    },
    {
        id: '2678',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40549',
    },
    {
        id: '2679',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40589',
    },
    {
        id: '2680',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40591',
    },
    {
        id: '2681',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40593',
    },
    {
        id: '2682',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40595',
    },
    {
        id: '2683',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40597',
    },
    {
        id: '2684',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40599',
    },
    {
        id: '2685',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40625',
    },
    {
        id: '2686',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40627',
    },
    {
        id: '2687',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40629',
    },
    {
        id: '2688',
        ort: 'Düsseldorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '40721',
    },
    {
        id: '2689',
        ort: 'Dußlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72144',
    },
    {
        id: '2690',
        ort: 'Duvensee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23898',
    },
    {
        id: '2691',
        ort: 'Ebeleben',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '99713',
    },
    {
        id: '2692',
        ort: 'Ebelsbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '97500',
    },
    {
        id: '2693',
        ort: 'Ebensfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lichtenfels',
        plz: '96250',
    },
    {
        id: '2694',
        ort: 'Ebenweiler',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88370',
    },
    {
        id: '2695',
        ort: 'Eberbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '64754',
    },
    {
        id: '2696',
        ort: 'Eberbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69412',
    },
    {
        id: '2697',
        ort: 'Eberbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69429',
    },
    {
        id: '2698',
        ort: 'Eberbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69434',
    },
    {
        id: '2699',
        ort: 'Eberdingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71735',
    },
    {
        id: '2700',
        ort: 'Eberfing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82390',
    },
    {
        id: '2701',
        ort: 'Ebergötzen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37136',
    },
    {
        id: '2702',
        ort: 'Eberhardzell',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88436',
    },
    {
        id: '2703',
        ort: 'Ebermannsdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92263',
    },
    {
        id: '2704',
        ort: 'Ebermannstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91320',
    },
    {
        id: '2705',
        ort: 'Ebern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '96106',
    },
    {
        id: '2706',
        ort: 'Ebernhahn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56424',
    },
    {
        id: '2707',
        ort: 'Ebersbach',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01561',
    },
    {
        id: '2708',
        ort: 'Ebersbach an der Fils',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73061',
    },
    {
        id: '2709',
        ort: 'Ebersbach-Musbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88371',
    },
    {
        id: '2710',
        ort: 'Ebersbach-Neugersdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02727',
    },
    {
        id: '2711',
        ort: 'Ebersbach-Neugersdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02730',
    },
    {
        id: '2712',
        ort: 'Ebersberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85560',
    },
    {
        id: '2713',
        ort: 'Ebersburg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36157',
    },
    {
        id: '2714',
        ort: 'Ebersdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27432',
    },
    {
        id: '2715',
        ort: 'Ebersdorf b.Coburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Coburg',
        plz: '96237',
    },
    {
        id: '2716',
        ort: 'Ebershausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '86491',
    },
    {
        id: '2717',
        ort: 'Eberstadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74246',
    },
    {
        id: '2718',
        ort: 'Eberstedt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99518',
    },
    {
        id: '2719',
        ort: 'Eberswalde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16225',
    },
    {
        id: '2720',
        ort: 'Eberswalde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16227',
    },
    {
        id: '2721',
        ort: 'Ebertshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56370',
    },
    {
        id: '2722',
        ort: 'Ebertsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67280',
    },
    {
        id: '2723',
        ort: 'Ebhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '72224',
    },
    {
        id: '2724',
        ort: 'Ebnath',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95683',
    },
    {
        id: '2725',
        ort: 'Ebrach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96157',
    },
    {
        id: '2726',
        ort: 'Ebringen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79285',
    },
    {
        id: '2727',
        ort: 'Ebsdorfergrund',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35085',
    },
    {
        id: '2728',
        ort: 'Ebstorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29574',
    },
    {
        id: '2729',
        ort: 'Echem',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21379',
    },
    {
        id: '2730',
        ort: 'Eching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85386',
    },
    {
        id: '2731',
        ort: 'Eching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84174',
    },
    {
        id: '2732',
        ort: 'Eching am Ammersee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '82279',
    },
    {
        id: '2733',
        ort: 'Echternacherbrück',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54668',
    },
    {
        id: '2734',
        ort: 'Echtershausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '2735',
        ort: 'Echzell',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '61200',
    },
    {
        id: '2736',
        ort: 'Echzell',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '61209',
    },
    {
        id: '2737',
        ort: 'Eckartsberga',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06648',
    },
    {
        id: '2738',
        ort: 'Eckelsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55599',
    },
    {
        id: '2739',
        ort: 'Eckenroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55444',
    },
    {
        id: '2740',
        ort: 'Eckental',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '90542',
    },
    {
        id: '2741',
        ort: 'Eckernförde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24340',
    },
    {
        id: '2742',
        ort: 'Eckersdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95488',
    },
    {
        id: '2743',
        ort: 'Eckersweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55777',
    },
    {
        id: '2744',
        ort: 'Eckfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54531',
    },
    {
        id: '2745',
        ort: 'Ecklak',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25572',
    },
    {
        id: '2746',
        ort: 'Ecklingerode',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37339',
    },
    {
        id: '2747',
        ort: 'Eckstedt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99195',
    },
    {
        id: '2748',
        ort: 'Eddelak',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25715',
    },
    {
        id: '2749',
        ort: 'Edelsfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92265',
    },
    {
        id: '2750',
        ort: 'Edemissen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Peine',
        plz: '31234',
    },
    {
        id: '2751',
        ort: 'Edenkoben',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '67480',
    },
    {
        id: '2752',
        ort: 'Ederheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86739',
    },
    {
        id: '2753',
        ort: 'Edermünde',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34295',
    },
    {
        id: '2754',
        ort: 'Edersleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06528',
    },
    {
        id: '2755',
        ort: 'Edertal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '34549',
    },
    {
        id: '2756',
        ort: 'Edesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '67483',
    },
    {
        id: '2757',
        ort: 'Edewecht',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Ammerland',
        plz: '26188',
    },
    {
        id: '2758',
        ort: 'Ediger-Eller',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56814',
    },
    {
        id: '2759',
        ort: 'Edingen-Neckarhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '68535',
    },
    {
        id: '2760',
        ort: 'Edling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83533',
    },
    {
        id: '2761',
        ort: 'Effelder',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37359',
    },
    {
        id: '2762',
        ort: 'Effeltrich',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91090',
    },
    {
        id: '2763',
        ort: 'Efringen-Kirchen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79588',
    },
    {
        id: '2764',
        ort: 'Egeln',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '39435',
    },
    {
        id: '2765',
        ort: 'Egelsbach',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Offenbach',
        plz: '63329',
    },
    {
        id: '2766',
        ort: 'Egenhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '72227',
    },
    {
        id: '2767',
        ort: 'Egenhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82281',
    },
    {
        id: '2768',
        ort: 'Egesheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78592',
    },
    {
        id: '2769',
        ort: 'Egestorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21272',
    },
    {
        id: '2770',
        ort: 'Egg an der Günz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87743',
    },
    {
        id: '2771',
        ort: 'Eggebek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24852',
    },
    {
        id: '2772',
        ort: 'Eggenfelden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84307',
    },
    {
        id: '2773',
        ort: 'Eggenstein-Leopoldshafen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76344',
    },
    {
        id: '2774',
        ort: 'Eggenthal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87653',
    },
    {
        id: '2775',
        ort: 'Eggermühlen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49577',
    },
    {
        id: '2776',
        ort: 'Eggesin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17367',
    },
    {
        id: '2777',
        ort: 'Eggesin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17375',
    },
    {
        id: '2778',
        ort: 'Eggingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79805',
    },
    {
        id: '2779',
        ort: 'Egglham',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84385',
    },
    {
        id: '2780',
        ort: 'Egglkofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84546',
    },
    {
        id: '2781',
        ort: 'Eggolsheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91330',
    },
    {
        id: '2782',
        ort: 'Eggstätt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83125',
    },
    {
        id: '2783',
        ort: 'Eggstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25721',
    },
    {
        id: '2784',
        ort: 'Eging a.See',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94535',
    },
    {
        id: '2785',
        ort: 'Eglfing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82436',
    },
    {
        id: '2786',
        ort: 'Egling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '82544',
    },
    {
        id: '2787',
        ort: 'Egling an der Paar',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86492',
    },
    {
        id: '2788',
        ort: 'Egloffstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91349',
    },
    {
        id: '2789',
        ort: 'Egmating',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85658',
    },
    {
        id: '2790',
        ort: 'Egweil',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85116',
    },
    {
        id: '2791',
        ort: 'Ehekirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neuburg-Schrobenhausen',
        plz: '86676',
    },
    {
        id: '2792',
        ort: 'Ehingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86678',
    },
    {
        id: '2793',
        ort: 'Ehingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91725',
    },
    {
        id: '2794',
        ort: 'Ehingen a.Ries',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86741',
    },
    {
        id: '2795',
        ort: 'Ehingen (Donau)',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89584',
    },
    {
        id: '2796',
        ort: 'Ehlenz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '2797',
        ort: 'Ehlscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56581',
    },
    {
        id: '2798',
        ort: 'Ehndorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24647',
    },
    {
        id: '2799',
        ort: 'Ehningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71139',
    },
    {
        id: '2800',
        ort: 'Ehr',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '2801',
        ort: 'Ehra-Lessien',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38468',
    },
    {
        id: '2802',
        ort: 'Ehrenberg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98660',
    },
    {
        id: '2803',
        ort: 'Ehrenberg (Rhön)',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36115',
    },
    {
        id: '2804',
        ort: 'Ehrenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27248',
    },
    {
        id: '2805',
        ort: 'Ehrenfriedersdorf',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09427',
    },
    {
        id: '2806',
        ort: 'Ehrenkirchen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79238',
    },
    {
        id: '2807',
        ort: 'Ehringshausen',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35630',
    },
    {
        id: '2808',
        ort: 'Ehweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66871',
    },
    {
        id: '2809',
        ort: 'Eibelstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97246',
    },
    {
        id: '2810',
        ort: 'Eibenstock',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '08309',
    },
    {
        id: '2811',
        ort: 'Eich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67575',
    },
    {
        id: '2812',
        ort: 'Eichelhardt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57612',
    },
    {
        id: '2813',
        ort: 'Eichen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57632',
    },
    {
        id: '2814',
        ort: 'Eichenau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82223',
    },
    {
        id: '2815',
        ort: 'Eichenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53533',
    },
    {
        id: '2816',
        ort: 'Eichenberg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98553',
    },
    {
        id: '2817',
        ort: 'Eichenberg',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07768',
    },
    {
        id: '2818',
        ort: 'Eichenbühl',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63928',
    },
    {
        id: '2819',
        ort: 'Eichenbühl',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '74731',
    },
    {
        id: '2820',
        ort: 'Eichenbühl',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '97896',
    },
    {
        id: '2821',
        ort: 'Eichendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dingolfing-Landau',
        plz: '94428',
    },
    {
        id: '2822',
        ort: 'Eichenzell',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36124',
    },
    {
        id: '2823',
        ort: 'Eichigt',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08626',
    },
    {
        id: '2824',
        ort: 'Eichstätt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85072',
    },
    {
        id: '2825',
        ort: 'Eichstedt (Altmark)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39596',
    },
    {
        id: '2826',
        ort: 'Eichstegen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88361',
    },
    {
        id: '2827',
        ort: 'Eichstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79356',
    },
    {
        id: '2828',
        ort: 'Eichstruth',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '2829',
        ort: 'Eichwalde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15732',
    },
    {
        id: '2830',
        ort: 'Eickeloh',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29693',
    },
    {
        id: '2831',
        ort: 'Eicklingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29358',
    },
    {
        id: '2832',
        ort: 'Eigeltingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78253',
    },
    {
        id: '2833',
        ort: 'Eilenburg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04838',
    },
    {
        id: '2834',
        ort: 'Eilscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54649',
    },
    {
        id: '2835',
        ort: 'Eilsleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39365',
    },
    {
        id: '2836',
        ort: 'Eime',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31036',
    },
    {
        id: '2837',
        ort: 'Eimeldingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79591',
    },
    {
        id: '2838',
        ort: 'Eimen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37632',
    },
    {
        id: '2839',
        ort: 'Eimke',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29578',
    },
    {
        id: '2840',
        ort: 'Eimsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55278',
    },
    {
        id: '2841',
        ort: 'Einbeck',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Northeim',
        plz: '37574',
    },
    {
        id: '2842',
        ort: 'Eineborn',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '2843',
        ort: 'Einhaus',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23911',
    },
    {
        id: '2844',
        ort: 'Einhausen',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '64683',
    },
    {
        id: '2845',
        ort: 'Einhausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98617',
    },
    {
        id: '2846',
        ort: 'Einig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56751',
    },
    {
        id: '2847',
        ort: 'Einöllen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67753',
    },
    {
        id: '2848',
        ort: 'Einselthum',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67308',
    },
    {
        id: '2849',
        ort: 'Eiselfing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83549',
    },
    {
        id: '2850',
        ort: 'Eisenach',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99817',
    },
    {
        id: '2851',
        ort: 'Eisenach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54298',
    },
    {
        id: '2852',
        ort: 'Eisenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79871',
    },
    {
        id: '2853',
        ort: 'Eisenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87637',
    },
    {
        id: '2854',
        ort: 'Eisenberg',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07607',
    },
    {
        id: '2855',
        ort: 'Eisenberg (Pfalz)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67304',
    },
    {
        id: '2856',
        ort: 'Eisendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24589',
    },
    {
        id: '2857',
        ort: 'Eisenheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97247',
    },
    {
        id: '2858',
        ort: 'Eisenhüttenstadt',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15890',
    },
    {
        id: '2859',
        ort: 'Eisenschmitt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54533',
    },
    {
        id: '2860',
        ort: 'Eisfeld',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98673',
    },
    {
        id: '2861',
        ort: 'Eisighofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56370',
    },
    {
        id: '2862',
        ort: 'Eisingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75239',
    },
    {
        id: '2863',
        ort: 'Eisingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97249',
    },
    {
        id: '2864',
        ort: 'Eislingen/Fils',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73054',
    },
    {
        id: '2865',
        ort: 'Eitelborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56337',
    },
    {
        id: '2866',
        ort: 'Eitensheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85117',
    },
    {
        id: '2867',
        ort: 'Eiterfeld',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36132',
    },
    {
        id: '2868',
        ort: 'Eitorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53783',
    },
    {
        id: '2869',
        ort: 'Eitting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '85462',
    },
    {
        id: '2870',
        ort: 'Eixen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18334',
    },
    {
        id: '2871',
        ort: 'Elbe',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38274',
    },
    {
        id: '2872',
        ort: 'Elben',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57580',
    },
    {
        id: '2873',
        ort: 'Elbe-Parey',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Jerichower Land',
        plz: '39307',
    },
    {
        id: '2874',
        ort: 'Elbe-Parey',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Jerichower Land',
        plz: '39317',
    },
    {
        id: '2875',
        ort: 'Elbingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '2876',
        ort: 'Elbingerode',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37412',
    },
    {
        id: '2877',
        ort: 'Elbtal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65627',
    },
    {
        id: '2878',
        ort: 'Elchesheim-Illingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '76477',
    },
    {
        id: '2879',
        ort: 'Elchingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89081',
    },
    {
        id: '2880',
        ort: 'Elchingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89275',
    },
    {
        id: '2881',
        ort: 'Elchweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55765',
    },
    {
        id: '2882',
        ort: 'Eldena',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19294',
    },
    {
        id: '2883',
        ort: 'Eldetal',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17209',
    },
    {
        id: '2884',
        ort: 'Eldingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29351',
    },
    {
        id: '2885',
        ort: 'Elfershausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97725',
    },
    {
        id: '2886',
        ort: 'Elgersburg',
        bundesland: 'Thüringen',
        landkreis: 'Ilm-Kreis',
        plz: '98716',
    },
    {
        id: '2887',
        ort: 'Elisabeth-Sophien-Koog',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25845',
    },
    {
        id: '2888',
        ort: 'Elkenroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57578',
    },
    {
        id: '2889',
        ort: 'Elleben',
        bundesland: 'Thüringen',
        landkreis: 'Ilm-Kreis',
        plz: '99334',
    },
    {
        id: '2890',
        ort: 'Ellefeld',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08236',
    },
    {
        id: '2891',
        ort: 'Ellenberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73488',
    },
    {
        id: '2892',
        ort: 'Ellenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55765',
    },
    {
        id: '2893',
        ort: 'Ellenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56242',
    },
    {
        id: '2894',
        ort: 'Ellenz-Poltersdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56821',
    },
    {
        id: '2895',
        ort: 'Ellerau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '25479',
    },
    {
        id: '2896',
        ort: 'Ellerbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25474',
    },
    {
        id: '2897',
        ort: 'Ellerdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24589',
    },
    {
        id: '2898',
        ort: 'Ellerhoop',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25373',
    },
    {
        id: '2899',
        ort: 'Ellern (Hunsrück)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55497',
    },
    {
        id: '2900',
        ort: 'Ellerstadt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67158',
    },
    {
        id: '2901',
        ort: 'Ellgau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86679',
    },
    {
        id: '2902',
        ort: 'Ellhofen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74248',
    },
    {
        id: '2903',
        ort: 'Ellhöft',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25923',
    },
    {
        id: '2904',
        ort: 'Ellingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91792',
    },
    {
        id: '2905',
        ort: 'Ellingshausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98617',
    },
    {
        id: '2906',
        ort: 'Ellingstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24870',
    },
    {
        id: '2907',
        ort: 'Ellrich',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Nordhausen',
        plz: '99755',
    },
    {
        id: '2908',
        ort: 'Ellscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '2909',
        ort: 'Ellwangen (Jagst)',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73479',
    },
    {
        id: '2910',
        ort: 'Ellweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55765',
    },
    {
        id: '2911',
        ort: 'Ellzee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89352',
    },
    {
        id: '2912',
        ort: 'Elmenhorst',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18510',
    },
    {
        id: '2913',
        ort: 'Elmenhorst',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23869',
    },
    {
        id: '2914',
        ort: 'Elmenhorst',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21493',
    },
    {
        id: '2915',
        ort: 'Elmenhorst/Lichtenhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18107',
    },
    {
        id: '2916',
        ort: 'Elmshorn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25335',
    },
    {
        id: '2917',
        ort: 'Elmshorn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25336',
    },
    {
        id: '2918',
        ort: 'Elmshorn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25337',
    },
    {
        id: '2919',
        ort: 'Elmstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67471',
    },
    {
        id: '2920',
        ort: 'Elpersbüttel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25704',
    },
    {
        id: '2921',
        ort: 'Elsdorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Erft-Kreis',
        plz: '50189',
    },
    {
        id: '2922',
        ort: 'Elsdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27404',
    },
    {
        id: '2923',
        ort: 'Elsdorf-Westermühlen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24800',
    },
    {
        id: '2924',
        ort: 'Elsendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '84094',
    },
    {
        id: '2925',
        ort: 'Elsenfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63820',
    },
    {
        id: '2926',
        ort: 'Elsfleth',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wesermarsch',
        plz: '26931',
    },
    {
        id: '2927',
        ort: 'Elskop',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25361',
    },
    {
        id: '2928',
        ort: 'Elsnig',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04880',
    },
    {
        id: '2929',
        ort: 'Elsoff (Westerwald)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '2930',
        ort: 'Elsteraue',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06729',
    },
    {
        id: '2931',
        ort: 'Elsterberg',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '07985',
    },
    {
        id: '2932',
        ort: 'Elsterheide - Halštrowska Hola',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02979',
    },
    {
        id: '2933',
        ort: 'Elstertrebnitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04523',
    },
    {
        id: '2934',
        ort: 'Elsterwerda',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04910',
    },
    {
        id: '2935',
        ort: 'Elstra - Halštrow',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01920',
    },
    {
        id: '2936',
        ort: 'Elterlein',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09481',
    },
    {
        id: '2937',
        ort: 'Eltmann',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '97483',
    },
    {
        id: '2938',
        ort: 'Eltville am Rhein',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65343',
    },
    {
        id: '2939',
        ort: 'Eltville am Rhein',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65344',
    },
    {
        id: '2940',
        ort: 'Eltville am Rhein',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65345',
    },
    {
        id: '2941',
        ort: 'Eltville am Rhein',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65346',
    },
    {
        id: '2942',
        ort: 'Eltville am Rhein',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65347',
    },
    {
        id: '2943',
        ort: 'Elxleben',
        bundesland: 'Thüringen',
        landkreis: 'Ilm-Kreis',
        plz: '99334',
    },
    {
        id: '2944',
        ort: 'Elxleben',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99189',
    },
    {
        id: '2945',
        ort: 'Elz',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65604',
    },
    {
        id: '2946',
        ort: 'Elzach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79215',
    },
    {
        id: '2947',
        ort: 'Elze',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31008',
    },
    {
        id: '2948',
        ort: 'Elztal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74834',
    },
    {
        id: '2949',
        ort: 'Elzweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66887',
    },
    {
        id: '2950',
        ort: 'Embsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21409',
    },
    {
        id: '2951',
        ort: 'Embühren',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24819',
    },
    {
        id: '2952',
        ort: 'Emden',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '26721',
    },
    {
        id: '2953',
        ort: 'Emden',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '26723',
    },
    {
        id: '2954',
        ort: 'Emden',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '26725',
    },
    {
        id: '2955',
        ort: 'Emeringen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '88499',
    },
    {
        id: '2956',
        ort: 'Emerkingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89607',
    },
    {
        id: '2957',
        ort: 'Emersacker',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86494',
    },
    {
        id: '2958',
        ort: 'Emkendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24802',
    },
    {
        id: '2959',
        ort: 'Emleben',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99869',
    },
    {
        id: '2960',
        ort: 'Emlichheim',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '49824',
    },
    {
        id: '2961',
        ort: 'Emmelbaum',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '2962',
        ort: 'Emmelsbüll-Horsbüll',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25924',
    },
    {
        id: '2963',
        ort: 'Emmelshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56281',
    },
    {
        id: '2964',
        ort: 'Emmendingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79312',
    },
    {
        id: '2965',
        ort: 'Emmendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29579',
    },
    {
        id: '2966',
        ort: 'Emmerich am Rhein',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '46446',
    },
    {
        id: '2967',
        ort: 'Emmering',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '83550',
    },
    {
        id: '2968',
        ort: 'Emmering',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82275',
    },
    {
        id: '2969',
        ort: 'Emmerthal',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hameln-Pyrmont',
        plz: '31860',
    },
    {
        id: '2970',
        ort: 'Emmerting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84547',
    },
    {
        id: '2971',
        ort: 'Emmerzhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57520',
    },
    {
        id: '2972',
        ort: 'Emmingen-Liptingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78576',
    },
    {
        id: '2973',
        ort: 'Empfertshausen',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '36452',
    },
    {
        id: '2974',
        ort: 'Empfingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Freudenstadt',
        plz: '72186',
    },
    {
        id: '2975',
        ort: 'Emsbüren',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '48488',
    },
    {
        id: '2976',
        ort: 'Emsdetten',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '48282',
    },
    {
        id: '2977',
        ort: 'Emskirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91448',
    },
    {
        id: '2978',
        ort: 'Emstek',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cloppenburg',
        plz: '49685',
    },
    {
        id: '2979',
        ort: 'Emtinghausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Verden',
        plz: '27321',
    },
    {
        id: '2980',
        ort: 'Emtmannsberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95517',
    },
    {
        id: '2981',
        ort: 'Endingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79346',
    },
    {
        id: '2982',
        ort: 'Endlichhofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56355',
    },
    {
        id: '2983',
        ort: 'Endschütz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07570',
    },
    {
        id: '2984',
        ort: 'Engden',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '48465',
    },
    {
        id: '2985',
        ort: 'Engelbrechtsche Wildnis',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25348',
    },
    {
        id: '2986',
        ort: 'Engelsberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '84549',
    },
    {
        id: '2987',
        ort: 'Engelsbrand',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75331',
    },
    {
        id: '2988',
        ort: 'Engelschoff',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21710',
    },
    {
        id: '2989',
        ort: 'Engelskirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Oberbergischer Kreis',
        plz: '51766',
    },
    {
        id: '2990',
        ort: 'Engelstadt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55270',
    },
    {
        id: '2991',
        ort: 'Engelthal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91238',
    },
    {
        id: '2992',
        ort: 'Engen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78234',
    },
    {
        id: '2993',
        ort: 'Enger',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Herford',
        plz: '32130',
    },
    {
        id: '2994',
        ort: 'Enge-Sande',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25917',
    },
    {
        id: '2995',
        ort: 'Engstingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72829',
    },
    {
        id: '2996',
        ort: 'Eningen unter Achalm',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72800',
    },
    {
        id: '2997',
        ort: 'Enkenbach-Alsenborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67677',
    },
    {
        id: '2998',
        ort: 'Enkirch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '56850',
    },
    {
        id: '2999',
        ort: 'Ennepetal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Ennepe-Ruhr-Kreis',
        plz: '58256',
    },
    {
        id: '3000',
        ort: 'Ennigerloh',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Warendorf',
        plz: '59320',
    },
    {
        id: '3001',
        ort: 'Ensch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54340',
    },
    {
        id: '3002',
        ort: 'Ensdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92266',
    },
    {
        id: '3003',
        ort: 'Ensdorf/Saar',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Saarlouis',
        plz: '66806',
    },
    {
        id: '3004',
        ort: 'Ense',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Soest',
        plz: '59469',
    },
    {
        id: '3005',
        ort: 'Ensheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55232',
    },
    {
        id: '3006',
        ort: 'Enspel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57647',
    },
    {
        id: '3007',
        ort: 'Enzen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54646',
    },
    {
        id: '3008',
        ort: 'Enzklösterle',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '75337',
    },
    {
        id: '3009',
        ort: 'Epenwöhrden',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25704',
    },
    {
        id: '3010',
        ort: 'Epfenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '74925',
    },
    {
        id: '3011',
        ort: 'Epfendorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78736',
    },
    {
        id: '3012',
        ort: 'Eppelborn',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Neunkirchen',
        plz: '66571',
    },
    {
        id: '3013',
        ort: 'Eppelheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69214',
    },
    {
        id: '3014',
        ort: 'Eppelsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '3015',
        ort: 'Eppenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56759',
    },
    {
        id: '3016',
        ort: 'Eppenbrunn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66957',
    },
    {
        id: '3017',
        ort: 'Eppendorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09575',
    },
    {
        id: '3018',
        ort: 'Eppenrod',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65558',
    },
    {
        id: '3019',
        ort: 'Eppenschlag',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94536',
    },
    {
        id: '3020',
        ort: 'Eppertshausen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64859',
    },
    {
        id: '3021',
        ort: 'Eppingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '75031',
    },
    {
        id: '3022',
        ort: 'Eppishausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87745',
    },
    {
        id: '3023',
        ort: 'Eppstein',
        bundesland: 'Hessen',
        landkreis: 'Main-Taunus-Kreis',
        plz: '65817',
    },
    {
        id: '3024',
        ort: 'Erbach',
        bundesland: 'Hessen',
        landkreis: 'Odenwaldkreis',
        plz: '64711',
    },
    {
        id: '3025',
        ort: 'Erbach',
        bundesland: 'Hessen',
        landkreis: 'Odenwaldkreis',
        plz: '64743',
    },
    {
        id: '3026',
        ort: 'Erbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55494',
    },
    {
        id: '3027',
        ort: 'Erbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89155',
    },
    {
        id: '3028',
        ort: 'Erbendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '92681',
    },
    {
        id: '3029',
        ort: 'Erbenhausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98634',
    },
    {
        id: '3030',
        ort: 'Erbes-Büdesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '3031',
        ort: 'Erden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54492',
    },
    {
        id: '3032',
        ort: 'Erdesbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66887',
    },
    {
        id: '3033',
        ort: 'Erding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '85435',
    },
    {
        id: '3034',
        ort: 'Erdmannhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71729',
    },
    {
        id: '3035',
        ort: 'Erdweg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '85253',
    },
    {
        id: '3036',
        ort: 'Eresing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86922',
    },
    {
        id: '3037',
        ort: 'Eresing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86926',
    },
    {
        id: '3038',
        ort: 'Eresing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86941',
    },
    {
        id: '3039',
        ort: 'Erfde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24803',
    },
    {
        id: '3040',
        ort: 'Erftstadt',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Erft-Kreis',
        plz: '50374',
    },
    {
        id: '3041',
        ort: 'Erfurt',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99084',
    },
    {
        id: '3042',
        ort: 'Erfurt',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99085',
    },
    {
        id: '3043',
        ort: 'Erfurt',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99086',
    },
    {
        id: '3044',
        ort: 'Erfurt',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99087',
    },
    {
        id: '3045',
        ort: 'Erfurt',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99089',
    },
    {
        id: '3046',
        ort: 'Erfurt',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99090',
    },
    {
        id: '3047',
        ort: 'Erfurt',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99091',
    },
    {
        id: '3048',
        ort: 'Erfurt',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99092',
    },
    {
        id: '3049',
        ort: 'Erfurt',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99094',
    },
    {
        id: '3050',
        ort: 'Erfurt',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99095',
    },
    {
        id: '3051',
        ort: 'Erfurt',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99096',
    },
    {
        id: '3052',
        ort: 'Erfurt',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99097',
    },
    {
        id: '3053',
        ort: 'Erfurt',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99098',
    },
    {
        id: '3054',
        ort: 'Erfurt',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99099',
    },
    {
        id: '3055',
        ort: 'Erfweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66996',
    },
    {
        id: '3056',
        ort: 'Ergersheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91465',
    },
    {
        id: '3057',
        ort: 'Ergeshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56368',
    },
    {
        id: '3058',
        ort: 'Ergolding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84030',
    },
    {
        id: '3059',
        ort: 'Ergoldsbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84061',
    },
    {
        id: '3060',
        ort: 'Erharting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84513',
    },
    {
        id: '3061',
        ort: 'Ering',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '94140',
    },
    {
        id: '3062',
        ort: 'Eriskirch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88097',
    },
    {
        id: '3063',
        ort: 'Erkelenz',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Heinsberg',
        plz: '41812',
    },
    {
        id: '3064',
        ort: 'Erkenbrechtsweiler',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73268',
    },
    {
        id: '3065',
        ort: 'Erkerode',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38173',
    },
    {
        id: '3066',
        ort: 'Erkheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87746',
    },
    {
        id: '3067',
        ort: 'Erkner',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15537',
    },
    {
        id: '3068',
        ort: 'Erkrath',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '40699',
    },
    {
        id: '3069',
        ort: 'Erlabrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97250',
    },
    {
        id: '3070',
        ort: 'Erlangen',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '91052',
    },
    {
        id: '3071',
        ort: 'Erlangen',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '91054',
    },
    {
        id: '3072',
        ort: 'Erlangen',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '91056',
    },
    {
        id: '3073',
        ort: 'Erlangen',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '91058',
    },
    {
        id: '3074',
        ort: 'Erlau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09306',
    },
    {
        id: '3075',
        ort: 'Erlbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84567',
    },
    {
        id: '3076',
        ort: 'Erlenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74235',
    },
    {
        id: '3077',
        ort: 'Erlenbach am Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63906',
    },
    {
        id: '3078',
        ort: 'Erlenbach bei Dahn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '76891',
    },
    {
        id: '3079',
        ort: 'Erlenbach bei Kandel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76872',
    },
    {
        id: '3080',
        ort: 'Erlenbach b. Marktheidenfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97837',
    },
    {
        id: '3081',
        ort: 'Erlenmoos',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88416',
    },
    {
        id: '3082',
        ort: 'Erlensee',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63526',
    },
    {
        id: '3083',
        ort: 'Erligheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '74391',
    },
    {
        id: '3084',
        ort: 'Ermershausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '96126',
    },
    {
        id: '3085',
        ort: 'Erndtebrück',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Siegen-Wittgenstein',
        plz: '57339',
    },
    {
        id: '3086',
        ort: 'Ernsgaden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85119',
    },
    {
        id: '3087',
        ort: 'Ernst',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56814',
    },
    {
        id: '3088',
        ort: 'Ernzen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54668',
    },
    {
        id: '3089',
        ort: 'Erolzheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88453',
    },
    {
        id: '3090',
        ort: 'Erpel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53579',
    },
    {
        id: '3091',
        ort: 'Erpolzheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67167',
    },
    {
        id: '3092',
        ort: 'Ersfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57635',
    },
    {
        id: '3093',
        ort: 'Ertingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88521',
    },
    {
        id: '3094',
        ort: 'Erwitte',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Soest',
        plz: '59597',
    },
    {
        id: '3095',
        ort: 'Erxleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39343',
    },
    {
        id: '3096',
        ort: 'Erzenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67685',
    },
    {
        id: '3097',
        ort: 'Erzhausen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64390',
    },
    {
        id: '3098',
        ort: 'Esch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54585',
    },
    {
        id: '3099',
        ort: 'Esch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '3100',
        ort: 'Eschach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73569',
    },
    {
        id: '3101',
        ort: 'Eschau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63863',
    },
    {
        id: '3102',
        ort: 'Eschbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '3103',
        ort: 'Eschbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76831',
    },
    {
        id: '3104',
        ort: 'Eschbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79427',
    },
    {
        id: '3105',
        ort: 'Eschborn',
        bundesland: 'Hessen',
        landkreis: 'Main-Taunus-Kreis',
        plz: '65760',
    },
    {
        id: '3106',
        ort: 'Eschbronn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78664',
    },
    {
        id: '3107',
        ort: 'Esche',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '49828',
    },
    {
        id: '3108',
        ort: 'Escheburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21039',
    },
    {
        id: '3109',
        ort: 'Eschede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29348',
    },
    {
        id: '3110',
        ort: 'Eschede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29359',
    },
    {
        id: '3111',
        ort: 'Eschede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29361',
    },
    {
        id: '3112',
        ort: 'Eschelbronn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '74927',
    },
    {
        id: '3113',
        ort: 'Eschenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73037',
    },
    {
        id: '3114',
        ort: 'Eschenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73107',
    },
    {
        id: '3115',
        ort: 'Eschenbach i.d.OPf.',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92676',
    },
    {
        id: '3116',
        ort: 'Eschenbergen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99869',
    },
    {
        id: '3117',
        ort: 'Eschenburg',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35713',
    },
    {
        id: '3118',
        ort: 'Eschenlohe',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82438',
    },
    {
        id: '3119',
        ort: 'Eschershausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37632',
    },
    {
        id: '3120',
        ort: 'Eschfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54619',
    },
    {
        id: '3121',
        ort: 'Eschlkam',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93458',
    },
    {
        id: '3122',
        ort: 'Eschwege',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37269',
    },
    {
        id: '3123',
        ort: 'Eschweiler',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52249',
    },
    {
        id: '3124',
        ort: 'Esens',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26427',
    },
    {
        id: '3125',
        ort: 'Esgrus',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24402',
    },
    {
        id: '3126',
        ort: 'Eslarn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92693',
    },
    {
        id: '3127',
        ort: 'Eslohe',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Hochsauerlandkreis',
        plz: '59889',
    },
    {
        id: '3128',
        ort: 'Espelkamp',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Minden-Lübbecke',
        plz: '32339',
    },
    {
        id: '3129',
        ort: 'Espenau',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34314',
    },
    {
        id: '3130',
        ort: 'Eßbach',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07924',
    },
    {
        id: '3131',
        ort: 'Essel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29690',
    },
    {
        id: '3132',
        ort: 'Esselbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97839',
    },
    {
        id: '3133',
        ort: 'Esselborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '3134',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45127',
    },
    {
        id: '3135',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45128',
    },
    {
        id: '3136',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45130',
    },
    {
        id: '3137',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45131',
    },
    {
        id: '3138',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45133',
    },
    {
        id: '3139',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45134',
    },
    {
        id: '3140',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45136',
    },
    {
        id: '3141',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45138',
    },
    {
        id: '3142',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45139',
    },
    {
        id: '3143',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45141',
    },
    {
        id: '3144',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45143',
    },
    {
        id: '3145',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45144',
    },
    {
        id: '3146',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45145',
    },
    {
        id: '3147',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45147',
    },
    {
        id: '3148',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45149',
    },
    {
        id: '3149',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45219',
    },
    {
        id: '3150',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45239',
    },
    {
        id: '3151',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45257',
    },
    {
        id: '3152',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45259',
    },
    {
        id: '3153',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45276',
    },
    {
        id: '3154',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45277',
    },
    {
        id: '3155',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45279',
    },
    {
        id: '3156',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45289',
    },
    {
        id: '3157',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45307',
    },
    {
        id: '3158',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45309',
    },
    {
        id: '3159',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45326',
    },
    {
        id: '3160',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45327',
    },
    {
        id: '3161',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45329',
    },
    {
        id: '3162',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45355',
    },
    {
        id: '3163',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45356',
    },
    {
        id: '3164',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45357',
    },
    {
        id: '3165',
        ort: 'Essen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45359',
    },
    {
        id: '3166',
        ort: 'Essenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84051',
    },
    {
        id: '3167',
        ort: 'Essenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55270',
    },
    {
        id: '3168',
        ort: 'Essen (Oldenburg)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cloppenburg',
        plz: '49632',
    },
    {
        id: '3169',
        ort: 'Essing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '93343',
    },
    {
        id: '3170',
        ort: 'Essingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76879',
    },
    {
        id: '3171',
        ort: 'Essingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73457',
    },
    {
        id: '3172',
        ort: 'Essingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73563',
    },
    {
        id: '3173',
        ort: 'Essingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73566',
    },
    {
        id: '3174',
        ort: 'Eßlingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '3175',
        ort: 'Esslingen am Neckar',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73728',
    },
    {
        id: '3176',
        ort: 'Esslingen am Neckar',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73730',
    },
    {
        id: '3177',
        ort: 'Esslingen am Neckar',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73732',
    },
    {
        id: '3178',
        ort: 'Esslingen am Neckar',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73733',
    },
    {
        id: '3179',
        ort: 'Esslingen am Neckar',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73734',
    },
    {
        id: '3180',
        ort: 'Eßweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67754',
    },
    {
        id: '3181',
        ort: 'Estenfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97230',
    },
    {
        id: '3182',
        ort: 'Esterwegen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26897',
    },
    {
        id: '3183',
        ort: 'Esthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67466',
    },
    {
        id: '3184',
        ort: 'Esthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67468',
    },
    {
        id: '3185',
        ort: 'Esthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67472',
    },
    {
        id: '3186',
        ort: 'Estorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21727',
    },
    {
        id: '3187',
        ort: 'Estorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31629',
    },
    {
        id: '3188',
        ort: 'Etgert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54424',
    },
    {
        id: '3189',
        ort: 'Etschberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66871',
    },
    {
        id: '3190',
        ort: 'Ettal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82488',
    },
    {
        id: '3191',
        ort: 'Etteldorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54655',
    },
    {
        id: '3192',
        ort: 'Ettenheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77955',
    },
    {
        id: '3193',
        ort: 'Ettenstatt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91796',
    },
    {
        id: '3194',
        ort: 'Ettersburg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99439',
    },
    {
        id: '3195',
        ort: 'Ettinghausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '3196',
        ort: 'Ettlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76275',
    },
    {
        id: '3197',
        ort: 'Ettringen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '3198',
        ort: 'Ettringen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '86833',
    },
    {
        id: '3199',
        ort: 'Etzbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57539',
    },
    {
        id: '3200',
        ort: 'Etzelwang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92268',
    },
    {
        id: '3201',
        ort: 'Etzenricht',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92694',
    },
    {
        id: '3202',
        ort: 'Etzleben',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '06577',
    },
    {
        id: '3203',
        ort: 'Euerbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97502',
    },
    {
        id: '3204',
        ort: 'Euerdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97717',
    },
    {
        id: '3205',
        ort: 'Eulenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57632',
    },
    {
        id: '3206',
        ort: 'Eulenbis',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67685',
    },
    {
        id: '3207',
        ort: 'Eulgem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56761',
    },
    {
        id: '3208',
        ort: 'Eurasburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86495',
    },
    {
        id: '3209',
        ort: 'Eurasburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '82547',
    },
    {
        id: '3210',
        ort: 'Euscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '3211',
        ort: 'Euskirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Euskirchen',
        plz: '53879',
    },
    {
        id: '3212',
        ort: 'Euskirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Euskirchen',
        plz: '53881',
    },
    {
        id: '3213',
        ort: 'Eußenheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97776',
    },
    {
        id: '3214',
        ort: 'Eußerthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76857',
    },
    {
        id: '3215',
        ort: 'Eutin',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23701',
    },
    {
        id: '3216',
        ort: 'Eutingen im Gäu',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Freudenstadt',
        plz: '72184',
    },
    {
        id: '3217',
        ort: 'Eversmeer',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26556',
    },
    {
        id: '3218',
        ort: 'Everswinkel',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Warendorf',
        plz: '48351',
    },
    {
        id: '3219',
        ort: 'Evessen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38173',
    },
    {
        id: '3220',
        ort: 'Ewighausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '3221',
        ort: 'Extertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '32699',
    },
    {
        id: '3222',
        ort: 'Eydelstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49406',
    },
    {
        id: '3223',
        ort: 'Eyendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21376',
    },
    {
        id: '3224',
        ort: 'Eystrup',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '27324',
    },
    {
        id: '3225',
        ort: 'Fachbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56133',
    },
    {
        id: '3226',
        ort: 'Fahrdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24857',
    },
    {
        id: '3227',
        ort: 'Fahren',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24253',
    },
    {
        id: '3228',
        ort: 'Fahrenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74864',
    },
    {
        id: '3229',
        ort: 'Fahrenkrug',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23795',
    },
    {
        id: '3230',
        ort: 'Fahrenwalde',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17309',
    },
    {
        id: '3231',
        ort: 'Fahrenzhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85777',
    },
    {
        id: '3232',
        ort: 'Faid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56814',
    },
    {
        id: '3233',
        ort: 'Falkenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84326',
    },
    {
        id: '3234',
        ort: 'Falkenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95685',
    },
    {
        id: '3235',
        ort: 'Falkenberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '16259',
    },
    {
        id: '3236',
        ort: 'Falkenberg/Elster',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04895',
    },
    {
        id: '3237',
        ort: 'Falkenfels',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94350',
    },
    {
        id: '3238',
        ort: 'Falkenhagen (Mark)',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15306',
    },
    {
        id: '3239',
        ort: 'Falkensee',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14612',
    },
    {
        id: '3240',
        ort: 'Falkenstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93167',
    },
    {
        id: '3241',
        ort: 'Falkenstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67808',
    },
    {
        id: '3242',
        ort: 'Falkenstein/Harz',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '06333',
    },
    {
        id: '3243',
        ort: 'Falkenstein/Harz',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '06463',
    },
    {
        id: '3244',
        ort: 'Falkenstein/Harz',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '06543',
    },
    {
        id: '3245',
        ort: 'Falkenstein/Vogtland',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08223',
    },
    {
        id: '3246',
        ort: 'Falkenstein/Vogtland',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08239',
    },
    {
        id: '3247',
        ort: 'Fambach',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98597',
    },
    {
        id: '3248',
        ort: 'Farchant',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82490',
    },
    {
        id: '3249',
        ort: 'Fargau-Pratjau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24256',
    },
    {
        id: '3250',
        ort: 'Farnstädt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06279',
    },
    {
        id: '3251',
        ort: 'Farschweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54317',
    },
    {
        id: '3252',
        ort: 'Farven',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27446',
    },
    {
        id: '3253',
        ort: 'Faßberg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29328',
    },
    {
        id: '3254',
        ort: 'Faulbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '97906',
    },
    {
        id: '3255',
        ort: 'Faulenrost',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17139',
    },
    {
        id: '3256',
        ort: 'Fedderingen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25779',
    },
    {
        id: '3257',
        ort: 'Fehl-Ritzhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56472',
    },
    {
        id: '3258',
        ort: 'Fehmarn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23769',
    },
    {
        id: '3259',
        ort: 'Fehrbellin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16818',
    },
    {
        id: '3260',
        ort: 'Fehrbellin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16833',
    },
    {
        id: '3261',
        ort: 'Fehrbellin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16845',
    },
    {
        id: '3262',
        ort: 'Feichten an der Alz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84550',
    },
    {
        id: '3263',
        ort: 'Feilbingert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '67824',
    },
    {
        id: '3264',
        ort: 'Feilitzsch',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95183',
    },
    {
        id: '3265',
        ort: 'Feilsdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '3266',
        ort: 'Feldafing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Starnberg',
        plz: '82340',
    },
    {
        id: '3267',
        ort: 'Feldatal',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '36325',
    },
    {
        id: '3268',
        ort: 'Feldberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79868',
    },
    {
        id: '3269',
        ort: 'Feldberger Seenlandschaft',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17258',
    },
    {
        id: '3270',
        ort: 'Felde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24242',
    },
    {
        id: '3271',
        ort: 'Feldhorst',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23858',
    },
    {
        id: '3272',
        ort: 'Feldkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94351',
    },
    {
        id: '3273',
        ort: 'Feldkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '85622',
    },
    {
        id: '3274',
        ort: 'Feldkirchen-Westerham',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83620',
    },
    {
        id: '3275',
        ort: 'Felixsee',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03130',
    },
    {
        id: '3276',
        ort: 'Fell',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54341',
    },
    {
        id: '3277',
        ort: 'Fellbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '70734',
    },
    {
        id: '3278',
        ort: 'Fellbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '70736',
    },
    {
        id: '3279',
        ort: 'Fellen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97778',
    },
    {
        id: '3280',
        ort: 'Fellheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87748',
    },
    {
        id: '3281',
        ort: 'Felm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24161',
    },
    {
        id: '3282',
        ort: 'Felm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24214',
    },
    {
        id: '3283',
        ort: 'Felm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24244',
    },
    {
        id: '3284',
        ort: 'Felsberg',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34587',
    },
    {
        id: '3285',
        ort: 'Fensdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57580',
    },
    {
        id: '3286',
        ort: 'Fensterbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92269',
    },
    {
        id: '3287',
        ort: 'Ferdinandshof',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17379',
    },
    {
        id: '3288',
        ort: 'Ferna',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37339',
    },
    {
        id: '3289',
        ort: 'Fernwald',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35463',
    },
    {
        id: '3290',
        ort: 'Ferschweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54668',
    },
    {
        id: '3291',
        ort: 'Feucht',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '90537',
    },
    {
        id: '3292',
        ort: 'Feuchtwangen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91555',
    },
    {
        id: '3293',
        ort: 'Feuerscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '3294',
        ort: 'Feusdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54584',
    },
    {
        id: '3295',
        ort: 'Fichtelberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95686',
    },
    {
        id: '3296',
        ort: 'Fichtenau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74579',
    },
    {
        id: '3297',
        ort: 'Fichtenberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74427',
    },
    {
        id: '3298',
        ort: 'Fichtenhöhe',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15306',
    },
    {
        id: '3299',
        ort: 'Fichtwald',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04936',
    },
    {
        id: '3300',
        ort: 'Fiefbergen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24217',
    },
    {
        id: '3301',
        ort: 'Fiersbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57635',
    },
    {
        id: '3302',
        ort: 'Filderstadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '70794',
    },
    {
        id: '3303',
        ort: 'Filderstadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '71111',
    },
    {
        id: '3304',
        ort: 'Filsen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56341',
    },
    {
        id: '3305',
        ort: 'Filsum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26849',
    },
    {
        id: '3306',
        ort: 'Filz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56766',
    },
    {
        id: '3307',
        ort: 'Fincken',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17209',
    },
    {
        id: '3308',
        ort: 'Finkenbach-Gersweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67822',
    },
    {
        id: '3309',
        ort: 'Finkenthal',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17179',
    },
    {
        id: '3310',
        ort: 'Finne',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06647',
    },
    {
        id: '3311',
        ort: 'Finneland',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06647',
    },
    {
        id: '3312',
        ort: 'Finnentrop',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Olpe',
        plz: '57413',
    },
    {
        id: '3313',
        ort: 'Finning',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86923',
    },
    {
        id: '3314',
        ort: 'Finningen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89435',
    },
    {
        id: '3315',
        ort: 'Finsing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '85464',
    },
    {
        id: '3316',
        ort: 'Finsterwalde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '03238',
    },
    {
        id: '3317',
        ort: 'Fintel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27389',
    },
    {
        id: '3318',
        ort: 'Firrel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26835',
    },
    {
        id: '3319',
        ort: 'Fisch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54439',
    },
    {
        id: '3320',
        ort: 'Fischach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86850',
    },
    {
        id: '3321',
        ort: 'Fischbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55743',
    },
    {
        id: '3322',
        ort: 'Fischbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67693',
    },
    {
        id: '3323',
        ort: 'Fischbachau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miesbach',
        plz: '83730',
    },
    {
        id: '3324',
        ort: 'Fischbach bei Dahn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66996',
    },
    {
        id: '3325',
        ort: 'Fischbach-Oberraden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '3326',
        ort: 'Fischbachtal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64405',
    },
    {
        id: '3327',
        ort: 'Fischen im Allgäu',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87538',
    },
    {
        id: '3328',
        ort: 'Fischerbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77716',
    },
    {
        id: '3329',
        ort: 'Fischingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79592',
    },
    {
        id: '3330',
        ort: 'Fitzbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25579',
    },
    {
        id: '3331',
        ort: 'Fitzen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21514',
    },
    {
        id: '3332',
        ort: 'Flachslanden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91604',
    },
    {
        id: '3333',
        ort: 'Flacht',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65558',
    },
    {
        id: '3334',
        ort: 'Fladungen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97650',
    },
    {
        id: '3335',
        ort: 'Flammersfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57632',
    },
    {
        id: '3336',
        ort: 'Flechtingen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39345',
    },
    {
        id: '3337',
        ort: 'Flechtingen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39356',
    },
    {
        id: '3338',
        ort: 'Flechtingen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39359',
    },
    {
        id: '3339',
        ort: 'Fleckeby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24357',
    },
    {
        id: '3340',
        ort: 'Flein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74223',
    },
    {
        id: '3341',
        ort: 'Fleischwangen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88373',
    },
    {
        id: '3342',
        ort: 'Flemlingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76835',
    },
    {
        id: '3343',
        ort: 'Flensburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24937',
    },
    {
        id: '3344',
        ort: 'Flensburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24939',
    },
    {
        id: '3345',
        ort: 'Flensburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24941',
    },
    {
        id: '3346',
        ort: 'Flensburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24943',
    },
    {
        id: '3347',
        ort: 'Flensburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24944',
    },
    {
        id: '3348',
        ort: 'Fleringen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '3349',
        ort: 'Flieden',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36103',
    },
    {
        id: '3350',
        ort: 'Fließem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '3351',
        ort: 'Flieth-Stegelitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17268',
    },
    {
        id: '3352',
        ort: 'Flintbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24220',
    },
    {
        id: '3353',
        ort: 'Flintsbach am Inn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83126',
    },
    {
        id: '3354',
        ort: 'Flöha',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09557',
    },
    {
        id: '3355',
        ort: 'Floh-Seligenthal',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98593',
    },
    {
        id: '3356',
        ort: 'Flomborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '3357',
        ort: 'Flonheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55237',
    },
    {
        id: '3358',
        ort: 'Flörsbachtal',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63639',
    },
    {
        id: '3359',
        ort: 'Flörsheim',
        bundesland: 'Hessen',
        landkreis: 'Main-Taunus-Kreis',
        plz: '65439',
    },
    {
        id: '3360',
        ort: 'Flörsheim-Dalsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67592',
    },
    {
        id: '3361',
        ort: 'Florstadt',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '61197',
    },
    {
        id: '3362',
        ort: 'Floß',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92685',
    },
    {
        id: '3363',
        ort: 'Flossenbürg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92696',
    },
    {
        id: '3364',
        ort: 'Flöthe',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38312',
    },
    {
        id: '3365',
        ort: 'Fluorn-Winzeln',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78737',
    },
    {
        id: '3366',
        ort: 'Flußbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54516',
    },
    {
        id: '3367',
        ort: 'Fluterschen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57614',
    },
    {
        id: '3368',
        ort: 'Fockbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24787',
    },
    {
        id: '3369',
        ort: 'Föckelberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66887',
    },
    {
        id: '3370',
        ort: 'Fockendorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04617',
    },
    {
        id: '3371',
        ort: 'Föhrden-Barl',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '25563',
    },
    {
        id: '3372',
        ort: 'Föhren',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54343',
    },
    {
        id: '3373',
        ort: 'Fohren-Linden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55777',
    },
    {
        id: '3374',
        ort: 'Forbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '76596',
    },
    {
        id: '3375',
        ort: 'Forchheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91301',
    },
    {
        id: '3376',
        ort: 'Forchheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79362',
    },
    {
        id: '3377',
        ort: 'Forchtenberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Hohenlohekreis',
        plz: '74670',
    },
    {
        id: '3378',
        ort: 'Forheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86735',
    },
    {
        id: '3379',
        ort: 'Föritztal',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sonneberg',
        plz: '96524',
    },
    {
        id: '3380',
        ort: 'Forst',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57537',
    },
    {
        id: '3381',
        ort: 'Forst',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76694',
    },
    {
        id: '3382',
        ort: 'Forst an der Weinstraße',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67147',
    },
    {
        id: '3383',
        ort: 'Forst (Eifel)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56754',
    },
    {
        id: '3384',
        ort: 'Forstern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '85659',
    },
    {
        id: '3385',
        ort: 'Forst (Hunsrück)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56858',
    },
    {
        id: '3386',
        ort: 'Forstinning',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85661',
    },
    {
        id: '3387',
        ort: 'Forst (Lausitz) - Baršć',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03149',
    },
    {
        id: '3388',
        ort: 'Forstmehren',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57635',
    },
    {
        id: '3389',
        ort: 'Framersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '3390',
        ort: 'Frammersbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97833',
    },
    {
        id: '3391',
        ort: 'Frankelbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67737',
    },
    {
        id: '3392',
        ort: 'Frankenau',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '35110',
    },
    {
        id: '3393',
        ort: 'Frankenberg (Eder)',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '35066',
    },
    {
        id: '3394',
        ort: 'Frankenberg/Sachsen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09669',
    },
    {
        id: '3395',
        ort: 'Frankenblick',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sonneberg',
        plz: '96528',
    },
    {
        id: '3396',
        ort: 'Frankendorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99441',
    },
    {
        id: '3397',
        ort: 'Frankeneck',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67468',
    },
    {
        id: '3398',
        ort: 'Frankenfeld',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '27336',
    },
    {
        id: '3399',
        ort: 'Frankenhardt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74586',
    },
    {
        id: '3400',
        ort: 'Frankenheim/Rhön',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98634',
    },
    {
        id: '3401',
        ort: 'Frankenroda',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '99826',
    },
    {
        id: '3402',
        ort: 'Frankenstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67468',
    },
    {
        id: '3403',
        ort: 'Frankenthal',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01909',
    },
    {
        id: '3404',
        ort: 'Frankenthal (Pfalz)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67227',
    },
    {
        id: '3405',
        ort: 'Frankenwinheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97447',
    },
    {
        id: '3406',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60306',
    },
    {
        id: '3407',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60308',
    },
    {
        id: '3408',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60310',
    },
    {
        id: '3409',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60311',
    },
    {
        id: '3410',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60312',
    },
    {
        id: '3411',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60313',
    },
    {
        id: '3412',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60314',
    },
    {
        id: '3413',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60315',
    },
    {
        id: '3414',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60316',
    },
    {
        id: '3415',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60318',
    },
    {
        id: '3416',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60320',
    },
    {
        id: '3417',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60322',
    },
    {
        id: '3418',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60323',
    },
    {
        id: '3419',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60325',
    },
    {
        id: '3420',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60326',
    },
    {
        id: '3421',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60327',
    },
    {
        id: '3422',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60329',
    },
    {
        id: '3423',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60385',
    },
    {
        id: '3424',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60386',
    },
    {
        id: '3425',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60388',
    },
    {
        id: '3426',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60389',
    },
    {
        id: '3427',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60431',
    },
    {
        id: '3428',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60433',
    },
    {
        id: '3429',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60435',
    },
    {
        id: '3430',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60437',
    },
    {
        id: '3431',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60438',
    },
    {
        id: '3432',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60439',
    },
    {
        id: '3433',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60486',
    },
    {
        id: '3434',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60487',
    },
    {
        id: '3435',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60488',
    },
    {
        id: '3436',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60489',
    },
    {
        id: '3437',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60528',
    },
    {
        id: '3438',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60529',
    },
    {
        id: '3439',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60549',
    },
    {
        id: '3440',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60594',
    },
    {
        id: '3441',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60596',
    },
    {
        id: '3442',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60598',
    },
    {
        id: '3443',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '60599',
    },
    {
        id: '3444',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '65929',
    },
    {
        id: '3445',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '65931',
    },
    {
        id: '3446',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '65933',
    },
    {
        id: '3447',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '65934',
    },
    {
        id: '3448',
        ort: 'Frankfurt am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '65936',
    },
    {
        id: '3449',
        ort: 'Frankfurt (Oder)',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '15230',
    },
    {
        id: '3450',
        ort: 'Frankfurt (Oder)',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '15232',
    },
    {
        id: '3451',
        ort: 'Frankfurt (Oder)',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '15234',
    },
    {
        id: '3452',
        ort: 'Frankfurt (Oder)',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '15236',
    },
    {
        id: '3453',
        ort: 'Fränkisch-Crumbach',
        bundesland: 'Hessen',
        landkreis: 'Odenwaldkreis',
        plz: '64407',
    },
    {
        id: '3454',
        ort: 'Frankweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76833',
    },
    {
        id: '3455',
        ort: 'Franzburg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18461',
    },
    {
        id: '3456',
        ort: 'Franzenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54316',
    },
    {
        id: '3457',
        ort: 'Frasdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83112',
    },
    {
        id: '3458',
        ort: 'Frauenau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94258',
    },
    {
        id: '3459',
        ort: 'Frauenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55776',
    },
    {
        id: '3460',
        ort: 'Frauendorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01945',
    },
    {
        id: '3461',
        ort: 'Frauenneuharting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '83553',
    },
    {
        id: '3462',
        ort: 'Frauenprießnitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07774',
    },
    {
        id: '3463',
        ort: 'Frauenstein',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09623',
    },
    {
        id: '3464',
        ort: 'Fraunberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '85447',
    },
    {
        id: '3465',
        ort: 'Fraureuth',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08427',
    },
    {
        id: '3466',
        ort: 'Frechen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Erft-Kreis',
        plz: '50226',
    },
    {
        id: '3467',
        ort: 'Freckenfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76872',
    },
    {
        id: '3468',
        ort: 'Fredeburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23909',
    },
    {
        id: '3469',
        ort: 'Fredenbeck',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21717',
    },
    {
        id: '3470',
        ort: 'Freden (Leine)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31084',
    },
    {
        id: '3471',
        ort: 'Fredersdorf-Vogelsdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15370',
    },
    {
        id: '3472',
        ort: 'Fredesdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23826',
    },
    {
        id: '3473',
        ort: 'Freiamt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79348',
    },
    {
        id: '3474',
        ort: 'Freiberg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09599',
    },
    {
        id: '3475',
        ort: 'Freiberg am Neckar',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71691',
    },
    {
        id: '3476',
        ort: 'Freiburg (Elbe)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21729',
    },
    {
        id: '3477',
        ort: 'Freiburg im Breisgau',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '79098',
    },
    {
        id: '3478',
        ort: 'Freiburg im Breisgau',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '79100',
    },
    {
        id: '3479',
        ort: 'Freiburg im Breisgau',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '79102',
    },
    {
        id: '3480',
        ort: 'Freiburg im Breisgau',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '79104',
    },
    {
        id: '3481',
        ort: 'Freiburg im Breisgau',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '79106',
    },
    {
        id: '3482',
        ort: 'Freiburg im Breisgau',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '79108',
    },
    {
        id: '3483',
        ort: 'Freiburg im Breisgau',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '79110',
    },
    {
        id: '3484',
        ort: 'Freiburg im Breisgau',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '79111',
    },
    {
        id: '3485',
        ort: 'Freiburg im Breisgau',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '79112',
    },
    {
        id: '3486',
        ort: 'Freiburg im Breisgau',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '79114',
    },
    {
        id: '3487',
        ort: 'Freiburg im Breisgau',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '79115',
    },
    {
        id: '3488',
        ort: 'Freiburg im Breisgau',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '79117',
    },
    {
        id: '3489',
        ort: 'Freienbessingen',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '99713',
    },
    {
        id: '3490',
        ort: 'Freienhagen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '3491',
        ort: 'Freienorla',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07768',
    },
    {
        id: '3492',
        ort: 'Freiensteinau',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '36399',
    },
    {
        id: '3493',
        ort: 'Freienwill',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24991',
    },
    {
        id: '3494',
        ort: 'Freigericht',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63579',
    },
    {
        id: '3495',
        ort: 'Freihung',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92271',
    },
    {
        id: '3496',
        ort: 'Freilassing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Berchtesgadener Land',
        plz: '83395',
    },
    {
        id: '3497',
        ort: 'Frei-Laubersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55546',
    },
    {
        id: '3498',
        ort: 'Freilingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '3499',
        ort: 'Freimersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '3500',
        ort: 'Freimersheim (Pfalz)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '67482',
    },
    {
        id: '3501',
        ort: 'Freinsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67098',
    },
    {
        id: '3502',
        ort: 'Freinsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67251',
    },
    {
        id: '3503',
        ort: 'Freirachdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '3504',
        ort: 'Freisbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '67361',
    },
    {
        id: '3505',
        ort: 'Freisen',
        bundesland: 'Saarland',
        landkreis: 'Landkreis St. Wendel',
        plz: '66629',
    },
    {
        id: '3506',
        ort: 'Freising',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85354',
    },
    {
        id: '3507',
        ort: 'Freising',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85356',
    },
    {
        id: '3508',
        ort: 'Freistatt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27259',
    },
    {
        id: '3509',
        ort: 'Freital',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01705',
    },
    {
        id: '3510',
        ort: 'Frellstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38373',
    },
    {
        id: '3511',
        ort: 'Fremdingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86742',
    },
    {
        id: '3512',
        ort: 'Frensdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96158',
    },
    {
        id: '3513',
        ort: 'Freren',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49832',
    },
    {
        id: '3514',
        ort: 'Fresenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49762',
    },
    {
        id: '3515',
        ort: 'Fresendelf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25876',
    },
    {
        id: '3516',
        ort: 'Frestedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25727',
    },
    {
        id: '3517',
        ort: 'Frettenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67596',
    },
    {
        id: '3518',
        ort: 'Fretterode',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '3519',
        ort: 'Freudenberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Siegen-Wittgenstein',
        plz: '57258',
    },
    {
        id: '3520',
        ort: 'Freudenberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Main-Tauber-Kreis',
        plz: '97896',
    },
    {
        id: '3521',
        ort: 'Freudenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92272',
    },
    {
        id: '3522',
        ort: 'Freudenburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54450',
    },
    {
        id: '3523',
        ort: 'Freudenstadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Freudenstadt',
        plz: '72250',
    },
    {
        id: '3524',
        ort: 'Freudental',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '74392',
    },
    {
        id: '3525',
        ort: 'Freyburg (Unstrut)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06632',
    },
    {
        id: '3526',
        ort: 'Freystadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92342',
    },
    {
        id: '3527',
        ort: 'Freyung',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94078',
    },
    {
        id: '3528',
        ort: 'Frickenhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '72636',
    },
    {
        id: '3529',
        ort: 'Frickenhausen a.Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97252',
    },
    {
        id: '3530',
        ort: 'Frickingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88699',
    },
    {
        id: '3531',
        ort: 'Fridingen an der Donau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78567',
    },
    {
        id: '3532',
        ort: 'Fridolfing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83413',
    },
    {
        id: '3533',
        ort: 'Friedberg',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '61169',
    },
    {
        id: '3534',
        ort: 'Friedberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86316',
    },
    {
        id: '3535',
        ort: 'Friedeburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26446',
    },
    {
        id: '3536',
        ort: 'Friedelshausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98634',
    },
    {
        id: '3537',
        ort: 'Friedelsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67159',
    },
    {
        id: '3538',
        ort: 'Friedenfels',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95688',
    },
    {
        id: '3539',
        ort: 'Friedenweiler',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79877',
    },
    {
        id: '3540',
        ort: 'Friedewald',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36289',
    },
    {
        id: '3541',
        ort: 'Friedewald',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57520',
    },
    {
        id: '3542',
        ort: 'Friedland',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15848',
    },
    {
        id: '3543',
        ort: 'Friedland',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37133',
    },
    {
        id: '3544',
        ort: 'Friedland',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17098',
    },
    {
        id: '3545',
        ort: 'Friedrichroda',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99894',
    },
    {
        id: '3546',
        ort: 'Friedrichsdorf',
        bundesland: 'Hessen',
        landkreis: 'Hochtaunuskreis',
        plz: '61273',
    },
    {
        id: '3547',
        ort: 'Friedrichsdorf',
        bundesland: 'Hessen',
        landkreis: 'Hochtaunuskreis',
        plz: '61381',
    },
    {
        id: '3548',
        ort: 'Friedrichsgabekoog',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25764',
    },
    {
        id: '3549',
        ort: 'Friedrichsgraben',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24799',
    },
    {
        id: '3550',
        ort: 'Friedrichshafen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88045',
    },
    {
        id: '3551',
        ort: 'Friedrichshafen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88046',
    },
    {
        id: '3552',
        ort: 'Friedrichshafen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88048',
    },
    {
        id: '3553',
        ort: 'Friedrichsholm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24799',
    },
    {
        id: '3554',
        ort: 'Friedrichskoog',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25718',
    },
    {
        id: '3555',
        ort: 'Friedrichsruhe',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19089',
    },
    {
        id: '3556',
        ort: 'Friedrichstadt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25840',
    },
    {
        id: '3557',
        ort: 'Friedrichsthal',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66299',
    },
    {
        id: '3558',
        ort: 'Friedrichswalde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16247',
    },
    {
        id: '3559',
        ort: 'Friedrich-Wilhelm-Lübke-Koog',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25924',
    },
    {
        id: '3560',
        ort: 'Frielendorf',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34621',
    },
    {
        id: '3561',
        ort: 'Friemar',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99869',
    },
    {
        id: '3562',
        ort: 'Friesack',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14662',
    },
    {
        id: '3563',
        ort: 'Friesenhagen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '51598',
    },
    {
        id: '3564',
        ort: 'Friesenheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77948',
    },
    {
        id: '3565',
        ort: 'Friesenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55278',
    },
    {
        id: '3566',
        ort: 'Friesenried',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87654',
    },
    {
        id: '3567',
        ort: 'Friesoythe',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cloppenburg',
        plz: '26169',
    },
    {
        id: '3568',
        ort: 'Friolzheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '71292',
    },
    {
        id: '3569',
        ort: 'Frittlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78665',
    },
    {
        id: '3570',
        ort: 'Fritzlar',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34560',
    },
    {
        id: '3571',
        ort: 'Frohburg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04654',
    },
    {
        id: '3572',
        ort: 'Fröhnd',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79677',
    },
    {
        id: '3573',
        ort: 'Frohnhofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66903',
    },
    {
        id: '3574',
        ort: 'Fröndenberg/Ruhr',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Unna',
        plz: '58730',
    },
    {
        id: '3575',
        ort: 'Fronhausen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35112',
    },
    {
        id: '3576',
        ort: 'Fronhofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55471',
    },
    {
        id: '3577',
        ort: 'Fronreute',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88273',
    },
    {
        id: '3578',
        ort: 'Frontenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dingolfing-Landau',
        plz: '84160',
    },
    {
        id: '3579',
        ort: 'Frücht',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56132',
    },
    {
        id: '3580',
        ort: 'Fuchshofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53533',
    },
    {
        id: '3581',
        ort: 'Fuchsmühl',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95689',
    },
    {
        id: '3582',
        ort: 'Fuchsstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97727',
    },
    {
        id: '3583',
        ort: 'Fuchstal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86925',
    },
    {
        id: '3584',
        ort: 'Fuhlendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18356',
    },
    {
        id: '3585',
        ort: 'Fuhlendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24649',
    },
    {
        id: '3586',
        ort: 'Fuhlenhagen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21493',
    },
    {
        id: '3587',
        ort: 'Fulda',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36037',
    },
    {
        id: '3588',
        ort: 'Fulda',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36039',
    },
    {
        id: '3589',
        ort: 'Fulda',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36041',
    },
    {
        id: '3590',
        ort: 'Fulda',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36043',
    },
    {
        id: '3591',
        ort: 'Fuldabrück',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34277',
    },
    {
        id: '3592',
        ort: 'Fuldatal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34127',
    },
    {
        id: '3593',
        ort: 'Fuldatal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34233',
    },
    {
        id: '3594',
        ort: 'Fünfseen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17213',
    },
    {
        id: '3595',
        ort: 'Fünfstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86681',
    },
    {
        id: '3596',
        ort: 'Fürfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55546',
    },
    {
        id: '3597',
        ort: 'Fürstenau',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49584',
    },
    {
        id: '3598',
        ort: 'Fürstenberg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37699',
    },
    {
        id: '3599',
        ort: 'Fürstenberg/Havel',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16798',
    },
    {
        id: '3600',
        ort: 'Fürsteneck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94142',
    },
    {
        id: '3601',
        ort: 'Fürstenfeldbruck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82256',
    },
    {
        id: '3602',
        ort: 'Fürstenstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94538',
    },
    {
        id: '3603',
        ort: 'Fürstenwalde/Spree',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15517',
    },
    {
        id: '3604',
        ort: 'Fürstenzell',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94081',
    },
    {
        id: '3605',
        ort: 'Furth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84095',
    },
    {
        id: '3606',
        ort: 'Fürth',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90762',
    },
    {
        id: '3607',
        ort: 'Fürth',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90763',
    },
    {
        id: '3608',
        ort: 'Fürth',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90765',
    },
    {
        id: '3609',
        ort: 'Fürth',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90766',
    },
    {
        id: '3610',
        ort: 'Fürth',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90768',
    },
    {
        id: '3611',
        ort: 'Fürth',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '64658',
    },
    {
        id: '3612',
        ort: 'Fürthen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57539',
    },
    {
        id: '3613',
        ort: 'Furth im Wald',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93437',
    },
    {
        id: '3614',
        ort: 'Furtwangen im Schwarzwald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78120',
    },
    {
        id: '3615',
        ort: 'Füssen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87629',
    },
    {
        id: '3616',
        ort: 'Fußgönheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67136',
    },
    {
        id: '3617',
        ort: 'Gablenz - Jabłońc',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02953',
    },
    {
        id: '3618',
        ort: 'Gablingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86456',
    },
    {
        id: '3619',
        ort: 'Gabsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55288',
    },
    {
        id: '3620',
        ort: 'Gachenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neuburg-Schrobenhausen',
        plz: '86565',
    },
    {
        id: '3621',
        ort: 'Gackenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '3622',
        ort: 'Gadebusch',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19205',
    },
    {
        id: '3623',
        ort: 'Gädheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '97503',
    },
    {
        id: '3624',
        ort: 'Gägelow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23968',
    },
    {
        id: '3625',
        ort: 'Gaggenau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '76571',
    },
    {
        id: '3626',
        ort: 'Gaiberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69251',
    },
    {
        id: '3627',
        ort: 'Gaienhofen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78343',
    },
    {
        id: '3628',
        ort: 'Gaildorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74405',
    },
    {
        id: '3629',
        ort: 'Gailingen am Hochrhein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78262',
    },
    {
        id: '3630',
        ort: 'Gaimersheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85080',
    },
    {
        id: '3631',
        ort: 'Gaißach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '83674',
    },
    {
        id: '3632',
        ort: 'Galenbeck',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17099',
    },
    {
        id: '3633',
        ort: 'Galenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '56651',
    },
    {
        id: '3634',
        ort: 'Gallin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19258',
    },
    {
        id: '3635',
        ort: 'Gallin-Kuppentin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19386',
    },
    {
        id: '3636',
        ort: 'Gallmersgarten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91605',
    },
    {
        id: '3637',
        ort: 'Galmsbüll',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25899',
    },
    {
        id: '3638',
        ort: 'Gamlen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56761',
    },
    {
        id: '3639',
        ort: 'Gammelby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24340',
    },
    {
        id: '3640',
        ort: 'Gammelin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '3641',
        ort: 'Gammelsdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85408',
    },
    {
        id: '3642',
        ort: 'Gammelshausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73108',
    },
    {
        id: '3643',
        ort: 'Gammertingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '72501',
    },
    {
        id: '3644',
        ort: 'Ganderkesee',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Oldenburg',
        plz: '27777',
    },
    {
        id: '3645',
        ort: 'Gandesbergen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '27324',
    },
    {
        id: '3646',
        ort: 'Gangelt',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Heinsberg',
        plz: '52538',
    },
    {
        id: '3647',
        ort: 'Gangkofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84140',
    },
    {
        id: '3648',
        ort: 'Gangloffsömmern',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99634',
    },
    {
        id: '3649',
        ort: 'Ganzlin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19395',
    },
    {
        id: '3650',
        ort: 'Gappenach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56294',
    },
    {
        id: '3651',
        ort: 'Garbsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30823',
    },
    {
        id: '3652',
        ort: 'Garbsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30826',
    },
    {
        id: '3653',
        ort: 'Garbsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30827',
    },
    {
        id: '3654',
        ort: 'Garching an der Alz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84518',
    },
    {
        id: '3655',
        ort: 'Garching bei München',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '85748',
    },
    {
        id: '3656',
        ort: 'Gardelegen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Altmarkkreis Salzwedel',
        plz: '39638',
    },
    {
        id: '3657',
        ort: 'Gardelegen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Altmarkkreis Salzwedel',
        plz: '39646',
    },
    {
        id: '3658',
        ort: 'Gardelegen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Altmarkkreis Salzwedel',
        plz: '39649',
    },
    {
        id: '3659',
        ort: 'Garding',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25836',
    },
    {
        id: '3660',
        ort: 'Garlstorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21376',
    },
    {
        id: '3661',
        ort: 'Garmisch-Partenkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82467',
    },
    {
        id: '3662',
        ort: 'Garmisch-Partenkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82475',
    },
    {
        id: '3663',
        ort: 'Garrel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cloppenburg',
        plz: '49681',
    },
    {
        id: '3664',
        ort: 'Gars am Inn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '83536',
    },
    {
        id: '3665',
        ort: 'Gars am Inn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '83546',
    },
    {
        id: '3666',
        ort: 'Gars am Inn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '83555',
    },
    {
        id: '3667',
        ort: 'Gars am Inn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '83559',
    },
    {
        id: '3668',
        ort: 'Garstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21441',
    },
    {
        id: '3669',
        ort: 'Gartow',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29471',
    },
    {
        id: '3670',
        ort: 'Gärtringen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71116',
    },
    {
        id: '3671',
        ort: 'Gartz (Oder)',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '16307',
    },
    {
        id: '3672',
        ort: 'Garz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17419',
    },
    {
        id: '3673',
        ort: 'Garzau-Garzin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15345',
    },
    {
        id: '3674',
        ort: 'Garz/Rügen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18574',
    },
    {
        id: '3675',
        ort: 'Gattendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95185',
    },
    {
        id: '3676',
        ort: 'Gau-Algesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55435',
    },
    {
        id: '3677',
        ort: 'Gau-Bickelheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55599',
    },
    {
        id: '3678',
        ort: 'Gau-Bischofsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55296',
    },
    {
        id: '3679',
        ort: 'Gauern',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07580',
    },
    {
        id: '3680',
        ort: 'Gauersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67294',
    },
    {
        id: '3681',
        ort: 'Gäufelden',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71126',
    },
    {
        id: '3682',
        ort: 'Gaugrehweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67822',
    },
    {
        id: '3683',
        ort: 'Gau-Heppenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '3684',
        ort: 'Gaukönigshofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97253',
    },
    {
        id: '3685',
        ort: 'Gau-Odernheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55239',
    },
    {
        id: '3686',
        ort: 'Gaushorn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25782',
    },
    {
        id: '3687',
        ort: 'Gauting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Starnberg',
        plz: '82131',
    },
    {
        id: '3688',
        ort: 'Gau-Weinheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55578',
    },
    {
        id: '3689',
        ort: 'Gebenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92274',
    },
    {
        id: '3690',
        ort: 'Gebesee',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99189',
    },
    {
        id: '3691',
        ort: 'Gebhardshain',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57580',
    },
    {
        id: '3692',
        ort: 'Gebroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '3693',
        ort: 'Gebsattel',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91607',
    },
    {
        id: '3694',
        ort: 'Gechingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '75391',
    },
    {
        id: '3695',
        ort: 'Gedern',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '63688',
    },
    {
        id: '3696',
        ort: 'Geeste',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49744',
    },
    {
        id: '3697',
        ort: 'Geesthacht',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21502',
    },
    {
        id: '3698',
        ort: 'Geestland',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '27607',
    },
    {
        id: '3699',
        ort: 'Geestland',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '27624',
    },
    {
        id: '3700',
        ort: 'Gefell',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '3701',
        ort: 'Gefell',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07926',
    },
    {
        id: '3702',
        ort: 'Gefrees',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95482',
    },
    {
        id: '3703',
        ort: 'Gehlert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57627',
    },
    {
        id: '3704',
        ort: 'Gehlsbach',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19386',
    },
    {
        id: '3705',
        ort: 'Gehlweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55490',
    },
    {
        id: '3706',
        ort: 'Gehofen',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '06571',
    },
    {
        id: '3707',
        ort: 'Gehrde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49596',
    },
    {
        id: '3708',
        ort: 'Gehrden',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30989',
    },
    {
        id: '3709',
        ort: 'Gehrweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67724',
    },
    {
        id: '3710',
        ort: 'Geichlingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '3711',
        ort: 'Geiersthal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94244',
    },
    {
        id: '3712',
        ort: 'Geilenkirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Heinsberg',
        plz: '52511',
    },
    {
        id: '3713',
        ort: 'Geilnau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56379',
    },
    {
        id: '3714',
        ort: 'Geisa',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '36419',
    },
    {
        id: '3715',
        ort: 'Geiselbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63826',
    },
    {
        id: '3716',
        ort: 'Geiselberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '67715',
    },
    {
        id: '3717',
        ort: 'Geiselhöring',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94333',
    },
    {
        id: '3718',
        ort: 'Geiselwind',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '96160',
    },
    {
        id: '3719',
        ort: 'Geisenfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85290',
    },
    {
        id: '3720',
        ort: 'Geisenhain',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '3721',
        ort: 'Geisenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84144',
    },
    {
        id: '3722',
        ort: 'Geisenheim',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65366',
    },
    {
        id: '3723',
        ort: 'Geisfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54413',
    },
    {
        id: '3724',
        ort: 'Geisig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '3725',
        ort: 'Geisingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78187',
    },
    {
        id: '3726',
        ort: 'Geisleden',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37308',
    },
    {
        id: '3727',
        ort: 'Geislingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72351',
    },
    {
        id: '3728',
        ort: 'Geislingen an der Steige',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73312',
    },
    {
        id: '3729',
        ort: 'Geismar',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37308',
    },
    {
        id: '3730',
        ort: 'Geithain',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04643',
    },
    {
        id: '3731',
        ort: 'Gelbensande',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18182',
    },
    {
        id: '3732',
        ort: 'Gelchsheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97255',
    },
    {
        id: '3733',
        ort: 'Geldern',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '47608',
    },
    {
        id: '3734',
        ort: 'Geldersheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97505',
    },
    {
        id: '3735',
        ort: 'Gelenau/Erzgebirge',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09423',
    },
    {
        id: '3736',
        ort: 'Gelenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '53539',
    },
    {
        id: '3737',
        ort: 'Gelnhausen',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63571',
    },
    {
        id: '3738',
        ort: 'Gelnhausen',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63589',
    },
    {
        id: '3739',
        ort: 'Gelsenkirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45879',
    },
    {
        id: '3740',
        ort: 'Gelsenkirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45881',
    },
    {
        id: '3741',
        ort: 'Gelsenkirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45883',
    },
    {
        id: '3742',
        ort: 'Gelsenkirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45884',
    },
    {
        id: '3743',
        ort: 'Gelsenkirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45886',
    },
    {
        id: '3744',
        ort: 'Gelsenkirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45888',
    },
    {
        id: '3745',
        ort: 'Gelsenkirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45889',
    },
    {
        id: '3746',
        ort: 'Gelsenkirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45891',
    },
    {
        id: '3747',
        ort: 'Gelsenkirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45892',
    },
    {
        id: '3748',
        ort: 'Gelsenkirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45894',
    },
    {
        id: '3749',
        ort: 'Gelsenkirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45896',
    },
    {
        id: '3750',
        ort: 'Gelsenkirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45897',
    },
    {
        id: '3751',
        ort: 'Gelsenkirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45899',
    },
    {
        id: '3752',
        ort: 'Geltendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '82269',
    },
    {
        id: '3753',
        ort: 'Gelting',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24395',
    },
    {
        id: '3754',
        ort: 'Geltorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24884',
    },
    {
        id: '3755',
        ort: 'Gemmerich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '3756',
        ort: 'Gemmingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '75050',
    },
    {
        id: '3757',
        ort: 'Gemmrigheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '74376',
    },
    {
        id: '3758',
        ort: 'Gemünd',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '3759',
        ort: 'Gemünden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '3760',
        ort: 'Gemünden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55490',
    },
    {
        id: '3761',
        ort: 'Gemünden am Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97737',
    },
    {
        id: '3762',
        ort: 'Gemünden (Felda)',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '35329',
    },
    {
        id: '3763',
        ort: 'Gemünden (Wohra)',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '35285',
    },
    {
        id: '3764',
        ort: 'Genderkingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86682',
    },
    {
        id: '3765',
        ort: 'Gengenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77723',
    },
    {
        id: '3766',
        ort: 'Gensingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55457',
    },
    {
        id: '3767',
        ort: 'Genthin',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Jerichower Land',
        plz: '39291',
    },
    {
        id: '3768',
        ort: 'Genthin',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Jerichower Land',
        plz: '39307',
    },
    {
        id: '3769',
        ort: 'Gentingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '3770',
        ort: 'Georgenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92697',
    },
    {
        id: '3771',
        ort: 'Georgensgmünd',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Roth',
        plz: '91166',
    },
    {
        id: '3772',
        ort: 'Georgenthal',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99887',
    },
    {
        id: '3773',
        ort: 'Georgsdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '49828',
    },
    {
        id: '3774',
        ort: 'Georgsmarienhütte',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49124',
    },
    {
        id: '3775',
        ort: 'Gera',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '07545',
    },
    {
        id: '3776',
        ort: 'Gera',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '07546',
    },
    {
        id: '3777',
        ort: 'Gera',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '07548',
    },
    {
        id: '3778',
        ort: 'Gera',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '07549',
    },
    {
        id: '3779',
        ort: 'Gera',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '07551',
    },
    {
        id: '3780',
        ort: 'Gera',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '07552',
    },
    {
        id: '3781',
        ort: 'Gera',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '07554',
    },
    {
        id: '3782',
        ort: 'Gera',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '07557',
    },
    {
        id: '3783',
        ort: 'Gerabronn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74582',
    },
    {
        id: '3784',
        ort: 'Gerach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96161',
    },
    {
        id: '3785',
        ort: 'Gerach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55743',
    },
    {
        id: '3786',
        ort: 'Geratal',
        bundesland: 'Thüringen',
        landkreis: 'Ilm-Kreis',
        plz: '99330',
    },
    {
        id: '3787',
        ort: 'Geratal',
        bundesland: 'Thüringen',
        landkreis: 'Ilm-Kreis',
        plz: '99331',
    },
    {
        id: '3788',
        ort: 'Geratskirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84552',
    },
    {
        id: '3789',
        ort: 'Gerbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67813',
    },
    {
        id: '3790',
        ort: 'Gerbershausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '3791',
        ort: 'Gerbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97218',
    },
    {
        id: '3792',
        ort: 'Gerbstedt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06347',
    },
    {
        id: '3793',
        ort: 'Gerdau',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29581',
    },
    {
        id: '3794',
        ort: 'Gerdshagen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '16928',
    },
    {
        id: '3795',
        ort: 'Geretsried',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '82538',
    },
    {
        id: '3796',
        ort: 'Gerhardsbrunn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66851',
    },
    {
        id: '3797',
        ort: 'Gerhardsbrunn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66894',
    },
    {
        id: '3798',
        ort: 'Gerhardshofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91466',
    },
    {
        id: '3799',
        ort: 'Gering',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56751',
    },
    {
        id: '3800',
        ort: 'Geringswalde',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09326',
    },
    {
        id: '3801',
        ort: 'Gerlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '70839',
    },
    {
        id: '3802',
        ort: 'Germaringen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87656',
    },
    {
        id: '3803',
        ort: 'Germering',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82110',
    },
    {
        id: '3804',
        ort: 'Germersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76726',
    },
    {
        id: '3805',
        ort: 'Gernrode',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37339',
    },
    {
        id: '3806',
        ort: 'Gernsbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '76593',
    },
    {
        id: '3807',
        ort: 'Gernsheim',
        bundesland: 'Hessen',
        landkreis: 'Kreis Groß-Gerau',
        plz: '64579',
    },
    {
        id: '3808',
        ort: 'Geroda',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97779',
    },
    {
        id: '3809',
        ort: 'Geroda',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07819',
    },
    {
        id: '3810',
        ort: 'Geroldsgrün',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95179',
    },
    {
        id: '3811',
        ort: 'Geroldshausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97256',
    },
    {
        id: '3812',
        ort: 'Gerolfingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91726',
    },
    {
        id: '3813',
        ort: 'Gerolsbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85302',
    },
    {
        id: '3814',
        ort: 'Gerolsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67229',
    },
    {
        id: '3815',
        ort: 'Gerolstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54568',
    },
    {
        id: '3816',
        ort: 'Gerolzhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97447',
    },
    {
        id: '3817',
        ort: 'Gersdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '09355',
    },
    {
        id: '3818',
        ort: 'Gersfeld (Rhön)',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36129',
    },
    {
        id: '3819',
        ort: 'Gersheim',
        bundesland: 'Saarland',
        landkreis: 'Saarpfalz-Kreis',
        plz: '66453',
    },
    {
        id: '3820',
        ort: 'Gersten',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49838',
    },
    {
        id: '3821',
        ort: 'Gerstenberg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04617',
    },
    {
        id: '3822',
        ort: 'Gerstengrund',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '36419',
    },
    {
        id: '3823',
        ort: 'Gerstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heidenheim',
        plz: '89547',
    },
    {
        id: '3824',
        ort: 'Gersthofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86368',
    },
    {
        id: '3825',
        ort: 'Gerstungen',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '99834',
    },
    {
        id: '3826',
        ort: 'Gerswalde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17268',
    },
    {
        id: '3827',
        ort: 'Gertewitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07389',
    },
    {
        id: '3828',
        ort: 'Gerzen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84175',
    },
    {
        id: '3829',
        ort: 'Geschendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23815',
    },
    {
        id: '3830',
        ort: 'Gescher',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '48712',
    },
    {
        id: '3831',
        ort: 'Gesees',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95494',
    },
    {
        id: '3832',
        ort: 'Geseke',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Soest',
        plz: '59590',
    },
    {
        id: '3833',
        ort: 'Geslau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91608',
    },
    {
        id: '3834',
        ort: 'Gessertshausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86459',
    },
    {
        id: '3835',
        ort: 'Gestratz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88167',
    },
    {
        id: '3836',
        ort: 'Getelo',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '49843',
    },
    {
        id: '3837',
        ort: 'Gettorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24214',
    },
    {
        id: '3838',
        ort: 'Gevelsberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Ennepe-Ruhr-Kreis',
        plz: '58285',
    },
    {
        id: '3839',
        ort: 'Gevenich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56825',
    },
    {
        id: '3840',
        ort: 'Gevensleben',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38384',
    },
    {
        id: '3841',
        ort: 'Geyer',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09468',
    },
    {
        id: '3842',
        ort: 'Giebelstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97232',
    },
    {
        id: '3843',
        ort: 'Gieboldehausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37434',
    },
    {
        id: '3844',
        ort: 'Giekau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24321',
    },
    {
        id: '3845',
        ort: 'Gieleroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57610',
    },
    {
        id: '3846',
        ort: 'Gielert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54424',
    },
    {
        id: '3847',
        ort: 'Gielow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17139',
    },
    {
        id: '3848',
        ort: 'Giengen an der Brenz',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heidenheim',
        plz: '89522',
    },
    {
        id: '3849',
        ort: 'Giengen an der Brenz',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heidenheim',
        plz: '89537',
    },
    {
        id: '3850',
        ort: 'Gierschnach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56294',
    },
    {
        id: '3851',
        ort: 'Giershausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57632',
    },
    {
        id: '3852',
        ort: 'Giersleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '06449',
    },
    {
        id: '3853',
        ort: 'Gierstädt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99100',
    },
    {
        id: '3854',
        ort: 'Giesdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54614',
    },
    {
        id: '3855',
        ort: 'Giesen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31180',
    },
    {
        id: '3856',
        ort: 'Giesenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57612',
    },
    {
        id: '3857',
        ort: 'Giesensdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23909',
    },
    {
        id: '3858',
        ort: 'Gießen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35390',
    },
    {
        id: '3859',
        ort: 'Gießen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35392',
    },
    {
        id: '3860',
        ort: 'Gießen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35394',
    },
    {
        id: '3861',
        ort: 'Gießen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35396',
    },
    {
        id: '3862',
        ort: 'Gießen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35398',
    },
    {
        id: '3863',
        ort: 'Gifhorn',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38518',
    },
    {
        id: '3864',
        ort: 'Gifhorn',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38559',
    },
    {
        id: '3865',
        ort: 'Gilching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Starnberg',
        plz: '82205',
    },
    {
        id: '3866',
        ort: 'Gillenbeuren',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56825',
    },
    {
        id: '3867',
        ort: 'Gillenfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54558',
    },
    {
        id: '3868',
        ort: 'Gilserberg',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34630',
    },
    {
        id: '3869',
        ort: 'Gilten',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29690',
    },
    {
        id: '3870',
        ort: 'Gilzem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54298',
    },
    {
        id: '3871',
        ort: 'Gimbsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67578',
    },
    {
        id: '3872',
        ort: 'Gimbweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '3873',
        ort: 'Gindorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54657',
    },
    {
        id: '3874',
        ort: 'Gingen an der Fils',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73333',
    },
    {
        id: '3875',
        ort: 'Gingst',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18569',
    },
    {
        id: '3876',
        ort: 'Ginsheim-Gustavsburg',
        bundesland: 'Hessen',
        landkreis: 'Kreis Groß-Gerau',
        plz: '65462',
    },
    {
        id: '3877',
        ort: 'Ginsweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67742',
    },
    {
        id: '3878',
        ort: 'Gipperath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54533',
    },
    {
        id: '3879',
        ort: 'Girkenroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '3880',
        ort: 'Girod',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '3881',
        ort: 'Gladbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '3882',
        ort: 'Gladbeck',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '45964',
    },
    {
        id: '3883',
        ort: 'Gladbeck',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '45966',
    },
    {
        id: '3884',
        ort: 'Gladbeck',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '45968',
    },
    {
        id: '3885',
        ort: 'Gladenbach',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35075',
    },
    {
        id: '3886',
        ort: 'Glanbrücken',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66887',
    },
    {
        id: '3887',
        ort: 'Glandorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49219',
    },
    {
        id: '3888',
        ort: 'Glan-Münchweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66907',
    },
    {
        id: '3889',
        ort: 'Glasau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23719',
    },
    {
        id: '3890',
        ort: 'Glasehausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37308',
    },
    {
        id: '3891',
        ort: 'Glasewitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18276',
    },
    {
        id: '3892',
        ort: 'Glashütte',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01768',
    },
    {
        id: '3893',
        ort: 'Glashütten',
        bundesland: 'Hessen',
        landkreis: 'Hochtaunuskreis',
        plz: '61479',
    },
    {
        id: '3894',
        ort: 'Glashütten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95496',
    },
    {
        id: '3895',
        ort: 'Glasin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23992',
    },
    {
        id: '3896',
        ort: 'Glasow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17322',
    },
    {
        id: '3897',
        ort: 'Glattbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63864',
    },
    {
        id: '3898',
        ort: 'Glatten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Freudenstadt',
        plz: '72293',
    },
    {
        id: '3899',
        ort: 'Glaubitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01612',
    },
    {
        id: '3900',
        ort: 'Glauburg',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '63695',
    },
    {
        id: '3901',
        ort: 'Glauchau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08371',
    },
    {
        id: '3902',
        ort: 'Glees',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '56653',
    },
    {
        id: '3903',
        ort: 'Gleichen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37130',
    },
    {
        id: '3904',
        ort: 'Gleina',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06632',
    },
    {
        id: '3905',
        ort: 'Gleiritsch',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92723',
    },
    {
        id: '3906',
        ort: 'Gleißenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93477',
    },
    {
        id: '3907',
        ort: 'Gleisweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76835',
    },
    {
        id: '3908',
        ort: 'Gleiszellen-Gleishorbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76889',
    },
    {
        id: '3909',
        ort: 'Glewitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18513',
    },
    {
        id: '3910',
        ort: 'Glienicke/Nordbahn',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16548',
    },
    {
        id: '3911',
        ort: 'Glinde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '21509',
    },
    {
        id: '3912',
        ort: 'Glonn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85625',
    },
    {
        id: '3913',
        ort: 'Glött',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89353',
    },
    {
        id: '3914',
        ort: 'Glottertal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79286',
    },
    {
        id: '3915',
        ort: 'Glowe',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18551',
    },
    {
        id: '3916',
        ort: 'Glücksburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24960',
    },
    {
        id: '3917',
        ort: 'Glückstadt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25348',
    },
    {
        id: '3918',
        ort: 'Glüsing',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25779',
    },
    {
        id: '3919',
        ort: 'Gmund',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miesbach',
        plz: '83703',
    },
    {
        id: '3920',
        ort: 'Gnarrenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27442',
    },
    {
        id: '3921',
        ort: 'Gneus',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '3922',
        ort: 'Gneven',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19065',
    },
    {
        id: '3923',
        ort: 'Gnevkow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17089',
    },
    {
        id: '3924',
        ort: 'Gnewitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18195',
    },
    {
        id: '3925',
        ort: 'Gnoien',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17179',
    },
    {
        id: '3926',
        ort: 'Gnotzheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91728',
    },
    {
        id: '3927',
        ort: 'Gnutz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24622',
    },
    {
        id: '3928',
        ort: 'Goch',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '47574',
    },
    {
        id: '3929',
        ort: 'Gochsheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97469',
    },
    {
        id: '3930',
        ort: 'Göcklingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76831',
    },
    {
        id: '3931',
        ort: 'Göda - Hodźij',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02633',
    },
    {
        id: '3932',
        ort: 'Goddert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '3933',
        ort: 'Godendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17237',
    },
    {
        id: '3934',
        ort: 'Gödenroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56290',
    },
    {
        id: '3935',
        ort: 'Gödenstorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21376',
    },
    {
        id: '3936',
        ort: 'Goethestadt Bad Lauchstädt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06246',
    },
    {
        id: '3937',
        ort: 'Göggingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73571',
    },
    {
        id: '3938',
        ort: 'Göhl',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23758',
    },
    {
        id: '3939',
        ort: 'Göhlen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19288',
    },
    {
        id: '3940',
        ort: 'Göhrde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29473',
    },
    {
        id: '3941',
        ort: 'Göhren',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18586',
    },
    {
        id: '3942',
        ort: 'Göhren',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04603',
    },
    {
        id: '3943',
        ort: 'Göhren-Lebbin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17213',
    },
    {
        id: '3944',
        ort: 'Gohrisch',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01824',
    },
    {
        id: '3945',
        ort: 'Gokels',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '25557',
    },
    {
        id: '3946',
        ort: 'Golchen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17089',
    },
    {
        id: '3947',
        ort: 'Goldbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63773',
    },
    {
        id: '3948',
        ort: 'Goldbeck',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39596',
    },
    {
        id: '3949',
        ort: 'Goldberg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19399',
    },
    {
        id: '3950',
        ort: 'Goldebek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25862',
    },
    {
        id: '3951',
        ort: 'Goldelund',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25862',
    },
    {
        id: '3952',
        ort: 'Göldenitz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23919',
    },
    {
        id: '3953',
        ort: 'Goldenstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Vechta',
        plz: '49424',
    },
    {
        id: '3954',
        ort: 'Goldisthal',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sonneberg',
        plz: '98746',
    },
    {
        id: '3955',
        ort: 'Goldkronach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95497',
    },
    {
        id: '3956',
        ort: 'Gölenkamp',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '49843',
    },
    {
        id: '3957',
        ort: 'Gollenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '3958',
        ort: 'Gollenberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14728',
    },
    {
        id: '3959',
        ort: 'Göllheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67307',
    },
    {
        id: '3960',
        ort: 'Gollhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '97258',
    },
    {
        id: '3961',
        ort: 'Göllnitz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04626',
    },
    {
        id: '3962',
        ort: 'Golmbach',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37640',
    },
    {
        id: '3963',
        ort: 'Golmsdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07751',
    },
    {
        id: '3964',
        ort: 'Golßen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15938',
    },
    {
        id: '3965',
        ort: 'Golzow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14778',
    },
    {
        id: '3966',
        ort: 'Golzow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15328',
    },
    {
        id: '3967',
        ort: 'Gomadingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72532',
    },
    {
        id: '3968',
        ort: 'Gomaringen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72810',
    },
    {
        id: '3969',
        ort: 'Gommern',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Jerichower Land',
        plz: '39175',
    },
    {
        id: '3970',
        ort: 'Gommern',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Jerichower Land',
        plz: '39245',
    },
    {
        id: '3971',
        ort: 'Gommern',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Jerichower Land',
        plz: '39264',
    },
    {
        id: '3972',
        ort: 'Gommern',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Jerichower Land',
        plz: '39279',
    },
    {
        id: '3973',
        ort: 'Gommern',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Jerichower Land',
        plz: '39291',
    },
    {
        id: '3974',
        ort: 'Gommersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '67377',
    },
    {
        id: '3975',
        ort: 'Gonbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67724',
    },
    {
        id: '3976',
        ort: 'Gondelsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '75053',
    },
    {
        id: '3977',
        ort: 'Gondenbrett',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54595',
    },
    {
        id: '3978',
        ort: 'Gondershausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56283',
    },
    {
        id: '3979',
        ort: 'Gondorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54647',
    },
    {
        id: '3980',
        ort: 'Gönnebek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24610',
    },
    {
        id: '3981',
        ort: 'Gönnersdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53498',
    },
    {
        id: '3982',
        ort: 'Gönnersdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54584',
    },
    {
        id: '3983',
        ort: 'Gönnheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67161',
    },
    {
        id: '3984',
        ort: 'Goosefeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24340',
    },
    {
        id: '3985',
        ort: 'Göpfersdorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04618',
    },
    {
        id: '3986',
        ort: 'Göppingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73033',
    },
    {
        id: '3987',
        ort: 'Göppingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73035',
    },
    {
        id: '3988',
        ort: 'Göppingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73037',
    },
    {
        id: '3989',
        ort: 'Göppingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73116',
    },
    {
        id: '3990',
        ort: 'Gorden-Staupitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '03238',
    },
    {
        id: '3991',
        ort: 'Görgeshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '3992',
        ort: 'Görisried',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87657',
    },
    {
        id: '3993',
        ort: 'Göritz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17291',
    },
    {
        id: '3994',
        ort: 'Görkwitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07907',
    },
    {
        id: '3995',
        ort: 'Gorleben',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29475',
    },
    {
        id: '3996',
        ort: 'Görlitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02826',
    },
    {
        id: '3997',
        ort: 'Görlitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02827',
    },
    {
        id: '3998',
        ort: 'Görlitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02828',
    },
    {
        id: '3999',
        ort: 'Gorlosen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19294',
    },
    {
        id: '4000',
        ort: 'Görmin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17121',
    },
    {
        id: '4001',
        ort: 'Gornau',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09405',
    },
    {
        id: '4002',
        ort: 'Gornau',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09437',
    },
    {
        id: '4003',
        ort: 'Gornau',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09573',
    },
    {
        id: '4004',
        ort: 'Gornhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54472',
    },
    {
        id: '4005',
        ort: 'Gornsdorf',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09390',
    },
    {
        id: '4006',
        ort: 'Görsbach',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Nordhausen',
        plz: '99765',
    },
    {
        id: '4007',
        ort: 'Görwihl',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79733',
    },
    {
        id: '4008',
        ort: 'Gorxheimertal',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '69517',
    },
    {
        id: '4009',
        ort: 'Görzke',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14828',
    },
    {
        id: '4010',
        ort: 'Göschitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07907',
    },
    {
        id: '4011',
        ort: 'Goseck',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06667',
    },
    {
        id: '4012',
        ort: 'Gösen',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07607',
    },
    {
        id: '4013',
        ort: 'Gosen-Neu Zittau',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15537',
    },
    {
        id: '4014',
        ort: 'Gösenroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55624',
    },
    {
        id: '4015',
        ort: 'Gosheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78559',
    },
    {
        id: '4016',
        ort: 'Goslar',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Goslar',
        plz: '38640',
    },
    {
        id: '4017',
        ort: 'Goslar',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Goslar',
        plz: '38642',
    },
    {
        id: '4018',
        ort: 'Goslar',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Goslar',
        plz: '38644',
    },
    {
        id: '4019',
        ort: 'Goslar',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Goslar',
        plz: '38690',
    },
    {
        id: '4020',
        ort: 'Gössenheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97780',
    },
    {
        id: '4021',
        ort: 'Gossersweiler-Stein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76857',
    },
    {
        id: '4022',
        ort: 'Gössitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07389',
    },
    {
        id: '4023',
        ort: 'Gößnitz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04639',
    },
    {
        id: '4024',
        ort: 'Gößweinstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91327',
    },
    {
        id: '4025',
        ort: 'Gotha',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99867',
    },
    {
        id: '4026',
        ort: 'Gottenheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79288',
    },
    {
        id: '4027',
        ort: 'Gottesgabe',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19209',
    },
    {
        id: '4028',
        ort: 'Gotteszell',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94239',
    },
    {
        id: '4029',
        ort: 'Gottfrieding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dingolfing-Landau',
        plz: '84177',
    },
    {
        id: '4030',
        ort: 'Gotthun',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17207',
    },
    {
        id: '4031',
        ort: 'Göttin',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21514',
    },
    {
        id: '4032',
        ort: 'Göttingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37073',
    },
    {
        id: '4033',
        ort: 'Göttingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37075',
    },
    {
        id: '4034',
        ort: 'Göttingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37077',
    },
    {
        id: '4035',
        ort: 'Göttingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37079',
    },
    {
        id: '4036',
        ort: 'Göttingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37081',
    },
    {
        id: '4037',
        ort: 'Göttingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37083',
    },
    {
        id: '4038',
        ort: 'Göttingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37085',
    },
    {
        id: '4039',
        ort: 'Gottmadingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78244',
    },
    {
        id: '4040',
        ort: 'Graach an der Mosel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54470',
    },
    {
        id: '4041',
        ort: 'Graal-Müritz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18181',
    },
    {
        id: '4042',
        ort: 'Grabau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23845',
    },
    {
        id: '4043',
        ort: 'Grabau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21493',
    },
    {
        id: '4044',
        ort: 'Graben',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86836',
    },
    {
        id: '4045',
        ort: 'Gräben',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14793',
    },
    {
        id: '4046',
        ort: 'Graben-Neudorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76676',
    },
    {
        id: '4047',
        ort: 'Grabenstätt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83355',
    },
    {
        id: '4048',
        ort: 'Grabenstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72582',
    },
    {
        id: '4049',
        ort: 'Grabfeld',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98631',
    },
    {
        id: '4050',
        ort: 'Grabow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19300',
    },
    {
        id: '4051',
        ort: 'Grabowhöfe',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17194',
    },
    {
        id: '4052',
        ort: 'Gräfelfing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '82166',
    },
    {
        id: '4053',
        ort: 'Grafenau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71120',
    },
    {
        id: '4054',
        ort: 'Grafenau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94481',
    },
    {
        id: '4055',
        ort: 'Grafenberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72661',
    },
    {
        id: '4056',
        ort: 'Gräfenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91322',
    },
    {
        id: '4057',
        ort: 'Gräfendhron',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54426',
    },
    {
        id: '4058',
        ort: 'Gräfendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97782',
    },
    {
        id: '4059',
        ort: 'Grafengehaig',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95356',
    },
    {
        id: '4060',
        ort: 'Gräfenhainichen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Wittenberg',
        plz: '06772',
    },
    {
        id: '4061',
        ort: 'Gräfenhainichen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Wittenberg',
        plz: '06773',
    },
    {
        id: '4062',
        ort: 'Grafenhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79865',
    },
    {
        id: '4063',
        ort: 'Grafenrheinfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97506',
    },
    {
        id: '4064',
        ort: 'Gräfenthal',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '98743',
    },
    {
        id: '4065',
        ort: 'Grafenwiesen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93479',
    },
    {
        id: '4066',
        ort: 'Grafenwöhr',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92655',
    },
    {
        id: '4067',
        ort: 'Grafhorst',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38462',
    },
    {
        id: '4068',
        ort: 'Grafing bei München',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85567',
    },
    {
        id: '4069',
        ort: 'Grafling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94539',
    },
    {
        id: '4070',
        ort: 'Grafrath',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82284',
    },
    {
        id: '4071',
        ort: 'Grafschaft',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53501',
    },
    {
        id: '4072',
        ort: 'Grainau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82491',
    },
    {
        id: '4073',
        ort: 'Grainet',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94143',
    },
    {
        id: '4074',
        ort: 'Graitschen bei Bürgel',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07616',
    },
    {
        id: '4075',
        ort: 'Grambek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23883',
    },
    {
        id: '4076',
        ort: 'Grambin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17375',
    },
    {
        id: '4077',
        ort: 'Grambow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17322',
    },
    {
        id: '4078',
        ort: 'Grambow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19071',
    },
    {
        id: '4079',
        ort: 'Grambow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19073',
    },
    {
        id: '4080',
        ort: 'Grammendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18513',
    },
    {
        id: '4081',
        ort: 'Grammentin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17153',
    },
    {
        id: '4082',
        ort: 'Grammetal',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99428',
    },
    {
        id: '4083',
        ort: 'Grammow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18195',
    },
    {
        id: '4084',
        ort: 'Gramzow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17291',
    },
    {
        id: '4085',
        ort: 'Grande',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22946',
    },
    {
        id: '4086',
        ort: 'Gransdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54533',
    },
    {
        id: '4087',
        ort: 'Gransebieth',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18513',
    },
    {
        id: '4088',
        ort: 'Gransee',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16775',
    },
    {
        id: '4089',
        ort: 'Granzin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19386',
    },
    {
        id: '4090',
        ort: 'Grapzow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17089',
    },
    {
        id: '4091',
        ort: 'Grasberg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osterholz',
        plz: '28879',
    },
    {
        id: '4092',
        ort: 'Grasbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '85630',
    },
    {
        id: '4093',
        ort: 'Grasellenbach',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '64689',
    },
    {
        id: '4094',
        ort: 'Grasleben',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38368',
    },
    {
        id: '4095',
        ort: 'Grassau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83224',
    },
    {
        id: '4096',
        ort: 'Grattersdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94541',
    },
    {
        id: '4097',
        ort: 'Grauel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24594',
    },
    {
        id: '4098',
        ort: 'Grävenwiesbach',
        bundesland: 'Hessen',
        landkreis: 'Hochtaunuskreis',
        plz: '61279',
    },
    {
        id: '4099',
        ort: 'Grebenau',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '36323',
    },
    {
        id: '4100',
        ort: 'Grebenhain',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '36355',
    },
    {
        id: '4101',
        ort: 'Grebenstein',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34393',
    },
    {
        id: '4102',
        ort: 'Grebin',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24329',
    },
    {
        id: '4103',
        ort: 'Grebs-Niendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19294',
    },
    {
        id: '4104',
        ort: 'Greding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Roth',
        plz: '91171',
    },
    {
        id: '4105',
        ort: 'Grefrath',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Viersen',
        plz: '47929',
    },
    {
        id: '4106',
        ort: 'Greifenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86926',
    },
    {
        id: '4107',
        ort: 'Greifenstein',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35753',
    },
    {
        id: '4108',
        ort: 'Greifswald',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17489',
    },
    {
        id: '4109',
        ort: 'Greifswald',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17491',
    },
    {
        id: '4110',
        ort: 'Greifswald',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17493',
    },
    {
        id: '4111',
        ort: 'Greiling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '83677',
    },
    {
        id: '4112',
        ort: 'Greimerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54314',
    },
    {
        id: '4113',
        ort: 'Greimerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54533',
    },
    {
        id: '4114',
        ort: 'Greimersburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56814',
    },
    {
        id: '4115',
        ort: 'Greiz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07973',
    },
    {
        id: '4116',
        ort: 'Gremersdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23758',
    },
    {
        id: '4117',
        ort: 'Gremersdorf-Buchholz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18461',
    },
    {
        id: '4118',
        ort: 'Gremsdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91350',
    },
    {
        id: '4119',
        ort: 'Grenderich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56858',
    },
    {
        id: '4120',
        ort: 'Grenzach-Wyhlen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79639',
    },
    {
        id: '4121',
        ort: 'Gresse',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19258',
    },
    {
        id: '4122',
        ort: 'Grethem',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29690',
    },
    {
        id: '4123',
        ort: 'Grettstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97508',
    },
    {
        id: '4124',
        ort: 'Greußen',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '99718',
    },
    {
        id: '4125',
        ort: 'Greußenheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97259',
    },
    {
        id: '4126',
        ort: 'Greven',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '48268',
    },
    {
        id: '4127',
        ort: 'Greven',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19258',
    },
    {
        id: '4128',
        ort: 'Grevenbroich',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41515',
    },
    {
        id: '4129',
        ort: 'Grevenbroich',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41516',
    },
    {
        id: '4130',
        ort: 'Grevenbroich',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41517',
    },
    {
        id: '4131',
        ort: 'Grevenkop',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25361',
    },
    {
        id: '4132',
        ort: 'Grevenkrug',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24241',
    },
    {
        id: '4133',
        ort: 'Grevesmühlen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23936',
    },
    {
        id: '4134',
        ort: 'Gribbohm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25596',
    },
    {
        id: '4135',
        ort: 'Gribow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17506',
    },
    {
        id: '4136',
        ort: 'Griebelschied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55608',
    },
    {
        id: '4137',
        ort: 'Grieben',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23936',
    },
    {
        id: '4138',
        ort: 'Griefstedt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99638',
    },
    {
        id: '4139',
        ort: 'Gries',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66903',
    },
    {
        id: '4140',
        ort: 'Griesheim',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64347',
    },
    {
        id: '4141',
        ort: 'Griesingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89608',
    },
    {
        id: '4142',
        ort: 'Griesstätt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83556',
    },
    {
        id: '4143',
        ort: 'Grimburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54413',
    },
    {
        id: '4144',
        ort: 'Grimma',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04668',
    },
    {
        id: '4145',
        ort: 'Grimmelshausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98660',
    },
    {
        id: '4146',
        ort: 'Grimmen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18507',
    },
    {
        id: '4147',
        ort: 'Grinau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23847',
    },
    {
        id: '4148',
        ort: 'Grischow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17089',
    },
    {
        id: '4149',
        ort: 'Grobengereuth',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07389',
    },
    {
        id: '4150',
        ort: 'Gröbenzell',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82194',
    },
    {
        id: '4151',
        ort: 'Gröde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25869',
    },
    {
        id: '4152',
        ort: 'Gröden',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04932',
    },
    {
        id: '4153',
        ort: 'Grödersby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24376',
    },
    {
        id: '4154',
        ort: 'Grödersby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24399',
    },
    {
        id: '4155',
        ort: 'Gröditz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01609',
    },
    {
        id: '4156',
        ort: 'Groitzsch',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04539',
    },
    {
        id: '4157',
        ort: 'Grolsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55459',
    },
    {
        id: '4158',
        ort: 'Grömbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Freudenstadt',
        plz: '72294',
    },
    {
        id: '4159',
        ort: 'Grömbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Freudenstadt',
        plz: '72297',
    },
    {
        id: '4160',
        ort: 'Grömitz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23743',
    },
    {
        id: '4161',
        ort: 'Gronau',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '48599',
    },
    {
        id: '4162',
        ort: 'Gronau',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31028',
    },
    {
        id: '4163',
        ort: 'Gröningen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39397',
    },
    {
        id: '4164',
        ort: 'Grönwohld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22956',
    },
    {
        id: '4165',
        ort: 'Großaitingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86845',
    },
    {
        id: '4166',
        ort: 'Großalmerode',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37247',
    },
    {
        id: '4167',
        ort: 'Großbardorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97633',
    },
    {
        id: '4168',
        ort: 'Großbarkau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24245',
    },
    {
        id: '4169',
        ort: 'Großbartloff',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37359',
    },
    {
        id: '4170',
        ort: 'Großbeeren',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Teltow-Fläming',
        plz: '14979',
    },
    {
        id: '4171',
        ort: 'Groß Berßen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49777',
    },
    {
        id: '4172',
        ort: 'Großbettlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '72663',
    },
    {
        id: '4173',
        ort: 'Groß-Bieberau',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64395',
    },
    {
        id: '4174',
        ort: 'Groß-Bieberau',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64401',
    },
    {
        id: '4175',
        ort: 'Großbockedra',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '4176',
        ort: 'Groß Boden',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23847',
    },
    {
        id: '4177',
        ort: 'Großbottwar',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71723',
    },
    {
        id: '4178',
        ort: 'Großbreitenbach',
        bundesland: 'Thüringen',
        landkreis: 'Ilm-Kreis',
        plz: '98701',
    },
    {
        id: '4179',
        ort: 'Groß Buchwald',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24582',
    },
    {
        id: '4180',
        ort: 'Großbundenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66501',
    },
    {
        id: '4181',
        ort: 'Großderschau',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '16845',
    },
    {
        id: '4182',
        ort: 'Groß Disnack',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23911',
    },
    {
        id: '4183',
        ort: 'Groß Düben - Dźěwin',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02953',
    },
    {
        id: '4184',
        ort: 'Groß Düben - Dźěwin',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02959',
    },
    {
        id: '4185',
        ort: 'Großdubrau - Wulka Dubrawa',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02694',
    },
    {
        id: '4186',
        ort: 'Großefehn',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26629',
    },
    {
        id: '4187',
        ort: 'Großeibstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97633',
    },
    {
        id: '4188',
        ort: 'Grosselfingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72415',
    },
    {
        id: '4189',
        ort: 'Großenaspe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24623',
    },
    {
        id: '4190',
        ort: 'Großenbrode',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23775',
    },
    {
        id: '4191',
        ort: 'Großenhain',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01558',
    },
    {
        id: '4192',
        ort: 'Großenhain',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01561',
    },
    {
        id: '4193',
        ort: 'Großenkneten',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Oldenburg',
        plz: '26197',
    },
    {
        id: '4194',
        ort: 'Großenlüder',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36137',
    },
    {
        id: '4195',
        ort: 'Großenrade',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25712',
    },
    {
        id: '4196',
        ort: 'Großensee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22946',
    },
    {
        id: '4197',
        ort: 'Großenseebach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91091',
    },
    {
        id: '4198',
        ort: 'Großenstein',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07580',
    },
    {
        id: '4199',
        ort: 'Großenwiehe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24969',
    },
    {
        id: '4200',
        ort: 'Großenwörden',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21712',
    },
    {
        id: '4201',
        ort: 'Großerlach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71577',
    },
    {
        id: '4202',
        ort: 'Großeutersdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07768',
    },
    {
        id: '4203',
        ort: 'Großfahner',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99100',
    },
    {
        id: '4204',
        ort: 'Großfischlingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '67483',
    },
    {
        id: '4205',
        ort: 'Groß-Gerau',
        bundesland: 'Hessen',
        landkreis: 'Kreis Groß-Gerau',
        plz: '64521',
    },
    {
        id: '4206',
        ort: 'Groß Godems',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19372',
    },
    {
        id: '4207',
        ort: 'Groß Grönau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23627',
    },
    {
        id: '4208',
        ort: 'Großhabersdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürth',
        plz: '90613',
    },
    {
        id: '4209',
        ort: 'Großhansdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22927',
    },
    {
        id: '4210',
        ort: 'Großharrie',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24625',
    },
    {
        id: '4211',
        ort: 'Großharthau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01909',
    },
    {
        id: '4212',
        ort: 'Großhartmannsdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09618',
    },
    {
        id: '4213',
        ort: 'Großheide',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26532',
    },
    {
        id: '4214',
        ort: 'Großheirath',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Coburg',
        plz: '96269',
    },
    {
        id: '4215',
        ort: 'Großheringen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99518',
    },
    {
        id: '4216',
        ort: 'Großheubach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63920',
    },
    {
        id: '4217',
        ort: 'Großholbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '4218',
        ort: 'Groß Ippener',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Oldenburg',
        plz: '27243',
    },
    {
        id: '4219',
        ort: 'Großkampenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54619',
    },
    {
        id: '4220',
        ort: 'Großkarlbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67229',
    },
    {
        id: '4221',
        ort: 'Großkarolinenfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83109',
    },
    {
        id: '4222',
        ort: 'Groß Kelle',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17207',
    },
    {
        id: '4223',
        ort: 'Groß Kiesow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17495',
    },
    {
        id: '4224',
        ort: 'Großkmehlen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01990',
    },
    {
        id: '4225',
        ort: 'Groß Kordshagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18442',
    },
    {
        id: '4226',
        ort: 'Groß Köris',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15746',
    },
    {
        id: '4227',
        ort: 'Groß Krams',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '4228',
        ort: 'Groß Kreutz (Havel)',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14550',
    },
    {
        id: '4229',
        ort: 'Großkrotzenburg',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63538',
    },
    {
        id: '4230',
        ort: 'Groß Kummerfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24626',
    },
    {
        id: '4231',
        ort: 'Groß Laasch',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19288',
    },
    {
        id: '4232',
        ort: 'Großlangenfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54608',
    },
    {
        id: '4233',
        ort: 'Großlangheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97320',
    },
    {
        id: '4234',
        ort: 'Groß Lindow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15295',
    },
    {
        id: '4235',
        ort: 'Großlittgen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54534',
    },
    {
        id: '4236',
        ort: 'Großlöbichau',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07751',
    },
    {
        id: '4237',
        ort: 'Großlohra',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Nordhausen',
        plz: '99759',
    },
    {
        id: '4238',
        ort: 'Groß Luckow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17337',
    },
    {
        id: '4239',
        ort: 'Großmaischeid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56276',
    },
    {
        id: '4240',
        ort: 'Groß Meckelsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27419',
    },
    {
        id: '4241',
        ort: 'Großmehring',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85098',
    },
    {
        id: '4242',
        ort: 'Groß Miltzow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17349',
    },
    {
        id: '4243',
        ort: 'Groß Mohrdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18445',
    },
    {
        id: '4244',
        ort: 'Großmölsen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99198',
    },
    {
        id: '4245',
        ort: 'Groß Molzahn',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19217',
    },
    {
        id: '4246',
        ort: 'Großnaundorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01936',
    },
    {
        id: '4247',
        ort: 'Groß Nemerow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17094',
    },
    {
        id: '4248',
        ort: 'Großneuhausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99625',
    },
    {
        id: '4249',
        ort: 'Großniedesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67259',
    },
    {
        id: '4250',
        ort: 'Groß Niendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23816',
    },
    {
        id: '4251',
        ort: 'Groß Nordende',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25436',
    },
    {
        id: '4252',
        ort: 'Groß Oesingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '29393',
    },
    {
        id: '4253',
        ort: 'Groß Offenseth-Aspern',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25355',
    },
    {
        id: '4254',
        ort: 'Großolbersdorf',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09432',
    },
    {
        id: '4255',
        ort: 'Großostheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63762',
    },
    {
        id: '4256',
        ort: 'Groß Pampau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21493',
    },
    {
        id: '4257',
        ort: 'Groß Pankow (Prignitz)',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '16928',
    },
    {
        id: '4258',
        ort: 'Groß Plasten',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17192',
    },
    {
        id: '4259',
        ort: 'Groß Polzin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17390',
    },
    {
        id: '4260',
        ort: 'Großpösna',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04463',
    },
    {
        id: '4261',
        ort: 'Großpostwitz/O.L. - Budestecy',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02692',
    },
    {
        id: '4262',
        ort: 'Großpürschütz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07751',
    },
    {
        id: '4263',
        ort: 'Groß Quenstedt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38822',
    },
    {
        id: '4264',
        ort: 'Großräschen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01983',
    },
    {
        id: '4265',
        ort: 'Groß Rheide',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24872',
    },
    {
        id: '4266',
        ort: 'Großrinderfeld',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Main-Tauber-Kreis',
        plz: '97950',
    },
    {
        id: '4267',
        ort: 'Groß Roge',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17166',
    },
    {
        id: '4268',
        ort: 'Groß-Rohrheim',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '68649',
    },
    {
        id: '4269',
        ort: 'Großröhrsdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01900',
    },
    {
        id: '4270',
        ort: 'Groß Rönnau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23795',
    },
    {
        id: '4271',
        ort: 'Großrosseln',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66352',
    },
    {
        id: '4272',
        ort: 'Großrückerswalde',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09518',
    },
    {
        id: '4273',
        ort: 'Großrudestedt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99195',
    },
    {
        id: '4274',
        ort: 'Groß Sarau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23627',
    },
    {
        id: '4275',
        ort: 'Groß Schacksdorf-Simmersdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03149',
    },
    {
        id: '4276',
        ort: 'Groß Schenkenberg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23860',
    },
    {
        id: '4277',
        ort: 'Großschirma',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09603',
    },
    {
        id: '4278',
        ort: 'Großschönau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02779',
    },
    {
        id: '4279',
        ort: 'Großschönau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02799',
    },
    {
        id: '4280',
        ort: 'Großschwabhausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99441',
    },
    {
        id: '4281',
        ort: 'Großschweidnitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02708',
    },
    {
        id: '4282',
        ort: 'Groß Schwiesow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18276',
    },
    {
        id: '4283',
        ort: 'Großseifen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56472',
    },
    {
        id: '4284',
        ort: 'Großsolt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24991',
    },
    {
        id: '4285',
        ort: 'Großsteinhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66484',
    },
    {
        id: '4286',
        ort: 'Groß Stieten',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23972',
    },
    {
        id: '4287',
        ort: 'Groß Teetzleben',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17091',
    },
    {
        id: '4288',
        ort: 'Großthiemig',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04932',
    },
    {
        id: '4289',
        ort: 'Groß Twülpstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38464',
    },
    {
        id: '4290',
        ort: 'Groß-Umstadt',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64823',
    },
    {
        id: '4291',
        ort: 'Großvargula',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99958',
    },
    {
        id: '4292',
        ort: 'Groß Vollstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24802',
    },
    {
        id: '4293',
        ort: 'Großwallstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63868',
    },
    {
        id: '4294',
        ort: 'Großweil',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82439',
    },
    {
        id: '4295',
        ort: 'Großweitzschen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '04720',
    },
    {
        id: '4296',
        ort: 'Groß Wittensee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24361',
    },
    {
        id: '4297',
        ort: 'Groß Wokern',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17166',
    },
    {
        id: '4298',
        ort: 'Großwoltersdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16775',
    },
    {
        id: '4299',
        ort: 'Groß Wüstenfelde',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17168',
    },
    {
        id: '4300',
        ort: 'Groß-Zimmern',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64846',
    },
    {
        id: '4301',
        ort: 'Grothusenkoog',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25836',
    },
    {
        id: '4302',
        ort: 'Grove',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21493',
    },
    {
        id: '4303',
        ort: 'Groven',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25774',
    },
    {
        id: '4304',
        ort: 'Grub',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98530',
    },
    {
        id: '4305',
        ort: 'Grub a.Forst',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Coburg',
        plz: '96271',
    },
    {
        id: '4306',
        ort: 'Grube',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23749',
    },
    {
        id: '4307',
        ort: 'Gruibingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73344',
    },
    {
        id: '4308',
        ort: 'Grumbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67745',
    },
    {
        id: '4309',
        ort: 'Grünbach',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08223',
    },
    {
        id: '4310',
        ort: 'Grünberg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35305',
    },
    {
        id: '4311',
        ort: 'Gründau',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63584',
    },
    {
        id: '4312',
        ort: 'Grundhof',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24977',
    },
    {
        id: '4313',
        ort: 'Grundsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89613',
    },
    {
        id: '4314',
        ort: 'Grünebach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57520',
    },
    {
        id: '4315',
        ort: 'Grünenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88167',
    },
    {
        id: '4316',
        ort: 'Grünendeich',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21720',
    },
    {
        id: '4317',
        ort: 'Grünewald',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01945',
    },
    {
        id: '4318',
        ort: 'Grünhain-Beierfeld',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '08344',
    },
    {
        id: '4319',
        ort: 'Grünhainichen',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09579',
    },
    {
        id: '4320',
        ort: 'Grünheide (Mark)',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15537',
    },
    {
        id: '4321',
        ort: 'Grünkraut',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88287',
    },
    {
        id: '4322',
        ort: 'Grünow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17291',
    },
    {
        id: '4323',
        ort: 'Grünow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17237',
    },
    {
        id: '4324',
        ort: 'Grunow-Dammendorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15299',
    },
    {
        id: '4325',
        ort: 'Grünsfeld',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Main-Tauber-Kreis',
        plz: '97947',
    },
    {
        id: '4326',
        ort: 'Grünstadt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67269',
    },
    {
        id: '4327',
        ort: 'Grünwald',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '82031',
    },
    {
        id: '4328',
        ort: 'Gschwend',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '74417',
    },
    {
        id: '4329',
        ort: 'Gstadt am Chiemsee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83257',
    },
    {
        id: '4330',
        ort: 'Guben',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03172',
    },
    {
        id: '4331',
        ort: 'Güby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24357',
    },
    {
        id: '4332',
        ort: 'Guckheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '4333',
        ort: 'Gückingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65558',
    },
    {
        id: '4334',
        ort: 'Gudendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25693',
    },
    {
        id: '4335',
        ort: 'Gudensberg',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34281',
    },
    {
        id: '4336',
        ort: 'Guderhandviertel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21720',
    },
    {
        id: '4337',
        ort: 'Gudow',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23899',
    },
    {
        id: '4338',
        ort: 'Guggenhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88379',
    },
    {
        id: '4339',
        ort: 'Güglingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74363',
    },
    {
        id: '4340',
        ort: 'Guhrow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03096',
    },
    {
        id: '4341',
        ort: 'Guldental',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55452',
    },
    {
        id: '4342',
        ort: 'Gülitz-Reetz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '19348',
    },
    {
        id: '4343',
        ort: 'Güllesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '56593',
    },
    {
        id: '4344',
        ort: 'Gültz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17089',
    },
    {
        id: '4345',
        ort: 'Gülzow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17153',
    },
    {
        id: '4346',
        ort: 'Gülzow',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21483',
    },
    {
        id: '4347',
        ort: 'Gülzow-Prüzen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18276',
    },
    {
        id: '4348',
        ort: 'Gumbsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55597',
    },
    {
        id: '4349',
        ort: 'Gummersbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Oberbergischer Kreis',
        plz: '51643',
    },
    {
        id: '4350',
        ort: 'Gummersbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Oberbergischer Kreis',
        plz: '51645',
    },
    {
        id: '4351',
        ort: 'Gummersbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Oberbergischer Kreis',
        plz: '51647',
    },
    {
        id: '4352',
        ort: 'Gumperda',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07768',
    },
    {
        id: '4353',
        ort: 'Gumtow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '16866',
    },
    {
        id: '4354',
        ort: 'Gundelfingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79194',
    },
    {
        id: '4355',
        ort: 'Gundelfingen a.d.Donau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89423',
    },
    {
        id: '4356',
        ort: 'Gundelsheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96163',
    },
    {
        id: '4357',
        ort: 'Gundelsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74831',
    },
    {
        id: '4358',
        ort: 'Gunderath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '56767',
    },
    {
        id: '4359',
        ort: 'Gundersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67598',
    },
    {
        id: '4360',
        ort: 'Gundersweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67724',
    },
    {
        id: '4361',
        ort: 'Gundheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67599',
    },
    {
        id: '4362',
        ort: 'Gundremmingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89355',
    },
    {
        id: '4363',
        ort: 'Gunningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78594',
    },
    {
        id: '4364',
        ort: 'Günstedt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99631',
    },
    {
        id: '4365',
        ort: 'Guntersblum',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '67583',
    },
    {
        id: '4366',
        ort: 'Güntersleben',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97261',
    },
    {
        id: '4367',
        ort: 'Günzach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87634',
    },
    {
        id: '4368',
        ort: 'Günzburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89312',
    },
    {
        id: '4369',
        ort: 'Gunzenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91710',
    },
    {
        id: '4370',
        ort: 'Gusborn',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29476',
    },
    {
        id: '4371',
        ort: 'Gusenburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54413',
    },
    {
        id: '4372',
        ort: 'Gusow-Platkow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15306',
    },
    {
        id: '4373',
        ort: 'Güsten',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '39439',
    },
    {
        id: '4374',
        ort: 'Güster',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21514',
    },
    {
        id: '4375',
        ort: 'Gusterath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54317',
    },
    {
        id: '4376',
        ort: 'Gustow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18574',
    },
    {
        id: '4377',
        ort: 'Güstrow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18273',
    },
    {
        id: '4378',
        ort: 'Gutach im Breisgau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79261',
    },
    {
        id: '4379',
        ort: 'Gutach (Schwarzwaldbahn)',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77793',
    },
    {
        id: '4380',
        ort: 'Guteborn',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01945',
    },
    {
        id: '4381',
        ort: 'Gutenacker',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56370',
    },
    {
        id: '4382',
        ort: 'Gütenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78148',
    },
    {
        id: '4383',
        ort: 'Gutenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '4384',
        ort: 'Gutenborn',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06712',
    },
    {
        id: '4385',
        ort: 'Guteneck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92543',
    },
    {
        id: '4386',
        ort: 'Gutenstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91468',
    },
    {
        id: '4387',
        ort: 'Gutenzell-Hürbel',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88484',
    },
    {
        id: '4388',
        ort: 'Gütersloh',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Gütersloh',
        plz: '33330',
    },
    {
        id: '4389',
        ort: 'Gütersloh',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Gütersloh',
        plz: '33332',
    },
    {
        id: '4390',
        ort: 'Gütersloh',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Gütersloh',
        plz: '33333',
    },
    {
        id: '4391',
        ort: 'Gütersloh',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Gütersloh',
        plz: '33334',
    },
    {
        id: '4392',
        ort: 'Gütersloh',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Gütersloh',
        plz: '33335',
    },
    {
        id: '4393',
        ort: 'Gutow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18276',
    },
    {
        id: '4394',
        ort: 'Guttenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95358',
    },
    {
        id: '4395',
        ort: 'Gutweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54317',
    },
    {
        id: '4396',
        ort: 'Gützkow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17506',
    },
    {
        id: '4397',
        ort: 'Guxhagen',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34302',
    },
    {
        id: '4398',
        ort: 'Gyhum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27404',
    },
    {
        id: '4399',
        ort: 'Haag',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95473',
    },
    {
        id: '4400',
        ort: 'Haag an der Amper',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85410',
    },
    {
        id: '4401',
        ort: 'Haag in Oberbayern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '83527',
    },
    {
        id: '4402',
        ort: 'Haale',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24819',
    },
    {
        id: '4403',
        ort: 'Haan',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '42781',
    },
    {
        id: '4404',
        ort: 'Haar',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '85540',
    },
    {
        id: '4405',
        ort: 'Haarbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94501',
    },
    {
        id: '4406',
        ort: 'Haarbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94542',
    },
    {
        id: '4407',
        ort: 'Habach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82392',
    },
    {
        id: '4408',
        ort: 'Habichtswald',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34317',
    },
    {
        id: '4409',
        ort: 'Habscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '4410',
        ort: 'Haby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24361',
    },
    {
        id: '4411',
        ort: 'Hachenburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57627',
    },
    {
        id: '4412',
        ort: 'Hackenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55546',
    },
    {
        id: '4413',
        ort: 'Hadamar',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65589',
    },
    {
        id: '4414',
        ort: 'Hademstorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29693',
    },
    {
        id: '4415',
        ort: 'Hadenfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25560',
    },
    {
        id: '4416',
        ort: 'Hafenlohr',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97840',
    },
    {
        id: '4417',
        ort: 'Hage',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26524',
    },
    {
        id: '4418',
        ort: 'Häg-Ehrsberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79685',
    },
    {
        id: '4419',
        ort: 'Hagelstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93095',
    },
    {
        id: '4420',
        ort: 'Hagen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24576',
    },
    {
        id: '4421',
        ort: 'Hagen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '58089',
    },
    {
        id: '4422',
        ort: 'Hagen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '58091',
    },
    {
        id: '4423',
        ort: 'Hagen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '58093',
    },
    {
        id: '4424',
        ort: 'Hagen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '58095',
    },
    {
        id: '4425',
        ort: 'Hagen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '58097',
    },
    {
        id: '4426',
        ort: 'Hagen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '58099',
    },
    {
        id: '4427',
        ort: 'Hagen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '58119',
    },
    {
        id: '4428',
        ort: 'Hagen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '58135',
    },
    {
        id: '4429',
        ort: 'Hagen am Teutoburger Wald',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49170',
    },
    {
        id: '4430',
        ort: 'Hagenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76767',
    },
    {
        id: '4431',
        ort: 'Hagenbüchach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91469',
    },
    {
        id: '4432',
        ort: 'Hagenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31558',
    },
    {
        id: '4433',
        ort: 'Hagen im Bremischen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '27628',
    },
    {
        id: '4434',
        ort: 'Hagenow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '4435',
        ort: 'Hagermarsch',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26524',
    },
    {
        id: '4436',
        ort: 'Hagnau am Bodensee',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88709',
    },
    {
        id: '4437',
        ort: 'Hahn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56850',
    },
    {
        id: '4438',
        ort: 'Hahn am See',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '4439',
        ort: 'Hahnbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92256',
    },
    {
        id: '4440',
        ort: 'Hahn bei Marienberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56472',
    },
    {
        id: '4441',
        ort: 'Hahnenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55606',
    },
    {
        id: '4442',
        ort: 'Hahnheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55278',
    },
    {
        id: '4443',
        ort: 'Hähnichen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02923',
    },
    {
        id: '4444',
        ort: 'Hahnstätten',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65623',
    },
    {
        id: '4445',
        ort: 'Hahnweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55776',
    },
    {
        id: '4446',
        ort: 'Haibach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63808',
    },
    {
        id: '4447',
        ort: 'Haibach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94353',
    },
    {
        id: '4448',
        ort: 'Haidmühle',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94145',
    },
    {
        id: '4449',
        ort: 'Haiger',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35708',
    },
    {
        id: '4450',
        ort: 'Haigerloch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72401',
    },
    {
        id: '4451',
        ort: 'Haimhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '85778',
    },
    {
        id: '4452',
        ort: 'Haiming',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84533',
    },
    {
        id: '4453',
        ort: 'Haina (Kloster)',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '35114',
    },
    {
        id: '4454',
        ort: 'Hainau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '4455',
        ort: 'Hainburg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Offenbach',
        plz: '63512',
    },
    {
        id: '4456',
        ort: 'Hainewalde',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02779',
    },
    {
        id: '4457',
        ort: 'Hainfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76835',
    },
    {
        id: '4458',
        ort: 'Hainichen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09661',
    },
    {
        id: '4459',
        ort: 'Hainichen',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07778',
    },
    {
        id: '4460',
        ort: 'Hainsfarth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86744',
    },
    {
        id: '4461',
        ort: 'Hainspitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07607',
    },
    {
        id: '4462',
        ort: 'Haiterbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '72221',
    },
    {
        id: '4463',
        ort: 'Halbe',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15757',
    },
    {
        id: '4464',
        ort: 'Halbemond',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26524',
    },
    {
        id: '4465',
        ort: 'Halberstadt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38820',
    },
    {
        id: '4466',
        ort: 'Halberstadt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38822',
    },
    {
        id: '4467',
        ort: 'Halberstadt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38895',
    },
    {
        id: '4468',
        ort: 'Halblech',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87642',
    },
    {
        id: '4469',
        ort: 'Halbs',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56457',
    },
    {
        id: '4470',
        ort: 'Haldensleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39340',
    },
    {
        id: '4471',
        ort: 'Haldensleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39343',
    },
    {
        id: '4472',
        ort: 'Haldensleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39345',
    },
    {
        id: '4473',
        ort: 'Haldenwang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87490',
    },
    {
        id: '4474',
        ort: 'Haldenwang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89356',
    },
    {
        id: '4475',
        ort: 'Halenbeck-Rohlsdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '16945',
    },
    {
        id: '4476',
        ort: 'Halfing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83128',
    },
    {
        id: '4477',
        ort: 'Hallbergmoos',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85399',
    },
    {
        id: '4478',
        ort: 'Halle',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37620',
    },
    {
        id: '4479',
        ort: 'Halle',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '49843',
    },
    {
        id: '4480',
        ort: 'Hallenberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Hochsauerlandkreis',
        plz: '59969',
    },
    {
        id: '4481',
        ort: 'Hallerndorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91352',
    },
    {
        id: '4482',
        ort: 'Halle (Saale)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06108',
    },
    {
        id: '4483',
        ort: 'Halle (Saale)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06110',
    },
    {
        id: '4484',
        ort: 'Halle (Saale)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06112',
    },
    {
        id: '4485',
        ort: 'Halle (Saale)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06114',
    },
    {
        id: '4486',
        ort: 'Halle (Saale)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06116',
    },
    {
        id: '4487',
        ort: 'Halle (Saale)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06118',
    },
    {
        id: '4488',
        ort: 'Halle (Saale)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06120',
    },
    {
        id: '4489',
        ort: 'Halle (Saale)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06122',
    },
    {
        id: '4490',
        ort: 'Halle (Saale)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06124',
    },
    {
        id: '4491',
        ort: 'Halle (Saale)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06126',
    },
    {
        id: '4492',
        ort: 'Halle (Saale)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06128',
    },
    {
        id: '4493',
        ort: 'Halle (Saale)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06130',
    },
    {
        id: '4494',
        ort: 'Halle (Saale)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '06132',
    },
    {
        id: '4495',
        ort: 'Halle (Westf.)',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Gütersloh',
        plz: '33790',
    },
    {
        id: '4496',
        ort: 'Hallgarten',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '67826',
    },
    {
        id: '4497',
        ort: 'Hallig Hooge',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25859',
    },
    {
        id: '4498',
        ort: 'Hallschlag',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54611',
    },
    {
        id: '4499',
        ort: 'Hallstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96103',
    },
    {
        id: '4500',
        ort: 'Hallungen',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '99826',
    },
    {
        id: '4501',
        ort: 'Halsbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84553',
    },
    {
        id: '4502',
        ort: 'Halsbrücke',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09633',
    },
    {
        id: '4503',
        ort: 'Halsdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54646',
    },
    {
        id: '4504',
        ort: 'Halsenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56283',
    },
    {
        id: '4505',
        ort: 'Halstenbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25469',
    },
    {
        id: '4506',
        ort: 'Haltern am See',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '45721',
    },
    {
        id: '4507',
        ort: 'Halver',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58553',
    },
    {
        id: '4508',
        ort: 'Halvesbostel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21646',
    },
    {
        id: '4509',
        ort: 'Hambach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65582',
    },
    {
        id: '4510',
        ort: 'Hamberge',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23619',
    },
    {
        id: '4511',
        ort: 'Hambergen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osterholz',
        plz: '27729',
    },
    {
        id: '4512',
        ort: 'Hambrücken',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76707',
    },
    {
        id: '4513',
        ort: 'Hambuch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56761',
    },
    {
        id: '4514',
        ort: 'Hambühren',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29313',
    },
    {
        id: '4515',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20095',
    },
    {
        id: '4516',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20097',
    },
    {
        id: '4517',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20099',
    },
    {
        id: '4518',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20144',
    },
    {
        id: '4519',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20146',
    },
    {
        id: '4520',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20148',
    },
    {
        id: '4521',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20149',
    },
    {
        id: '4522',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20249',
    },
    {
        id: '4523',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20251',
    },
    {
        id: '4524',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20253',
    },
    {
        id: '4525',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20255',
    },
    {
        id: '4526',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20257',
    },
    {
        id: '4527',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20259',
    },
    {
        id: '4528',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20354',
    },
    {
        id: '4529',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20355',
    },
    {
        id: '4530',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20357',
    },
    {
        id: '4531',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20359',
    },
    {
        id: '4532',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20457',
    },
    {
        id: '4533',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20459',
    },
    {
        id: '4534',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20535',
    },
    {
        id: '4535',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20537',
    },
    {
        id: '4536',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '20539',
    },
    {
        id: '4537',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '21029',
    },
    {
        id: '4538',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '21031',
    },
    {
        id: '4539',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '21033',
    },
    {
        id: '4540',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '21035',
    },
    {
        id: '4541',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '21037',
    },
    {
        id: '4542',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '21039',
    },
    {
        id: '4543',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '21073',
    },
    {
        id: '4544',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '21075',
    },
    {
        id: '4545',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '21077',
    },
    {
        id: '4546',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '21079',
    },
    {
        id: '4547',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '21107',
    },
    {
        id: '4548',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '21109',
    },
    {
        id: '4549',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '21129',
    },
    {
        id: '4550',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '21147',
    },
    {
        id: '4551',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '21149',
    },
    {
        id: '4552',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22041',
    },
    {
        id: '4553',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22043',
    },
    {
        id: '4554',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22045',
    },
    {
        id: '4555',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22047',
    },
    {
        id: '4556',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22049',
    },
    {
        id: '4557',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22081',
    },
    {
        id: '4558',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22083',
    },
    {
        id: '4559',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22085',
    },
    {
        id: '4560',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22087',
    },
    {
        id: '4561',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22089',
    },
    {
        id: '4562',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22111',
    },
    {
        id: '4563',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22113',
    },
    {
        id: '4564',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22115',
    },
    {
        id: '4565',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22117',
    },
    {
        id: '4566',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22119',
    },
    {
        id: '4567',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22143',
    },
    {
        id: '4568',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22145',
    },
    {
        id: '4569',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22147',
    },
    {
        id: '4570',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22149',
    },
    {
        id: '4571',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22159',
    },
    {
        id: '4572',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22175',
    },
    {
        id: '4573',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22177',
    },
    {
        id: '4574',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22179',
    },
    {
        id: '4575',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22297',
    },
    {
        id: '4576',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22299',
    },
    {
        id: '4577',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22301',
    },
    {
        id: '4578',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22303',
    },
    {
        id: '4579',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22305',
    },
    {
        id: '4580',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22307',
    },
    {
        id: '4581',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22309',
    },
    {
        id: '4582',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22335',
    },
    {
        id: '4583',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22337',
    },
    {
        id: '4584',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22339',
    },
    {
        id: '4585',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22359',
    },
    {
        id: '4586',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22391',
    },
    {
        id: '4587',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22393',
    },
    {
        id: '4588',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22395',
    },
    {
        id: '4589',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22397',
    },
    {
        id: '4590',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22399',
    },
    {
        id: '4591',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22415',
    },
    {
        id: '4592',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22417',
    },
    {
        id: '4593',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22419',
    },
    {
        id: '4594',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22453',
    },
    {
        id: '4595',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22455',
    },
    {
        id: '4596',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22457',
    },
    {
        id: '4597',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22459',
    },
    {
        id: '4598',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22523',
    },
    {
        id: '4599',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22525',
    },
    {
        id: '4600',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22527',
    },
    {
        id: '4601',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22529',
    },
    {
        id: '4602',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22547',
    },
    {
        id: '4603',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22549',
    },
    {
        id: '4604',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22559',
    },
    {
        id: '4605',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22587',
    },
    {
        id: '4606',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22589',
    },
    {
        id: '4607',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22605',
    },
    {
        id: '4608',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22607',
    },
    {
        id: '4609',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22609',
    },
    {
        id: '4610',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22761',
    },
    {
        id: '4611',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22763',
    },
    {
        id: '4612',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22765',
    },
    {
        id: '4613',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22767',
    },
    {
        id: '4614',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '22769',
    },
    {
        id: '4615',
        ort: 'Hamburg',
        bundesland: 'Hamburg',
        landkreis: '',
        plz: '27499',
    },
    {
        id: '4616',
        ort: 'Hamdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24805',
    },
    {
        id: '4617',
        ort: 'Hämelhausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '27324',
    },
    {
        id: '4618',
        ort: 'Hameln',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hameln-Pyrmont',
        plz: '31785',
    },
    {
        id: '4619',
        ort: 'Hameln',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hameln-Pyrmont',
        plz: '31787',
    },
    {
        id: '4620',
        ort: 'Hameln',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hameln-Pyrmont',
        plz: '31789',
    },
    {
        id: '4621',
        ort: 'Hamersen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27419',
    },
    {
        id: '4622',
        ort: 'Hamfelde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22929',
    },
    {
        id: '4623',
        ort: 'Hamfelde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '22929',
    },
    {
        id: '4624',
        ort: 'Hamm',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '59063',
    },
    {
        id: '4625',
        ort: 'Hamm',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '59065',
    },
    {
        id: '4626',
        ort: 'Hamm',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '59067',
    },
    {
        id: '4627',
        ort: 'Hamm',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '59069',
    },
    {
        id: '4628',
        ort: 'Hamm',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '59071',
    },
    {
        id: '4629',
        ort: 'Hamm',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '59073',
    },
    {
        id: '4630',
        ort: 'Hamm',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '59075',
    },
    {
        id: '4631',
        ort: 'Hamm',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '59077',
    },
    {
        id: '4632',
        ort: 'Hamm',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '4633',
        ort: 'Hammah',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21714',
    },
    {
        id: '4634',
        ort: 'Hamm am Rhein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67580',
    },
    {
        id: '4635',
        ort: 'Hammelburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97762',
    },
    {
        id: '4636',
        ort: 'Hammer a.d. Uecker',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17358',
    },
    {
        id: '4637',
        ort: 'Hammersbach',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63546',
    },
    {
        id: '4638',
        ort: 'Hammerstedt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99441',
    },
    {
        id: '4639',
        ort: 'Hammerstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56598',
    },
    {
        id: '4640',
        ort: 'Hamminkeln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '46499',
    },
    {
        id: '4641',
        ort: 'Hammoor',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22941',
    },
    {
        id: '4642',
        ort: 'Hamm (Sieg)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57577',
    },
    {
        id: '4643',
        ort: 'Hamwarde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21502',
    },
    {
        id: '4644',
        ort: 'Hamweddel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24816',
    },
    {
        id: '4645',
        ort: 'Hanau',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63450',
    },
    {
        id: '4646',
        ort: 'Hanau',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63452',
    },
    {
        id: '4647',
        ort: 'Hanau',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63454',
    },
    {
        id: '4648',
        ort: 'Hanau',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63456',
    },
    {
        id: '4649',
        ort: 'Hanau',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63457',
    },
    {
        id: '4650',
        ort: 'Handeloh',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21256',
    },
    {
        id: '4651',
        ort: 'Handewitt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24976',
    },
    {
        id: '4652',
        ort: 'Handewitt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24983',
    },
    {
        id: '4653',
        ort: 'Handorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21447',
    },
    {
        id: '4654',
        ort: 'Handrup',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49838',
    },
    {
        id: '4655',
        ort: 'Hanerau-Hademarschen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '25557',
    },
    {
        id: '4656',
        ort: 'Hangen-Weisheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '4657',
        ort: 'Hanhofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67374',
    },
    {
        id: '4658',
        ort: 'Hankensbüttel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '29386',
    },
    {
        id: '4659',
        ort: 'Hann. Münden',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '34346',
    },
    {
        id: '4660',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30159',
    },
    {
        id: '4661',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30161',
    },
    {
        id: '4662',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30163',
    },
    {
        id: '4663',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30165',
    },
    {
        id: '4664',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30167',
    },
    {
        id: '4665',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30169',
    },
    {
        id: '4666',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30171',
    },
    {
        id: '4667',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30173',
    },
    {
        id: '4668',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30175',
    },
    {
        id: '4669',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30177',
    },
    {
        id: '4670',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30179',
    },
    {
        id: '4671',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30419',
    },
    {
        id: '4672',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30449',
    },
    {
        id: '4673',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30451',
    },
    {
        id: '4674',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30453',
    },
    {
        id: '4675',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30455',
    },
    {
        id: '4676',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30457',
    },
    {
        id: '4677',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30459',
    },
    {
        id: '4678',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30519',
    },
    {
        id: '4679',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30521',
    },
    {
        id: '4680',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30539',
    },
    {
        id: '4681',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30559',
    },
    {
        id: '4682',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30625',
    },
    {
        id: '4683',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30627',
    },
    {
        id: '4684',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30629',
    },
    {
        id: '4685',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30655',
    },
    {
        id: '4686',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30657',
    },
    {
        id: '4687',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30659',
    },
    {
        id: '4688',
        ort: 'Hannover',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30669',
    },
    {
        id: '4689',
        ort: 'Hanroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56316',
    },
    {
        id: '4690',
        ort: 'Hanshagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17509',
    },
    {
        id: '4691',
        ort: 'Hanstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21271',
    },
    {
        id: '4692',
        ort: 'Hanstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29582',
    },
    {
        id: '4693',
        ort: 'Happurg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91230',
    },
    {
        id: '4694',
        ort: 'Harbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57572',
    },
    {
        id: '4695',
        ort: 'Harbke',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39365',
    },
    {
        id: '4696',
        ort: 'Harburg (Schwaben)',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86655',
    },
    {
        id: '4697',
        ort: 'Hardebek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24616',
    },
    {
        id: '4698',
        ort: 'Hardegsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Northeim',
        plz: '37181',
    },
    {
        id: '4699',
        ort: 'Hardert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56579',
    },
    {
        id: '4700',
        ort: 'Hardheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74736',
    },
    {
        id: '4701',
        ort: 'Hardt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56472',
    },
    {
        id: '4702',
        ort: 'Hardt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78739',
    },
    {
        id: '4703',
        ort: 'Hardthausen am Kocher',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74239',
    },
    {
        id: '4704',
        ort: 'Haren (Ems)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49733',
    },
    {
        id: '4705',
        ort: 'Hargarten',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54649',
    },
    {
        id: '4706',
        ort: 'Hargesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '4707',
        ort: 'Harmsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23738',
    },
    {
        id: '4708',
        ort: 'Harmsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23911',
    },
    {
        id: '4709',
        ort: 'Harmstorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21228',
    },
    {
        id: '4710',
        ort: 'Harpstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Oldenburg',
        plz: '27243',
    },
    {
        id: '4711',
        ort: 'Harrislee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24955',
    },
    {
        id: '4712',
        ort: 'Harschbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56307',
    },
    {
        id: '4713',
        ort: 'Harscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53520',
    },
    {
        id: '4714',
        ort: 'Harsdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95499',
    },
    {
        id: '4715',
        ort: 'Harsefeld',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21698',
    },
    {
        id: '4716',
        ort: 'Harsewinkel',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Gütersloh',
        plz: '33428',
    },
    {
        id: '4717',
        ort: 'Harsleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38829',
    },
    {
        id: '4718',
        ort: 'Harspelt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54617',
    },
    {
        id: '4719',
        ort: 'Harsum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31177',
    },
    {
        id: '4720',
        ort: 'Hartenfels',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '4721',
        ort: 'Hartenholm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24628',
    },
    {
        id: '4722',
        ort: 'Hartenstein',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08118',
    },
    {
        id: '4723',
        ort: 'Hartenstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91235',
    },
    {
        id: '4724',
        ort: 'Hartha',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '04746',
    },
    {
        id: '4725',
        ort: 'Harthausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67376',
    },
    {
        id: '4726',
        ort: 'Hartheim am Rhein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79258',
    },
    {
        id: '4727',
        ort: 'Harth-Pöllnitz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07570',
    },
    {
        id: '4728',
        ort: 'Härtlingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '4729',
        ort: 'Hartmannsdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09232',
    },
    {
        id: '4730',
        ort: 'Hartmannsdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07613',
    },
    {
        id: '4731',
        ort: 'Hartmannsdorf bei Kirchberg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08107',
    },
    {
        id: '4732',
        ort: 'Hartmannsdorf-Reichenau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01762',
    },
    {
        id: '4733',
        ort: 'Harxheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55296',
    },
    {
        id: '4734',
        ort: 'Harzgerode',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '06493',
    },
    {
        id: '4735',
        ort: 'Harztor',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Nordhausen',
        plz: '99768',
    },
    {
        id: '4736',
        ort: 'Hasbergen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49205',
    },
    {
        id: '4737',
        ort: 'Hasborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54533',
    },
    {
        id: '4738',
        ort: 'Haschbach am Remigiusberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66871',
    },
    {
        id: '4739',
        ort: 'Hasel',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79686',
    },
    {
        id: '4740',
        ort: 'Haselau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25489',
    },
    {
        id: '4741',
        ort: 'Haselbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94354',
    },
    {
        id: '4742',
        ort: 'Haselbach',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04617',
    },
    {
        id: '4743',
        ort: 'Haselbachtal',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01920',
    },
    {
        id: '4744',
        ort: 'Haseldorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25489',
    },
    {
        id: '4745',
        ort: 'Haselund',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25855',
    },
    {
        id: '4746',
        ort: 'Haselünne',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49740',
    },
    {
        id: '4747',
        ort: 'Hasenkrug',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24616',
    },
    {
        id: '4748',
        ort: 'Hasenmoor',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24640',
    },
    {
        id: '4749',
        ort: 'Haserich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56858',
    },
    {
        id: '4750',
        ort: 'Haslach im Kinzigtal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77716',
    },
    {
        id: '4751',
        ort: 'Hasloch',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97907',
    },
    {
        id: '4752',
        ort: 'Hasloh',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25474',
    },
    {
        id: '4753',
        ort: 'Haßbergen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31626',
    },
    {
        id: '4754',
        ort: 'Hassel',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39596',
    },
    {
        id: '4755',
        ort: 'Hasselbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57635',
    },
    {
        id: '4756',
        ort: 'Hasselbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56288',
    },
    {
        id: '4757',
        ort: 'Hasselberg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24376',
    },
    {
        id: '4758',
        ort: 'Hasselroth',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63594',
    },
    {
        id: '4759',
        ort: 'Hassel (Weser)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '27324',
    },
    {
        id: '4760',
        ort: 'Hassendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27367',
    },
    {
        id: '4761',
        ort: 'Haßfurt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '97437',
    },
    {
        id: '4762',
        ort: 'Haßleben',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99189',
    },
    {
        id: '4763',
        ort: 'Haßloch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67454',
    },
    {
        id: '4764',
        ort: 'Haßmersheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74855',
    },
    {
        id: '4765',
        ort: 'Haßmoor',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24790',
    },
    {
        id: '4766',
        ort: 'Haste',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31559',
    },
    {
        id: '4767',
        ort: 'Hatten',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Oldenburg',
        plz: '26209',
    },
    {
        id: '4768',
        ort: 'Hattenhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82285',
    },
    {
        id: '4769',
        ort: 'Hattenhofen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73110',
    },
    {
        id: '4770',
        ort: 'Hattersheim am Main',
        bundesland: 'Hessen',
        landkreis: 'Main-Taunus-Kreis',
        plz: '65795',
    },
    {
        id: '4771',
        ort: 'Hattert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57644',
    },
    {
        id: '4772',
        ort: 'Hattgenstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '4773',
        ort: 'Hattingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Ennepe-Ruhr-Kreis',
        plz: '45525',
    },
    {
        id: '4774',
        ort: 'Hattingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Ennepe-Ruhr-Kreis',
        plz: '45527',
    },
    {
        id: '4775',
        ort: 'Hattingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Ennepe-Ruhr-Kreis',
        plz: '45529',
    },
    {
        id: '4776',
        ort: 'Hattorf am Harz',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37197',
    },
    {
        id: '4777',
        ort: 'Hattstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25856',
    },
    {
        id: '4778',
        ort: 'Hattstedtermarsch',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25856',
    },
    {
        id: '4779',
        ort: 'Hatzenbühl',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76770',
    },
    {
        id: '4780',
        ort: 'Hatzenport',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56332',
    },
    {
        id: '4781',
        ort: 'Hatzfeld (Eder)',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '35116',
    },
    {
        id: '4782',
        ort: 'Hauenstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '76846',
    },
    {
        id: '4783',
        ort: 'Haundorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91729',
    },
    {
        id: '4784',
        ort: 'Hauneck',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36282',
    },
    {
        id: '4785',
        ort: 'Haunetal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36166',
    },
    {
        id: '4786',
        ort: 'Haunsheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89437',
    },
    {
        id: '4787',
        ort: 'Hauptstuhl',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66851',
    },
    {
        id: '4788',
        ort: 'Hauroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56761',
    },
    {
        id: '4789',
        ort: 'Hausach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77756',
    },
    {
        id: '4790',
        ort: 'Hausbay',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56291',
    },
    {
        id: '4791',
        ort: 'Hausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97647',
    },
    {
        id: '4792',
        ort: 'Hausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63840',
    },
    {
        id: '4793',
        ort: 'Hausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '93345',
    },
    {
        id: '4794',
        ort: 'Hausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91353',
    },
    {
        id: '4795',
        ort: 'Hausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55608',
    },
    {
        id: '4796',
        ort: 'Hausen am Bussen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89597',
    },
    {
        id: '4797',
        ort: 'Hausen am Tann',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72361',
    },
    {
        id: '4798',
        ort: 'Hausen b. Würzburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97262',
    },
    {
        id: '4799',
        ort: 'Hausen im Wiesental',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79688',
    },
    {
        id: '4800',
        ort: 'Hausen ob Verena',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78595',
    },
    {
        id: '4801',
        ort: 'Hausen (Wied)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53547',
    },
    {
        id: '4802',
        ort: 'Häusern',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79837',
    },
    {
        id: '4803',
        ort: 'Hausham',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miesbach',
        plz: '83734',
    },
    {
        id: '4804',
        ort: 'Häuslingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '27336',
    },
    {
        id: '4805',
        ort: 'Haussömmern',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99955',
    },
    {
        id: '4806',
        ort: 'Hausten',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56745',
    },
    {
        id: '4807',
        ort: 'Hausweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67742',
    },
    {
        id: '4808',
        ort: 'Hauzenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94051',
    },
    {
        id: '4809',
        ort: 'Havekost',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21493',
    },
    {
        id: '4810',
        ort: 'Havelaue',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14715',
    },
    {
        id: '4811',
        ort: 'Havelberg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39539',
    },
    {
        id: '4812',
        ort: 'Havelsee',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14798',
    },
    {
        id: '4813',
        ort: 'Haverlah',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38275',
    },
    {
        id: '4814',
        ort: 'Havetoft',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24873',
    },
    {
        id: '4815',
        ort: 'Havixbeck',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Coesfeld',
        plz: '48329',
    },
    {
        id: '4816',
        ort: 'Hawangen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87749',
    },
    {
        id: '4817',
        ort: 'Hayingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72534',
    },
    {
        id: '4818',
        ort: 'Haynrode',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37339',
    },
    {
        id: '4819',
        ort: 'Hebertsfelden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84332',
    },
    {
        id: '4820',
        ort: 'Hebertshausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '85241',
    },
    {
        id: '4821',
        ort: 'Hebertshausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '85764',
    },
    {
        id: '4822',
        ort: 'Hechingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72379',
    },
    {
        id: '4823',
        ort: 'Hechthausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21755',
    },
    {
        id: '4824',
        ort: 'Heckelberg-Brunow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '16259',
    },
    {
        id: '4825',
        ort: 'Hecken',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55481',
    },
    {
        id: '4826',
        ort: 'Heckenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53506',
    },
    {
        id: '4827',
        ort: 'Heckenmünster',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '4828',
        ort: 'Heckenmünster',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54523',
    },
    {
        id: '4829',
        ort: 'Heckhuscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54619',
    },
    {
        id: '4830',
        ort: 'Hecklingen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '39444',
    },
    {
        id: '4831',
        ort: 'Heddert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54429',
    },
    {
        id: '4832',
        ort: 'Heddesbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69434',
    },
    {
        id: '4833',
        ort: 'Heddesheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '68542',
    },
    {
        id: '4834',
        ort: 'Hedeper',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38322',
    },
    {
        id: '4835',
        ort: 'Hedersleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '06458',
    },
    {
        id: '4836',
        ort: 'Hedwigenkoog',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25761',
    },
    {
        id: '4837',
        ort: 'Heede',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25355',
    },
    {
        id: '4838',
        ort: 'Heede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26892',
    },
    {
        id: '4839',
        ort: 'Heek',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '48619',
    },
    {
        id: '4840',
        ort: 'Heemsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31622',
    },
    {
        id: '4841',
        ort: 'Heere',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38277',
    },
    {
        id: '4842',
        ort: 'Heeslingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27404',
    },
    {
        id: '4843',
        ort: 'Heeßen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31707',
    },
    {
        id: '4844',
        ort: 'Hefersweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67753',
    },
    {
        id: '4845',
        ort: 'Hehlen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37619',
    },
    {
        id: '4846',
        ort: 'Heide',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25746',
    },
    {
        id: '4847',
        ort: 'Heideblick',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15926',
    },
    {
        id: '4848',
        ort: 'Heideck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Roth',
        plz: '91180',
    },
    {
        id: '4849',
        ort: 'Heidekamp',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23858',
    },
    {
        id: '4850',
        ort: 'Heideland',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '03238',
    },
    {
        id: '4851',
        ort: 'Heideland',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07613',
    },
    {
        id: '4852',
        ort: 'Heidelberg',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '69115',
    },
    {
        id: '4853',
        ort: 'Heidelberg',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '69117',
    },
    {
        id: '4854',
        ort: 'Heidelberg',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '69118',
    },
    {
        id: '4855',
        ort: 'Heidelberg',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '69120',
    },
    {
        id: '4856',
        ort: 'Heidelberg',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '69121',
    },
    {
        id: '4857',
        ort: 'Heidelberg',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '69123',
    },
    {
        id: '4858',
        ort: 'Heidelberg',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '69124',
    },
    {
        id: '4859',
        ort: 'Heidelberg',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '69126',
    },
    {
        id: '4860',
        ort: 'Heiden',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '46359',
    },
    {
        id: '4861',
        ort: 'Heidenau',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21258',
    },
    {
        id: '4862',
        ort: 'Heidenau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01809',
    },
    {
        id: '4863',
        ort: 'Heidenburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54426',
    },
    {
        id: '4864',
        ort: 'Heidenheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91719',
    },
    {
        id: '4865',
        ort: 'Heidenheim an der Brenz',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heidenheim',
        plz: '89518',
    },
    {
        id: '4866',
        ort: 'Heidenheim an der Brenz',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heidenheim',
        plz: '89520',
    },
    {
        id: '4867',
        ort: 'Heidenheim an der Brenz',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heidenheim',
        plz: '89522',
    },
    {
        id: '4868',
        ort: 'Heidenrod',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65321',
    },
    {
        id: '4869',
        ort: 'Heidersdorf',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09526',
    },
    {
        id: '4870',
        ort: 'Heidesee',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15754',
    },
    {
        id: '4871',
        ort: 'Heidgraben',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25436',
    },
    {
        id: '4872',
        ort: 'Heidmoor',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24632',
    },
    {
        id: '4873',
        ort: 'Heidmühlen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24598',
    },
    {
        id: '4874',
        ort: 'Heidweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '4875',
        ort: 'Heigenbrücken',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63869',
    },
    {
        id: '4876',
        ort: 'Heikendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24226',
    },
    {
        id: '4877',
        ort: 'Heilbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '4878',
        ort: 'Heilbad Heiligenstadt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37308',
    },
    {
        id: '4879',
        ort: 'Heilberscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '4880',
        ort: 'Heilbronn',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '74072',
    },
    {
        id: '4881',
        ort: 'Heilbronn',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '74074',
    },
    {
        id: '4882',
        ort: 'Heilbronn',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '74076',
    },
    {
        id: '4883',
        ort: 'Heilbronn',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '74078',
    },
    {
        id: '4884',
        ort: 'Heilbronn',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '74080',
    },
    {
        id: '4885',
        ort: 'Heilbronn',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '74081',
    },
    {
        id: '4886',
        ort: 'Heilenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '4887',
        ort: 'Heiligenberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88633',
    },
    {
        id: '4888',
        ort: 'Heiligengrabe',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16909',
    },
    {
        id: '4889',
        ort: 'Heiligenhafen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23774',
    },
    {
        id: '4890',
        ort: 'Heiligenhaus',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '42579',
    },
    {
        id: '4891',
        ort: 'Heiligenmoschel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67699',
    },
    {
        id: '4892',
        ort: 'Heiligenroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '4893',
        ort: 'Heiligenstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '91332',
    },
    {
        id: '4894',
        ort: 'Heiligenstedten',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25524',
    },
    {
        id: '4895',
        ort: 'Heiligenstedtenerkamp',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25524',
    },
    {
        id: '4896',
        ort: 'Heiligkreuzsteinach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69253',
    },
    {
        id: '4897',
        ort: 'Heilsbronn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91560',
    },
    {
        id: '4898',
        ort: 'Heilshoop',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23619',
    },
    {
        id: '4899',
        ort: 'Heimbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Düren',
        plz: '52396',
    },
    {
        id: '4900',
        ort: 'Heimbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55779',
    },
    {
        id: '4901',
        ort: 'Heimborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57629',
    },
    {
        id: '4902',
        ort: 'Heimbuchenthal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63872',
    },
    {
        id: '4903',
        ort: 'Heimenkirch',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88178',
    },
    {
        id: '4904',
        ort: 'Heimertingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87751',
    },
    {
        id: '4905',
        ort: 'Heimsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '71296',
    },
    {
        id: '4906',
        ort: 'Heimweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55606',
    },
    {
        id: '4907',
        ort: 'Heinade',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37627',
    },
    {
        id: '4908',
        ort: 'Heinbockel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21726',
    },
    {
        id: '4909',
        ort: 'Heinersbrück',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03185',
    },
    {
        id: '4910',
        ort: 'Heinersreuth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95500',
    },
    {
        id: '4911',
        ort: 'Heiningen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38312',
    },
    {
        id: '4912',
        ort: 'Heiningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73037',
    },
    {
        id: '4913',
        ort: 'Heiningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73092',
    },
    {
        id: '4914',
        ort: 'Heiningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73107',
    },
    {
        id: '4915',
        ort: 'Heinkenborstel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24594',
    },
    {
        id: '4916',
        ort: 'Heinrichsthal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63871',
    },
    {
        id: '4917',
        ort: 'Heinrichswalde',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17379',
    },
    {
        id: '4918',
        ort: 'Heinsberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Heinsberg',
        plz: '52525',
    },
    {
        id: '4919',
        ort: 'Heinsdorfergrund',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08468',
    },
    {
        id: '4920',
        ort: 'Heinsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37649',
    },
    {
        id: '4921',
        ort: 'Heinzenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55483',
    },
    {
        id: '4922',
        ort: 'Heinzenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55606',
    },
    {
        id: '4923',
        ort: 'Heinzenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67742',
    },
    {
        id: '4924',
        ort: 'Heisdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54614',
    },
    {
        id: '4925',
        ort: 'Heist',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25492',
    },
    {
        id: '4926',
        ort: 'Heistenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65558',
    },
    {
        id: '4927',
        ort: 'Heitersheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79423',
    },
    {
        id: '4928',
        ort: 'Helbedündorf',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '99713',
    },
    {
        id: '4929',
        ort: 'Helbra',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06311',
    },
    {
        id: '4930',
        ort: 'Heldburg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98663',
    },
    {
        id: '4931',
        ort: 'Heldenstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84431',
    },
    {
        id: '4932',
        ort: 'Helferskirchen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '4933',
        ort: 'Helgoland',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '27498',
    },
    {
        id: '4934',
        ort: 'Hellenhahn-Schellenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '4935',
        ort: 'Hellenthal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Euskirchen',
        plz: '53940',
    },
    {
        id: '4936',
        ort: 'Hellertshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '4937',
        ort: 'Hellschen-Heringsand-Unterschaar',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25764',
    },
    {
        id: '4938',
        ort: 'Hellwege',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27367',
    },
    {
        id: '4939',
        ort: 'Helmbrechts',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95233',
    },
    {
        id: '4940',
        ort: 'Helmenzen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57612',
    },
    {
        id: '4941',
        ort: 'Helmeroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57612',
    },
    {
        id: '4942',
        ort: 'Helmstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97264',
    },
    {
        id: '4943',
        ort: 'Helmstadt-Bargen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '74921',
    },
    {
        id: '4944',
        ort: 'Helmstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38350',
    },
    {
        id: '4945',
        ort: 'Helmstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38372',
    },
    {
        id: '4946',
        ort: 'Helmstorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24321',
    },
    {
        id: '4947',
        ort: 'Helpsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31691',
    },
    {
        id: '4948',
        ort: 'Helsa',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34298',
    },
    {
        id: '4949',
        ort: 'Helse',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25709',
    },
    {
        id: '4950',
        ort: 'Heltersberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '67716',
    },
    {
        id: '4951',
        ort: 'Helvesiek',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27389',
    },
    {
        id: '4952',
        ort: 'Hemau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93155',
    },
    {
        id: '4953',
        ort: 'Hemdingen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25485',
    },
    {
        id: '4954',
        ort: 'Hemer',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58675',
    },
    {
        id: '4955',
        ort: 'Hemhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91334',
    },
    {
        id: '4956',
        ort: 'Hemme',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25774',
    },
    {
        id: '4957',
        ort: 'Hemmelzen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57612',
    },
    {
        id: '4958',
        ort: 'Hemmersheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '97258',
    },
    {
        id: '4959',
        ort: 'Hemmingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71282',
    },
    {
        id: '4960',
        ort: 'Hemmingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30966',
    },
    {
        id: '4961',
        ort: 'Hemmingstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25770',
    },
    {
        id: '4962',
        ort: 'Hemmoor',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21745',
    },
    {
        id: '4963',
        ort: 'Hemsbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69502',
    },
    {
        id: '4964',
        ort: 'Hemsbünde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27386',
    },
    {
        id: '4965',
        ort: 'Hemslingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27386',
    },
    {
        id: '4966',
        ort: 'Hemsloh',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49453',
    },
    {
        id: '4967',
        ort: 'Henau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55490',
    },
    {
        id: '4968',
        ort: 'Hendungen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97640',
    },
    {
        id: '4969',
        ort: 'Henfenfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91239',
    },
    {
        id: '4970',
        ort: 'Henfstädt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98660',
    },
    {
        id: '4971',
        ort: 'Hengersberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94491',
    },
    {
        id: '4972',
        ort: 'Hennef (Sieg)',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53773',
    },
    {
        id: '4973',
        ort: 'Hennigsdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16761',
    },
    {
        id: '4974',
        ort: 'Hennstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25581',
    },
    {
        id: '4975',
        ort: 'Hennstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25779',
    },
    {
        id: '4976',
        ort: 'Hennweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55619',
    },
    {
        id: '4977',
        ort: 'Henschtal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66909',
    },
    {
        id: '4978',
        ort: 'Henstedt-Ulzburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24558',
    },
    {
        id: '4979',
        ort: 'Hentern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54314',
    },
    {
        id: '4980',
        ort: 'Hepberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85120',
    },
    {
        id: '4981',
        ort: 'Heppenheim',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '64646',
    },
    {
        id: '4982',
        ort: 'Hepstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27412',
    },
    {
        id: '4983',
        ort: 'Herbertingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '88518',
    },
    {
        id: '4984',
        ort: 'Herbolzheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79336',
    },
    {
        id: '4985',
        ort: 'Herborn',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35745',
    },
    {
        id: '4986',
        ort: 'Herborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '4987',
        ort: 'Herbrechtingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heidenheim',
        plz: '89542',
    },
    {
        id: '4988',
        ort: 'Herbsleben',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99955',
    },
    {
        id: '4989',
        ort: 'Herbstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97633',
    },
    {
        id: '4990',
        ort: 'Herbstein',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '36358',
    },
    {
        id: '4991',
        ort: 'Herbstmühle',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '4992',
        ort: 'Herchweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66871',
    },
    {
        id: '4993',
        ort: 'Herdecke',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Ennepe-Ruhr-Kreis',
        plz: '58313',
    },
    {
        id: '4994',
        ort: 'Herdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57562',
    },
    {
        id: '4995',
        ort: 'Herdwangen-Schönach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '88634',
    },
    {
        id: '4996',
        ort: 'Heretsried',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86465',
    },
    {
        id: '4997',
        ort: 'Herford',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Herford',
        plz: '32049',
    },
    {
        id: '4998',
        ort: 'Herford',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Herford',
        plz: '32051',
    },
    {
        id: '4999',
        ort: 'Herford',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Herford',
        plz: '32052',
    },
    {
        id: '5000',
        ort: 'Herforst',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54662',
    },
    {
        id: '5001',
        ort: 'Hergatz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88145',
    },
    {
        id: '5002',
        ort: 'Hergenfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55452',
    },
    {
        id: '5003',
        ort: 'Hergenroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56457',
    },
    {
        id: '5004',
        ort: 'Hergensweiler',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88138',
    },
    {
        id: '5005',
        ort: 'Hergersweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76872',
    },
    {
        id: '5006',
        ort: 'Hergisdorf',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06313',
    },
    {
        id: '5007',
        ort: 'Heringen/Helme',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Nordhausen',
        plz: '99765',
    },
    {
        id: '5008',
        ort: 'Heringen (Werra)',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36266',
    },
    {
        id: '5009',
        ort: 'Heringsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23777',
    },
    {
        id: '5010',
        ort: 'Heringsdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17419',
    },
    {
        id: '5011',
        ort: 'Heringsdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17424',
    },
    {
        id: '5012',
        ort: 'Heringsdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17429',
    },
    {
        id: '5013',
        ort: 'Herl',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54317',
    },
    {
        id: '5014',
        ort: 'Herleshausen',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37293',
    },
    {
        id: '5015',
        ort: 'Hermaringen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heidenheim',
        plz: '89537',
    },
    {
        id: '5016',
        ort: 'Hermaringen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heidenheim',
        plz: '89568',
    },
    {
        id: '5017',
        ort: 'Hermersberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66919',
    },
    {
        id: '5018',
        ort: 'Hermeskeil',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54411',
    },
    {
        id: '5019',
        ort: 'Hermsdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01945',
    },
    {
        id: '5020',
        ort: 'Hermsdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07629',
    },
    {
        id: '5021',
        ort: 'Hermsdorf/Erzgebirge',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01776',
    },
    {
        id: '5022',
        ort: 'Herne',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44623',
    },
    {
        id: '5023',
        ort: 'Herne',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44625',
    },
    {
        id: '5024',
        ort: 'Herne',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44627',
    },
    {
        id: '5025',
        ort: 'Herne',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44628',
    },
    {
        id: '5026',
        ort: 'Herne',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44629',
    },
    {
        id: '5027',
        ort: 'Herne',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44649',
    },
    {
        id: '5028',
        ort: 'Herne',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44651',
    },
    {
        id: '5029',
        ort: 'Herne',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44652',
    },
    {
        id: '5030',
        ort: 'Herne',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '44653',
    },
    {
        id: '5031',
        ort: 'Herold',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56368',
    },
    {
        id: '5032',
        ort: 'Heroldsbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91336',
    },
    {
        id: '5033',
        ort: 'Heroldsberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '90562',
    },
    {
        id: '5034',
        ort: 'Heroldstatt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '72535',
    },
    {
        id: '5035',
        ort: 'Herrenberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71083',
    },
    {
        id: '5036',
        ort: 'Herrenhof',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99887',
    },
    {
        id: '5037',
        ort: 'Herren-Sulzbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67742',
    },
    {
        id: '5038',
        ort: 'Herresbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '5039',
        ort: 'Herrieden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91567',
    },
    {
        id: '5040',
        ort: 'Herrischried',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79737',
    },
    {
        id: '5041',
        ort: 'Herrngiersdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '84097',
    },
    {
        id: '5042',
        ort: 'Herrnhut',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02747',
    },
    {
        id: '5043',
        ort: 'Herrsching am Ammersee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Starnberg',
        plz: '82211',
    },
    {
        id: '5044',
        ort: 'Herrstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55756',
    },
    {
        id: '5045',
        ort: 'Hersbruck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91217',
    },
    {
        id: '5046',
        ort: 'Herschbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56249',
    },
    {
        id: '5047',
        ort: 'Herschbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56414',
    },
    {
        id: '5048',
        ort: 'Herschberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66917',
    },
    {
        id: '5049',
        ort: 'Herschberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66919',
    },
    {
        id: '5050',
        ort: 'Herschberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66987',
    },
    {
        id: '5051',
        ort: 'Herschbroich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53518',
    },
    {
        id: '5052',
        ort: 'Herscheid',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58849',
    },
    {
        id: '5053',
        ort: 'Herschweiler-Pettersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66909',
    },
    {
        id: '5054',
        ort: 'Hersdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '5055',
        ort: 'Herten',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '45699',
    },
    {
        id: '5056',
        ort: 'Herten',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '45701',
    },
    {
        id: '5057',
        ort: 'Herxheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76863',
    },
    {
        id: '5058',
        ort: 'Herxheim am Berg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67273',
    },
    {
        id: '5059',
        ort: 'Herxheimweyher',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76863',
    },
    {
        id: '5060',
        ort: 'Herzberg am Harz',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37412',
    },
    {
        id: '5061',
        ort: 'Herzberg am Harz',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37434',
    },
    {
        id: '5062',
        ort: 'Herzberg (Elster)',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04916',
    },
    {
        id: '5063',
        ort: 'Herzberg (Mark)',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16835',
    },
    {
        id: '5064',
        ort: 'Herzebrock-Clarholz',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Gütersloh',
        plz: '33442',
    },
    {
        id: '5065',
        ort: 'Herzfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54619',
    },
    {
        id: '5066',
        ort: 'Herzhorn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25379',
    },
    {
        id: '5067',
        ort: 'Herzlake',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49770',
    },
    {
        id: '5068',
        ort: 'Herzogenaurach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91074',
    },
    {
        id: '5069',
        ort: 'Herzogenrath',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52134',
    },
    {
        id: '5070',
        ort: 'Hesel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26835',
    },
    {
        id: '5071',
        ort: 'Hespe',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31693',
    },
    {
        id: '5072',
        ort: 'Heßdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91093',
    },
    {
        id: '5073',
        ort: 'Heßheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67258',
    },
    {
        id: '5074',
        ort: 'Hessigheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '74394',
    },
    {
        id: '5075',
        ort: 'Hessisch Lichtenau',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37235',
    },
    {
        id: '5076',
        ort: 'Hessisch Oldendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hameln-Pyrmont',
        plz: '31840',
    },
    {
        id: '5077',
        ort: 'Hesweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56865',
    },
    {
        id: '5078',
        ort: 'Hetlingen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25491',
    },
    {
        id: '5079',
        ort: 'Hetschburg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99438',
    },
    {
        id: '5080',
        ort: 'Hettenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66919',
    },
    {
        id: '5081',
        ort: 'Hettenleidelheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67310',
    },
    {
        id: '5082',
        ort: 'Hettenrodt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '5083',
        ort: 'Hettenshausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85276',
    },
    {
        id: '5084',
        ort: 'Hettenshausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85304',
    },
    {
        id: '5085',
        ort: 'Hettenshausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85307',
    },
    {
        id: '5086',
        ort: 'Hettingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '72513',
    },
    {
        id: '5087',
        ort: 'Hettstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97265',
    },
    {
        id: '5088',
        ort: 'Hettstedt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06333',
    },
    {
        id: '5089',
        ort: 'Hetzerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54523',
    },
    {
        id: '5090',
        ort: 'Hetzles',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91077',
    },
    {
        id: '5091',
        ort: 'Heubach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73540',
    },
    {
        id: '5092',
        ort: 'Heuchelheim',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35452',
    },
    {
        id: '5093',
        ort: 'Heuchelheim bei Frankenthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67259',
    },
    {
        id: '5094',
        ort: 'Heuchelheim-Klingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76831',
    },
    {
        id: '5095',
        ort: 'Heuchlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73572',
    },
    {
        id: '5096',
        ort: 'Heuerßen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31700',
    },
    {
        id: '5097',
        ort: 'Heukewalde',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04626',
    },
    {
        id: '5098',
        ort: 'Heupelzen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57612',
    },
    {
        id: '5099',
        ort: 'Heusenstamm',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Offenbach',
        plz: '63150',
    },
    {
        id: '5100',
        ort: 'Heustreu',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97618',
    },
    {
        id: '5101',
        ort: 'Heusweiler',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66265',
    },
    {
        id: '5102',
        ort: 'Heuthen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37308',
    },
    {
        id: '5103',
        ort: 'Heuweiler',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79194',
    },
    {
        id: '5104',
        ort: 'Heuzert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57627',
    },
    {
        id: '5105',
        ort: 'Heyen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37619',
    },
    {
        id: '5106',
        ort: 'Heyersdorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04626',
    },
    {
        id: '5107',
        ort: 'Hiddenhausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Herford',
        plz: '32120',
    },
    {
        id: '5108',
        ort: 'Hilbersdorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07580',
    },
    {
        id: '5109',
        ort: 'Hilchenbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Siegen-Wittgenstein',
        plz: '57271',
    },
    {
        id: '5110',
        ort: 'Hildburghausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98646',
    },
    {
        id: '5111',
        ort: 'Hilden',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '40721',
    },
    {
        id: '5112',
        ort: 'Hilden',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '40723',
    },
    {
        id: '5113',
        ort: 'Hilden',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '40724',
    },
    {
        id: '5114',
        ort: 'Hilders',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36115',
    },
    {
        id: '5115',
        ort: 'Hildesheim',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31134',
    },
    {
        id: '5116',
        ort: 'Hildesheim',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31135',
    },
    {
        id: '5117',
        ort: 'Hildesheim',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31137',
    },
    {
        id: '5118',
        ort: 'Hildesheim',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31139',
    },
    {
        id: '5119',
        ort: 'Hildesheim',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31141',
    },
    {
        id: '5120',
        ort: 'Hildrizhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71157',
    },
    {
        id: '5121',
        ort: 'Hilgenroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57612',
    },
    {
        id: '5122',
        ort: 'Hilgermissen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '27318',
    },
    {
        id: '5123',
        ort: 'Hilgert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56206',
    },
    {
        id: '5124',
        ort: 'Hilgertshausen-Tandern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '86567',
    },
    {
        id: '5125',
        ort: 'Hilkenbrook',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26897',
    },
    {
        id: '5126',
        ort: 'Hille',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Minden-Lübbecke',
        plz: '32479',
    },
    {
        id: '5127',
        ort: 'Hillerse',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38543',
    },
    {
        id: '5128',
        ort: 'Hillesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54576',
    },
    {
        id: '5129',
        ort: 'Hillesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '67586',
    },
    {
        id: '5130',
        ort: 'Hillgroven',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25764',
    },
    {
        id: '5131',
        ort: 'Hillscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56204',
    },
    {
        id: '5132',
        ort: 'Hilpoltstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Roth',
        plz: '91161',
    },
    {
        id: '5133',
        ort: 'Hilscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54426',
    },
    {
        id: '5134',
        ort: 'Hilst',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66957',
    },
    {
        id: '5135',
        ort: 'Hiltenfingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86856',
    },
    {
        id: '5136',
        ort: 'Hilter',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49176',
    },
    {
        id: '5137',
        ort: 'Hiltpoltstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91355',
    },
    {
        id: '5138',
        ort: 'Hilzingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78247',
    },
    {
        id: '5139',
        ort: 'Himbergen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29584',
    },
    {
        id: '5140',
        ort: 'Himmelkron',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95502',
    },
    {
        id: '5141',
        ort: 'Himmelpforten',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21709',
    },
    {
        id: '5142',
        ort: 'Himmelstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97267',
    },
    {
        id: '5143',
        ort: 'Himmighofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '5144',
        ort: 'Hingstheide',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25563',
    },
    {
        id: '5145',
        ort: 'Hinrichshagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17498',
    },
    {
        id: '5146',
        ort: 'Hinte',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26759',
    },
    {
        id: '5147',
        ort: 'Hinterschmiding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94146',
    },
    {
        id: '5148',
        ort: 'Hintersee',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17375',
    },
    {
        id: '5149',
        ort: 'Hintertiefenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55743',
    },
    {
        id: '5150',
        ort: 'Hinterweidenthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66999',
    },
    {
        id: '5151',
        ort: 'Hinterweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '5152',
        ort: 'Hinterzarten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79856',
    },
    {
        id: '5153',
        ort: 'Hinzenburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54316',
    },
    {
        id: '5154',
        ort: 'Hinzert-Pölert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54421',
    },
    {
        id: '5155',
        ort: 'Hinzweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67756',
    },
    {
        id: '5156',
        ort: 'Hipstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27432',
    },
    {
        id: '5157',
        ort: 'Hirrlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72145',
    },
    {
        id: '5158',
        ort: 'Hirschaid',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96114',
    },
    {
        id: '5159',
        ort: 'Hirschau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92242',
    },
    {
        id: '5160',
        ort: 'Hirschbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92275',
    },
    {
        id: '5161',
        ort: 'Hirschberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65558',
    },
    {
        id: '5162',
        ort: 'Hirschberg',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07927',
    },
    {
        id: '5163',
        ort: 'Hirschberg an der Bergstraße',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69493',
    },
    {
        id: '5164',
        ort: 'Hirschfeld',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08144',
    },
    {
        id: '5165',
        ort: 'Hirschfeld',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04932',
    },
    {
        id: '5166',
        ort: 'Hirschfeld',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07554',
    },
    {
        id: '5167',
        ort: 'Hirschfeld (Hunsrück)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55483',
    },
    {
        id: '5168',
        ort: 'Hirschhorn',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '64757',
    },
    {
        id: '5169',
        ort: 'Hirschhorn',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '69412',
    },
    {
        id: '5170',
        ort: 'Hirschhorn',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '69434',
    },
    {
        id: '5171',
        ort: 'Hirschhorn/Pfalz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67732',
    },
    {
        id: '5172',
        ort: 'Hirschstein',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01594',
    },
    {
        id: '5173',
        ort: 'Hirschthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66996',
    },
    {
        id: '5174',
        ort: 'Hirten',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '5175',
        ort: 'Hirzenhain',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '63697',
    },
    {
        id: '5176',
        ort: 'Hirz-Maulsbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57635',
    },
    {
        id: '5177',
        ort: 'Hittbergen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21522',
    },
    {
        id: '5178',
        ort: 'Hitzacker',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29456',
    },
    {
        id: '5179',
        ort: 'Hitzhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85122',
    },
    {
        id: '5180',
        ort: 'Hitzhusen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24576',
    },
    {
        id: '5181',
        ort: 'Höchberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97204',
    },
    {
        id: '5182',
        ort: 'Hochborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '5183',
        ort: 'Hochdonn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25712',
    },
    {
        id: '5184',
        ort: 'Hochdorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73269',
    },
    {
        id: '5185',
        ort: 'Hochdorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88436',
    },
    {
        id: '5186',
        ort: 'Hochdorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88454',
    },
    {
        id: '5187',
        ort: 'Hochdorf-Assenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67126',
    },
    {
        id: '5188',
        ort: 'Höchenschwand',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79862',
    },
    {
        id: '5189',
        ort: 'Hochheim',
        bundesland: 'Hessen',
        landkreis: 'Main-Taunus-Kreis',
        plz: '65239',
    },
    {
        id: '5190',
        ort: 'Höchheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97633',
    },
    {
        id: '5191',
        ort: 'Hochkirch - Bukecy',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02627',
    },
    {
        id: '5192',
        ort: 'Hochscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54472',
    },
    {
        id: '5193',
        ort: 'Hochspeyer',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67691',
    },
    {
        id: '5194',
        ort: 'Höchstadt a.d.Aisch',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91315',
    },
    {
        id: '5195',
        ort: 'Hochstadt a.Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lichtenfels',
        plz: '96272',
    },
    {
        id: '5196',
        ort: 'Höchstädt an der Donau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89420',
    },
    {
        id: '5197',
        ort: 'Höchstädt im Fichtelgebirge',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Wunsiedel im Fichtelgebirge',
        plz: '95186',
    },
    {
        id: '5198',
        ort: 'Hochstadt (Pfalz)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76879',
    },
    {
        id: '5199',
        ort: 'Hochstätten',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55585',
    },
    {
        id: '5200',
        ort: 'Höchstberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '56767',
    },
    {
        id: '5201',
        ort: 'Höchstenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57629',
    },
    {
        id: '5202',
        ort: 'Hochstetten-Dhaun',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55606',
    },
    {
        id: '5203',
        ort: 'Höchst im Odenwald',
        bundesland: 'Hessen',
        landkreis: 'Odenwaldkreis',
        plz: '64739',
    },
    {
        id: '5204',
        ort: 'Hockenheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '68766',
    },
    {
        id: '5205',
        ort: 'Hockweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54316',
    },
    {
        id: '5206',
        ort: 'Hodenhagen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29693',
    },
    {
        id: '5207',
        ort: 'Hodorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25569',
    },
    {
        id: '5208',
        ort: 'Hof',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '95028',
    },
    {
        id: '5209',
        ort: 'Hof',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '95030',
    },
    {
        id: '5210',
        ort: 'Hof',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '95032',
    },
    {
        id: '5211',
        ort: 'Hof',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56472',
    },
    {
        id: '5212',
        ort: 'Hofbieber',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36145',
    },
    {
        id: '5213',
        ort: 'Höfen an der Enz',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '75339',
    },
    {
        id: '5214',
        ort: 'Hoffeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24582',
    },
    {
        id: '5215',
        ort: 'Hoffeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53534',
    },
    {
        id: '5216',
        ort: 'Hofgeismar',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34369',
    },
    {
        id: '5217',
        ort: 'Hofheim am Taunus',
        bundesland: 'Hessen',
        landkreis: 'Main-Taunus-Kreis',
        plz: '65719',
    },
    {
        id: '5218',
        ort: 'Hofheim in Unterfranken',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '97461',
    },
    {
        id: '5219',
        ort: 'Hofkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94544',
    },
    {
        id: '5220',
        ort: 'Hofstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77716',
    },
    {
        id: '5221',
        ort: 'Hofstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86928',
    },
    {
        id: '5222',
        ort: 'Högel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25858',
    },
    {
        id: '5223',
        ort: 'Högersdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23795',
    },
    {
        id: '5224',
        ort: 'Högsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24327',
    },
    {
        id: '5225',
        ort: 'Höhbeck',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29478',
    },
    {
        id: '5226',
        ort: 'Hohberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77749',
    },
    {
        id: '5227',
        ort: 'Hohe Börde',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39167',
    },
    {
        id: '5228',
        ort: 'Hohe Börde',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39326',
    },
    {
        id: '5229',
        ort: 'Hohe Börde',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39343',
    },
    {
        id: '5230',
        ort: 'Höheinöd',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66989',
    },
    {
        id: '5231',
        ort: 'Höheischweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66989',
    },
    {
        id: '5232',
        ort: 'Hohenahr',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35644',
    },
    {
        id: '5233',
        ort: 'Hohenaltheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86745',
    },
    {
        id: '5234',
        ort: 'Hohenaspe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25582',
    },
    {
        id: '5235',
        ort: 'Hohenau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94545',
    },
    {
        id: '5236',
        ort: 'Hohenberg an der Eger',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Wunsiedel im Fichtelgebirge',
        plz: '95691',
    },
    {
        id: '5237',
        ort: 'Hohenberg-Krusemark',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39596',
    },
    {
        id: '5238',
        ort: 'Hohenbocka',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01945',
    },
    {
        id: '5239',
        ort: 'Hohenbollentin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17111',
    },
    {
        id: '5240',
        ort: 'Hohenbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '85521',
    },
    {
        id: '5241',
        ort: 'Hohenbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '85662',
    },
    {
        id: '5242',
        ort: 'Hohenbucko',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04936',
    },
    {
        id: '5243',
        ort: 'Hohenburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92277',
    },
    {
        id: '5244',
        ort: 'Hohen Demzin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17166',
    },
    {
        id: '5245',
        ort: 'Hohendubrau - Wysoka Dubrawa',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02906',
    },
    {
        id: '5246',
        ort: 'Hohenfelde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24257',
    },
    {
        id: '5247',
        ort: 'Hohenfelde',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18209',
    },
    {
        id: '5248',
        ort: 'Hohenfelde',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18211',
    },
    {
        id: '5249',
        ort: 'Hohenfelde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25358',
    },
    {
        id: '5250',
        ort: 'Hohenfelde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22946',
    },
    {
        id: '5251',
        ort: 'Hohenfelden',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99448',
    },
    {
        id: '5252',
        ort: 'Hohenfels',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92366',
    },
    {
        id: '5253',
        ort: 'Hohenfels',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78355',
    },
    {
        id: '5254',
        ort: 'Hohenfels-Essingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '5255',
        ort: 'Hohenfinow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16248',
    },
    {
        id: '5256',
        ort: 'Hohenfurch',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '86978',
    },
    {
        id: '5257',
        ort: 'Hohengandern',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '5258',
        ort: 'Hohenhameln',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Peine',
        plz: '31249',
    },
    {
        id: '5259',
        ort: 'Hohenhorn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21526',
    },
    {
        id: '5260',
        ort: 'Hohenkammer',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85411',
    },
    {
        id: '5261',
        ort: 'Hohenkirchen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23968',
    },
    {
        id: '5262',
        ort: 'Höhenkirchen-Siegertsbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '85635',
    },
    {
        id: '5263',
        ort: 'Höhenland',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '16259',
    },
    {
        id: '5264',
        ort: 'Hohenleimbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '56746',
    },
    {
        id: '5265',
        ort: 'Hohenleipisch',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04934',
    },
    {
        id: '5266',
        ort: 'Hohenleuben',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07958',
    },
    {
        id: '5267',
        ort: 'Hohenlinden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85664',
    },
    {
        id: '5268',
        ort: 'Hohenlockstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25551',
    },
    {
        id: '5269',
        ort: 'Hohenmocker',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17111',
    },
    {
        id: '5270',
        ort: 'Hohenmölsen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06679',
    },
    {
        id: '5271',
        ort: 'Hohen Neuendorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16540',
    },
    {
        id: '5272',
        ort: 'Hohen Neuendorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16556',
    },
    {
        id: '5273',
        ort: 'Hohen Neuendorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16562',
    },
    {
        id: '5274',
        ort: 'Hohenöllen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67744',
    },
    {
        id: '5275',
        ort: 'Hohenpeißenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82383',
    },
    {
        id: '5276',
        ort: 'Hohenpolding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '84432',
    },
    {
        id: '5277',
        ort: 'Hohen Pritz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19406',
    },
    {
        id: '5278',
        ort: 'Hohenroda',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36284',
    },
    {
        id: '5279',
        ort: 'Hohenroth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97618',
    },
    {
        id: '5280',
        ort: 'Hohenselchow-Groß Pinnow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '16306',
    },
    {
        id: '5281',
        ort: 'Hohen Sprenz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18299',
    },
    {
        id: '5282',
        ort: 'Hohenstadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73345',
    },
    {
        id: '5283',
        ort: 'Hohenstein',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65329',
    },
    {
        id: '5284',
        ort: 'Hohenstein',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Nordhausen',
        plz: '99755',
    },
    {
        id: '5285',
        ort: 'Hohenstein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72531',
    },
    {
        id: '5286',
        ort: 'Hohenstein-Ernstthal',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '09337',
    },
    {
        id: '5287',
        ort: 'Hohen-Sülzen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67591',
    },
    {
        id: '5288',
        ort: 'Hohentengen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '88367',
    },
    {
        id: '5289',
        ort: 'Hohentengen am Hochrhein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79801',
    },
    {
        id: '5290',
        ort: 'Hohenthann',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84098',
    },
    {
        id: '5291',
        ort: 'Hohen Viecheln',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23996',
    },
    {
        id: '5292',
        ort: 'Hohen Wangelin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17194',
    },
    {
        id: '5293',
        ort: 'Hohenwart',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '86558',
    },
    {
        id: '5294',
        ort: 'Hohenwarte',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07338',
    },
    {
        id: '5295',
        ort: 'Hohenwarth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93480',
    },
    {
        id: '5296',
        ort: 'Hohenwestedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24594',
    },
    {
        id: '5297',
        ort: 'Hohenzieritz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17237',
    },
    {
        id: '5298',
        ort: 'Hohes Kreuz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37308',
    },
    {
        id: '5299',
        ort: 'Höhfröschen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66989',
    },
    {
        id: '5300',
        ort: 'Hohn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24806',
    },
    {
        id: '5301',
        ort: 'Höhn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56462',
    },
    {
        id: '5302',
        ort: 'Hohndorf',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09394',
    },
    {
        id: '5303',
        ort: 'Höhndorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24217',
    },
    {
        id: '5304',
        ort: 'Hohne',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29362',
    },
    {
        id: '5305',
        ort: 'Hohnhorst',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31559',
    },
    {
        id: '5306',
        ort: 'Hohnstein',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01848',
    },
    {
        id: '5307',
        ort: 'Hohnstorf (Elbe)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21522',
    },
    {
        id: '5308',
        ort: 'Höhr-Grenzhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56203',
    },
    {
        id: '5309',
        ort: 'Hohwacht',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24321',
    },
    {
        id: '5310',
        ort: 'Hoisdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22955',
    },
    {
        id: '5311',
        ort: 'Hoisdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22961',
    },
    {
        id: '5312',
        ort: 'Holdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Vechta',
        plz: '49451',
    },
    {
        id: '5313',
        ort: 'Holdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19205',
    },
    {
        id: '5314',
        ort: 'Holdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19217',
    },
    {
        id: '5315',
        ort: 'Holenberg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37642',
    },
    {
        id: '5316',
        ort: 'Holldorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17094',
    },
    {
        id: '5317',
        ort: 'Holle',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31188',
    },
    {
        id: '5318',
        ort: 'Hollenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86568',
    },
    {
        id: '5319',
        ort: 'Hollenbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23883',
    },
    {
        id: '5320',
        ort: 'Hollenstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21279',
    },
    {
        id: '5321',
        ort: 'Holler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '5322',
        ort: 'Hollern-Twielenfleth',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21723',
    },
    {
        id: '5323',
        ort: 'Hollfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '96142',
    },
    {
        id: '5324',
        ort: 'Hollingstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25788',
    },
    {
        id: '5325',
        ort: 'Hollingstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24876',
    },
    {
        id: '5326',
        ort: 'Hollnich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56288',
    },
    {
        id: '5327',
        ort: 'Hollnseth',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21769',
    },
    {
        id: '5328',
        ort: 'Hollstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97618',
    },
    {
        id: '5329',
        ort: 'Holm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25488',
    },
    {
        id: '5330',
        ort: 'Holm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25923',
    },
    {
        id: '5331',
        ort: 'Holste',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osterholz',
        plz: '27729',
    },
    {
        id: '5332',
        ort: 'Holstenniendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25584',
    },
    {
        id: '5333',
        ort: 'Holsthum',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54668',
    },
    {
        id: '5334',
        ort: 'Holt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24994',
    },
    {
        id: '5335',
        ort: 'Holtgast',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26427',
    },
    {
        id: '5336',
        ort: 'Holthusen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19075',
    },
    {
        id: '5337',
        ort: 'Holtland',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26835',
    },
    {
        id: '5338',
        ort: 'Holtsee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24363',
    },
    {
        id: '5339',
        ort: 'Holzappel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56379',
    },
    {
        id: '5340',
        ort: 'Holzbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55469',
    },
    {
        id: '5341',
        ort: 'Holzbunge',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24361',
    },
    {
        id: '5342',
        ort: 'Holzdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24364',
    },
    {
        id: '5343',
        ort: 'Holzen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37632',
    },
    {
        id: '5344',
        ort: 'Holzerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54316',
    },
    {
        id: '5345',
        ort: 'Holzgerlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71088',
    },
    {
        id: '5346',
        ort: 'Holzgünz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87752',
    },
    {
        id: '5347',
        ort: 'Holzhausen an der Haide',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '5348',
        ort: 'Holzheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65558',
    },
    {
        id: '5349',
        ort: 'Holzheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89291',
    },
    {
        id: '5350',
        ort: 'Holzheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89438',
    },
    {
        id: '5351',
        ort: 'Holzheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86684',
    },
    {
        id: '5352',
        ort: 'Holzheim am Forst',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93183',
    },
    {
        id: '5353',
        ort: 'Holzkirch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89183',
    },
    {
        id: '5354',
        ort: 'Holzkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97292',
    },
    {
        id: '5355',
        ort: 'Holzkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miesbach',
        plz: '83607',
    },
    {
        id: '5356',
        ort: 'Holzmaden',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73271',
    },
    {
        id: '5357',
        ort: 'Holzminden',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37603',
    },
    {
        id: '5358',
        ort: 'Holzsußra',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '99713',
    },
    {
        id: '5359',
        ort: 'Holzwickede',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Unna',
        plz: '59439',
    },
    {
        id: '5360',
        ort: 'Homberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '5361',
        ort: 'Homberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67742',
    },
    {
        id: '5362',
        ort: 'Homberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67744',
    },
    {
        id: '5363',
        ort: 'Hömberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56379',
    },
    {
        id: '5364',
        ort: 'Homberg (Efze)',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34576',
    },
    {
        id: '5365',
        ort: 'Homberg (Ohm)',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '35315',
    },
    {
        id: '5366',
        ort: 'Homburg',
        bundesland: 'Saarland',
        landkreis: 'Saarpfalz-Kreis',
        plz: '66424',
    },
    {
        id: '5367',
        ort: 'Hommerdingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '5368',
        ort: 'Honerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53518',
    },
    {
        id: '5369',
        ort: 'Honigsee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24211',
    },
    {
        id: '5370',
        ort: 'Hönningen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53506',
    },
    {
        id: '5371',
        ort: 'Hontheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54538',
    },
    {
        id: '5372',
        ort: 'Hoogstede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '49846',
    },
    {
        id: '5373',
        ort: 'Hoort',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '5374',
        ort: 'Hopferau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87659',
    },
    {
        id: '5375',
        ort: 'Höpfingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74746',
    },
    {
        id: '5376',
        ort: 'Hoppegarten',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15366',
    },
    {
        id: '5377',
        ort: 'Hoppenrade',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18292',
    },
    {
        id: '5378',
        ort: 'Hoppstädten',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67744',
    },
    {
        id: '5379',
        ort: 'Hoppstädten-Weiersbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55768',
    },
    {
        id: '5380',
        ort: 'Hopsten',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '48496',
    },
    {
        id: '5381',
        ort: 'Horath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54497',
    },
    {
        id: '5382',
        ort: 'Horbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '5383',
        ort: 'Horbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66851',
    },
    {
        id: '5384',
        ort: 'Horbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55606',
    },
    {
        id: '5385',
        ort: 'Horb am Neckar',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Freudenstadt',
        plz: '72160',
    },
    {
        id: '5386',
        ort: 'Horben',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79289',
    },
    {
        id: '5387',
        ort: 'Horbruch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55483',
    },
    {
        id: '5388',
        ort: 'Hörden am Harz',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37412',
    },
    {
        id: '5389',
        ort: 'Hördt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76771',
    },
    {
        id: '5390',
        ort: 'Horgau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86497',
    },
    {
        id: '5391',
        ort: 'Horgenzell',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88263',
    },
    {
        id: '5392',
        ort: 'Hörgertshausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85413',
    },
    {
        id: '5393',
        ort: 'Horhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56379',
    },
    {
        id: '5394',
        ort: 'Horhausen (Westerwald)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '56593',
    },
    {
        id: '5395',
        ort: 'Höringen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67724',
    },
    {
        id: '5396',
        ort: 'Horka',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02923',
    },
    {
        id: '5397',
        ort: 'Horn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55469',
    },
    {
        id: '5398',
        ort: 'Hornbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66500',
    },
    {
        id: '5399',
        ort: 'Horn-Bad Meinberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '32805',
    },
    {
        id: '5400',
        ort: 'Hornbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21514',
    },
    {
        id: '5401',
        ort: 'Hornberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '78132',
    },
    {
        id: '5402',
        ort: 'Horneburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21640',
    },
    {
        id: '5403',
        ort: 'Hornsömmern',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99955',
    },
    {
        id: '5404',
        ort: 'Hornstorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23970',
    },
    {
        id: '5405',
        ort: 'Hornstorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23974',
    },
    {
        id: '5406',
        ort: 'Hörnum (Sylt)',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25997',
    },
    {
        id: '5407',
        ort: 'Horperath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '56766',
    },
    {
        id: '5408',
        ort: 'Horrweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55457',
    },
    {
        id: '5409',
        ort: 'Horschbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66887',
    },
    {
        id: '5410',
        ort: 'Hörscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '5411',
        ort: 'Hörschhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '5412',
        ort: 'Hörsel',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99869',
    },
    {
        id: '5413',
        ort: 'Hörsel',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99880',
    },
    {
        id: '5414',
        ort: 'Hörselberg-Hainich',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '99820',
    },
    {
        id: '5415',
        ort: 'Horst',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25358',
    },
    {
        id: '5416',
        ort: 'Horst',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23883',
    },
    {
        id: '5417',
        ort: 'Horstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27367',
    },
    {
        id: '5418',
        ort: 'Horstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25860',
    },
    {
        id: '5419',
        ort: 'Hörstel',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '48477',
    },
    {
        id: '5420',
        ort: 'Hörsten',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24797',
    },
    {
        id: '5421',
        ort: 'Horstmar',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '48612',
    },
    {
        id: '5422',
        ort: 'Hörup',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24980',
    },
    {
        id: '5423',
        ort: 'Hösbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63768',
    },
    {
        id: '5424',
        ort: 'Hosenfeld',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36154',
    },
    {
        id: '5425',
        ort: 'Höslwang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83129',
    },
    {
        id: '5426',
        ort: 'Hoßkirch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88374',
    },
    {
        id: '5427',
        ort: 'Hosten',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54664',
    },
    {
        id: '5428',
        ort: 'Hötensleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39393',
    },
    {
        id: '5429',
        ort: 'Hottenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '5430',
        ort: 'Höttingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91798',
    },
    {
        id: '5431',
        ort: 'Hövede',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25782',
    },
    {
        id: '5432',
        ort: 'Hövelhof',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Paderborn',
        plz: '33161',
    },
    {
        id: '5433',
        ort: 'Hövels',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57537',
    },
    {
        id: '5434',
        ort: 'Höxter',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Höxter',
        plz: '37671',
    },
    {
        id: '5435',
        ort: 'Hoya',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '27318',
    },
    {
        id: '5436',
        ort: 'Hoyerhagen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '27318',
    },
    {
        id: '5437',
        ort: 'Hoyerswerda - Wojerecy',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02977',
    },
    {
        id: '5438',
        ort: 'Hübingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '5439',
        ort: 'Hüblingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '5440',
        ort: 'Hückelhoven',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Heinsberg',
        plz: '41836',
    },
    {
        id: '5441',
        ort: 'Hückeswagen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Oberbergischer Kreis',
        plz: '42499',
    },
    {
        id: '5442',
        ort: 'Hude',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25876',
    },
    {
        id: '5443',
        ort: 'Hüde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49448',
    },
    {
        id: '5444',
        ort: 'Hude (Oldenburg)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Oldenburg',
        plz: '27798',
    },
    {
        id: '5445',
        ort: 'Hüffelsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '5446',
        ort: 'Hüffenhardt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74928',
    },
    {
        id: '5447',
        ort: 'Hüffler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66909',
    },
    {
        id: '5448',
        ort: 'Hüfingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78183',
    },
    {
        id: '5449',
        ort: 'Hügelsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '76549',
    },
    {
        id: '5450',
        ort: 'Huglfing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82386',
    },
    {
        id: '5451',
        ort: 'Hugoldsdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18465',
    },
    {
        id: '5452',
        ort: 'Huisheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86685',
    },
    {
        id: '5453',
        ort: 'Huje',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25588',
    },
    {
        id: '5454',
        ort: 'Hülben',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72584',
    },
    {
        id: '5455',
        ort: 'Hüllhorst',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Minden-Lübbecke',
        plz: '32609',
    },
    {
        id: '5456',
        ort: 'Hülseburg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '5457',
        ort: 'Hülsede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31867',
    },
    {
        id: '5458',
        ort: 'Hümmel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53520',
    },
    {
        id: '5459',
        ort: 'Hummelfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24357',
    },
    {
        id: '5460',
        ort: 'Hummelshain',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07768',
    },
    {
        id: '5461',
        ort: 'Hummeltal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95503',
    },
    {
        id: '5462',
        ort: 'Hümmerich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53547',
    },
    {
        id: '5463',
        ort: 'Humptrup',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25923',
    },
    {
        id: '5464',
        ort: 'Hunderdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94336',
    },
    {
        id: '5465',
        ort: 'Hundhaupten',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07557',
    },
    {
        id: '5466',
        ort: 'Hunding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94551',
    },
    {
        id: '5467',
        ort: 'Hundsangen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56414',
    },
    {
        id: '5468',
        ort: 'Hundsbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55621',
    },
    {
        id: '5469',
        ort: 'Hundsdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56235',
    },
    {
        id: '5470',
        ort: 'Hünfeld',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36088',
    },
    {
        id: '5471',
        ort: 'Hünfelden',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65597',
    },
    {
        id: '5472',
        ort: 'Hungen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35410',
    },
    {
        id: '5473',
        ort: 'Hungenroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56281',
    },
    {
        id: '5474',
        ort: 'Hünstetten',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65510',
    },
    {
        id: '5475',
        ort: 'Hünxe',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '46569',
    },
    {
        id: '5476',
        ort: 'Hunzel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56355',
    },
    {
        id: '5477',
        ort: 'Hupperath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '5478',
        ort: 'Hurlach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86857',
    },
    {
        id: '5479',
        ort: 'Hürtgenwald',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Düren',
        plz: '52393',
    },
    {
        id: '5480',
        ort: 'Hürth',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Erft-Kreis',
        plz: '50354',
    },
    {
        id: '5481',
        ort: 'Hürup',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24975',
    },
    {
        id: '5482',
        ort: 'Husby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24975',
    },
    {
        id: '5483',
        ort: 'Hüsby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24850',
    },
    {
        id: '5484',
        ort: 'Husum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31632',
    },
    {
        id: '5485',
        ort: 'Husum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25813',
    },
    {
        id: '5486',
        ort: 'Hütschenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66882',
    },
    {
        id: '5487',
        ort: 'Hüttblek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24641',
    },
    {
        id: '5488',
        ort: 'Hütten',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24358',
    },
    {
        id: '5489',
        ort: 'Hütten',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '5490',
        ort: 'Hüttenberg',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35625',
    },
    {
        id: '5491',
        ort: 'Hütterscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '5492',
        ort: 'Hutthurm',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94116',
    },
    {
        id: '5493',
        ort: 'Hüttingen an der Kyll',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '5494',
        ort: 'Hüttingen bei Lahr',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '5495',
        ort: 'Hüttisheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89185',
    },
    {
        id: '5496',
        ort: 'Hüttlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73434',
    },
    {
        id: '5497',
        ort: 'Hüttlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73453',
    },
    {
        id: '5498',
        ort: 'Hüttlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73460',
    },
    {
        id: '5499',
        ort: 'Hüven',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49751',
    },
    {
        id: '5500',
        ort: 'Huy',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38836',
    },
    {
        id: '5501',
        ort: 'Huy',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38838',
    },
    {
        id: '5502',
        ort: 'Ibach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79837',
    },
    {
        id: '5503',
        ort: 'Ibbenbüren',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '49477',
    },
    {
        id: '5504',
        ort: 'Ibbenbüren',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '49479',
    },
    {
        id: '5505',
        ort: 'Ichenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89335',
    },
    {
        id: '5506',
        ort: 'Icking',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '82057',
    },
    {
        id: '5507',
        ort: 'Idar-Oberstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55743',
    },
    {
        id: '5508',
        ort: 'Idelberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57612',
    },
    {
        id: '5509',
        ort: 'Iden',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39606',
    },
    {
        id: '5510',
        ort: 'Idenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '5511',
        ort: 'Idesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '5512',
        ort: 'Idstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24879',
    },
    {
        id: '5513',
        ort: 'Idstein',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65510',
    },
    {
        id: '5514',
        ort: 'Iffeldorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82393',
    },
    {
        id: '5515',
        ort: 'Iffezheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '76473',
    },
    {
        id: '5516',
        ort: 'Igel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54298',
    },
    {
        id: '5517',
        ort: 'Igensdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91338',
    },
    {
        id: '5518',
        ort: 'Igersheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Main-Tauber-Kreis',
        plz: '97999',
    },
    {
        id: '5519',
        ort: 'Iggensbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94547',
    },
    {
        id: '5520',
        ort: 'Iggingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73574',
    },
    {
        id: '5521',
        ort: 'Igling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86859',
    },
    {
        id: '5522',
        ort: 'Ihlienworth',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21775',
    },
    {
        id: '5523',
        ort: 'Ihlow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Teltow-Fläming',
        plz: '15936',
    },
    {
        id: '5524',
        ort: 'Ihlow',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26632',
    },
    {
        id: '5525',
        ort: 'Ihringen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79241',
    },
    {
        id: '5526',
        ort: 'Ihrlerstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '93346',
    },
    {
        id: '5527',
        ort: 'Ilberstedt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '06408',
    },
    {
        id: '5528',
        ort: 'Ilbesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67294',
    },
    {
        id: '5529',
        ort: 'Ilbesheim bei Landau in der Pfalz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76831',
    },
    {
        id: '5530',
        ort: 'Illerich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56814',
    },
    {
        id: '5531',
        ort: 'Illerkirchberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89171',
    },
    {
        id: '5532',
        ort: 'Illerrieden',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89186',
    },
    {
        id: '5533',
        ort: 'Illertissen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89257',
    },
    {
        id: '5534',
        ort: 'Illesheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91471',
    },
    {
        id: '5535',
        ort: 'Illingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75428',
    },
    {
        id: '5536',
        ort: 'Illingen',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Neunkirchen',
        plz: '66557',
    },
    {
        id: '5537',
        ort: 'Illmensee',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '88636',
    },
    {
        id: '5538',
        ort: 'Illschwang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92278',
    },
    {
        id: '5539',
        ort: 'Ilmenau',
        bundesland: 'Thüringen',
        landkreis: 'Ilm-Kreis',
        plz: '98693',
    },
    {
        id: '5540',
        ort: 'Ilmenau',
        bundesland: 'Thüringen',
        landkreis: 'Ilm-Kreis',
        plz: '98694',
    },
    {
        id: '5541',
        ort: 'Ilmmünster',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85304',
    },
    {
        id: '5542',
        ort: 'Ilmtal-Weinstraße',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99510',
    },
    {
        id: '5543',
        ort: 'Ilsede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Peine',
        plz: '31241',
    },
    {
        id: '5544',
        ort: 'Ilsede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Peine',
        plz: '31246',
    },
    {
        id: '5545',
        ort: 'Ilsenburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38871',
    },
    {
        id: '5546',
        ort: 'Ilsfeld',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74360',
    },
    {
        id: '5547',
        ort: 'Ilshofen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74532',
    },
    {
        id: '5548',
        ort: 'Ilvesheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '68549',
    },
    {
        id: '5549',
        ort: 'Immendingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78194',
    },
    {
        id: '5550',
        ort: 'Immenhausen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34376',
    },
    {
        id: '5551',
        ort: 'Immenreuth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95505',
    },
    {
        id: '5552',
        ort: 'Immenstaad am Bodensee',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88090',
    },
    {
        id: '5553',
        ort: 'Immenstadt im Allgäu',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87509',
    },
    {
        id: '5554',
        ort: 'Immenstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25767',
    },
    {
        id: '5555',
        ort: 'Immenstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25885',
    },
    {
        id: '5556',
        ort: 'Immerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '5557',
        ort: 'Immert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54426',
    },
    {
        id: '5558',
        ort: 'Immesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67308',
    },
    {
        id: '5559',
        ort: 'Impflingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76831',
    },
    {
        id: '5560',
        ort: 'Imsbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67725',
    },
    {
        id: '5561',
        ort: 'Imsbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67808',
    },
    {
        id: '5562',
        ort: 'Imsbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67817',
    },
    {
        id: '5563',
        ort: 'Imsweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67806',
    },
    {
        id: '5564',
        ort: 'Imsweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67808',
    },
    {
        id: '5565',
        ort: 'Inchenhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86570',
    },
    {
        id: '5566',
        ort: 'Inden',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Düren',
        plz: '52459',
    },
    {
        id: '5567',
        ort: 'Ingelbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57610',
    },
    {
        id: '5568',
        ort: 'Ingelfingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Hohenlohekreis',
        plz: '74653',
    },
    {
        id: '5569',
        ort: 'Ingelheim am Rhein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55218',
    },
    {
        id: '5570',
        ort: 'Ingelheim am Rhein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55262',
    },
    {
        id: '5571',
        ort: 'Ingelheim am Rhein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55263',
    },
    {
        id: '5572',
        ort: 'Ingendorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '5573',
        ort: 'Ingenried',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '86980',
    },
    {
        id: '5574',
        ort: 'Ingersheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '74379',
    },
    {
        id: '5575',
        ort: 'Ingersleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39343',
    },
    {
        id: '5576',
        ort: 'Ingoldingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88456',
    },
    {
        id: '5577',
        ort: 'Ingolstadt',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '85049',
    },
    {
        id: '5578',
        ort: 'Ingolstadt',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '85051',
    },
    {
        id: '5579',
        ort: 'Ingolstadt',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '85053',
    },
    {
        id: '5580',
        ort: 'Ingolstadt',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '85055',
    },
    {
        id: '5581',
        ort: 'Ingolstadt',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '85057',
    },
    {
        id: '5582',
        ort: 'Innernzell',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94548',
    },
    {
        id: '5583',
        ort: 'Inning am Ammersee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Starnberg',
        plz: '82266',
    },
    {
        id: '5584',
        ort: 'Inning am Holz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '84416',
    },
    {
        id: '5585',
        ort: 'Insel Hiddensee',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18565',
    },
    {
        id: '5586',
        ort: 'Insel Poel',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23999',
    },
    {
        id: '5587',
        ort: 'Insheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76865',
    },
    {
        id: '5588',
        ort: 'Insingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91610',
    },
    {
        id: '5589',
        ort: 'Insul',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53520',
    },
    {
        id: '5590',
        ort: 'Inzell',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83334',
    },
    {
        id: '5591',
        ort: 'Inzigkofen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '72514',
    },
    {
        id: '5592',
        ort: 'Inzlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79594',
    },
    {
        id: '5593',
        ort: 'Iphofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97346',
    },
    {
        id: '5594',
        ort: 'Ippenschied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55566',
    },
    {
        id: '5595',
        ort: 'Ippesheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '97258',
    },
    {
        id: '5596',
        ort: 'Ipsheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91472',
    },
    {
        id: '5597',
        ort: 'Irchenrieth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92699',
    },
    {
        id: '5598',
        ort: 'Irlbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94342',
    },
    {
        id: '5599',
        ort: 'Irmenach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '56843',
    },
    {
        id: '5600',
        ort: 'Irmtraut',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '5601',
        ort: 'Irndorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78597',
    },
    {
        id: '5602',
        ort: 'Irrel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54666',
    },
    {
        id: '5603',
        ort: 'Irrhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54689',
    },
    {
        id: '5604',
        ort: 'Irsch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54451',
    },
    {
        id: '5605',
        ort: 'Irschenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miesbach',
        plz: '83737',
    },
    {
        id: '5606',
        ort: 'Irsee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87660',
    },
    {
        id: '5607',
        ort: 'Isen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '84424',
    },
    {
        id: '5608',
        ort: 'Isenburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56271',
    },
    {
        id: '5609',
        ort: 'Isenbüttel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38550',
    },
    {
        id: '5610',
        ort: 'Iserlohn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58636',
    },
    {
        id: '5611',
        ort: 'Iserlohn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58638',
    },
    {
        id: '5612',
        ort: 'Iserlohn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58640',
    },
    {
        id: '5613',
        ort: 'Iserlohn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58642',
    },
    {
        id: '5614',
        ort: 'Iserlohn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58644',
    },
    {
        id: '5615',
        ort: 'Isernhagen',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30916',
    },
    {
        id: '5616',
        ort: 'Isert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57612',
    },
    {
        id: '5617',
        ort: 'Ismaning',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '85737',
    },
    {
        id: '5618',
        ort: 'Isny im Allgäu',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88316',
    },
    {
        id: '5619',
        ort: 'Ispringen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75228',
    },
    {
        id: '5620',
        ort: 'Isselbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65558',
    },
    {
        id: '5621',
        ort: 'Isselburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '46419',
    },
    {
        id: '5622',
        ort: 'Issigau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95188',
    },
    {
        id: '5623',
        ort: 'Issum',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '47661',
    },
    {
        id: '5624',
        ort: 'Isterberg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '48465',
    },
    {
        id: '5625',
        ort: 'Itterbeck',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '49847',
    },
    {
        id: '5626',
        ort: 'Ittlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74930',
    },
    {
        id: '5627',
        ort: 'Itzehoe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25524',
    },
    {
        id: '5628',
        ort: 'Itzgrund',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Coburg',
        plz: '96274',
    },
    {
        id: '5629',
        ort: 'Itzstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23845',
    },
    {
        id: '5630',
        ort: 'Iven',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17391',
    },
    {
        id: '5631',
        ort: 'Ivenack',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17153',
    },
    {
        id: '5632',
        ort: 'Jabel',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17194',
    },
    {
        id: '5633',
        ort: 'Jachenau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '83676',
    },
    {
        id: '5634',
        ort: 'Jacobsdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15236',
    },
    {
        id: '5635',
        ort: 'Jade',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wesermarsch',
        plz: '26349',
    },
    {
        id: '5636',
        ort: 'Jagel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24878',
    },
    {
        id: '5637',
        ort: 'Jagsthausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74249',
    },
    {
        id: '5638',
        ort: 'Jagstzell',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73489',
    },
    {
        id: '5639',
        ort: 'Jahnatal',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '04749',
    },
    {
        id: '5640',
        ort: 'Jahnsdorf/Erzgebirge',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09387',
    },
    {
        id: '5641',
        ort: 'Jahrsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24594',
    },
    {
        id: '5642',
        ort: 'Jakobsdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18442',
    },
    {
        id: '5643',
        ort: 'Jakobsweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67814',
    },
    {
        id: '5644',
        ort: 'Jameln',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29479',
    },
    {
        id: '5645',
        ort: 'Jamlitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15868',
    },
    {
        id: '5646',
        ort: 'Jämlitz-Klein Düben',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03130',
    },
    {
        id: '5647',
        ort: 'Jandelsbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94118',
    },
    {
        id: '5648',
        ort: 'Janneby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24992',
    },
    {
        id: '5649',
        ort: 'Jänschwalde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03172',
    },
    {
        id: '5650',
        ort: 'Jänschwalde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03197',
    },
    {
        id: '5651',
        ort: 'Jardelund',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24994',
    },
    {
        id: '5652',
        ort: 'Jarmen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17126',
    },
    {
        id: '5653',
        ort: 'Jatznick',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17309',
    },
    {
        id: '5654',
        ort: 'Jeckenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55592',
    },
    {
        id: '5655',
        ort: 'Jelmstorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29585',
    },
    {
        id: '5656',
        ort: 'Jembke',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38477',
    },
    {
        id: '5657',
        ort: 'Jemgum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26844',
    },
    {
        id: '5658',
        ort: 'Jena',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '07743',
    },
    {
        id: '5659',
        ort: 'Jena',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '07745',
    },
    {
        id: '5660',
        ort: 'Jena',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '07747',
    },
    {
        id: '5661',
        ort: 'Jena',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '07749',
    },
    {
        id: '5662',
        ort: 'Jena',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '07751',
    },
    {
        id: '5663',
        ort: 'Jenalöbnitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07751',
    },
    {
        id: '5664',
        ort: 'Jengen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '86860',
    },
    {
        id: '5665',
        ort: 'Jerichow',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Jerichower Land',
        plz: '39307',
    },
    {
        id: '5666',
        ort: 'Jerichow',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Jerichower Land',
        plz: '39319',
    },
    {
        id: '5667',
        ort: 'Jerrishoe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24963',
    },
    {
        id: '5668',
        ort: 'Jersbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22941',
    },
    {
        id: '5669',
        ort: 'Jerxheim',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38381',
    },
    {
        id: '5670',
        ort: 'Jesberg',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34632',
    },
    {
        id: '5671',
        ort: 'Jesendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19417',
    },
    {
        id: '5672',
        ort: 'Jesenwang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82287',
    },
    {
        id: '5673',
        ort: 'Jesewitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04838',
    },
    {
        id: '5674',
        ort: 'Jessen (Elster)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Wittenberg',
        plz: '06917',
    },
    {
        id: '5675',
        ort: 'Jesteburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21266',
    },
    {
        id: '5676',
        ort: 'Jestetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79798',
    },
    {
        id: '5677',
        ort: 'Jettenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84555',
    },
    {
        id: '5678',
        ort: 'Jettenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66887',
    },
    {
        id: '5679',
        ort: 'Jettingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71131',
    },
    {
        id: '5680',
        ort: 'Jettingen-Scheppach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89343',
    },
    {
        id: '5681',
        ort: 'Jetzendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85305',
    },
    {
        id: '5682',
        ort: 'Jevenstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24808',
    },
    {
        id: '5683',
        ort: 'Jever',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Friesland',
        plz: '26441',
    },
    {
        id: '5684',
        ort: 'Joachimsthal',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16247',
    },
    {
        id: '5685',
        ort: 'Jockgrim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76751',
    },
    {
        id: '5686',
        ort: 'Johannesberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63867',
    },
    {
        id: '5687',
        ort: 'Johanngeorgenstadt',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '08349',
    },
    {
        id: '5688',
        ort: 'Johanniskirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84381',
    },
    {
        id: '5689',
        ort: 'Jöhstadt',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09477',
    },
    {
        id: '5690',
        ort: 'Joldelund',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25862',
    },
    {
        id: '5691',
        ort: 'Jonaswalde',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04626',
    },
    {
        id: '5692',
        ort: 'Jonsdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02796',
    },
    {
        id: '5693',
        ort: 'Jördenstorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17168',
    },
    {
        id: '5694',
        ort: 'Jork',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21635',
    },
    {
        id: '5695',
        ort: 'Jörl',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24992',
    },
    {
        id: '5696',
        ort: 'Jossgrund',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63637',
    },
    {
        id: '5697',
        ort: 'Jübar',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Altmarkkreis Salzwedel',
        plz: '38489',
    },
    {
        id: '5698',
        ort: 'Jübek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24855',
    },
    {
        id: '5699',
        ort: 'Jüchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41363',
    },
    {
        id: '5700',
        ort: 'Jucken',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54689',
    },
    {
        id: '5701',
        ort: 'Jugenheim in Rheinhessen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55270',
    },
    {
        id: '5702',
        ort: 'Jühnde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37127',
    },
    {
        id: '5703',
        ort: 'Juist',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26571',
    },
    {
        id: '5704',
        ort: 'Julbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84387',
    },
    {
        id: '5705',
        ort: 'Jülich',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Düren',
        plz: '52428',
    },
    {
        id: '5706',
        ort: 'Juliusburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21483',
    },
    {
        id: '5707',
        ort: 'Jungingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72417',
    },
    {
        id: '5708',
        ort: 'Jünkerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54584',
    },
    {
        id: '5709',
        ort: 'Jürgenshagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18246',
    },
    {
        id: '5710',
        ort: 'Jürgenstorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17153',
    },
    {
        id: '5711',
        ort: 'Jüterbog',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Teltow-Fläming',
        plz: '14913',
    },
    {
        id: '5712',
        ort: 'Kaaks',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25582',
    },
    {
        id: '5713',
        ort: 'Kaarst',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41564',
    },
    {
        id: '5714',
        ort: 'Kabelhorst',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23738',
    },
    {
        id: '5715',
        ort: 'Kabelsketal',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06184',
    },
    {
        id: '5716',
        ort: 'Käbschütztal',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01665',
    },
    {
        id: '5717',
        ort: 'Kaden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '5718',
        ort: 'Kadenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56337',
    },
    {
        id: '5719',
        ort: 'Kahla',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07768',
    },
    {
        id: '5720',
        ort: 'Kahl am Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63796',
    },
    {
        id: '5721',
        ort: 'Kaifenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56761',
    },
    {
        id: '5722',
        ort: 'Kail',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56829',
    },
    {
        id: '5723',
        ort: 'Kaisborstel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25560',
    },
    {
        id: '5724',
        ort: 'Kaiserpfalz',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06642',
    },
    {
        id: '5725',
        ort: 'Kaisersbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '73553',
    },
    {
        id: '5726',
        ort: 'Kaisersbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '73642',
    },
    {
        id: '5727',
        ort: 'Kaisersbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '73667',
    },
    {
        id: '5728',
        ort: 'Kaisersesch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56759',
    },
    {
        id: '5729',
        ort: 'Kaiserslautern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67655',
    },
    {
        id: '5730',
        ort: 'Kaiserslautern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67657',
    },
    {
        id: '5731',
        ort: 'Kaiserslautern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67659',
    },
    {
        id: '5732',
        ort: 'Kaiserslautern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67661',
    },
    {
        id: '5733',
        ort: 'Kaiserslautern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67663',
    },
    {
        id: '5734',
        ort: 'Kaiser-Wilhelm-Koog',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25709',
    },
    {
        id: '5735',
        ort: 'Kaisheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86687',
    },
    {
        id: '5736',
        ort: 'Kakenstorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21255',
    },
    {
        id: '5737',
        ort: 'Kalbach',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36148',
    },
    {
        id: '5738',
        ort: 'Kalbe',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27419',
    },
    {
        id: '5739',
        ort: 'Kalbe (Milde)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Altmarkkreis Salzwedel',
        plz: '39624',
    },
    {
        id: '5740',
        ort: 'Kalbsrieth',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '06556',
    },
    {
        id: '5741',
        ort: 'Kalchreuth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '90562',
    },
    {
        id: '5742',
        ort: 'Kalefeld',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Northeim',
        plz: '37589',
    },
    {
        id: '5743',
        ort: 'Kalenborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56759',
    },
    {
        id: '5744',
        ort: 'Kalenborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53505',
    },
    {
        id: '5745',
        ort: 'Kalenborn-Scheuern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '5746',
        ort: 'Kalkar',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '47546',
    },
    {
        id: '5747',
        ort: 'Kalkhorst',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23942',
    },
    {
        id: '5748',
        ort: 'Kalkhorst',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23948',
    },
    {
        id: '5749',
        ort: 'Kalkofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67822',
    },
    {
        id: '5750',
        ort: 'Kall',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Euskirchen',
        plz: '53925',
    },
    {
        id: '5751',
        ort: 'Kalletal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '32689',
    },
    {
        id: '5752',
        ort: 'Kallmünz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93183',
    },
    {
        id: '5753',
        ort: 'Kallstadt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67169',
    },
    {
        id: '5754',
        ort: 'Kalt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56294',
    },
    {
        id: '5755',
        ort: 'Kaltenborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53520',
    },
    {
        id: '5756',
        ort: 'Kaltenengers',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56220',
    },
    {
        id: '5757',
        ort: 'Kaltenholzhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65558',
    },
    {
        id: '5758',
        ort: 'Kaltenkirchen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24568',
    },
    {
        id: '5759',
        ort: 'Kaltennordheim',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '36452',
    },
    {
        id: '5760',
        ort: 'Kaltental',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87662',
    },
    {
        id: '5761',
        ort: 'Kalübbe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24326',
    },
    {
        id: '5762',
        ort: 'Kamen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Unna',
        plz: '59174',
    },
    {
        id: '5763',
        ort: 'Kamenz - Kamjenc',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01917',
    },
    {
        id: '5764',
        ort: 'Kamern',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39524',
    },
    {
        id: '5765',
        ort: 'Kammeltal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89358',
    },
    {
        id: '5766',
        ort: 'Kammerforst',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56206',
    },
    {
        id: '5767',
        ort: 'Kammerforst',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99986',
    },
    {
        id: '5768',
        ort: 'Kammerstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Roth',
        plz: '91126',
    },
    {
        id: '5769',
        ort: 'Kamminke',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17419',
    },
    {
        id: '5770',
        ort: 'Kammlach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87754',
    },
    {
        id: '5771',
        ort: 'Kamp-Bornhofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56341',
    },
    {
        id: '5772',
        ort: 'Kampen (Sylt)',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25999',
    },
    {
        id: '5773',
        ort: 'Kämpfelbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75236',
    },
    {
        id: '5774',
        ort: 'Kamp-Lintfort',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '47475',
    },
    {
        id: '5775',
        ort: 'Kandel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76870',
    },
    {
        id: '5776',
        ort: 'Kandern',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79400',
    },
    {
        id: '5777',
        ort: 'Kankelau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21514',
    },
    {
        id: '5778',
        ort: 'Kanzach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88422',
    },
    {
        id: '5779',
        ort: 'Kanzem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54441',
    },
    {
        id: '5780',
        ort: 'Kapellendorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99510',
    },
    {
        id: '5781',
        ort: 'Kapellen-Drusweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76889',
    },
    {
        id: '5782',
        ort: 'Kaperich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '56767',
    },
    {
        id: '5783',
        ort: 'Kappel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55483',
    },
    {
        id: '5784',
        ort: 'Kappel-Grafenhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77966',
    },
    {
        id: '5785',
        ort: 'Kappeln',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24376',
    },
    {
        id: '5786',
        ort: 'Kappeln',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24404',
    },
    {
        id: '5787',
        ort: 'Kappeln',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67744',
    },
    {
        id: '5788',
        ort: 'Kappelrodeck',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77876',
    },
    {
        id: '5789',
        ort: 'Kapsweyer',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76889',
    },
    {
        id: '5790',
        ort: 'Karbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97842',
    },
    {
        id: '5791',
        ort: 'Karbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56281',
    },
    {
        id: '5792',
        ort: 'Karben',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '61184',
    },
    {
        id: '5793',
        ort: 'Karby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24398',
    },
    {
        id: '5794',
        ort: 'Karenz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19294',
    },
    {
        id: '5795',
        ort: 'Kargow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17192',
    },
    {
        id: '5796',
        ort: 'Karl',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54534',
    },
    {
        id: '5797',
        ort: 'Karlsbad',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76307',
    },
    {
        id: '5798',
        ort: 'Karlsburg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17495',
    },
    {
        id: '5799',
        ort: 'Karlsdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '5800',
        ort: 'Karlsdorf-Neuthard',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76689',
    },
    {
        id: '5801',
        ort: 'Karlsfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '85757',
    },
    {
        id: '5802',
        ort: 'Karlshagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17449',
    },
    {
        id: '5803',
        ort: 'Karlshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '5804',
        ort: 'Karlshuld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neuburg-Schrobenhausen',
        plz: '86668',
    },
    {
        id: '5805',
        ort: 'Karlskron',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neuburg-Schrobenhausen',
        plz: '85123',
    },
    {
        id: '5806',
        ort: 'Karlsruhe',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '76131',
    },
    {
        id: '5807',
        ort: 'Karlsruhe',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '76133',
    },
    {
        id: '5808',
        ort: 'Karlsruhe',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '76135',
    },
    {
        id: '5809',
        ort: 'Karlsruhe',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '76137',
    },
    {
        id: '5810',
        ort: 'Karlsruhe',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '76139',
    },
    {
        id: '5811',
        ort: 'Karlsruhe',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '76149',
    },
    {
        id: '5812',
        ort: 'Karlsruhe',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '76185',
    },
    {
        id: '5813',
        ort: 'Karlsruhe',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '76187',
    },
    {
        id: '5814',
        ort: 'Karlsruhe',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '76189',
    },
    {
        id: '5815',
        ort: 'Karlsruhe',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '76199',
    },
    {
        id: '5816',
        ort: 'Karlsruhe',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '76227',
    },
    {
        id: '5817',
        ort: 'Karlsruhe',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '76228',
    },
    {
        id: '5818',
        ort: 'Karlsruhe',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '76229',
    },
    {
        id: '5819',
        ort: 'Karlstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97753',
    },
    {
        id: '5820',
        ort: 'Karlstein am Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63791',
    },
    {
        id: '5821',
        ort: 'Karlum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25926',
    },
    {
        id: '5822',
        ort: 'Karnin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18469',
    },
    {
        id: '5823',
        ort: 'Karolinenkoog',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25774',
    },
    {
        id: '5824',
        ort: 'Karrenzin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19372',
    },
    {
        id: '5825',
        ort: 'Karsbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97783',
    },
    {
        id: '5826',
        ort: 'Karsdorf',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06638',
    },
    {
        id: '5827',
        ort: 'Karstädt',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '19357',
    },
    {
        id: '5828',
        ort: 'Karstädt',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19294',
    },
    {
        id: '5829',
        ort: 'Karwitz',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29481',
    },
    {
        id: '5830',
        ort: 'Kasbach-Ohlenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53547',
    },
    {
        id: '5831',
        ort: 'Kaschenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54668',
    },
    {
        id: '5832',
        ort: 'Kasdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '5833',
        ort: 'Kasel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54317',
    },
    {
        id: '5834',
        ort: 'Kasel-Golzig',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15938',
    },
    {
        id: '5835',
        ort: 'Kasendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95359',
    },
    {
        id: '5836',
        ort: 'Käshofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66894',
    },
    {
        id: '5837',
        ort: 'Kasseburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '22929',
    },
    {
        id: '5838',
        ort: 'Kasseedorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23717',
    },
    {
        id: '5839',
        ort: 'Kassel',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '34117',
    },
    {
        id: '5840',
        ort: 'Kassel',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '34119',
    },
    {
        id: '5841',
        ort: 'Kassel',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '34121',
    },
    {
        id: '5842',
        ort: 'Kassel',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '34123',
    },
    {
        id: '5843',
        ort: 'Kassel',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '34125',
    },
    {
        id: '5844',
        ort: 'Kassel',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '34127',
    },
    {
        id: '5845',
        ort: 'Kassel',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '34128',
    },
    {
        id: '5846',
        ort: 'Kassel',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '34130',
    },
    {
        id: '5847',
        ort: 'Kassel',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '34131',
    },
    {
        id: '5848',
        ort: 'Kassel',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '34132',
    },
    {
        id: '5849',
        ort: 'Kassel',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '34134',
    },
    {
        id: '5850',
        ort: 'Kassel',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '34233',
    },
    {
        id: '5851',
        ort: 'Kassel',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '34355',
    },
    {
        id: '5852',
        ort: 'Kassow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18258',
    },
    {
        id: '5853',
        ort: 'Kastellaun',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56288',
    },
    {
        id: '5854',
        ort: 'Kastel-Staadt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54441',
    },
    {
        id: '5855',
        ort: 'Kastl',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84556',
    },
    {
        id: '5856',
        ort: 'Kastl',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95506',
    },
    {
        id: '5857',
        ort: 'Kastl',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92280',
    },
    {
        id: '5858',
        ort: 'Kastorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23847',
    },
    {
        id: '5859',
        ort: 'Katharinenheerd',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25836',
    },
    {
        id: '5860',
        ort: 'Katlenburg-Lindau',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Northeim',
        plz: '37191',
    },
    {
        id: '5861',
        ort: 'Kattendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24568',
    },
    {
        id: '5862',
        ort: 'Katzenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67806',
    },
    {
        id: '5863',
        ort: 'Katzenelnbogen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56368',
    },
    {
        id: '5864',
        ort: 'Katzhütte',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '98746',
    },
    {
        id: '5865',
        ort: 'Katzow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17509',
    },
    {
        id: '5866',
        ort: 'Katzweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67734',
    },
    {
        id: '5867',
        ort: 'Katzwinkel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '5868',
        ort: 'Katzwinkel (Sieg)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57581',
    },
    {
        id: '5869',
        ort: 'Kaub',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56349',
    },
    {
        id: '5870',
        ort: 'Kauern',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07554',
    },
    {
        id: '5871',
        ort: 'Kaufbeuren',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '87600',
    },
    {
        id: '5872',
        ort: 'Kaufering',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86916',
    },
    {
        id: '5873',
        ort: 'Kaufungen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34260',
    },
    {
        id: '5874',
        ort: 'Kaulsdorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07338',
    },
    {
        id: '5875',
        ort: 'Kausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57520',
    },
    {
        id: '5876',
        ort: 'Kayhude',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23863',
    },
    {
        id: '5877',
        ort: 'Kefenrod',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '63699',
    },
    {
        id: '5878',
        ort: 'Kehl',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77694',
    },
    {
        id: '5879',
        ort: 'Kehlbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56355',
    },
    {
        id: '5880',
        ort: 'Kehmstedt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Nordhausen',
        plz: '99752',
    },
    {
        id: '5881',
        ort: 'Kehrig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '5882',
        ort: 'Keidelheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55471',
    },
    {
        id: '5883',
        ort: 'Keila',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07389',
    },
    {
        id: '5884',
        ort: 'Kelberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '53539',
    },
    {
        id: '5885',
        ort: 'Kelbra (Kyffhäuser)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06537',
    },
    {
        id: '5886',
        ort: 'Kelheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '93309',
    },
    {
        id: '5887',
        ort: 'Kelkheim',
        bundesland: 'Hessen',
        landkreis: 'Main-Taunus-Kreis',
        plz: '65779',
    },
    {
        id: '5888',
        ort: 'Kella',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37308',
    },
    {
        id: '5889',
        ort: 'Kell am See',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54427',
    },
    {
        id: '5890',
        ort: 'Kellenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55606',
    },
    {
        id: '5891',
        ort: 'Kellenhusen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23746',
    },
    {
        id: '5892',
        ort: 'Kellinghusen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25548',
    },
    {
        id: '5893',
        ort: 'Kellmünz a.d.Iller',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89293',
    },
    {
        id: '5894',
        ort: 'Kelsterbach',
        bundesland: 'Hessen',
        landkreis: 'Kreis Groß-Gerau',
        plz: '65451',
    },
    {
        id: '5895',
        ort: 'Keltern',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75210',
    },
    {
        id: '5896',
        ort: 'Kemberg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Wittenberg',
        plz: '06901',
    },
    {
        id: '5897',
        ort: 'Kemmenau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56132',
    },
    {
        id: '5898',
        ort: 'Kemmern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96164',
    },
    {
        id: '5899',
        ort: 'Kemnath',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95478',
    },
    {
        id: '5900',
        ort: 'Kemnitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17509',
    },
    {
        id: '5901',
        ort: 'Kempen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Viersen',
        plz: '47906',
    },
    {
        id: '5902',
        ort: 'Kempenich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '56746',
    },
    {
        id: '5903',
        ort: 'Kempfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '5904',
        ort: 'Kempten (Allgäu)',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '87435',
    },
    {
        id: '5905',
        ort: 'Kempten (Allgäu)',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '87437',
    },
    {
        id: '5906',
        ort: 'Kempten (Allgäu)',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '87439',
    },
    {
        id: '5907',
        ort: 'Kenn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54344',
    },
    {
        id: '5908',
        ort: 'Kentzlin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17111',
    },
    {
        id: '5909',
        ort: 'Kenzingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79341',
    },
    {
        id: '5910',
        ort: 'Kenz-Küstrow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18314',
    },
    {
        id: '5911',
        ort: 'Keppeshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '5912',
        ort: 'Kerben',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56295',
    },
    {
        id: '5913',
        ort: 'Kerken',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '47647',
    },
    {
        id: '5914',
        ort: 'Kernen im Remstal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71394',
    },
    {
        id: '5915',
        ort: 'Kerpen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Erft-Kreis',
        plz: '50169',
    },
    {
        id: '5916',
        ort: 'Kerpen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Erft-Kreis',
        plz: '50170',
    },
    {
        id: '5917',
        ort: 'Kerpen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Erft-Kreis',
        plz: '50171',
    },
    {
        id: '5918',
        ort: 'Kerpen (Eifel)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54578',
    },
    {
        id: '5919',
        ort: 'Kerschenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54589',
    },
    {
        id: '5920',
        ort: 'Kerzenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67304',
    },
    {
        id: '5921',
        ort: 'Kescheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57632',
    },
    {
        id: '5922',
        ort: 'Kesfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54619',
    },
    {
        id: '5923',
        ort: 'Kesseling',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53506',
    },
    {
        id: '5924',
        ort: 'Kesten',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '5925',
        ort: 'Kestert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56348',
    },
    {
        id: '5926',
        ort: 'Ketsch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '68775',
    },
    {
        id: '5927',
        ort: 'Kettenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57612',
    },
    {
        id: '5928',
        ort: 'Kettenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '5929',
        ort: 'Kettenkamp',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49577',
    },
    {
        id: '5930',
        ort: 'Kettershausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '86498',
    },
    {
        id: '5931',
        ort: 'Kettig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56220',
    },
    {
        id: '5932',
        ort: 'Ketzin/Havel',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14669',
    },
    {
        id: '5933',
        ort: 'Kevelaer',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '47623',
    },
    {
        id: '5934',
        ort: 'Kevelaer',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '47624',
    },
    {
        id: '5935',
        ort: 'Kevelaer',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '47625',
    },
    {
        id: '5936',
        ort: 'Kevelaer',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '47626',
    },
    {
        id: '5937',
        ort: 'Kevelaer',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '47627',
    },
    {
        id: '5938',
        ort: 'Kickeshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54689',
    },
    {
        id: '5939',
        ort: 'Kiebitzreihe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25368',
    },
    {
        id: '5940',
        ort: 'Kiedrich',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65399',
    },
    {
        id: '5941',
        ort: 'Kiefersfelden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83088',
    },
    {
        id: '5942',
        ort: 'Kiel',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24103',
    },
    {
        id: '5943',
        ort: 'Kiel',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24105',
    },
    {
        id: '5944',
        ort: 'Kiel',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24106',
    },
    {
        id: '5945',
        ort: 'Kiel',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24107',
    },
    {
        id: '5946',
        ort: 'Kiel',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24109',
    },
    {
        id: '5947',
        ort: 'Kiel',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24111',
    },
    {
        id: '5948',
        ort: 'Kiel',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24113',
    },
    {
        id: '5949',
        ort: 'Kiel',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24114',
    },
    {
        id: '5950',
        ort: 'Kiel',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24116',
    },
    {
        id: '5951',
        ort: 'Kiel',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24118',
    },
    {
        id: '5952',
        ort: 'Kiel',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24143',
    },
    {
        id: '5953',
        ort: 'Kiel',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24145',
    },
    {
        id: '5954',
        ort: 'Kiel',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24146',
    },
    {
        id: '5955',
        ort: 'Kiel',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24147',
    },
    {
        id: '5956',
        ort: 'Kiel',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24148',
    },
    {
        id: '5957',
        ort: 'Kiel',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24149',
    },
    {
        id: '5958',
        ort: 'Kiel',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24159',
    },
    {
        id: '5959',
        ort: 'Kienberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83361',
    },
    {
        id: '5960',
        ort: 'Kierspe',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58566',
    },
    {
        id: '5961',
        ort: 'Kieselbronn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75249',
    },
    {
        id: '5962',
        ort: 'Kieve',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17209',
    },
    {
        id: '5963',
        ort: 'Kiliansroda',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99441',
    },
    {
        id: '5964',
        ort: 'Kindelbrück',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99638',
    },
    {
        id: '5965',
        ort: 'Kindenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67271',
    },
    {
        id: '5966',
        ort: 'Kinderbeuern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54538',
    },
    {
        id: '5967',
        ort: 'Kinding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85125',
    },
    {
        id: '5968',
        ort: 'Kindsbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66862',
    },
    {
        id: '5969',
        ort: 'Kinheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54538',
    },
    {
        id: '5970',
        ort: 'Kinsau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86981',
    },
    {
        id: '5971',
        ort: 'Kinzenburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '5972',
        ort: 'Kipfenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85110',
    },
    {
        id: '5973',
        ort: 'Kippenheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77971',
    },
    {
        id: '5974',
        ort: 'Kirburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57629',
    },
    {
        id: '5975',
        ort: 'Kirchanschöring',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83329',
    },
    {
        id: '5976',
        ort: 'Kirchanschöring',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83417',
    },
    {
        id: '5977',
        ort: 'Kirchardt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74912',
    },
    {
        id: '5978',
        ort: 'Kirchbarkau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24245',
    },
    {
        id: '5979',
        ort: 'Kirchberg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08107',
    },
    {
        id: '5980',
        ort: 'Kirchberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55481',
    },
    {
        id: '5981',
        ort: 'Kirchberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '84434',
    },
    {
        id: '5982',
        ort: 'Kirchberg an der Iller',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88486',
    },
    {
        id: '5983',
        ort: 'Kirchberg an der Jagst',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74592',
    },
    {
        id: '5984',
        ort: 'Kirchberg an der Murr',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71737',
    },
    {
        id: '5985',
        ort: 'Kirchberg i.Wald',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94259',
    },
    {
        id: '5986',
        ort: 'Kirchbrak',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37619',
    },
    {
        id: '5987',
        ort: 'Kirchdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '83527',
    },
    {
        id: '5988',
        ort: 'Kirchdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '93348',
    },
    {
        id: '5989',
        ort: 'Kirchdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27245',
    },
    {
        id: '5990',
        ort: 'Kirchdorf am Inn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84375',
    },
    {
        id: '5991',
        ort: 'Kirchdorf an der Amper',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85414',
    },
    {
        id: '5992',
        ort: 'Kirchdorf an der Iller',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88457',
    },
    {
        id: '5993',
        ort: 'Kirchdorf im Wald',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94261',
    },
    {
        id: '5994',
        ort: 'Kirchehrenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91356',
    },
    {
        id: '5995',
        ort: 'Kircheib',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57635',
    },
    {
        id: '5996',
        ort: 'Kirchendemenreuth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92665',
    },
    {
        id: '5997',
        ort: 'Kirchenlamitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Wunsiedel im Fichtelgebirge',
        plz: '95158',
    },
    {
        id: '5998',
        ort: 'Kirchenpingarten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95466',
    },
    {
        id: '5999',
        ort: 'Kirchen (Sieg)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57548',
    },
    {
        id: '6000',
        ort: 'Kirchensittenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91241',
    },
    {
        id: '6001',
        ort: 'Kirchentellinsfurt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72138',
    },
    {
        id: '6002',
        ort: 'Kirchenthumbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '91281',
    },
    {
        id: '6003',
        ort: 'Kirchgandern',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '6004',
        ort: 'Kirchgellersen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21394',
    },
    {
        id: '6005',
        ort: 'Kirchhain',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35274',
    },
    {
        id: '6006',
        ort: 'Kirchham',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94148',
    },
    {
        id: '6007',
        ort: 'Kirchhaslach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87755',
    },
    {
        id: '6008',
        ort: 'Kirchheilingen',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99947',
    },
    {
        id: '6009',
        ort: 'Kirchheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97268',
    },
    {
        id: '6010',
        ort: 'Kirchheim',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36275',
    },
    {
        id: '6011',
        ort: 'Kirchheim am Neckar',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '74366',
    },
    {
        id: '6012',
        ort: 'Kirchheim am Ries',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73467',
    },
    {
        id: '6013',
        ort: 'Kirchheim an der Weinstraße',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67281',
    },
    {
        id: '6014',
        ort: 'Kirchheim bei München',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '85551',
    },
    {
        id: '6015',
        ort: 'Kirchheimbolanden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67292',
    },
    {
        id: '6016',
        ort: 'Kirchheim in Schwaben',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87757',
    },
    {
        id: '6017',
        ort: 'Kirchheim unter Teck',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73230',
    },
    {
        id: '6018',
        ort: 'Kirchhundem',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Olpe',
        plz: '57399',
    },
    {
        id: '6019',
        ort: 'Kirch Jesar',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '6020',
        ort: 'Kirchlauter',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '96166',
    },
    {
        id: '6021',
        ort: 'Kirchlengern',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Herford',
        plz: '32278',
    },
    {
        id: '6022',
        ort: 'Kirchlinteln',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Verden',
        plz: '27308',
    },
    {
        id: '6023',
        ort: 'Kirchnüchel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '23714',
    },
    {
        id: '6024',
        ort: 'Kirchroth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94356',
    },
    {
        id: '6025',
        ort: 'Kirchsahr',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53505',
    },
    {
        id: '6026',
        ort: 'Kirchseelte',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Oldenburg',
        plz: '27243',
    },
    {
        id: '6027',
        ort: 'Kirchseeon',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85614',
    },
    {
        id: '6028',
        ort: 'Kirchspiel Garding',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25836',
    },
    {
        id: '6029',
        ort: 'Kirchtimke',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27412',
    },
    {
        id: '6030',
        ort: 'Kirchwald',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '6031',
        ort: 'Kirchwalsede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27386',
    },
    {
        id: '6032',
        ort: 'Kirchweidach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84558',
    },
    {
        id: '6033',
        ort: 'Kirchweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '6034',
        ort: 'Kirchworbis',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37339',
    },
    {
        id: '6035',
        ort: 'Kirchzarten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79199',
    },
    {
        id: '6036',
        ort: 'Kirchzell',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63931',
    },
    {
        id: '6037',
        ort: 'Kirf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54441',
    },
    {
        id: '6038',
        ort: 'Kirkel',
        bundesland: 'Saarland',
        landkreis: 'Saarpfalz-Kreis',
        plz: '66459',
    },
    {
        id: '6039',
        ort: 'Kirn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55606',
    },
    {
        id: '6040',
        ort: 'Kirrweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67744',
    },
    {
        id: '6041',
        ort: 'Kirrweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '67489',
    },
    {
        id: '6042',
        ort: 'Kirsbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '53539',
    },
    {
        id: '6043',
        ort: 'Kirschkau',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07919',
    },
    {
        id: '6044',
        ort: 'Kirschroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55566',
    },
    {
        id: '6045',
        ort: 'Kirschweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55743',
    },
    {
        id: '6046',
        ort: 'Kirtorf',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '36320',
    },
    {
        id: '6047',
        ort: 'Kisdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24629',
    },
    {
        id: '6048',
        ort: 'Kisselbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56291',
    },
    {
        id: '6049',
        ort: 'Kissenbrück',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38324',
    },
    {
        id: '6050',
        ort: 'Kissing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86438',
    },
    {
        id: '6051',
        ort: 'Kißlegg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88353',
    },
    {
        id: '6052',
        ort: 'Kist',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97270',
    },
    {
        id: '6053',
        ort: 'Kittendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17153',
    },
    {
        id: '6054',
        ort: 'Kittlitz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23911',
    },
    {
        id: '6055',
        ort: 'Kitzingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97318',
    },
    {
        id: '6056',
        ort: 'Kitzscher',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04567',
    },
    {
        id: '6057',
        ort: 'Klamp',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24321',
    },
    {
        id: '6058',
        ort: 'Klanxbüll',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25924',
    },
    {
        id: '6059',
        ort: 'Klappholz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24860',
    },
    {
        id: '6060',
        ort: 'Klausdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18445',
    },
    {
        id: '6061',
        ort: 'Klausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54524',
    },
    {
        id: '6062',
        ort: 'Kleinaitingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86507',
    },
    {
        id: '6063',
        ort: 'Klein Barkau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24245',
    },
    {
        id: '6064',
        ort: 'Klein Belitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18246',
    },
    {
        id: '6065',
        ort: 'Klein Bennebek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24848',
    },
    {
        id: '6066',
        ort: 'Klein Bennebek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24863',
    },
    {
        id: '6067',
        ort: 'Klein Berßen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49777',
    },
    {
        id: '6068',
        ort: 'Kleinblittersdorf',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66271',
    },
    {
        id: '6069',
        ort: 'Kleinbockedra',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '6070',
        ort: 'Kleinbundenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66501',
    },
    {
        id: '6071',
        ort: 'Klein Bünzow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17390',
    },
    {
        id: '6072',
        ort: 'Kleinebersdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '6073',
        ort: 'Kleines Wiesental',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79692',
    },
    {
        id: '6074',
        ort: 'Kleineutersdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07768',
    },
    {
        id: '6075',
        ort: 'Kleinfischlingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '67483',
    },
    {
        id: '6076',
        ort: 'Kleinfurra',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Nordhausen',
        plz: '99735',
    },
    {
        id: '6077',
        ort: 'Klein Gladebrügge',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23795',
    },
    {
        id: '6078',
        ort: 'Kleinheubach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63924',
    },
    {
        id: '6079',
        ort: 'Kleinich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54483',
    },
    {
        id: '6080',
        ort: 'Kleinkahl',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63828',
    },
    {
        id: '6081',
        ort: 'Kleinkarlbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67271',
    },
    {
        id: '6082',
        ort: 'Kleinlangenfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '6083',
        ort: 'Kleinlangheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97355',
    },
    {
        id: '6084',
        ort: 'Kleinmachnow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14532',
    },
    {
        id: '6085',
        ort: 'Kleinmaischeid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56271',
    },
    {
        id: '6086',
        ort: 'Klein Meckelsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27419',
    },
    {
        id: '6087',
        ort: 'Kleinmölsen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99198',
    },
    {
        id: '6088',
        ort: 'Kleinneuhausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99625',
    },
    {
        id: '6089',
        ort: 'Kleinniedesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67259',
    },
    {
        id: '6090',
        ort: 'Klein Nordende',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25336',
    },
    {
        id: '6091',
        ort: 'Klein Offenseth-Sparrieshoop',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25365',
    },
    {
        id: '6092',
        ort: 'Kleinostheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63801',
    },
    {
        id: '6093',
        ort: 'Klein Pampau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21514',
    },
    {
        id: '6094',
        ort: 'Klein Rheide',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24848',
    },
    {
        id: '6095',
        ort: 'Kleinrinderfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97271',
    },
    {
        id: '6096',
        ort: 'Klein Rogahn',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19073',
    },
    {
        id: '6097',
        ort: 'Klein Rönnau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23795',
    },
    {
        id: '6098',
        ort: 'Kleinschwabhausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99441',
    },
    {
        id: '6099',
        ort: 'Kleinsendelbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91077',
    },
    {
        id: '6100',
        ort: 'Kleinsteinhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66484',
    },
    {
        id: '6101',
        ort: 'Klein Trebbow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19069',
    },
    {
        id: '6102',
        ort: 'Klein Upahl',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18276',
    },
    {
        id: '6103',
        ort: 'Klein Vielen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17237',
    },
    {
        id: '6104',
        ort: 'Kleinwallstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63839',
    },
    {
        id: '6105',
        ort: 'Klein Wesenberg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23860',
    },
    {
        id: '6106',
        ort: 'Klein-Winternheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55270',
    },
    {
        id: '6107',
        ort: 'Klein Wittensee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24361',
    },
    {
        id: '6108',
        ort: 'Klein Zecher',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23883',
    },
    {
        id: '6109',
        ort: 'Klempau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23628',
    },
    {
        id: '6110',
        ort: 'Kleßen-Görne',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14728',
    },
    {
        id: '6111',
        ort: 'Kletkamp',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24327',
    },
    {
        id: '6112',
        ort: 'Klettbach',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99102',
    },
    {
        id: '6113',
        ort: 'Klettgau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79771',
    },
    {
        id: '6114',
        ort: 'Kletzin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17111',
    },
    {
        id: '6115',
        ort: 'Kleve',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '47533',
    },
    {
        id: '6116',
        ort: 'Kleve',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25554',
    },
    {
        id: '6117',
        ort: 'Kleve',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25776',
    },
    {
        id: '6118',
        ort: 'Kleve',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25779',
    },
    {
        id: '6119',
        ort: 'Kliding',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56825',
    },
    {
        id: '6120',
        ort: 'Klietz',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39524',
    },
    {
        id: '6121',
        ort: 'Klingelbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56368',
    },
    {
        id: '6122',
        ort: 'Klingenberg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01774',
    },
    {
        id: '6123',
        ort: 'Klingenberg am Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63911',
    },
    {
        id: '6124',
        ort: 'Klingenmünster',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76889',
    },
    {
        id: '6125',
        ort: 'Klingenthal',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08248',
    },
    {
        id: '6126',
        ort: 'Klingenthal',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08267',
    },
    {
        id: '6127',
        ort: 'Klink',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17192',
    },
    {
        id: '6128',
        ort: 'Klinkrade',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23898',
    },
    {
        id: '6129',
        ort: 'Klipphausen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01665',
    },
    {
        id: '6130',
        ort: 'Klixbüll',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25899',
    },
    {
        id: '6131',
        ort: 'Klocksin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17194',
    },
    {
        id: '6132',
        ort: 'Klosterkumbd',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55469',
    },
    {
        id: '6133',
        ort: 'Klosterlechfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86836',
    },
    {
        id: '6134',
        ort: 'Kloster Lehnin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14797',
    },
    {
        id: '6135',
        ort: 'Klostermansfeld',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06308',
    },
    {
        id: '6136',
        ort: 'Kloster Tempzin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19412',
    },
    {
        id: '6137',
        ort: 'Kloster Veßra',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98660',
    },
    {
        id: '6138',
        ort: 'Klotten',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56818',
    },
    {
        id: '6139',
        ort: 'Klötze',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Altmarkkreis Salzwedel',
        plz: '38486',
    },
    {
        id: '6140',
        ort: 'Kludenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55481',
    },
    {
        id: '6141',
        ort: 'Kluis',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18569',
    },
    {
        id: '6142',
        ort: 'Kluse',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26892',
    },
    {
        id: '6143',
        ort: 'Klüsserath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54340',
    },
    {
        id: '6144',
        ort: 'Klütz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23946',
    },
    {
        id: '6145',
        ort: 'Klütz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23948',
    },
    {
        id: '6146',
        ort: 'Kneese',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19205',
    },
    {
        id: '6147',
        ort: 'Kneitlingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38170',
    },
    {
        id: '6148',
        ort: 'Knetzgau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '97478',
    },
    {
        id: '6149',
        ort: 'Knittelsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76879',
    },
    {
        id: '6150',
        ort: 'Knittlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75438',
    },
    {
        id: '6151',
        ort: 'Knopp-Labach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66917',
    },
    {
        id: '6152',
        ort: 'Knöringen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76833',
    },
    {
        id: '6153',
        ort: 'Knorrendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17091',
    },
    {
        id: '6154',
        ort: 'Knüllwald',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34593',
    },
    {
        id: '6155',
        ort: 'Koberg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23881',
    },
    {
        id: '6156',
        ort: 'Kobern-Gondorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56330',
    },
    {
        id: '6157',
        ort: 'Koblentz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17309',
    },
    {
        id: '6158',
        ort: 'Koblenz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '56068',
    },
    {
        id: '6159',
        ort: 'Koblenz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '56070',
    },
    {
        id: '6160',
        ort: 'Koblenz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '56072',
    },
    {
        id: '6161',
        ort: 'Koblenz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '56073',
    },
    {
        id: '6162',
        ort: 'Koblenz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '56075',
    },
    {
        id: '6163',
        ort: 'Koblenz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '56076',
    },
    {
        id: '6164',
        ort: 'Koblenz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '56077',
    },
    {
        id: '6165',
        ort: 'Kobrow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19406',
    },
    {
        id: '6166',
        ort: 'Kochel am See',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '82431',
    },
    {
        id: '6167',
        ort: 'Kochel am See',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '82432',
    },
    {
        id: '6168',
        ort: 'Kodersdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02923',
    },
    {
        id: '6169',
        ort: 'Köditz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95189',
    },
    {
        id: '6170',
        ort: 'Ködnitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95361',
    },
    {
        id: '6171',
        ort: 'Köfering',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93096',
    },
    {
        id: '6172',
        ort: 'Kogel',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19246',
    },
    {
        id: '6173',
        ort: 'Kohlberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92702',
    },
    {
        id: '6174',
        ort: 'Kohlberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '72664',
    },
    {
        id: '6175',
        ort: 'Köhn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24257',
    },
    {
        id: '6176',
        ort: 'Kolbermoor',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83059',
    },
    {
        id: '6177',
        ort: 'Kolbingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78600',
    },
    {
        id: '6178',
        ort: 'Kölbingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '6179',
        ort: 'Koldenbüttel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25840',
    },
    {
        id: '6180',
        ort: 'Kolitzheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97509',
    },
    {
        id: '6181',
        ort: 'Kolkerheide',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25862',
    },
    {
        id: '6182',
        ort: 'Kolkwitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03099',
    },
    {
        id: '6183',
        ort: 'Kölleda',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99625',
    },
    {
        id: '6184',
        ort: 'Kollig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56751',
    },
    {
        id: '6185',
        ort: 'Kollmar',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25377',
    },
    {
        id: '6186',
        ort: 'Kollmoor',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25524',
    },
    {
        id: '6187',
        ort: 'Kollnburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94262',
    },
    {
        id: '6188',
        ort: 'Kölln-Reisiek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25337',
    },
    {
        id: '6189',
        ort: 'Kollow',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21527',
    },
    {
        id: '6190',
        ort: 'Kollweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66879',
    },
    {
        id: '6191',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50667',
    },
    {
        id: '6192',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50668',
    },
    {
        id: '6193',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50670',
    },
    {
        id: '6194',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50672',
    },
    {
        id: '6195',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50674',
    },
    {
        id: '6196',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50676',
    },
    {
        id: '6197',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50677',
    },
    {
        id: '6198',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50678',
    },
    {
        id: '6199',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50679',
    },
    {
        id: '6200',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50733',
    },
    {
        id: '6201',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50735',
    },
    {
        id: '6202',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50737',
    },
    {
        id: '6203',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50739',
    },
    {
        id: '6204',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50765',
    },
    {
        id: '6205',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50767',
    },
    {
        id: '6206',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50769',
    },
    {
        id: '6207',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50823',
    },
    {
        id: '6208',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50825',
    },
    {
        id: '6209',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50827',
    },
    {
        id: '6210',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50829',
    },
    {
        id: '6211',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50858',
    },
    {
        id: '6212',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50859',
    },
    {
        id: '6213',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50931',
    },
    {
        id: '6214',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50933',
    },
    {
        id: '6215',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50935',
    },
    {
        id: '6216',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50937',
    },
    {
        id: '6217',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50939',
    },
    {
        id: '6218',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50968',
    },
    {
        id: '6219',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50969',
    },
    {
        id: '6220',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50996',
    },
    {
        id: '6221',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50997',
    },
    {
        id: '6222',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '50999',
    },
    {
        id: '6223',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51061',
    },
    {
        id: '6224',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51063',
    },
    {
        id: '6225',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51065',
    },
    {
        id: '6226',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51067',
    },
    {
        id: '6227',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51069',
    },
    {
        id: '6228',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51103',
    },
    {
        id: '6229',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51105',
    },
    {
        id: '6230',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51107',
    },
    {
        id: '6231',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51109',
    },
    {
        id: '6232',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51143',
    },
    {
        id: '6233',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51145',
    },
    {
        id: '6234',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51147',
    },
    {
        id: '6235',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51149',
    },
    {
        id: '6236',
        ort: 'Köln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51467',
    },
    {
        id: '6237',
        ort: 'Kolverath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '56767',
    },
    {
        id: '6238',
        ort: 'Kommen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54472',
    },
    {
        id: '6239',
        ort: 'Köngen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73257',
    },
    {
        id: '6240',
        ort: 'Köngernheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55278',
    },
    {
        id: '6241',
        ort: 'Königheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Main-Tauber-Kreis',
        plz: '97953',
    },
    {
        id: '6242',
        ort: 'Königsau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55606',
    },
    {
        id: '6243',
        ort: 'Königsbach-Stein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75203',
    },
    {
        id: '6244',
        ort: 'Königsberg in Bayern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '97486',
    },
    {
        id: '6245',
        ort: 'Königsbronn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heidenheim',
        plz: '89551',
    },
    {
        id: '6246',
        ort: 'Königsbrück',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01936',
    },
    {
        id: '6247',
        ort: 'Königsbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86343',
    },
    {
        id: '6248',
        ort: 'Königsdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '82549',
    },
    {
        id: '6249',
        ort: 'Königsee',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07426',
    },
    {
        id: '6250',
        ort: 'Königseggwald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88376',
    },
    {
        id: '6251',
        ort: 'Königsfeld',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09306',
    },
    {
        id: '6252',
        ort: 'Königsfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53426',
    },
    {
        id: '6253',
        ort: 'Königsfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96167',
    },
    {
        id: '6254',
        ort: 'Königsfeld',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19217',
    },
    {
        id: '6255',
        ort: 'Königsfeld im Schwarzwald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78126',
    },
    {
        id: '6256',
        ort: 'Königshain',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02829',
    },
    {
        id: '6257',
        ort: 'Königshain-Wiederau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09306',
    },
    {
        id: '6258',
        ort: 'Königsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78598',
    },
    {
        id: '6259',
        ort: 'Königshügel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24799',
    },
    {
        id: '6260',
        ort: 'Königslutter am Elm',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38154',
    },
    {
        id: '6261',
        ort: 'Königsmoor',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21255',
    },
    {
        id: '6262',
        ort: 'Königsmoos',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neuburg-Schrobenhausen',
        plz: '86669',
    },
    {
        id: '6263',
        ort: 'Königstein',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01824',
    },
    {
        id: '6264',
        ort: 'Königstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92281',
    },
    {
        id: '6265',
        ort: 'Königstein im Taunus',
        bundesland: 'Hessen',
        landkreis: 'Hochtaunuskreis',
        plz: '61462',
    },
    {
        id: '6266',
        ort: 'Königswalde',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09471',
    },
    {
        id: '6267',
        ort: 'Königswartha - Rakecy',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02699',
    },
    {
        id: '6268',
        ort: 'Königswinter',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53639',
    },
    {
        id: '6269',
        ort: 'Königs Wusterhausen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15711',
    },
    {
        id: '6270',
        ort: 'Königs Wusterhausen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15712',
    },
    {
        id: '6271',
        ort: 'Königs Wusterhausen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15713',
    },
    {
        id: '6272',
        ort: 'Konken',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66871',
    },
    {
        id: '6273',
        ort: 'Könnern',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '06420',
    },
    {
        id: '6274',
        ort: 'Konnersreuth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95692',
    },
    {
        id: '6275',
        ort: 'Konradsreuth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95176',
    },
    {
        id: '6276',
        ort: 'Konstanz',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78462',
    },
    {
        id: '6277',
        ort: 'Konstanz',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78464',
    },
    {
        id: '6278',
        ort: 'Konstanz',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78465',
    },
    {
        id: '6279',
        ort: 'Konstanz',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78467',
    },
    {
        id: '6280',
        ort: 'Konz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54329',
    },
    {
        id: '6281',
        ort: 'Konzell',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94357',
    },
    {
        id: '6282',
        ort: 'Kopp',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54574',
    },
    {
        id: '6283',
        ort: 'Korb',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71404',
    },
    {
        id: '6284',
        ort: 'Korbach',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '34497',
    },
    {
        id: '6285',
        ort: 'Korbach',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '34513',
    },
    {
        id: '6286',
        ort: 'Körborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66871',
    },
    {
        id: '6287',
        ort: 'Korbußen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07554',
    },
    {
        id: '6288',
        ort: 'Kordel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54306',
    },
    {
        id: '6289',
        ort: 'Kördorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56370',
    },
    {
        id: '6290',
        ort: 'Körle',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34327',
    },
    {
        id: '6291',
        ort: 'Korlingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54317',
    },
    {
        id: '6292',
        ort: 'Körner',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99998',
    },
    {
        id: '6293',
        ort: 'Korntal-Münchingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '70825',
    },
    {
        id: '6294',
        ort: 'Kornwestheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '70806',
    },
    {
        id: '6295',
        ort: 'Körperich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '6296',
        ort: 'Korschenbroich',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41352',
    },
    {
        id: '6297',
        ort: 'Korswandt',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17419',
    },
    {
        id: '6298',
        ort: 'Korweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56288',
    },
    {
        id: '6299',
        ort: 'Kösching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85092',
    },
    {
        id: '6300',
        ort: 'Kosel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24354',
    },
    {
        id: '6301',
        ort: 'Koserow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17459',
    },
    {
        id: '6302',
        ort: 'Kospoda',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07806',
    },
    {
        id: '6303',
        ort: 'Kößlarn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94149',
    },
    {
        id: '6304',
        ort: 'Köthel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22929',
    },
    {
        id: '6305',
        ort: 'Köthel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '22929',
    },
    {
        id: '6306',
        ort: 'Köthen (Anhalt)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06366',
    },
    {
        id: '6307',
        ort: 'Köthen (Anhalt)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06369',
    },
    {
        id: '6308',
        ort: 'Köthen (Anhalt)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06388',
    },
    {
        id: '6309',
        ort: 'Kottenborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53518',
    },
    {
        id: '6310',
        ort: 'Kottenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56736',
    },
    {
        id: '6311',
        ort: 'Kötterichen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '56767',
    },
    {
        id: '6312',
        ort: 'Kottgeisering',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82288',
    },
    {
        id: '6313',
        ort: 'Kottmar',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02708',
    },
    {
        id: '6314',
        ort: 'Kottmar',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02739',
    },
    {
        id: '6315',
        ort: 'Kottweiler-Schwanden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66879',
    },
    {
        id: '6316',
        ort: 'Kötz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89359',
    },
    {
        id: '6317',
        ort: 'Kotzen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14715',
    },
    {
        id: '6318',
        ort: 'Kotzenbüll',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25832',
    },
    {
        id: '6319',
        ort: 'Köwerich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54340',
    },
    {
        id: '6320',
        ort: 'Koxhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '6321',
        ort: 'Kraam',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57635',
    },
    {
        id: '6322',
        ort: 'Krackow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17329',
    },
    {
        id: '6323',
        ort: 'Kradenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '6324',
        ort: 'Kraftisried',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87647',
    },
    {
        id: '6325',
        ort: 'Kraftsdorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07586',
    },
    {
        id: '6326',
        ort: 'Krähenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66894',
    },
    {
        id: '6327',
        ort: 'Kraiburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84559',
    },
    {
        id: '6328',
        ort: 'Kraichtal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76703',
    },
    {
        id: '6329',
        ort: 'Krailling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Starnberg',
        plz: '82152',
    },
    {
        id: '6330',
        ort: 'Krailling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Starnberg',
        plz: '82349',
    },
    {
        id: '6331',
        ort: 'Krakow am See',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18292',
    },
    {
        id: '6332',
        ort: 'Kramerhof',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18445',
    },
    {
        id: '6333',
        ort: 'Kranenburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '47559',
    },
    {
        id: '6334',
        ort: 'Kranenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21726',
    },
    {
        id: '6335',
        ort: 'Kranichfeld',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99448',
    },
    {
        id: '6336',
        ort: 'Kranzberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85402',
    },
    {
        id: '6337',
        ort: 'Kratzeburg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17237',
    },
    {
        id: '6338',
        ort: 'Kratzenburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56283',
    },
    {
        id: '6339',
        ort: 'Krauchenwies',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '72505',
    },
    {
        id: '6340',
        ort: 'Krauschwitz - Krušwica',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02957',
    },
    {
        id: '6341',
        ort: 'Krausnick-Groß Wasserburg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15910',
    },
    {
        id: '6342',
        ort: 'Krauthausen',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '99819',
    },
    {
        id: '6343',
        ort: 'Krautheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Hohenlohekreis',
        plz: '74238',
    },
    {
        id: '6344',
        ort: 'Krautscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '6345',
        ort: 'Krayenberggemeinde',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '36460',
    },
    {
        id: '6346',
        ort: 'Kreba-Neudorf - Chrjebja-Nowa Wjes',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02906',
    },
    {
        id: '6347',
        ort: 'Krebeck',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37434',
    },
    {
        id: '6348',
        ort: 'Krefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47798',
    },
    {
        id: '6349',
        ort: 'Krefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47799',
    },
    {
        id: '6350',
        ort: 'Krefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47800',
    },
    {
        id: '6351',
        ort: 'Krefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47802',
    },
    {
        id: '6352',
        ort: 'Krefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47803',
    },
    {
        id: '6353',
        ort: 'Krefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47804',
    },
    {
        id: '6354',
        ort: 'Krefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47805',
    },
    {
        id: '6355',
        ort: 'Krefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47807',
    },
    {
        id: '6356',
        ort: 'Krefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47809',
    },
    {
        id: '6357',
        ort: 'Krefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47829',
    },
    {
        id: '6358',
        ort: 'Krefeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '47839',
    },
    {
        id: '6359',
        ort: 'Kreien',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19386',
    },
    {
        id: '6360',
        ort: 'Kreimbach-Kaulbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67757',
    },
    {
        id: '6361',
        ort: 'Kreischa',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01731',
    },
    {
        id: '6362',
        ort: 'Krembz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19205',
    },
    {
        id: '6363',
        ort: 'Kremitzaue',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04916',
    },
    {
        id: '6364',
        ort: 'Kremitzaue',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04936',
    },
    {
        id: '6365',
        ort: 'Kremmen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16766',
    },
    {
        id: '6366',
        ort: 'Kremmin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19300',
    },
    {
        id: '6367',
        ort: 'Krempdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25361',
    },
    {
        id: '6368',
        ort: 'Krempdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25376',
    },
    {
        id: '6369',
        ort: 'Krempe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25361',
    },
    {
        id: '6370',
        ort: 'Krempel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25774',
    },
    {
        id: '6371',
        ort: 'Kremperheide',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25569',
    },
    {
        id: '6372',
        ort: 'Krempermoor',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25569',
    },
    {
        id: '6373',
        ort: 'Krems II',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23827',
    },
    {
        id: '6374',
        ort: 'Kreßberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74594',
    },
    {
        id: '6375',
        ort: 'Kressbronn am Bodensee',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88079',
    },
    {
        id: '6376',
        ort: 'Kretz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56630',
    },
    {
        id: '6377',
        ort: 'Kretzschau',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06712',
    },
    {
        id: '6378',
        ort: 'Kreuth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miesbach',
        plz: '83708',
    },
    {
        id: '6379',
        ort: 'Kreuzau',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Düren',
        plz: '52372',
    },
    {
        id: '6380',
        ort: 'Kreuztal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Siegen-Wittgenstein',
        plz: '57223',
    },
    {
        id: '6381',
        ort: 'Kreuzwertheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97892',
    },
    {
        id: '6382',
        ort: 'Krickenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67706',
    },
    {
        id: '6383',
        ort: 'Kriebitzsch',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04617',
    },
    {
        id: '6384',
        ort: 'Kriebstein',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09648',
    },
    {
        id: '6385',
        ort: 'Kriegsfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67819',
    },
    {
        id: '6386',
        ort: 'Krien',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17391',
    },
    {
        id: '6387',
        ort: 'Kriesow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17091',
    },
    {
        id: '6388',
        ort: 'Kriftel',
        bundesland: 'Hessen',
        landkreis: 'Main-Taunus-Kreis',
        plz: '65830',
    },
    {
        id: '6389',
        ort: 'Kritzmow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18198',
    },
    {
        id: '6390',
        ort: 'Kritzow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19386',
    },
    {
        id: '6391',
        ort: 'Krogaspe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24644',
    },
    {
        id: '6392',
        ort: 'Krokau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24217',
    },
    {
        id: '6393',
        ort: 'Krölpa',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07387',
    },
    {
        id: '6394',
        ort: 'Krombach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63829',
    },
    {
        id: '6395',
        ort: 'Krombach',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37308',
    },
    {
        id: '6396',
        ort: 'Kronach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kronach',
        plz: '96317',
    },
    {
        id: '6397',
        ort: 'Kronau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76709',
    },
    {
        id: '6398',
        ort: 'Kronberg im Taunus',
        bundesland: 'Hessen',
        landkreis: 'Hochtaunuskreis',
        plz: '61476',
    },
    {
        id: '6399',
        ort: 'Kronburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87758',
    },
    {
        id: '6400',
        ort: 'Kröning',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84178',
    },
    {
        id: '6401',
        ort: 'Kronprinzenkoog',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25709',
    },
    {
        id: '6402',
        ort: 'Kronsgaard',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24395',
    },
    {
        id: '6403',
        ort: 'Kronshagen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24119',
    },
    {
        id: '6404',
        ort: 'Kronsmoor',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25597',
    },
    {
        id: '6405',
        ort: 'Kronweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '6406',
        ort: 'Kröpelin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18236',
    },
    {
        id: '6407',
        ort: 'Kropp',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24848',
    },
    {
        id: '6408',
        ort: 'Kroppach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57612',
    },
    {
        id: '6409',
        ort: 'Kröppelshagen-Fahrendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21039',
    },
    {
        id: '6410',
        ort: 'Kröppelshagen-Fahrendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21529',
    },
    {
        id: '6411',
        ort: 'Kroppen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01945',
    },
    {
        id: '6412',
        ort: 'Kröppen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66957',
    },
    {
        id: '6413',
        ort: 'Kroppenstedt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39397',
    },
    {
        id: '6414',
        ort: 'Kröslin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17440',
    },
    {
        id: '6415',
        ort: 'Krostitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04509',
    },
    {
        id: '6416',
        ort: 'Krottelbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66909',
    },
    {
        id: '6417',
        ort: 'Kröv',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54536',
    },
    {
        id: '6418',
        ort: 'Kruchten',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '6419',
        ort: 'Kruckow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17129',
    },
    {
        id: '6420',
        ort: 'Kruft',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56642',
    },
    {
        id: '6421',
        ort: 'Krugsdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17309',
    },
    {
        id: '6422',
        ort: 'Krukow',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21483',
    },
    {
        id: '6423',
        ort: 'Krumbach (Schwaben)',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '86381',
    },
    {
        id: '6424',
        ort: 'Krummbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24217',
    },
    {
        id: '6425',
        ort: 'Krümmel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '6426',
        ort: 'Krummenau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55483',
    },
    {
        id: '6427',
        ort: 'Krummendeich',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21732',
    },
    {
        id: '6428',
        ort: 'Krummendiek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25554',
    },
    {
        id: '6429',
        ort: 'Krummennaab',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '92703',
    },
    {
        id: '6430',
        ort: 'Krummesse',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23628',
    },
    {
        id: '6431',
        ort: 'Krummhörn',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26736',
    },
    {
        id: '6432',
        ort: 'Krummin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17440',
    },
    {
        id: '6433',
        ort: 'Krummwisch',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24796',
    },
    {
        id: '6434',
        ort: 'Krumstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25727',
    },
    {
        id: '6435',
        ort: 'Krün',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82467',
    },
    {
        id: '6436',
        ort: 'Krün',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82493',
    },
    {
        id: '6437',
        ort: 'Krün',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82494',
    },
    {
        id: '6438',
        ort: 'Krunkel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '56593',
    },
    {
        id: '6439',
        ort: 'Krusenfelde',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17391',
    },
    {
        id: '6440',
        ort: 'Krusenhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23974',
    },
    {
        id: '6441',
        ort: 'Krüzen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21483',
    },
    {
        id: '6442',
        ort: 'Kublank',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17349',
    },
    {
        id: '6443',
        ort: 'Kubschütz - Kubšicy',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02627',
    },
    {
        id: '6444',
        ort: 'Kuchelmiß',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18292',
    },
    {
        id: '6445',
        ort: 'Kuchen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73329',
    },
    {
        id: '6446',
        ort: 'Kuckssee',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17217',
    },
    {
        id: '6447',
        ort: 'Kuddewörde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '22958',
    },
    {
        id: '6448',
        ort: 'Kuden',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25712',
    },
    {
        id: '6449',
        ort: 'Kudensee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25572',
    },
    {
        id: '6450',
        ort: 'Kuhardt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76773',
    },
    {
        id: '6451',
        ort: 'Kühbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86556',
    },
    {
        id: '6452',
        ort: 'Kuhfelde',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Altmarkkreis Salzwedel',
        plz: '29416',
    },
    {
        id: '6453',
        ort: 'Kühlenthal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86707',
    },
    {
        id: '6454',
        ort: 'Kuhlen-Wendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19412',
    },
    {
        id: '6455',
        ort: 'Kühlungsborn',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18225',
    },
    {
        id: '6456',
        ort: 'Kühndorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98547',
    },
    {
        id: '6457',
        ort: 'Kuhnhöfen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '6458',
        ort: 'Kühren',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24211',
    },
    {
        id: '6459',
        ort: 'Kuhs',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18276',
    },
    {
        id: '6460',
        ort: 'Kühsen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23898',
    },
    {
        id: '6461',
        ort: 'Kuhstorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '6462',
        ort: 'Kükels',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23829',
    },
    {
        id: '6463',
        ort: 'Küllstedt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37359',
    },
    {
        id: '6464',
        ort: 'Kulmain',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95508',
    },
    {
        id: '6465',
        ort: 'Kulmbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95326',
    },
    {
        id: '6466',
        ort: 'Kulmbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '96369',
    },
    {
        id: '6467',
        ort: 'Kulpin',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23911',
    },
    {
        id: '6468',
        ort: 'Külsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Main-Tauber-Kreis',
        plz: '97900',
    },
    {
        id: '6469',
        ort: 'Külz (Hunsrück)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55471',
    },
    {
        id: '6470',
        ort: 'Kümbdchen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55471',
    },
    {
        id: '6471',
        ort: 'Kumhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84036',
    },
    {
        id: '6472',
        ort: 'Kummerfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25495',
    },
    {
        id: '6473',
        ort: 'Kümmernitztal',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '16945',
    },
    {
        id: '6474',
        ort: 'Kummerow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17139',
    },
    {
        id: '6475',
        ort: 'Kümmersbruck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92245',
    },
    {
        id: '6476',
        ort: 'Kundert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57629',
    },
    {
        id: '6477',
        ort: 'Kunreuth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91358',
    },
    {
        id: '6478',
        ort: 'Künzell',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36093',
    },
    {
        id: '6479',
        ort: 'Künzelsau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Hohenlohekreis',
        plz: '74653',
    },
    {
        id: '6480',
        ort: 'Künzing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94550',
    },
    {
        id: '6481',
        ort: 'Kupferberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95362',
    },
    {
        id: '6482',
        ort: 'Kupferzell',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Hohenlohekreis',
        plz: '74635',
    },
    {
        id: '6483',
        ort: 'Kuppenheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '76456',
    },
    {
        id: '6484',
        ort: 'Küps',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kronach',
        plz: '96328',
    },
    {
        id: '6485',
        ort: 'Kürnach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97273',
    },
    {
        id: '6486',
        ort: 'Kürnbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '75057',
    },
    {
        id: '6487',
        ort: 'Kürten',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rheinisch-Bergischer Kreis',
        plz: '51515',
    },
    {
        id: '6488',
        ort: 'Kurtscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56581',
    },
    {
        id: '6489',
        ort: 'Kusel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66869',
    },
    {
        id: '6490',
        ort: 'Küssaberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79790',
    },
    {
        id: '6491',
        ort: 'Küsten',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29482',
    },
    {
        id: '6492',
        ort: 'Kusterdingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72127',
    },
    {
        id: '6493',
        ort: 'Kusterdingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72138',
    },
    {
        id: '6494',
        ort: 'Küstriner Vorland',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15328',
    },
    {
        id: '6495',
        ort: 'Kutenholz',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '27449',
    },
    {
        id: '6496',
        ort: 'Kutzenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86500',
    },
    {
        id: '6497',
        ort: 'Kutzleben',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99955',
    },
    {
        id: '6498',
        ort: 'Kyffhäuserland',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '99707',
    },
    {
        id: '6499',
        ort: 'Kyllburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54655',
    },
    {
        id: '6500',
        ort: 'Kyllburgweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54655',
    },
    {
        id: '6501',
        ort: 'Kyritz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16866',
    },
    {
        id: '6502',
        ort: 'Laaber',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93164',
    },
    {
        id: '6503',
        ort: 'Laage',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18299',
    },
    {
        id: '6504',
        ort: 'Laar',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '49824',
    },
    {
        id: '6505',
        ort: 'Laasdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '6506',
        ort: 'Laatzen',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30880',
    },
    {
        id: '6507',
        ort: 'Labenz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23898',
    },
    {
        id: '6508',
        ort: 'Laberweinting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '84082',
    },
    {
        id: '6509',
        ort: 'Laboe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24235',
    },
    {
        id: '6510',
        ort: 'Lachen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87760',
    },
    {
        id: '6511',
        ort: 'Lachendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29331',
    },
    {
        id: '6512',
        ort: 'Ladbergen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '49549',
    },
    {
        id: '6513',
        ort: 'Ladelund',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25926',
    },
    {
        id: '6514',
        ort: 'Ladenburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '68526',
    },
    {
        id: '6515',
        ort: 'Laer',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '48366',
    },
    {
        id: '6516',
        ort: 'Lage',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '32791',
    },
    {
        id: '6517',
        ort: 'Lage',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '49828',
    },
    {
        id: '6518',
        ort: 'Lägerdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25566',
    },
    {
        id: '6519',
        ort: 'Lähden',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49774',
    },
    {
        id: '6520',
        ort: 'Lahn',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49757',
    },
    {
        id: '6521',
        ort: 'Lahnau',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35633',
    },
    {
        id: '6522',
        ort: 'Lahnstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56112',
    },
    {
        id: '6523',
        ort: 'Lahnstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56130',
    },
    {
        id: '6524',
        ort: 'Lahntal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35094',
    },
    {
        id: '6525',
        ort: 'Lahr',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '6526',
        ort: 'Lahr',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56288',
    },
    {
        id: '6527',
        ort: 'Lahr/Schwarzwald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77933',
    },
    {
        id: '6528',
        ort: 'Laichingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89150',
    },
    {
        id: '6529',
        ort: 'Lalendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18279',
    },
    {
        id: '6530',
        ort: 'Lalling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94551',
    },
    {
        id: '6531',
        ort: 'Lam',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93462',
    },
    {
        id: '6532',
        ort: 'Lambertsberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54649',
    },
    {
        id: '6533',
        ort: 'Lambrecht',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67466',
    },
    {
        id: '6534',
        ort: 'Lambrechtshagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18069',
    },
    {
        id: '6535',
        ort: 'Lambsborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66894',
    },
    {
        id: '6536',
        ort: 'Lambsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67245',
    },
    {
        id: '6537',
        ort: 'Lamerdingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '86862',
    },
    {
        id: '6538',
        ort: 'Lammershagen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24238',
    },
    {
        id: '6539',
        ort: 'Lampaden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54316',
    },
    {
        id: '6540',
        ort: 'Lampertheim',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '68623',
    },
    {
        id: '6541',
        ort: 'Lampertswalde',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01561',
    },
    {
        id: '6542',
        ort: 'Lamspringe',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31195',
    },
    {
        id: '6543',
        ort: 'Lamstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21769',
    },
    {
        id: '6544',
        ort: 'Lancken-Granitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18586',
    },
    {
        id: '6545',
        ort: 'Landau an der Isar',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dingolfing-Landau',
        plz: '94405',
    },
    {
        id: '6546',
        ort: 'Landau in der Pfalz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '76829',
    },
    {
        id: '6547',
        ort: 'Landensberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89361',
    },
    {
        id: '6548',
        ort: 'Landesbergen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31628',
    },
    {
        id: '6549',
        ort: 'Landkern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56814',
    },
    {
        id: '6550',
        ort: 'Landolfshausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37136',
    },
    {
        id: '6551',
        ort: 'Landrecht',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25554',
    },
    {
        id: '6552',
        ort: 'Landsberg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06188',
    },
    {
        id: '6553',
        ort: 'Landsberg am Lech',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86899',
    },
    {
        id: '6554',
        ort: 'Landsberg am Lech',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86932',
    },
    {
        id: '6555',
        ort: 'Landsberied',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82290',
    },
    {
        id: '6556',
        ort: 'Landscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54526',
    },
    {
        id: '6557',
        ort: 'Landscheide',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25572',
    },
    {
        id: '6558',
        ort: 'Landshut',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '84028',
    },
    {
        id: '6559',
        ort: 'Landshut',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '84030',
    },
    {
        id: '6560',
        ort: 'Landshut',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '84032',
    },
    {
        id: '6561',
        ort: 'Landshut',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '84034',
    },
    {
        id: '6562',
        ort: 'Landshut',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '84036',
    },
    {
        id: '6563',
        ort: 'Landstuhl',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66849',
    },
    {
        id: '6564',
        ort: 'Langballig',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24977',
    },
    {
        id: '6565',
        ort: 'Langdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94264',
    },
    {
        id: '6566',
        ort: 'Langeln',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25485',
    },
    {
        id: '6567',
        ort: 'Langelsheim',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Goslar',
        plz: '38685',
    },
    {
        id: '6568',
        ort: 'Langelsheim',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Goslar',
        plz: '38729',
    },
    {
        id: '6569',
        ort: 'Langen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Offenbach',
        plz: '63225',
    },
    {
        id: '6570',
        ort: 'Langen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49838',
    },
    {
        id: '6571',
        ort: 'Langenaltheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91799',
    },
    {
        id: '6572',
        ort: 'Langenargen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88085',
    },
    {
        id: '6573',
        ort: 'Langenau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89129',
    },
    {
        id: '6574',
        ort: 'Langenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85416',
    },
    {
        id: '6575',
        ort: 'Langenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66909',
    },
    {
        id: '6576',
        ort: 'Langenbach bei Kirburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57520',
    },
    {
        id: '6577',
        ort: 'Langenberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Gütersloh',
        plz: '33449',
    },
    {
        id: '6578',
        ort: 'Langenbernsdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08428',
    },
    {
        id: '6579',
        ort: 'Langenbrettach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74243',
    },
    {
        id: '6580',
        ort: 'Langen Brütz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19067',
    },
    {
        id: '6581',
        ort: 'Langenburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74595',
    },
    {
        id: '6582',
        ort: 'Langendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29484',
    },
    {
        id: '6583',
        ort: 'Langenenslingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88515',
    },
    {
        id: '6584',
        ort: 'Langeneß',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25863',
    },
    {
        id: '6585',
        ort: 'Langeneß',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25867',
    },
    {
        id: '6586',
        ort: 'Langenfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91474',
    },
    {
        id: '6587',
        ort: 'Langenfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '6588',
        ort: 'Langenfeld (Rheinland)',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '40764',
    },
    {
        id: '6589',
        ort: 'Langenhagen',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30669',
    },
    {
        id: '6590',
        ort: 'Langenhagen',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30851',
    },
    {
        id: '6591',
        ort: 'Langenhagen',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30853',
    },
    {
        id: '6592',
        ort: 'Langenhagen',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30855',
    },
    {
        id: '6593',
        ort: 'Langenhahn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '6594',
        ort: 'Langenhorn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25842',
    },
    {
        id: '6595',
        ort: 'Langenlehsten',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21514',
    },
    {
        id: '6596',
        ort: 'Langenleuba-Niederhain',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04618',
    },
    {
        id: '6597',
        ort: 'Langenlonsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55450',
    },
    {
        id: '6598',
        ort: 'Langenmosen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neuburg-Schrobenhausen',
        plz: '86571',
    },
    {
        id: '6599',
        ort: 'Langenneufnach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86863',
    },
    {
        id: '6600',
        ort: 'Langenorla',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07381',
    },
    {
        id: '6601',
        ort: 'Langenpreising',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '85465',
    },
    {
        id: '6602',
        ort: 'Langenscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65558',
    },
    {
        id: '6603',
        ort: 'Langenselbold',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63505',
    },
    {
        id: '6604',
        ort: 'Langensendelbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91094',
    },
    {
        id: '6605',
        ort: 'Langenthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55569',
    },
    {
        id: '6606',
        ort: 'Langenweißbach',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08134',
    },
    {
        id: '6607',
        ort: 'Langenwetzendorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07957',
    },
    {
        id: '6608',
        ort: 'Langenwetzendorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07980',
    },
    {
        id: '6609',
        ort: 'Langenwolschendorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07937',
    },
    {
        id: '6610',
        ort: 'Langenzenn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürth',
        plz: '90579',
    },
    {
        id: '6611',
        ort: 'Langeoog',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26465',
    },
    {
        id: '6612',
        ort: 'Langerringen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86853',
    },
    {
        id: '6613',
        ort: 'Langerwehe',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Düren',
        plz: '52379',
    },
    {
        id: '6614',
        ort: 'Langewahl',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15518',
    },
    {
        id: '6615',
        ort: 'Langfurth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91731',
    },
    {
        id: '6616',
        ort: 'Langgöns',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35428',
    },
    {
        id: '6617',
        ort: 'Langlingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29364',
    },
    {
        id: '6618',
        ort: 'Langquaid',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '84085',
    },
    {
        id: '6619',
        ort: 'Langscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '6620',
        ort: 'Langstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24852',
    },
    {
        id: '6621',
        ort: 'Langsur',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54308',
    },
    {
        id: '6622',
        ort: 'Langwedel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24631',
    },
    {
        id: '6623',
        ort: 'Langwedel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Verden',
        plz: '27299',
    },
    {
        id: '6624',
        ort: 'Langweid am Lech',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86462',
    },
    {
        id: '6625',
        ort: 'Langweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '6626',
        ort: 'Langweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67746',
    },
    {
        id: '6627',
        ort: 'Langwieden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66894',
    },
    {
        id: '6628',
        ort: 'Lanitz-Hassel-Tal',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06628',
    },
    {
        id: '6629',
        ort: 'Lankau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23881',
    },
    {
        id: '6630',
        ort: 'Lanz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '19309',
    },
    {
        id: '6631',
        ort: 'Lanze',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21483',
    },
    {
        id: '6632',
        ort: 'Lappersdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93138',
    },
    {
        id: '6633',
        ort: 'Lärz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17248',
    },
    {
        id: '6634',
        ort: 'Lasbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23847',
    },
    {
        id: '6635',
        ort: 'Lascheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '6636',
        ort: 'Lasel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54612',
    },
    {
        id: '6637',
        ort: 'Lassan',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17440',
    },
    {
        id: '6638',
        ort: 'Lastrup',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cloppenburg',
        plz: '49688',
    },
    {
        id: '6639',
        ort: 'Latendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24598',
    },
    {
        id: '6640',
        ort: 'Lathen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49762',
    },
    {
        id: '6641',
        ort: 'Laubach',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35321',
    },
    {
        id: '6642',
        ort: 'Laubach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56288',
    },
    {
        id: '6643',
        ort: 'Laubach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56759',
    },
    {
        id: '6644',
        ort: 'Lauben',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87493',
    },
    {
        id: '6645',
        ort: 'Lauben',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87761',
    },
    {
        id: '6646',
        ort: 'Laubenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55452',
    },
    {
        id: '6647',
        ort: 'Laucha an der Unstrut',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06636',
    },
    {
        id: '6648',
        ort: 'Lauchhammer',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01979',
    },
    {
        id: '6649',
        ort: 'Lauchheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73466',
    },
    {
        id: '6650',
        ort: 'Lauchringen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79787',
    },
    {
        id: '6651',
        ort: 'Lauda-Königshofen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Main-Tauber-Kreis',
        plz: '97922',
    },
    {
        id: '6652',
        ort: 'Laudenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69514',
    },
    {
        id: '6653',
        ort: 'Laudenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63925',
    },
    {
        id: '6654',
        ort: 'Laudert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56291',
    },
    {
        id: '6655',
        ort: 'Lauenau',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31867',
    },
    {
        id: '6656',
        ort: 'Lauenbrück',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27389',
    },
    {
        id: '6657',
        ort: 'Lauenburg/Elbe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21481',
    },
    {
        id: '6658',
        ort: 'Lauenförde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37697',
    },
    {
        id: '6659',
        ort: 'Lauenhagen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31714',
    },
    {
        id: '6660',
        ort: 'Lauf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77886',
    },
    {
        id: '6661',
        ort: 'Laufach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63846',
    },
    {
        id: '6662',
        ort: 'Lauf an der Pegnitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91207',
    },
    {
        id: '6663',
        ort: 'Laufeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54533',
    },
    {
        id: '6664',
        ort: 'Laufen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Berchtesgadener Land',
        plz: '83410',
    },
    {
        id: '6665',
        ort: 'Laufenburg (Baden)',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79725',
    },
    {
        id: '6666',
        ort: 'Laufersweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55487',
    },
    {
        id: '6667',
        ort: 'Lauffen am Neckar',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74348',
    },
    {
        id: '6668',
        ort: 'Laugna',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '86502',
    },
    {
        id: '6669',
        ort: 'Lauingen (Donau)',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89415',
    },
    {
        id: '6670',
        ort: 'Laumersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67229',
    },
    {
        id: '6671',
        ort: 'Lauperath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54649',
    },
    {
        id: '6672',
        ort: 'Laupheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88471',
    },
    {
        id: '6673',
        ort: 'Laurenburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56379',
    },
    {
        id: '6674',
        ort: 'Lauscha',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sonneberg',
        plz: '98724',
    },
    {
        id: '6675',
        ort: 'Lauschied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55568',
    },
    {
        id: '6676',
        ort: 'Lausnitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07806',
    },
    {
        id: '6677',
        ort: 'Laußig',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04838',
    },
    {
        id: '6678',
        ort: 'Laußig',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04849',
    },
    {
        id: '6679',
        ort: 'Laußnitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01936',
    },
    {
        id: '6680',
        ort: 'Lauta',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02991',
    },
    {
        id: '6681',
        ort: 'Lautenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77794',
    },
    {
        id: '6682',
        ort: 'Lauter',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96169',
    },
    {
        id: '6683',
        ort: 'Lauterach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89584',
    },
    {
        id: '6684',
        ort: 'Lauterbach',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '36341',
    },
    {
        id: '6685',
        ort: 'Lauterbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78730',
    },
    {
        id: '6686',
        ort: 'Lauterbach',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '99826',
    },
    {
        id: '6687',
        ort: 'Lauter-Bernsbach',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '08315',
    },
    {
        id: '6688',
        ort: 'Lauterecken',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67742',
    },
    {
        id: '6689',
        ort: 'Lauterhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92283',
    },
    {
        id: '6690',
        ort: 'Lautersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67308',
    },
    {
        id: '6691',
        ort: 'Lauterstein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73111',
    },
    {
        id: '6692',
        ort: 'Lautert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56355',
    },
    {
        id: '6693',
        ort: 'Lautertal',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '36369',
    },
    {
        id: '6694',
        ort: 'Lautertal',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '64686',
    },
    {
        id: '6695',
        ort: 'Lautertal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Coburg',
        plz: '96486',
    },
    {
        id: '6696',
        ort: 'Lautrach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87763',
    },
    {
        id: '6697',
        ort: 'Lautzenbrücken',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56472',
    },
    {
        id: '6698',
        ort: 'Lautzenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55483',
    },
    {
        id: '6699',
        ort: 'Lawalde',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02708',
    },
    {
        id: '6700',
        ort: 'Lawitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15898',
    },
    {
        id: '6701',
        ort: 'Lebach',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Saarlouis',
        plz: '66822',
    },
    {
        id: '6702',
        ort: 'Lebrade',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24306',
    },
    {
        id: '6703',
        ort: 'Lebus',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15326',
    },
    {
        id: '6704',
        ort: 'Lebusa',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04936',
    },
    {
        id: '6705',
        ort: 'Lechbruck a.See',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '86983',
    },
    {
        id: '6706',
        ort: 'Leck',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25917',
    },
    {
        id: '6707',
        ort: 'Lederhose',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07589',
    },
    {
        id: '6708',
        ort: 'Leegebruch',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16767',
    },
    {
        id: '6709',
        ort: 'Leer',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26789',
    },
    {
        id: '6710',
        ort: 'Leese',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31633',
    },
    {
        id: '6711',
        ort: 'Leezdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26529',
    },
    {
        id: '6712',
        ort: 'Leezen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23816',
    },
    {
        id: '6713',
        ort: 'Leezen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19067',
    },
    {
        id: '6714',
        ort: 'Legau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87764',
    },
    {
        id: '6715',
        ort: 'Legden',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '48739',
    },
    {
        id: '6716',
        ort: 'Legde/Quitzöbel',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '19336',
    },
    {
        id: '6717',
        ort: 'Lehe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25774',
    },
    {
        id: '6718',
        ort: 'Lehe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25776',
    },
    {
        id: '6719',
        ort: 'Lehe',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26892',
    },
    {
        id: '6720',
        ort: 'Lehesten',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07349',
    },
    {
        id: '6721',
        ort: 'Lehesten',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07778',
    },
    {
        id: '6722',
        ort: 'Lehmen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56332',
    },
    {
        id: '6723',
        ort: 'Lehmkuhlen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24211',
    },
    {
        id: '6724',
        ort: 'Lehmrade',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23883',
    },
    {
        id: '6725',
        ort: 'Lehnstedt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99441',
    },
    {
        id: '6726',
        ort: 'Lehrberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91611',
    },
    {
        id: '6727',
        ort: 'Lehre',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38165',
    },
    {
        id: '6728',
        ort: 'Lehrensteinsfeld',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74251',
    },
    {
        id: '6729',
        ort: 'Lehrte',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '31275',
    },
    {
        id: '6730',
        ort: 'Leibertingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '88637',
    },
    {
        id: '6731',
        ort: 'Leiblfing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94339',
    },
    {
        id: '6732',
        ort: 'Leichlingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rheinisch-Bergischer Kreis',
        plz: '42799',
    },
    {
        id: '6733',
        ort: 'Leidenborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54619',
    },
    {
        id: '6734',
        ort: 'Leidersbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63849',
    },
    {
        id: '6735',
        ort: 'Leienkaul',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56759',
    },
    {
        id: '6736',
        ort: 'Leiferde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38542',
    },
    {
        id: '6737',
        ort: 'Leimbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53518',
    },
    {
        id: '6738',
        ort: 'Leimbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '6739',
        ort: 'Leimbach',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '36433',
    },
    {
        id: '6740',
        ort: 'Leimen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69181',
    },
    {
        id: '6741',
        ort: 'Leimen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66978',
    },
    {
        id: '6742',
        ort: 'Leimersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76774',
    },
    {
        id: '6743',
        ort: 'Leinach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97274',
    },
    {
        id: '6744',
        ort: 'Leinburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91227',
    },
    {
        id: '6745',
        ort: 'Leinefelde-Worbis',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37327',
    },
    {
        id: '6746',
        ort: 'Leinefelde-Worbis',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37339',
    },
    {
        id: '6747',
        ort: 'Leinfelden-Echterdingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '70771',
    },
    {
        id: '6748',
        ort: 'Leingarten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74211',
    },
    {
        id: '6749',
        ort: 'Leiningen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56291',
    },
    {
        id: '6750',
        ort: 'Leinsweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76829',
    },
    {
        id: '6751',
        ort: 'Leinzell',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73575',
    },
    {
        id: '6752',
        ort: 'Leipheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89340',
    },
    {
        id: '6753',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04103',
    },
    {
        id: '6754',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04105',
    },
    {
        id: '6755',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04107',
    },
    {
        id: '6756',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04109',
    },
    {
        id: '6757',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04129',
    },
    {
        id: '6758',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04155',
    },
    {
        id: '6759',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04157',
    },
    {
        id: '6760',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04158',
    },
    {
        id: '6761',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04159',
    },
    {
        id: '6762',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04177',
    },
    {
        id: '6763',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04178',
    },
    {
        id: '6764',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04179',
    },
    {
        id: '6765',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04205',
    },
    {
        id: '6766',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04207',
    },
    {
        id: '6767',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04209',
    },
    {
        id: '6768',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04229',
    },
    {
        id: '6769',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04249',
    },
    {
        id: '6770',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04275',
    },
    {
        id: '6771',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04277',
    },
    {
        id: '6772',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04279',
    },
    {
        id: '6773',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04288',
    },
    {
        id: '6774',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04289',
    },
    {
        id: '6775',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04299',
    },
    {
        id: '6776',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04315',
    },
    {
        id: '6777',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04316',
    },
    {
        id: '6778',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04317',
    },
    {
        id: '6779',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04318',
    },
    {
        id: '6780',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04319',
    },
    {
        id: '6781',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04328',
    },
    {
        id: '6782',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04329',
    },
    {
        id: '6783',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04347',
    },
    {
        id: '6784',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04349',
    },
    {
        id: '6785',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04356',
    },
    {
        id: '6786',
        ort: 'Leipzig',
        bundesland: 'Sachsen',
        landkreis: '',
        plz: '04357',
    },
    {
        id: '6787',
        ort: 'Leisel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '6788',
        ort: 'Leisnig',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '04703',
    },
    {
        id: '6789',
        ort: 'Leitzweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55779',
    },
    {
        id: '6790',
        ort: 'Leiwen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54340',
    },
    {
        id: '6791',
        ort: 'Leizen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17209',
    },
    {
        id: '6792',
        ort: 'Lelkendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17168',
    },
    {
        id: '6793',
        ort: 'Lemberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66969',
    },
    {
        id: '6794',
        ort: 'Lembruch',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49459',
    },
    {
        id: '6795',
        ort: 'Lemförde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49448',
    },
    {
        id: '6796',
        ort: 'Lemgo',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '32657',
    },
    {
        id: '6797',
        ort: 'Lemgow',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29485',
    },
    {
        id: '6798',
        ort: 'Lemnitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07819',
    },
    {
        id: '6799',
        ort: 'Lemwerder',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wesermarsch',
        plz: '27809',
    },
    {
        id: '6800',
        ort: 'Lengdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '84435',
    },
    {
        id: '6801',
        ort: 'Lengede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Peine',
        plz: '38268',
    },
    {
        id: '6802',
        ort: 'Lengenbostel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27419',
    },
    {
        id: '6803',
        ort: 'Lengenfeld',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08485',
    },
    {
        id: '6804',
        ort: 'Lengenwang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87663',
    },
    {
        id: '6805',
        ort: 'Lengerich',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '49525',
    },
    {
        id: '6806',
        ort: 'Lengerich',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49838',
    },
    {
        id: '6807',
        ort: 'Lengfeld',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98660',
    },
    {
        id: '6808',
        ort: 'Lenggries',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '83661',
    },
    {
        id: '6809',
        ort: 'Lenne',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37627',
    },
    {
        id: '6810',
        ort: 'Lennestadt',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Olpe',
        plz: '57368',
    },
    {
        id: '6811',
        ort: 'Lenningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73252',
    },
    {
        id: '6812',
        ort: 'Lenningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73266',
    },
    {
        id: '6813',
        ort: 'Lensahn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23738',
    },
    {
        id: '6814',
        ort: 'Lenterode',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '6815',
        ort: 'Lentföhrden',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24632',
    },
    {
        id: '6816',
        ort: 'Lentföhrden',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24640',
    },
    {
        id: '6817',
        ort: 'Lenting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85101',
    },
    {
        id: '6818',
        ort: 'Lenzen (Elbe)',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '19309',
    },
    {
        id: '6819',
        ort: 'Lenzerwische',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '19309',
    },
    {
        id: '6820',
        ort: 'Lenzkirch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79853',
    },
    {
        id: '6821',
        ort: 'Leonberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71229',
    },
    {
        id: '6822',
        ort: 'Leonberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95666',
    },
    {
        id: '6823',
        ort: 'Leopoldshagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17375',
    },
    {
        id: '6824',
        ort: 'Leopoldshöhe',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '33818',
    },
    {
        id: '6825',
        ort: 'Letschin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15324',
    },
    {
        id: '6826',
        ort: 'Lettweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '67823',
    },
    {
        id: '6827',
        ort: 'Leubsdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09573',
    },
    {
        id: '6828',
        ort: 'Leubsdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53547',
    },
    {
        id: '6829',
        ort: 'Leuchtenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92705',
    },
    {
        id: '6830',
        ort: 'Leun',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35638',
    },
    {
        id: '6831',
        ort: 'Leuna',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06237',
    },
    {
        id: '6832',
        ort: 'Leupoldsgrün',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95191',
    },
    {
        id: '6833',
        ort: 'Leutenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71397',
    },
    {
        id: '6834',
        ort: 'Leutenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91359',
    },
    {
        id: '6835',
        ort: 'Leutenberg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07338',
    },
    {
        id: '6836',
        ort: 'Leuterod',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '6837',
        ort: 'Leutersdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02794',
    },
    {
        id: '6838',
        ort: 'Leutersdorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98617',
    },
    {
        id: '6839',
        ort: 'Leutershausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91578',
    },
    {
        id: '6840',
        ort: 'Leutesdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56599',
    },
    {
        id: '6841',
        ort: 'Leutkirch im Allgäu',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88299',
    },
    {
        id: '6842',
        ort: 'Levenhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17498',
    },
    {
        id: '6843',
        ort: 'Leverkusen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51371',
    },
    {
        id: '6844',
        ort: 'Leverkusen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51373',
    },
    {
        id: '6845',
        ort: 'Leverkusen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51375',
    },
    {
        id: '6846',
        ort: 'Leverkusen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51377',
    },
    {
        id: '6847',
        ort: 'Leverkusen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51379',
    },
    {
        id: '6848',
        ort: 'Leverkusen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '51381',
    },
    {
        id: '6849',
        ort: 'Lewitzrand',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19372',
    },
    {
        id: '6850',
        ort: 'Lewitzrand',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19374',
    },
    {
        id: '6851',
        ort: 'Lexgaard',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25923',
    },
    {
        id: '6852',
        ort: 'Lich',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35423',
    },
    {
        id: '6853',
        ort: 'Lichtenau',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Paderborn',
        plz: '33165',
    },
    {
        id: '6854',
        ort: 'Lichtenau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09244',
    },
    {
        id: '6855',
        ort: 'Lichtenau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91586',
    },
    {
        id: '6856',
        ort: 'Lichtenau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '77839',
    },
    {
        id: '6857',
        ort: 'Lichtenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95192',
    },
    {
        id: '6858',
        ort: 'Lichtenberg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01896',
    },
    {
        id: '6859',
        ort: 'Lichtenberg/Erzgebirge',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09638',
    },
    {
        id: '6860',
        ort: 'Lichtenborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54619',
    },
    {
        id: '6861',
        ort: 'Lichtenfels',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '35104',
    },
    {
        id: '6862',
        ort: 'Lichtenfels',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lichtenfels',
        plz: '96215',
    },
    {
        id: '6863',
        ort: 'Lichtenstein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72805',
    },
    {
        id: '6864',
        ort: 'Lichtenstein/Sachsen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '09350',
    },
    {
        id: '6865',
        ort: 'Lichtentanne',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08115',
    },
    {
        id: '6866',
        ort: 'Lichtenwald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73669',
    },
    {
        id: '6867',
        ort: 'Lichterfeld-Schacksdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '03238',
    },
    {
        id: '6868',
        ort: 'Liebenau',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34396',
    },
    {
        id: '6869',
        ort: 'Liebenau',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31618',
    },
    {
        id: '6870',
        ort: 'Liebenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Goslar',
        plz: '38704',
    },
    {
        id: '6871',
        ort: 'Liebenscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '6872',
        ort: 'Liebenwalde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16559',
    },
    {
        id: '6873',
        ort: 'Lieberose',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15868',
    },
    {
        id: '6874',
        ort: 'Liebschützberg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04758',
    },
    {
        id: '6875',
        ort: 'Liebshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55494',
    },
    {
        id: '6876',
        ort: 'Liebstadt',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01825',
    },
    {
        id: '6877',
        ort: 'Liederbach am Taunus',
        bundesland: 'Hessen',
        landkreis: 'Main-Taunus-Kreis',
        plz: '65835',
    },
    {
        id: '6878',
        ort: 'Lieg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56290',
    },
    {
        id: '6879',
        ort: 'Lienen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '49536',
    },
    {
        id: '6880',
        ort: 'Liepe',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16248',
    },
    {
        id: '6881',
        ort: 'Liepgarten',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17375',
    },
    {
        id: '6882',
        ort: 'Lierfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '6883',
        ort: 'Lierschied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '6884',
        ort: 'Liesenich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56858',
    },
    {
        id: '6885',
        ort: 'Lieser',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54470',
    },
    {
        id: '6886',
        ort: 'Ließem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '6887',
        ort: 'Lieth',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25770',
    },
    {
        id: '6888',
        ort: 'Lietzen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15306',
    },
    {
        id: '6889',
        ort: 'Lietzow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18528',
    },
    {
        id: '6890',
        ort: 'Lilienthal',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osterholz',
        plz: '28865',
    },
    {
        id: '6891',
        ort: 'Limbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74838',
    },
    {
        id: '6892',
        ort: 'Limbach',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08468',
    },
    {
        id: '6893',
        ort: 'Limbach',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08491',
    },
    {
        id: '6894',
        ort: 'Limbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57629',
    },
    {
        id: '6895',
        ort: 'Limbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55606',
    },
    {
        id: '6896',
        ort: 'Limbach-Oberfrohna',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '09212',
    },
    {
        id: '6897',
        ort: 'Limburg an der Lahn',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65549',
    },
    {
        id: '6898',
        ort: 'Limburg an der Lahn',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65550',
    },
    {
        id: '6899',
        ort: 'Limburg an der Lahn',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65551',
    },
    {
        id: '6900',
        ort: 'Limburg an der Lahn',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65552',
    },
    {
        id: '6901',
        ort: 'Limburg an der Lahn',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65553',
    },
    {
        id: '6902',
        ort: 'Limburg an der Lahn',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65554',
    },
    {
        id: '6903',
        ort: 'Limburg an der Lahn',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65555',
    },
    {
        id: '6904',
        ort: 'Limburg an der Lahn',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65556',
    },
    {
        id: '6905',
        ort: 'Limburgerhof',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67117',
    },
    {
        id: '6906',
        ort: 'Limeshain',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '63694',
    },
    {
        id: '6907',
        ort: 'Linau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '22959',
    },
    {
        id: '6908',
        ort: 'Lind',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '6909',
        ort: 'Lind',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53506',
    },
    {
        id: '6910',
        ort: 'Linda b. Weida',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07580',
    },
    {
        id: '6911',
        ort: 'Lindau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24214',
    },
    {
        id: '6912',
        ort: 'Lindau (Bodensee)',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88131',
    },
    {
        id: '6913',
        ort: 'Lindberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94227',
    },
    {
        id: '6914',
        ort: 'Linden',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35440',
    },
    {
        id: '6915',
        ort: 'Linden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57629',
    },
    {
        id: '6916',
        ort: 'Linden',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25791',
    },
    {
        id: '6917',
        ort: 'Linden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66851',
    },
    {
        id: '6918',
        ort: 'Linden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67706',
    },
    {
        id: '6919',
        ort: 'Lindenau',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01945',
    },
    {
        id: '6920',
        ort: 'Lindenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67473',
    },
    {
        id: '6921',
        ort: 'Lindenberg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17111',
    },
    {
        id: '6922',
        ort: 'Lindenberg im Allgäu',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88161',
    },
    {
        id: '6923',
        ort: 'Lindendorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15306',
    },
    {
        id: '6924',
        ort: 'Lindenfels',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '64678',
    },
    {
        id: '6925',
        ort: 'Lindenkreuz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07589',
    },
    {
        id: '6926',
        ort: 'Lindenschied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55481',
    },
    {
        id: '6927',
        ort: 'Lindern (Oldenburg)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cloppenburg',
        plz: '49699',
    },
    {
        id: '6928',
        ort: 'Lindetal',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17349',
    },
    {
        id: '6929',
        ort: 'Lindewerra',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '6930',
        ort: 'Lindewitt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24969',
    },
    {
        id: '6931',
        ort: 'Lindholz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18334',
    },
    {
        id: '6932',
        ort: 'Lindhorst',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31698',
    },
    {
        id: '6933',
        ort: 'Lindig',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07768',
    },
    {
        id: '6934',
        ort: 'Lindlar',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Oberbergischer Kreis',
        plz: '51789',
    },
    {
        id: '6935',
        ort: 'Lindow (Mark)',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16835',
    },
    {
        id: '6936',
        ort: 'Lindwedel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29690',
    },
    {
        id: '6937',
        ort: 'Lingen (Ems)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49808',
    },
    {
        id: '6938',
        ort: 'Lingen (Ems)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49809',
    },
    {
        id: '6939',
        ort: 'Lingen (Ems)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49811',
    },
    {
        id: '6940',
        ort: 'Lingenfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '67360',
    },
    {
        id: '6941',
        ort: 'Lingerhahn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56291',
    },
    {
        id: '6942',
        ort: 'Linkenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56317',
    },
    {
        id: '6943',
        ort: 'Linkenheim-Hochstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76351',
    },
    {
        id: '6944',
        ort: 'Linnich',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Düren',
        plz: '52441',
    },
    {
        id: '6945',
        ort: 'Linsburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31636',
    },
    {
        id: '6946',
        ort: 'Linsengericht',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63589',
    },
    {
        id: '6947',
        ort: 'Linthe',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14822',
    },
    {
        id: '6948',
        ort: 'Linz am Rhein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53545',
    },
    {
        id: '6949',
        ort: 'Linz am Rhein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53560',
    },
    {
        id: '6950',
        ort: 'Lippersdorf-Erdmannsdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '6951',
        ort: 'Lippetal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Soest',
        plz: '59510',
    },
    {
        id: '6952',
        ort: 'Lipporn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '6953',
        ort: 'Lipprechterode',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Nordhausen',
        plz: '99752',
    },
    {
        id: '6954',
        ort: 'Lippstadt',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Soest',
        plz: '59555',
    },
    {
        id: '6955',
        ort: 'Lippstadt',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Soest',
        plz: '59556',
    },
    {
        id: '6956',
        ort: 'Lippstadt',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Soest',
        plz: '59557',
    },
    {
        id: '6957',
        ort: 'Lippstadt',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Soest',
        plz: '59558',
    },
    {
        id: '6958',
        ort: 'Lirstal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '56767',
    },
    {
        id: '6959',
        ort: 'Lisberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96170',
    },
    {
        id: '6960',
        ort: 'Lissendorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54587',
    },
    {
        id: '6961',
        ort: 'List auf Sylt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25992',
    },
    {
        id: '6962',
        ort: 'Litzendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96123',
    },
    {
        id: '6963',
        ort: 'Löbau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02708',
    },
    {
        id: '6964',
        ort: 'Lobbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '74931',
    },
    {
        id: '6965',
        ort: 'Löberschütz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07751',
    },
    {
        id: '6966',
        ort: 'Löbichau',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04626',
    },
    {
        id: '6967',
        ort: 'Löbnitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18314',
    },
    {
        id: '6968',
        ort: 'Löbnitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04509',
    },
    {
        id: '6969',
        ort: 'Löchgau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '74369',
    },
    {
        id: '6970',
        ort: 'Lochum',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57629',
    },
    {
        id: '6971',
        ort: 'Löcknitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17321',
    },
    {
        id: '6972',
        ort: 'Lockstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25551',
    },
    {
        id: '6973',
        ort: 'Loddin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17459',
    },
    {
        id: '6974',
        ort: 'Lödla',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04617',
    },
    {
        id: '6975',
        ort: 'Löf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56332',
    },
    {
        id: '6976',
        ort: 'Loffenau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '76597',
    },
    {
        id: '6977',
        ort: 'Löffingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79843',
    },
    {
        id: '6978',
        ort: 'Lohbarbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25551',
    },
    {
        id: '6979',
        ort: 'Lohberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93470',
    },
    {
        id: '6980',
        ort: 'Lohe-Föhrden',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24806',
    },
    {
        id: '6981',
        ort: 'Lohe-Rickelshof',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25746',
    },
    {
        id: '6982',
        ort: 'Lohfelden',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34253',
    },
    {
        id: '6983',
        ort: 'Lohheide',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29303',
    },
    {
        id: '6984',
        ort: 'Lohkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84494',
    },
    {
        id: '6985',
        ort: 'Löhma',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07907',
    },
    {
        id: '6986',
        ort: 'Lohmar',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53797',
    },
    {
        id: '6987',
        ort: 'Lohme',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18551',
    },
    {
        id: '6988',
        ort: 'Lohmen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01847',
    },
    {
        id: '6989',
        ort: 'Lohmen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18276',
    },
    {
        id: '6990',
        ort: 'Löhnberg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '35792',
    },
    {
        id: '6991',
        ort: 'Lohne',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Vechta',
        plz: '49393',
    },
    {
        id: '6992',
        ort: 'Löhne',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Herford',
        plz: '32584',
    },
    {
        id: '6993',
        ort: 'Lohnsfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67727',
    },
    {
        id: '6994',
        ort: 'Lohnweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67744',
    },
    {
        id: '6995',
        ort: 'Lohra',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35102',
    },
    {
        id: '6996',
        ort: 'Lohr am Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97816',
    },
    {
        id: '6997',
        ort: 'Lohrheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65558',
    },
    {
        id: '6998',
        ort: 'Lohsa - Łaz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02999',
    },
    {
        id: '6999',
        ort: 'Loiching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dingolfing-Landau',
        plz: '84180',
    },
    {
        id: '7000',
        ort: 'Loissin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17509',
    },
    {
        id: '7001',
        ort: 'Loit',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24888',
    },
    {
        id: '7002',
        ort: 'Loitsche-Heinrichsberg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39326',
    },
    {
        id: '7003',
        ort: 'Loitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17121',
    },
    {
        id: '7004',
        ort: 'Loitzendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94359',
    },
    {
        id: '7005',
        ort: 'Lollar',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35457',
    },
    {
        id: '7006',
        ort: 'Löllbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '67744',
    },
    {
        id: '7007',
        ort: 'Lollschied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '7008',
        ort: 'Lommatzsch',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01623',
    },
    {
        id: '7009',
        ort: 'Longen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54338',
    },
    {
        id: '7010',
        ort: 'Longkamp',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54472',
    },
    {
        id: '7011',
        ort: 'Longuich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54340',
    },
    {
        id: '7012',
        ort: 'Löningen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cloppenburg',
        plz: '49624',
    },
    {
        id: '7013',
        ort: 'Lonnerstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91475',
    },
    {
        id: '7014',
        ort: 'Lonnig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56295',
    },
    {
        id: '7015',
        ort: 'Lonsee',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89173',
    },
    {
        id: '7016',
        ort: 'Lonsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55237',
    },
    {
        id: '7017',
        ort: 'Looft',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25582',
    },
    {
        id: '7018',
        ort: 'Loop',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24644',
    },
    {
        id: '7019',
        ort: 'Loose',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24366',
    },
    {
        id: '7020',
        ort: 'Löptin',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24250',
    },
    {
        id: '7021',
        ort: 'Lorch',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65391',
    },
    {
        id: '7022',
        ort: 'Lorch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73547',
    },
    {
        id: '7023',
        ort: 'Lörrach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79539',
    },
    {
        id: '7024',
        ort: 'Lörrach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79540',
    },
    {
        id: '7025',
        ort: 'Lörrach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79541',
    },
    {
        id: '7026',
        ort: 'Lorsch',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '64653',
    },
    {
        id: '7027',
        ort: 'Lorscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54317',
    },
    {
        id: '7028',
        ort: 'Lorup',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26901',
    },
    {
        id: '7029',
        ort: 'Lörzweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55296',
    },
    {
        id: '7030',
        ort: 'Losheim am See',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Merzig-Wadern',
        plz: '66679',
    },
    {
        id: '7031',
        ort: 'Lösnich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54492',
    },
    {
        id: '7032',
        ort: 'Lossatal',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04808',
    },
    {
        id: '7033',
        ort: 'Loßburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Freudenstadt',
        plz: '72290',
    },
    {
        id: '7034',
        ort: 'Lößnitz',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '08294',
    },
    {
        id: '7035',
        ort: 'Lotte',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '49504',
    },
    {
        id: '7036',
        ort: 'Lottorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24878',
    },
    {
        id: '7037',
        ort: 'Lottstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79807',
    },
    {
        id: '7038',
        ort: 'Lötzbeuren',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '56843',
    },
    {
        id: '7039',
        ort: 'Löwenberger Land',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16775',
    },
    {
        id: '7040',
        ort: 'Löwenstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25864',
    },
    {
        id: '7041',
        ort: 'Löwenstein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74245',
    },
    {
        id: '7042',
        ort: 'Loxstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '27612',
    },
    {
        id: '7043',
        ort: 'Lübbecke',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Minden-Lübbecke',
        plz: '32312',
    },
    {
        id: '7044',
        ort: 'Lübbenau/Spreewald',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '03222',
    },
    {
        id: '7045',
        ort: 'Lübben (Spreewald)',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15907',
    },
    {
        id: '7046',
        ort: 'Lübberstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osterholz',
        plz: '27729',
    },
    {
        id: '7047',
        ort: 'Lübberstorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23992',
    },
    {
        id: '7048',
        ort: 'Lübbow',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29488',
    },
    {
        id: '7049',
        ort: 'Lübeck',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '23552',
    },
    {
        id: '7050',
        ort: 'Lübeck',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '23554',
    },
    {
        id: '7051',
        ort: 'Lübeck',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '23556',
    },
    {
        id: '7052',
        ort: 'Lübeck',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '23558',
    },
    {
        id: '7053',
        ort: 'Lübeck',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '23560',
    },
    {
        id: '7054',
        ort: 'Lübeck',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '23562',
    },
    {
        id: '7055',
        ort: 'Lübeck',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '23564',
    },
    {
        id: '7056',
        ort: 'Lübeck',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '23566',
    },
    {
        id: '7057',
        ort: 'Lübeck',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '23568',
    },
    {
        id: '7058',
        ort: 'Lübeck',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '23569',
    },
    {
        id: '7059',
        ort: 'Lübeck',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '23570',
    },
    {
        id: '7060',
        ort: 'Lübesse',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19077',
    },
    {
        id: '7061',
        ort: 'Lüblow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19288',
    },
    {
        id: '7062',
        ort: 'Lubmin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17509',
    },
    {
        id: '7063',
        ort: 'Lübow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23972',
    },
    {
        id: '7064',
        ort: 'Lübs',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17379',
    },
    {
        id: '7065',
        ort: 'Lübstorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19069',
    },
    {
        id: '7066',
        ort: 'Lübtheen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19249',
    },
    {
        id: '7067',
        ort: 'Lübz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19386',
    },
    {
        id: '7068',
        ort: 'Lüchow',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23898',
    },
    {
        id: '7069',
        ort: 'Lüchow',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29439',
    },
    {
        id: '7070',
        ort: 'Lucka',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04613',
    },
    {
        id: '7071',
        ort: 'Luckaitztal',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '03229',
    },
    {
        id: '7072',
        ort: 'Luckau',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15926',
    },
    {
        id: '7073',
        ort: 'Luckau (Wendland)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29487',
    },
    {
        id: '7074',
        ort: 'Luckenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57629',
    },
    {
        id: '7075',
        ort: 'Lückenburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54424',
    },
    {
        id: '7076',
        ort: 'Luckenwalde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Teltow-Fläming',
        plz: '14943',
    },
    {
        id: '7077',
        ort: 'Luckow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17375',
    },
    {
        id: '7078',
        ort: 'Lüdenscheid',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58507',
    },
    {
        id: '7079',
        ort: 'Lüdenscheid',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58509',
    },
    {
        id: '7080',
        ort: 'Lüdenscheid',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58511',
    },
    {
        id: '7081',
        ort: 'Lüdenscheid',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58513',
    },
    {
        id: '7082',
        ort: 'Lüdenscheid',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58515',
    },
    {
        id: '7083',
        ort: 'Lüder',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29394',
    },
    {
        id: '7084',
        ort: 'Lüdersburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21379',
    },
    {
        id: '7085',
        ort: 'Lüdersdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23923',
    },
    {
        id: '7086',
        ort: 'Lüdersfeld',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31702',
    },
    {
        id: '7087',
        ort: 'Lüdershagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18314',
    },
    {
        id: '7088',
        ort: 'Lüdinghausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Coesfeld',
        plz: '59348',
    },
    {
        id: '7089',
        ort: 'Ludwigsau',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36251',
    },
    {
        id: '7090',
        ort: 'Ludwigsburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71634',
    },
    {
        id: '7091',
        ort: 'Ludwigsburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71636',
    },
    {
        id: '7092',
        ort: 'Ludwigsburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71638',
    },
    {
        id: '7093',
        ort: 'Ludwigsburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71640',
    },
    {
        id: '7094',
        ort: 'Ludwigsburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71642',
    },
    {
        id: '7095',
        ort: 'Ludwigsburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71672',
    },
    {
        id: '7096',
        ort: 'Ludwigschorgast',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95364',
    },
    {
        id: '7097',
        ort: 'Ludwigsfelde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Teltow-Fläming',
        plz: '14974',
    },
    {
        id: '7098',
        ort: 'Ludwigshafen am Rhein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67059',
    },
    {
        id: '7099',
        ort: 'Ludwigshafen am Rhein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67061',
    },
    {
        id: '7100',
        ort: 'Ludwigshafen am Rhein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67063',
    },
    {
        id: '7101',
        ort: 'Ludwigshafen am Rhein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67065',
    },
    {
        id: '7102',
        ort: 'Ludwigshafen am Rhein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67067',
    },
    {
        id: '7103',
        ort: 'Ludwigshafen am Rhein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67069',
    },
    {
        id: '7104',
        ort: 'Ludwigshafen am Rhein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67071',
    },
    {
        id: '7105',
        ort: 'Ludwigshöhe',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55278',
    },
    {
        id: '7106',
        ort: 'Ludwigslust',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19288',
    },
    {
        id: '7107',
        ort: 'Ludwigsstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kronach',
        plz: '96337',
    },
    {
        id: '7108',
        ort: 'Ludwigswinkel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66996',
    },
    {
        id: '7109',
        ort: 'Lüerdissen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37635',
    },
    {
        id: '7110',
        ort: 'Lug',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '76848',
    },
    {
        id: '7111',
        ort: 'Lugau',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09385',
    },
    {
        id: '7112',
        ort: 'Lügde',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '32676',
    },
    {
        id: '7113',
        ort: 'Luhden',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31711',
    },
    {
        id: '7114',
        ort: 'Luhe-Wildenau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92706',
    },
    {
        id: '7115',
        ort: 'Luhnstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24816',
    },
    {
        id: '7116',
        ort: 'Luisenthal',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99885',
    },
    {
        id: '7117',
        ort: 'Lülsfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97511',
    },
    {
        id: '7118',
        ort: 'Lunden',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25774',
    },
    {
        id: '7119',
        ort: 'Lünebach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '7120',
        ort: 'Lüneburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21335',
    },
    {
        id: '7121',
        ort: 'Lüneburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21337',
    },
    {
        id: '7122',
        ort: 'Lüneburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21339',
    },
    {
        id: '7123',
        ort: 'Lüneburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21391',
    },
    {
        id: '7124',
        ort: 'Lünen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Unna',
        plz: '44532',
    },
    {
        id: '7125',
        ort: 'Lünen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Unna',
        plz: '44534',
    },
    {
        id: '7126',
        ort: 'Lünen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Unna',
        plz: '44536',
    },
    {
        id: '7127',
        ort: 'Lünne',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '48480',
    },
    {
        id: '7128',
        ort: 'Lunow-Stolzenhagen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16248',
    },
    {
        id: '7129',
        ort: 'Lunzenau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09328',
    },
    {
        id: '7130',
        ort: 'Lupburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92331',
    },
    {
        id: '7131',
        ort: 'Lürschau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24850',
    },
    {
        id: '7132',
        ort: 'Lüssow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18442',
    },
    {
        id: '7133',
        ort: 'Lüssow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18276',
    },
    {
        id: '7134',
        ort: 'Lustadt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '67363',
    },
    {
        id: '7135',
        ort: 'Lütau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21483',
    },
    {
        id: '7136',
        ort: 'Lütetsburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26524',
    },
    {
        id: '7137',
        ort: 'Lutherstadt Eisleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06295',
    },
    {
        id: '7138',
        ort: 'Lütjenburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24321',
    },
    {
        id: '7139',
        ort: 'Lütjenholm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25842',
    },
    {
        id: '7140',
        ort: 'Lütjensee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22952',
    },
    {
        id: '7141',
        ort: 'Lütjenwestedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '25585',
    },
    {
        id: '7142',
        ort: 'Lütow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17440',
    },
    {
        id: '7143',
        ort: 'Lutter',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '7144',
        ort: 'Lutterbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24235',
    },
    {
        id: '7145',
        ort: 'Lüttow-Valluhn',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19246',
    },
    {
        id: '7146',
        ort: 'Lütz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56290',
    },
    {
        id: '7147',
        ort: 'Lützelbach',
        bundesland: 'Hessen',
        landkreis: 'Odenwaldkreis',
        plz: '64750',
    },
    {
        id: '7148',
        ort: 'Lützen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06686',
    },
    {
        id: '7149',
        ort: 'Lutzerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56826',
    },
    {
        id: '7150',
        ort: 'Lutzhorn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25355',
    },
    {
        id: '7151',
        ort: 'Lutzingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89440',
    },
    {
        id: '7152',
        ort: 'Lützkampen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54617',
    },
    {
        id: '7153',
        ort: 'Lützow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19209',
    },
    {
        id: '7154',
        ort: 'Luxem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '7155',
        ort: 'Lychen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17279',
    },
    {
        id: '7156',
        ort: 'Lykershausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56346',
    },
    {
        id: '7157',
        ort: 'Maasen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27249',
    },
    {
        id: '7158',
        ort: 'Maasholm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24404',
    },
    {
        id: '7159',
        ort: 'Machern',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04827',
    },
    {
        id: '7160',
        ort: 'Macken',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56290',
    },
    {
        id: '7161',
        ort: 'Mackenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67686',
    },
    {
        id: '7162',
        ort: 'Mackenrode',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '7163',
        ort: 'Mackenrodt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '7164',
        ort: 'Magdala',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99441',
    },
    {
        id: '7165',
        ort: 'Magdeburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '39104',
    },
    {
        id: '7166',
        ort: 'Magdeburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '39106',
    },
    {
        id: '7167',
        ort: 'Magdeburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '39108',
    },
    {
        id: '7168',
        ort: 'Magdeburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '39110',
    },
    {
        id: '7169',
        ort: 'Magdeburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '39112',
    },
    {
        id: '7170',
        ort: 'Magdeburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '39114',
    },
    {
        id: '7171',
        ort: 'Magdeburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '39116',
    },
    {
        id: '7172',
        ort: 'Magdeburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '39118',
    },
    {
        id: '7173',
        ort: 'Magdeburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '39120',
    },
    {
        id: '7174',
        ort: 'Magdeburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '39122',
    },
    {
        id: '7175',
        ort: 'Magdeburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '39124',
    },
    {
        id: '7176',
        ort: 'Magdeburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '39126',
    },
    {
        id: '7177',
        ort: 'Magdeburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '39128',
    },
    {
        id: '7178',
        ort: 'Magdeburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: '',
        plz: '39130',
    },
    {
        id: '7179',
        ort: 'Magstadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71106',
    },
    {
        id: '7180',
        ort: 'Mahlberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77972',
    },
    {
        id: '7181',
        ort: 'Mahlstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78601',
    },
    {
        id: '7182',
        ort: 'Mähren',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '7183',
        ort: 'Mähring',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95695',
    },
    {
        id: '7184',
        ort: 'Maierhöfen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88167',
    },
    {
        id: '7185',
        ort: 'Maihingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86747',
    },
    {
        id: '7186',
        ort: 'Maikammer',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '67487',
    },
    {
        id: '7187',
        ort: 'Mainaschaff',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63814',
    },
    {
        id: '7188',
        ort: 'Mainbernheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97350',
    },
    {
        id: '7189',
        ort: 'Mainburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '84048',
    },
    {
        id: '7190',
        ort: 'Mainhardt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74535',
    },
    {
        id: '7191',
        ort: 'Mainhausen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Offenbach',
        plz: '63533',
    },
    {
        id: '7192',
        ort: 'Mainleus',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95336',
    },
    {
        id: '7193',
        ort: 'Mainstockheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97320',
    },
    {
        id: '7194',
        ort: 'Maintal',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63477',
    },
    {
        id: '7195',
        ort: 'Mainz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '55116',
    },
    {
        id: '7196',
        ort: 'Mainz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '55118',
    },
    {
        id: '7197',
        ort: 'Mainz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '55120',
    },
    {
        id: '7198',
        ort: 'Mainz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '55122',
    },
    {
        id: '7199',
        ort: 'Mainz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '55124',
    },
    {
        id: '7200',
        ort: 'Mainz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '55126',
    },
    {
        id: '7201',
        ort: 'Mainz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '55127',
    },
    {
        id: '7202',
        ort: 'Mainz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '55128',
    },
    {
        id: '7203',
        ort: 'Mainz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '55129',
    },
    {
        id: '7204',
        ort: 'Mainz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '55130',
    },
    {
        id: '7205',
        ort: 'Mainz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '55131',
    },
    {
        id: '7206',
        ort: 'Maisach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82216',
    },
    {
        id: '7207',
        ort: 'Maisborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56291',
    },
    {
        id: '7208',
        ort: 'Maitenbeth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '83558',
    },
    {
        id: '7209',
        ort: 'Maitzborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55481',
    },
    {
        id: '7210',
        ort: 'Malberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57629',
    },
    {
        id: '7211',
        ort: 'Malberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54655',
    },
    {
        id: '7212',
        ort: 'Malbergweich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54655',
    },
    {
        id: '7213',
        ort: 'Malborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54426',
    },
    {
        id: '7214',
        ort: 'Malchin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17139',
    },
    {
        id: '7215',
        ort: 'Malching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94094',
    },
    {
        id: '7216',
        ort: 'Malchow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17213',
    },
    {
        id: '7217',
        ort: 'Malente',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23714',
    },
    {
        id: '7218',
        ort: 'Malgersdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84333',
    },
    {
        id: '7219',
        ort: 'Malk Göhren',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19294',
    },
    {
        id: '7220',
        ort: 'Mallersdorf-Pfaffenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '84066',
    },
    {
        id: '7221',
        ort: 'Malliß',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19294',
    },
    {
        id: '7222',
        ort: 'Malsburg-Marzell',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79429',
    },
    {
        id: '7223',
        ort: 'Malsch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69254',
    },
    {
        id: '7224',
        ort: 'Malsch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76316',
    },
    {
        id: '7225',
        ort: 'Malschwitz - Malešecy',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02694',
    },
    {
        id: '7226',
        ort: 'Malsfeld',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34323',
    },
    {
        id: '7227',
        ort: 'Malterdingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79364',
    },
    {
        id: '7228',
        ort: 'Mammelzen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57636',
    },
    {
        id: '7229',
        ort: 'Mammendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82291',
    },
    {
        id: '7230',
        ort: 'Mamming',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dingolfing-Landau',
        plz: '94437',
    },
    {
        id: '7231',
        ort: 'Manching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85077',
    },
    {
        id: '7232',
        ort: 'Mandel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '7233',
        ort: 'Mandelbachtal',
        bundesland: 'Saarland',
        landkreis: 'Saarpfalz-Kreis',
        plz: '66399',
    },
    {
        id: '7234',
        ort: 'Mandern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54429',
    },
    {
        id: '7235',
        ort: 'Manderscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54649',
    },
    {
        id: '7236',
        ort: 'Manderscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54531',
    },
    {
        id: '7237',
        ort: 'Manhagen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23738',
    },
    {
        id: '7238',
        ort: 'Mannebach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '56769',
    },
    {
        id: '7239',
        ort: 'Mannebach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54441',
    },
    {
        id: '7240',
        ort: 'Mannheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '68159',
    },
    {
        id: '7241',
        ort: 'Mannheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '68161',
    },
    {
        id: '7242',
        ort: 'Mannheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '68163',
    },
    {
        id: '7243',
        ort: 'Mannheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '68165',
    },
    {
        id: '7244',
        ort: 'Mannheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '68167',
    },
    {
        id: '7245',
        ort: 'Mannheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '68169',
    },
    {
        id: '7246',
        ort: 'Mannheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '68199',
    },
    {
        id: '7247',
        ort: 'Mannheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '68219',
    },
    {
        id: '7248',
        ort: 'Mannheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '68229',
    },
    {
        id: '7249',
        ort: 'Mannheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '68239',
    },
    {
        id: '7250',
        ort: 'Mannheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '68259',
    },
    {
        id: '7251',
        ort: 'Mannheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '68305',
    },
    {
        id: '7252',
        ort: 'Mannheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '68307',
    },
    {
        id: '7253',
        ort: 'Mannheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '68309',
    },
    {
        id: '7254',
        ort: 'Mannweiler-Cölln',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67822',
    },
    {
        id: '7255',
        ort: 'Mansfeld',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06343',
    },
    {
        id: '7256',
        ort: 'Mantel',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92708',
    },
    {
        id: '7257',
        ort: 'Manubach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55413',
    },
    {
        id: '7258',
        ort: 'Marbach am Neckar',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71672',
    },
    {
        id: '7259',
        ort: 'Marbach am Neckar',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71711',
    },
    {
        id: '7260',
        ort: 'Marburg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35037',
    },
    {
        id: '7261',
        ort: 'Marburg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35039',
    },
    {
        id: '7262',
        ort: 'Marburg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35041',
    },
    {
        id: '7263',
        ort: 'Marburg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35043',
    },
    {
        id: '7264',
        ort: 'Marburg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35094',
    },
    {
        id: '7265',
        ort: 'March',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79232',
    },
    {
        id: '7266',
        ort: 'Margetshöchheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97276',
    },
    {
        id: '7267',
        ort: 'Mariaposching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94553',
    },
    {
        id: '7268',
        ort: 'Marienberg',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09496',
    },
    {
        id: '7269',
        ort: 'Marienfels',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '7270',
        ort: 'Marienfließ',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '16945',
    },
    {
        id: '7271',
        ort: 'Marienhafe',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26529',
    },
    {
        id: '7272',
        ort: 'Marienhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56269',
    },
    {
        id: '7273',
        ort: 'Marienheide',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Oberbergischer Kreis',
        plz: '51709',
    },
    {
        id: '7274',
        ort: 'Marienmünster',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Höxter',
        plz: '37696',
    },
    {
        id: '7275',
        ort: 'Marienrachdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56242',
    },
    {
        id: '7276',
        ort: 'Mariental',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38368',
    },
    {
        id: '7277',
        ort: 'Marienwerder',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16348',
    },
    {
        id: '7278',
        ort: 'Maring-Noviand',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54484',
    },
    {
        id: '7279',
        ort: 'Marisfeld',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98530',
    },
    {
        id: '7280',
        ort: 'Markdorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88677',
    },
    {
        id: '7281',
        ort: 'Markersdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02829',
    },
    {
        id: '7282',
        ort: 'Markgröningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71706',
    },
    {
        id: '7283',
        ort: 'Märkisch Buchholz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15748',
    },
    {
        id: '7284',
        ort: 'Märkische Heide',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15913',
    },
    {
        id: '7285',
        ort: 'Märkische Höhe',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15377',
    },
    {
        id: '7286',
        ort: 'Märkisch Linden',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16818',
    },
    {
        id: '7287',
        ort: 'Märkisch Luch',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14715',
    },
    {
        id: '7288',
        ort: 'Markkleeberg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04416',
    },
    {
        id: '7289',
        ort: 'Marklkofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dingolfing-Landau',
        plz: '84163',
    },
    {
        id: '7290',
        ort: 'Marklohe',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31608',
    },
    {
        id: '7291',
        ort: 'Markneukirchen',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08258',
    },
    {
        id: '7292',
        ort: 'Markranstädt',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04420',
    },
    {
        id: '7293',
        ort: 'Marktbergel',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91613',
    },
    {
        id: '7294',
        ort: 'Markt Berolzheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91801',
    },
    {
        id: '7295',
        ort: 'Markt Bibart',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91477',
    },
    {
        id: '7296',
        ort: 'Marktbreit',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97340',
    },
    {
        id: '7297',
        ort: 'Markt Einersheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97348',
    },
    {
        id: '7298',
        ort: 'Markt Erlbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91459',
    },
    {
        id: '7299',
        ort: 'Marktgraitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lichtenfels',
        plz: '96257',
    },
    {
        id: '7300',
        ort: 'Marktheidenfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97828',
    },
    {
        id: '7301',
        ort: 'Markt Indersdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '85229',
    },
    {
        id: '7302',
        ort: 'Marktl',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84533',
    },
    {
        id: '7303',
        ort: 'Marktleugast',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95352',
    },
    {
        id: '7304',
        ort: 'Marktleuthen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Wunsiedel im Fichtelgebirge',
        plz: '95168',
    },
    {
        id: '7305',
        ort: 'Markt Nordheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91478',
    },
    {
        id: '7306',
        ort: 'Marktoberdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87616',
    },
    {
        id: '7307',
        ort: 'Marktoffingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86748',
    },
    {
        id: '7308',
        ort: 'Marktredwitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Wunsiedel im Fichtelgebirge',
        plz: '95615',
    },
    {
        id: '7309',
        ort: 'Markt Rettenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87733',
    },
    {
        id: '7310',
        ort: 'Marktrodach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kronach',
        plz: '96364',
    },
    {
        id: '7311',
        ort: 'Marktschellenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Berchtesgadener Land',
        plz: '83487',
    },
    {
        id: '7312',
        ort: 'Marktschorgast',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95509',
    },
    {
        id: '7313',
        ort: 'Markt Schwaben',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85570',
    },
    {
        id: '7314',
        ort: 'Marktsteft',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97342',
    },
    {
        id: '7315',
        ort: 'Markt Taschendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91480',
    },
    {
        id: '7316',
        ort: 'Markt Wald',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '86865',
    },
    {
        id: '7317',
        ort: 'Marktzeuln',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lichtenfels',
        plz: '96275',
    },
    {
        id: '7318',
        ort: 'Markvippach',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99195',
    },
    {
        id: '7319',
        ort: 'Marl',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '45768',
    },
    {
        id: '7320',
        ort: 'Marl',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '45770',
    },
    {
        id: '7321',
        ort: 'Marl',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '45772',
    },
    {
        id: '7322',
        ort: 'Marl',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49448',
    },
    {
        id: '7323',
        ort: 'Marloffstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91080',
    },
    {
        id: '7324',
        ort: 'Marlow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18337',
    },
    {
        id: '7325',
        ort: 'Marne',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25709',
    },
    {
        id: '7326',
        ort: 'Marnerdeich',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25709',
    },
    {
        id: '7327',
        ort: 'Marnheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67297',
    },
    {
        id: '7328',
        ort: 'Maroldsweisach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '96126',
    },
    {
        id: '7329',
        ort: 'Marolterode',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99994',
    },
    {
        id: '7330',
        ort: 'Maroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56271',
    },
    {
        id: '7331',
        ort: 'Marpingen',
        bundesland: 'Saarland',
        landkreis: 'Landkreis St. Wendel',
        plz: '66646',
    },
    {
        id: '7332',
        ort: 'Marquartstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83250',
    },
    {
        id: '7333',
        ort: 'Marsberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Hochsauerlandkreis',
        plz: '34431',
    },
    {
        id: '7334',
        ort: 'Marschacht',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21436',
    },
    {
        id: '7335',
        ort: 'Martensrade',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24238',
    },
    {
        id: '7336',
        ort: 'Martfeld',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27327',
    },
    {
        id: '7337',
        ort: 'Marth',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '7338',
        ort: 'Martinroda',
        bundesland: 'Thüringen',
        landkreis: 'Ilm-Kreis',
        plz: '98693',
    },
    {
        id: '7339',
        ort: 'Martinsheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97340',
    },
    {
        id: '7340',
        ort: 'Martinshöhe',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66894',
    },
    {
        id: '7341',
        ort: 'Martinstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55627',
    },
    {
        id: '7342',
        ort: 'Marxen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21439',
    },
    {
        id: '7343',
        ort: 'Marxheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86688',
    },
    {
        id: '7344',
        ort: 'Marxzell',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76359',
    },
    {
        id: '7345',
        ort: 'Marzhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57627',
    },
    {
        id: '7346',
        ort: 'Marzling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85417',
    },
    {
        id: '7347',
        ort: 'Masburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56761',
    },
    {
        id: '7348',
        ort: 'Maselheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88437',
    },
    {
        id: '7349',
        ort: 'Maßbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97711',
    },
    {
        id: '7350',
        ort: 'Massenbachhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74252',
    },
    {
        id: '7351',
        ort: 'Massen-Niederlausitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '03238',
    },
    {
        id: '7352',
        ort: 'Massen-Niederlausitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '03246',
    },
    {
        id: '7353',
        ort: 'Masserberg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98666',
    },
    {
        id: '7354',
        ort: 'Massing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84323',
    },
    {
        id: '7355',
        ort: 'Maßweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66506',
    },
    {
        id: '7356',
        ort: 'Mastershausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56869',
    },
    {
        id: '7357',
        ort: 'Masthorn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '7358',
        ort: 'Matzenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66909',
    },
    {
        id: '7359',
        ort: 'Matzerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '7360',
        ort: 'Mauchenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67294',
    },
    {
        id: '7361',
        ort: 'Mauden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57520',
    },
    {
        id: '7362',
        ort: 'Mauel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54649',
    },
    {
        id: '7363',
        ort: 'Mauer',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69256',
    },
    {
        id: '7364',
        ort: 'Mauern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85419',
    },
    {
        id: '7365',
        ort: 'Mauerstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87665',
    },
    {
        id: '7366',
        ort: 'Maulbronn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75433',
    },
    {
        id: '7367',
        ort: 'Maulburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79689',
    },
    {
        id: '7368',
        ort: 'Mauschbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66500',
    },
    {
        id: '7369',
        ort: 'Mauth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94151',
    },
    {
        id: '7370',
        ort: 'Maxdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67133',
    },
    {
        id: '7371',
        ort: 'Maxhütte-Haidhof',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '93142',
    },
    {
        id: '7372',
        ort: 'Maxsain',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '7373',
        ort: 'Mayen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56727',
    },
    {
        id: '7374',
        ort: 'Mayen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '7375',
        ort: 'Mayschoß',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53508',
    },
    {
        id: '7376',
        ort: 'Mechelroda',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99441',
    },
    {
        id: '7377',
        ort: 'Mechernich',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Euskirchen',
        plz: '53894',
    },
    {
        id: '7378',
        ort: 'Mechow',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23909',
    },
    {
        id: '7379',
        ort: 'Mechtersen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21358',
    },
    {
        id: '7380',
        ort: 'Meckel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '7381',
        ort: 'Meckenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55606',
    },
    {
        id: '7382',
        ort: 'Meckenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '7383',
        ort: 'Meckenbeuren',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88074',
    },
    {
        id: '7384',
        ort: 'Meckenheim',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53340',
    },
    {
        id: '7385',
        ort: 'Meckenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67149',
    },
    {
        id: '7386',
        ort: 'Meckesheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '74909',
    },
    {
        id: '7387',
        ort: 'Medard',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67744',
    },
    {
        id: '7388',
        ort: 'Meddersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55566',
    },
    {
        id: '7389',
        ort: 'Meddewade',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23847',
    },
    {
        id: '7390',
        ort: 'Medebach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Hochsauerlandkreis',
        plz: '59964',
    },
    {
        id: '7391',
        ort: 'Medelby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24994',
    },
    {
        id: '7392',
        ort: 'Medlingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89441',
    },
    {
        id: '7393',
        ort: 'Medow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17391',
    },
    {
        id: '7394',
        ort: 'Meeder',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Coburg',
        plz: '96484',
    },
    {
        id: '7395',
        ort: 'Meerane',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08393',
    },
    {
        id: '7396',
        ort: 'Meerbeck',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31715',
    },
    {
        id: '7397',
        ort: 'Meerbusch',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '40667',
    },
    {
        id: '7398',
        ort: 'Meerbusch',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '40668',
    },
    {
        id: '7399',
        ort: 'Meerbusch',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '40670',
    },
    {
        id: '7400',
        ort: 'Meerfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54531',
    },
    {
        id: '7401',
        ort: 'Meersburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88709',
    },
    {
        id: '7402',
        ort: 'Meesiger',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17111',
    },
    {
        id: '7403',
        ort: 'Meezen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24594',
    },
    {
        id: '7404',
        ort: 'Megesheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86750',
    },
    {
        id: '7405',
        ort: 'Meggerdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24799',
    },
    {
        id: '7406',
        ort: 'Meggerdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24861',
    },
    {
        id: '7407',
        ort: 'Mehlbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67735',
    },
    {
        id: '7408',
        ort: 'Mehlbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25588',
    },
    {
        id: '7409',
        ort: 'Mehlingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67678',
    },
    {
        id: '7410',
        ort: 'Mehlmeisel',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95694',
    },
    {
        id: '7411',
        ort: 'Mehmels',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98634',
    },
    {
        id: '7412',
        ort: 'Mehna',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04626',
    },
    {
        id: '7413',
        ort: 'Mehren',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57635',
    },
    {
        id: '7414',
        ort: 'Mehren',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '7415',
        ort: 'Mehring',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84561',
    },
    {
        id: '7416',
        ort: 'Mehring',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54346',
    },
    {
        id: '7417',
        ort: 'Mehrstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72537',
    },
    {
        id: '7418',
        ort: 'Meiersberg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17375',
    },
    {
        id: '7419',
        ort: 'Meinborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56584',
    },
    {
        id: '7420',
        ort: 'Meine',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38527',
    },
    {
        id: '7421',
        ort: 'Meinersen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38536',
    },
    {
        id: '7422',
        ort: 'Meinerzhagen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58540',
    },
    {
        id: '7423',
        ort: 'Meineweh',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06721',
    },
    {
        id: '7424',
        ort: 'Meinhard',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37276',
    },
    {
        id: '7425',
        ort: 'Meinheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91802',
    },
    {
        id: '7426',
        ort: 'Meiningen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98617',
    },
    {
        id: '7427',
        ort: 'Meisburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '7428',
        ort: 'Meisenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55590',
    },
    {
        id: '7429',
        ort: 'Meißen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01662',
    },
    {
        id: '7430',
        ort: 'Meißenheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77974',
    },
    {
        id: '7431',
        ort: 'Meißner',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37290',
    },
    {
        id: '7432',
        ort: 'Meitingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86405',
    },
    {
        id: '7433',
        ort: 'Melbeck',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21406',
    },
    {
        id: '7434',
        ort: 'Melchow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16230',
    },
    {
        id: '7435',
        ort: 'Meldorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25704',
    },
    {
        id: '7436',
        ort: 'Melle',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49324',
    },
    {
        id: '7437',
        ort: 'Melle',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49326',
    },
    {
        id: '7438',
        ort: 'Melle',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49328',
    },
    {
        id: '7439',
        ort: 'Mellenthin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17429',
    },
    {
        id: '7440',
        ort: 'Mellingen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99441',
    },
    {
        id: '7441',
        ort: 'Mellinghausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27249',
    },
    {
        id: '7442',
        ort: 'Mellrichstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97638',
    },
    {
        id: '7443',
        ort: 'Melsbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56581',
    },
    {
        id: '7444',
        ort: 'Melsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24109',
    },
    {
        id: '7445',
        ort: 'Melsungen',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34212',
    },
    {
        id: '7446',
        ort: 'Melz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17209',
    },
    {
        id: '7447',
        ort: 'Memmelsdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96117',
    },
    {
        id: '7448',
        ort: 'Memmingen',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '87700',
    },
    {
        id: '7449',
        ort: 'Memmingerberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87766',
    },
    {
        id: '7450',
        ort: 'Menden',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58706',
    },
    {
        id: '7451',
        ort: 'Menden',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58708',
    },
    {
        id: '7452',
        ort: 'Menden',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58710',
    },
    {
        id: '7453',
        ort: 'Mendig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56743',
    },
    {
        id: '7454',
        ort: 'Mengen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '88512',
    },
    {
        id: '7455',
        ort: 'Mengerschied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55490',
    },
    {
        id: '7456',
        ort: 'Mengerskirchen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '35794',
    },
    {
        id: '7457',
        ort: 'Mengkofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dingolfing-Landau',
        plz: '84152',
    },
    {
        id: '7458',
        ort: 'Menningen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54310',
    },
    {
        id: '7459',
        ort: 'Menslage',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49637',
    },
    {
        id: '7460',
        ort: 'Menzendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23923',
    },
    {
        id: '7461',
        ort: 'Meppen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49716',
    },
    {
        id: '7462',
        ort: 'Merching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86504',
    },
    {
        id: '7463',
        ort: 'Merchweiler',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Neunkirchen',
        plz: '66589',
    },
    {
        id: '7464',
        ort: 'Merdingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79291',
    },
    {
        id: '7465',
        ort: 'Merenberg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '35799',
    },
    {
        id: '7466',
        ort: 'Mering',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86415',
    },
    {
        id: '7467',
        ort: 'Merkelbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57629',
    },
    {
        id: '7468',
        ort: 'Merkendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91732',
    },
    {
        id: '7469',
        ort: 'Merklingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89188',
    },
    {
        id: '7470',
        ort: 'Merlscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '7471',
        ort: 'Mermuth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56283',
    },
    {
        id: '7472',
        ort: 'Merschbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54426',
    },
    {
        id: '7473',
        ort: 'Merseburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06217',
    },
    {
        id: '7474',
        ort: 'Mertendorf',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06618',
    },
    {
        id: '7475',
        ort: 'Mertendorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07619',
    },
    {
        id: '7476',
        ort: 'Mertesdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54318',
    },
    {
        id: '7477',
        ort: 'Mertesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67271',
    },
    {
        id: '7478',
        ort: 'Mertingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86690',
    },
    {
        id: '7479',
        ort: 'Mertloch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56753',
    },
    {
        id: '7480',
        ort: 'Merxheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55627',
    },
    {
        id: '7481',
        ort: 'Merzalben',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66978',
    },
    {
        id: '7482',
        ort: 'Merzdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04932',
    },
    {
        id: '7483',
        ort: 'Merzen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49586',
    },
    {
        id: '7484',
        ort: 'Merzenich',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Düren',
        plz: '52399',
    },
    {
        id: '7485',
        ort: 'Merzhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79249',
    },
    {
        id: '7486',
        ort: 'Merzig',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Merzig-Wadern',
        plz: '66663',
    },
    {
        id: '7487',
        ort: 'Merzkirchen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54439',
    },
    {
        id: '7488',
        ort: 'Merzweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67746',
    },
    {
        id: '7489',
        ort: 'Meschede',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Hochsauerlandkreis',
        plz: '59872',
    },
    {
        id: '7490',
        ort: 'Mescherin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '16307',
    },
    {
        id: '7491',
        ort: 'Mesekenhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17498',
    },
    {
        id: '7492',
        ort: 'Mesenich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56820',
    },
    {
        id: '7493',
        ort: 'Mespelbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63875',
    },
    {
        id: '7494',
        ort: 'Messel',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64409',
    },
    {
        id: '7495',
        ort: 'Messenkamp',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31867',
    },
    {
        id: '7496',
        ort: 'Messerich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '7497',
        ort: 'Messingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49832',
    },
    {
        id: '7498',
        ort: 'Meßkirch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '88605',
    },
    {
        id: '7499',
        ort: 'Meßstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72469',
    },
    {
        id: '7500',
        ort: 'Mestlin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19374',
    },
    {
        id: '7501',
        ort: 'Metelen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '48629',
    },
    {
        id: '7502',
        ort: 'Metelsdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23972',
    },
    {
        id: '7503',
        ort: 'Metten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94526',
    },
    {
        id: '7504',
        ort: 'Mettendorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '7505',
        ort: 'Mettenheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84562',
    },
    {
        id: '7506',
        ort: 'Mettenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67582',
    },
    {
        id: '7507',
        ort: 'Metterich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54634',
    },
    {
        id: '7508',
        ort: 'Mettingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '49497',
    },
    {
        id: '7509',
        ort: 'Mettlach',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Merzig-Wadern',
        plz: '66693',
    },
    {
        id: '7510',
        ort: 'Mettmann',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '40822',
    },
    {
        id: '7511',
        ort: 'Mettweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55777',
    },
    {
        id: '7512',
        ort: 'Metzenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55481',
    },
    {
        id: '7513',
        ort: 'Metzingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72555',
    },
    {
        id: '7514',
        ort: 'Meudt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56414',
    },
    {
        id: '7515',
        ort: 'Meura',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '98744',
    },
    {
        id: '7516',
        ort: 'Meusebach',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '7517',
        ort: 'Meuselwitz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04610',
    },
    {
        id: '7518',
        ort: 'Meuspath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53520',
    },
    {
        id: '7519',
        ort: 'Meyenburg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '16945',
    },
    {
        id: '7520',
        ort: 'Meyn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24980',
    },
    {
        id: '7521',
        ort: 'Michelau i.OFr.',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lichtenfels',
        plz: '96247',
    },
    {
        id: '7522',
        ort: 'Michelau i.Steigerwald',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97513',
    },
    {
        id: '7523',
        ort: 'Michelbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57610',
    },
    {
        id: '7524',
        ort: 'Michelbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56288',
    },
    {
        id: '7525',
        ort: 'Michelbach an der Bilz',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74544',
    },
    {
        id: '7526',
        ort: 'Michelfeld',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74545',
    },
    {
        id: '7527',
        ort: 'Michelsneukirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93185',
    },
    {
        id: '7528',
        ort: 'Michelstadt',
        bundesland: 'Hessen',
        landkreis: 'Odenwaldkreis',
        plz: '64720',
    },
    {
        id: '7529',
        ort: 'Michendorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14552',
    },
    {
        id: '7530',
        ort: 'Mickhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86866',
    },
    {
        id: '7531',
        ort: 'Midlum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25938',
    },
    {
        id: '7532',
        ort: 'Miehlen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '7533',
        ort: 'Mielkendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24247',
    },
    {
        id: '7534',
        ort: 'Miellen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56132',
    },
    {
        id: '7535',
        ort: 'Miesbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miesbach',
        plz: '83714',
    },
    {
        id: '7536',
        ort: 'Miesitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07819',
    },
    {
        id: '7537',
        ort: 'Mietingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88487',
    },
    {
        id: '7538',
        ort: 'Milda',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07751',
    },
    {
        id: '7539',
        ort: 'Mildenau',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09456',
    },
    {
        id: '7540',
        ort: 'Mildstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25866',
    },
    {
        id: '7541',
        ort: 'Millienhagen-Oebelitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18461',
    },
    {
        id: '7542',
        ort: 'Milmersdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17268',
    },
    {
        id: '7543',
        ort: 'Milow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19294',
    },
    {
        id: '7544',
        ort: 'Milow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19300',
    },
    {
        id: '7545',
        ort: 'Milower Land',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14715',
    },
    {
        id: '7546',
        ort: 'Miltach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93468',
    },
    {
        id: '7547',
        ort: 'Miltenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63897',
    },
    {
        id: '7548',
        ort: 'Mindelheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87719',
    },
    {
        id: '7549',
        ort: 'Mindelstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '93349',
    },
    {
        id: '7550',
        ort: 'Minden',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Minden-Lübbecke',
        plz: '32423',
    },
    {
        id: '7551',
        ort: 'Minden',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Minden-Lübbecke',
        plz: '32425',
    },
    {
        id: '7552',
        ort: 'Minden',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Minden-Lübbecke',
        plz: '32427',
    },
    {
        id: '7553',
        ort: 'Minden',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Minden-Lübbecke',
        plz: '32429',
    },
    {
        id: '7554',
        ort: 'Minden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54310',
    },
    {
        id: '7555',
        ort: 'Minderlittgen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '7556',
        ort: 'Minfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76872',
    },
    {
        id: '7557',
        ort: 'Minheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '7558',
        ort: 'Mintraching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93098',
    },
    {
        id: '7559',
        ort: 'Mirow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17252',
    },
    {
        id: '7560',
        ort: 'Misselberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56377',
    },
    {
        id: '7561',
        ort: 'Missen-Wilhams',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87547',
    },
    {
        id: '7562',
        ort: 'Mistelbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95511',
    },
    {
        id: '7563',
        ort: 'Mistelgau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95490',
    },
    {
        id: '7564',
        ort: 'Mistorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18276',
    },
    {
        id: '7565',
        ort: 'Mittelangeln',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24986',
    },
    {
        id: '7566',
        ort: 'Mittelbiberach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88441',
    },
    {
        id: '7567',
        ort: 'Mittelbrunn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66851',
    },
    {
        id: '7568',
        ort: 'Mitteleschenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91734',
    },
    {
        id: '7569',
        ort: 'Mittelfischbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56370',
    },
    {
        id: '7570',
        ort: 'Mittelherwigsdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02763',
    },
    {
        id: '7571',
        ort: 'Mittelhof',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57537',
    },
    {
        id: '7572',
        ort: 'Mittelneufnach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86868',
    },
    {
        id: '7573',
        ort: 'Mittelnkirchen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21720',
    },
    {
        id: '7574',
        ort: 'Mittelpöllnitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07819',
    },
    {
        id: '7575',
        ort: 'Mittelreidenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '7576',
        ort: 'Mittelsinn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97785',
    },
    {
        id: '7577',
        ort: 'Mittelsömmern',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99955',
    },
    {
        id: '7578',
        ort: 'Mittelstenahe',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21770',
    },
    {
        id: '7579',
        ort: 'Mittelstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82293',
    },
    {
        id: '7580',
        ort: 'Mittelstrimmig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56858',
    },
    {
        id: '7581',
        ort: 'Mittenaar',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35756',
    },
    {
        id: '7582',
        ort: 'Mittenwald',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82481',
    },
    {
        id: '7583',
        ort: 'Mittenwalde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15749',
    },
    {
        id: '7584',
        ort: 'Mittenwalde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17268',
    },
    {
        id: '7585',
        ort: 'Mitterfels',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94360',
    },
    {
        id: '7586',
        ort: 'Mitterskirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84335',
    },
    {
        id: '7587',
        ort: 'Mitterteich',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95666',
    },
    {
        id: '7588',
        ort: 'Mittweida',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09648',
    },
    {
        id: '7589',
        ort: 'Mitwitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kronach',
        plz: '96268',
    },
    {
        id: '7590',
        ort: 'Mixdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15299',
    },
    {
        id: '7591',
        ort: 'Möckern',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Jerichower Land',
        plz: '39279',
    },
    {
        id: '7592',
        ort: 'Möckern',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Jerichower Land',
        plz: '39291',
    },
    {
        id: '7593',
        ort: 'Möckern',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '7594',
        ort: 'Möckmühl',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74219',
    },
    {
        id: '7595',
        ort: 'Mockrehna',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04862',
    },
    {
        id: '7596',
        ort: 'Modautal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64397',
    },
    {
        id: '7597',
        ort: 'Mödingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89426',
    },
    {
        id: '7598',
        ort: 'Moers',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '47441',
    },
    {
        id: '7599',
        ort: 'Moers',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '47443',
    },
    {
        id: '7600',
        ort: 'Moers',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '47445',
    },
    {
        id: '7601',
        ort: 'Moers',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '47447',
    },
    {
        id: '7602',
        ort: 'Mogendorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56424',
    },
    {
        id: '7603',
        ort: 'Mögglingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73563',
    },
    {
        id: '7604',
        ort: 'Möglingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71696',
    },
    {
        id: '7605',
        ort: 'Mohlsdorf-Teichwolframsdorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07987',
    },
    {
        id: '7606',
        ort: 'Möhnesee',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Soest',
        plz: '59519',
    },
    {
        id: '7607',
        ort: 'Möhnsen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21493',
    },
    {
        id: '7608',
        ort: 'Möhrendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91096',
    },
    {
        id: '7609',
        ort: 'Mohrkirch',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24392',
    },
    {
        id: '7610',
        ort: 'Mohrkirch',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24405',
    },
    {
        id: '7611',
        ort: 'Mohrkirch',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24891',
    },
    {
        id: '7612',
        ort: 'Moisburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21647',
    },
    {
        id: '7613',
        ort: 'Molauer Land',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06618',
    },
    {
        id: '7614',
        ort: 'Molbergen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cloppenburg',
        plz: '49696',
    },
    {
        id: '7615',
        ort: 'Molfsee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24113',
    },
    {
        id: '7616',
        ort: 'Möllenbeck',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17237',
    },
    {
        id: '7617',
        ort: 'Möllenbeck',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19300',
    },
    {
        id: '7618',
        ort: 'Möllenhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17219',
    },
    {
        id: '7619',
        ort: 'Mölln',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17091',
    },
    {
        id: '7620',
        ort: 'Mölln',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23879',
    },
    {
        id: '7621',
        ort: 'Molsberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56414',
    },
    {
        id: '7622',
        ort: 'Molschleben',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99869',
    },
    {
        id: '7623',
        ort: 'Mölschow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17449',
    },
    {
        id: '7624',
        ort: 'Mölsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67591',
    },
    {
        id: '7625',
        ort: 'Moltzow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17194',
    },
    {
        id: '7626',
        ort: 'Molzhain',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57520',
    },
    {
        id: '7627',
        ort: 'Mömbris',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63776',
    },
    {
        id: '7628',
        ort: 'Mömlingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63853',
    },
    {
        id: '7629',
        ort: 'Mommenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55278',
    },
    {
        id: '7630',
        ort: 'Mönchberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63933',
    },
    {
        id: '7631',
        ort: 'Mönchengladbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '41061',
    },
    {
        id: '7632',
        ort: 'Mönchengladbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '41063',
    },
    {
        id: '7633',
        ort: 'Mönchengladbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '41065',
    },
    {
        id: '7634',
        ort: 'Mönchengladbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '41066',
    },
    {
        id: '7635',
        ort: 'Mönchengladbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '41068',
    },
    {
        id: '7636',
        ort: 'Mönchengladbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '41069',
    },
    {
        id: '7637',
        ort: 'Mönchengladbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '41169',
    },
    {
        id: '7638',
        ort: 'Mönchengladbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '41179',
    },
    {
        id: '7639',
        ort: 'Mönchengladbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '41189',
    },
    {
        id: '7640',
        ort: 'Mönchengladbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '41199',
    },
    {
        id: '7641',
        ort: 'Mönchengladbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '41236',
    },
    {
        id: '7642',
        ort: 'Mönchengladbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '41238',
    },
    {
        id: '7643',
        ort: 'Mönchengladbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '41239',
    },
    {
        id: '7644',
        ort: 'Mönchgut',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18586',
    },
    {
        id: '7645',
        ort: 'Mönchhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18182',
    },
    {
        id: '7646',
        ort: 'Mönchpfiffel-Nikolausrieth',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '06556',
    },
    {
        id: '7647',
        ort: 'Mönchsdeggingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86751',
    },
    {
        id: '7648',
        ort: 'Mönchsroth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91614',
    },
    {
        id: '7649',
        ort: 'Mönchweiler',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78087',
    },
    {
        id: '7650',
        ort: 'Monheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86653',
    },
    {
        id: '7651',
        ort: 'Monheim am Rhein',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '40789',
    },
    {
        id: '7652',
        ort: 'Mönkeberg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24248',
    },
    {
        id: '7653',
        ort: 'Mönkebude',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17375',
    },
    {
        id: '7654',
        ort: 'Mönkhagen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23619',
    },
    {
        id: '7655',
        ort: 'Mönkloh',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24576',
    },
    {
        id: '7656',
        ort: 'Monreal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '7657',
        ort: 'Monschau',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52156',
    },
    {
        id: '7658',
        ort: 'Monsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67590',
    },
    {
        id: '7659',
        ort: 'Mönsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '71297',
    },
    {
        id: '7660',
        ort: 'Monstab',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04617',
    },
    {
        id: '7661',
        ort: 'Montabaur',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56410',
    },
    {
        id: '7662',
        ort: 'Möntenich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56754',
    },
    {
        id: '7663',
        ort: 'Monzelfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54472',
    },
    {
        id: '7664',
        ort: 'Monzernheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '7665',
        ort: 'Monzingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55569',
    },
    {
        id: '7666',
        ort: 'Moordiek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25597',
    },
    {
        id: '7667',
        ort: 'Moorenweis',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82272',
    },
    {
        id: '7668',
        ort: 'Moorhusen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25554',
    },
    {
        id: '7669',
        ort: 'Moormerland',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26802',
    },
    {
        id: '7670',
        ort: 'Moorrege',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25436',
    },
    {
        id: '7671',
        ort: 'Moorweg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26427',
    },
    {
        id: '7672',
        ort: 'Moos',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94554',
    },
    {
        id: '7673',
        ort: 'Moos',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78345',
    },
    {
        id: '7674',
        ort: 'Moosach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85665',
    },
    {
        id: '7675',
        ort: 'Moosbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92709',
    },
    {
        id: '7676',
        ort: 'Moosburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85368',
    },
    {
        id: '7677',
        ort: 'Moosburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88422',
    },
    {
        id: '7678',
        ort: 'Moosinning',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '85452',
    },
    {
        id: '7679',
        ort: 'Moosthenning',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dingolfing-Landau',
        plz: '84164',
    },
    {
        id: '7680',
        ort: 'Moraas',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '7681',
        ort: 'Morbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54497',
    },
    {
        id: '7682',
        ort: 'Mörel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24594',
    },
    {
        id: '7683',
        ort: 'Mörfelden-Walldorf',
        bundesland: 'Hessen',
        landkreis: 'Kreis Groß-Gerau',
        plz: '64546',
    },
    {
        id: '7684',
        ort: 'Moringen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Northeim',
        plz: '37186',
    },
    {
        id: '7685',
        ort: 'Moritzburg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01468',
    },
    {
        id: '7686',
        ort: 'Moritzheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56865',
    },
    {
        id: '7687',
        ort: 'Mörlen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57583',
    },
    {
        id: '7688',
        ort: 'Mörlenbach',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '69509',
    },
    {
        id: '7689',
        ort: 'Mörnsheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '91804',
    },
    {
        id: '7690',
        ort: 'Morsbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Oberbergischer Kreis',
        plz: '51597',
    },
    {
        id: '7691',
        ort: 'Mörsbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57629',
    },
    {
        id: '7692',
        ort: 'Mörschbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55494',
    },
    {
        id: '7693',
        ort: 'Morscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54317',
    },
    {
        id: '7694',
        ort: 'Morschen',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34326',
    },
    {
        id: '7695',
        ort: 'Morschheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67294',
    },
    {
        id: '7696',
        ort: 'Mörschied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '7697',
        ort: 'Mörsdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56290',
    },
    {
        id: '7698',
        ort: 'Mörsdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '7699',
        ort: 'Mörsfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67808',
    },
    {
        id: '7700',
        ort: 'Morshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56283',
    },
    {
        id: '7701',
        ort: 'Mörstadt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67591',
    },
    {
        id: '7702',
        ort: 'Mosbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74821',
    },
    {
        id: '7703',
        ort: 'Mosbruch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '56767',
    },
    {
        id: '7704',
        ort: 'Moschheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56424',
    },
    {
        id: '7705',
        ort: 'Moselkern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56254',
    },
    {
        id: '7706',
        ort: 'Möser',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Jerichower Land',
        plz: '39175',
    },
    {
        id: '7707',
        ort: 'Möser',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Jerichower Land',
        plz: '39291',
    },
    {
        id: '7708',
        ort: 'Mossautal',
        bundesland: 'Hessen',
        landkreis: 'Odenwaldkreis',
        plz: '64756',
    },
    {
        id: '7709',
        ort: 'Moßbach',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07907',
    },
    {
        id: '7710',
        ort: 'Mössingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72116',
    },
    {
        id: '7711',
        ort: 'Motten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97786',
    },
    {
        id: '7712',
        ort: 'Möttingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86753',
    },
    {
        id: '7713',
        ort: 'Mötzing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93099',
    },
    {
        id: '7714',
        ort: 'Mötzingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71159',
    },
    {
        id: '7715',
        ort: 'Moxa',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07381',
    },
    {
        id: '7716',
        ort: 'Mözen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23795',
    },
    {
        id: '7717',
        ort: 'Much',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53804',
    },
    {
        id: '7718',
        ort: 'Mucheln',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24238',
    },
    {
        id: '7719',
        ort: 'Mücheln (Geiseltal)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06249',
    },
    {
        id: '7720',
        ort: 'Mücheln (Geiseltal)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06255',
    },
    {
        id: '7721',
        ort: 'Mücheln (Geiseltal)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06268',
    },
    {
        id: '7722',
        ort: 'Mücheln (Geiseltal)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06632',
    },
    {
        id: '7723',
        ort: 'Muchow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19300',
    },
    {
        id: '7724',
        ort: 'Mücka - Mikow',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02906',
    },
    {
        id: '7725',
        ort: 'Mücke',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '35325',
    },
    {
        id: '7726',
        ort: 'Mückeln',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54558',
    },
    {
        id: '7727',
        ort: 'Mudau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '69427',
    },
    {
        id: '7728',
        ort: 'Müden (Aller)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38539',
    },
    {
        id: '7729',
        ort: 'Mudenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57614',
    },
    {
        id: '7730',
        ort: 'Müden (Mosel)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56254',
    },
    {
        id: '7731',
        ort: 'Mudersbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57555',
    },
    {
        id: '7732',
        ort: 'Mudershausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65623',
    },
    {
        id: '7733',
        ort: 'Mügeln',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04769',
    },
    {
        id: '7734',
        ort: 'Muggensturm',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '76461',
    },
    {
        id: '7735',
        ort: 'Müglitztal',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01809',
    },
    {
        id: '7736',
        ort: 'Mühbrook',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24582',
    },
    {
        id: '7737',
        ort: 'Mühlacker',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75417',
    },
    {
        id: '7738',
        ort: 'Mühlau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09241',
    },
    {
        id: '7739',
        ort: 'Mühlberg/Elbe',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04895',
    },
    {
        id: '7740',
        ort: 'Mühlberg/Elbe',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04931',
    },
    {
        id: '7741',
        ort: 'Mühldorf a. Inn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84453',
    },
    {
        id: '7742',
        ort: 'Mühlenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77796',
    },
    {
        id: '7743',
        ort: 'Mühlenbarbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25548',
    },
    {
        id: '7744',
        ort: 'Mühlenbecker Land',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16515',
    },
    {
        id: '7745',
        ort: 'Mühlenbecker Land',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16552',
    },
    {
        id: '7746',
        ort: 'Mühlenbecker Land',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16567',
    },
    {
        id: '7747',
        ort: 'Mühlenberge',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14662',
    },
    {
        id: '7748',
        ort: 'Mühlen Eichsen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19205',
    },
    {
        id: '7749',
        ort: 'Mühlenfließ',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14822',
    },
    {
        id: '7750',
        ort: 'Mühlenfließ',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14823',
    },
    {
        id: '7751',
        ort: 'Mühlenrade',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21493',
    },
    {
        id: '7752',
        ort: 'Mühlental',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08626',
    },
    {
        id: '7753',
        ort: 'Mühlhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69242',
    },
    {
        id: '7754',
        ort: 'Mühlhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '96172',
    },
    {
        id: '7755',
        ort: 'Mühlhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92360',
    },
    {
        id: '7756',
        ort: 'Mühlhausen',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99974',
    },
    {
        id: '7757',
        ort: 'Mühlhausen',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99998',
    },
    {
        id: '7758',
        ort: 'Mühlhausen-Ehingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78259',
    },
    {
        id: '7759',
        ort: 'Mühlhausen im Täle',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73347',
    },
    {
        id: '7760',
        ort: 'Mühlheim am Main',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Offenbach',
        plz: '63165',
    },
    {
        id: '7761',
        ort: 'Mühlheim an der Donau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78570',
    },
    {
        id: '7762',
        ort: 'Mühlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78357',
    },
    {
        id: '7763',
        ort: 'Mühlpfad',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56291',
    },
    {
        id: '7764',
        ort: 'Mühl Rosin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18276',
    },
    {
        id: '7765',
        ort: 'Mühltal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64367',
    },
    {
        id: '7766',
        ort: 'Muhr am See',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91735',
    },
    {
        id: '7767',
        ort: 'Mülbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '7768',
        ort: 'Mulda/Sachsen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09619',
    },
    {
        id: '7769',
        ort: 'Muldenhammer',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08262',
    },
    {
        id: '7770',
        ort: 'Muldestausee',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06774',
    },
    {
        id: '7771',
        ort: 'Mulfingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Hohenlohekreis',
        plz: '74673',
    },
    {
        id: '7772',
        ort: 'Mülheim an der Mosel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54486',
    },
    {
        id: '7773',
        ort: 'Mülheim an der Ruhr',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45468',
    },
    {
        id: '7774',
        ort: 'Mülheim an der Ruhr',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45470',
    },
    {
        id: '7775',
        ort: 'Mülheim an der Ruhr',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45472',
    },
    {
        id: '7776',
        ort: 'Mülheim an der Ruhr',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45473',
    },
    {
        id: '7777',
        ort: 'Mülheim an der Ruhr',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45475',
    },
    {
        id: '7778',
        ort: 'Mülheim an der Ruhr',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45476',
    },
    {
        id: '7779',
        ort: 'Mülheim an der Ruhr',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45478',
    },
    {
        id: '7780',
        ort: 'Mülheim an der Ruhr',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45479',
    },
    {
        id: '7781',
        ort: 'Mülheim an der Ruhr',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '45481',
    },
    {
        id: '7782',
        ort: 'Mülheim-Kärlich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56218',
    },
    {
        id: '7783',
        ort: 'Müllenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53520',
    },
    {
        id: '7784',
        ort: 'Müllenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56761',
    },
    {
        id: '7785',
        ort: 'Müllheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79379',
    },
    {
        id: '7786',
        ort: 'Müllrose',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15299',
    },
    {
        id: '7787',
        ort: 'Mülsen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08132',
    },
    {
        id: '7788',
        ort: 'Münchberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95213',
    },
    {
        id: '7789',
        ort: 'Müncheberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15374',
    },
    {
        id: '7790',
        ort: 'Münchehofe',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15748',
    },
    {
        id: '7791',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80331',
    },
    {
        id: '7792',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80333',
    },
    {
        id: '7793',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80335',
    },
    {
        id: '7794',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80336',
    },
    {
        id: '7795',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80337',
    },
    {
        id: '7796',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80339',
    },
    {
        id: '7797',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80469',
    },
    {
        id: '7798',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80538',
    },
    {
        id: '7799',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80539',
    },
    {
        id: '7800',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80634',
    },
    {
        id: '7801',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80636',
    },
    {
        id: '7802',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80637',
    },
    {
        id: '7803',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80638',
    },
    {
        id: '7804',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80639',
    },
    {
        id: '7805',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80686',
    },
    {
        id: '7806',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80687',
    },
    {
        id: '7807',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80689',
    },
    {
        id: '7808',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80796',
    },
    {
        id: '7809',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80797',
    },
    {
        id: '7810',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80798',
    },
    {
        id: '7811',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80799',
    },
    {
        id: '7812',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80801',
    },
    {
        id: '7813',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80802',
    },
    {
        id: '7814',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80803',
    },
    {
        id: '7815',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80804',
    },
    {
        id: '7816',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80805',
    },
    {
        id: '7817',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80807',
    },
    {
        id: '7818',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80809',
    },
    {
        id: '7819',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80933',
    },
    {
        id: '7820',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80935',
    },
    {
        id: '7821',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80937',
    },
    {
        id: '7822',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80939',
    },
    {
        id: '7823',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80992',
    },
    {
        id: '7824',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80993',
    },
    {
        id: '7825',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80995',
    },
    {
        id: '7826',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80997',
    },
    {
        id: '7827',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '80999',
    },
    {
        id: '7828',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81241',
    },
    {
        id: '7829',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81243',
    },
    {
        id: '7830',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81245',
    },
    {
        id: '7831',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81247',
    },
    {
        id: '7832',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81248',
    },
    {
        id: '7833',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81249',
    },
    {
        id: '7834',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81369',
    },
    {
        id: '7835',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81371',
    },
    {
        id: '7836',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81373',
    },
    {
        id: '7837',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81375',
    },
    {
        id: '7838',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81377',
    },
    {
        id: '7839',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81379',
    },
    {
        id: '7840',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81475',
    },
    {
        id: '7841',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81476',
    },
    {
        id: '7842',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81477',
    },
    {
        id: '7843',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81479',
    },
    {
        id: '7844',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81539',
    },
    {
        id: '7845',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81541',
    },
    {
        id: '7846',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81543',
    },
    {
        id: '7847',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81545',
    },
    {
        id: '7848',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81547',
    },
    {
        id: '7849',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81549',
    },
    {
        id: '7850',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81667',
    },
    {
        id: '7851',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81669',
    },
    {
        id: '7852',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81671',
    },
    {
        id: '7853',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81673',
    },
    {
        id: '7854',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81675',
    },
    {
        id: '7855',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81677',
    },
    {
        id: '7856',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81679',
    },
    {
        id: '7857',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81735',
    },
    {
        id: '7858',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81737',
    },
    {
        id: '7859',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81739',
    },
    {
        id: '7860',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81825',
    },
    {
        id: '7861',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81827',
    },
    {
        id: '7862',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81829',
    },
    {
        id: '7863',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81925',
    },
    {
        id: '7864',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81927',
    },
    {
        id: '7865',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '81929',
    },
    {
        id: '7866',
        ort: 'München',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '85540',
    },
    {
        id: '7867',
        ort: 'Münchenbernsdorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07589',
    },
    {
        id: '7868',
        ort: 'Münchhausen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35117',
    },
    {
        id: '7869',
        ort: 'Münchsmünster',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85126',
    },
    {
        id: '7870',
        ort: 'Münchsteinach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91481',
    },
    {
        id: '7871',
        ort: 'Münchwald',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '7872',
        ort: 'Münchweiler am Klingbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76857',
    },
    {
        id: '7873',
        ort: 'Münchweiler an der Alsenz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67728',
    },
    {
        id: '7874',
        ort: 'Münchweiler an der Rodalb',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66955',
    },
    {
        id: '7875',
        ort: 'Münchweiler an der Rodalb',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66981',
    },
    {
        id: '7876',
        ort: 'Mundelsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '74395',
    },
    {
        id: '7877',
        ort: 'Munderkingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89597',
    },
    {
        id: '7878',
        ort: 'Mündersbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56271',
    },
    {
        id: '7879',
        ort: 'Münk',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '7880',
        ort: 'Munkbrarup',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24960',
    },
    {
        id: '7881',
        ort: 'Münnerstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97702',
    },
    {
        id: '7882',
        ort: 'Munningen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86754',
    },
    {
        id: '7883',
        ort: 'Münsing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '82541',
    },
    {
        id: '7884',
        ort: 'Münsingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72525',
    },
    {
        id: '7885',
        ort: 'Munster',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29633',
    },
    {
        id: '7886',
        ort: 'Münster',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '48143',
    },
    {
        id: '7887',
        ort: 'Münster',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '48145',
    },
    {
        id: '7888',
        ort: 'Münster',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '48147',
    },
    {
        id: '7889',
        ort: 'Münster',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '48149',
    },
    {
        id: '7890',
        ort: 'Münster',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '48151',
    },
    {
        id: '7891',
        ort: 'Münster',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '48153',
    },
    {
        id: '7892',
        ort: 'Münster',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '48155',
    },
    {
        id: '7893',
        ort: 'Münster',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '48157',
    },
    {
        id: '7894',
        ort: 'Münster',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '48159',
    },
    {
        id: '7895',
        ort: 'Münster',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '48161',
    },
    {
        id: '7896',
        ort: 'Münster',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '48163',
    },
    {
        id: '7897',
        ort: 'Münster',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '48165',
    },
    {
        id: '7898',
        ort: 'Münster',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '48167',
    },
    {
        id: '7899',
        ort: 'Münster',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86692',
    },
    {
        id: '7900',
        ort: 'Münsterappel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67822',
    },
    {
        id: '7901',
        ort: 'Münsterdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25587',
    },
    {
        id: '7902',
        ort: 'Münsterhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '86505',
    },
    {
        id: '7903',
        ort: 'Münster (Hessen)',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64839',
    },
    {
        id: '7904',
        ort: 'Münstermaifeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56294',
    },
    {
        id: '7905',
        ort: 'Münster-Sarmsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55424',
    },
    {
        id: '7906',
        ort: 'Münstertal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79244',
    },
    {
        id: '7907',
        ort: 'Münzenberg',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '35516',
    },
    {
        id: '7908',
        ort: 'Murchin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17390',
    },
    {
        id: '7909',
        ort: 'Murg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79730',
    },
    {
        id: '7910',
        ort: 'Mürlenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '7911',
        ort: 'Murnau am Staffelsee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82418',
    },
    {
        id: '7912',
        ort: 'Murr',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71711',
    },
    {
        id: '7913',
        ort: 'Murrhardt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71540',
    },
    {
        id: '7914',
        ort: 'Müsch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53533',
    },
    {
        id: '7915',
        ort: 'Müschenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57629',
    },
    {
        id: '7916',
        ort: 'Müssen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21516',
    },
    {
        id: '7917',
        ort: 'Mustin',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23911',
    },
    {
        id: '7918',
        ort: 'Mustin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19406',
    },
    {
        id: '7919',
        ort: 'Musweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54534',
    },
    {
        id: '7920',
        ort: 'Mutlangen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73557',
    },
    {
        id: '7921',
        ort: 'Mutterschied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55469',
    },
    {
        id: '7922',
        ort: 'Mutterstadt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67112',
    },
    {
        id: '7923',
        ort: 'Mützenich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54608',
    },
    {
        id: '7924',
        ort: 'Muxerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '7925',
        ort: 'Nabburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92507',
    },
    {
        id: '7926',
        ort: 'Nachrodt-Wiblingwerde',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58769',
    },
    {
        id: '7927',
        ort: 'Nachtsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '7928',
        ort: 'Nack',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '7929',
        ort: 'Nackenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55299',
    },
    {
        id: '7930',
        ort: 'Nadrensee',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17329',
    },
    {
        id: '7931',
        ort: 'Nagel',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Wunsiedel im Fichtelgebirge',
        plz: '95697',
    },
    {
        id: '7932',
        ort: 'Nagold',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '72202',
    },
    {
        id: '7933',
        ort: 'Nahe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23866',
    },
    {
        id: '7934',
        ort: 'Nahrendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21369',
    },
    {
        id: '7935',
        ort: 'Naila',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95119',
    },
    {
        id: '7936',
        ort: 'Nalbach',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Saarlouis',
        plz: '66809',
    },
    {
        id: '7937',
        ort: 'Namborn',
        bundesland: 'Saarland',
        landkreis: 'Landkreis St. Wendel',
        plz: '66640',
    },
    {
        id: '7938',
        ort: 'Nandlstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85395',
    },
    {
        id: '7939',
        ort: 'Nandlstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85405',
    },
    {
        id: '7940',
        ort: 'Nannhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55469',
    },
    {
        id: '7941',
        ort: 'Nanzdietschweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66909',
    },
    {
        id: '7942',
        ort: 'Nasingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '7943',
        ort: 'Nassau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56377',
    },
    {
        id: '7944',
        ort: 'Nassenfels',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85128',
    },
    {
        id: '7945',
        ort: 'Nastätten',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56355',
    },
    {
        id: '7946',
        ort: 'Natendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29587',
    },
    {
        id: '7947',
        ort: 'Nattenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '7948',
        ort: 'Nattheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heidenheim',
        plz: '89564',
    },
    {
        id: '7949',
        ort: 'Nauen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14641',
    },
    {
        id: '7950',
        ort: 'Nauendorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99448',
    },
    {
        id: '7951',
        ort: 'Nauheim',
        bundesland: 'Hessen',
        landkreis: 'Kreis Groß-Gerau',
        plz: '64569',
    },
    {
        id: '7952',
        ort: 'Naumburg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34311',
    },
    {
        id: '7953',
        ort: 'Naumburg (Saale)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06618',
    },
    {
        id: '7954',
        ort: 'Naumburg (Saale)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06628',
    },
    {
        id: '7955',
        ort: 'Naundorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04769',
    },
    {
        id: '7956',
        ort: 'Naunheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56753',
    },
    {
        id: '7957',
        ort: 'Naunhof',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04683',
    },
    {
        id: '7958',
        ort: 'Nauort',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56237',
    },
    {
        id: '7959',
        ort: 'Naurath (Eifel)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54340',
    },
    {
        id: '7960',
        ort: 'Naurath (Wald)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54426',
    },
    {
        id: '7961',
        ort: 'Nauroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57583',
    },
    {
        id: '7962',
        ort: 'Nausnitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07616',
    },
    {
        id: '7963',
        ort: 'Nazza',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '99826',
    },
    {
        id: '7964',
        ort: 'Nebel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25946',
    },
    {
        id: '7965',
        ort: 'Nebelschütz - Njebjelčicy',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01920',
    },
    {
        id: '7966',
        ort: 'Nebra (Unstrut)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06642',
    },
    {
        id: '7967',
        ort: 'Neckarbischofsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '74924',
    },
    {
        id: '7968',
        ort: 'Neckargemünd',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69151',
    },
    {
        id: '7969',
        ort: 'Neckargerach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '69437',
    },
    {
        id: '7970',
        ort: 'Neckarsteinach',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '69239',
    },
    {
        id: '7971',
        ort: 'Neckarsulm',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74172',
    },
    {
        id: '7972',
        ort: 'Neckartailfingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '72666',
    },
    {
        id: '7973',
        ort: 'Neckartenzlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '72654',
    },
    {
        id: '7974',
        ort: 'Neckarwestheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74382',
    },
    {
        id: '7975',
        ort: 'Neckarzimmern',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74865',
    },
    {
        id: '7976',
        ort: 'Neddemin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17039',
    },
    {
        id: '7977',
        ort: 'Neef',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56858',
    },
    {
        id: '7978',
        ort: 'Neenstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89189',
    },
    {
        id: '7979',
        ort: 'Neetze',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21398',
    },
    {
        id: '7980',
        ort: 'Neetzka',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17349',
    },
    {
        id: '7981',
        ort: 'Neetzow-Liepen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17391',
    },
    {
        id: '7982',
        ort: 'Negenborn',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37643',
    },
    {
        id: '7983',
        ort: 'Negenharrie',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24625',
    },
    {
        id: '7984',
        ort: 'Negernbötel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23795',
    },
    {
        id: '7985',
        ort: 'Nehms',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23813',
    },
    {
        id: '7986',
        ort: 'Nehmten',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24326',
    },
    {
        id: '7987',
        ort: 'Nehren',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56820',
    },
    {
        id: '7988',
        ort: 'Nehren',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72147',
    },
    {
        id: '7989',
        ort: 'Neichen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '7990',
        ort: 'Neidenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54657',
    },
    {
        id: '7991',
        ort: 'Neidenfels',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67468',
    },
    {
        id: '7992',
        ort: 'Neidenstein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '74933',
    },
    {
        id: '7993',
        ort: 'Neidlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73272',
    },
    {
        id: '7994',
        ort: 'Neißeaue',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02829',
    },
    {
        id: '7995',
        ort: 'Neiße-Malxetal',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03159',
    },
    {
        id: '7996',
        ort: 'Neißemünde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15898',
    },
    {
        id: '7997',
        ort: 'Neitersen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57638',
    },
    {
        id: '7998',
        ort: 'Nellingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89191',
    },
    {
        id: '7999',
        ort: 'Nemsdorf-Göhrendorf',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06268',
    },
    {
        id: '8000',
        ort: 'Nenndorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26556',
    },
    {
        id: '8001',
        ort: 'Nennhausen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14715',
    },
    {
        id: '8002',
        ort: 'Nennslingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91790',
    },
    {
        id: '8003',
        ort: 'Nentershausen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36214',
    },
    {
        id: '8004',
        ort: 'Nentershausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '8005',
        ort: 'Nerdlen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '8006',
        ort: 'Nerenstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89129',
    },
    {
        id: '8007',
        ort: 'Neresheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73450',
    },
    {
        id: '8008',
        ort: 'Neritz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23843',
    },
    {
        id: '8009',
        ort: 'Neroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '8010',
        ort: 'Nersingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89278',
    },
    {
        id: '8011',
        ort: 'Nerzweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67749',
    },
    {
        id: '8012',
        ort: 'Neschwitz - Njeswačidło',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02699',
    },
    {
        id: '8013',
        ort: 'Nesse-Apfelstädt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99192',
    },
    {
        id: '8014',
        ort: 'Nesselwang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87484',
    },
    {
        id: '8015',
        ort: 'Nessetal',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99869',
    },
    {
        id: '8016',
        ort: 'Netphen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Siegen-Wittgenstein',
        plz: '57250',
    },
    {
        id: '8017',
        ort: 'Nettelsee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24250',
    },
    {
        id: '8018',
        ort: 'Nettersheim',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Euskirchen',
        plz: '53947',
    },
    {
        id: '8019',
        ort: 'Nettetal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Viersen',
        plz: '41334',
    },
    {
        id: '8020',
        ort: 'Netzbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65623',
    },
    {
        id: '8021',
        ort: 'Netzschkau',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08491',
    },
    {
        id: '8022',
        ort: 'Neu-Anspach',
        bundesland: 'Hessen',
        landkreis: 'Hochtaunuskreis',
        plz: '61267',
    },
    {
        id: '8023',
        ort: 'Neu-Bamberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55546',
    },
    {
        id: '8024',
        ort: 'Neuberend',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24879',
    },
    {
        id: '8025',
        ort: 'Neuberg',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63543',
    },
    {
        id: '8026',
        ort: 'Neubeuern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83115',
    },
    {
        id: '8027',
        ort: 'Neubiberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '85579',
    },
    {
        id: '8028',
        ort: 'Neu Boltenhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17509',
    },
    {
        id: '8029',
        ort: 'Neubörger',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26909',
    },
    {
        id: '8030',
        ort: 'Neubrandenburg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17033',
    },
    {
        id: '8031',
        ort: 'Neubrandenburg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17034',
    },
    {
        id: '8032',
        ort: 'Neubrandenburg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17036',
    },
    {
        id: '8033',
        ort: 'Neubrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97277',
    },
    {
        id: '8034',
        ort: 'Neubrunn',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98617',
    },
    {
        id: '8035',
        ort: 'Neubukow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18233',
    },
    {
        id: '8036',
        ort: 'Neubulach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '75385',
    },
    {
        id: '8037',
        ort: 'Neubulach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '75387',
    },
    {
        id: '8038',
        ort: 'Neuburg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23974',
    },
    {
        id: '8039',
        ort: 'Neuburg am Inn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94036',
    },
    {
        id: '8040',
        ort: 'Neuburg am Inn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94127',
    },
    {
        id: '8041',
        ort: 'Neuburg am Rhein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76776',
    },
    {
        id: '8042',
        ort: 'Neuburg an der Donau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neuburg-Schrobenhausen',
        plz: '86633',
    },
    {
        id: '8043',
        ort: 'Neuburg an der Kammel',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '86476',
    },
    {
        id: '8044',
        ort: 'Neuching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '85467',
    },
    {
        id: '8045',
        ort: 'Neu Darchau',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29490',
    },
    {
        id: '8046',
        ort: 'Neudenau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74861',
    },
    {
        id: '8047',
        ort: 'Neudorf-Bornstein',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24214',
    },
    {
        id: '8048',
        ort: 'Neudrossenfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95512',
    },
    {
        id: '8049',
        ort: 'Neu Duvenstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24791',
    },
    {
        id: '8050',
        ort: 'Neu Duvenstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24794',
    },
    {
        id: '8051',
        ort: 'Neu-Eichenberg',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37249',
    },
    {
        id: '8052',
        ort: 'Neuenbrook',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25361',
    },
    {
        id: '8053',
        ort: 'Neuenbrook',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25578',
    },
    {
        id: '8054',
        ort: 'Neuenbürg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75305',
    },
    {
        id: '8055',
        ort: 'Neuenburg am Rhein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79395',
    },
    {
        id: '8056',
        ort: 'Neuendeich',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25436',
    },
    {
        id: '8057',
        ort: 'Neuendettelsau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91564',
    },
    {
        id: '8058',
        ort: 'Neuendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97788',
    },
    {
        id: '8059',
        ort: 'Neuendorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '8060',
        ort: 'Neuendorf bei Elmshorn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25335',
    },
    {
        id: '8061',
        ort: 'Neuendorf-Sachsenbande',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25554',
    },
    {
        id: '8062',
        ort: 'Neuengönna',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07778',
    },
    {
        id: '8063',
        ort: 'Neuengörs',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23818',
    },
    {
        id: '8064',
        ort: 'Neuenhagen bei Berlin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15366',
    },
    {
        id: '8065',
        ort: 'Neuenhaus',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '49828',
    },
    {
        id: '8066',
        ort: 'Neuenkirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '48485',
    },
    {
        id: '8067',
        ort: 'Neuenkirchen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25792',
    },
    {
        id: '8068',
        ort: 'Neuenkirchen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21640',
    },
    {
        id: '8069',
        ort: 'Neuenkirchen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17498',
    },
    {
        id: '8070',
        ort: 'Neuenkirchen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17039',
    },
    {
        id: '8071',
        ort: 'Neuenkirchen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17392',
    },
    {
        id: '8072',
        ort: 'Neuenkirchen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18569',
    },
    {
        id: '8073',
        ort: 'Neuenkirchen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27251',
    },
    {
        id: '8074',
        ort: 'Neuenkirchen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21763',
    },
    {
        id: '8075',
        ort: 'Neuenkirchen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29643',
    },
    {
        id: '8076',
        ort: 'Neuenkirchen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49586',
    },
    {
        id: '8077',
        ort: 'Neuenkirchen-Vörden',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Vechta',
        plz: '49434',
    },
    {
        id: '8078',
        ort: 'Neuenmarkt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95339',
    },
    {
        id: '8079',
        ort: 'Neuenrade',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58809',
    },
    {
        id: '8080',
        ort: 'Neuensalz',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08541',
    },
    {
        id: '8081',
        ort: 'Neuenstadt am Kocher',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74196',
    },
    {
        id: '8082',
        ort: 'Neuenstein',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36286',
    },
    {
        id: '8083',
        ort: 'Neuenstein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Hohenlohekreis',
        plz: '74632',
    },
    {
        id: '8084',
        ort: 'Neuental',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34599',
    },
    {
        id: '8085',
        ort: 'Neuerburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '8086',
        ort: 'Neuerkirch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55471',
    },
    {
        id: '8087',
        ort: 'Neufahrn bei Freising',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85375',
    },
    {
        id: '8088',
        ort: 'Neufahrn bei Freising',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85376',
    },
    {
        id: '8089',
        ort: 'Neufahrn in Niederbayern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84088',
    },
    {
        id: '8090',
        ort: 'Neufeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25724',
    },
    {
        id: '8091',
        ort: 'Neufelderkoog',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25724',
    },
    {
        id: '8092',
        ort: 'Neuffen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '72639',
    },
    {
        id: '8093',
        ort: 'Neufra',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '72419',
    },
    {
        id: '8094',
        ort: 'Neufraunhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84181',
    },
    {
        id: '8095',
        ort: 'Neu Gülze',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19258',
    },
    {
        id: '8096',
        ort: 'Neuhardenberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15320',
    },
    {
        id: '8097',
        ort: 'Neuharlingersiel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26427',
    },
    {
        id: '8098',
        ort: 'Neuhaus',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21785',
    },
    {
        id: '8099',
        ort: 'Neuhaus a.d.Pegnitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91284',
    },
    {
        id: '8100',
        ort: 'Neuhaus am Inn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94152',
    },
    {
        id: '8101',
        ort: 'Neuhaus am Rennweg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sonneberg',
        plz: '98724',
    },
    {
        id: '8102',
        ort: 'Neuhäusel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56335',
    },
    {
        id: '8103',
        ort: 'Neuhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75242',
    },
    {
        id: '8104',
        ort: 'Neuhausen auf den Fildern',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73765',
    },
    {
        id: '8105',
        ort: 'Neuhausen/Erzgebirge',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09544',
    },
    {
        id: '8106',
        ort: 'Neuhausen ob Eck',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78579',
    },
    {
        id: '8107',
        ort: 'Neuhausen/Spree',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03058',
    },
    {
        id: '8108',
        ort: 'Neuheilenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '8109',
        ort: 'Neuhemsbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67680',
    },
    {
        id: '8110',
        ort: 'Neuhof',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36119',
    },
    {
        id: '8111',
        ort: 'Neuhof a.d.Zenn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '90616',
    },
    {
        id: '8112',
        ort: 'Neuhofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67141',
    },
    {
        id: '8113',
        ort: 'Neuhütten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97843',
    },
    {
        id: '8114',
        ort: 'Neuhütten',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54422',
    },
    {
        id: '8115',
        ort: 'Neu-Isenburg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Offenbach',
        plz: '63263',
    },
    {
        id: '8116',
        ort: 'Neukalen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17154',
    },
    {
        id: '8117',
        ort: 'Neu Kaliß',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19294',
    },
    {
        id: '8118',
        ort: 'Neukamperfehn',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26835',
    },
    {
        id: '8119',
        ort: 'Neukieritzsch',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04575',
    },
    {
        id: '8120',
        ort: 'Neukirch',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01936',
    },
    {
        id: '8121',
        ort: 'Neukirch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88099',
    },
    {
        id: '8122',
        ort: 'Neukirchen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23779',
    },
    {
        id: '8123',
        ort: 'Neukirchen',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34626',
    },
    {
        id: '8124',
        ort: 'Neukirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94362',
    },
    {
        id: '8125',
        ort: 'Neukirchen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25927',
    },
    {
        id: '8126',
        ort: 'Neukirchen-Balbini',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92445',
    },
    {
        id: '8127',
        ort: 'Neukirchen beim Heiligen Blut',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93453',
    },
    {
        id: '8128',
        ort: 'Neukirchen bei Sulzbach-Rosenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92259',
    },
    {
        id: '8129',
        ort: 'Neukirchen/Erzgebirge',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09221',
    },
    {
        id: '8130',
        ort: 'Neukirchen/Pleiße',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08459',
    },
    {
        id: '8131',
        ort: 'Neukirchen-Vluyn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '47506',
    },
    {
        id: '8132',
        ort: 'Neukirchen vorm Wald',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94154',
    },
    {
        id: '8133',
        ort: 'Neukirch/Lausitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01904',
    },
    {
        id: '8134',
        ort: 'Neukloster',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23992',
    },
    {
        id: '8135',
        ort: 'Neu Kosenow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17398',
    },
    {
        id: '8136',
        ort: 'Neulehe',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26909',
    },
    {
        id: '8137',
        ort: 'Neuleiningen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67271',
    },
    {
        id: '8138',
        ort: 'Neuler',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73491',
    },
    {
        id: '8139',
        ort: 'Neulewin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '16259',
    },
    {
        id: '8140',
        ort: 'Neulingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75245',
    },
    {
        id: '8141',
        ort: 'Neulußheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '68809',
    },
    {
        id: '8142',
        ort: 'Neumagen-Dhron',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54347',
    },
    {
        id: '8143',
        ort: 'Neumark',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08496',
    },
    {
        id: '8144',
        ort: 'Neumark',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99439',
    },
    {
        id: '8145',
        ort: 'Neumarkt in der Oberpfalz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92318',
    },
    {
        id: '8146',
        ort: 'Neumarkt in der Oberpfalz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92348',
    },
    {
        id: '8147',
        ort: 'Neumarkt-Sankt Veit',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84494',
    },
    {
        id: '8148',
        ort: 'Neumünster',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24534',
    },
    {
        id: '8149',
        ort: 'Neumünster',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24536',
    },
    {
        id: '8150',
        ort: 'Neumünster',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24537',
    },
    {
        id: '8151',
        ort: 'Neumünster',
        bundesland: 'Schleswig-Holstein',
        landkreis: '',
        plz: '24539',
    },
    {
        id: '8152',
        ort: 'Neunburg vorm Wald',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92431',
    },
    {
        id: '8153',
        ort: 'Neundorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07924',
    },
    {
        id: '8154',
        ort: 'Neunkhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57520',
    },
    {
        id: '8155',
        ort: 'Neunkirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Siegen-Wittgenstein',
        plz: '57290',
    },
    {
        id: '8156',
        ort: 'Neunkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63930',
    },
    {
        id: '8157',
        ort: 'Neunkirchen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74867',
    },
    {
        id: '8158',
        ort: 'Neunkirchen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '8159',
        ort: 'Neunkirchen',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Neunkirchen',
        plz: '66538',
    },
    {
        id: '8160',
        ort: 'Neunkirchen',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Neunkirchen',
        plz: '66539',
    },
    {
        id: '8161',
        ort: 'Neunkirchen',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Neunkirchen',
        plz: '66540',
    },
    {
        id: '8162',
        ort: 'Neunkirchen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54426',
    },
    {
        id: '8163',
        ort: 'Neunkirchen a.Brand',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91077',
    },
    {
        id: '8164',
        ort: 'Neunkirchen am Potzberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66887',
    },
    {
        id: '8165',
        ort: 'Neunkirchen a. Sand',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91233',
    },
    {
        id: '8166',
        ort: 'Neunkirchen-Seelscheid',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53819',
    },
    {
        id: '8167',
        ort: 'Neuötting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84524',
    },
    {
        id: '8168',
        ort: 'Neupetershain',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '03103',
    },
    {
        id: '8169',
        ort: 'Neu Poserin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19399',
    },
    {
        id: '8170',
        ort: 'Neupotz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76777',
    },
    {
        id: '8171',
        ort: 'Neureichenau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94089',
    },
    {
        id: '8172',
        ort: 'Neuried',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '82061',
    },
    {
        id: '8173',
        ort: 'Neuried',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '82131',
    },
    {
        id: '8174',
        ort: 'Neuried',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77743',
    },
    {
        id: '8175',
        ort: 'Neuruppin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16816',
    },
    {
        id: '8176',
        ort: 'Neuruppin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16818',
    },
    {
        id: '8177',
        ort: 'Neuruppin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16827',
    },
    {
        id: '8178',
        ort: 'Neuruppin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16833',
    },
    {
        id: '8179',
        ort: 'Neuruppin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16835',
    },
    {
        id: '8180',
        ort: 'Neusalza-Spremberg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02742',
    },
    {
        id: '8181',
        ort: 'Neusäß',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86356',
    },
    {
        id: '8182',
        ort: 'Neuschönau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94556',
    },
    {
        id: '8183',
        ort: 'Neuschoo',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26487',
    },
    {
        id: '8184',
        ort: 'Neu-Seeland',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '03103',
    },
    {
        id: '8185',
        ort: 'Neusitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91616',
    },
    {
        id: '8186',
        ort: 'Neusorg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95700',
    },
    {
        id: '8187',
        ort: 'Neuss',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41460',
    },
    {
        id: '8188',
        ort: 'Neuss',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41462',
    },
    {
        id: '8189',
        ort: 'Neuss',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41464',
    },
    {
        id: '8190',
        ort: 'Neuss',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41466',
    },
    {
        id: '8191',
        ort: 'Neuss',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41468',
    },
    {
        id: '8192',
        ort: 'Neuss',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41469',
    },
    {
        id: '8193',
        ort: 'Neuss',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41470',
    },
    {
        id: '8194',
        ort: 'Neuss',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41472',
    },
    {
        id: '8195',
        ort: 'Neustadt',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35279',
    },
    {
        id: '8196',
        ort: 'Neustadt a.Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97845',
    },
    {
        id: '8197',
        ort: 'Neustadt am Kulm',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '95514',
    },
    {
        id: '8198',
        ort: 'Neustadt am Rübenberge',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '31535',
    },
    {
        id: '8199',
        ort: 'Neustadt an der Aisch',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91413',
    },
    {
        id: '8200',
        ort: 'Neustadt an der Donau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '93333',
    },
    {
        id: '8201',
        ort: 'Neustadt an der Orla',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07806',
    },
    {
        id: '8202',
        ort: 'Neustadt an der Waldnaab',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92660',
    },
    {
        id: '8203',
        ort: 'Neustadt an der Weinstraße',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67433',
    },
    {
        id: '8204',
        ort: 'Neustadt an der Weinstraße',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67434',
    },
    {
        id: '8205',
        ort: 'Neustadt an der Weinstraße',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67435',
    },
    {
        id: '8206',
        ort: 'Neustadt bei Coburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Coburg',
        plz: '96465',
    },
    {
        id: '8207',
        ort: 'Neustadt (Dosse)',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16845',
    },
    {
        id: '8208',
        ort: 'Neustadt-Glewe',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19306',
    },
    {
        id: '8209',
        ort: 'Neustadt in Holstein',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23730',
    },
    {
        id: '8210',
        ort: 'Neustadt in Sachsen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01844',
    },
    {
        id: '8211',
        ort: 'Neustadt/Vogtland',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08223',
    },
    {
        id: '8212',
        ort: 'Neustadt/ Westerwald',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '8213',
        ort: 'Neustadt (Wied)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53577',
    },
    {
        id: '8214',
        ort: 'Neustetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72149',
    },
    {
        id: '8215',
        ort: 'Neustrelitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17235',
    },
    {
        id: '8216',
        ort: 'Neutraubling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93073',
    },
    {
        id: '8217',
        ort: 'Neutrebbin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15320',
    },
    {
        id: '8218',
        ort: 'Neu-Ulm',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89231',
    },
    {
        id: '8219',
        ort: 'Neu-Ulm',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89233',
    },
    {
        id: '8220',
        ort: 'Neuweiler',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '75389',
    },
    {
        id: '8221',
        ort: 'Neuwied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56564',
    },
    {
        id: '8222',
        ort: 'Neuwied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56566',
    },
    {
        id: '8223',
        ort: 'Neuwied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56567',
    },
    {
        id: '8224',
        ort: 'Neuwittenbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24161',
    },
    {
        id: '8225',
        ort: 'Neuwittenbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24214',
    },
    {
        id: '8226',
        ort: 'Neu Wulmstorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21629',
    },
    {
        id: '8227',
        ort: 'Neu Zauche',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15913',
    },
    {
        id: '8228',
        ort: 'Neuzelle',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15898',
    },
    {
        id: '8229',
        ort: 'Neverin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17039',
    },
    {
        id: '8230',
        ort: 'Neversdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23816',
    },
    {
        id: '8231',
        ort: 'Newel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54309',
    },
    {
        id: '8232',
        ort: 'Ney',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56283',
    },
    {
        id: '8233',
        ort: 'Nickenich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56645',
    },
    {
        id: '8234',
        ort: 'Nidda',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '63667',
    },
    {
        id: '8235',
        ort: 'Niddatal',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '61194',
    },
    {
        id: '8236',
        ort: 'Nidderau',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '61130',
    },
    {
        id: '8237',
        ort: 'Nideggen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Düren',
        plz: '52385',
    },
    {
        id: '8238',
        ort: 'Nieblum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25938',
    },
    {
        id: '8239',
        ort: 'Niebüll',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25899',
    },
    {
        id: '8240',
        ort: 'Nieby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24395',
    },
    {
        id: '8241',
        ort: 'Nieden',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17309',
    },
    {
        id: '8242',
        ort: 'Niedenstein',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34305',
    },
    {
        id: '8243',
        ort: 'Niederahr',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56414',
    },
    {
        id: '8244',
        ort: 'Niederaichbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84100',
    },
    {
        id: '8245',
        ort: 'Niederalben',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66887',
    },
    {
        id: '8246',
        ort: 'Niederalteich',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94557',
    },
    {
        id: '8247',
        ort: 'Niederau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01689',
    },
    {
        id: '8248',
        ort: 'Niederaula',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36272',
    },
    {
        id: '8249',
        ort: 'Niederbachheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '8250',
        ort: 'Niederbergkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84494',
    },
    {
        id: '8251',
        ort: 'Niederbösa',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '99718',
    },
    {
        id: '8252',
        ort: 'Niederbreitbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56589',
    },
    {
        id: '8253',
        ort: 'Niederbrombach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '8254',
        ort: 'Niederburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55432',
    },
    {
        id: '8255',
        ort: 'Niederdorf',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09366',
    },
    {
        id: '8256',
        ort: 'Niederdorfelden',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '61138',
    },
    {
        id: '8257',
        ort: 'Niederdreisbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57520',
    },
    {
        id: '8258',
        ort: 'Niederdürenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '56651',
    },
    {
        id: '8259',
        ort: 'Niedere Börde',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39326',
    },
    {
        id: '8260',
        ort: 'Niedere Börde',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39345',
    },
    {
        id: '8261',
        ort: 'Niederelbert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '8262',
        ort: 'Niedererbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '8263',
        ort: 'Niederer Fläming',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Teltow-Fläming',
        plz: '14913',
    },
    {
        id: '8264',
        ort: 'Niedereschach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78078',
    },
    {
        id: '8265',
        ort: 'Niederfell',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56332',
    },
    {
        id: '8266',
        ort: 'Niederfinow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16248',
    },
    {
        id: '8267',
        ort: 'Niederfischbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57572',
    },
    {
        id: '8268',
        ort: 'Niederfrohna',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '09243',
    },
    {
        id: '8269',
        ort: 'Niederfüllbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Coburg',
        plz: '96489',
    },
    {
        id: '8270',
        ort: 'Niedergebra',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Nordhausen',
        plz: '99759',
    },
    {
        id: '8271',
        ort: 'Niedergeckler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '8272',
        ort: 'Niedergörsdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Teltow-Fläming',
        plz: '14913',
    },
    {
        id: '8273',
        ort: 'Niederhambach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '8274',
        ort: 'Niederhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55585',
    },
    {
        id: '8275',
        ort: 'Niederhausen an der Appel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67822',
    },
    {
        id: '8276',
        ort: 'Niederheimbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55413',
    },
    {
        id: '8277',
        ort: 'Nieder-Hilbersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55437',
    },
    {
        id: '8278',
        ort: 'Niederhofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56316',
    },
    {
        id: '8279',
        ort: 'Niederhorbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76889',
    },
    {
        id: '8280',
        ort: 'Niederhosenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '8281',
        ort: 'Niederirsen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57589',
    },
    {
        id: '8282',
        ort: 'Niederkassel',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53859',
    },
    {
        id: '8283',
        ort: 'Niederkirchen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67700',
    },
    {
        id: '8284',
        ort: 'Niederkirchen bei Deidesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67150',
    },
    {
        id: '8285',
        ort: 'Nieder Kostenz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55481',
    },
    {
        id: '8286',
        ort: 'Niederkrüchten',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Viersen',
        plz: '41372',
    },
    {
        id: '8287',
        ort: 'Niederkumbd',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55469',
    },
    {
        id: '8288',
        ort: 'Niederlangen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49779',
    },
    {
        id: '8289',
        ort: 'Niederlauch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54614',
    },
    {
        id: '8290',
        ort: 'Niederlauer',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97618',
    },
    {
        id: '8291',
        ort: 'Niedermohr',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66879',
    },
    {
        id: '8292',
        ort: 'Niedermoschel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67822',
    },
    {
        id: '8293',
        ort: 'Niedermurach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92545',
    },
    {
        id: '8294',
        ort: 'Niedernberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63843',
    },
    {
        id: '8295',
        ort: 'Niederneisen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65629',
    },
    {
        id: '8296',
        ort: 'Niedernhall',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Hohenlohekreis',
        plz: '74676',
    },
    {
        id: '8297',
        ort: 'Niedernhausen',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65527',
    },
    {
        id: '8298',
        ort: 'Niedernwöhren',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31712',
    },
    {
        id: '8299',
        ort: 'Niederöfflingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54533',
    },
    {
        id: '8300',
        ort: 'Nieder-Olm',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55268',
    },
    {
        id: '8301',
        ort: 'Niederorschel',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37355',
    },
    {
        id: '8302',
        ort: 'Niederotterbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76889',
    },
    {
        id: '8303',
        ort: 'Niederpierscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54649',
    },
    {
        id: '8304',
        ort: 'Niederraden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '8305',
        ort: 'Niederrieden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87767',
    },
    {
        id: '8306',
        ort: 'Niederroßbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '8307',
        ort: 'Niedersayn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '8308',
        ort: 'Niederscheidweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54533',
    },
    {
        id: '8309',
        ort: 'Niederschlettenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '76891',
    },
    {
        id: '8310',
        ort: 'Niederschönenfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86694',
    },
    {
        id: '8311',
        ort: 'Niedersohren',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55487',
    },
    {
        id: '8312',
        ort: 'Niederstadtfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '8313',
        ort: 'Niederstaufenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66879',
    },
    {
        id: '8314',
        ort: 'Niederstedem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54634',
    },
    {
        id: '8315',
        ort: 'Niedersteinebach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '56593',
    },
    {
        id: '8316',
        ort: 'Niederstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Main-Tauber-Kreis',
        plz: '97996',
    },
    {
        id: '8317',
        ort: 'Niederstotzingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heidenheim',
        plz: '89168',
    },
    {
        id: '8318',
        ort: 'Niedert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56291',
    },
    {
        id: '8319',
        ort: 'Niedertaufkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84494',
    },
    {
        id: '8320',
        ort: 'Niedertiefenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56368',
    },
    {
        id: '8321',
        ort: 'Niedertrebra',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99518',
    },
    {
        id: '8322',
        ort: 'Niederviehbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dingolfing-Landau',
        plz: '84183',
    },
    {
        id: '8323',
        ort: 'Niederwallmenach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '8324',
        ort: 'Niederwambach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '57614',
    },
    {
        id: '8325',
        ort: 'Niederweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '8326',
        ort: 'Niederweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55491',
    },
    {
        id: '8327',
        ort: 'Niederweis',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54298',
    },
    {
        id: '8328',
        ort: 'Niederweis',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54668',
    },
    {
        id: '8329',
        ort: 'Niederwerrn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97464',
    },
    {
        id: '8330',
        ort: 'Niederwerth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56179',
    },
    {
        id: '8331',
        ort: 'Niederwiesa',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09577',
    },
    {
        id: '8332',
        ort: 'Nieder-Wiesen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '8333',
        ort: 'Niederwinkling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94559',
    },
    {
        id: '8334',
        ort: 'Niederwörresbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '8335',
        ort: 'Niederwürschnitz',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09399',
    },
    {
        id: '8336',
        ort: 'Niederzier',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Düren',
        plz: '52382',
    },
    {
        id: '8337',
        ort: 'Niederzissen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '56651',
    },
    {
        id: '8338',
        ort: 'Niefern-Öschelbronn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75223',
    },
    {
        id: '8339',
        ort: 'Nieheim',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Höxter',
        plz: '33039',
    },
    {
        id: '8340',
        ort: 'Niehl',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54646',
    },
    {
        id: '8341',
        ort: 'Niemegk',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14823',
    },
    {
        id: '8342',
        ort: 'Niemetal',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37127',
    },
    {
        id: '8343',
        ort: 'Nienborstel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24819',
    },
    {
        id: '8344',
        ort: 'Nienburg (Saale)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '06429',
    },
    {
        id: '8345',
        ort: 'Nienburg/Weser',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31582',
    },
    {
        id: '8346',
        ort: 'Nienbüttel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25596',
    },
    {
        id: '8347',
        ort: 'Niendorf bei Berkenthin',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23919',
    },
    {
        id: '8348',
        ort: 'Niendorf/Stecknitz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23881',
    },
    {
        id: '8349',
        ort: 'Nienhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18211',
    },
    {
        id: '8350',
        ort: 'Nienhagen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29336',
    },
    {
        id: '8351',
        ort: 'Nienstädt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31688',
    },
    {
        id: '8352',
        ort: 'Nienwohld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23863',
    },
    {
        id: '8353',
        ort: 'Niepars',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18442',
    },
    {
        id: '8354',
        ort: 'Niersbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '8355',
        ort: 'Nierstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55283',
    },
    {
        id: '8356',
        ort: 'Niesgrau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24395',
    },
    {
        id: '8357',
        ort: 'Niesky',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02906',
    },
    {
        id: '8358',
        ort: 'Nieste',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34329',
    },
    {
        id: '8359',
        ort: 'Niestetal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34266',
    },
    {
        id: '8360',
        ort: 'Nievern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56132',
    },
    {
        id: '8361',
        ort: 'Nimritz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07381',
    },
    {
        id: '8362',
        ort: 'Nimshuscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54612',
    },
    {
        id: '8363',
        ort: 'Nimsreuland',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54614',
    },
    {
        id: '8364',
        ort: 'Nindorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25704',
    },
    {
        id: '8365',
        ort: 'Nindorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24594',
    },
    {
        id: '8366',
        ort: 'Nister',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57645',
    },
    {
        id: '8367',
        ort: 'Nisterau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56472',
    },
    {
        id: '8368',
        ort: 'Nisterberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '56472',
    },
    {
        id: '8369',
        ort: 'Nister-Möhrendorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56477',
    },
    {
        id: '8370',
        ort: 'Nistertal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57647',
    },
    {
        id: '8371',
        ort: 'Nittel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54453',
    },
    {
        id: '8372',
        ort: 'Nittenau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '93149',
    },
    {
        id: '8373',
        ort: 'Nittendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93152',
    },
    {
        id: '8374',
        ort: 'Nitz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '56729',
    },
    {
        id: '8375',
        ort: 'Nobitz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04603',
    },
    {
        id: '8376',
        ort: 'Nochern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '8377',
        ort: 'Nöda',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99195',
    },
    {
        id: '8378',
        ort: 'Noer',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24214',
    },
    {
        id: '8379',
        ort: 'Nohen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '8380',
        ort: 'Nohfelden',
        bundesland: 'Saarland',
        landkreis: 'Landkreis St. Wendel',
        plz: '66625',
    },
    {
        id: '8381',
        ort: 'Nohn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54578',
    },
    {
        id: '8382',
        ort: 'Nomborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '8383',
        ort: 'Nonnenhorn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88149',
    },
    {
        id: '8384',
        ort: 'Nonnweiler',
        bundesland: 'Saarland',
        landkreis: 'Landkreis St. Wendel',
        plz: '66620',
    },
    {
        id: '8385',
        ort: 'Norath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56291',
    },
    {
        id: '8386',
        ort: 'Norddeich',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25764',
    },
    {
        id: '8387',
        ort: 'Norddorf auf Amrum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25946',
    },
    {
        id: '8388',
        ort: 'Norden',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26506',
    },
    {
        id: '8389',
        ort: 'Nordendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86695',
    },
    {
        id: '8390',
        ort: 'Nordenham',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wesermarsch',
        plz: '26954',
    },
    {
        id: '8391',
        ort: 'Norderbrarup',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24392',
    },
    {
        id: '8392',
        ort: 'Norderfriedrichskoog',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25870',
    },
    {
        id: '8393',
        ort: 'Norderheistedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25779',
    },
    {
        id: '8394',
        ort: 'Nordermeldorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25704',
    },
    {
        id: '8395',
        ort: 'Norderney',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26548',
    },
    {
        id: '8396',
        ort: 'Norderstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '22844',
    },
    {
        id: '8397',
        ort: 'Norderstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '22846',
    },
    {
        id: '8398',
        ort: 'Norderstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '22848',
    },
    {
        id: '8399',
        ort: 'Norderstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '22850',
    },
    {
        id: '8400',
        ort: 'Norderstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '22851',
    },
    {
        id: '8401',
        ort: 'Norderwöhrden',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25746',
    },
    {
        id: '8402',
        ort: 'Nordhackstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24980',
    },
    {
        id: '8403',
        ort: 'Nordhalben',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kronach',
        plz: '96365',
    },
    {
        id: '8404',
        ort: 'Nordharz',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38855',
    },
    {
        id: '8405',
        ort: 'Nordharz',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38871',
    },
    {
        id: '8406',
        ort: 'Nordhastedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25785',
    },
    {
        id: '8407',
        ort: 'Nordhausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Nordhausen',
        plz: '99734',
    },
    {
        id: '8408',
        ort: 'Nordheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74226',
    },
    {
        id: '8409',
        ort: 'Nordheim a.Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97334',
    },
    {
        id: '8410',
        ort: 'Nordheim v.d.Rhön',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97647',
    },
    {
        id: '8411',
        ort: 'Nordhofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56242',
    },
    {
        id: '8412',
        ort: 'Nordhorn',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '48527',
    },
    {
        id: '8413',
        ort: 'Nordhorn',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '48529',
    },
    {
        id: '8414',
        ort: 'Nordhorn',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '48531',
    },
    {
        id: '8415',
        ort: 'Nordkirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Coesfeld',
        plz: '59394',
    },
    {
        id: '8416',
        ort: 'Nordleda',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21765',
    },
    {
        id: '8417',
        ort: 'Nördlingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86720',
    },
    {
        id: '8418',
        ort: 'Nordrach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77787',
    },
    {
        id: '8419',
        ort: 'Nordsehl',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31717',
    },
    {
        id: '8420',
        ort: 'Nordstemmen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31171',
    },
    {
        id: '8421',
        ort: 'Nordstrand',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25845',
    },
    {
        id: '8422',
        ort: 'Nordwalde',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '48356',
    },
    {
        id: '8423',
        ort: 'Nordwestuckermark',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17291',
    },
    {
        id: '8424',
        ort: 'Norheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55585',
    },
    {
        id: '8425',
        ort: 'Norken',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57629',
    },
    {
        id: '8426',
        ort: 'Norstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25884',
    },
    {
        id: '8427',
        ort: 'Nörten-Hardenberg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Northeim',
        plz: '37176',
    },
    {
        id: '8428',
        ort: 'Nörtershausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56283',
    },
    {
        id: '8429',
        ort: 'Northeim',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Northeim',
        plz: '37154',
    },
    {
        id: '8430',
        ort: 'Nortmoor',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26845',
    },
    {
        id: '8431',
        ort: 'Nortorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25554',
    },
    {
        id: '8432',
        ort: 'Nortorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24589',
    },
    {
        id: '8433',
        ort: 'Nortrup',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49638',
    },
    {
        id: '8434',
        ort: 'Nörvenich',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Düren',
        plz: '52388',
    },
    {
        id: '8435',
        ort: 'Nossen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01683',
    },
    {
        id: '8436',
        ort: 'Nossendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17111',
    },
    {
        id: '8437',
        ort: 'Nossentiner Hütte',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17214',
    },
    {
        id: '8438',
        ort: 'Nostorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19258',
    },
    {
        id: '8439',
        ort: 'Nothweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '76891',
    },
    {
        id: '8440',
        ort: 'Nottensdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21640',
    },
    {
        id: '8441',
        ort: 'Nottertal-Heilinger Höhen',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99994',
    },
    {
        id: '8442',
        ort: 'Nottfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24392',
    },
    {
        id: '8443',
        ort: 'Nottleben',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99192',
    },
    {
        id: '8444',
        ort: 'Nottuln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Coesfeld',
        plz: '48301',
    },
    {
        id: '8445',
        ort: 'Notzingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73274',
    },
    {
        id: '8446',
        ort: 'Nübbel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24809',
    },
    {
        id: '8447',
        ort: 'Nübel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24881',
    },
    {
        id: '8448',
        ort: 'Nüdlingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97720',
    },
    {
        id: '8449',
        ort: 'Nufringen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71154',
    },
    {
        id: '8450',
        ort: 'Nümbrecht',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Oberbergischer Kreis',
        plz: '51588',
    },
    {
        id: '8451',
        ort: 'Nünchritz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01612',
    },
    {
        id: '8452',
        ort: 'Nünschweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66989',
    },
    {
        id: '8453',
        ort: 'Nürburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53520',
    },
    {
        id: '8454',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90402',
    },
    {
        id: '8455',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90403',
    },
    {
        id: '8456',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90408',
    },
    {
        id: '8457',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90409',
    },
    {
        id: '8458',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90411',
    },
    {
        id: '8459',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90419',
    },
    {
        id: '8460',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90425',
    },
    {
        id: '8461',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90427',
    },
    {
        id: '8462',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90429',
    },
    {
        id: '8463',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90431',
    },
    {
        id: '8464',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90439',
    },
    {
        id: '8465',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90441',
    },
    {
        id: '8466',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90443',
    },
    {
        id: '8467',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90449',
    },
    {
        id: '8468',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90451',
    },
    {
        id: '8469',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90453',
    },
    {
        id: '8470',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90455',
    },
    {
        id: '8471',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90459',
    },
    {
        id: '8472',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90461',
    },
    {
        id: '8473',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90469',
    },
    {
        id: '8474',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90471',
    },
    {
        id: '8475',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90473',
    },
    {
        id: '8476',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90475',
    },
    {
        id: '8477',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90478',
    },
    {
        id: '8478',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90480',
    },
    {
        id: '8479',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90482',
    },
    {
        id: '8480',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90489',
    },
    {
        id: '8481',
        ort: 'Nürnberg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '90491',
    },
    {
        id: '8482',
        ort: 'Nürtingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '72622',
    },
    {
        id: '8483',
        ort: 'Nusbaum',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '8484',
        ort: 'Nusplingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72362',
    },
    {
        id: '8485',
        ort: 'Nußbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67759',
    },
    {
        id: '8486',
        ort: 'Nußbaum',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55569',
    },
    {
        id: '8487',
        ort: 'Nußdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83365',
    },
    {
        id: '8488',
        ort: 'Nußdorf am Inn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83131',
    },
    {
        id: '8489',
        ort: 'Nusse',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23896',
    },
    {
        id: '8490',
        ort: 'Nußloch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69226',
    },
    {
        id: '8491',
        ort: 'Nustrow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18195',
    },
    {
        id: '8492',
        ort: 'Nüsttal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36167',
    },
    {
        id: '8493',
        ort: 'Nuthetal',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14558',
    },
    {
        id: '8494',
        ort: 'Nuthe-Urstromtal',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Teltow-Fläming',
        plz: '14947',
    },
    {
        id: '8495',
        ort: 'Nutteln',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25594',
    },
    {
        id: '8496',
        ort: 'Nützen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24568',
    },
    {
        id: '8497',
        ort: 'Oberahr',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56414',
    },
    {
        id: '8498',
        ort: 'Oberalben',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66871',
    },
    {
        id: '8499',
        ort: 'Oberammergau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82487',
    },
    {
        id: '8500',
        ort: 'Oberarnbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66851',
    },
    {
        id: '8501',
        ort: 'Oberasbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürth',
        plz: '90522',
    },
    {
        id: '8502',
        ort: 'Oberau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82496',
    },
    {
        id: '8503',
        ort: 'Oberaudorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83080',
    },
    {
        id: '8504',
        ort: 'Oberaula',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '36280',
    },
    {
        id: '8505',
        ort: 'Oberaurach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '97514',
    },
    {
        id: '8506',
        ort: 'Oberbachheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56355',
    },
    {
        id: '8507',
        ort: 'Oberbarnim',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15377',
    },
    {
        id: '8508',
        ort: 'Oberbergkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84564',
    },
    {
        id: '8509',
        ort: 'Oberbettingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54578',
    },
    {
        id: '8510',
        ort: 'Oberbillig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54331',
    },
    {
        id: '8511',
        ort: 'Oberbodnitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '8512',
        ort: 'Oberboihingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '72644',
    },
    {
        id: '8513',
        ort: 'Oberbösa',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '99718',
    },
    {
        id: '8514',
        ort: 'Oberbrombach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '8515',
        ort: 'Oberdachstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91617',
    },
    {
        id: '8516',
        ort: 'Oberderdingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '75038',
    },
    {
        id: '8517',
        ort: 'Oberdiebach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55413',
    },
    {
        id: '8518',
        ort: 'Oberding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '85445',
    },
    {
        id: '8519',
        ort: 'Oberdischingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89610',
    },
    {
        id: '8520',
        ort: 'Oberdolling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85129',
    },
    {
        id: '8521',
        ort: 'Oberdreis',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '57639',
    },
    {
        id: '8522',
        ort: 'Oberdürenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '56651',
    },
    {
        id: '8523',
        ort: 'Oberehe-Stroheich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54578',
    },
    {
        id: '8524',
        ort: 'Oberelbert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '8525',
        ort: 'Oberelsbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97656',
    },
    {
        id: '8526',
        ort: 'Oberelz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '56767',
    },
    {
        id: '8527',
        ort: 'Obererbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57612',
    },
    {
        id: '8528',
        ort: 'Obererbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56414',
    },
    {
        id: '8529',
        ort: 'Obere Warnow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19374',
    },
    {
        id: '8530',
        ort: 'Oberfell',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56332',
    },
    {
        id: '8531',
        ort: 'Oberfischbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56370',
    },
    {
        id: '8532',
        ort: 'Ober-Flörsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '8533',
        ort: 'Obergeckler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '8534',
        ort: 'Obergriesbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86573',
    },
    {
        id: '8535',
        ort: 'Obergröningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73569',
    },
    {
        id: '8536',
        ort: 'Obergünzburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87634',
    },
    {
        id: '8537',
        ort: 'Obergurig - Hornja Hórka',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02692',
    },
    {
        id: '8538',
        ort: 'Oberhaching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '82041',
    },
    {
        id: '8539',
        ort: 'Oberhaching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '82064',
    },
    {
        id: '8540',
        ort: 'Oberhaid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56237',
    },
    {
        id: '8541',
        ort: 'Oberhaid',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96173',
    },
    {
        id: '8542',
        ort: 'Oberhambach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55765',
    },
    {
        id: '8543',
        ort: 'Oberharmersbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77784',
    },
    {
        id: '8544',
        ort: 'Oberharz am Brocken',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38875',
    },
    {
        id: '8545',
        ort: 'Oberharz am Brocken',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38877',
    },
    {
        id: '8546',
        ort: 'Oberharz am Brocken',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38889',
    },
    {
        id: '8547',
        ort: 'Oberharz am Brocken',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38899',
    },
    {
        id: '8548',
        ort: 'Oberhausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '46045',
    },
    {
        id: '8549',
        ort: 'Oberhausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '46047',
    },
    {
        id: '8550',
        ort: 'Oberhausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '46049',
    },
    {
        id: '8551',
        ort: 'Oberhausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '46117',
    },
    {
        id: '8552',
        ort: 'Oberhausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '46119',
    },
    {
        id: '8553',
        ort: 'Oberhausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '46145',
    },
    {
        id: '8554',
        ort: 'Oberhausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '46147',
    },
    {
        id: '8555',
        ort: 'Oberhausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '46149',
    },
    {
        id: '8556',
        ort: 'Oberhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82386',
    },
    {
        id: '8557',
        ort: 'Oberhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neuburg-Schrobenhausen',
        plz: '86697',
    },
    {
        id: '8558',
        ort: 'Oberhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76887',
    },
    {
        id: '8559',
        ort: 'Oberhausen an der Appel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67822',
    },
    {
        id: '8560',
        ort: 'Oberhausen an der Nahe',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55585',
    },
    {
        id: '8561',
        ort: 'Oberhausen bei Kirn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55606',
    },
    {
        id: '8562',
        ort: 'Oberhausen-Rheinhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '68794',
    },
    {
        id: '8563',
        ort: 'Oberheimbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55413',
    },
    {
        id: '8564',
        ort: 'Oberheldrungen',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '06577',
    },
    {
        id: '8565',
        ort: 'Ober-Hilbersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55437',
    },
    {
        id: '8566',
        ort: 'Oberhof',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98559',
    },
    {
        id: '8567',
        ort: 'Oberhonnefeld-Gierend',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56587',
    },
    {
        id: '8568',
        ort: 'Oberhosenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '8569',
        ort: 'Oberickelsheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '97258',
    },
    {
        id: '8570',
        ort: 'Oberirsen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57635',
    },
    {
        id: '8571',
        ort: 'Oberkail',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54533',
    },
    {
        id: '8572',
        ort: 'Oberkirch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77704',
    },
    {
        id: '8573',
        ort: 'Oberkirn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55624',
    },
    {
        id: '8574',
        ort: 'Oberkochen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73447',
    },
    {
        id: '8575',
        ort: 'Ober Kostenz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55481',
    },
    {
        id: '8576',
        ort: 'Oberkotzau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95145',
    },
    {
        id: '8577',
        ort: 'Oberkrämer',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16727',
    },
    {
        id: '8578',
        ort: 'Oberlahr',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57641',
    },
    {
        id: '8579',
        ort: 'Oberlangen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49779',
    },
    {
        id: '8580',
        ort: 'Oberlascheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54608',
    },
    {
        id: '8581',
        ort: 'Oberlauch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54614',
    },
    {
        id: '8582',
        ort: 'Oberleichtersbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97789',
    },
    {
        id: '8583',
        ort: 'Oberlungwitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '09353',
    },
    {
        id: '8584',
        ort: 'Obermaiselstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87538',
    },
    {
        id: '8585',
        ort: 'Obermarchtal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89611',
    },
    {
        id: '8586',
        ort: 'Obermaßfeld-Grimmenthal',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98617',
    },
    {
        id: '8587',
        ort: 'Obermeitingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86836',
    },
    {
        id: '8588',
        ort: 'Obermichelbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürth',
        plz: '90587',
    },
    {
        id: '8589',
        ort: 'Ober-Mörlen',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '61239',
    },
    {
        id: '8590',
        ort: 'Obermoschel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67823',
    },
    {
        id: '8591',
        ort: 'Obernbreit',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97342',
    },
    {
        id: '8592',
        ort: 'Obernburg am Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63785',
    },
    {
        id: '8593',
        ort: 'Oberndorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21787',
    },
    {
        id: '8594',
        ort: 'Oberndorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67821',
    },
    {
        id: '8595',
        ort: 'Oberndorf a.Lech',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86698',
    },
    {
        id: '8596',
        ort: 'Oberndorf am Neckar',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78727',
    },
    {
        id: '8597',
        ort: 'Oberneisen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65558',
    },
    {
        id: '8598',
        ort: 'Oberneukirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84565',
    },
    {
        id: '8599',
        ort: 'Obernfeld',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37434',
    },
    {
        id: '8600',
        ort: 'Obernheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72364',
    },
    {
        id: '8601',
        ort: 'Obernheim-Kirchenarnbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66919',
    },
    {
        id: '8602',
        ort: 'Obernhof',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56379',
    },
    {
        id: '8603',
        ort: 'Obernholz',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '29386',
    },
    {
        id: '8604',
        ort: 'Obernkirchen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31683',
    },
    {
        id: '8605',
        ort: 'Obernzell',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94107',
    },
    {
        id: '8606',
        ort: 'Obernzell',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94130',
    },
    {
        id: '8607',
        ort: 'Obernzenn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91619',
    },
    {
        id: '8608',
        ort: 'Oberöfflingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54533',
    },
    {
        id: '8609',
        ort: 'Ober-Olm',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55270',
    },
    {
        id: '8610',
        ort: 'Oberoppurg',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07381',
    },
    {
        id: '8611',
        ort: 'Oberostendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '86869',
    },
    {
        id: '8612',
        ort: 'Oberotterbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76889',
    },
    {
        id: '8613',
        ort: 'Oberottmarshausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86507',
    },
    {
        id: '8614',
        ort: 'Oberpframmern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85667',
    },
    {
        id: '8615',
        ort: 'Oberpierscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54649',
    },
    {
        id: '8616',
        ort: 'Oberpleichfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97241',
    },
    {
        id: '8617',
        ort: 'Oberpöring',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94562',
    },
    {
        id: '8618',
        ort: 'Oberraden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56587',
    },
    {
        id: '8619',
        ort: 'Ober-Ramstadt',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64372',
    },
    {
        id: '8620',
        ort: 'Oberreichenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '75394',
    },
    {
        id: '8621',
        ort: 'Oberreichenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91097',
    },
    {
        id: '8622',
        ort: 'Oberreidenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '8623',
        ort: 'Oberreute',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88179',
    },
    {
        id: '8624',
        ort: 'Oberried',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79254',
    },
    {
        id: '8625',
        ort: 'Oberrieden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87769',
    },
    {
        id: '8626',
        ort: 'Oberriexingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71739',
    },
    {
        id: '8627',
        ort: 'Oberrod',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '8628',
        ort: 'Oberroßbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '8629',
        ort: 'Oberrot',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74420',
    },
    {
        id: '8630',
        ort: 'Oberroth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89294',
    },
    {
        id: '8631',
        ort: 'Oberscheidweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54533',
    },
    {
        id: '8632',
        ort: 'Oberscheinfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91483',
    },
    {
        id: '8633',
        ort: 'Oberschleißheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '85764',
    },
    {
        id: '8634',
        ort: 'Oberschlettenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76889',
    },
    {
        id: '8635',
        ort: 'Oberschneiding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94363',
    },
    {
        id: '8636',
        ort: 'Oberschöna',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09600',
    },
    {
        id: '8637',
        ort: 'Oberschönegg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87770',
    },
    {
        id: '8638',
        ort: 'Oberschwarzach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97516',
    },
    {
        id: '8639',
        ort: 'Oberschweinbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82294',
    },
    {
        id: '8640',
        ort: 'Obersimten',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66957',
    },
    {
        id: '8641',
        ort: 'Obersinn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97791',
    },
    {
        id: '8642',
        ort: 'Obersöchering',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82395',
    },
    {
        id: '8643',
        ort: 'Obersontheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74423',
    },
    {
        id: '8644',
        ort: 'Oberstadion',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89613',
    },
    {
        id: '8645',
        ort: 'Oberstadt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98530',
    },
    {
        id: '8646',
        ort: 'Oberstadtfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '8647',
        ort: 'Oberstaufen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87534',
    },
    {
        id: '8648',
        ort: 'Oberstaufenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66879',
    },
    {
        id: '8649',
        ort: 'Oberstdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87561',
    },
    {
        id: '8650',
        ort: 'Oberstedem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54634',
    },
    {
        id: '8651',
        ort: 'Obersteinebach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '56593',
    },
    {
        id: '8652',
        ort: 'Oberstenfeld',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71720',
    },
    {
        id: '8653',
        ort: 'Oberstreit',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55596',
    },
    {
        id: '8654',
        ort: 'Oberstreu',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97640',
    },
    {
        id: '8655',
        ort: 'Obersulm',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74182',
    },
    {
        id: '8656',
        ort: 'Obersülzen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67271',
    },
    {
        id: '8657',
        ort: 'Obersüßbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84101',
    },
    {
        id: '8658',
        ort: 'Obertaufkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84419',
    },
    {
        id: '8659',
        ort: 'Oberteuringen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88094',
    },
    {
        id: '8660',
        ort: 'Oberthal',
        bundesland: 'Saarland',
        landkreis: 'Landkreis St. Wendel',
        plz: '66649',
    },
    {
        id: '8661',
        ort: 'Oberthulba',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97723',
    },
    {
        id: '8662',
        ort: 'Obertiefenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '8663',
        ort: 'Obertraubling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93083',
    },
    {
        id: '8664',
        ort: 'Obertrebra',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99510',
    },
    {
        id: '8665',
        ort: 'Obertrubach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91286',
    },
    {
        id: '8666',
        ort: 'Obertshausen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Offenbach',
        plz: '63179',
    },
    {
        id: '8667',
        ort: 'Oberuckersee',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17291',
    },
    {
        id: '8668',
        ort: 'Oberursel',
        bundesland: 'Hessen',
        landkreis: 'Hochtaunuskreis',
        plz: '61440',
    },
    {
        id: '8669',
        ort: 'Oberviechtach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92526',
    },
    {
        id: '8670',
        ort: 'Oberwallmenach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '8671',
        ort: 'Oberwambach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57614',
    },
    {
        id: '8672',
        ort: 'Oberweid',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98634',
    },
    {
        id: '8673',
        ort: 'Oberweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '8674',
        ort: 'Oberweiler im Tal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67756',
    },
    {
        id: '8675',
        ort: 'Oberweiler-Tiefenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67752',
    },
    {
        id: '8676',
        ort: 'Oberweis',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '8677',
        ort: 'Oberwesel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55430',
    },
    {
        id: '8678',
        ort: 'Oberwiera',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08396',
    },
    {
        id: '8679',
        ort: 'Oberwies',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56379',
    },
    {
        id: '8680',
        ort: 'Oberwiesen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67294',
    },
    {
        id: '8681',
        ort: 'Oberwiesenthal',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09484',
    },
    {
        id: '8682',
        ort: 'Oberwolfach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77709',
    },
    {
        id: '8683',
        ort: 'Oberwörresbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '8684',
        ort: 'Oberzent',
        bundesland: 'Hessen',
        landkreis: 'Odenwaldkreis',
        plz: '64760',
    },
    {
        id: '8685',
        ort: 'Oberzissen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '56651',
    },
    {
        id: '8686',
        ort: 'Obhausen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06268',
    },
    {
        id: '8687',
        ort: 'Obing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83119',
    },
    {
        id: '8688',
        ort: 'Obrigheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74847',
    },
    {
        id: '8689',
        ort: 'Obrigheim (Pfalz)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67283',
    },
    {
        id: '8690',
        ort: 'Ochsenfurt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97199',
    },
    {
        id: '8691',
        ort: 'Ochsenhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88416',
    },
    {
        id: '8692',
        ort: 'Ochtendung',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56299',
    },
    {
        id: '8693',
        ort: 'Ochtersum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26489',
    },
    {
        id: '8694',
        ort: 'Ochtrup',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '48607',
    },
    {
        id: '8695',
        ort: 'Ockenfels',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53545',
    },
    {
        id: '8696',
        ort: 'Ockenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55437',
    },
    {
        id: '8697',
        ort: 'Ockfen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54441',
    },
    {
        id: '8698',
        ort: 'Ockholm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25842',
    },
    {
        id: '8699',
        ort: 'Odderade',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25785',
    },
    {
        id: '8700',
        ort: 'Odelzhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '85235',
    },
    {
        id: '8701',
        ort: 'Odenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67748',
    },
    {
        id: '8702',
        ort: 'Odenthal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rheinisch-Bergischer Kreis',
        plz: '51519',
    },
    {
        id: '8703',
        ort: 'Oderaue',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '16259',
    },
    {
        id: '8704',
        ort: 'Oderberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16248',
    },
    {
        id: '8705',
        ort: 'Odernheim am Glan',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55571',
    },
    {
        id: '8706',
        ort: 'Oderwitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02791',
    },
    {
        id: '8707',
        ort: 'Odisheim',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21775',
    },
    {
        id: '8708',
        ort: 'Oebisfelde-Weferlingen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39343',
    },
    {
        id: '8709',
        ort: 'Oebisfelde-Weferlingen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39356',
    },
    {
        id: '8710',
        ort: 'Oebisfelde-Weferlingen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39359',
    },
    {
        id: '8711',
        ort: 'Oebisfelde-Weferlingen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39646',
    },
    {
        id: '8712',
        ort: 'Oechsen',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '36404',
    },
    {
        id: '8713',
        ort: 'Oederan',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09569',
    },
    {
        id: '8714',
        ort: 'Oederquart',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21734',
    },
    {
        id: '8715',
        ort: 'Oedheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74229',
    },
    {
        id: '8716',
        ort: 'Oelde',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Warendorf',
        plz: '59302',
    },
    {
        id: '8717',
        ort: 'Oelixdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25524',
    },
    {
        id: '8718',
        ort: 'Oelsberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '8719',
        ort: 'Oelsnitz/Erzgeb.',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09376',
    },
    {
        id: '8720',
        ort: 'Oelsnitz/Vogtland',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08606',
    },
    {
        id: '8721',
        ort: 'Oerel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27432',
    },
    {
        id: '8722',
        ort: 'Oer-Erkenschwick',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '45739',
    },
    {
        id: '8723',
        ort: 'Oering',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23845',
    },
    {
        id: '8724',
        ort: 'Oerlenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97714',
    },
    {
        id: '8725',
        ort: 'Oerlinghausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '33813',
    },
    {
        id: '8726',
        ort: 'Oersberg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24407',
    },
    {
        id: '8727',
        ort: 'Oersdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24568',
    },
    {
        id: '8728',
        ort: 'Oeschebüttel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25548',
    },
    {
        id: '8729',
        ort: 'Oesterdeichstrich',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25761',
    },
    {
        id: '8730',
        ort: 'Oesterwurth',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25764',
    },
    {
        id: '8731',
        ort: 'Oestrich-Winkel',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65375',
    },
    {
        id: '8732',
        ort: 'Oettern',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99438',
    },
    {
        id: '8733',
        ort: 'Oettersdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07907',
    },
    {
        id: '8734',
        ort: 'Oettingen i.Bay.',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86732',
    },
    {
        id: '8735',
        ort: 'Oetzen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29588',
    },
    {
        id: '8736',
        ort: 'Oevenum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25938',
    },
    {
        id: '8737',
        ort: 'Oeversee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24988',
    },
    {
        id: '8738',
        ort: 'Offenau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74254',
    },
    {
        id: '8739',
        ort: 'Offenbach am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '63065',
    },
    {
        id: '8740',
        ort: 'Offenbach am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '63067',
    },
    {
        id: '8741',
        ort: 'Offenbach am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '63069',
    },
    {
        id: '8742',
        ort: 'Offenbach am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '63071',
    },
    {
        id: '8743',
        ort: 'Offenbach am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '63073',
    },
    {
        id: '8744',
        ort: 'Offenbach am Main',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '63075',
    },
    {
        id: '8745',
        ort: 'Offenbach an der Queich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76877',
    },
    {
        id: '8746',
        ort: 'Offenbach-Hundheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67749',
    },
    {
        id: '8747',
        ort: 'Offenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94560',
    },
    {
        id: '8748',
        ort: 'Offenburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77652',
    },
    {
        id: '8749',
        ort: 'Offenburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77654',
    },
    {
        id: '8750',
        ort: 'Offenburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77656',
    },
    {
        id: '8751',
        ort: 'Offenbüttel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25767',
    },
    {
        id: '8752',
        ort: 'Offenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91238',
    },
    {
        id: '8753',
        ort: 'Offenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '8754',
        ort: 'Offingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89362',
    },
    {
        id: '8755',
        ort: 'Offstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67591',
    },
    {
        id: '8756',
        ort: 'Ofterdingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72131',
    },
    {
        id: '8757',
        ort: 'Ofterschwang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87527',
    },
    {
        id: '8758',
        ort: 'Oftersheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '68723',
    },
    {
        id: '8759',
        ort: 'Oggelshausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88422',
    },
    {
        id: '8760',
        ort: 'Ohlenhard',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53520',
    },
    {
        id: '8761',
        ort: 'Ohlsbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77797',
    },
    {
        id: '8762',
        ort: 'Ohlstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82441',
    },
    {
        id: '8763',
        ort: 'Ohlweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55469',
    },
    {
        id: '8764',
        ort: 'Ohmbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66903',
    },
    {
        id: '8765',
        ort: 'Ohmden',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73275',
    },
    {
        id: '8766',
        ort: 'Ohne',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '48465',
    },
    {
        id: '8767',
        ort: 'Öhningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78337',
    },
    {
        id: '8768',
        ort: 'Ohorn',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01896',
    },
    {
        id: '8769',
        ort: 'Ohrdruf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99885',
    },
    {
        id: '8770',
        ort: 'Ohrenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91620',
    },
    {
        id: '8771',
        ort: 'Öhringen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Hohenlohekreis',
        plz: '74613',
    },
    {
        id: '8772',
        ort: 'Ohrum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38312',
    },
    {
        id: '8773',
        ort: 'Olbernhau',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09526',
    },
    {
        id: '8774',
        ort: 'Olbersdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02785',
    },
    {
        id: '8775',
        ort: 'Ölbronn-Dürrn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75248',
    },
    {
        id: '8776',
        ort: 'Olching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82140',
    },
    {
        id: '8777',
        ort: 'Oldenborstel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25560',
    },
    {
        id: '8778',
        ort: 'Oldenburg',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '26121',
    },
    {
        id: '8779',
        ort: 'Oldenburg',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '26122',
    },
    {
        id: '8780',
        ort: 'Oldenburg',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '26123',
    },
    {
        id: '8781',
        ort: 'Oldenburg',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '26125',
    },
    {
        id: '8782',
        ort: 'Oldenburg',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '26127',
    },
    {
        id: '8783',
        ort: 'Oldenburg',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '26129',
    },
    {
        id: '8784',
        ort: 'Oldenburg',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '26131',
    },
    {
        id: '8785',
        ort: 'Oldenburg',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '26133',
    },
    {
        id: '8786',
        ort: 'Oldenburg',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '26135',
    },
    {
        id: '8787',
        ort: 'Oldenburg in Holstein',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23758',
    },
    {
        id: '8788',
        ort: 'Oldenbüttel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '25557',
    },
    {
        id: '8789',
        ort: 'Oldendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21726',
    },
    {
        id: '8790',
        ort: 'Oldendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25588',
    },
    {
        id: '8791',
        ort: 'Oldendorf (Luhe)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21385',
    },
    {
        id: '8792',
        ort: 'Oldenhütten',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24793',
    },
    {
        id: '8793',
        ort: 'Oldenswort',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25870',
    },
    {
        id: '8794',
        ort: 'Oldersbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25873',
    },
    {
        id: '8795',
        ort: 'Olderup',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25860',
    },
    {
        id: '8796',
        ort: 'Oldsum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25938',
    },
    {
        id: '8797',
        ort: 'Olfen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Coesfeld',
        plz: '59399',
    },
    {
        id: '8798',
        ort: 'Ollendorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99198',
    },
    {
        id: '8799',
        ort: 'Öllingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89129',
    },
    {
        id: '8800',
        ort: 'Ollmuth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54316',
    },
    {
        id: '8801',
        ort: 'Olmscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54689',
    },
    {
        id: '8802',
        ort: 'Olpe',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Olpe',
        plz: '57462',
    },
    {
        id: '8803',
        ort: 'Olsberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Hochsauerlandkreis',
        plz: '59939',
    },
    {
        id: '8804',
        ort: 'Olsbrücken',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67737',
    },
    {
        id: '8805',
        ort: 'Olsdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54646',
    },
    {
        id: '8806',
        ort: 'Ölsen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57612',
    },
    {
        id: '8807',
        ort: 'Olzheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '8808',
        ort: 'Onsdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54456',
    },
    {
        id: '8809',
        ort: 'Opfenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88145',
    },
    {
        id: '8810',
        ort: 'Öpfingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89614',
    },
    {
        id: '8811',
        ort: 'Oppach',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02736',
    },
    {
        id: '8812',
        ort: 'Oppenau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77728',
    },
    {
        id: '8813',
        ort: 'Oppenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55276',
    },
    {
        id: '8814',
        ort: 'Oppenweiler',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71560',
    },
    {
        id: '8815',
        ort: 'Oppenweiler',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71570',
    },
    {
        id: '8816',
        ort: 'Oppershausen',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99986',
    },
    {
        id: '8817',
        ort: 'Oppertshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55469',
    },
    {
        id: '8818',
        ort: 'Oppurg',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07381',
    },
    {
        id: '8819',
        ort: 'Oranienbaum-Wörlitz',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Wittenberg',
        plz: '06785',
    },
    {
        id: '8820',
        ort: 'Oranienburg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16515',
    },
    {
        id: '8821',
        ort: 'Orbis',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67294',
    },
    {
        id: '8822',
        ort: 'Orenhofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54298',
    },
    {
        id: '8823',
        ort: 'Orfgen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57632',
    },
    {
        id: '8824',
        ort: 'Orlamünde',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07768',
    },
    {
        id: '8825',
        ort: 'Orlenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54595',
    },
    {
        id: '8826',
        ort: 'Ormont',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54597',
    },
    {
        id: '8827',
        ort: 'Ornbau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91737',
    },
    {
        id: '8828',
        ort: 'Orsfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54655',
    },
    {
        id: '8829',
        ort: 'Orsingen-Nenzingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78359',
    },
    {
        id: '8830',
        ort: 'Ortenberg',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '63683',
    },
    {
        id: '8831',
        ort: 'Ortenberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77799',
    },
    {
        id: '8832',
        ort: 'Ortenburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94496',
    },
    {
        id: '8833',
        ort: 'Ortrand',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01990',
    },
    {
        id: '8834',
        ort: 'Osann-Monzel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '8835',
        ort: 'Osburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54317',
    },
    {
        id: '8836',
        ort: 'Oschatz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04758',
    },
    {
        id: '8837',
        ort: 'Oschersleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39387',
    },
    {
        id: '8838',
        ort: 'Osdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24251',
    },
    {
        id: '8839',
        ort: 'Osloß',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38557',
    },
    {
        id: '8840',
        ort: 'Osnabrück',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '49074',
    },
    {
        id: '8841',
        ort: 'Osnabrück',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '49076',
    },
    {
        id: '8842',
        ort: 'Osnabrück',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '49078',
    },
    {
        id: '8843',
        ort: 'Osnabrück',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '49080',
    },
    {
        id: '8844',
        ort: 'Osnabrück',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '49082',
    },
    {
        id: '8845',
        ort: 'Osnabrück',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '49084',
    },
    {
        id: '8846',
        ort: 'Osnabrück',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '49086',
    },
    {
        id: '8847',
        ort: 'Osnabrück',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '49088',
    },
    {
        id: '8848',
        ort: 'Osnabrück',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '49090',
    },
    {
        id: '8849',
        ort: 'Oßling - Wóslink',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01920',
    },
    {
        id: '8850',
        ort: 'Ostbevern',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Warendorf',
        plz: '48346',
    },
    {
        id: '8851',
        ort: 'Osteel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26529',
    },
    {
        id: '8852',
        ort: 'Ostelsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '75395',
    },
    {
        id: '8853',
        ort: 'Osten',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21756',
    },
    {
        id: '8854',
        ort: 'Ostenfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24790',
    },
    {
        id: '8855',
        ort: 'Ostenfeld (Husum)',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25872',
    },
    {
        id: '8856',
        ort: 'Osterberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89296',
    },
    {
        id: '8857',
        ort: 'Osterbruch',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21762',
    },
    {
        id: '8858',
        ort: 'Osterburg (Altmark)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39606',
    },
    {
        id: '8859',
        ort: 'Osterburken',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74706',
    },
    {
        id: '8860',
        ort: 'Osterby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24367',
    },
    {
        id: '8861',
        ort: 'Osterby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24994',
    },
    {
        id: '8862',
        ort: 'Ostercappeln',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49179',
    },
    {
        id: '8863',
        ort: 'Ostereistedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27404',
    },
    {
        id: '8864',
        ort: 'Osterfeld',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06721',
    },
    {
        id: '8865',
        ort: 'Osterheide',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29664',
    },
    {
        id: '8866',
        ort: 'Osterheide',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29683',
    },
    {
        id: '8867',
        ort: 'Osterhever',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25836',
    },
    {
        id: '8868',
        ort: 'Osterhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94486',
    },
    {
        id: '8869',
        ort: 'Osterholz-Scharmbeck',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osterholz',
        plz: '27711',
    },
    {
        id: '8870',
        ort: 'Osterhorn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25364',
    },
    {
        id: '8871',
        ort: 'Osternienburger Land',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06386',
    },
    {
        id: '8872',
        ort: 'Osterode am Harz',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37520',
    },
    {
        id: '8873',
        ort: 'Oster-Ohrstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25885',
    },
    {
        id: '8874',
        ort: 'Osterrade',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25767',
    },
    {
        id: '8875',
        ort: 'Osterrönfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24783',
    },
    {
        id: '8876',
        ort: 'Osterspai',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56340',
    },
    {
        id: '8877',
        ort: 'Osterstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '25590',
    },
    {
        id: '8878',
        ort: 'Osterwald',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '49828',
    },
    {
        id: '8879',
        ort: 'Osterwieck',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38835',
    },
    {
        id: '8880',
        ort: 'Osterwieck',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38836',
    },
    {
        id: '8881',
        ort: 'Osterzell',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87662',
    },
    {
        id: '8882',
        ort: 'Ostfildern',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73760',
    },
    {
        id: '8883',
        ort: 'Osthausen-Wülfershausen',
        bundesland: 'Thüringen',
        landkreis: 'Ilm-Kreis',
        plz: '99310',
    },
    {
        id: '8884',
        ort: 'Ostheim v.d.Rhön',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97645',
    },
    {
        id: '8885',
        ort: 'Osthofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67574',
    },
    {
        id: '8886',
        ort: 'Ostrach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '88356',
    },
    {
        id: '8887',
        ort: 'Ostramondra',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99636',
    },
    {
        id: '8888',
        ort: 'Ostrhauderfehn',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26842',
    },
    {
        id: '8889',
        ort: 'Östringen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76684',
    },
    {
        id: '8890',
        ort: 'Ostritz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02899',
    },
    {
        id: '8891',
        ort: 'Ostrohe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25746',
    },
    {
        id: '8892',
        ort: 'Oststeinbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22113',
    },
    {
        id: '8893',
        ort: 'Ötigheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '76470',
    },
    {
        id: '8894',
        ort: 'Ötisheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75443',
    },
    {
        id: '8895',
        ort: 'Ottenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73113',
    },
    {
        id: '8896',
        ort: 'Ottenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73529',
    },
    {
        id: '8897',
        ort: 'Ottenbüttel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25591',
    },
    {
        id: '8898',
        ort: 'Ottendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24107',
    },
    {
        id: '8899',
        ort: 'Ottendorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '8900',
        ort: 'Ottendorf-Okrilla',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01458',
    },
    {
        id: '8901',
        ort: 'Ottenhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '85570',
    },
    {
        id: '8902',
        ort: 'Ottenhöfen im Schwarzwald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77883',
    },
    {
        id: '8903',
        ort: 'Ottensoos',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91242',
    },
    {
        id: '8904',
        ort: 'Ottenstein',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '31868',
    },
    {
        id: '8905',
        ort: 'Otter',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21259',
    },
    {
        id: '8906',
        ort: 'Otterbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67731',
    },
    {
        id: '8907',
        ort: 'Otterberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67697',
    },
    {
        id: '8908',
        ort: 'Otterfing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miesbach',
        plz: '83624',
    },
    {
        id: '8909',
        ort: 'Otterndorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21762',
    },
    {
        id: '8910',
        ort: 'Ottersberg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Verden',
        plz: '28870',
    },
    {
        id: '8911',
        ort: 'Ottersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67308',
    },
    {
        id: '8912',
        ort: 'Ottersheim bei Landau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76879',
    },
    {
        id: '8913',
        ort: 'Otterstadt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67166',
    },
    {
        id: '8914',
        ort: 'Ottersweier',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '77833',
    },
    {
        id: '8915',
        ort: 'Otterwisch',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04668',
    },
    {
        id: '8916',
        ort: 'Otting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86700',
    },
    {
        id: '8917',
        ort: 'Ottobeuren',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87724',
    },
    {
        id: '8918',
        ort: 'Ottobrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '85521',
    },
    {
        id: '8919',
        ort: 'Ottrau',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34633',
    },
    {
        id: '8920',
        ort: 'Ottweiler',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Neunkirchen',
        plz: '66564',
    },
    {
        id: '8921',
        ort: 'Otzberg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64853',
    },
    {
        id: '8922',
        ort: 'Otzing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94563',
    },
    {
        id: '8923',
        ort: 'Ötzingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '8924',
        ort: 'Otzweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55606',
    },
    {
        id: '8925',
        ort: 'Ovelgönne',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wesermarsch',
        plz: '26939',
    },
    {
        id: '8926',
        ort: 'Overath',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rheinisch-Bergischer Kreis',
        plz: '51491',
    },
    {
        id: '8927',
        ort: 'Owen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73277',
    },
    {
        id: '8928',
        ort: 'Owingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88696',
    },
    {
        id: '8929',
        ort: 'Owschlag',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24811',
    },
    {
        id: '8930',
        ort: 'Owschlag',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24848',
    },
    {
        id: '8931',
        ort: 'Oybin',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02797',
    },
    {
        id: '8932',
        ort: 'Oy-Mittelberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87466',
    },
    {
        id: '8933',
        ort: 'Oyten',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Verden',
        plz: '28876',
    },
    {
        id: '8934',
        ort: 'Padenstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24634',
    },
    {
        id: '8935',
        ort: 'Paderborn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Paderborn',
        plz: '33098',
    },
    {
        id: '8936',
        ort: 'Paderborn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Paderborn',
        plz: '33100',
    },
    {
        id: '8937',
        ort: 'Paderborn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Paderborn',
        plz: '33102',
    },
    {
        id: '8938',
        ort: 'Paderborn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Paderborn',
        plz: '33104',
    },
    {
        id: '8939',
        ort: 'Paderborn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Paderborn',
        plz: '33106',
    },
    {
        id: '8940',
        ort: 'Paderborn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Paderborn',
        plz: '33154',
    },
    {
        id: '8941',
        ort: 'Paderborn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Paderborn',
        plz: '33161',
    },
    {
        id: '8942',
        ort: 'Pähl',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82396',
    },
    {
        id: '8943',
        ort: 'Pahlen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25794',
    },
    {
        id: '8944',
        ort: 'Painten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '93351',
    },
    {
        id: '8945',
        ort: 'Paitzdorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07580',
    },
    {
        id: '8946',
        ort: 'Palling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83349',
    },
    {
        id: '8947',
        ort: 'Palzem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54439',
    },
    {
        id: '8948',
        ort: 'Pampow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19075',
    },
    {
        id: '8949',
        ort: 'Panker',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24321',
    },
    {
        id: '8950',
        ort: 'Panketal',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16341',
    },
    {
        id: '8951',
        ort: 'Panschwitz-Kuckau - Pančicy-Kukow',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01920',
    },
    {
        id: '8952',
        ort: 'Pantelitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18442',
    },
    {
        id: '8953',
        ort: 'Panten',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23896',
    },
    {
        id: '8954',
        ort: 'Pantenburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54531',
    },
    {
        id: '8955',
        ort: 'Panzweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56865',
    },
    {
        id: '8956',
        ort: 'Papenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26871',
    },
    {
        id: '8957',
        ort: 'Papendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18059',
    },
    {
        id: '8958',
        ort: 'Papendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17309',
    },
    {
        id: '8959',
        ort: 'Papenhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18510',
    },
    {
        id: '8960',
        ort: 'Pappenheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91788',
    },
    {
        id: '8961',
        ort: 'Parchim',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19370',
    },
    {
        id: '8962',
        ort: 'Parchim',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19374',
    },
    {
        id: '8963',
        ort: 'Parchtitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18528',
    },
    {
        id: '8964',
        ort: 'Parkstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92711',
    },
    {
        id: '8965',
        ort: 'Parkstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94365',
    },
    {
        id: '8966',
        ort: 'Parsau',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38470',
    },
    {
        id: '8967',
        ort: 'Parsberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92331',
    },
    {
        id: '8968',
        ort: 'Parsteinsee',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16248',
    },
    {
        id: '8969',
        ort: 'Partenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55288',
    },
    {
        id: '8970',
        ort: 'Partenstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97846',
    },
    {
        id: '8971',
        ort: 'Parthenstein',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04668',
    },
    {
        id: '8972',
        ort: 'Paschel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54314',
    },
    {
        id: '8973',
        ort: 'Pasewalk',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17309',
    },
    {
        id: '8974',
        ort: 'Paska',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07381',
    },
    {
        id: '8975',
        ort: 'Passade',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24253',
    },
    {
        id: '8976',
        ort: 'Passau',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '94032',
    },
    {
        id: '8977',
        ort: 'Passau',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '94034',
    },
    {
        id: '8978',
        ort: 'Passau',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '94036',
    },
    {
        id: '8979',
        ort: 'Passee',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23992',
    },
    {
        id: '8980',
        ort: 'Passow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19386',
    },
    {
        id: '8981',
        ort: 'Pastetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '85669',
    },
    {
        id: '8982',
        ort: 'Patersberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56348',
    },
    {
        id: '8983',
        ort: 'Patersdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94265',
    },
    {
        id: '8984',
        ort: 'Pätow-Steegen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '8985',
        ort: 'Pattensen',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30982',
    },
    {
        id: '8986',
        ort: 'Patzig',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18528',
    },
    {
        id: '8987',
        ort: 'Paulinenaue',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14641',
    },
    {
        id: '8988',
        ort: 'Paunzhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85307',
    },
    {
        id: '8989',
        ort: 'Pausa-Mühltroff',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '07919',
    },
    {
        id: '8990',
        ort: 'Pausa-Mühltroff',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '07952',
    },
    {
        id: '8991',
        ort: 'Pausa-Mühltroff',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08539',
    },
    {
        id: '8992',
        ort: 'Päwesin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14778',
    },
    {
        id: '8993',
        ort: 'Pechbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95701',
    },
    {
        id: '8994',
        ort: 'Peenehagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17192',
    },
    {
        id: '8995',
        ort: 'Peenehagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17194',
    },
    {
        id: '8996',
        ort: 'Peenemünde',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17449',
    },
    {
        id: '8997',
        ort: 'Peffingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54668',
    },
    {
        id: '8998',
        ort: 'Pegau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04523',
    },
    {
        id: '8999',
        ort: 'Pegestorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37619',
    },
    {
        id: '9000',
        ort: 'Pegnitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '91257',
    },
    {
        id: '9001',
        ort: 'Peine',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Peine',
        plz: '31224',
    },
    {
        id: '9002',
        ort: 'Peine',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Peine',
        plz: '31226',
    },
    {
        id: '9003',
        ort: 'Peine',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Peine',
        plz: '31228',
    },
    {
        id: '9004',
        ort: 'Peissen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25551',
    },
    {
        id: '9005',
        ort: 'Peißenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82380',
    },
    {
        id: '9006',
        ort: 'Peiting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '86971',
    },
    {
        id: '9007',
        ort: 'Peitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03185',
    },
    {
        id: '9008',
        ort: 'Pellingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54331',
    },
    {
        id: '9009',
        ort: 'Pellworm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25845',
    },
    {
        id: '9010',
        ort: 'Pellworm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25849',
    },
    {
        id: '9011',
        ort: 'Pelm',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '9012',
        ort: 'Pemfling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93482',
    },
    {
        id: '9013',
        ort: 'Penig',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09322',
    },
    {
        id: '9014',
        ort: 'Penkow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17213',
    },
    {
        id: '9015',
        ort: 'Penkun',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17328',
    },
    {
        id: '9016',
        ort: 'Pennigsehl',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31621',
    },
    {
        id: '9017',
        ort: 'Pentling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93080',
    },
    {
        id: '9018',
        ort: 'Penzberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82377',
    },
    {
        id: '9019',
        ort: 'Penzin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18249',
    },
    {
        id: '9020',
        ort: 'Penzing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86929',
    },
    {
        id: '9021',
        ort: 'Penzlin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17217',
    },
    {
        id: '9022',
        ort: 'Penzlin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17219',
    },
    {
        id: '9023',
        ort: 'Perach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84567',
    },
    {
        id: '9024',
        ort: 'Perasdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94366',
    },
    {
        id: '9025',
        ort: 'Perkam',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94368',
    },
    {
        id: '9026',
        ort: 'Perl',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Merzig-Wadern',
        plz: '66706',
    },
    {
        id: '9027',
        ort: 'Perleberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '19348',
    },
    {
        id: '9028',
        ort: 'Perlesreut',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94157',
    },
    {
        id: '9029',
        ort: 'Perlin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19209',
    },
    {
        id: '9030',
        ort: 'Perscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55430',
    },
    {
        id: '9031',
        ort: 'Pessin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14641',
    },
    {
        id: '9032',
        ort: 'Petersaurach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91580',
    },
    {
        id: '9033',
        ort: 'Petersberg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36100',
    },
    {
        id: '9034',
        ort: 'Petersberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66989',
    },
    {
        id: '9035',
        ort: 'Petersberg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06193',
    },
    {
        id: '9036',
        ort: 'Petersberg',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07616',
    },
    {
        id: '9037',
        ort: 'Petersdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86574',
    },
    {
        id: '9038',
        ort: 'Petershagen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Minden-Lübbecke',
        plz: '32469',
    },
    {
        id: '9039',
        ort: 'Petershagen/Eggersdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15345',
    },
    {
        id: '9040',
        ort: 'Petershagen/Eggersdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15370',
    },
    {
        id: '9041',
        ort: 'Petershausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '85238',
    },
    {
        id: '9042',
        ort: 'Peterslahr',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57632',
    },
    {
        id: '9043',
        ort: 'Peterswald-Löffelscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56858',
    },
    {
        id: '9044',
        ort: 'Pettendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93186',
    },
    {
        id: '9045',
        ort: 'Petting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83367',
    },
    {
        id: '9046',
        ort: 'Pettstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96175',
    },
    {
        id: '9047',
        ort: 'Peuschen',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07389',
    },
    {
        id: '9048',
        ort: 'Pfaffenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87772',
    },
    {
        id: '9049',
        ort: 'Pfaffenhofen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74397',
    },
    {
        id: '9050',
        ort: 'Pfaffenhofen an der Glonn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '85235',
    },
    {
        id: '9051',
        ort: 'Pfaffenhofen an der Ilm',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85276',
    },
    {
        id: '9052',
        ort: 'Pfaffenhofen an der Roth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89284',
    },
    {
        id: '9053',
        ort: 'Pfaffen-Schwabenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55546',
    },
    {
        id: '9054',
        ort: 'Pfaffenweiler',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79292',
    },
    {
        id: '9055',
        ort: 'Pfaffing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83539',
    },
    {
        id: '9056',
        ort: 'Pfaffschwende',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37308',
    },
    {
        id: '9057',
        ort: 'Pfakofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93101',
    },
    {
        id: '9058',
        ort: 'Pfalzfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56291',
    },
    {
        id: '9059',
        ort: 'Pfalzgrafenweiler',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Freudenstadt',
        plz: '72285',
    },
    {
        id: '9060',
        ort: 'Pfarrkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84347',
    },
    {
        id: '9061',
        ort: 'Pfarrweisach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '96176',
    },
    {
        id: '9062',
        ort: 'Pfatter',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93102',
    },
    {
        id: '9063',
        ort: 'Pfedelbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Hohenlohekreis',
        plz: '74629',
    },
    {
        id: '9064',
        ort: 'Pfeffelbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66871',
    },
    {
        id: '9065',
        ort: 'Pfeffenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84076',
    },
    {
        id: '9066',
        ort: 'Pferdingsleben',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99869',
    },
    {
        id: '9067',
        ort: 'Pfinztal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76327',
    },
    {
        id: '9068',
        ort: 'Pfofeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91738',
    },
    {
        id: '9069',
        ort: 'Pförring',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85104',
    },
    {
        id: '9070',
        ort: 'Pforzen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87666',
    },
    {
        id: '9071',
        ort: 'Pforzheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '75172',
    },
    {
        id: '9072',
        ort: 'Pforzheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '75173',
    },
    {
        id: '9073',
        ort: 'Pforzheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '75175',
    },
    {
        id: '9074',
        ort: 'Pforzheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '75177',
    },
    {
        id: '9075',
        ort: 'Pforzheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '75179',
    },
    {
        id: '9076',
        ort: 'Pforzheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '75180',
    },
    {
        id: '9077',
        ort: 'Pforzheim',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '75181',
    },
    {
        id: '9078',
        ort: 'Pfreimd',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92536',
    },
    {
        id: '9079',
        ort: 'Pfronstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72539',
    },
    {
        id: '9080',
        ort: 'Pfronten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87459',
    },
    {
        id: '9081',
        ort: 'Pfullendorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '88630',
    },
    {
        id: '9082',
        ort: 'Pfullingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72793',
    },
    {
        id: '9083',
        ort: 'Pfungstadt',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64319',
    },
    {
        id: '9084',
        ort: 'Philippsburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76661',
    },
    {
        id: '9085',
        ort: 'Philippsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54662',
    },
    {
        id: '9086',
        ort: 'Philippsreut',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94158',
    },
    {
        id: '9087',
        ort: 'Philippsthal (Werra)',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36269',
    },
    {
        id: '9088',
        ort: 'Picher',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '9089',
        ort: 'Pickließem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54647',
    },
    {
        id: '9090',
        ort: 'Piding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Berchtesgadener Land',
        plz: '83451',
    },
    {
        id: '9091',
        ort: 'Pielenhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93188',
    },
    {
        id: '9092',
        ort: 'Piesport',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54498',
    },
    {
        id: '9093',
        ort: 'Pillig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56753',
    },
    {
        id: '9094',
        ort: 'Pilsach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92367',
    },
    {
        id: '9095',
        ort: 'Pilsting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dingolfing-Landau',
        plz: '94431',
    },
    {
        id: '9096',
        ort: 'Pingelshagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19069',
    },
    {
        id: '9097',
        ort: 'Pinneberg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25421',
    },
    {
        id: '9098',
        ort: 'Pinnow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19065',
    },
    {
        id: '9099',
        ort: 'Pinnow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '16278',
    },
    {
        id: '9100',
        ort: 'Pintesfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54649',
    },
    {
        id: '9101',
        ort: 'Pinzberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91361',
    },
    {
        id: '9102',
        ort: 'Pirk',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92712',
    },
    {
        id: '9103',
        ort: 'Pirmasens',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '66953',
    },
    {
        id: '9104',
        ort: 'Pirmasens',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '66954',
    },
    {
        id: '9105',
        ort: 'Pirmasens',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '66955',
    },
    {
        id: '9106',
        ort: 'Pirna',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01796',
    },
    {
        id: '9107',
        ort: 'Pirow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '19348',
    },
    {
        id: '9108',
        ort: 'Pittenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54595',
    },
    {
        id: '9109',
        ort: 'Pittenhart',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83132',
    },
    {
        id: '9110',
        ort: 'Plaaz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18276',
    },
    {
        id: '9111',
        ort: 'Plaidt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56637',
    },
    {
        id: '9112',
        ort: 'Planebruch',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14822',
    },
    {
        id: '9113',
        ort: 'Planegg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '82152',
    },
    {
        id: '9114',
        ort: 'Planetal',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14806',
    },
    {
        id: '9115',
        ort: 'Plankenfels',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95515',
    },
    {
        id: '9116',
        ort: 'Plankstadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '68723',
    },
    {
        id: '9117',
        ort: 'Plascheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '9118',
        ort: 'Plate',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19086',
    },
    {
        id: '9119',
        ort: 'Platten',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '9120',
        ort: 'Plattenburg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '19339',
    },
    {
        id: '9121',
        ort: 'Plattling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94447',
    },
    {
        id: '9122',
        ort: 'Plau am See',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19395',
    },
    {
        id: '9123',
        ort: 'Plaue',
        bundesland: 'Thüringen',
        landkreis: 'Ilm-Kreis',
        plz: '99338',
    },
    {
        id: '9124',
        ort: 'Plauen',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08523',
    },
    {
        id: '9125',
        ort: 'Plauen',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08525',
    },
    {
        id: '9126',
        ort: 'Plauen',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08527',
    },
    {
        id: '9127',
        ort: 'Plauen',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08529',
    },
    {
        id: '9128',
        ort: 'Plauen',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08541',
    },
    {
        id: '9129',
        ort: 'Plauen',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08547',
    },
    {
        id: '9130',
        ort: 'Plech',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '91287',
    },
    {
        id: '9131',
        ort: 'Pleckhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '56593',
    },
    {
        id: '9132',
        ort: 'Pleidelsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '74385',
    },
    {
        id: '9133',
        ort: 'Plein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '9134',
        ort: 'Pleinfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91785',
    },
    {
        id: '9135',
        ort: 'Pleiskirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84568',
    },
    {
        id: '9136',
        ort: 'Pleisweiler-Oberhofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76889',
    },
    {
        id: '9137',
        ort: 'Pleitersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55576',
    },
    {
        id: '9138',
        ort: 'Pleizenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55469',
    },
    {
        id: '9139',
        ort: 'Pleß',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87773',
    },
    {
        id: '9140',
        ort: 'Plessa',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04928',
    },
    {
        id: '9141',
        ort: 'Plettenberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58840',
    },
    {
        id: '9142',
        ort: 'Pleystein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92714',
    },
    {
        id: '9143',
        ort: 'Pliening',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85652',
    },
    {
        id: '9144',
        ort: 'Pliezhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72124',
    },
    {
        id: '9145',
        ort: 'Plochingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73207',
    },
    {
        id: '9146',
        ort: 'Plön',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24306',
    },
    {
        id: '9147',
        ort: 'Plößberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95703',
    },
    {
        id: '9148',
        ort: 'Plothen',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07907',
    },
    {
        id: '9149',
        ort: 'Plötzkau',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '06425',
    },
    {
        id: '9150',
        ort: 'Plöwen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17321',
    },
    {
        id: '9151',
        ort: 'Plüderhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '73642',
    },
    {
        id: '9152',
        ort: 'Plüderhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '73655',
    },
    {
        id: '9153',
        ort: 'Plütscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '9154',
        ort: 'Pluwig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54316',
    },
    {
        id: '9155',
        ort: 'Pockau-Lengefeld',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09509',
    },
    {
        id: '9156',
        ort: 'Pockau-Lengefeld',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09514',
    },
    {
        id: '9157',
        ort: 'Pocking',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94060',
    },
    {
        id: '9158',
        ort: 'Pöcking',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Starnberg',
        plz: '82343',
    },
    {
        id: '9159',
        ort: 'Podelzig',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15326',
    },
    {
        id: '9160',
        ort: 'Pogeez',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23911',
    },
    {
        id: '9161',
        ort: 'Poggensee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23896',
    },
    {
        id: '9162',
        ort: 'Pohl',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '9163',
        ort: 'Pöhl',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08543',
    },
    {
        id: '9164',
        ort: 'Pohle',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31867',
    },
    {
        id: '9165',
        ort: 'Pohlheim',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35415',
    },
    {
        id: '9166',
        ort: 'Pohnsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24211',
    },
    {
        id: '9167',
        ort: 'Poing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85586',
    },
    {
        id: '9168',
        ort: 'Pokrent',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19205',
    },
    {
        id: '9169',
        ort: 'Polch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56751',
    },
    {
        id: '9170',
        ort: 'Pölchow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18059',
    },
    {
        id: '9171',
        ort: 'Pölich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54340',
    },
    {
        id: '9172',
        ort: 'Pölitz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23847',
    },
    {
        id: '9173',
        ort: 'Polle',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37647',
    },
    {
        id: '9174',
        ort: 'Pollenfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85131',
    },
    {
        id: '9175',
        ort: 'Pollhagen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31718',
    },
    {
        id: '9176',
        ort: 'Polling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82398',
    },
    {
        id: '9177',
        ort: 'Polling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84570',
    },
    {
        id: '9178',
        ort: 'Polsingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91805',
    },
    {
        id: '9179',
        ort: 'Pölzig',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07554',
    },
    {
        id: '9180',
        ort: 'Polzow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17309',
    },
    {
        id: '9181',
        ort: 'Pommelsbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91224',
    },
    {
        id: '9182',
        ort: 'Pommerby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24395',
    },
    {
        id: '9183',
        ort: 'Pommern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56829',
    },
    {
        id: '9184',
        ort: 'Pommersfelden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96178',
    },
    {
        id: '9185',
        ort: 'Pomster',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53534',
    },
    {
        id: '9186',
        ort: 'Ponitz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04639',
    },
    {
        id: '9187',
        ort: 'Poppenbüll',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25836',
    },
    {
        id: '9188',
        ort: 'Poppendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18184',
    },
    {
        id: '9189',
        ort: 'Poppenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97490',
    },
    {
        id: '9190',
        ort: 'Poppenhausen (Wasserkuppe)',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36163',
    },
    {
        id: '9191',
        ort: 'Poppenricht',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92284',
    },
    {
        id: '9192',
        ort: 'Pörmitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07907',
    },
    {
        id: '9193',
        ort: 'Pörnbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85309',
    },
    {
        id: '9194',
        ort: 'Porta Westfalica',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Minden-Lübbecke',
        plz: '32457',
    },
    {
        id: '9195',
        ort: 'Pöschendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25560',
    },
    {
        id: '9196',
        ort: 'Poseritz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18574',
    },
    {
        id: '9197',
        ort: 'Pösing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93483',
    },
    {
        id: '9198',
        ort: 'Pößneck',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07381',
    },
    {
        id: '9199',
        ort: 'Postau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84103',
    },
    {
        id: '9200',
        ort: 'Postbauer-Heng',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92353',
    },
    {
        id: '9201',
        ort: 'Posterstein',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04626',
    },
    {
        id: '9202',
        ort: 'Postfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24211',
    },
    {
        id: '9203',
        ort: 'Postlow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17391',
    },
    {
        id: '9204',
        ort: 'Postmünster',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84389',
    },
    {
        id: '9205',
        ort: 'Potsdam',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '14467',
    },
    {
        id: '9206',
        ort: 'Potsdam',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '14469',
    },
    {
        id: '9207',
        ort: 'Potsdam',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '14471',
    },
    {
        id: '9208',
        ort: 'Potsdam',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '14473',
    },
    {
        id: '9209',
        ort: 'Potsdam',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '14476',
    },
    {
        id: '9210',
        ort: 'Potsdam',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '14478',
    },
    {
        id: '9211',
        ort: 'Potsdam',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '14480',
    },
    {
        id: '9212',
        ort: 'Potsdam',
        bundesland: 'Brandenburg',
        landkreis: '',
        plz: '14482',
    },
    {
        id: '9213',
        ort: 'Pottenstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '91278',
    },
    {
        id: '9214',
        ort: 'Pöttmes',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86554',
    },
    {
        id: '9215',
        ort: 'Pottum',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '9216',
        ort: 'Poxdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91099',
    },
    {
        id: '9217',
        ort: 'Poxdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07616',
    },
    {
        id: '9218',
        ort: 'Poyenberg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25581',
    },
    {
        id: '9219',
        ort: 'Pracht',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57589',
    },
    {
        id: '9220',
        ort: 'Prackenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94267',
    },
    {
        id: '9221',
        ort: 'Pragsdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17094',
    },
    {
        id: '9222',
        ort: 'Prasdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24253',
    },
    {
        id: '9223',
        ort: 'Prath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56346',
    },
    {
        id: '9224',
        ort: 'Prebberede',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17168',
    },
    {
        id: '9225',
        ort: 'Prebitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95473',
    },
    {
        id: '9226',
        ort: 'Preetz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24211',
    },
    {
        id: '9227',
        ort: 'Preetz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18445',
    },
    {
        id: '9228',
        ort: 'Preischeid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54689',
    },
    {
        id: '9229',
        ort: 'Preist',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54664',
    },
    {
        id: '9230',
        ort: 'Prem',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '86984',
    },
    {
        id: '9231',
        ort: 'Premnitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14727',
    },
    {
        id: '9232',
        ort: 'Prenzlau',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17291',
    },
    {
        id: '9233',
        ort: 'Prerow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18375',
    },
    {
        id: '9234',
        ort: 'Pressath',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92690',
    },
    {
        id: '9235',
        ort: 'Presseck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95355',
    },
    {
        id: '9236',
        ort: 'Pressig',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kronach',
        plz: '96332',
    },
    {
        id: '9237',
        ort: 'Pretzfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91362',
    },
    {
        id: '9238',
        ort: 'Preußisch Oldendorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Minden-Lübbecke',
        plz: '32361',
    },
    {
        id: '9239',
        ort: 'Prezelle',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29491',
    },
    {
        id: '9240',
        ort: 'Priborn',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17209',
    },
    {
        id: '9241',
        ort: 'Prichsenstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97357',
    },
    {
        id: '9242',
        ort: 'Prien am Chiemsee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83209',
    },
    {
        id: '9243',
        ort: 'Priepert',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17255',
    },
    {
        id: '9244',
        ort: 'Priesendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96170',
    },
    {
        id: '9245',
        ort: 'Priestewitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01561',
    },
    {
        id: '9246',
        ort: 'Prinzenmoor',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24805',
    },
    {
        id: '9247',
        ort: 'Prinzhöfte',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Oldenburg',
        plz: '27243',
    },
    {
        id: '9248',
        ort: 'Pripsleben',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17091',
    },
    {
        id: '9249',
        ort: 'Prisdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25497',
    },
    {
        id: '9250',
        ort: 'Prislich',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19300',
    },
    {
        id: '9251',
        ort: 'Prittriching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86931',
    },
    {
        id: '9252',
        ort: 'Pritzier',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '9253',
        ort: 'Pritzwalk',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '16928',
    },
    {
        id: '9254',
        ort: 'Probsteierhagen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24253',
    },
    {
        id: '9255',
        ort: 'Probstzella',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07330',
    },
    {
        id: '9256',
        ort: 'Prohn',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18445',
    },
    {
        id: '9257',
        ort: 'Pronsfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '9258',
        ort: 'Pronstorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23820',
    },
    {
        id: '9259',
        ort: 'Prosselsheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97279',
    },
    {
        id: '9260',
        ort: 'Prötzel',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15345',
    },
    {
        id: '9261',
        ort: 'Pruchten',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18356',
    },
    {
        id: '9262',
        ort: 'Prüm',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54595',
    },
    {
        id: '9263',
        ort: 'Prümzurlay',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54668',
    },
    {
        id: '9264',
        ort: 'Prutting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83134',
    },
    {
        id: '9265',
        ort: 'Püchersreuth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92715',
    },
    {
        id: '9266',
        ort: 'Puchheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82178',
    },
    {
        id: '9267',
        ort: 'Pudagla',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17429',
    },
    {
        id: '9268',
        ort: 'Puderbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56305',
    },
    {
        id: '9269',
        ort: 'Pulheim',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Erft-Kreis',
        plz: '50259',
    },
    {
        id: '9270',
        ort: 'Pullach im Isartal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '82049',
    },
    {
        id: '9271',
        ort: 'Pullenreuth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95704',
    },
    {
        id: '9272',
        ort: 'Puls',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25560',
    },
    {
        id: '9273',
        ort: 'Pulsnitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01896',
    },
    {
        id: '9274',
        ort: 'Pünderich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56862',
    },
    {
        id: '9275',
        ort: 'Pürgen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86932',
    },
    {
        id: '9276',
        ort: 'Puschendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürth',
        plz: '90617',
    },
    {
        id: '9277',
        ort: 'Puschwitz - Bóšicy',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02699',
    },
    {
        id: '9278',
        ort: 'Putbus',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18581',
    },
    {
        id: '9279',
        ort: 'Putgarten',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18556',
    },
    {
        id: '9280',
        ort: 'Putlitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '16949',
    },
    {
        id: '9281',
        ort: 'Püttlingen',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66346',
    },
    {
        id: '9282',
        ort: 'Putzbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '85640',
    },
    {
        id: '9283',
        ort: 'Pyrbaum',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '90602',
    },
    {
        id: '9284',
        ort: 'Quakenbrück',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49610',
    },
    {
        id: '9285',
        ort: 'Quarnbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24107',
    },
    {
        id: '9286',
        ort: 'Quarnstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25563',
    },
    {
        id: '9287',
        ort: 'Quaschwitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07389',
    },
    {
        id: '9288',
        ort: 'Quedlinburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '06484',
    },
    {
        id: '9289',
        ort: 'Quedlinburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '06485',
    },
    {
        id: '9290',
        ort: 'Queidersbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66851',
    },
    {
        id: '9291',
        ort: 'Quendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '48465',
    },
    {
        id: '9292',
        ort: 'Querenhorst',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38368',
    },
    {
        id: '9293',
        ort: 'Querfurt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06268',
    },
    {
        id: '9294',
        ort: 'Quernheim',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49448',
    },
    {
        id: '9295',
        ort: 'Quickborn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25451',
    },
    {
        id: '9296',
        ort: 'Quickborn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25712',
    },
    {
        id: '9297',
        ort: 'Quiddelbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53518',
    },
    {
        id: '9298',
        ort: 'Quierschied',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66287',
    },
    {
        id: '9299',
        ort: 'Quirnbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56242',
    },
    {
        id: '9300',
        ort: 'Quirnbach/Pfalz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66909',
    },
    {
        id: '9301',
        ort: 'Quirnheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67280',
    },
    {
        id: '9302',
        ort: 'Quitzdorf am See - Kwětanecy',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02906',
    },
    {
        id: '9303',
        ort: 'Raa-Besenbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25335',
    },
    {
        id: '9304',
        ort: 'Rabel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24376',
    },
    {
        id: '9305',
        ort: 'Rabenau',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35466',
    },
    {
        id: '9306',
        ort: 'Rabenau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01734',
    },
    {
        id: '9307',
        ort: 'Rabenholz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24395',
    },
    {
        id: '9308',
        ort: 'Rabenkirchen-Faulück',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24407',
    },
    {
        id: '9309',
        ort: 'Raben Steinfeld',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19065',
    },
    {
        id: '9310',
        ort: 'Rabenstein/Fläming',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14823',
    },
    {
        id: '9311',
        ort: 'Räbke',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38375',
    },
    {
        id: '9312',
        ort: 'Räckelwitz - Worklecy',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01920',
    },
    {
        id: '9313',
        ort: 'Racksen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57612',
    },
    {
        id: '9314',
        ort: 'Rackwitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04519',
    },
    {
        id: '9315',
        ort: 'Radbruch',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21449',
    },
    {
        id: '9316',
        ort: 'Raddestorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31604',
    },
    {
        id: '9317',
        ort: 'Rade',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25579',
    },
    {
        id: '9318',
        ort: 'Rade',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24790',
    },
    {
        id: '9319',
        ort: 'Rade',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24794',
    },
    {
        id: '9320',
        ort: 'Radeberg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01454',
    },
    {
        id: '9321',
        ort: 'Radebeul',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01445',
    },
    {
        id: '9322',
        ort: 'Rade b. Hohenwestedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24594',
    },
    {
        id: '9323',
        ort: 'Radeburg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01471',
    },
    {
        id: '9324',
        ort: 'Radevormwald',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Oberbergischer Kreis',
        plz: '42477',
    },
    {
        id: '9325',
        ort: 'Radibor - Radwor',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02627',
    },
    {
        id: '9326',
        ort: 'Radolfzell am Bodensee',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78315',
    },
    {
        id: '9327',
        ort: 'Raesfeld',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '46348',
    },
    {
        id: '9328',
        ort: 'Ragow-Merz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15848',
    },
    {
        id: '9329',
        ort: 'Raguhn-Jeßnitz',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06779',
    },
    {
        id: '9330',
        ort: 'Raguhn-Jeßnitz',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06800',
    },
    {
        id: '9331',
        ort: 'Rahden',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Minden-Lübbecke',
        plz: '32369',
    },
    {
        id: '9332',
        ort: 'Rain',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94369',
    },
    {
        id: '9333',
        ort: 'Rain',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86641',
    },
    {
        id: '9334',
        ort: 'Rainau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73492',
    },
    {
        id: '9335',
        ort: 'Raisting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82399',
    },
    {
        id: '9336',
        ort: 'Raitenbuch',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91790',
    },
    {
        id: '9337',
        ort: 'Ralbitz-Rosenthal - Ralbicy-Róžant',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01920',
    },
    {
        id: '9338',
        ort: 'Ralingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54310',
    },
    {
        id: '9339',
        ort: 'Ralswiek',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18528',
    },
    {
        id: '9340',
        ort: 'Ramberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76829',
    },
    {
        id: '9341',
        ort: 'Ramberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76857',
    },
    {
        id: '9342',
        ort: 'Rambin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18573',
    },
    {
        id: '9343',
        ort: 'Ramerberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83561',
    },
    {
        id: '9344',
        ort: 'Ramhusen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25715',
    },
    {
        id: '9345',
        ort: 'Ramin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17321',
    },
    {
        id: '9346',
        ort: 'Rammelsbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66887',
    },
    {
        id: '9347',
        ort: 'Rammenau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01877',
    },
    {
        id: '9348',
        ort: 'Rammingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89192',
    },
    {
        id: '9349',
        ort: 'Rammingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '86871',
    },
    {
        id: '9350',
        ort: 'Ramsau bei Berchtesgaden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Berchtesgadener Land',
        plz: '83486',
    },
    {
        id: '9351',
        ort: 'Ramsen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67305',
    },
    {
        id: '9352',
        ort: 'Ramstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25876',
    },
    {
        id: '9353',
        ort: 'Ramstein-Miesenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66877',
    },
    {
        id: '9354',
        ort: 'Ramsthal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97729',
    },
    {
        id: '9355',
        ort: 'Randersacker',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97236',
    },
    {
        id: '9356',
        ort: 'Randowtal',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17291',
    },
    {
        id: '9357',
        ort: 'Rangendingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72414',
    },
    {
        id: '9358',
        ort: 'Rangsdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Teltow-Fläming',
        plz: '15834',
    },
    {
        id: '9359',
        ort: 'Ranis',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07389',
    },
    {
        id: '9360',
        ort: 'Rankwitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17406',
    },
    {
        id: '9361',
        ort: 'Rannungen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97517',
    },
    {
        id: '9362',
        ort: 'Ransbach-Baumbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56235',
    },
    {
        id: '9363',
        ort: 'Ranschbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76829',
    },
    {
        id: '9364',
        ort: 'Ranstadt',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '63691',
    },
    {
        id: '9365',
        ort: 'Ransweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67808',
    },
    {
        id: '9366',
        ort: 'Rantrum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25873',
    },
    {
        id: '9367',
        ort: 'Rantzau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24321',
    },
    {
        id: '9368',
        ort: 'Rantzau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24329',
    },
    {
        id: '9369',
        ort: 'Rappin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18528',
    },
    {
        id: '9370',
        ort: 'Raschau-Markersbach',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '08352',
    },
    {
        id: '9371',
        ort: 'Rascheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54413',
    },
    {
        id: '9372',
        ort: 'Rasdorf',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36169',
    },
    {
        id: '9373',
        ort: 'Rastatt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '76437',
    },
    {
        id: '9374',
        ort: 'Rastdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26901',
    },
    {
        id: '9375',
        ort: 'Rastede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Ammerland',
        plz: '26180',
    },
    {
        id: '9376',
        ort: 'Rastenberg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99636',
    },
    {
        id: '9377',
        ort: 'Rastorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24211',
    },
    {
        id: '9378',
        ort: 'Rastow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19077',
    },
    {
        id: '9379',
        ort: 'Ratekau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23611',
    },
    {
        id: '9380',
        ort: 'Ratekau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23626',
    },
    {
        id: '9381',
        ort: 'Ratekau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23669',
    },
    {
        id: '9382',
        ort: 'Ratekau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23689',
    },
    {
        id: '9383',
        ort: 'Rathen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01824',
    },
    {
        id: '9384',
        ort: 'Rathenow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14712',
    },
    {
        id: '9385',
        ort: 'Rathjensdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24306',
    },
    {
        id: '9386',
        ort: 'Rathmannsdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01814',
    },
    {
        id: '9387',
        ort: 'Rathskirchen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67744',
    },
    {
        id: '9388',
        ort: 'Rathsweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66887',
    },
    {
        id: '9389',
        ort: 'Ratingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '40472',
    },
    {
        id: '9390',
        ort: 'Ratingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '40878',
    },
    {
        id: '9391',
        ort: 'Ratingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '40880',
    },
    {
        id: '9392',
        ort: 'Ratingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '40882',
    },
    {
        id: '9393',
        ort: 'Ratingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '40883',
    },
    {
        id: '9394',
        ort: 'Ratingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '40885',
    },
    {
        id: '9395',
        ort: 'Ratshausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72365',
    },
    {
        id: '9396',
        ort: 'Rattelsdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96179',
    },
    {
        id: '9397',
        ort: 'Rattelsdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '9398',
        ort: 'Rattenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94371',
    },
    {
        id: '9399',
        ort: 'Rattenkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84431',
    },
    {
        id: '9400',
        ort: 'Rattiszell',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94372',
    },
    {
        id: '9401',
        ort: 'Ratzeburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23909',
    },
    {
        id: '9402',
        ort: 'Ratzert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '57614',
    },
    {
        id: '9403',
        ort: 'Rätzlingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29590',
    },
    {
        id: '9404',
        ort: 'Raubach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56316',
    },
    {
        id: '9405',
        ort: 'Raubling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83064',
    },
    {
        id: '9406',
        ort: 'Rauda',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07613',
    },
    {
        id: '9407',
        ort: 'Rauen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15518',
    },
    {
        id: '9408',
        ort: 'Rauenberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69231',
    },
    {
        id: '9409',
        ort: 'Rauhenebrach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '96181',
    },
    {
        id: '9410',
        ort: 'Raumbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55592',
    },
    {
        id: '9411',
        ort: 'Raunheim',
        bundesland: 'Hessen',
        landkreis: 'Kreis Groß-Gerau',
        plz: '65479',
    },
    {
        id: '9412',
        ort: 'Rauschenberg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35282',
    },
    {
        id: '9413',
        ort: 'Rauschwitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07616',
    },
    {
        id: '9414',
        ort: 'Rausdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22929',
    },
    {
        id: '9415',
        ort: 'Rausdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '9416',
        ort: 'Ravengiersburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55471',
    },
    {
        id: '9417',
        ort: 'Ravensburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88212',
    },
    {
        id: '9418',
        ort: 'Ravensburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88213',
    },
    {
        id: '9419',
        ort: 'Ravensburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88214',
    },
    {
        id: '9420',
        ort: 'Ravenstein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74747',
    },
    {
        id: '9421',
        ort: 'Raversbeuren',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56850',
    },
    {
        id: '9422',
        ort: 'Rayerschied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55469',
    },
    {
        id: '9423',
        ort: 'Rech',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53506',
    },
    {
        id: '9424',
        ort: 'Rechberghausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73098',
    },
    {
        id: '9425',
        ort: 'Rechenberg-Bienenmühle',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09623',
    },
    {
        id: '9426',
        ort: 'Rechlin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17248',
    },
    {
        id: '9427',
        ort: 'Rechtenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97848',
    },
    {
        id: '9428',
        ort: 'Rechtenstein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89611',
    },
    {
        id: '9429',
        ort: 'Rechtmehring',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '83562',
    },
    {
        id: '9430',
        ort: 'Rechtsupweg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26529',
    },
    {
        id: '9431',
        ort: 'Recke',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '49509',
    },
    {
        id: '9432',
        ort: 'Reckendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96182',
    },
    {
        id: '9433',
        ort: 'Reckenroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56370',
    },
    {
        id: '9434',
        ort: 'Reckershausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55481',
    },
    {
        id: '9435',
        ort: 'Recklinghausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '45657',
    },
    {
        id: '9436',
        ort: 'Recklinghausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '45659',
    },
    {
        id: '9437',
        ort: 'Recklinghausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '45661',
    },
    {
        id: '9438',
        ort: 'Recklinghausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '45663',
    },
    {
        id: '9439',
        ort: 'Recklinghausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '45665',
    },
    {
        id: '9440',
        ort: 'Reddelich',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18209',
    },
    {
        id: '9441',
        ort: 'Redefin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '9442',
        ort: 'Rednitzhembach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Roth',
        plz: '91126',
    },
    {
        id: '9443',
        ort: 'Redwitz a.d.Rodach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lichtenfels',
        plz: '96257',
    },
    {
        id: '9444',
        ort: 'Rees',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '46459',
    },
    {
        id: '9445',
        ort: 'Reesdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24241',
    },
    {
        id: '9446',
        ort: 'Reeßum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27367',
    },
    {
        id: '9447',
        ort: 'Regen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94209',
    },
    {
        id: '9448',
        ort: 'Regensburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '93047',
    },
    {
        id: '9449',
        ort: 'Regensburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '93049',
    },
    {
        id: '9450',
        ort: 'Regensburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '93051',
    },
    {
        id: '9451',
        ort: 'Regensburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '93053',
    },
    {
        id: '9452',
        ort: 'Regensburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '93055',
    },
    {
        id: '9453',
        ort: 'Regensburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '93057',
    },
    {
        id: '9454',
        ort: 'Regensburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '93059',
    },
    {
        id: '9455',
        ort: 'Regenstauf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93128',
    },
    {
        id: '9456',
        ort: 'Regesbostel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21649',
    },
    {
        id: '9457',
        ort: 'Regis-Breitingen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04565',
    },
    {
        id: '9458',
        ort: 'Regnitzlosau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95194',
    },
    {
        id: '9459',
        ort: 'Rehau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95111',
    },
    {
        id: '9460',
        ort: 'Rehbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55566',
    },
    {
        id: '9461',
        ort: 'Rehborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55592',
    },
    {
        id: '9462',
        ort: 'Rehburg-Loccum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31547',
    },
    {
        id: '9463',
        ort: 'Rehden',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49453',
    },
    {
        id: '9464',
        ort: 'Rehe',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '9465',
        ort: 'Reher',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25593',
    },
    {
        id: '9466',
        ort: 'Rehfelde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15345',
    },
    {
        id: '9467',
        ort: 'Rehhorst',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23619',
    },
    {
        id: '9468',
        ort: 'Rehling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86508',
    },
    {
        id: '9469',
        ort: 'Rehlingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21385',
    },
    {
        id: '9470',
        ort: 'Rehlingen-Siersburg',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Saarlouis',
        plz: '66780',
    },
    {
        id: '9471',
        ort: 'Rehm-Flehde-Bargen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25776',
    },
    {
        id: '9472',
        ort: 'Rehna',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19217',
    },
    {
        id: '9473',
        ort: 'Rehweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66907',
    },
    {
        id: '9474',
        ort: 'Reich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55471',
    },
    {
        id: '9475',
        ort: 'Reichartshausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '74934',
    },
    {
        id: '9476',
        ort: 'Reichelsheim',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '61203',
    },
    {
        id: '9477',
        ort: 'Reichelsheim (Odenwald)',
        bundesland: 'Hessen',
        landkreis: 'Odenwaldkreis',
        plz: '64385',
    },
    {
        id: '9478',
        ort: 'Reichenau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78479',
    },
    {
        id: '9479',
        ort: 'Reichenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93189',
    },
    {
        id: '9480',
        ort: 'Reichenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kronach',
        plz: '96358',
    },
    {
        id: '9481',
        ort: 'Reichenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55776',
    },
    {
        id: '9482',
        ort: 'Reichenbach',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07629',
    },
    {
        id: '9483',
        ort: 'Reichenbach',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08468',
    },
    {
        id: '9484',
        ort: 'Reichenbach',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08499',
    },
    {
        id: '9485',
        ort: 'Reichenbach am Heuberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78564',
    },
    {
        id: '9486',
        ort: 'Reichenbach an der Fils',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73262',
    },
    {
        id: '9487',
        ort: 'Reichenbach/Oberlausitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02894',
    },
    {
        id: '9488',
        ort: 'Reichenbach-Steegen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66879',
    },
    {
        id: '9489',
        ort: 'Reichenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97234',
    },
    {
        id: '9490',
        ort: 'Reichenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '9491',
        ort: 'Reichenow-Möglin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15345',
    },
    {
        id: '9492',
        ort: 'Reichenschwand',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91244',
    },
    {
        id: '9493',
        ort: 'Reichenwalde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15526',
    },
    {
        id: '9494',
        ort: 'Reichersbeuern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '83677',
    },
    {
        id: '9495',
        ort: 'Reichertshausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85293',
    },
    {
        id: '9496',
        ort: 'Reichertsheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84437',
    },
    {
        id: '9497',
        ort: 'Reichertshofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85084',
    },
    {
        id: '9498',
        ort: 'Reichling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86934',
    },
    {
        id: '9499',
        ort: 'Reichshof',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Oberbergischer Kreis',
        plz: '51580',
    },
    {
        id: '9500',
        ort: 'Reichstädt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07580',
    },
    {
        id: '9501',
        ort: 'Reichsthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67759',
    },
    {
        id: '9502',
        ort: 'Reichweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66871',
    },
    {
        id: '9503',
        ort: 'Reidenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56865',
    },
    {
        id: '9504',
        ort: 'Reifenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66507',
    },
    {
        id: '9505',
        ort: 'Reiferscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57632',
    },
    {
        id: '9506',
        ort: 'Reiff',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54619',
    },
    {
        id: '9507',
        ort: 'Reiffelbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '67829',
    },
    {
        id: '9508',
        ort: 'Reifferscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53520',
    },
    {
        id: '9509',
        ort: 'Reil',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '56861',
    },
    {
        id: '9510',
        ort: 'Reilingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '68799',
    },
    {
        id: '9511',
        ort: 'Reimerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '53539',
    },
    {
        id: '9512',
        ort: 'Reimershagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18276',
    },
    {
        id: '9513',
        ort: 'Reimlingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86756',
    },
    {
        id: '9514',
        ort: 'Reinbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '21465',
    },
    {
        id: '9515',
        ort: 'Reinbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '21521',
    },
    {
        id: '9516',
        ort: 'Reinfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23858',
    },
    {
        id: '9517',
        ort: 'Reinhardshagen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34359',
    },
    {
        id: '9518',
        ort: 'Reinhardtsdorf-Schöna',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01814',
    },
    {
        id: '9519',
        ort: 'Reinheim',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64354',
    },
    {
        id: '9520',
        ort: 'Reinholterode',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37308',
    },
    {
        id: '9521',
        ort: 'Reinsberg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09629',
    },
    {
        id: '9522',
        ort: 'Reinsberg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09634',
    },
    {
        id: '9523',
        ort: 'Reinsbüttel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25764',
    },
    {
        id: '9524',
        ort: 'Reinsdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08141',
    },
    {
        id: '9525',
        ort: 'Reinsdorf',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '06556',
    },
    {
        id: '9526',
        ort: 'Reinsfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54421',
    },
    {
        id: '9527',
        ort: 'Reinstädt',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07768',
    },
    {
        id: '9528',
        ort: 'Reinstorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21400',
    },
    {
        id: '9529',
        ort: 'Reipeldingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54689',
    },
    {
        id: '9530',
        ort: 'Reipoltskirchen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67753',
    },
    {
        id: '9531',
        ort: 'Reisbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dingolfing-Landau',
        plz: '94419',
    },
    {
        id: '9532',
        ort: 'Reischach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84571',
    },
    {
        id: '9533',
        ort: 'Reiskirchen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35447',
    },
    {
        id: '9534',
        ort: 'Reit im Winkl',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83242',
    },
    {
        id: '9535',
        ort: 'Reitwein',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15328',
    },
    {
        id: '9536',
        ort: 'Reitzenhain',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '9537',
        ort: 'Reken',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '48734',
    },
    {
        id: '9538',
        ort: 'Rellingen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25462',
    },
    {
        id: '9539',
        ort: 'Relsberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67753',
    },
    {
        id: '9540',
        ort: 'Remagen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53424',
    },
    {
        id: '9541',
        ort: 'Remchingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75196',
    },
    {
        id: '9542',
        ort: 'Remlingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97280',
    },
    {
        id: '9543',
        ort: 'Remlingen-Semmenstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38319',
    },
    {
        id: '9544',
        ort: 'Remlingen-Semmenstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38327',
    },
    {
        id: '9545',
        ort: 'Remmels',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24594',
    },
    {
        id: '9546',
        ort: 'Remptendorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07368',
    },
    {
        id: '9547',
        ort: 'Remscheid',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42853',
    },
    {
        id: '9548',
        ort: 'Remscheid',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42855',
    },
    {
        id: '9549',
        ort: 'Remscheid',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42857',
    },
    {
        id: '9550',
        ort: 'Remscheid',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42859',
    },
    {
        id: '9551',
        ort: 'Remscheid',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42897',
    },
    {
        id: '9552',
        ort: 'Remscheid',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42899',
    },
    {
        id: '9553',
        ort: 'Remse',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08373',
    },
    {
        id: '9554',
        ort: 'Remseck am Neckar',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71686',
    },
    {
        id: '9555',
        ort: 'Remshalden',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '73630',
    },
    {
        id: '9556',
        ort: 'Renchen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77871',
    },
    {
        id: '9557',
        ort: 'Rendsburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24768',
    },
    {
        id: '9558',
        ort: 'Rendswühren',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24619',
    },
    {
        id: '9559',
        ort: 'Rengsdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56579',
    },
    {
        id: '9560',
        ort: 'Renkenberge',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49762',
    },
    {
        id: '9561',
        ort: 'Rennau',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38368',
    },
    {
        id: '9562',
        ort: 'Rennerod',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56477',
    },
    {
        id: '9563',
        ort: 'Rennertshofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neuburg-Schrobenhausen',
        plz: '86643',
    },
    {
        id: '9564',
        ort: 'Renningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71272',
    },
    {
        id: '9565',
        ort: 'Renquishausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78603',
    },
    {
        id: '9566',
        ort: 'Renthendorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '9567',
        ort: 'Rentweinsdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '96184',
    },
    {
        id: '9568',
        ort: 'Reppenstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21391',
    },
    {
        id: '9569',
        ort: 'Rerik',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18230',
    },
    {
        id: '9570',
        ort: 'Rethem (Aller)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '27336',
    },
    {
        id: '9571',
        ort: 'Rethwisch',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25566',
    },
    {
        id: '9572',
        ort: 'Rethwisch',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23847',
    },
    {
        id: '9573',
        ort: 'Retschow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18211',
    },
    {
        id: '9574',
        ort: 'Rettenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93191',
    },
    {
        id: '9575',
        ort: 'Rettenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89364',
    },
    {
        id: '9576',
        ort: 'Rettenbach a.Auerberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87675',
    },
    {
        id: '9577',
        ort: 'Rettenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87549',
    },
    {
        id: '9578',
        ort: 'Retterath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '56769',
    },
    {
        id: '9579',
        ort: 'Rettersen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57635',
    },
    {
        id: '9580',
        ort: 'Rettershain',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '9581',
        ort: 'Rettert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56370',
    },
    {
        id: '9582',
        ort: 'Retzow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14641',
    },
    {
        id: '9583',
        ort: 'Retzstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97282',
    },
    {
        id: '9584',
        ort: 'Reudelsterz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56727',
    },
    {
        id: '9585',
        ort: 'Reurieth',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98646',
    },
    {
        id: '9586',
        ort: 'Reußenköge',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25821',
    },
    {
        id: '9587',
        ort: 'Reut',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84367',
    },
    {
        id: '9588',
        ort: 'Reute',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79276',
    },
    {
        id: '9589',
        ort: 'Reuth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54597',
    },
    {
        id: '9590',
        ort: 'Reuth b.Erbendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '92717',
    },
    {
        id: '9591',
        ort: 'Reutlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72760',
    },
    {
        id: '9592',
        ort: 'Reutlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72762',
    },
    {
        id: '9593',
        ort: 'Reutlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72764',
    },
    {
        id: '9594',
        ort: 'Reutlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72766',
    },
    {
        id: '9595',
        ort: 'Reutlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72768',
    },
    {
        id: '9596',
        ort: 'Reutlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72770',
    },
    {
        id: '9597',
        ort: 'Rhade',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27404',
    },
    {
        id: '9598',
        ort: 'Rhauderfehn',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26817',
    },
    {
        id: '9599',
        ort: 'Rhaunen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55624',
    },
    {
        id: '9600',
        ort: 'Rheda-Wiedenbrück',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Gütersloh',
        plz: '33378',
    },
    {
        id: '9601',
        ort: 'Rhede',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '46414',
    },
    {
        id: '9602',
        ort: 'Rhede (Ems)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26899',
    },
    {
        id: '9603',
        ort: 'Rheinau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77866',
    },
    {
        id: '9604',
        ort: 'Rheinbach',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53359',
    },
    {
        id: '9605',
        ort: 'Rheinberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '47495',
    },
    {
        id: '9606',
        ort: 'Rheinböllen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55494',
    },
    {
        id: '9607',
        ort: 'Rheinbreitbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53619',
    },
    {
        id: '9608',
        ort: 'Rheinbrohl',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56598',
    },
    {
        id: '9609',
        ort: 'Rheine',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '48429',
    },
    {
        id: '9610',
        ort: 'Rheine',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '48431',
    },
    {
        id: '9611',
        ort: 'Rheine',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '48432',
    },
    {
        id: '9612',
        ort: 'Rheinfelden (Baden)',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79618',
    },
    {
        id: '9613',
        ort: 'Rheinhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79365',
    },
    {
        id: '9614',
        ort: 'Rheinmünster',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '77836',
    },
    {
        id: '9615',
        ort: 'Rheinsberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16818',
    },
    {
        id: '9616',
        ort: 'Rheinsberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16831',
    },
    {
        id: '9617',
        ort: 'Rheinsberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16835',
    },
    {
        id: '9618',
        ort: 'Rheinsberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16837',
    },
    {
        id: '9619',
        ort: 'Rheinstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76287',
    },
    {
        id: '9620',
        ort: 'Rheinzabern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76764',
    },
    {
        id: '9621',
        ort: 'Rhens',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56321',
    },
    {
        id: '9622',
        ort: 'Rhens',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56323',
    },
    {
        id: '9623',
        ort: 'Rheurdt',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '47509',
    },
    {
        id: '9624',
        ort: 'Rhinow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14728',
    },
    {
        id: '9625',
        ort: 'Rhodt unter Rietburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76835',
    },
    {
        id: '9626',
        ort: 'Rhönblick',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98617',
    },
    {
        id: '9627',
        ort: 'Rhumspringe',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37434',
    },
    {
        id: '9628',
        ort: 'Ribbesbüttel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38551',
    },
    {
        id: '9629',
        ort: 'Ribnitz-Damgarten',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18311',
    },
    {
        id: '9630',
        ort: 'Richtenberg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18461',
    },
    {
        id: '9631',
        ort: 'Rickenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79736',
    },
    {
        id: '9632',
        ort: 'Rickert',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24782',
    },
    {
        id: '9633',
        ort: 'Rickert',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24787',
    },
    {
        id: '9634',
        ort: 'Rickling',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24635',
    },
    {
        id: '9635',
        ort: 'Ried',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86510',
    },
    {
        id: '9636',
        ort: 'Riedbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '97519',
    },
    {
        id: '9637',
        ort: 'Riede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Verden',
        plz: '27339',
    },
    {
        id: '9638',
        ort: 'Riedelberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66484',
    },
    {
        id: '9639',
        ort: 'Rieden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87668',
    },
    {
        id: '9640',
        ort: 'Rieden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56745',
    },
    {
        id: '9641',
        ort: 'Rieden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92286',
    },
    {
        id: '9642',
        ort: 'Rieden am Forggensee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87669',
    },
    {
        id: '9643',
        ort: 'Riedenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97792',
    },
    {
        id: '9644',
        ort: 'Riedenburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '93339',
    },
    {
        id: '9645',
        ort: 'Riedenheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97283',
    },
    {
        id: '9646',
        ort: 'Riederich',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72585',
    },
    {
        id: '9647',
        ort: 'Riedering',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83083',
    },
    {
        id: '9648',
        ort: 'Riedhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88377',
    },
    {
        id: '9649',
        ort: 'Riedlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88499',
    },
    {
        id: '9650',
        ort: 'Riedstadt',
        bundesland: 'Hessen',
        landkreis: 'Kreis Groß-Gerau',
        plz: '64560',
    },
    {
        id: '9651',
        ort: 'Riegel',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79359',
    },
    {
        id: '9652',
        ort: 'Riegelsberg',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66292',
    },
    {
        id: '9653',
        ort: 'Riegenroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55469',
    },
    {
        id: '9654',
        ort: 'Riegsee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82418',
    },
    {
        id: '9655',
        ort: 'Riekofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93104',
    },
    {
        id: '9656',
        ort: 'Rielasingen-Worblingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78239',
    },
    {
        id: '9657',
        ort: 'Rieneck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97794',
    },
    {
        id: '9658',
        ort: 'Rieps',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19217',
    },
    {
        id: '9659',
        ort: 'Riepsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23738',
    },
    {
        id: '9660',
        ort: 'Riesa',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01587',
    },
    {
        id: '9661',
        ort: 'Riesa',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01589',
    },
    {
        id: '9662',
        ort: 'Riesa',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01591',
    },
    {
        id: '9663',
        ort: 'Riesa',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01594',
    },
    {
        id: '9664',
        ort: 'Riesbürg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73469',
    },
    {
        id: '9665',
        ort: 'Rieschweiler-Mühlbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66509',
    },
    {
        id: '9666',
        ort: 'Rieseby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24354',
    },
    {
        id: '9667',
        ort: 'Rieste',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49597',
    },
    {
        id: '9668',
        ort: 'Riesweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55499',
    },
    {
        id: '9669',
        ort: 'Rietberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Gütersloh',
        plz: '33397',
    },
    {
        id: '9670',
        ort: 'Rietheim-Weilheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78604',
    },
    {
        id: '9671',
        ort: 'Riethnordhausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99195',
    },
    {
        id: '9672',
        ort: 'Rietschen - Rěčicy',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02956',
    },
    {
        id: '9673',
        ort: 'Rietz-Neuendorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15848',
    },
    {
        id: '9674',
        ort: 'Rietzneuendorf-Staakow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15910',
    },
    {
        id: '9675',
        ort: 'Rimbach',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '64668',
    },
    {
        id: '9676',
        ort: 'Rimbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84326',
    },
    {
        id: '9677',
        ort: 'Rimbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93485',
    },
    {
        id: '9678',
        ort: 'Rimpar',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97222',
    },
    {
        id: '9679',
        ort: 'Rimsberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55765',
    },
    {
        id: '9680',
        ort: 'Rimsting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83253',
    },
    {
        id: '9681',
        ort: 'Rinchnach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94269',
    },
    {
        id: '9682',
        ort: 'Ringe',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '49824',
    },
    {
        id: '9683',
        ort: 'Ringelai',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94160',
    },
    {
        id: '9684',
        ort: 'Ringgau',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37296',
    },
    {
        id: '9685',
        ort: 'Ringleben',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99189',
    },
    {
        id: '9686',
        ort: 'Ringsberg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24977',
    },
    {
        id: '9687',
        ort: 'Ringsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77975',
    },
    {
        id: '9688',
        ort: 'Rinnthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76857',
    },
    {
        id: '9689',
        ort: 'Rinteln',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31737',
    },
    {
        id: '9690',
        ort: 'Rinzenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '9691',
        ort: 'Riol',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54340',
    },
    {
        id: '9692',
        ort: 'Rippershausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98639',
    },
    {
        id: '9693',
        ort: 'Risum-Lindholm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25920',
    },
    {
        id: '9694',
        ort: 'Ritschenhausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98617',
    },
    {
        id: '9695',
        ort: 'Ritterhude',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osterholz',
        plz: '27721',
    },
    {
        id: '9696',
        ort: 'Rittersdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '9697',
        ort: 'Rittersdorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99448',
    },
    {
        id: '9698',
        ort: 'Rittersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67294',
    },
    {
        id: '9699',
        ort: 'Ritzerau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23896',
    },
    {
        id: '9700',
        ort: 'Ritzerow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17153',
    },
    {
        id: '9701',
        ort: 'Rivenich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '9702',
        ort: 'Riveris',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54317',
    },
    {
        id: '9703',
        ort: 'Röbel/Müritz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17207',
    },
    {
        id: '9704',
        ort: 'Rochau',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39579',
    },
    {
        id: '9705',
        ort: 'Rochlitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09306',
    },
    {
        id: '9706',
        ort: 'Rockenberg',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '35519',
    },
    {
        id: '9707',
        ort: 'Rockenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67806',
    },
    {
        id: '9708',
        ort: 'Rockeskyll',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '9709',
        ort: 'Röckingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91740',
    },
    {
        id: '9710',
        ort: 'Rockstedt',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '99713',
    },
    {
        id: '9711',
        ort: 'Röckwitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17091',
    },
    {
        id: '9712',
        ort: 'Rodalben',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66976',
    },
    {
        id: '9713',
        ort: 'Rodder',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53520',
    },
    {
        id: '9714',
        ort: 'Rodeberg',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99976',
    },
    {
        id: '9715',
        ort: 'Rödelhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56858',
    },
    {
        id: '9716',
        ort: 'Rödelmaier',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97618',
    },
    {
        id: '9717',
        ort: 'Rödelsee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97348',
    },
    {
        id: '9718',
        ort: 'Roden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97849',
    },
    {
        id: '9719',
        ort: 'Rodenäs',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25924',
    },
    {
        id: '9720',
        ort: 'Rodenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67688',
    },
    {
        id: '9721',
        ort: 'Rodenbach',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63517',
    },
    {
        id: '9722',
        ort: 'Rodenbach bei Puderbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '57639',
    },
    {
        id: '9723',
        ort: 'Rodenbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24247',
    },
    {
        id: '9724',
        ort: 'Rodenberg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31552',
    },
    {
        id: '9725',
        ort: 'Rödental',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Coburg',
        plz: '96472',
    },
    {
        id: '9726',
        ort: 'Röderaue',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01609',
    },
    {
        id: '9727',
        ort: 'Röderland',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04932',
    },
    {
        id: '9728',
        ort: 'Rödermark',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Offenbach',
        plz: '63322',
    },
    {
        id: '9729',
        ort: 'Rödern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55481',
    },
    {
        id: '9730',
        ort: 'Rodershausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '9731',
        ort: 'Rödersheim-Gronau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67127',
    },
    {
        id: '9732',
        ort: 'Rodewald',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31637',
    },
    {
        id: '9733',
        ort: 'Rodewisch',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08228',
    },
    {
        id: '9734',
        ort: 'Rodgau',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Offenbach',
        plz: '63110',
    },
    {
        id: '9735',
        ort: 'Roding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93426',
    },
    {
        id: '9736',
        ort: 'Rödinghausen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Herford',
        plz: '32289',
    },
    {
        id: '9737',
        ort: 'Roduchelstorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23923',
    },
    {
        id: '9738',
        ort: 'Roes',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56754',
    },
    {
        id: '9739',
        ort: 'Roetgen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52159',
    },
    {
        id: '9740',
        ort: 'Röfingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89365',
    },
    {
        id: '9741',
        ort: 'Rogätz',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39326',
    },
    {
        id: '9742',
        ort: 'Roggenburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89297',
    },
    {
        id: '9743',
        ort: 'Roggendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19205',
    },
    {
        id: '9744',
        ort: 'Roggenstorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23936',
    },
    {
        id: '9745',
        ort: 'Roggentin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18184',
    },
    {
        id: '9746',
        ort: 'Rögling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86703',
    },
    {
        id: '9747',
        ort: 'Rögnitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19205',
    },
    {
        id: '9748',
        ort: 'Röhl',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '9749',
        ort: 'Rohlstorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23821',
    },
    {
        id: '9750',
        ort: 'Rohr',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Roth',
        plz: '91189',
    },
    {
        id: '9751',
        ort: 'Rohr',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98530',
    },
    {
        id: '9752',
        ort: 'Rohrbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85296',
    },
    {
        id: '9753',
        ort: 'Rohrbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55776',
    },
    {
        id: '9754',
        ort: 'Rohrbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55490',
    },
    {
        id: '9755',
        ort: 'Rohrbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76865',
    },
    {
        id: '9756',
        ort: 'Rohrbach',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07429',
    },
    {
        id: '9757',
        ort: 'Rohrberg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Altmarkkreis Salzwedel',
        plz: '38489',
    },
    {
        id: '9758',
        ort: 'Rohrberg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '9759',
        ort: 'Rohrdorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '72229',
    },
    {
        id: '9760',
        ort: 'Rohrdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83101',
    },
    {
        id: '9761',
        ort: 'Rohrenfels',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neuburg-Schrobenhausen',
        plz: '86701',
    },
    {
        id: '9762',
        ort: 'Röhrig',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '9763',
        ort: 'Rohr i.NB',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '93352',
    },
    {
        id: '9764',
        ort: 'Röhrmoos',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '85244',
    },
    {
        id: '9765',
        ort: 'Röhrnbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94133',
    },
    {
        id: '9766',
        ort: 'Rohrsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31627',
    },
    {
        id: '9767',
        ort: 'Roigheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74255',
    },
    {
        id: '9768',
        ort: 'Roklum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38325',
    },
    {
        id: '9769',
        ort: 'Röllbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63934',
    },
    {
        id: '9770',
        ort: 'Rollshausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37434',
    },
    {
        id: '9771',
        ort: 'Rollwitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17309',
    },
    {
        id: '9772',
        ort: 'Rom',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19372',
    },
    {
        id: '9773',
        ort: 'Römerberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67354',
    },
    {
        id: '9774',
        ort: 'Römerstein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72587',
    },
    {
        id: '9775',
        ort: 'Römhild',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98630',
    },
    {
        id: '9776',
        ort: 'Rommersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '9777',
        ort: 'Rommerskirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Kreis Neuss',
        plz: '41569',
    },
    {
        id: '9778',
        ort: 'Römnitz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23909',
    },
    {
        id: '9779',
        ort: 'Romrod',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '36329',
    },
    {
        id: '9780',
        ort: 'Römstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29591',
    },
    {
        id: '9781',
        ort: 'Rondeshagen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23919',
    },
    {
        id: '9782',
        ort: 'Ronneburg',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63549',
    },
    {
        id: '9783',
        ort: 'Ronneburg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07580',
    },
    {
        id: '9784',
        ort: 'Ronnenberg',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30952',
    },
    {
        id: '9785',
        ort: 'Ronsberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87671',
    },
    {
        id: '9786',
        ort: 'Ronshausen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36217',
    },
    {
        id: '9787',
        ort: 'Rorodt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54411',
    },
    {
        id: '9788',
        ort: 'Rosa',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98590',
    },
    {
        id: '9789',
        ort: 'Rosbach v. d. Höhe',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '61191',
    },
    {
        id: '9790',
        ort: 'Roschbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76835',
    },
    {
        id: '9791',
        ort: 'Rosche',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29571',
    },
    {
        id: '9792',
        ort: 'Roscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54619',
    },
    {
        id: '9793',
        ort: 'Rosdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25548',
    },
    {
        id: '9794',
        ort: 'Rosdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37124',
    },
    {
        id: '9795',
        ort: 'Roseburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21514',
    },
    {
        id: '9796',
        ort: 'Rosenau',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14789',
    },
    {
        id: '9797',
        ort: 'Rosenbach',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02708',
    },
    {
        id: '9798',
        ort: 'Rosenbach/Vogtland',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08527',
    },
    {
        id: '9799',
        ort: 'Rosenbach/Vogtland',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08539',
    },
    {
        id: '9800',
        ort: 'Rosenbach/Vogtland',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08548',
    },
    {
        id: '9801',
        ort: 'Rosenberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74749',
    },
    {
        id: '9802',
        ort: 'Rosenberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73494',
    },
    {
        id: '9803',
        ort: 'Rosendahl',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Coesfeld',
        plz: '48720',
    },
    {
        id: '9804',
        ort: 'Rosendorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07819',
    },
    {
        id: '9805',
        ort: 'Rosenfeld',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72348',
    },
    {
        id: '9806',
        ort: 'Rosengarten',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21224',
    },
    {
        id: '9807',
        ort: 'Rosengarten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74538',
    },
    {
        id: '9808',
        ort: 'Rosenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57520',
    },
    {
        id: '9809',
        ort: 'Rosenheim',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '83022',
    },
    {
        id: '9810',
        ort: 'Rosenheim',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '83024',
    },
    {
        id: '9811',
        ort: 'Rosenheim',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '83026',
    },
    {
        id: '9812',
        ort: 'Rosenkopf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66894',
    },
    {
        id: '9813',
        ort: 'Rosenow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17091',
    },
    {
        id: '9814',
        ort: 'Rosenthal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '35119',
    },
    {
        id: '9815',
        ort: 'Rosenthal am Rennsteig',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07366',
    },
    {
        id: '9816',
        ort: 'Rosenthal-Bielatal',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01824',
    },
    {
        id: '9817',
        ort: 'Rositz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04617',
    },
    {
        id: '9818',
        ort: 'Roskow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14778',
    },
    {
        id: '9819',
        ort: 'Röslau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Wunsiedel im Fichtelgebirge',
        plz: '95195',
    },
    {
        id: '9820',
        ort: 'Rösrath',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rheinisch-Bergischer Kreis',
        plz: '51503',
    },
    {
        id: '9821',
        ort: 'Rossau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09661',
    },
    {
        id: '9822',
        ort: 'Roßbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53547',
    },
    {
        id: '9823',
        ort: 'Roßbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56271',
    },
    {
        id: '9824',
        ort: 'Roßbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '94439',
    },
    {
        id: '9825',
        ort: 'Roßdorf',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64380',
    },
    {
        id: '9826',
        ort: 'Roßdorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98590',
    },
    {
        id: '9827',
        ort: 'Roßhaupten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87672',
    },
    {
        id: '9828',
        ort: 'Rossin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17398',
    },
    {
        id: '9829',
        ort: 'Roßleben-Wiehe',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '06571',
    },
    {
        id: '9830',
        ort: 'Rossow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17322',
    },
    {
        id: '9831',
        ort: 'Roßtal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürth',
        plz: '90574',
    },
    {
        id: '9832',
        ort: 'Roßwein',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '04741',
    },
    {
        id: '9833',
        ort: 'Rostock',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: '',
        plz: '18055',
    },
    {
        id: '9834',
        ort: 'Rostock',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: '',
        plz: '18057',
    },
    {
        id: '9835',
        ort: 'Rostock',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: '',
        plz: '18059',
    },
    {
        id: '9836',
        ort: 'Rostock',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: '',
        plz: '18069',
    },
    {
        id: '9837',
        ort: 'Rostock',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: '',
        plz: '18106',
    },
    {
        id: '9838',
        ort: 'Rostock',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: '',
        plz: '18107',
    },
    {
        id: '9839',
        ort: 'Rostock',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: '',
        plz: '18109',
    },
    {
        id: '9840',
        ort: 'Rostock',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: '',
        plz: '18119',
    },
    {
        id: '9841',
        ort: 'Rostock',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: '',
        plz: '18146',
    },
    {
        id: '9842',
        ort: 'Rostock',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: '',
        plz: '18147',
    },
    {
        id: '9843',
        ort: 'Rostock',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: '',
        plz: '18181',
    },
    {
        id: '9844',
        ort: 'Rostock',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: '',
        plz: '18182',
    },
    {
        id: '9845',
        ort: 'Rot am See',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74585',
    },
    {
        id: '9846',
        ort: 'Rot an der Rot',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88430',
    },
    {
        id: '9847',
        ort: 'Rotenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27356',
    },
    {
        id: '9848',
        ort: 'Rotenburg an der Fulda',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36199',
    },
    {
        id: '9849',
        ort: 'Rotenhain',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '9850',
        ort: 'Rötgesbüttel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38531',
    },
    {
        id: '9851',
        ort: 'Roth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Roth',
        plz: '91154',
    },
    {
        id: '9852',
        ort: 'Roth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57539',
    },
    {
        id: '9853',
        ort: 'Roth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56368',
    },
    {
        id: '9854',
        ort: 'Roth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56288',
    },
    {
        id: '9855',
        ort: 'Roth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55442',
    },
    {
        id: '9856',
        ort: 'Rötha',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04571',
    },
    {
        id: '9857',
        ort: 'Roth an der Our',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '9858',
        ort: 'Roth bei Prüm',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '9859',
        ort: 'Rothemühl',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17379',
    },
    {
        id: '9860',
        ort: 'Rothenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '9861',
        ort: 'Röthenbach (Allgäu)',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88167',
    },
    {
        id: '9862',
        ort: 'Röthenbach an der Pegnitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '90552',
    },
    {
        id: '9863',
        ort: 'Rothenbuch',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63860',
    },
    {
        id: '9864',
        ort: 'Rothenbuch',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '97840',
    },
    {
        id: '9865',
        ort: 'Rothenburg ob der Tauber',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91541',
    },
    {
        id: '9866',
        ort: 'Rothenburg/Oberlausitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02929',
    },
    {
        id: '9867',
        ort: 'Rothenfels',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97851',
    },
    {
        id: '9868',
        ort: 'Rothenklempenow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17321',
    },
    {
        id: '9869',
        ort: 'Rothenklempenow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17322',
    },
    {
        id: '9870',
        ort: 'Rothenstein',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07751',
    },
    {
        id: '9871',
        ort: 'Röthlein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97520',
    },
    {
        id: '9872',
        ort: 'Rothselberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67753',
    },
    {
        id: '9873',
        ort: 'Rötsweiler-Nockenthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '9874',
        ort: 'Rott',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57632',
    },
    {
        id: '9875',
        ort: 'Rott',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86935',
    },
    {
        id: '9876',
        ort: 'Rottach-Egern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miesbach',
        plz: '83700',
    },
    {
        id: '9877',
        ort: 'Rott am Inn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83543',
    },
    {
        id: '9878',
        ort: 'Rottenacker',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89616',
    },
    {
        id: '9879',
        ort: 'Röttenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Roth',
        plz: '91187',
    },
    {
        id: '9880',
        ort: 'Röttenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91341',
    },
    {
        id: '9881',
        ort: 'Rottenbuch',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82401',
    },
    {
        id: '9882',
        ort: 'Rottenburg a. d. Laaber',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84056',
    },
    {
        id: '9883',
        ort: 'Rottenburg am Neckar',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72108',
    },
    {
        id: '9884',
        ort: 'Rottendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97228',
    },
    {
        id: '9885',
        ort: 'Rotthalmünster',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94094',
    },
    {
        id: '9886',
        ort: 'Röttingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97285',
    },
    {
        id: '9887',
        ort: 'Rottweil',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78628',
    },
    {
        id: '9888',
        ort: 'Rötz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '92444',
    },
    {
        id: '9889',
        ort: 'Rövershagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18182',
    },
    {
        id: '9890',
        ort: 'Roxheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '9891',
        ort: 'Rubenow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17509',
    },
    {
        id: '9892',
        ort: 'Rüber',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56295',
    },
    {
        id: '9893',
        ort: 'Rubkow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17390',
    },
    {
        id: '9894',
        ort: 'Rückeroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '9895',
        ort: 'Rückersdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '90607',
    },
    {
        id: '9896',
        ort: 'Rückersdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '03238',
    },
    {
        id: '9897',
        ort: 'Rückersdorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07580',
    },
    {
        id: '9898',
        ort: 'Rückholz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87494',
    },
    {
        id: '9899',
        ort: 'Rückweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55776',
    },
    {
        id: '9900',
        ort: 'Rudelzhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '84104',
    },
    {
        id: '9901',
        ort: 'Rüdenau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63924',
    },
    {
        id: '9902',
        ort: 'Rüdenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97355',
    },
    {
        id: '9903',
        ort: 'Ruderatshofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87674',
    },
    {
        id: '9904',
        ort: 'Rudersberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '73635',
    },
    {
        id: '9905',
        ort: 'Rüdersdorf bei Berlin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15345',
    },
    {
        id: '9906',
        ort: 'Rüdersdorf bei Berlin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15378',
    },
    {
        id: '9907',
        ort: 'Rüdersdorf bei Berlin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15562',
    },
    {
        id: '9908',
        ort: 'Rüdershausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37434',
    },
    {
        id: '9909',
        ort: 'Ruderting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94161',
    },
    {
        id: '9910',
        ort: 'Rüdesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55593',
    },
    {
        id: '9911',
        ort: 'Rüdesheim am Rhein',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65385',
    },
    {
        id: '9912',
        ort: 'Rüdnitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16321',
    },
    {
        id: '9913',
        ort: 'Rudolstadt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07407',
    },
    {
        id: '9914',
        ort: 'Rugendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95365',
    },
    {
        id: '9915',
        ort: 'Rügge',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24405',
    },
    {
        id: '9916',
        ort: 'Rügland',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91622',
    },
    {
        id: '9917',
        ort: 'Rühen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38471',
    },
    {
        id: '9918',
        ort: 'Ruhla',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '99842',
    },
    {
        id: '9919',
        ort: 'Ruhland',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01945',
    },
    {
        id: '9920',
        ort: 'Ruhmannsfelden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94239',
    },
    {
        id: '9921',
        ort: 'Rühn',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18246',
    },
    {
        id: '9922',
        ort: 'Ruhner Berge',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19376',
    },
    {
        id: '9923',
        ort: 'Ruhpolding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83324',
    },
    {
        id: '9924',
        ort: 'Rühstädt',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '19322',
    },
    {
        id: '9925',
        ort: 'Ruhstorf a.d.Rott',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94099',
    },
    {
        id: '9926',
        ort: 'Ruhwinkel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24601',
    },
    {
        id: '9927',
        ort: 'Rukieten',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18258',
    },
    {
        id: '9928',
        ort: 'Rullstorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21379',
    },
    {
        id: '9929',
        ort: 'Rülzheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76761',
    },
    {
        id: '9930',
        ort: 'Rumbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '76891',
    },
    {
        id: '9931',
        ort: 'Rümmelsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55452',
    },
    {
        id: '9932',
        ort: 'Rümmingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79595',
    },
    {
        id: '9933',
        ort: 'Rumohr',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24254',
    },
    {
        id: '9934',
        ort: 'Rümpel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23843',
    },
    {
        id: '9935',
        ort: 'Runding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93486',
    },
    {
        id: '9936',
        ort: 'Runkel',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65594',
    },
    {
        id: '9937',
        ort: 'Ruppach-Goldhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '9938',
        ort: 'Ruppertsberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67152',
    },
    {
        id: '9939',
        ort: 'Ruppertsecken',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67808',
    },
    {
        id: '9940',
        ort: 'Ruppertshofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '9941',
        ort: 'Ruppertshofen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73577',
    },
    {
        id: '9942',
        ort: 'Ruppertsweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66957',
    },
    {
        id: '9943',
        ort: 'Ruppichteroth',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53809',
    },
    {
        id: '9944',
        ort: 'Ruschberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55776',
    },
    {
        id: '9945',
        ort: 'Rüscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56584',
    },
    {
        id: '9946',
        ort: 'Rüsselsheim am Main',
        bundesland: 'Hessen',
        landkreis: 'Kreis Groß-Gerau',
        plz: '65428',
    },
    {
        id: '9947',
        ort: 'Rüssingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67308',
    },
    {
        id: '9948',
        ort: 'Rust',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77977',
    },
    {
        id: '9949',
        ort: 'Rustenfelde',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '9950',
        ort: 'Rutesheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71277',
    },
    {
        id: '9951',
        ort: 'Rüthen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Soest',
        plz: '59602',
    },
    {
        id: '9952',
        ort: 'Rüthnick',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16835',
    },
    {
        id: '9953',
        ort: 'Ruthweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66869',
    },
    {
        id: '9954',
        ort: 'Rüting',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23936',
    },
    {
        id: '9955',
        ort: 'Rutsweiler am Glan',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66887',
    },
    {
        id: '9956',
        ort: 'Rutsweiler an der Lauter',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67752',
    },
    {
        id: '9957',
        ort: 'Ruttersdorf-Lotschen',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '9958',
        ort: 'Saal',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18317',
    },
    {
        id: '9959',
        ort: 'Saal a.d.Donau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '93342',
    },
    {
        id: '9960',
        ort: 'Saal a.d.Saale',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97633',
    },
    {
        id: '9961',
        ort: 'Saalburg-Ebersdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07929',
    },
    {
        id: '9962',
        ort: 'Saaldorf-Surheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Berchtesgadener Land',
        plz: '83416',
    },
    {
        id: '9963',
        ort: 'Saalfeld/Saale',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07318',
    },
    {
        id: '9964',
        ort: 'Saalstadt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66919',
    },
    {
        id: '9965',
        ort: 'Saara',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07589',
    },
    {
        id: '9966',
        ort: 'Saarbrücken',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66111',
    },
    {
        id: '9967',
        ort: 'Saarbrücken',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66113',
    },
    {
        id: '9968',
        ort: 'Saarbrücken',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66115',
    },
    {
        id: '9969',
        ort: 'Saarbrücken',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66117',
    },
    {
        id: '9970',
        ort: 'Saarbrücken',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66119',
    },
    {
        id: '9971',
        ort: 'Saarbrücken',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66121',
    },
    {
        id: '9972',
        ort: 'Saarbrücken',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66123',
    },
    {
        id: '9973',
        ort: 'Saarbrücken',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66125',
    },
    {
        id: '9974',
        ort: 'Saarbrücken',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66126',
    },
    {
        id: '9975',
        ort: 'Saarbrücken',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66127',
    },
    {
        id: '9976',
        ort: 'Saarbrücken',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66128',
    },
    {
        id: '9977',
        ort: 'Saarbrücken',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66129',
    },
    {
        id: '9978',
        ort: 'Saarbrücken',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66130',
    },
    {
        id: '9979',
        ort: 'Saarbrücken',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66131',
    },
    {
        id: '9980',
        ort: 'Saarbrücken',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66132',
    },
    {
        id: '9981',
        ort: 'Saarbrücken',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66133',
    },
    {
        id: '9982',
        ort: 'Saarburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54439',
    },
    {
        id: '9983',
        ort: 'Saarlouis',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Saarlouis',
        plz: '66740',
    },
    {
        id: '9984',
        ort: 'Saarwellingen',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Saarlouis',
        plz: '66793',
    },
    {
        id: '9985',
        ort: 'Sachsen bei Ansbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91623',
    },
    {
        id: '9986',
        ort: 'Sachsenhagen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31553',
    },
    {
        id: '9987',
        ort: 'Sachsenheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '74343',
    },
    {
        id: '9988',
        ort: 'Sachsenkam',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '83679',
    },
    {
        id: '9989',
        ort: 'Saerbeck',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '48369',
    },
    {
        id: '9990',
        ort: 'Saffig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56648',
    },
    {
        id: '9991',
        ort: 'Sagard',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18551',
    },
    {
        id: '9992',
        ort: 'Sahms',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21493',
    },
    {
        id: '9993',
        ort: 'Sailauf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63877',
    },
    {
        id: '9994',
        ort: 'Salach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73084',
    },
    {
        id: '9995',
        ort: 'Salching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94330',
    },
    {
        id: '9996',
        ort: 'Saldenburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94163',
    },
    {
        id: '9997',
        ort: 'Salem',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23883',
    },
    {
        id: '9998',
        ort: 'Salem',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23911',
    },
    {
        id: '9999',
        ort: 'Salem',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88682',
    },
    {
        id: '10000',
        ort: 'Salgen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87775',
    },
    {
        id: '10001',
        ort: 'Sallgast',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '03238',
    },
    {
        id: '10002',
        ort: 'Salm',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '10003',
        ort: 'Salmtal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54528',
    },
    {
        id: '10004',
        ort: 'Salz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97616',
    },
    {
        id: '10005',
        ort: 'Salz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56414',
    },
    {
        id: '10006',
        ort: 'Salzatal',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06198',
    },
    {
        id: '10007',
        ort: 'Salzbergen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '48499',
    },
    {
        id: '10008',
        ort: 'Salzburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '10009',
        ort: 'Salzgitter',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38226',
    },
    {
        id: '10010',
        ort: 'Salzgitter',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38228',
    },
    {
        id: '10011',
        ort: 'Salzgitter',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38229',
    },
    {
        id: '10012',
        ort: 'Salzgitter',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38239',
    },
    {
        id: '10013',
        ort: 'Salzgitter',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38259',
    },
    {
        id: '10014',
        ort: 'Salzhausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21376',
    },
    {
        id: '10015',
        ort: 'Salzhemmendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hameln-Pyrmont',
        plz: '31020',
    },
    {
        id: '10016',
        ort: 'Salzkotten',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Paderborn',
        plz: '33154',
    },
    {
        id: '10017',
        ort: 'Salzwedel',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Altmarkkreis Salzwedel',
        plz: '29410',
    },
    {
        id: '10018',
        ort: 'Salzweg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94121',
    },
    {
        id: '10019',
        ort: 'Samerberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83122',
    },
    {
        id: '10020',
        ort: 'Samern',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '48465',
    },
    {
        id: '10021',
        ort: 'Samtens',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18573',
    },
    {
        id: '10022',
        ort: 'Sand a. Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '97522',
    },
    {
        id: '10023',
        ort: 'Sandau (Elbe)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39524',
    },
    {
        id: '10024',
        ort: 'Sandberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97657',
    },
    {
        id: '10025',
        ort: 'Sandbostel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27446',
    },
    {
        id: '10026',
        ort: 'Sande',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Friesland',
        plz: '26452',
    },
    {
        id: '10027',
        ort: 'Sandersdorf-Brehna',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06792',
    },
    {
        id: '10028',
        ort: 'Sandersdorf-Brehna',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06794',
    },
    {
        id: '10029',
        ort: 'Sandersdorf-Brehna',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06796',
    },
    {
        id: '10030',
        ort: 'Sandersdorf-Brehna',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06809',
    },
    {
        id: '10031',
        ort: 'Sandesneben',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23898',
    },
    {
        id: '10032',
        ort: 'Sandhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69207',
    },
    {
        id: '10033',
        ort: 'Sangerhausen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06526',
    },
    {
        id: '10034',
        ort: 'Sanitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18190',
    },
    {
        id: '10035',
        ort: 'Sankt Alban',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67813',
    },
    {
        id: '10036',
        ort: 'Sankt Alban',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67822',
    },
    {
        id: '10037',
        ort: 'Sankt Aldegund',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56858',
    },
    {
        id: '10038',
        ort: 'Sankt Annen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25776',
    },
    {
        id: '10039',
        ort: 'Sankt Augustin',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53757',
    },
    {
        id: '10040',
        ort: 'Sankt Bernhard',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98660',
    },
    {
        id: '10041',
        ort: 'Sankt Englmar',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94379',
    },
    {
        id: '10042',
        ort: 'Sankt Goar',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56329',
    },
    {
        id: '10043',
        ort: 'Sankt Goarshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56346',
    },
    {
        id: '10044',
        ort: 'Sankt Ingbert',
        bundesland: 'Saarland',
        landkreis: 'Saarpfalz-Kreis',
        plz: '66386',
    },
    {
        id: '10045',
        ort: 'Sankt Johann',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56727',
    },
    {
        id: '10046',
        ort: 'Sankt Johann',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55578',
    },
    {
        id: '10047',
        ort: 'Sankt Julian',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66887',
    },
    {
        id: '10048',
        ort: 'Sankt Katharinen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53562',
    },
    {
        id: '10049',
        ort: 'Sankt Katharinen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '10050',
        ort: 'Sankt Margarethen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25572',
    },
    {
        id: '10051',
        ort: 'Sankt Martin',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '67487',
    },
    {
        id: '10052',
        ort: 'Sankt Michaelisdonn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25693',
    },
    {
        id: '10053',
        ort: 'Sankt Oswald-Riedlhütte',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94566',
    },
    {
        id: '10054',
        ort: 'Sankt Oswald-Riedlhütte',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94568',
    },
    {
        id: '10055',
        ort: 'Sankt Peter-Ording',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25826',
    },
    {
        id: '10056',
        ort: 'Sankt Sebastian',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56220',
    },
    {
        id: '10057',
        ort: 'Sankt Thomas',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54655',
    },
    {
        id: '10058',
        ort: 'Sankt Wendel',
        bundesland: 'Saarland',
        landkreis: 'Landkreis St. Wendel',
        plz: '66606',
    },
    {
        id: '10059',
        ort: 'Sankt Wolfgang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '84427',
    },
    {
        id: '10060',
        ort: 'Sargenroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55471',
    },
    {
        id: '10061',
        ort: 'Sarlhusen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '24616',
    },
    {
        id: '10062',
        ort: 'Sarmersbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '10063',
        ort: 'Sarmstorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18276',
    },
    {
        id: '10064',
        ort: 'Sarnow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17392',
    },
    {
        id: '10065',
        ort: 'Sarow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17111',
    },
    {
        id: '10066',
        ort: 'Sarstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31157',
    },
    {
        id: '10067',
        ort: 'Sarzbüttel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25785',
    },
    {
        id: '10068',
        ort: 'Sasbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77880',
    },
    {
        id: '10069',
        ort: 'Sasbach am Kaiserstuhl',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79361',
    },
    {
        id: '10070',
        ort: 'Sasbachwalden',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77887',
    },
    {
        id: '10071',
        ort: 'Sassen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '56767',
    },
    {
        id: '10072',
        ort: 'Sassenberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Warendorf',
        plz: '48336',
    },
    {
        id: '10073',
        ort: 'Sassenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38524',
    },
    {
        id: '10074',
        ort: 'Sassen-Trantow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17121',
    },
    {
        id: '10075',
        ort: 'Sassnitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18546',
    },
    {
        id: '10076',
        ort: 'Saterland - Seelterlound',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cloppenburg',
        plz: '26683',
    },
    {
        id: '10077',
        ort: 'Satow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18239',
    },
    {
        id: '10078',
        ort: 'Satteldorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74589',
    },
    {
        id: '10079',
        ort: 'Sauensiek',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21644',
    },
    {
        id: '10080',
        ort: 'Sauerlach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '82054',
    },
    {
        id: '10081',
        ort: 'Sauerthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65391',
    },
    {
        id: '10082',
        ort: 'Sauldorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '88605',
    },
    {
        id: '10083',
        ort: 'Saulgrub',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82442',
    },
    {
        id: '10084',
        ort: 'Saulheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55291',
    },
    {
        id: '10085',
        ort: 'Saustrup',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24392',
    },
    {
        id: '10086',
        ort: 'Sauzin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17440',
    },
    {
        id: '10087',
        ort: 'Saxler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54558',
    },
    {
        id: '10088',
        ort: 'Sayda',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09619',
    },
    {
        id: '10089',
        ort: 'Schaafheim',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64850',
    },
    {
        id: '10090',
        ort: 'Schaalby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24882',
    },
    {
        id: '10091',
        ort: 'Schacht-Audorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24790',
    },
    {
        id: '10092',
        ort: 'Schacht-Audorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24794',
    },
    {
        id: '10093',
        ort: 'Schachtebich',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '10094',
        ort: 'Schackendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23795',
    },
    {
        id: '10095',
        ort: 'Schafflund',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24980',
    },
    {
        id: '10096',
        ort: 'Schafstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25725',
    },
    {
        id: '10097',
        ort: 'Schäftlarn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '82067',
    },
    {
        id: '10098',
        ort: 'Schäftlarn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '82069',
    },
    {
        id: '10099',
        ort: 'Schalkau',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sonneberg',
        plz: '96528',
    },
    {
        id: '10100',
        ort: 'Schalkenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53426',
    },
    {
        id: '10101',
        ort: 'Schalkenmehren',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '10102',
        ort: 'Schalkham',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84175',
    },
    {
        id: '10103',
        ort: 'Schalkholz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25782',
    },
    {
        id: '10104',
        ort: 'Schalksmühle',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58579',
    },
    {
        id: '10105',
        ort: 'Schallbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79597',
    },
    {
        id: '10106',
        ort: 'Schallodenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67701',
    },
    {
        id: '10107',
        ort: 'Schallstadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79227',
    },
    {
        id: '10108',
        ort: 'Schankweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54668',
    },
    {
        id: '10109',
        ort: 'Schapen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '48480',
    },
    {
        id: '10110',
        ort: 'Schaprode',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18569',
    },
    {
        id: '10111',
        ort: 'Scharbeutz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23629',
    },
    {
        id: '10112',
        ort: 'Scharbeutz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23683',
    },
    {
        id: '10113',
        ort: 'Scharbeutz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23684',
    },
    {
        id: '10114',
        ort: 'Scharfbillig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '10115',
        ort: 'Scharnebeck',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21379',
    },
    {
        id: '10116',
        ort: 'Schashagen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23730',
    },
    {
        id: '10117',
        ort: 'Schauenburg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34270',
    },
    {
        id: '10118',
        ort: 'Schauenstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95197',
    },
    {
        id: '10119',
        ort: 'Schauerberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66919',
    },
    {
        id: '10120',
        ort: 'Schaufling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94571',
    },
    {
        id: '10121',
        ort: 'Schauren',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '10122',
        ort: 'Schauren',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56865',
    },
    {
        id: '10123',
        ort: 'Schechen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83135',
    },
    {
        id: '10124',
        ort: 'Schechingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73579',
    },
    {
        id: '10125',
        ort: 'Scheden',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37127',
    },
    {
        id: '10126',
        ort: 'Scheer',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '72516',
    },
    {
        id: '10127',
        ort: 'Scheeßel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27383',
    },
    {
        id: '10128',
        ort: 'Schefflenz',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74850',
    },
    {
        id: '10129',
        ort: 'Scheggerott',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24392',
    },
    {
        id: '10130',
        ort: 'Scheibenberg',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09481',
    },
    {
        id: '10131',
        ort: 'Scheibenhardt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76779',
    },
    {
        id: '10132',
        ort: 'Scheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54611',
    },
    {
        id: '10133',
        ort: 'Scheidegg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88175',
    },
    {
        id: '10134',
        ort: 'Scheiditz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '10135',
        ort: 'Scheidt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56379',
    },
    {
        id: '10136',
        ort: 'Scheinfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91443',
    },
    {
        id: '10137',
        ort: 'Scheitenkorb',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '10138',
        ort: 'Schelklingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89601',
    },
    {
        id: '10139',
        ort: 'Schellerten',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31174',
    },
    {
        id: '10140',
        ort: 'Schellhorn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24211',
    },
    {
        id: '10141',
        ort: 'Schellweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66869',
    },
    {
        id: '10142',
        ort: 'Schemmerhofen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88433',
    },
    {
        id: '10143',
        ort: 'Schenefeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '22869',
    },
    {
        id: '10144',
        ort: 'Schenefeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25560',
    },
    {
        id: '10145',
        ort: 'Schenkelberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '10146',
        ort: 'Schenkenberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17291',
    },
    {
        id: '10147',
        ort: 'Schenkendöbern',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03172',
    },
    {
        id: '10148',
        ort: 'Schenkenzell',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '77773',
    },
    {
        id: '10149',
        ort: 'Schenklengsfeld',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36277',
    },
    {
        id: '10150',
        ort: 'Schermbeck',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '46514',
    },
    {
        id: '10151',
        ort: 'Schernfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85132',
    },
    {
        id: '10152',
        ort: 'Scherstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86872',
    },
    {
        id: '10153',
        ort: 'Scheßlitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96110',
    },
    {
        id: '10154',
        ort: 'Scheuerfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57584',
    },
    {
        id: '10155',
        ort: 'Scheuern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '10156',
        ort: 'Scheuring',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86937',
    },
    {
        id: '10157',
        ort: 'Scheyern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85298',
    },
    {
        id: '10158',
        ort: 'Schieder-Schwalenberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '32816',
    },
    {
        id: '10159',
        ort: 'Schieren',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23795',
    },
    {
        id: '10160',
        ort: 'Schierensee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24241',
    },
    {
        id: '10161',
        ort: 'Schierling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '84069',
    },
    {
        id: '10162',
        ort: 'Schiersfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67808',
    },
    {
        id: '10163',
        ort: 'Schiersfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67823',
    },
    {
        id: '10164',
        ort: 'Schiesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '65623',
    },
    {
        id: '10165',
        ort: 'Schiffdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '27619',
    },
    {
        id: '10166',
        ort: 'Schifferstadt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67105',
    },
    {
        id: '10167',
        ort: 'Schiffweiler',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Neunkirchen',
        plz: '66578',
    },
    {
        id: '10168',
        ort: 'Schilda',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '03253',
    },
    {
        id: '10169',
        ort: 'Schildetal',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19209',
    },
    {
        id: '10170',
        ort: 'Schillingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54429',
    },
    {
        id: '10171',
        ort: 'Schillingsfürst',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91583',
    },
    {
        id: '10172',
        ort: 'Schillsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24637',
    },
    {
        id: '10173',
        ort: 'Schiltach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '77761',
    },
    {
        id: '10174',
        ort: 'Schiltberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86576',
    },
    {
        id: '10175',
        ort: 'Schimberg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37308',
    },
    {
        id: '10176',
        ort: 'Schindhard',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66996',
    },
    {
        id: '10177',
        ort: 'Schinkel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24214',
    },
    {
        id: '10178',
        ort: 'Schiphorst',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23847',
    },
    {
        id: '10179',
        ort: 'Schipkau',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01968',
    },
    {
        id: '10180',
        ort: 'Schipkau',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01993',
    },
    {
        id: '10181',
        ort: 'Schipkau',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01994',
    },
    {
        id: '10182',
        ort: 'Schipkau',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01998',
    },
    {
        id: '10183',
        ort: 'Schirgiswalde-Kirschau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02681',
    },
    {
        id: '10184',
        ort: 'Schirmitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92718',
    },
    {
        id: '10185',
        ort: 'Schirnding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Wunsiedel im Fichtelgebirge',
        plz: '95706',
    },
    {
        id: '10186',
        ort: 'Schkeuditz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04435',
    },
    {
        id: '10187',
        ort: 'Schkölen',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07619',
    },
    {
        id: '10188',
        ort: 'Schkopau',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06258',
    },
    {
        id: '10189',
        ort: 'Schladen-Werla',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38315',
    },
    {
        id: '10190',
        ort: 'Schladt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54534',
    },
    {
        id: '10191',
        ort: 'Schlagsdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19217',
    },
    {
        id: '10192',
        ort: 'Schlaitdorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '72667',
    },
    {
        id: '10193',
        ort: 'Schlammersdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '95519',
    },
    {
        id: '10194',
        ort: 'Schlangen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Lippe',
        plz: '33189',
    },
    {
        id: '10195',
        ort: 'Schlangenbad',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65388',
    },
    {
        id: '10196',
        ort: 'Schlat',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73114',
    },
    {
        id: '10197',
        ort: 'Schlaubetal',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15890',
    },
    {
        id: '10198',
        ort: 'Schleching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83259',
    },
    {
        id: '10199',
        ort: 'Schlechtsart',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98663',
    },
    {
        id: '10200',
        ort: 'Schlehdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '82439',
    },
    {
        id: '10201',
        ort: 'Schlehdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '82444',
    },
    {
        id: '10202',
        ort: 'Schleich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54340',
    },
    {
        id: '10203',
        ort: 'Schleid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '10204',
        ort: 'Schleid',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '36419',
    },
    {
        id: '10205',
        ort: 'Schleiden',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Euskirchen',
        plz: '53937',
    },
    {
        id: '10206',
        ort: 'Schleife - Slepo',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02959',
    },
    {
        id: '10207',
        ort: 'Schleifreisen',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07629',
    },
    {
        id: '10208',
        ort: 'Schleiz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07907',
    },
    {
        id: '10209',
        ort: 'Schlemmin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18320',
    },
    {
        id: '10210',
        ort: 'Schlepzig',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15910',
    },
    {
        id: '10211',
        ort: 'Schlesen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24256',
    },
    {
        id: '10212',
        ort: 'Schleswig',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24837',
    },
    {
        id: '10213',
        ort: 'Schlettau',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09487',
    },
    {
        id: '10214',
        ort: 'Schleusegrund',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98666',
    },
    {
        id: '10215',
        ort: 'Schleusegrund',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98667',
    },
    {
        id: '10216',
        ort: 'Schleusingen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98553',
    },
    {
        id: '10217',
        ort: 'Schlichting',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25779',
    },
    {
        id: '10218',
        ort: 'Schlieben',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04936',
    },
    {
        id: '10219',
        ort: 'Schliengen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79418',
    },
    {
        id: '10220',
        ort: 'Schlier',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88281',
    },
    {
        id: '10221',
        ort: 'Schlierbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73278',
    },
    {
        id: '10222',
        ort: 'Schlierschied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55483',
    },
    {
        id: '10223',
        ort: 'Schliersee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miesbach',
        plz: '83727',
    },
    {
        id: '10224',
        ort: 'Schlitz',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '36110',
    },
    {
        id: '10225',
        ort: 'Schlöben',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '10226',
        ort: 'Schloen-Dratow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17192',
    },
    {
        id: '10227',
        ort: 'Schloen-Dratow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17219',
    },
    {
        id: '10228',
        ort: 'Schloßböckelheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55596',
    },
    {
        id: '10229',
        ort: 'Schloß Holte-Stukenbrock',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Gütersloh',
        plz: '33758',
    },
    {
        id: '10230',
        ort: 'Schloßvippach',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99195',
    },
    {
        id: '10231',
        ort: 'Schlotfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25551',
    },
    {
        id: '10232',
        ort: 'Schluchsee',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79859',
    },
    {
        id: '10233',
        ort: 'Schlüchtern',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '36381',
    },
    {
        id: '10234',
        ort: 'Schlüsselfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96132',
    },
    {
        id: '10235',
        ort: 'Schmalenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '67718',
    },
    {
        id: '10236',
        ort: 'Schmalensee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24638',
    },
    {
        id: '10237',
        ort: 'Schmalfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24640',
    },
    {
        id: '10238',
        ort: 'Schmalkalden',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98574',
    },
    {
        id: '10239',
        ort: 'Schmalkalden',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98587',
    },
    {
        id: '10240',
        ort: 'Schmallenberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Hochsauerlandkreis',
        plz: '57392',
    },
    {
        id: '10241',
        ort: 'Schmalstede',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24241',
    },
    {
        id: '10242',
        ort: 'Schmatzin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17390',
    },
    {
        id: '10243',
        ort: 'Schmedeswurth',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25724',
    },
    {
        id: '10244',
        ort: 'Schmeheim',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98530',
    },
    {
        id: '10245',
        ort: 'Schmelz',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Saarlouis',
        plz: '66839',
    },
    {
        id: '10246',
        ort: 'Schmidgaden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92546',
    },
    {
        id: '10247',
        ort: 'Schmidmühlen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92287',
    },
    {
        id: '10248',
        ort: 'Schmidthachenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '10249',
        ort: 'Schmiechen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86511',
    },
    {
        id: '10250',
        ort: 'Schmiedehausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99518',
    },
    {
        id: '10251',
        ort: 'Schmieritz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07819',
    },
    {
        id: '10252',
        ort: 'Schmilau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23883',
    },
    {
        id: '10253',
        ort: 'Schmilau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23909',
    },
    {
        id: '10254',
        ort: 'Schmilau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23911',
    },
    {
        id: '10255',
        ort: 'Schmißberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55765',
    },
    {
        id: '10256',
        ort: 'Schmitshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66484',
    },
    {
        id: '10257',
        ort: 'Schmitshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66917',
    },
    {
        id: '10258',
        ort: 'Schmitt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56825',
    },
    {
        id: '10259',
        ort: 'Schmitten im Taunus',
        bundesland: 'Hessen',
        landkreis: 'Hochtaunuskreis',
        plz: '61389',
    },
    {
        id: '10260',
        ort: 'Schmittweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '67829',
    },
    {
        id: '10261',
        ort: 'Schmogrow-Fehrow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03096',
    },
    {
        id: '10262',
        ort: 'Schmölln',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04626',
    },
    {
        id: '10263',
        ort: 'Schmölln-Putzkau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01877',
    },
    {
        id: '10264',
        ort: 'Schmorda',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07389',
    },
    {
        id: '10265',
        ort: 'Schnabelwaid',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '91289',
    },
    {
        id: '10266',
        ort: 'Schnackenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29493',
    },
    {
        id: '10267',
        ort: 'Schnaitsee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83530',
    },
    {
        id: '10268',
        ort: 'Schnaittach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91220',
    },
    {
        id: '10269',
        ort: 'Schnaittenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92253',
    },
    {
        id: '10270',
        ort: 'Schnakenbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21481',
    },
    {
        id: '10271',
        ort: 'Schnarup-Thumby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24891',
    },
    {
        id: '10272',
        ort: 'Schnaudertal',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06712',
    },
    {
        id: '10273',
        ort: 'Schneckenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67699',
    },
    {
        id: '10274',
        ort: 'Schneckenlohe',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kronach',
        plz: '96277',
    },
    {
        id: '10275',
        ort: 'Schneeberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63936',
    },
    {
        id: '10276',
        ort: 'Schneeberg',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '08289',
    },
    {
        id: '10277',
        ort: 'Schnega',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29465',
    },
    {
        id: '10278',
        ort: 'Schneizlreuth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Berchtesgadener Land',
        plz: '83458',
    },
    {
        id: '10279',
        ort: 'Schnelldorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91625',
    },
    {
        id: '10280',
        ort: 'Schneppenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55608',
    },
    {
        id: '10281',
        ort: 'Schneverdingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29640',
    },
    {
        id: '10282',
        ort: 'Schnorbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55497',
    },
    {
        id: '10283',
        ort: 'Schnürpflingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89194',
    },
    {
        id: '10284',
        ort: 'Schoden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54441',
    },
    {
        id: '10285',
        ort: 'Schöffengrund',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35641',
    },
    {
        id: '10286',
        ort: 'Schöfweg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94572',
    },
    {
        id: '10287',
        ort: 'Scholen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27251',
    },
    {
        id: '10288',
        ort: 'Schollbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97852',
    },
    {
        id: '10289',
        ort: 'Schollbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97907',
    },
    {
        id: '10290',
        ort: 'Schollene',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '14715',
    },
    {
        id: '10291',
        ort: 'Schöllkrippen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63825',
    },
    {
        id: '10292',
        ort: 'Schöllnach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94508',
    },
    {
        id: '10293',
        ort: 'Schömberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '75328',
    },
    {
        id: '10294',
        ort: 'Schömberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72355',
    },
    {
        id: '10295',
        ort: 'Schömerich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54314',
    },
    {
        id: '10296',
        ort: 'Schonach im Schwarzwald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78136',
    },
    {
        id: '10297',
        ort: 'Schönaich',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71101',
    },
    {
        id: '10298',
        ort: 'Schönau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69250',
    },
    {
        id: '10299',
        ort: 'Schönau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84337',
    },
    {
        id: '10300',
        ort: 'Schönau a.d.Brend',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97659',
    },
    {
        id: '10301',
        ort: 'Schönau am Königssee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Berchtesgadener Land',
        plz: '83471',
    },
    {
        id: '10302',
        ort: 'Schönau-Berzdorf auf dem Eigen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02899',
    },
    {
        id: '10303',
        ort: 'Schönau im Schwarzwald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79677',
    },
    {
        id: '10304',
        ort: 'Schönau (Pfalz)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66996',
    },
    {
        id: '10305',
        ort: 'Schönbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '10306',
        ort: 'Schönbach',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02708',
    },
    {
        id: '10307',
        ort: 'Schönbeck',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17349',
    },
    {
        id: '10308',
        ort: 'Schönbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24582',
    },
    {
        id: '10309',
        ort: 'Schönberg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08393',
    },
    {
        id: '10310',
        ort: 'Schönberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84573',
    },
    {
        id: '10311',
        ort: 'Schönberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94513',
    },
    {
        id: '10312',
        ort: 'Schönberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54426',
    },
    {
        id: '10313',
        ort: 'Schönberg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '22929',
    },
    {
        id: '10314',
        ort: 'Schönberg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23923',
    },
    {
        id: '10315',
        ort: 'Schönberg (Holstein)',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24217',
    },
    {
        id: '10316',
        ort: 'Schönborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56370',
    },
    {
        id: '10317',
        ort: 'Schönborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55469',
    },
    {
        id: '10318',
        ort: 'Schönborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67808',
    },
    {
        id: '10319',
        ort: 'Schönborn',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '03253',
    },
    {
        id: '10320',
        ort: 'Schönbrunn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69436',
    },
    {
        id: '10321',
        ort: 'Schönbrunn i.Steigerwald',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96185',
    },
    {
        id: '10322',
        ort: 'Schönburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06618',
    },
    {
        id: '10323',
        ort: 'Schöndorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54316',
    },
    {
        id: '10324',
        ort: 'Schöndorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07924',
    },
    {
        id: '10325',
        ort: 'Schondorf am Ammersee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86938',
    },
    {
        id: '10326',
        ort: 'Schondra',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97795',
    },
    {
        id: '10327',
        ort: 'Schönebeck (Elbe)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '39217',
    },
    {
        id: '10328',
        ort: 'Schönebeck (Elbe)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '39218',
    },
    {
        id: '10329',
        ort: 'Schöneberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57638',
    },
    {
        id: '10330',
        ort: 'Schöneberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55444',
    },
    {
        id: '10331',
        ort: 'Schöneck',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '61137',
    },
    {
        id: '10332',
        ort: 'Schönecken',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54614',
    },
    {
        id: '10333',
        ort: 'Schöneck/Vogtl.',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08261',
    },
    {
        id: '10334',
        ort: 'Schönefeld',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '12529',
    },
    {
        id: '10335',
        ort: 'Schöneiche bei Berlin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15566',
    },
    {
        id: '10336',
        ort: 'Schönenberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79677',
    },
    {
        id: '10337',
        ort: 'Schönenberg-Kübelberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66901',
    },
    {
        id: '10338',
        ort: 'Schönermark',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16775',
    },
    {
        id: '10339',
        ort: 'Schönewalde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04916',
    },
    {
        id: '10340',
        ort: 'Schönewörde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '29396',
    },
    {
        id: '10341',
        ort: 'Schönfeld',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01561',
    },
    {
        id: '10342',
        ort: 'Schönfeld',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17291',
    },
    {
        id: '10343',
        ort: 'Schönfeld',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17111',
    },
    {
        id: '10344',
        ort: 'Schongau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '86956',
    },
    {
        id: '10345',
        ort: 'Schöngeising',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82296',
    },
    {
        id: '10346',
        ort: 'Schöngleina',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '10347',
        ort: 'Schönhagen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '10348',
        ort: 'Schönhausen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17337',
    },
    {
        id: '10349',
        ort: 'Schönhausen (Elbe)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39524',
    },
    {
        id: '10350',
        ort: 'Schönheide',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '08304',
    },
    {
        id: '10351',
        ort: 'Schönhorst',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24220',
    },
    {
        id: '10352',
        ort: 'Schöningen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38364',
    },
    {
        id: '10353',
        ort: 'Schönkirchen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24232',
    },
    {
        id: '10354',
        ort: 'Schönsee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92539',
    },
    {
        id: '10355',
        ort: 'Schönstedt',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99947',
    },
    {
        id: '10356',
        ort: 'Schonstett',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83137',
    },
    {
        id: '10357',
        ort: 'Schöntal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Hohenlohekreis',
        plz: '74214',
    },
    {
        id: '10358',
        ort: 'Schönthal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93488',
    },
    {
        id: '10359',
        ort: 'Schonungen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97453',
    },
    {
        id: '10360',
        ort: 'Schönwald',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Wunsiedel im Fichtelgebirge',
        plz: '95173',
    },
    {
        id: '10361',
        ort: 'Schönwald',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15910',
    },
    {
        id: '10362',
        ort: 'Schönwalde',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17309',
    },
    {
        id: '10363',
        ort: 'Schönwalde am Bungsberg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23744',
    },
    {
        id: '10364',
        ort: 'Schönwalde-Glien',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14621',
    },
    {
        id: '10365',
        ort: 'Schönwald im Schwarzwald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78141',
    },
    {
        id: '10366',
        ort: 'Schönwölkau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04509',
    },
    {
        id: '10367',
        ort: 'Schopfheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79650',
    },
    {
        id: '10368',
        ort: 'Schopfloch',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91626',
    },
    {
        id: '10369',
        ort: 'Schopfloch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Freudenstadt',
        plz: '72296',
    },
    {
        id: '10370',
        ort: 'Schopp',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67707',
    },
    {
        id: '10371',
        ort: 'Schöppenstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38170',
    },
    {
        id: '10372',
        ort: 'Schöppingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '48624',
    },
    {
        id: '10373',
        ort: 'Schöps',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07768',
    },
    {
        id: '10374',
        ort: 'Schöpstal',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02829',
    },
    {
        id: '10375',
        ort: 'Schorfheide',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16244',
    },
    {
        id: '10376',
        ort: 'Schorndorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '73614',
    },
    {
        id: '10377',
        ort: 'Schorndorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93489',
    },
    {
        id: '10378',
        ort: 'Schornsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55288',
    },
    {
        id: '10379',
        ort: 'Schorssow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17166',
    },
    {
        id: '10380',
        ort: 'Schortens',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Friesland',
        plz: '26419',
    },
    {
        id: '10381',
        ort: 'Schossin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19073',
    },
    {
        id: '10382',
        ort: 'Schossin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19075',
    },
    {
        id: '10383',
        ort: 'Schotten',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '63679',
    },
    {
        id: '10384',
        ort: 'Schraden',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04928',
    },
    {
        id: '10385',
        ort: 'Schramberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78144',
    },
    {
        id: '10386',
        ort: 'Schramberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78713',
    },
    {
        id: '10387',
        ort: 'Schraplau',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06279',
    },
    {
        id: '10388',
        ort: 'Schrecksbach',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34637',
    },
    {
        id: '10389',
        ort: 'Schretstaken',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21493',
    },
    {
        id: '10390',
        ort: 'Schriesheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69198',
    },
    {
        id: '10391',
        ort: 'Schrobenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neuburg-Schrobenhausen',
        plz: '86529',
    },
    {
        id: '10392',
        ort: 'Schrozberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74575',
    },
    {
        id: '10393',
        ort: 'Schrum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25782',
    },
    {
        id: '10394',
        ort: 'Schuby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24837',
    },
    {
        id: '10395',
        ort: 'Schuby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24850',
    },
    {
        id: '10396',
        ort: 'Schuld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53520',
    },
    {
        id: '10397',
        ort: 'Schulendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21516',
    },
    {
        id: '10398',
        ort: 'Schülldorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24790',
    },
    {
        id: '10399',
        ort: 'Schüller',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54586',
    },
    {
        id: '10400',
        ort: 'Schülp',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25764',
    },
    {
        id: '10401',
        ort: 'Schülp bei Nortorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24589',
    },
    {
        id: '10402',
        ort: 'Schülp bei Rendsburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24768',
    },
    {
        id: '10403',
        ort: 'Schülp bei Rendsburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24797',
    },
    {
        id: '10404',
        ort: 'Schülp bei Rendsburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24813',
    },
    {
        id: '10405',
        ort: 'Schulzendorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15732',
    },
    {
        id: '10406',
        ort: 'Schürdt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57632',
    },
    {
        id: '10407',
        ort: 'Schürensöhlen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23847',
    },
    {
        id: '10408',
        ort: 'Schuttertal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77978',
    },
    {
        id: '10409',
        ort: 'Schutterwald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77746',
    },
    {
        id: '10410',
        ort: 'Schüttorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '48465',
    },
    {
        id: '10411',
        ort: 'Schutz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '10412',
        ort: 'Schutzbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57520',
    },
    {
        id: '10413',
        ort: 'Schwaan',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18258',
    },
    {
        id: '10414',
        ort: 'Schwaara',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07554',
    },
    {
        id: '10415',
        ort: 'Schwabach',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '91126',
    },
    {
        id: '10416',
        ort: 'Schwabbruck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '86986',
    },
    {
        id: '10417',
        ort: 'Schwabenheim an der Selz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55270',
    },
    {
        id: '10418',
        ort: 'Schwabhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '85247',
    },
    {
        id: '10419',
        ort: 'Schwabhausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99869',
    },
    {
        id: '10420',
        ort: 'Schwäbisch Gmünd',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73072',
    },
    {
        id: '10421',
        ort: 'Schwäbisch Gmünd',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73525',
    },
    {
        id: '10422',
        ort: 'Schwäbisch Gmünd',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73527',
    },
    {
        id: '10423',
        ort: 'Schwäbisch Gmünd',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73529',
    },
    {
        id: '10424',
        ort: 'Schwäbisch Gmünd',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73550',
    },
    {
        id: '10425',
        ort: 'Schwäbisch Gmünd',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73553',
    },
    {
        id: '10426',
        ort: 'Schwäbisch Hall',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74523',
    },
    {
        id: '10427',
        ort: 'Schwabmünchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86830',
    },
    {
        id: '10428',
        ort: 'Schwabsoien',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '86987',
    },
    {
        id: '10429',
        ort: 'Schwabstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25876',
    },
    {
        id: '10430',
        ort: 'Schwaförden',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27252',
    },
    {
        id: '10431',
        ort: 'Schwaig bei Nürnberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '90571',
    },
    {
        id: '10432',
        ort: 'Schwaigen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82445',
    },
    {
        id: '10433',
        ort: 'Schwaigern',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74193',
    },
    {
        id: '10434',
        ort: 'Schwaikheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71409',
    },
    {
        id: '10435',
        ort: 'Schwalbach',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Saarlouis',
        plz: '66773',
    },
    {
        id: '10436',
        ort: 'Schwalbach am Taunus',
        bundesland: 'Hessen',
        landkreis: 'Main-Taunus-Kreis',
        plz: '65824',
    },
    {
        id: '10437',
        ort: 'Schwall',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56281',
    },
    {
        id: '10438',
        ort: 'Schwallungen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98590',
    },
    {
        id: '10439',
        ort: 'Schwalmstadt',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34613',
    },
    {
        id: '10440',
        ort: 'Schwalmtal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Viersen',
        plz: '41366',
    },
    {
        id: '10441',
        ort: 'Schwalmtal',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '36318',
    },
    {
        id: '10442',
        ort: 'Schwanau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77963',
    },
    {
        id: '10443',
        ort: 'Schwandorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92421',
    },
    {
        id: '10444',
        ort: 'Schwanebeck',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '39397',
    },
    {
        id: '10445',
        ort: 'Schwanewede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osterholz',
        plz: '28790',
    },
    {
        id: '10446',
        ort: 'Schwanfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97523',
    },
    {
        id: '10447',
        ort: 'Schwangau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87645',
    },
    {
        id: '10448',
        ort: 'Schwanheide',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19258',
    },
    {
        id: '10449',
        ort: 'Schwanheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '76848',
    },
    {
        id: '10450',
        ort: 'Schwanstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Roth',
        plz: '90596',
    },
    {
        id: '10451',
        ort: 'Schwarme',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27327',
    },
    {
        id: '10452',
        ort: 'Schwarmstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29690',
    },
    {
        id: '10453',
        ort: 'Schwartbuck',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24257',
    },
    {
        id: '10454',
        ort: 'Schwarz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17252',
    },
    {
        id: '10455',
        ort: 'Schwarza',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98547',
    },
    {
        id: '10456',
        ort: 'Schwarzach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74869',
    },
    {
        id: '10457',
        ort: 'Schwarzach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94374',
    },
    {
        id: '10458',
        ort: 'Schwarzach am Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97359',
    },
    {
        id: '10459',
        ort: 'Schwarzach b.Nabburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92548',
    },
    {
        id: '10460',
        ort: 'Schwarzatal',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '98744',
    },
    {
        id: '10461',
        ort: 'Schwarzbach',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01945',
    },
    {
        id: '10462',
        ort: 'Schwarzbach',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07589',
    },
    {
        id: '10463',
        ort: 'Schwarzburg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07427',
    },
    {
        id: '10464',
        ort: 'Schwarzen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55481',
    },
    {
        id: '10465',
        ort: 'Schwarzenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92720',
    },
    {
        id: '10466',
        ort: 'Schwarzenbach a.d.Saale',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95126',
    },
    {
        id: '10467',
        ort: 'Schwarzenbach a.Wald',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95131',
    },
    {
        id: '10468',
        ort: 'Schwarzenbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21493',
    },
    {
        id: '10469',
        ort: 'Schwarzenberg/Erzgebirge',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '08340',
    },
    {
        id: '10470',
        ort: 'Schwarzenborn',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34639',
    },
    {
        id: '10471',
        ort: 'Schwarzenborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54533',
    },
    {
        id: '10472',
        ort: 'Schwarzenbruck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '90592',
    },
    {
        id: '10473',
        ort: 'Schwarzenfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92521',
    },
    {
        id: '10474',
        ort: 'Schwarzerden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55629',
    },
    {
        id: '10475',
        ort: 'Schwarzheide',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01987',
    },
    {
        id: '10476',
        ort: 'Schwarzhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92447',
    },
    {
        id: '10477',
        ort: 'Schwasdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17168',
    },
    {
        id: '10478',
        ort: 'Schwebheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97525',
    },
    {
        id: '10479',
        ort: 'Schwedelbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67685',
    },
    {
        id: '10480',
        ort: 'Schwedeneck',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24229',
    },
    {
        id: '10481',
        ort: 'Schwedt/Oder',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '16303',
    },
    {
        id: '10482',
        ort: 'Schwegenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '67365',
    },
    {
        id: '10483',
        ort: 'Schweich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54338',
    },
    {
        id: '10484',
        ort: 'Schweickershausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98663',
    },
    {
        id: '10485',
        ort: 'Schweigen-Rechtenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76889',
    },
    {
        id: '10486',
        ort: 'Schweighausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56377',
    },
    {
        id: '10487',
        ort: 'Schweighofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76889',
    },
    {
        id: '10488',
        ort: 'Schweindorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26556',
    },
    {
        id: '10489',
        ort: 'Schweinfurt',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '97421',
    },
    {
        id: '10490',
        ort: 'Schweinfurt',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '97422',
    },
    {
        id: '10491',
        ort: 'Schweinfurt',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '97424',
    },
    {
        id: '10492',
        ort: 'Schweinschied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '67744',
    },
    {
        id: '10493',
        ort: 'Schweisweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67808',
    },
    {
        id: '10494',
        ort: 'Schweitenkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85301',
    },
    {
        id: '10495',
        ort: 'Schweix',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66957',
    },
    {
        id: '10496',
        ort: 'Schwelm',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Ennepe-Ruhr-Kreis',
        plz: '58332',
    },
    {
        id: '10497',
        ort: 'Schwendi',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88477',
    },
    {
        id: '10498',
        ort: 'Schwenningen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89443',
    },
    {
        id: '10499',
        ort: 'Schwenningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '72477',
    },
    {
        id: '10500',
        ort: 'Schwentinental',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24222',
    },
    {
        id: '10501',
        ort: 'Schwentinental',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24223',
    },
    {
        id: '10502',
        ort: 'Schwepnitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01936',
    },
    {
        id: '10503',
        ort: 'Schweppenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55444',
    },
    {
        id: '10504',
        ort: 'Schwerbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55624',
    },
    {
        id: '10505',
        ort: 'Schwerin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: '',
        plz: '19053',
    },
    {
        id: '10506',
        ort: 'Schwerin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: '',
        plz: '19055',
    },
    {
        id: '10507',
        ort: 'Schwerin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: '',
        plz: '19057',
    },
    {
        id: '10508',
        ort: 'Schwerin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: '',
        plz: '19059',
    },
    {
        id: '10509',
        ort: 'Schwerin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: '',
        plz: '19061',
    },
    {
        id: '10510',
        ort: 'Schwerin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: '',
        plz: '19063',
    },
    {
        id: '10511',
        ort: 'Schwerin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15755',
    },
    {
        id: '10512',
        ort: 'Schweringen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '27324',
    },
    {
        id: '10513',
        ort: 'Schweringen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '27333',
    },
    {
        id: '10514',
        ort: 'Schwerinsdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26835',
    },
    {
        id: '10515',
        ort: 'Schwerstedt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99634',
    },
    {
        id: '10516',
        ort: 'Schwerte',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Unna',
        plz: '58239',
    },
    {
        id: '10517',
        ort: 'Schwesing',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25813',
    },
    {
        id: '10518',
        ort: 'Schwetzingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '68723',
    },
    {
        id: '10519',
        ort: 'Schwieberdingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71701',
    },
    {
        id: '10520',
        ort: 'Schwieberdingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71706',
    },
    {
        id: '10521',
        ort: 'Schwielochsee',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15913',
    },
    {
        id: '10522',
        ort: 'Schwielowsee',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14548',
    },
    {
        id: '10523',
        ort: 'Schwienau',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29593',
    },
    {
        id: '10524',
        ort: 'Schwifting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86940',
    },
    {
        id: '10525',
        ort: 'Schwindegg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84419',
    },
    {
        id: '10526',
        ort: 'Schwirzheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '10527',
        ort: 'Schwissel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23795',
    },
    {
        id: '10528',
        ort: 'Schwobfeld',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '10529',
        ort: 'Schwollen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '10530',
        ort: 'Schwörstadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79739',
    },
    {
        id: '10531',
        ort: 'Schwülper',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38179',
    },
    {
        id: '10532',
        ort: 'Sebnitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01855',
    },
    {
        id: '10533',
        ort: 'Seck',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '10534',
        ort: 'Seckach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74743',
    },
    {
        id: '10535',
        ort: 'Seddiner See',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14554',
    },
    {
        id: '10536',
        ort: 'Seebach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77889',
    },
    {
        id: '10537',
        ort: 'Seebach',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '99846',
    },
    {
        id: '10538',
        ort: 'Seeblick',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14715',
    },
    {
        id: '10539',
        ort: 'Seeburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37136',
    },
    {
        id: '10540',
        ort: 'Seedorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23823',
    },
    {
        id: '10541',
        ort: 'Seedorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27404',
    },
    {
        id: '10542',
        ort: 'Seedorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23883',
    },
    {
        id: '10543',
        ort: 'Seefeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '25557',
    },
    {
        id: '10544',
        ort: 'Seefeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Starnberg',
        plz: '82229',
    },
    {
        id: '10545',
        ort: 'Seeg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87637',
    },
    {
        id: '10546',
        ort: 'Seegebiet Mansfelder Land',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06317',
    },
    {
        id: '10547',
        ort: 'Seehausen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39615',
    },
    {
        id: '10548',
        ort: 'Seehausen am Staffelsee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82418',
    },
    {
        id: '10549',
        ort: 'Seeheim-Jugenheim',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64342',
    },
    {
        id: '10550',
        ort: 'Seehof',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19069',
    },
    {
        id: '10551',
        ort: 'Seekirch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88422',
    },
    {
        id: '10552',
        ort: 'Seeland',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '06449',
    },
    {
        id: '10553',
        ort: 'Seeland',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '06464',
    },
    {
        id: '10554',
        ort: 'Seeland',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '06466',
    },
    {
        id: '10555',
        ort: 'Seeland',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '06467',
    },
    {
        id: '10556',
        ort: 'Seeland',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '06469',
    },
    {
        id: '10557',
        ort: 'Seelbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77960',
    },
    {
        id: '10558',
        ort: 'Seelbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56377',
    },
    {
        id: '10559',
        ort: 'Seelbach bei Hamm (Sieg)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57577',
    },
    {
        id: '10560',
        ort: 'Seelbach (Westerwald)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57632',
    },
    {
        id: '10561',
        ort: 'Seelen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67744',
    },
    {
        id: '10562',
        ort: 'Seelingstädt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07580',
    },
    {
        id: '10563',
        ort: 'Seelitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09306',
    },
    {
        id: '10564',
        ort: 'Seelow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15306',
    },
    {
        id: '10565',
        ort: 'Seelze',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30926',
    },
    {
        id: '10566',
        ort: 'Seeon-Seebruck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83358',
    },
    {
        id: '10567',
        ort: 'Seeon-Seebruck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83370',
    },
    {
        id: '10568',
        ort: 'Seeon-Seebruck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83376',
    },
    {
        id: '10569',
        ort: 'Seesbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55629',
    },
    {
        id: '10570',
        ort: 'Seesen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Goslar',
        plz: '38723',
    },
    {
        id: '10571',
        ort: 'Seeshaupt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82402',
    },
    {
        id: '10572',
        ort: 'Seester',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25370',
    },
    {
        id: '10573',
        ort: 'Seestermühe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25371',
    },
    {
        id: '10574',
        ort: 'Seeth',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25840',
    },
    {
        id: '10575',
        ort: 'Seeth',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25878',
    },
    {
        id: '10576',
        ort: 'Seeth-Ekholt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25337',
    },
    {
        id: '10577',
        ort: 'Seevetal',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21217',
    },
    {
        id: '10578',
        ort: 'Seevetal',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21218',
    },
    {
        id: '10579',
        ort: 'Seevetal',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21220',
    },
    {
        id: '10580',
        ort: 'Seewald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Freudenstadt',
        plz: '72297',
    },
    {
        id: '10581',
        ort: 'Seffern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '10582',
        ort: 'Sefferweich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '10583',
        ort: 'Seggebruch',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31691',
    },
    {
        id: '10584',
        ort: 'Segnitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97340',
    },
    {
        id: '10585',
        ort: 'Sehestedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24814',
    },
    {
        id: '10586',
        ort: 'Sehlde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38279',
    },
    {
        id: '10587',
        ort: 'Sehlem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54518',
    },
    {
        id: '10588',
        ort: 'Sehlen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18528',
    },
    {
        id: '10589',
        ort: 'Sehmatal',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09465',
    },
    {
        id: '10590',
        ort: 'Sehnde',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '31319',
    },
    {
        id: '10591',
        ort: 'Seibersbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55444',
    },
    {
        id: '10592',
        ort: 'Seifen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57632',
    },
    {
        id: '10593',
        ort: 'Seiffen/Erzgebirge',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09548',
    },
    {
        id: '10594',
        ort: 'Seifhennersdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02782',
    },
    {
        id: '10595',
        ort: 'Seinsfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54655',
    },
    {
        id: '10596',
        ort: 'Seinsheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97342',
    },
    {
        id: '10597',
        ort: 'Seisla',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07389',
    },
    {
        id: '10598',
        ort: 'Seitenroda',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07768',
    },
    {
        id: '10599',
        ort: 'Seitingen-Oberflacht',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78606',
    },
    {
        id: '10600',
        ort: 'Seiwerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '10601',
        ort: 'Selb',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Wunsiedel im Fichtelgebirge',
        plz: '95100',
    },
    {
        id: '10602',
        ort: 'Selbach (Sieg)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57537',
    },
    {
        id: '10603',
        ort: 'Selbitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95152',
    },
    {
        id: '10604',
        ort: 'Selchenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66871',
    },
    {
        id: '10605',
        ort: 'Selent',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24238',
    },
    {
        id: '10606',
        ort: 'Selfkant',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Heinsberg',
        plz: '52538',
    },
    {
        id: '10607',
        ort: 'Seligenstadt',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Offenbach',
        plz: '63500',
    },
    {
        id: '10608',
        ort: 'Selk',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24884',
    },
    {
        id: '10609',
        ort: 'Selke-Aue',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '06458',
    },
    {
        id: '10610',
        ort: 'Sellerich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54608',
    },
    {
        id: '10611',
        ort: 'Sellin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18586',
    },
    {
        id: '10612',
        ort: 'Selm',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Unna',
        plz: '59379',
    },
    {
        id: '10613',
        ort: 'Selmsdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23923',
    },
    {
        id: '10614',
        ort: 'Selpin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18195',
    },
    {
        id: '10615',
        ort: 'Selsingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27446',
    },
    {
        id: '10616',
        ort: 'Selters (Taunus)',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65618',
    },
    {
        id: '10617',
        ort: 'Selters (Westerwald)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56242',
    },
    {
        id: '10618',
        ort: 'Selzen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55278',
    },
    {
        id: '10619',
        ort: 'Sembach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67681',
    },
    {
        id: '10620',
        ort: 'Semlow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18334',
    },
    {
        id: '10621',
        ort: 'Senden',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Coesfeld',
        plz: '48308',
    },
    {
        id: '10622',
        ort: 'Senden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89250',
    },
    {
        id: '10623',
        ort: 'Sendenhorst',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Warendorf',
        plz: '48324',
    },
    {
        id: '10624',
        ort: 'Senftenberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01945',
    },
    {
        id: '10625',
        ort: 'Senftenberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01968',
    },
    {
        id: '10626',
        ort: 'Senftenberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01996',
    },
    {
        id: '10627',
        ort: 'Sengenthal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92369',
    },
    {
        id: '10628',
        ort: 'Sengerich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54619',
    },
    {
        id: '10629',
        ort: 'Senheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56820',
    },
    {
        id: '10630',
        ort: 'Sennfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97526',
    },
    {
        id: '10631',
        ort: 'Senscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53520',
    },
    {
        id: '10632',
        ort: 'Sensweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '10633',
        ort: 'Serba',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07616',
    },
    {
        id: '10634',
        ort: 'Serrig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54455',
    },
    {
        id: '10635',
        ort: 'Sersheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '74372',
    },
    {
        id: '10636',
        ort: 'Sessenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56237',
    },
    {
        id: '10637',
        ort: 'Sessenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '10638',
        ort: 'Seßlach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Coburg',
        plz: '96145',
    },
    {
        id: '10639',
        ort: 'Seth',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23845',
    },
    {
        id: '10640',
        ort: 'Setzingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89129',
    },
    {
        id: '10641',
        ort: 'Seubersdorf in der Oberpfalz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92358',
    },
    {
        id: '10642',
        ort: 'Seukendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürth',
        plz: '90556',
    },
    {
        id: '10643',
        ort: 'Seulingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37136',
    },
    {
        id: '10644',
        ort: 'Sevenig bei Neuerburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '10645',
        ort: 'Sevenig (Our)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54617',
    },
    {
        id: '10646',
        ort: 'Sexau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79350',
    },
    {
        id: '10647',
        ort: 'Seybothenreuth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95517',
    },
    {
        id: '10648',
        ort: 'Sibbesse',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31079',
    },
    {
        id: '10649',
        ort: 'Sickerode',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37308',
    },
    {
        id: '10650',
        ort: 'Sickte',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38173',
    },
    {
        id: '10651',
        ort: 'Siebeldingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76833',
    },
    {
        id: '10652',
        ort: 'Siebenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '10653',
        ort: 'Siebenbäumen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23847',
    },
    {
        id: '10654',
        ort: 'Siebeneichen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21514',
    },
    {
        id: '10655',
        ort: 'Siedenbollentin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17089',
    },
    {
        id: '10656',
        ort: 'Siedenbrünzow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17111',
    },
    {
        id: '10657',
        ort: 'Siedenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27254',
    },
    {
        id: '10658',
        ort: 'Siefersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55599',
    },
    {
        id: '10659',
        ort: 'Siegbach',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35768',
    },
    {
        id: '10660',
        ort: 'Siegburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53721',
    },
    {
        id: '10661',
        ort: 'Siegelsbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74936',
    },
    {
        id: '10662',
        ort: 'Siegen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Siegen-Wittgenstein',
        plz: '57072',
    },
    {
        id: '10663',
        ort: 'Siegen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Siegen-Wittgenstein',
        plz: '57074',
    },
    {
        id: '10664',
        ort: 'Siegen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Siegen-Wittgenstein',
        plz: '57076',
    },
    {
        id: '10665',
        ort: 'Siegen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Siegen-Wittgenstein',
        plz: '57078',
    },
    {
        id: '10666',
        ort: 'Siegen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Siegen-Wittgenstein',
        plz: '57080',
    },
    {
        id: '10667',
        ort: 'Siegenburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '93354',
    },
    {
        id: '10668',
        ort: 'Siegsdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83313',
    },
    {
        id: '10669',
        ort: 'Siehdichum',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15890',
    },
    {
        id: '10670',
        ort: 'Siek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22962',
    },
    {
        id: '10671',
        ort: 'Sielenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86577',
    },
    {
        id: '10672',
        ort: 'Siemz-Niendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23923',
    },
    {
        id: '10673',
        ort: 'Sien',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '10674',
        ort: 'Sienhachenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '10675',
        ort: 'Sierksdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23730',
    },
    {
        id: '10676',
        ort: 'Sierksrade',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23847',
    },
    {
        id: '10677',
        ort: 'Sierscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53520',
    },
    {
        id: '10678',
        ort: 'Siershahn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56427',
    },
    {
        id: '10679',
        ort: 'Siesbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '10680',
        ort: 'Sietow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17209',
    },
    {
        id: '10681',
        ort: 'Sieversdorf-Hohenofen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16845',
    },
    {
        id: '10682',
        ort: 'Sievershütten',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24641',
    },
    {
        id: '10683',
        ort: 'Sieverstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24885',
    },
    {
        id: '10684',
        ort: 'Siggelkow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19376',
    },
    {
        id: '10685',
        ort: 'Sigmaringen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '72488',
    },
    {
        id: '10686',
        ort: 'Sigmaringendorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '72517',
    },
    {
        id: '10687',
        ort: 'Sigmarszell',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88138',
    },
    {
        id: '10688',
        ort: 'Silberstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24887',
    },
    {
        id: '10689',
        ort: 'Silbitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07613',
    },
    {
        id: '10690',
        ort: 'Silz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76857',
    },
    {
        id: '10691',
        ort: 'Silz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17214',
    },
    {
        id: '10692',
        ort: 'Silzen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25551',
    },
    {
        id: '10693',
        ort: 'Simbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84359',
    },
    {
        id: '10694',
        ort: 'Simbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dingolfing-Landau',
        plz: '94436',
    },
    {
        id: '10695',
        ort: 'Simmelsdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91245',
    },
    {
        id: '10696',
        ort: 'Simmerath',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52152',
    },
    {
        id: '10697',
        ort: 'Simmern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56337',
    },
    {
        id: '10698',
        ort: 'Simmern/Hunsrück',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55469',
    },
    {
        id: '10699',
        ort: 'Simmersfeld',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '72226',
    },
    {
        id: '10700',
        ort: 'Simmershofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '97215',
    },
    {
        id: '10701',
        ort: 'Simmertal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55618',
    },
    {
        id: '10702',
        ort: 'Simmozheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '75397',
    },
    {
        id: '10703',
        ort: 'Simonsberg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25813',
    },
    {
        id: '10704',
        ort: 'Simonswald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79263',
    },
    {
        id: '10705',
        ort: 'Simonswald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79271',
    },
    {
        id: '10706',
        ort: 'Sindelfingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71063',
    },
    {
        id: '10707',
        ort: 'Sindelfingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71065',
    },
    {
        id: '10708',
        ort: 'Sindelfingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71067',
    },
    {
        id: '10709',
        ort: 'Sindelfingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71069',
    },
    {
        id: '10710',
        ort: 'Sindelsdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82404',
    },
    {
        id: '10711',
        ort: 'Singen (Hohentwiel)',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78224',
    },
    {
        id: '10712',
        ort: 'Singhofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56379',
    },
    {
        id: '10713',
        ort: 'Sinn',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35764',
    },
    {
        id: '10714',
        ort: 'Sinntal',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '36391',
    },
    {
        id: '10715',
        ort: 'Sinsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '74889',
    },
    {
        id: '10716',
        ort: 'Sinspelt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '10717',
        ort: 'Sinzheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '76547',
    },
    {
        id: '10718',
        ort: 'Sinzig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53489',
    },
    {
        id: '10719',
        ort: 'Sinzing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93161',
    },
    {
        id: '10720',
        ort: 'Sippersfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67729',
    },
    {
        id: '10721',
        ort: 'Sipplingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '78354',
    },
    {
        id: '10722',
        ort: 'Sirksfelde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23898',
    },
    {
        id: '10723',
        ort: 'Sittensen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27419',
    },
    {
        id: '10724',
        ort: 'Sitters',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67823',
    },
    {
        id: '10725',
        ort: 'Sitzendorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07429',
    },
    {
        id: '10726',
        ort: 'Söchtenau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83139',
    },
    {
        id: '10727',
        ort: 'Soderstorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21388',
    },
    {
        id: '10728',
        ort: 'Soest',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Soest',
        plz: '59494',
    },
    {
        id: '10729',
        ort: 'Sögel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49751',
    },
    {
        id: '10730',
        ort: 'Sohland an der Spree',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02689',
    },
    {
        id: '10731',
        ort: 'Söhlde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Hildesheim',
        plz: '31185',
    },
    {
        id: '10732',
        ort: 'Sohren',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55487',
    },
    {
        id: '10733',
        ort: 'Söhrewald',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34320',
    },
    {
        id: '10734',
        ort: 'Sohrschied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55487',
    },
    {
        id: '10735',
        ort: 'Sölden',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79294',
    },
    {
        id: '10736',
        ort: 'Solingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42651',
    },
    {
        id: '10737',
        ort: 'Solingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42653',
    },
    {
        id: '10738',
        ort: 'Solingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42655',
    },
    {
        id: '10739',
        ort: 'Solingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42657',
    },
    {
        id: '10740',
        ort: 'Solingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42659',
    },
    {
        id: '10741',
        ort: 'Solingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42697',
    },
    {
        id: '10742',
        ort: 'Solingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42699',
    },
    {
        id: '10743',
        ort: 'Solingen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42719',
    },
    {
        id: '10744',
        ort: 'Solkwitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07381',
    },
    {
        id: '10745',
        ort: 'Sollerup',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24852',
    },
    {
        id: '10746',
        ort: 'Söllingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38387',
    },
    {
        id: '10747',
        ort: 'Sollstedt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Nordhausen',
        plz: '99759',
    },
    {
        id: '10748',
        ort: 'Sollwitt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25884',
    },
    {
        id: '10749',
        ort: 'Solms',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35606',
    },
    {
        id: '10750',
        ort: 'Solnhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91807',
    },
    {
        id: '10751',
        ort: 'Soltau',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29614',
    },
    {
        id: '10752',
        ort: 'Soltendieck',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29594',
    },
    {
        id: '10753',
        ort: 'Sommerach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97334',
    },
    {
        id: '10754',
        ort: 'Sommerau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54317',
    },
    {
        id: '10755',
        ort: 'Sömmerda',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99610',
    },
    {
        id: '10756',
        ort: 'Sömmerda',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99625',
    },
    {
        id: '10757',
        ort: 'Sommerhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97286',
    },
    {
        id: '10758',
        ort: 'Sommerkahl',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63825',
    },
    {
        id: '10759',
        ort: 'Sommerland',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25358',
    },
    {
        id: '10760',
        ort: 'Sommerloch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '10761',
        ort: 'Sommersdorf',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39365',
    },
    {
        id: '10762',
        ort: 'Sommersdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17111',
    },
    {
        id: '10763',
        ort: 'Sonderhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97255',
    },
    {
        id: '10764',
        ort: 'Sondershausen',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '99706',
    },
    {
        id: '10765',
        ort: 'Sondheim v.d.Rhön',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97647',
    },
    {
        id: '10766',
        ort: 'Sonneberg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sonneberg',
        plz: '96515',
    },
    {
        id: '10767',
        ort: 'Sonneborn',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99869',
    },
    {
        id: '10768',
        ort: 'Sönnebüll',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25821',
    },
    {
        id: '10769',
        ort: 'Sonnefeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Coburg',
        plz: '96242',
    },
    {
        id: '10770',
        ort: 'Sonnen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94164',
    },
    {
        id: '10771',
        ort: 'Sonnenberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16775',
    },
    {
        id: '10772',
        ort: 'Sonnenberg-Winnenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '10773',
        ort: 'Sonnenbühl',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72820',
    },
    {
        id: '10774',
        ort: 'Sonnenstein',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37345',
    },
    {
        id: '10775',
        ort: 'Sonnewalde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '03249',
    },
    {
        id: '10776',
        ort: 'Sonnschied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '10777',
        ort: 'Sonsbeck',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '47665',
    },
    {
        id: '10778',
        ort: 'Sontheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87776',
    },
    {
        id: '10779',
        ort: 'Sontheim an der Brenz',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heidenheim',
        plz: '89567',
    },
    {
        id: '10780',
        ort: 'Sonthofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87527',
    },
    {
        id: '10781',
        ort: 'Sontra',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '36205',
    },
    {
        id: '10782',
        ort: 'Sophienhamm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24806',
    },
    {
        id: '10783',
        ort: 'Sören',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24241',
    },
    {
        id: '10784',
        ort: 'Sörgenloch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55270',
    },
    {
        id: '10785',
        ort: 'Sörth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57636',
    },
    {
        id: '10786',
        ort: 'Sörup',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24966',
    },
    {
        id: '10787',
        ort: 'Sosberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56858',
    },
    {
        id: '10788',
        ort: 'Sottrum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27367',
    },
    {
        id: '10789',
        ort: 'Soyen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83564',
    },
    {
        id: '10790',
        ort: 'Spabrücken',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '10791',
        ort: 'Spahnharrenstätte',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49751',
    },
    {
        id: '10792',
        ort: 'Spaichingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78549',
    },
    {
        id: '10793',
        ort: 'Spall',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '10794',
        ort: 'Spalt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Roth',
        plz: '91174',
    },
    {
        id: '10795',
        ort: 'Spangdahlem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54529',
    },
    {
        id: '10796',
        ort: 'Spangenberg',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34286',
    },
    {
        id: '10797',
        ort: 'Spantekow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17391',
    },
    {
        id: '10798',
        ort: 'Spantekow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17392',
    },
    {
        id: '10799',
        ort: 'Spardorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91080',
    },
    {
        id: '10800',
        ort: 'Sparneck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95234',
    },
    {
        id: '10801',
        ort: 'Spatzenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82418',
    },
    {
        id: '10802',
        ort: 'Spatzenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82447',
    },
    {
        id: '10803',
        ort: 'Spay',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56322',
    },
    {
        id: '10804',
        ort: 'Spechbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '74937',
    },
    {
        id: '10805',
        ort: 'Speicher',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54662',
    },
    {
        id: '10806',
        ort: 'Speichersdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95469',
    },
    {
        id: '10807',
        ort: 'Speinshart',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92676',
    },
    {
        id: '10808',
        ort: 'Spelle',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '48480',
    },
    {
        id: '10809',
        ort: 'Spenge',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Herford',
        plz: '32139',
    },
    {
        id: '10810',
        ort: 'Spesenroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56288',
    },
    {
        id: '10811',
        ort: 'Spessart',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '56746',
    },
    {
        id: '10812',
        ort: 'Speyer',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67346',
    },
    {
        id: '10813',
        ort: 'Spiegelau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94518',
    },
    {
        id: '10814',
        ort: 'Spiegelberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71579',
    },
    {
        id: '10815',
        ort: 'Spiekeroog',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26474',
    },
    {
        id: '10816',
        ort: 'Spiesen-Elversberg',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Neunkirchen',
        plz: '66583',
    },
    {
        id: '10817',
        ort: 'Spiesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55288',
    },
    {
        id: '10818',
        ort: 'Spirkelbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '76848',
    },
    {
        id: '10819',
        ort: 'Splietsdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18513',
    },
    {
        id: '10820',
        ort: 'Sponheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '10821',
        ort: 'Sponholz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17039',
    },
    {
        id: '10822',
        ort: 'Spornitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19372',
    },
    {
        id: '10823',
        ort: 'Spraitbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73565',
    },
    {
        id: '10824',
        ort: 'Spraitbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73568',
    },
    {
        id: '10825',
        ort: 'Spraitbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73577',
    },
    {
        id: '10826',
        ort: 'Sprakebüll',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25917',
    },
    {
        id: '10827',
        ort: 'Sprakensehl',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '29365',
    },
    {
        id: '10828',
        ort: 'Spreenhagen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15528',
    },
    {
        id: '10829',
        ort: 'Spreetal - Sprjewiny Doł',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02979',
    },
    {
        id: '10830',
        ort: 'Spreewaldheide',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15913',
    },
    {
        id: '10831',
        ort: 'Spremberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03130',
    },
    {
        id: '10832',
        ort: 'Sprendlingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55576',
    },
    {
        id: '10833',
        ort: 'Springe',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '31832',
    },
    {
        id: '10834',
        ort: 'Sprockhövel',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Ennepe-Ruhr-Kreis',
        plz: '45549',
    },
    {
        id: '10835',
        ort: 'Sprötau',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99610',
    },
    {
        id: '10836',
        ort: 'Stäbelow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18198',
    },
    {
        id: '10837',
        ort: 'Stade',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21680',
    },
    {
        id: '10838',
        ort: 'Stade',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21682',
    },
    {
        id: '10839',
        ort: 'Stade',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21683',
    },
    {
        id: '10840',
        ort: 'Stade',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21684',
    },
    {
        id: '10841',
        ort: 'Stadecken-Elsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55271',
    },
    {
        id: '10842',
        ort: 'Stadelhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96187',
    },
    {
        id: '10843',
        ort: 'Stadland',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wesermarsch',
        plz: '26935',
    },
    {
        id: '10844',
        ort: 'Stadland',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wesermarsch',
        plz: '26936',
    },
    {
        id: '10845',
        ort: 'Stadland',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wesermarsch',
        plz: '26937',
    },
    {
        id: '10846',
        ort: 'Stadlern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92549',
    },
    {
        id: '10847',
        ort: 'Stadtallendorf',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35260',
    },
    {
        id: '10848',
        ort: 'Stadtbergen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86391',
    },
    {
        id: '10849',
        ort: 'Stadthagen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31655',
    },
    {
        id: '10850',
        ort: 'Stadtilm',
        bundesland: 'Thüringen',
        landkreis: 'Ilm-Kreis',
        plz: '99326',
    },
    {
        id: '10851',
        ort: 'Stadtkyll',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54589',
    },
    {
        id: '10852',
        ort: 'Stadtlauringen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97488',
    },
    {
        id: '10853',
        ort: 'Stadtlohn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '48703',
    },
    {
        id: '10854',
        ort: 'Stadtoldendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37627',
    },
    {
        id: '10855',
        ort: 'Stadtprozelten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '97909',
    },
    {
        id: '10856',
        ort: 'Stadtroda',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '10857',
        ort: 'Stadtsteinach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95346',
    },
    {
        id: '10858',
        ort: 'Stadum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25917',
    },
    {
        id: '10859',
        ort: 'Staffhorst',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27254',
    },
    {
        id: '10860',
        ort: 'Stafstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24816',
    },
    {
        id: '10861',
        ort: 'Stahlberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67808',
    },
    {
        id: '10862',
        ort: 'Stahlhofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '10863',
        ort: 'Stahlhofen am Wiesensee',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '10864',
        ort: 'Stahnsdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14532',
    },
    {
        id: '10865',
        ort: 'Staig',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89195',
    },
    {
        id: '10866',
        ort: 'Stakendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24217',
    },
    {
        id: '10867',
        ort: 'Stallwang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94375',
    },
    {
        id: '10868',
        ort: 'Stammbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95236',
    },
    {
        id: '10869',
        ort: 'Stammham',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84533',
    },
    {
        id: '10870',
        ort: 'Stammham',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85092',
    },
    {
        id: '10871',
        ort: 'Stammham',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85134',
    },
    {
        id: '10872',
        ort: 'Stamsried',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93491',
    },
    {
        id: '10873',
        ort: 'Standenbühl',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67816',
    },
    {
        id: '10874',
        ort: 'Stangheck',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24395',
    },
    {
        id: '10875',
        ort: 'Stapel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '25879',
    },
    {
        id: '10876',
        ort: 'Stapelfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22145',
    },
    {
        id: '10877',
        ort: 'Starkenberg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04617',
    },
    {
        id: '10878',
        ort: 'Starkenburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '56843',
    },
    {
        id: '10879',
        ort: 'Starnberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Starnberg',
        plz: '82319',
    },
    {
        id: '10880',
        ort: 'Starzach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72181',
    },
    {
        id: '10881',
        ort: 'Staßfurt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '39418',
    },
    {
        id: '10882',
        ort: 'Staßfurt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '39443',
    },
    {
        id: '10883',
        ort: 'Staßfurt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '39446',
    },
    {
        id: '10884',
        ort: 'Stauchitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01594',
    },
    {
        id: '10885',
        ort: 'Staudach-Egerndach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83224',
    },
    {
        id: '10886',
        ort: 'Staudernheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55568',
    },
    {
        id: '10887',
        ort: 'Staudt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56424',
    },
    {
        id: '10888',
        ort: 'Staufenberg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35460',
    },
    {
        id: '10889',
        ort: 'Staufenberg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '34355',
    },
    {
        id: '10890',
        ort: 'Staufen im Breisgau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79219',
    },
    {
        id: '10891',
        ort: 'Staven',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17039',
    },
    {
        id: '10892',
        ort: 'Stavenhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17153',
    },
    {
        id: '10893',
        ort: 'Stavern',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49777',
    },
    {
        id: '10894',
        ort: 'St. Blasien',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79837',
    },
    {
        id: '10895',
        ort: 'Stebach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56276',
    },
    {
        id: '10896',
        ort: 'Stechlin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16775',
    },
    {
        id: '10897',
        ort: 'Stechow-Ferchesar',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14715',
    },
    {
        id: '10898',
        ort: 'Stedesand',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25920',
    },
    {
        id: '10899',
        ort: 'Stedesdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26427',
    },
    {
        id: '10900',
        ort: 'Steenfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '25557',
    },
    {
        id: '10901',
        ort: 'Steffeln',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54597',
    },
    {
        id: '10902',
        ort: 'Steffenberg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35239',
    },
    {
        id: '10903',
        ort: 'Steffenshagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18209',
    },
    {
        id: '10904',
        ort: 'Stegaurach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96135',
    },
    {
        id: '10905',
        ort: 'Stegen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79252',
    },
    {
        id: '10906',
        ort: 'St. Egidien',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '09356',
    },
    {
        id: '10907',
        ort: 'Steigra',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06268',
    },
    {
        id: '10908',
        ort: 'Steimbke',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31634',
    },
    {
        id: '10909',
        ort: 'Steimel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '57614',
    },
    {
        id: '10910',
        ort: 'Stein',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24235',
    },
    {
        id: '10911',
        ort: 'Stein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürth',
        plz: '90547',
    },
    {
        id: '10912',
        ort: 'Steina',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01920',
    },
    {
        id: '10913',
        ort: 'Steinach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77790',
    },
    {
        id: '10914',
        ort: 'Steinach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94377',
    },
    {
        id: '10915',
        ort: 'Steinach',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sonneberg',
        plz: '96523',
    },
    {
        id: '10916',
        ort: 'Steinalben',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66851',
    },
    {
        id: '10917',
        ort: 'Steinau',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21775',
    },
    {
        id: '10918',
        ort: 'Steinau an der Straße',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '36396',
    },
    {
        id: '10919',
        ort: 'Steinbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56291',
    },
    {
        id: '10920',
        ort: 'Steinbach',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37308',
    },
    {
        id: '10921',
        ort: 'Steinbach am Donnersberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67808',
    },
    {
        id: '10922',
        ort: 'Steinbach am Glan',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66909',
    },
    {
        id: '10923',
        ort: 'Steinbach am Wald',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kronach',
        plz: '96361',
    },
    {
        id: '10924',
        ort: 'Steinbach-Hallenberg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98587',
    },
    {
        id: '10925',
        ort: 'Steinbach (Taunus)',
        bundesland: 'Hessen',
        landkreis: 'Hochtaunuskreis',
        plz: '61449',
    },
    {
        id: '10926',
        ort: 'Steinberg',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08237',
    },
    {
        id: '10927',
        ort: 'Steinberg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24972',
    },
    {
        id: '10928',
        ort: 'Steinberg am See',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92449',
    },
    {
        id: '10929',
        ort: 'Steinbergkirche',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24972',
    },
    {
        id: '10930',
        ort: 'Stein-Bockenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55599',
    },
    {
        id: '10931',
        ort: 'Steinborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54655',
    },
    {
        id: '10932',
        ort: 'Steinburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22964',
    },
    {
        id: '10933',
        ort: 'Steindorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '82297',
    },
    {
        id: '10934',
        ort: 'Steinebach an der Wied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57629',
    },
    {
        id: '10935',
        ort: 'Steinebach/Sieg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57520',
    },
    {
        id: '10936',
        ort: 'Steineberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '10937',
        ort: 'Steinefrenz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56414',
    },
    {
        id: '10938',
        ort: 'Steinen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '10939',
        ort: 'Steinen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79585',
    },
    {
        id: '10940',
        ort: 'Steinenbronn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71144',
    },
    {
        id: '10941',
        ort: 'Steineroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57518',
    },
    {
        id: '10942',
        ort: 'Steinfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97854',
    },
    {
        id: '10943',
        ort: 'Steinfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24888',
    },
    {
        id: '10944',
        ort: 'Steinfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76889',
    },
    {
        id: '10945',
        ort: 'Steinfeld',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Vechta',
        plz: '49439',
    },
    {
        id: '10946',
        ort: 'Steinfurt',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '48565',
    },
    {
        id: '10947',
        ort: 'Steingaden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '86989',
    },
    {
        id: '10948',
        ort: 'Steinhagen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Gütersloh',
        plz: '33803',
    },
    {
        id: '10949',
        ort: 'Steinhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18442',
    },
    {
        id: '10950',
        ort: 'Steinhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18246',
    },
    {
        id: '10951',
        ort: 'Steinhausen an der Rottum',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88416',
    },
    {
        id: '10952',
        ort: 'Steinheim',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Höxter',
        plz: '32839',
    },
    {
        id: '10953',
        ort: 'Steinheim am Albuch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heidenheim',
        plz: '73566',
    },
    {
        id: '10954',
        ort: 'Steinheim am Albuch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heidenheim',
        plz: '89555',
    },
    {
        id: '10955',
        ort: 'Steinheim an der Murr',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71711',
    },
    {
        id: '10956',
        ort: 'Steinheuterode',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '10957',
        ort: 'Steinhöfel',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15518',
    },
    {
        id: '10958',
        ort: 'Steinhöring',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85643',
    },
    {
        id: '10959',
        ort: 'Steinhorst',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '29367',
    },
    {
        id: '10960',
        ort: 'Steinhorst',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23847',
    },
    {
        id: '10961',
        ort: 'Steinigtwolmsdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01904',
    },
    {
        id: '10962',
        ort: 'Steiningen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '10963',
        ort: 'Steinkirchen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21720',
    },
    {
        id: '10964',
        ort: 'Steinkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '84439',
    },
    {
        id: '10965',
        ort: 'Steinmauern',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '76479',
    },
    {
        id: '10966',
        ort: 'Stein-Neukirch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '10967',
        ort: 'Steinreich',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15938',
    },
    {
        id: '10968',
        ort: 'Steinsberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56379',
    },
    {
        id: '10969',
        ort: 'Steinsfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91628',
    },
    {
        id: '10970',
        ort: 'Steinweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76872',
    },
    {
        id: '10971',
        ort: 'Steinwenden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '66879',
    },
    {
        id: '10972',
        ort: 'Steinwiesen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kronach',
        plz: '96349',
    },
    {
        id: '10973',
        ort: 'Stein-Wingert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57629',
    },
    {
        id: '10974',
        ort: 'Steißlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78256',
    },
    {
        id: '10975',
        ort: 'Stelle',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21435',
    },
    {
        id: '10976',
        ort: 'Stelle-Wittenwurth',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25795',
    },
    {
        id: '10977',
        ort: 'Stelzenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67705',
    },
    {
        id: '10978',
        ort: 'Stemmen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27389',
    },
    {
        id: '10979',
        ort: 'Stemshorn',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49448',
    },
    {
        id: '10980',
        ort: 'Stemwede',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Minden-Lübbecke',
        plz: '32351',
    },
    {
        id: '10981',
        ort: 'Stendal',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39576',
    },
    {
        id: '10982',
        ort: 'Stepenitztal',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23936',
    },
    {
        id: '10983',
        ort: 'Stephanskirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83071',
    },
    {
        id: '10984',
        ort: 'Stephansposching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94569',
    },
    {
        id: '10985',
        ort: 'Sterley',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23883',
    },
    {
        id: '10986',
        ort: 'Sternberg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19406',
    },
    {
        id: '10987',
        ort: 'Sternenfels',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75447',
    },
    {
        id: '10988',
        ort: 'Sterup',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24996',
    },
    {
        id: '10989',
        ort: 'Stetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87778',
    },
    {
        id: '10990',
        ort: 'Stetten',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67294',
    },
    {
        id: '10991',
        ort: 'Stetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88719',
    },
    {
        id: '10992',
        ort: 'Stetten am kalten Markt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '72510',
    },
    {
        id: '10993',
        ort: 'Stettfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '96188',
    },
    {
        id: '10994',
        ort: 'Steyerberg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31595',
    },
    {
        id: '10995',
        ort: 'St. Gangloff',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07629',
    },
    {
        id: '10996',
        ort: 'St. Georgen im Schwarzwald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78112',
    },
    {
        id: '10997',
        ort: 'Stiefenhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88167',
    },
    {
        id: '10998',
        ort: 'Stimpfach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74597',
    },
    {
        id: '10999',
        ort: 'Stinstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21772',
    },
    {
        id: '11000',
        ort: 'Stipsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23795',
    },
    {
        id: '11001',
        ort: 'Stipshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '11002',
        ort: 'St. Johann',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72813',
    },
    {
        id: '11003',
        ort: 'St. Leon-Rot',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '68789',
    },
    {
        id: '11004',
        ort: 'St. Märgen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79274',
    },
    {
        id: '11005',
        ort: 'Stockach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78333',
    },
    {
        id: '11006',
        ort: 'Stockelsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23617',
    },
    {
        id: '11007',
        ort: 'Stockem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54646',
    },
    {
        id: '11008',
        ort: 'Stockhausen-Illfurth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56472',
    },
    {
        id: '11009',
        ort: 'Stockheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97640',
    },
    {
        id: '11010',
        ort: 'Stockheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kronach',
        plz: '96342',
    },
    {
        id: '11011',
        ort: 'Stöckse',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31638',
    },
    {
        id: '11012',
        ort: 'Stocksee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24326',
    },
    {
        id: '11013',
        ort: 'Stockstadt',
        bundesland: 'Hessen',
        landkreis: 'Kreis Groß-Gerau',
        plz: '64589',
    },
    {
        id: '11014',
        ort: 'Stockstadt am Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63811',
    },
    {
        id: '11015',
        ort: 'Stockum-Püschen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '11016',
        ort: 'Stödtlen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73495',
    },
    {
        id: '11017',
        ort: 'Stoetze',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29597',
    },
    {
        id: '11018',
        ort: 'Stolberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52222',
    },
    {
        id: '11019',
        ort: 'Stolberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52223',
    },
    {
        id: '11020',
        ort: 'Stolberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52224',
    },
    {
        id: '11021',
        ort: 'Stolk',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24890',
    },
    {
        id: '11022',
        ort: 'Stollberg/Erzgebirge',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09366',
    },
    {
        id: '11023',
        ort: 'Stolpe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24601',
    },
    {
        id: '11024',
        ort: 'Stolpe',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19372',
    },
    {
        id: '11025',
        ort: 'Stolpe an der Peene',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17391',
    },
    {
        id: '11026',
        ort: 'Stolpe auf Usedom',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17406',
    },
    {
        id: '11027',
        ort: 'Stolpen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01833',
    },
    {
        id: '11028',
        ort: 'Stoltebüll',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24409',
    },
    {
        id: '11029',
        ort: 'Stoltenberg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24256',
    },
    {
        id: '11030',
        ort: 'Stolzenau',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31592',
    },
    {
        id: '11031',
        ort: 'Storbeck-Frankendorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16818',
    },
    {
        id: '11032',
        ort: 'Stördorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25554',
    },
    {
        id: '11033',
        ort: 'Störkathen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25548',
    },
    {
        id: '11034',
        ort: 'Storkow (Mark)',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15859',
    },
    {
        id: '11035',
        ort: 'Störnstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92721',
    },
    {
        id: '11036',
        ort: 'Stößen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06667',
    },
    {
        id: '11037',
        ort: 'Stötten a.Auerberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87675',
    },
    {
        id: '11038',
        ort: 'Stöttwang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87677',
    },
    {
        id: '11039',
        ort: 'St. Peter',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79271',
    },
    {
        id: '11040',
        ort: 'Straelen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '47638',
    },
    {
        id: '11041',
        ort: 'Strahlungen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97618',
    },
    {
        id: '11042',
        ort: 'Stralendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19073',
    },
    {
        id: '11043',
        ort: 'Stralsund',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18435',
    },
    {
        id: '11044',
        ort: 'Stralsund',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18437',
    },
    {
        id: '11045',
        ort: 'Stralsund',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18439',
    },
    {
        id: '11046',
        ort: 'Strande',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24229',
    },
    {
        id: '11047',
        ort: 'Strasburg (Uckermark)',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17335',
    },
    {
        id: '11048',
        ort: 'Straßberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72479',
    },
    {
        id: '11049',
        ort: 'Straßenhaus',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56587',
    },
    {
        id: '11050',
        ort: 'Straßkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94342',
    },
    {
        id: '11051',
        ort: 'Straßlach-Dingharting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '82064',
    },
    {
        id: '11052',
        ort: 'Straubenhardt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75334',
    },
    {
        id: '11053',
        ort: 'Straubing',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '94315',
    },
    {
        id: '11054',
        ort: 'Straufhain',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98646',
    },
    {
        id: '11055',
        ort: 'Straupitz (Spreewald)',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15913',
    },
    {
        id: '11056',
        ort: 'Strausberg',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15344',
    },
    {
        id: '11057',
        ort: 'Straußfurt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99634',
    },
    {
        id: '11058',
        ort: 'Strehla',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01616',
    },
    {
        id: '11059',
        ort: 'Streithausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57629',
    },
    {
        id: '11060',
        ort: 'Strickscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '11061',
        ort: 'Striegistal',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09661',
    },
    {
        id: '11062',
        ort: 'Strohkirchen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '11063',
        ort: 'Strohn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54558',
    },
    {
        id: '11064',
        ort: 'Stromberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55442',
    },
    {
        id: '11065',
        ort: 'Strotzbüsch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '11066',
        ort: 'Strübbel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25792',
    },
    {
        id: '11067',
        ort: 'Struckum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25821',
    },
    {
        id: '11068',
        ort: 'Strukdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23815',
    },
    {
        id: '11069',
        ort: 'Strullendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96129',
    },
    {
        id: '11070',
        ort: 'Struppen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01796',
    },
    {
        id: '11071',
        ort: 'Strüth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '11072',
        ort: 'Struvenhütten',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24643',
    },
    {
        id: '11073',
        ort: 'Struxdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24891',
    },
    {
        id: '11074',
        ort: 'Stubben',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23847',
    },
    {
        id: '11075',
        ort: 'Stubbendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18195',
    },
    {
        id: '11076',
        ort: 'Stubenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '94166',
    },
    {
        id: '11077',
        ort: 'Stüdenitz-Schönermark',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16845',
    },
    {
        id: '11078',
        ort: 'Stuer',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17209',
    },
    {
        id: '11079',
        ort: 'Stühlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79780',
    },
    {
        id: '11080',
        ort: 'Stuhr',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '28816',
    },
    {
        id: '11081',
        ort: 'Stulln',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92551',
    },
    {
        id: '11082',
        ort: 'Stürzelbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57614',
    },
    {
        id: '11083',
        ort: 'Stutensee',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76297',
    },
    {
        id: '11084',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70173',
    },
    {
        id: '11085',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70174',
    },
    {
        id: '11086',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70176',
    },
    {
        id: '11087',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70178',
    },
    {
        id: '11088',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70180',
    },
    {
        id: '11089',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70182',
    },
    {
        id: '11090',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70184',
    },
    {
        id: '11091',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70186',
    },
    {
        id: '11092',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70188',
    },
    {
        id: '11093',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70190',
    },
    {
        id: '11094',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70191',
    },
    {
        id: '11095',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70192',
    },
    {
        id: '11096',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70193',
    },
    {
        id: '11097',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70195',
    },
    {
        id: '11098',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70197',
    },
    {
        id: '11099',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70199',
    },
    {
        id: '11100',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70327',
    },
    {
        id: '11101',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70329',
    },
    {
        id: '11102',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70372',
    },
    {
        id: '11103',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70374',
    },
    {
        id: '11104',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70376',
    },
    {
        id: '11105',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70378',
    },
    {
        id: '11106',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70435',
    },
    {
        id: '11107',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70437',
    },
    {
        id: '11108',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70439',
    },
    {
        id: '11109',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70469',
    },
    {
        id: '11110',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70499',
    },
    {
        id: '11111',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70563',
    },
    {
        id: '11112',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70565',
    },
    {
        id: '11113',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70567',
    },
    {
        id: '11114',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70569',
    },
    {
        id: '11115',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70597',
    },
    {
        id: '11116',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70599',
    },
    {
        id: '11117',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70619',
    },
    {
        id: '11118',
        ort: 'Stuttgart',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '70629',
    },
    {
        id: '11119',
        ort: 'Stützengrün',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '08328',
    },
    {
        id: '11120',
        ort: 'Stuvenborn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24641',
    },
    {
        id: '11121',
        ort: 'Südbrookmerland',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26624',
    },
    {
        id: '11122',
        ort: 'Südbrookmerland',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26759',
    },
    {
        id: '11123',
        ort: 'Südeichsfeld',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99976',
    },
    {
        id: '11124',
        ort: 'Südeichsfeld',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99988',
    },
    {
        id: '11125',
        ort: 'Süderau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25361',
    },
    {
        id: '11126',
        ort: 'Süderbrarup',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24392',
    },
    {
        id: '11127',
        ort: 'Suderburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29556',
    },
    {
        id: '11128',
        ort: 'Süderdeich',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25764',
    },
    {
        id: '11129',
        ort: 'Süderdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25782',
    },
    {
        id: '11130',
        ort: 'Süderende',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25938',
    },
    {
        id: '11131',
        ort: 'Süderfahrenstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24890',
    },
    {
        id: '11132',
        ort: 'Südergellersen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21394',
    },
    {
        id: '11133',
        ort: 'Süderhackstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24852',
    },
    {
        id: '11134',
        ort: 'Süderhackstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24992',
    },
    {
        id: '11135',
        ort: 'Süderhastedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25727',
    },
    {
        id: '11136',
        ort: 'Süderheistedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25779',
    },
    {
        id: '11137',
        ort: 'Süderhöft',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25876',
    },
    {
        id: '11138',
        ort: 'Süderholz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18516',
    },
    {
        id: '11139',
        ort: 'Süderlügum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25923',
    },
    {
        id: '11140',
        ort: 'Südermarsch',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25813',
    },
    {
        id: '11141',
        ort: 'Südermarsch',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25840',
    },
    {
        id: '11142',
        ort: 'Südharz',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06536',
    },
    {
        id: '11143',
        ort: 'Südheide',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29303',
    },
    {
        id: '11144',
        ort: 'Südheide',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29320',
    },
    {
        id: '11145',
        ort: 'Südheide',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29345',
    },
    {
        id: '11146',
        ort: 'Südliches Anhalt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06369',
    },
    {
        id: '11147',
        ort: 'Südliches Anhalt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06386',
    },
    {
        id: '11148',
        ort: 'Südliches Anhalt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06388',
    },
    {
        id: '11149',
        ort: 'Südlohn',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '46354',
    },
    {
        id: '11150',
        ort: 'Südmüritz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17207',
    },
    {
        id: '11151',
        ort: 'Sudwalde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27257',
    },
    {
        id: '11152',
        ort: 'Sugenheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91438',
    },
    {
        id: '11153',
        ort: 'Sugenheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91484',
    },
    {
        id: '11154',
        ort: 'Suhl',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '98527',
    },
    {
        id: '11155',
        ort: 'Suhl',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '98528',
    },
    {
        id: '11156',
        ort: 'Suhl',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '98529',
    },
    {
        id: '11157',
        ort: 'Suhlendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29562',
    },
    {
        id: '11158',
        ort: 'Sukow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19079',
    },
    {
        id: '11159',
        ort: 'Sukow-Levitzow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17168',
    },
    {
        id: '11160',
        ort: 'Sülfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23845',
    },
    {
        id: '11161',
        ort: 'Sülfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23867',
    },
    {
        id: '11162',
        ort: 'Sulingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27232',
    },
    {
        id: '11163',
        ort: 'Sülm',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '11164',
        ort: 'Sülstorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19077',
    },
    {
        id: '11165',
        ort: 'Sulza',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07751',
    },
    {
        id: '11166',
        ort: 'Sulz am Neckar',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '72172',
    },
    {
        id: '11167',
        ort: 'Sulzbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56379',
    },
    {
        id: '11168',
        ort: 'Sulzbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '11169',
        ort: 'Sulzbach am Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63834',
    },
    {
        id: '11170',
        ort: 'Sulzbach an der Murr',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71560',
    },
    {
        id: '11171',
        ort: 'Sulzbach-Laufen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74429',
    },
    {
        id: '11172',
        ort: 'Sulzbach-Rosenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92237',
    },
    {
        id: '11173',
        ort: 'Sulzbach/Saar',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66280',
    },
    {
        id: '11174',
        ort: 'Sulzbachtal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67734',
    },
    {
        id: '11175',
        ort: 'Sulzbach (Taunus)',
        bundesland: 'Hessen',
        landkreis: 'Main-Taunus-Kreis',
        plz: '65843',
    },
    {
        id: '11176',
        ort: 'Sulzberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87477',
    },
    {
        id: '11177',
        ort: 'Sulzburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79295',
    },
    {
        id: '11178',
        ort: 'Sulzdorf a.d.Lederhecke',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97528',
    },
    {
        id: '11179',
        ort: 'Sulzemoos',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '85254',
    },
    {
        id: '11180',
        ort: 'Sulzemoos',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '85259',
    },
    {
        id: '11181',
        ort: 'Sülzetal',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39171',
    },
    {
        id: '11182',
        ort: 'Sulzfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97633',
    },
    {
        id: '11183',
        ort: 'Sulzfeld',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '75056',
    },
    {
        id: '11184',
        ort: 'Sülzfeld',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98617',
    },
    {
        id: '11185',
        ort: 'Sulzfeld am Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97320',
    },
    {
        id: '11186',
        ort: 'Sulzheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97529',
    },
    {
        id: '11187',
        ort: 'Sulzheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55286',
    },
    {
        id: '11188',
        ort: 'Sulzthal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97717',
    },
    {
        id: '11189',
        ort: 'Sünching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93104',
    },
    {
        id: '11190',
        ort: 'Sundern',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Hochsauerlandkreis',
        plz: '59846',
    },
    {
        id: '11191',
        ort: 'Sundhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18519',
    },
    {
        id: '11192',
        ort: 'Sundhausen',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99947',
    },
    {
        id: '11193',
        ort: 'Süpplingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38373',
    },
    {
        id: '11194',
        ort: 'Süpplingenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38376',
    },
    {
        id: '11195',
        ort: 'Surberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83362',
    },
    {
        id: '11196',
        ort: 'Surwold',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26903',
    },
    {
        id: '11197',
        ort: 'Süsel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23701',
    },
    {
        id: '11198',
        ort: 'Süßen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73079',
    },
    {
        id: '11199',
        ort: 'Sustrum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49762',
    },
    {
        id: '11200',
        ort: 'Suthfeld',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31555',
    },
    {
        id: '11201',
        ort: 'Swisttal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53913',
    },
    {
        id: '11202',
        ort: 'Sydower Fließ',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16230',
    },
    {
        id: '11203',
        ort: 'Syke',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '28857',
    },
    {
        id: '11204',
        ort: 'Sylt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25980',
    },
    {
        id: '11205',
        ort: 'Syrgenstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89428',
    },
    {
        id: '11206',
        ort: 'Taarstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24893',
    },
    {
        id: '11207',
        ort: 'Taben-Rodt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54441',
    },
    {
        id: '11208',
        ort: 'Tacherting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83342',
    },
    {
        id: '11209',
        ort: 'Taching am See',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83373',
    },
    {
        id: '11210',
        ort: 'Tackesdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24797',
    },
    {
        id: '11211',
        ort: 'Tackesdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '25585',
    },
    {
        id: '11212',
        ort: 'Täferrot',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73527',
    },
    {
        id: '11213',
        ort: 'Tagmersheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86704',
    },
    {
        id: '11214',
        ort: 'Talheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74388',
    },
    {
        id: '11215',
        ort: 'Talheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78607',
    },
    {
        id: '11216',
        ort: 'Talkau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21493',
    },
    {
        id: '11217',
        ort: 'Talling',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54426',
    },
    {
        id: '11218',
        ort: 'Tambach-Dietharz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99897',
    },
    {
        id: '11219',
        ort: 'Tamm',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71732',
    },
    {
        id: '11220',
        ort: 'Tangerhütte',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39517',
    },
    {
        id: '11221',
        ort: 'Tangerhütte',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39579',
    },
    {
        id: '11222',
        ort: 'Tangermünde',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39590',
    },
    {
        id: '11223',
        ort: 'Tangstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25499',
    },
    {
        id: '11224',
        ort: 'Tangstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22889',
    },
    {
        id: '11225',
        ort: 'Tann',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84367',
    },
    {
        id: '11226',
        ort: 'Tanna',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07922',
    },
    {
        id: '11227',
        ort: 'Tannenberg',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09468',
    },
    {
        id: '11228',
        ort: 'Tännesberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92723',
    },
    {
        id: '11229',
        ort: 'Tannhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73497',
    },
    {
        id: '11230',
        ort: 'Tannheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88459',
    },
    {
        id: '11231',
        ort: 'Tann (Rhön)',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Fulda',
        plz: '36142',
    },
    {
        id: '11232',
        ort: 'Tantow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '16307',
    },
    {
        id: '11233',
        ort: 'Tapfheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86660',
    },
    {
        id: '11234',
        ort: 'Tappenbeck',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38479',
    },
    {
        id: '11235',
        ort: 'Tappendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24594',
    },
    {
        id: '11236',
        ort: 'Tarbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24619',
    },
    {
        id: '11237',
        ort: 'Tarmstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27412',
    },
    {
        id: '11238',
        ort: 'Tarnow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18249',
    },
    {
        id: '11239',
        ort: 'Tarp',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24963',
    },
    {
        id: '11240',
        ort: 'Tasdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24536',
    },
    {
        id: '11241',
        ort: 'Tastungen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37339',
    },
    {
        id: '11242',
        ort: 'Tating',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25881',
    },
    {
        id: '11243',
        ort: 'Tauberbischofsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Main-Tauber-Kreis',
        plz: '97941',
    },
    {
        id: '11244',
        ort: 'Tauberrettersheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97285',
    },
    {
        id: '11245',
        ort: 'Taucha',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04425',
    },
    {
        id: '11246',
        ort: 'Tauche',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15848',
    },
    {
        id: '11247',
        ort: 'Tauer',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03185',
    },
    {
        id: '11248',
        ort: 'Taufkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '82024',
    },
    {
        id: '11249',
        ort: 'Taufkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '84416',
    },
    {
        id: '11250',
        ort: 'Taufkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84574',
    },
    {
        id: '11251',
        ort: 'Taunusstein',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65232',
    },
    {
        id: '11252',
        ort: 'Taura',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09249',
    },
    {
        id: '11253',
        ort: 'Tautenburg',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07778',
    },
    {
        id: '11254',
        ort: 'Tautendorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '11255',
        ort: 'Tautenhain',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07639',
    },
    {
        id: '11256',
        ort: 'Tawern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54456',
    },
    {
        id: '11257',
        ort: 'Techelsdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24220',
    },
    {
        id: '11258',
        ort: 'Techentin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19399',
    },
    {
        id: '11259',
        ort: 'Tecklenburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '49545',
    },
    {
        id: '11260',
        ort: 'Tegau',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07907',
    },
    {
        id: '11261',
        ort: 'Tegernheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93105',
    },
    {
        id: '11262',
        ort: 'Tegernsee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miesbach',
        plz: '83684',
    },
    {
        id: '11263',
        ort: 'Teichland',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03185',
    },
    {
        id: '11264',
        ort: 'Teichwitz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07570',
    },
    {
        id: '11265',
        ort: 'Teisendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Berchtesgadener Land',
        plz: '83317',
    },
    {
        id: '11266',
        ort: 'Teisendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Berchtesgadener Land',
        plz: '83364',
    },
    {
        id: '11267',
        ort: 'Teising',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84576',
    },
    {
        id: '11268',
        ort: 'Teisnach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94244',
    },
    {
        id: '11269',
        ort: 'Teistungen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37339',
    },
    {
        id: '11270',
        ort: 'Teldau',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19273',
    },
    {
        id: '11271',
        ort: 'Telgte',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Warendorf',
        plz: '48291',
    },
    {
        id: '11272',
        ort: 'Tellig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56858',
    },
    {
        id: '11273',
        ort: 'Tellingstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25782',
    },
    {
        id: '11274',
        ort: 'Teltow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14513',
    },
    {
        id: '11275',
        ort: 'Temmels',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54441',
    },
    {
        id: '11276',
        ort: 'Temmen-Ringenwalde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17268',
    },
    {
        id: '11277',
        ort: 'Temnitzquell',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16818',
    },
    {
        id: '11278',
        ort: 'Temnitztal',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16845',
    },
    {
        id: '11279',
        ort: 'Templin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17268',
    },
    {
        id: '11280',
        ort: 'Tengen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78250',
    },
    {
        id: '11281',
        ort: 'Teningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79312',
    },
    {
        id: '11282',
        ort: 'Teningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79331',
    },
    {
        id: '11283',
        ort: 'Tensbüttel-Röst',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25767',
    },
    {
        id: '11284',
        ort: 'Tensfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23824',
    },
    {
        id: '11285',
        ort: 'Teschenmoschel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67806',
    },
    {
        id: '11286',
        ort: 'Tespe',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21395',
    },
    {
        id: '11287',
        ort: 'Tessin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18195',
    },
    {
        id: '11288',
        ort: 'Tessin b. Boizenburg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19258',
    },
    {
        id: '11289',
        ort: 'Testorf-Steinfort',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23936',
    },
    {
        id: '11290',
        ort: 'Tetenbüll',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25882',
    },
    {
        id: '11291',
        ort: 'Tetenhusen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24817',
    },
    {
        id: '11292',
        ort: 'Teterow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17166',
    },
    {
        id: '11293',
        ort: 'Tettau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kronach',
        plz: '96355',
    },
    {
        id: '11294',
        ort: 'Tettau',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '01945',
    },
    {
        id: '11295',
        ort: 'Tettenweis',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94167',
    },
    {
        id: '11296',
        ort: 'Tettnang',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88069',
    },
    {
        id: '11297',
        ort: 'Tettnang',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88079',
    },
    {
        id: '11298',
        ort: 'Teublitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '93158',
    },
    {
        id: '11299',
        ort: 'Teuchern',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06682',
    },
    {
        id: '11300',
        ort: 'Teugn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '93356',
    },
    {
        id: '11301',
        ort: 'Teunz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92552',
    },
    {
        id: '11302',
        ort: 'Teupitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15755',
    },
    {
        id: '11303',
        ort: 'Teuschnitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kronach',
        plz: '96358',
    },
    {
        id: '11304',
        ort: 'Teutschenthal',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06179',
    },
    {
        id: '11305',
        ort: 'Thaden',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '25557',
    },
    {
        id: '11306',
        ort: 'Thaining',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86943',
    },
    {
        id: '11307',
        ort: 'Thale',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '06502',
    },
    {
        id: '11308',
        ort: 'Thaleischweiler-Fröschen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66987',
    },
    {
        id: '11309',
        ort: 'Thalfang',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54424',
    },
    {
        id: '11310',
        ort: 'Thalhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56584',
    },
    {
        id: '11311',
        ort: 'Thalheim/Erzgebirge',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09380',
    },
    {
        id: '11312',
        ort: 'Thallichtenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66871',
    },
    {
        id: '11313',
        ort: 'Thallwitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04808',
    },
    {
        id: '11314',
        ort: 'Thalmassing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93107',
    },
    {
        id: '11315',
        ort: 'Thalmässing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Roth',
        plz: '91177',
    },
    {
        id: '11316',
        ort: 'Thalwenden',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '11317',
        ort: 'Thandorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19217',
    },
    {
        id: '11318',
        ort: 'Thannhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '86470',
    },
    {
        id: '11319',
        ort: 'Thanstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92554',
    },
    {
        id: '11320',
        ort: 'Tharandt',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01737',
    },
    {
        id: '11321',
        ort: 'Thedinghausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Verden',
        plz: '27321',
    },
    {
        id: '11322',
        ort: 'Theilenhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91741',
    },
    {
        id: '11323',
        ort: 'Theilheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97288',
    },
    {
        id: '11324',
        ort: 'Theisbergstegen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66871',
    },
    {
        id: '11325',
        ort: 'Theisseil',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92637',
    },
    {
        id: '11326',
        ort: 'Thelkow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18195',
    },
    {
        id: '11327',
        ort: 'Themar',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98660',
    },
    {
        id: '11328',
        ort: 'Theres',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '97531',
    },
    {
        id: '11329',
        ort: 'Thermalbad Wiesenbad',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09488',
    },
    {
        id: '11330',
        ort: 'Theuma',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08541',
    },
    {
        id: '11331',
        ort: 'Thiendorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01561',
    },
    {
        id: '11332',
        ort: 'Thierhaupten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86672',
    },
    {
        id: '11333',
        ort: 'Thierschneck',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07774',
    },
    {
        id: '11334',
        ort: 'Thiersheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Wunsiedel im Fichtelgebirge',
        plz: '95707',
    },
    {
        id: '11335',
        ort: 'Thierstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Wunsiedel im Fichtelgebirge',
        plz: '95199',
    },
    {
        id: '11336',
        ort: 'Tholey',
        bundesland: 'Saarland',
        landkreis: 'Landkreis St. Wendel',
        plz: '66636',
    },
    {
        id: '11337',
        ort: 'Thomasburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21401',
    },
    {
        id: '11338',
        ort: 'Thomm',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54317',
    },
    {
        id: '11339',
        ort: 'Thonhausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04626',
    },
    {
        id: '11340',
        ort: 'Thörlingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56291',
    },
    {
        id: '11341',
        ort: 'Thörnich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54340',
    },
    {
        id: '11342',
        ort: 'Thuine',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49832',
    },
    {
        id: '11343',
        ort: 'Thulendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18184',
    },
    {
        id: '11344',
        ort: 'Thum',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09419',
    },
    {
        id: '11345',
        ort: 'Thumby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24351',
    },
    {
        id: '11346',
        ort: 'Thundorf i.UFr.',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97711',
    },
    {
        id: '11347',
        ort: 'Thüngen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97289',
    },
    {
        id: '11348',
        ort: 'Thüngersheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97291',
    },
    {
        id: '11349',
        ort: 'Thür',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56743',
    },
    {
        id: '11350',
        ort: 'Thürkow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17168',
    },
    {
        id: '11351',
        ort: 'Thurmansbang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94169',
    },
    {
        id: '11352',
        ort: 'Thurnau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95349',
    },
    {
        id: '11353',
        ort: 'Thyrnau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94136',
    },
    {
        id: '11354',
        ort: 'Tiddische',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38473',
    },
    {
        id: '11355',
        ort: 'Tiefenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84184',
    },
    {
        id: '11356',
        ort: 'Tiefenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94113',
    },
    {
        id: '11357',
        ort: 'Tiefenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93464',
    },
    {
        id: '11358',
        ort: 'Tiefenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55471',
    },
    {
        id: '11359',
        ort: 'Tiefenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88422',
    },
    {
        id: '11360',
        ort: 'Tiefenbronn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75233',
    },
    {
        id: '11361',
        ort: 'Tiefenthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55546',
    },
    {
        id: '11362',
        ort: 'Tiefenthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67311',
    },
    {
        id: '11363',
        ort: 'Tielen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24803',
    },
    {
        id: '11364',
        ort: 'Tielenhemme',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25794',
    },
    {
        id: '11365',
        ort: 'Timmaspe',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24644',
    },
    {
        id: '11366',
        ort: 'Timmendorfer Strand',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23669',
    },
    {
        id: '11367',
        ort: 'Tinningstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25917',
    },
    {
        id: '11368',
        ort: 'Tirpersdorf',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08606',
    },
    {
        id: '11369',
        ort: 'Tirschenreuth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95643',
    },
    {
        id: '11370',
        ort: 'Tissa',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '11371',
        ort: 'Tiste',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27419',
    },
    {
        id: '11372',
        ort: 'Titisee-Neustadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79822',
    },
    {
        id: '11373',
        ort: 'Titting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85135',
    },
    {
        id: '11374',
        ort: 'Tittling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94104',
    },
    {
        id: '11375',
        ort: 'Tittmoning',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '84529',
    },
    {
        id: '11376',
        ort: 'Titz',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Düren',
        plz: '52445',
    },
    {
        id: '11377',
        ort: 'Toddin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '11378',
        ort: 'Todenbüttel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24819',
    },
    {
        id: '11379',
        ort: 'Todendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22965',
    },
    {
        id: '11380',
        ort: 'Todenroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55481',
    },
    {
        id: '11381',
        ort: 'Todesfelde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23826',
    },
    {
        id: '11382',
        ort: 'Todtenweis',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aichach-Friedberg',
        plz: '86447',
    },
    {
        id: '11383',
        ort: 'Todtmoos',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79682',
    },
    {
        id: '11384',
        ort: 'Todtnau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79674',
    },
    {
        id: '11385',
        ort: 'Töging am Inn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84513',
    },
    {
        id: '11386',
        ort: 'Tolk',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24894',
    },
    {
        id: '11387',
        ort: 'Tömmelsdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07819',
    },
    {
        id: '11388',
        ort: 'Tönisvorst',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Viersen',
        plz: '47918',
    },
    {
        id: '11389',
        ort: 'Tonna',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99958',
    },
    {
        id: '11390',
        ort: 'Tonndorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99438',
    },
    {
        id: '11391',
        ort: 'Tönning',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25832',
    },
    {
        id: '11392',
        ort: 'Töpen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95183',
    },
    {
        id: '11393',
        ort: 'Topfstedt',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '99718',
    },
    {
        id: '11394',
        ort: 'Toppenstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21442',
    },
    {
        id: '11395',
        ort: 'Torgau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04860',
    },
    {
        id: '11396',
        ort: 'Torgau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04861',
    },
    {
        id: '11397',
        ort: 'Torgelow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17358',
    },
    {
        id: '11398',
        ort: 'Torgelow am See',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17192',
    },
    {
        id: '11399',
        ort: 'Tornesch',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25436',
    },
    {
        id: '11400',
        ort: 'Tostedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21255',
    },
    {
        id: '11401',
        ort: 'Tosterglope',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21371',
    },
    {
        id: '11402',
        ort: 'Tottleben',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99947',
    },
    {
        id: '11403',
        ort: 'Traben-Trarbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '56841',
    },
    {
        id: '11404',
        ort: 'Trabitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92724',
    },
    {
        id: '11405',
        ort: 'Train',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '93358',
    },
    {
        id: '11406',
        ort: 'Traisen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '11407',
        ort: 'Traitsching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93455',
    },
    {
        id: '11408',
        ort: 'Tramm',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19089',
    },
    {
        id: '11409',
        ort: 'Tramm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21516',
    },
    {
        id: '11410',
        ort: 'Trappenkamp',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24610',
    },
    {
        id: '11411',
        ort: 'Trappstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97633',
    },
    {
        id: '11412',
        ort: 'Trassem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54441',
    },
    {
        id: '11413',
        ort: 'Trassenheide',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17449',
    },
    {
        id: '11414',
        ort: 'Traunreut',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83301',
    },
    {
        id: '11415',
        ort: 'Traunreut',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83368',
    },
    {
        id: '11416',
        ort: 'Traunreut',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83371',
    },
    {
        id: '11417',
        ort: 'Traunreut',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83374',
    },
    {
        id: '11418',
        ort: 'Traunstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83278',
    },
    {
        id: '11419',
        ort: 'Trausnitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92555',
    },
    {
        id: '11420',
        ort: 'Trautskirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '90619',
    },
    {
        id: '11421',
        ort: 'Travenbrück',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23843',
    },
    {
        id: '11422',
        ort: 'Travenhorst',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23827',
    },
    {
        id: '11423',
        ort: 'Traventhal',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23795',
    },
    {
        id: '11424',
        ort: 'Trebbin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Teltow-Fläming',
        plz: '14959',
    },
    {
        id: '11425',
        ort: 'Trebel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29494',
    },
    {
        id: '11426',
        ort: 'Treben',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04617',
    },
    {
        id: '11427',
        ort: 'Trebendorf - Trjebin',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02959',
    },
    {
        id: '11428',
        ort: 'Trebgast',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95367',
    },
    {
        id: '11429',
        ort: 'Trebra',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '99718',
    },
    {
        id: '11430',
        ort: 'Trebsen/Mulde',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04687',
    },
    {
        id: '11431',
        ort: 'Trebur',
        bundesland: 'Hessen',
        landkreis: 'Kreis Groß-Gerau',
        plz: '65468',
    },
    {
        id: '11432',
        ort: 'Trechtingshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55413',
    },
    {
        id: '11433',
        ort: 'Treffelstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93492',
    },
    {
        id: '11434',
        ort: 'Treffurt',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '99830',
    },
    {
        id: '11435',
        ort: 'Treia',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24896',
    },
    {
        id: '11436',
        ort: 'Treis-Karden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56253',
    },
    {
        id: '11437',
        ort: 'Tremsbüttel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22967',
    },
    {
        id: '11438',
        ort: 'Trendelburg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34388',
    },
    {
        id: '11439',
        ort: 'Trennewurth',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25693',
    },
    {
        id: '11440',
        ort: 'Trent',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18569',
    },
    {
        id: '11441',
        ort: 'Treplin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15236',
    },
    {
        id: '11442',
        ort: 'Treuchtlingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91757',
    },
    {
        id: '11443',
        ort: 'Treuen',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08233',
    },
    {
        id: '11444',
        ort: 'Treuenbrietzen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14929',
    },
    {
        id: '11445',
        ort: 'Triberg im Schwarzwald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78098',
    },
    {
        id: '11446',
        ort: 'Tribsees',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18465',
    },
    {
        id: '11447',
        ort: 'Triebel',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08606',
    },
    {
        id: '11448',
        ort: 'Triefenstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97855',
    },
    {
        id: '11449',
        ort: 'Trier',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '54290',
    },
    {
        id: '11450',
        ort: 'Trier',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '54292',
    },
    {
        id: '11451',
        ort: 'Trier',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '54293',
    },
    {
        id: '11452',
        ort: 'Trier',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '54294',
    },
    {
        id: '11453',
        ort: 'Trier',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '54295',
    },
    {
        id: '11454',
        ort: 'Trier',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '54296',
    },
    {
        id: '11455',
        ort: 'Trierscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53520',
    },
    {
        id: '11456',
        ort: 'Trierweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54311',
    },
    {
        id: '11457',
        ort: 'Triftern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84371',
    },
    {
        id: '11458',
        ort: 'Triglitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '16949',
    },
    {
        id: '11459',
        ort: 'Trimbs',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56753',
    },
    {
        id: '11460',
        ort: 'Trimport',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '11461',
        ort: 'Trinwillershagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18320',
    },
    {
        id: '11462',
        ort: 'Trippstadt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67705',
    },
    {
        id: '11463',
        ort: 'Triptis',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07819',
    },
    {
        id: '11464',
        ort: 'Trittau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22946',
    },
    {
        id: '11465',
        ort: 'Trittenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54349',
    },
    {
        id: '11466',
        ort: 'Tröbitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '03253',
    },
    {
        id: '11467',
        ort: 'Tröbnitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '11468',
        ort: 'Tröchtelborn',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99869',
    },
    {
        id: '11469',
        ort: 'Trochtelfingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72818',
    },
    {
        id: '11470',
        ort: 'Trockenborn-Wolfersdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '11471',
        ort: 'Trogen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95183',
    },
    {
        id: '11472',
        ort: 'Troisdorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53840',
    },
    {
        id: '11473',
        ort: 'Troisdorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53842',
    },
    {
        id: '11474',
        ort: 'Troisdorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53844',
    },
    {
        id: '11475',
        ort: 'Trollenhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17039',
    },
    {
        id: '11476',
        ort: 'Tröndel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24321',
    },
    {
        id: '11477',
        ort: 'Trossin',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04880',
    },
    {
        id: '11478',
        ort: 'Trossingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78647',
    },
    {
        id: '11479',
        ort: 'Tröstau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Wunsiedel im Fichtelgebirge',
        plz: '95709',
    },
    {
        id: '11480',
        ort: 'Trostberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83308',
    },
    {
        id: '11481',
        ort: 'Trulben',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66957',
    },
    {
        id: '11482',
        ort: 'Trunkelsberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87779',
    },
    {
        id: '11483',
        ort: 'Tschernitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03130',
    },
    {
        id: '11484',
        ort: 'Tschirn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kronach',
        plz: '96367',
    },
    {
        id: '11485',
        ort: 'Tübingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72070',
    },
    {
        id: '11486',
        ort: 'Tübingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72072',
    },
    {
        id: '11487',
        ort: 'Tübingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72074',
    },
    {
        id: '11488',
        ort: 'Tübingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tübingen',
        plz: '72076',
    },
    {
        id: '11489',
        ort: 'Tuchenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürth',
        plz: '90587',
    },
    {
        id: '11490',
        ort: 'Tülau',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38474',
    },
    {
        id: '11491',
        ort: 'Tümlauer Koog',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25881',
    },
    {
        id: '11492',
        ort: 'Tunau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79677',
    },
    {
        id: '11493',
        ort: 'Tuningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78609',
    },
    {
        id: '11494',
        ort: 'Tuntenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83104',
    },
    {
        id: '11495',
        ort: 'Türkenfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürstenfeldbruck',
        plz: '82299',
    },
    {
        id: '11496',
        ort: 'Türkheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '86842',
    },
    {
        id: '11497',
        ort: 'Turnow-Preilack',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03185',
    },
    {
        id: '11498',
        ort: 'Tussenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '86874',
    },
    {
        id: '11499',
        ort: 'Tüßling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84577',
    },
    {
        id: '11500',
        ort: 'Tutow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17129',
    },
    {
        id: '11501',
        ort: 'Tüttendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24214',
    },
    {
        id: '11502',
        ort: 'Tüttleben',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99869',
    },
    {
        id: '11503',
        ort: 'Tuttlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78532',
    },
    {
        id: '11504',
        ort: 'Tutzing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Starnberg',
        plz: '82327',
    },
    {
        id: '11505',
        ort: 'Tützpatz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17091',
    },
    {
        id: '11506',
        ort: 'Twedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24894',
    },
    {
        id: '11507',
        ort: 'Twist',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49767',
    },
    {
        id: '11508',
        ort: 'Twistetal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '34477',
    },
    {
        id: '11509',
        ort: 'Twistringen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27239',
    },
    {
        id: '11510',
        ort: 'Tyrlaching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84558',
    },
    {
        id: '11511',
        ort: 'Übach-Palenberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Heinsberg',
        plz: '52531',
    },
    {
        id: '11512',
        ort: 'Übereisenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54689',
    },
    {
        id: '11513',
        ort: 'Überherrn',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Saarlouis',
        plz: '66802',
    },
    {
        id: '11514',
        ort: 'Überlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88662',
    },
    {
        id: '11515',
        ort: 'Übersee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83236',
    },
    {
        id: '11516',
        ort: 'Ubstadt-Weiher',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76698',
    },
    {
        id: '11517',
        ort: 'Uchte',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31600',
    },
    {
        id: '11518',
        ort: 'Üchtelhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97532',
    },
    {
        id: '11519',
        ort: 'Uckerfelde',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17291',
    },
    {
        id: '11520',
        ort: 'Ückeritz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17459',
    },
    {
        id: '11521',
        ort: 'Uckerland',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '17337',
    },
    {
        id: '11522',
        ort: 'Udenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55288',
    },
    {
        id: '11523',
        ort: 'Uder',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '11524',
        ort: 'Üdersdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '11525',
        ort: 'Udestedt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99198',
    },
    {
        id: '11526',
        ort: 'Udler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '11527',
        ort: 'Uebigau-Wahrenbrück',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04895',
    },
    {
        id: '11528',
        ort: 'Uebigau-Wahrenbrück',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04924',
    },
    {
        id: '11529',
        ort: 'Uebigau-Wahrenbrück',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Elbe-Elster',
        plz: '04938',
    },
    {
        id: '11530',
        ort: 'Ueckermünde',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17373',
    },
    {
        id: '11531',
        ort: 'Uedem',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '47589',
    },
    {
        id: '11532',
        ort: 'Uehlfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91486',
    },
    {
        id: '11533',
        ort: 'Uehrde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38170',
    },
    {
        id: '11534',
        ort: 'Uelitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19077',
    },
    {
        id: '11535',
        ort: 'Uelsby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24860',
    },
    {
        id: '11536',
        ort: 'Uelsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '49843',
    },
    {
        id: '11537',
        ort: 'Uelversheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55278',
    },
    {
        id: '11538',
        ort: 'Uelvesbüll',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25889',
    },
    {
        id: '11539',
        ort: 'Uelzen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29525',
    },
    {
        id: '11540',
        ort: 'Uersfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '56767',
    },
    {
        id: '11541',
        ort: 'Ueß',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '56767',
    },
    {
        id: '11542',
        ort: 'Uetersen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25436',
    },
    {
        id: '11543',
        ort: 'Uettingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97292',
    },
    {
        id: '11544',
        ort: 'Uetze',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '31311',
    },
    {
        id: '11545',
        ort: 'Uffenheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '97215',
    },
    {
        id: '11546',
        ort: 'Uffing am Staffelsee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82449',
    },
    {
        id: '11547',
        ort: 'Uhingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73066',
    },
    {
        id: '11548',
        ort: 'Uhldingen-Mühlhofen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Bodenseekreis',
        plz: '88690',
    },
    {
        id: '11549',
        ort: 'Uhler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56290',
    },
    {
        id: '11550',
        ort: 'Ühlingen-Birkendorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79777',
    },
    {
        id: '11551',
        ort: 'Uhlstädt-Kirchhasel',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07407',
    },
    {
        id: '11552',
        ort: 'Ulm',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '89073',
    },
    {
        id: '11553',
        ort: 'Ulm',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '89075',
    },
    {
        id: '11554',
        ort: 'Ulm',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '89077',
    },
    {
        id: '11555',
        ort: 'Ulm',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '89079',
    },
    {
        id: '11556',
        ort: 'Ulm',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '89081',
    },
    {
        id: '11557',
        ort: 'Ulm',
        bundesland: 'Baden-Württemberg',
        landkreis: '',
        plz: '89160',
    },
    {
        id: '11558',
        ort: 'Ulmen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56766',
    },
    {
        id: '11559',
        ort: 'Ulmet',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66887',
    },
    {
        id: '11560',
        ort: 'Ulrichstein',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '35327',
    },
    {
        id: '11561',
        ort: 'Ulsnis',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24897',
    },
    {
        id: '11562',
        ort: 'Umkirch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79224',
    },
    {
        id: '11563',
        ort: 'Ummanz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18569',
    },
    {
        id: '11564',
        ort: 'Ummendorf',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39365',
    },
    {
        id: '11565',
        ort: 'Ummendorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88444',
    },
    {
        id: '11566',
        ort: 'Ummern',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '29369',
    },
    {
        id: '11567',
        ort: 'Ummerstadt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98663',
    },
    {
        id: '11568',
        ort: 'Umpferstedt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99441',
    },
    {
        id: '11569',
        ort: 'Undeloh',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21274',
    },
    {
        id: '11570',
        ort: 'Undeloh',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '29640',
    },
    {
        id: '11571',
        ort: 'Undenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55278',
    },
    {
        id: '11572',
        ort: 'Ungerhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87781',
    },
    {
        id: '11573',
        ort: 'Unkel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53572',
    },
    {
        id: '11574',
        ort: 'Unkenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67823',
    },
    {
        id: '11575',
        ort: 'Unlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88527',
    },
    {
        id: '11576',
        ort: 'Unna',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Unna',
        plz: '59423',
    },
    {
        id: '11577',
        ort: 'Unna',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Unna',
        plz: '59425',
    },
    {
        id: '11578',
        ort: 'Unna',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Unna',
        plz: '59427',
    },
    {
        id: '11579',
        ort: 'Unnau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57648',
    },
    {
        id: '11580',
        ort: 'Unsleben',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97618',
    },
    {
        id: '11581',
        ort: 'Unstrut-Hainich',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99991',
    },
    {
        id: '11582',
        ort: 'Unstruttal',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99996',
    },
    {
        id: '11583',
        ort: 'Unterammergau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82497',
    },
    {
        id: '11584',
        ort: 'Unterbodnitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '11585',
        ort: 'Unterbreizbach',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '36414',
    },
    {
        id: '11586',
        ort: 'Unterdießen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86944',
    },
    {
        id: '11587',
        ort: 'Unterdietfurt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84339',
    },
    {
        id: '11588',
        ort: 'Unteregg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87782',
    },
    {
        id: '11589',
        ort: 'Untereisesheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74257',
    },
    {
        id: '11590',
        ort: 'Unterensingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '72669',
    },
    {
        id: '11591',
        ort: 'Unterföhring',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '85774',
    },
    {
        id: '11592',
        ort: 'Untergriesbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94107',
    },
    {
        id: '11593',
        ort: 'Untergruppenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74199',
    },
    {
        id: '11594',
        ort: 'Unterhaching',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '82008',
    },
    {
        id: '11595',
        ort: 'Unterjeckenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67746',
    },
    {
        id: '11596',
        ort: 'Unterkirnach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78089',
    },
    {
        id: '11597',
        ort: 'Unterleinleiter',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91364',
    },
    {
        id: '11598',
        ort: 'Untermarchtal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89617',
    },
    {
        id: '11599',
        ort: 'Untermaßfeld',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98617',
    },
    {
        id: '11600',
        ort: 'Untermeitingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86836',
    },
    {
        id: '11601',
        ort: 'Untermerzbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '96190',
    },
    {
        id: '11602',
        ort: 'Untermünkheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74547',
    },
    {
        id: '11603',
        ort: 'Unterneukirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84579',
    },
    {
        id: '11604',
        ort: 'Unterpleichfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97294',
    },
    {
        id: '11605',
        ort: 'Unterreichenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '75399',
    },
    {
        id: '11606',
        ort: 'Unterreit',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '83567',
    },
    {
        id: '11607',
        ort: 'Unterroth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89299',
    },
    {
        id: '11608',
        ort: 'Unterschleißheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis München',
        plz: '85716',
    },
    {
        id: '11609',
        ort: 'Unterschneidheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73485',
    },
    {
        id: '11610',
        ort: 'Unterschwaningen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91743',
    },
    {
        id: '11611',
        ort: 'Untershausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '11612',
        ort: 'Untersiemau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Coburg',
        plz: '96253',
    },
    {
        id: '11613',
        ort: 'Unterspreewald',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15910',
    },
    {
        id: '11614',
        ort: 'Unterstadion',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89619',
    },
    {
        id: '11615',
        ort: 'Untersteinach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95369',
    },
    {
        id: '11616',
        ort: 'Unterthingau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87647',
    },
    {
        id: '11617',
        ort: 'Unterwachingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89597',
    },
    {
        id: '11618',
        ort: 'Unterwaldhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88379',
    },
    {
        id: '11619',
        ort: 'Unterweißbach',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '98744',
    },
    {
        id: '11620',
        ort: 'Unterwellenborn',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Saalfeld-Rudolstadt',
        plz: '07333',
    },
    {
        id: '11621',
        ort: 'Unterwössen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83246',
    },
    {
        id: '11622',
        ort: 'Untrasried',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87496',
    },
    {
        id: '11623',
        ort: 'Unzenberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55483',
    },
    {
        id: '11624',
        ort: 'Upahl',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23936',
    },
    {
        id: '11625',
        ort: 'Upgant-Schott',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26529',
    },
    {
        id: '11626',
        ort: 'Uphusum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25923',
    },
    {
        id: '11627',
        ort: 'Uplengen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26670',
    },
    {
        id: '11628',
        ort: 'Uppershausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '11629',
        ort: 'Urbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '73655',
    },
    {
        id: '11630',
        ort: 'Urbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '73660',
    },
    {
        id: '11631',
        ort: 'Urbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56317',
    },
    {
        id: '11632',
        ort: 'Urbach',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Nordhausen',
        plz: '99765',
    },
    {
        id: '11633',
        ort: 'Urbar',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56182',
    },
    {
        id: '11634',
        ort: 'Urbar',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55430',
    },
    {
        id: '11635',
        ort: 'Urleben',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99955',
    },
    {
        id: '11636',
        ort: 'Urmersbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56761',
    },
    {
        id: '11637',
        ort: 'Urmitz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56220',
    },
    {
        id: '11638',
        ort: 'Ursberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '86513',
    },
    {
        id: '11639',
        ort: 'Urschmitt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56825',
    },
    {
        id: '11640',
        ort: 'Ursensollen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92289',
    },
    {
        id: '11641',
        ort: 'Urspringen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97857',
    },
    {
        id: '11642',
        ort: 'Ürzig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54539',
    },
    {
        id: '11643',
        ort: 'Usch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54655',
    },
    {
        id: '11644',
        ort: 'Usedom',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17406',
    },
    {
        id: '11645',
        ort: 'Userin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17237',
    },
    {
        id: '11646',
        ort: 'Usingen',
        bundesland: 'Hessen',
        landkreis: 'Hochtaunuskreis',
        plz: '61250',
    },
    {
        id: '11647',
        ort: 'Uslar',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Northeim',
        plz: '37170',
    },
    {
        id: '11648',
        ort: 'Ustersbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86514',
    },
    {
        id: '11649',
        ort: 'Utarp',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26556',
    },
    {
        id: '11650',
        ort: 'Utecht',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19217',
    },
    {
        id: '11651',
        ort: 'Utendorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98617',
    },
    {
        id: '11652',
        ort: 'Utersum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25938',
    },
    {
        id: '11653',
        ort: 'Utscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '11654',
        ort: 'Uttenreuth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91080',
    },
    {
        id: '11655',
        ort: 'Uttenweiler',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88524',
    },
    {
        id: '11656',
        ort: 'Üttfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54619',
    },
    {
        id: '11657',
        ort: 'Utting am Ammersee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86919',
    },
    {
        id: '11658',
        ort: 'Utzedel',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17111',
    },
    {
        id: '11659',
        ort: 'Utzenfeld',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79694',
    },
    {
        id: '11660',
        ort: 'Utzenhain',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56291',
    },
    {
        id: '11661',
        ort: 'Utzerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54552',
    },
    {
        id: '11662',
        ort: 'Üxheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54579',
    },
    {
        id: '11663',
        ort: 'Vaale',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25572',
    },
    {
        id: '11664',
        ort: 'Vaale',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25594',
    },
    {
        id: '11665',
        ort: 'Vaalermoor',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25594',
    },
    {
        id: '11666',
        ort: 'Vacha',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '36404',
    },
    {
        id: '11667',
        ort: 'Vachdorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98617',
    },
    {
        id: '11668',
        ort: 'Vachendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83377',
    },
    {
        id: '11669',
        ort: 'Vahlberg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38170',
    },
    {
        id: '11670',
        ort: 'Vahlbruch',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37647',
    },
    {
        id: '11671',
        ort: 'Vahlde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27389',
    },
    {
        id: '11672',
        ort: 'Vaihingen an der Enz',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '71665',
    },
    {
        id: '11673',
        ort: 'Vallendar',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56179',
    },
    {
        id: '11674',
        ort: 'Valley',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miesbach',
        plz: '83626',
    },
    {
        id: '11675',
        ort: 'Valwig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56812',
    },
    {
        id: '11676',
        ort: 'Varel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Friesland',
        plz: '26316',
    },
    {
        id: '11677',
        ort: 'Varrel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27259',
    },
    {
        id: '11678',
        ort: 'Vastorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21397',
    },
    {
        id: '11679',
        ort: 'Vaterstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85591',
    },
    {
        id: '11680',
        ort: 'Vaterstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85598',
    },
    {
        id: '11681',
        ort: 'Vaterstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85599',
    },
    {
        id: '11682',
        ort: 'Vaterstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85622',
    },
    {
        id: '11683',
        ort: 'Vaterstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85646',
    },
    {
        id: '11684',
        ort: 'Vechelde',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Peine',
        plz: '38159',
    },
    {
        id: '11685',
        ort: 'Vechta',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Vechta',
        plz: '49377',
    },
    {
        id: '11686',
        ort: 'Veelböken',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19205',
    },
    {
        id: '11687',
        ort: 'Veilsdorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98669',
    },
    {
        id: '11688',
        ort: 'Veitsbronn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürth',
        plz: '90587',
    },
    {
        id: '11689',
        ort: 'Veitshöchheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97209',
    },
    {
        id: '11690',
        ort: 'Veitsrodt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '11691',
        ort: 'Velbert',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '42549',
    },
    {
        id: '11692',
        ort: 'Velbert',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '42551',
    },
    {
        id: '11693',
        ort: 'Velbert',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '42553',
    },
    {
        id: '11694',
        ort: 'Velbert',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '42555',
    },
    {
        id: '11695',
        ort: 'Velburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neumarkt in der Oberpfalz',
        plz: '92355',
    },
    {
        id: '11696',
        ort: 'Velden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84149',
    },
    {
        id: '11697',
        ort: 'Velden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91235',
    },
    {
        id: '11698',
        ort: 'Veldenz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54472',
    },
    {
        id: '11699',
        ort: 'Velen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '46342',
    },
    {
        id: '11700',
        ort: 'Velgast',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18469',
    },
    {
        id: '11701',
        ort: 'Vellahn',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19260',
    },
    {
        id: '11702',
        ort: 'Vellberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74541',
    },
    {
        id: '11703',
        ort: 'Vellmar',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34246',
    },
    {
        id: '11704',
        ort: 'Velpke',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38458',
    },
    {
        id: '11705',
        ort: 'Velten',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16727',
    },
    {
        id: '11706',
        ort: 'Veltheim',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38173',
    },
    {
        id: '11707',
        ort: 'Vendersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55578',
    },
    {
        id: '11708',
        ort: 'Venningen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '67482',
    },
    {
        id: '11709',
        ort: 'Ventschow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19417',
    },
    {
        id: '11710',
        ort: 'Verchen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17111',
    },
    {
        id: '11711',
        ort: 'Verden (Aller)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Verden',
        plz: '27283',
    },
    {
        id: '11712',
        ort: 'Veringenstadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '72519',
    },
    {
        id: '11713',
        ort: 'Verl',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Gütersloh',
        plz: '33415',
    },
    {
        id: '11714',
        ort: 'Versmold',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Gütersloh',
        plz: '33775',
    },
    {
        id: '11715',
        ort: 'Vestenbergsgreuth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91487',
    },
    {
        id: '11716',
        ort: 'Vetschau/Spreewald',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberspreewald-Lausitz',
        plz: '03226',
    },
    {
        id: '11717',
        ort: 'Vettelschoß',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53560',
    },
    {
        id: '11718',
        ort: 'Vettweiß',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Düren',
        plz: '52391',
    },
    {
        id: '11719',
        ort: 'Viechtach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94234',
    },
    {
        id: '11720',
        ort: 'Vielank',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19303',
    },
    {
        id: '11721',
        ort: 'Vielbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '11722',
        ort: 'Vielitzsee',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16835',
    },
    {
        id: '11723',
        ort: 'Vierden',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27419',
    },
    {
        id: '11724',
        ort: 'Viereck',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17309',
    },
    {
        id: '11725',
        ort: 'Viereth-Trunstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96191',
    },
    {
        id: '11726',
        ort: 'Vierherrenborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54314',
    },
    {
        id: '11727',
        ort: 'Vierhöfen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21444',
    },
    {
        id: '11728',
        ort: 'Vierkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '85256',
    },
    {
        id: '11729',
        ort: 'Vierkirchen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02894',
    },
    {
        id: '11730',
        ort: 'Vierlinden',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15306',
    },
    {
        id: '11731',
        ort: 'Viernheim',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '68519',
    },
    {
        id: '11732',
        ort: 'Viersen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Viersen',
        plz: '41747',
    },
    {
        id: '11733',
        ort: 'Viersen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Viersen',
        plz: '41748',
    },
    {
        id: '11734',
        ort: 'Viersen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Viersen',
        plz: '41749',
    },
    {
        id: '11735',
        ort: 'Viersen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Viersen',
        plz: '41751',
    },
    {
        id: '11736',
        ort: 'Vilgertshofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86946',
    },
    {
        id: '11737',
        ort: 'Villenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '86637',
    },
    {
        id: '11738',
        ort: 'Villingendorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78667',
    },
    {
        id: '11739',
        ort: 'Villingen-Schwenningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78048',
    },
    {
        id: '11740',
        ort: 'Villingen-Schwenningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78050',
    },
    {
        id: '11741',
        ort: 'Villingen-Schwenningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78052',
    },
    {
        id: '11742',
        ort: 'Villingen-Schwenningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78054',
    },
    {
        id: '11743',
        ort: 'Villingen-Schwenningen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78056',
    },
    {
        id: '11744',
        ort: 'Villmar',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65606',
    },
    {
        id: '11745',
        ort: 'Vilsbiburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84137',
    },
    {
        id: '11746',
        ort: 'Vilseck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '92249',
    },
    {
        id: '11747',
        ort: 'Vilsheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84186',
    },
    {
        id: '11748',
        ort: 'Vilshofen an der Donau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94474',
    },
    {
        id: '11749',
        ort: 'Vinningen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66957',
    },
    {
        id: '11750',
        ort: 'Viöl',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25884',
    },
    {
        id: '11751',
        ort: 'Virneburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '11752',
        ort: 'Visbek',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Vechta',
        plz: '49429',
    },
    {
        id: '11753',
        ort: 'Visselhövede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27374',
    },
    {
        id: '11754',
        ort: 'Vlotho',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Herford',
        plz: '32602',
    },
    {
        id: '11755',
        ort: 'Voerde (Niederrhein)',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '46562',
    },
    {
        id: '11756',
        ort: 'Vogelsang',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15890',
    },
    {
        id: '11757',
        ort: 'Vogelsang-Warsin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17375',
    },
    {
        id: '11758',
        ort: 'Vogelsberg',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99610',
    },
    {
        id: '11759',
        ort: 'Vögelsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21360',
    },
    {
        id: '11760',
        ort: 'Vogt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88267',
    },
    {
        id: '11761',
        ort: 'Vogtareuth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83569',
    },
    {
        id: '11762',
        ort: 'Vogtei',
        bundesland: 'Thüringen',
        landkreis: 'Unstrut-Hainich-Kreis',
        plz: '99986',
    },
    {
        id: '11763',
        ort: 'Vogtsburg im Kaiserstuhl',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79235',
    },
    {
        id: '11764',
        ort: 'Vohburg an der Donau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85088',
    },
    {
        id: '11765',
        ort: 'Vohenstrauß',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92648',
    },
    {
        id: '11766',
        ort: 'Vöhl',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '34516',
    },
    {
        id: '11767',
        ort: 'Vöhrenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Schwarzwald-Baar-Kreis',
        plz: '78147',
    },
    {
        id: '11768',
        ort: 'Vöhringen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89269',
    },
    {
        id: '11769',
        ort: 'Vöhringen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '72189',
    },
    {
        id: '11770',
        ort: 'Voigtsdorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17349',
    },
    {
        id: '11771',
        ort: 'Volkach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97332',
    },
    {
        id: '11772',
        ort: 'Volkenschwand',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '84106',
    },
    {
        id: '11773',
        ort: 'Volkerode',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37308',
    },
    {
        id: '11774',
        ort: 'Völkersweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76857',
    },
    {
        id: '11775',
        ort: 'Volkertshausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Konstanz',
        plz: '78269',
    },
    {
        id: '11776',
        ort: 'Volkerzen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57612',
    },
    {
        id: '11777',
        ort: 'Volkesfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56745',
    },
    {
        id: '11778',
        ort: 'Völklingen',
        bundesland: 'Saarland',
        landkreis: 'Regionalverband Saarbrücken',
        plz: '66333',
    },
    {
        id: '11779',
        ort: 'Volkmannsdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07924',
    },
    {
        id: '11780',
        ort: 'Volkmarsen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '34471',
    },
    {
        id: '11781',
        ort: 'Vollersode',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osterholz',
        plz: '27729',
    },
    {
        id: '11782',
        ort: 'Vollersroda',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99438',
    },
    {
        id: '11783',
        ort: 'Vollerwiek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25836',
    },
    {
        id: '11784',
        ort: 'Vollmersbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '11785',
        ort: 'Vollmershain',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04626',
    },
    {
        id: '11786',
        ort: 'Vollmersweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76744',
    },
    {
        id: '11787',
        ort: 'Vollrathsruhe',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17194',
    },
    {
        id: '11788',
        ort: 'Vollstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25821',
    },
    {
        id: '11789',
        ort: 'Völpke',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39393',
    },
    {
        id: '11790',
        ort: 'Völschow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17129',
    },
    {
        id: '11791',
        ort: 'Volsemenhusen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25693',
    },
    {
        id: '11792',
        ort: 'Voltlage',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49599',
    },
    {
        id: '11793',
        ort: 'Volxheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55546',
    },
    {
        id: '11794',
        ort: 'Vorbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '95519',
    },
    {
        id: '11795',
        ort: 'Vorbeck',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18258',
    },
    {
        id: '11796',
        ort: 'Vorderweidenthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76889',
    },
    {
        id: '11797',
        ort: 'Vordorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38533',
    },
    {
        id: '11798',
        ort: 'Vorra',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '91247',
    },
    {
        id: '11799',
        ort: 'Vörstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79279',
    },
    {
        id: '11800',
        ort: 'Vorwerk',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27412',
    },
    {
        id: '11801',
        ort: 'Vreden',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Borken',
        plz: '48691',
    },
    {
        id: '11802',
        ort: 'Vrees',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49757',
    },
    {
        id: '11803',
        ort: 'Waabs',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24369',
    },
    {
        id: '11804',
        ort: 'Waake',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37136',
    },
    {
        id: '11805',
        ort: 'Waakirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miesbach',
        plz: '83666',
    },
    {
        id: '11806',
        ort: 'Waal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '86875',
    },
    {
        id: '11807',
        ort: 'Wabern',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34590',
    },
    {
        id: '11808',
        ort: 'Wachau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '01454',
    },
    {
        id: '11809',
        ort: 'Wachenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67591',
    },
    {
        id: '11810',
        ort: 'Wachenheim an der Weinstraße',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67157',
    },
    {
        id: '11811',
        ort: 'Wachenroth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '96193',
    },
    {
        id: '11812',
        ort: 'Wachstedt',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37359',
    },
    {
        id: '11813',
        ort: 'Wachtberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '53343',
    },
    {
        id: '11814',
        ort: 'Wachtendonk',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '47669',
    },
    {
        id: '11815',
        ort: 'Wächtersbach',
        bundesland: 'Hessen',
        landkreis: 'Main-Kinzig-Kreis',
        plz: '63607',
    },
    {
        id: '11816',
        ort: 'Wacken',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25596',
    },
    {
        id: '11817',
        ort: 'Wackerow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17498',
    },
    {
        id: '11818',
        ort: 'Wackersberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '83646',
    },
    {
        id: '11819',
        ort: 'Wackersdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92442',
    },
    {
        id: '11820',
        ort: 'Waddeweitz',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29496',
    },
    {
        id: '11821',
        ort: 'Wadern',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Merzig-Wadern',
        plz: '66687',
    },
    {
        id: '11822',
        ort: 'Wadersloh',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Warendorf',
        plz: '59329',
    },
    {
        id: '11823',
        ort: 'Wadgassen',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Saarlouis',
        plz: '66787',
    },
    {
        id: '11824',
        ort: 'Waffenbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93494',
    },
    {
        id: '11825',
        ort: 'Wagenfeld',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49419',
    },
    {
        id: '11826',
        ort: 'Wagenhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56826',
    },
    {
        id: '11827',
        ort: 'Wagenhoff',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38559',
    },
    {
        id: '11828',
        ort: 'Wagersrott',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24392',
    },
    {
        id: '11829',
        ort: 'Waghäusel',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '68753',
    },
    {
        id: '11830',
        ort: 'Waging am See',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83329',
    },
    {
        id: '11831',
        ort: 'Wahlbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55494',
    },
    {
        id: '11832',
        ort: 'Wahlenau',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55491',
    },
    {
        id: '11833',
        ort: 'Wahlhausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '11834',
        ort: 'Wahlheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '11835',
        ort: 'Wahlrod',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57614',
    },
    {
        id: '11836',
        ort: 'Wahlstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23812',
    },
    {
        id: '11837',
        ort: 'Wahlstorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24211',
    },
    {
        id: '11838',
        ort: 'Wahnwegen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66909',
    },
    {
        id: '11839',
        ort: 'Wahrenholz',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '29399',
    },
    {
        id: '11840',
        ort: 'Waiblingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71332',
    },
    {
        id: '11841',
        ort: 'Waiblingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71334',
    },
    {
        id: '11842',
        ort: 'Waiblingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71336',
    },
    {
        id: '11843',
        ort: 'Waibstadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '74915',
    },
    {
        id: '11844',
        ort: 'Waidhaus',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92726',
    },
    {
        id: '11845',
        ort: 'Waidhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neuburg-Schrobenhausen',
        plz: '86579',
    },
    {
        id: '11846',
        ort: 'Waigandshain',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56477',
    },
    {
        id: '11847',
        ort: 'Waigolshausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97534',
    },
    {
        id: '11848',
        ort: 'Wain',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88489',
    },
    {
        id: '11849',
        ort: 'Wain',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '89165',
    },
    {
        id: '11850',
        ort: 'Waischenfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '91344',
    },
    {
        id: '11851',
        ort: 'Wakendorf I',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23845',
    },
    {
        id: '11852',
        ort: 'Wakendorf II',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24558',
    },
    {
        id: '11853',
        ort: 'Walchum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26907',
    },
    {
        id: '11854',
        ort: 'Wald',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87616',
    },
    {
        id: '11855',
        ort: 'Wald',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93192',
    },
    {
        id: '11856',
        ort: 'Wald',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Sigmaringen',
        plz: '88639',
    },
    {
        id: '11857',
        ort: 'Waldachtal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Freudenstadt',
        plz: '72178',
    },
    {
        id: '11858',
        ort: 'Waldalgesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55425',
    },
    {
        id: '11859',
        ort: 'Waldaschaff',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63857',
    },
    {
        id: '11860',
        ort: 'Waldböckelheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55596',
    },
    {
        id: '11861',
        ort: 'Waldbreitbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '56588',
    },
    {
        id: '11862',
        ort: 'Waldbröl',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Oberbergischer Kreis',
        plz: '51545',
    },
    {
        id: '11863',
        ort: 'Waldbronn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76337',
    },
    {
        id: '11864',
        ort: 'Waldbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97295',
    },
    {
        id: '11865',
        ort: 'Waldbrunn',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '65620',
    },
    {
        id: '11866',
        ort: 'Waldbrunn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '69429',
    },
    {
        id: '11867',
        ort: 'Waldburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88289',
    },
    {
        id: '11868',
        ort: 'Waldbüttelbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97297',
    },
    {
        id: '11869',
        ort: 'Walddorfhäslach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72141',
    },
    {
        id: '11870',
        ort: 'Waldeck',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '34513',
    },
    {
        id: '11871',
        ort: 'Waldeck',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '11872',
        ort: 'Waldems',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65529',
    },
    {
        id: '11873',
        ort: 'Waldenbuch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71111',
    },
    {
        id: '11874',
        ort: 'Waldenburg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Hohenlohekreis',
        plz: '74638',
    },
    {
        id: '11875',
        ort: 'Waldenburg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08396',
    },
    {
        id: '11876',
        ort: 'Walderbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93194',
    },
    {
        id: '11877',
        ort: 'Waldershof',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95679',
    },
    {
        id: '11878',
        ort: 'Waldesch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56323',
    },
    {
        id: '11879',
        ort: 'Waldfeucht',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Heinsberg',
        plz: '52525',
    },
    {
        id: '11880',
        ort: 'Waldfischbach-Burgalben',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '67714',
    },
    {
        id: '11881',
        ort: 'Waldgrehweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67822',
    },
    {
        id: '11882',
        ort: 'Waldhambach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76857',
    },
    {
        id: '11883',
        ort: 'Waldheim',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '04736',
    },
    {
        id: '11884',
        ort: 'Waldhof-Falkenstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '11885',
        ort: 'Waldhufen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02906',
    },
    {
        id: '11886',
        ort: 'Waldkappel',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37284',
    },
    {
        id: '11887',
        ort: 'Waldkirch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79183',
    },
    {
        id: '11888',
        ort: 'Waldkirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94065',
    },
    {
        id: '11889',
        ort: 'Waldkraiburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84478',
    },
    {
        id: '11890',
        ort: 'Waldlaubersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55444',
    },
    {
        id: '11891',
        ort: 'Waldleiningen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67693',
    },
    {
        id: '11892',
        ort: 'Wald-Michelbach',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '69483',
    },
    {
        id: '11893',
        ort: 'Waldmohr',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66914',
    },
    {
        id: '11894',
        ort: 'Waldmühlen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '11895',
        ort: 'Waldmünchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93449',
    },
    {
        id: '11896',
        ort: 'Waldorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53498',
    },
    {
        id: '11897',
        ort: 'Waldrach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54320',
    },
    {
        id: '11898',
        ort: 'Waldrohrbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76857',
    },
    {
        id: '11899',
        ort: 'Waldsassen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95652',
    },
    {
        id: '11900',
        ort: 'Waldsee',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Pfalz-Kreis',
        plz: '67165',
    },
    {
        id: '11901',
        ort: 'Waldshut-Tiengen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79761',
    },
    {
        id: '11902',
        ort: 'Waldsieversdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15377',
    },
    {
        id: '11903',
        ort: 'Waldsolms',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35647',
    },
    {
        id: '11904',
        ort: 'Waldstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89367',
    },
    {
        id: '11905',
        ort: 'Waldstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73550',
    },
    {
        id: '11906',
        ort: 'Waldthurn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92727',
    },
    {
        id: '11907',
        ort: 'Waldweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54429',
    },
    {
        id: '11908',
        ort: 'Walhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56865',
    },
    {
        id: '11909',
        ort: 'Walheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ludwigsburg',
        plz: '74399',
    },
    {
        id: '11910',
        ort: 'Walkendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17179',
    },
    {
        id: '11911',
        ort: 'Walkenried',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37445',
    },
    {
        id: '11912',
        ort: 'Walkertshofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86877',
    },
    {
        id: '11913',
        ort: 'Walksfelde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23896',
    },
    {
        id: '11914',
        ort: 'Walldorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69190',
    },
    {
        id: '11915',
        ort: 'Walldürn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '63928',
    },
    {
        id: '11916',
        ort: 'Walldürn',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '74731',
    },
    {
        id: '11917',
        ort: 'Wallen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25788',
    },
    {
        id: '11918',
        ort: 'Wallenborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '11919',
        ort: 'Wallendorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54675',
    },
    {
        id: '11920',
        ort: 'Wallenfels',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kronach',
        plz: '96346',
    },
    {
        id: '11921',
        ort: 'Wallenhorst',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osnabrück',
        plz: '49134',
    },
    {
        id: '11922',
        ort: 'Wallerfangen',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Saarlouis',
        plz: '66798',
    },
    {
        id: '11923',
        ort: 'Wallerfing',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94574',
    },
    {
        id: '11924',
        ort: 'Wallersdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dingolfing-Landau',
        plz: '94522',
    },
    {
        id: '11925',
        ort: 'Wallersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54597',
    },
    {
        id: '11926',
        ort: 'Wallerstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86757',
    },
    {
        id: '11927',
        ort: 'Wallertheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55578',
    },
    {
        id: '11928',
        ort: 'Wallgau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Garmisch-Partenkirchen',
        plz: '82499',
    },
    {
        id: '11929',
        ort: 'Wallhalben',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66917',
    },
    {
        id: '11930',
        ort: 'Wallhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74599',
    },
    {
        id: '11931',
        ort: 'Wallhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '11932',
        ort: 'Wallhausen',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06528',
    },
    {
        id: '11933',
        ort: 'Wallmenroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57584',
    },
    {
        id: '11934',
        ort: 'Wallmerod',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56414',
    },
    {
        id: '11935',
        ort: 'Wallsbüll',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24980',
    },
    {
        id: '11936',
        ort: 'Wallscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54531',
    },
    {
        id: '11937',
        ort: 'Wallstawe',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Altmarkkreis Salzwedel',
        plz: '29413',
    },
    {
        id: '11938',
        ort: 'Walluf',
        bundesland: 'Hessen',
        landkreis: 'Rheingau-Taunus-Kreis',
        plz: '65396',
    },
    {
        id: '11939',
        ort: 'Walow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17209',
    },
    {
        id: '11940',
        ort: 'Walpernhain',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07613',
    },
    {
        id: '11941',
        ort: 'Walpertskirchen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '85469',
    },
    {
        id: '11942',
        ort: 'Walschleben',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99189',
    },
    {
        id: '11943',
        ort: 'Walsdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54578',
    },
    {
        id: '11944',
        ort: 'Walsdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96194',
    },
    {
        id: '11945',
        ort: 'Walshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66484',
    },
    {
        id: '11946',
        ort: 'Walsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76833',
    },
    {
        id: '11947',
        ort: 'Walsleben',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16818',
    },
    {
        id: '11948',
        ort: 'Walsrode',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29664',
    },
    {
        id: '11949',
        ort: 'Walsrode',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29699',
    },
    {
        id: '11950',
        ort: 'Waltenhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '86480',
    },
    {
        id: '11951',
        ort: 'Waltenhofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87448',
    },
    {
        id: '11952',
        ort: 'Walterschen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57632',
    },
    {
        id: '11953',
        ort: 'Waltersdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '11954',
        ort: 'Waltershausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99880',
    },
    {
        id: '11955',
        ort: 'Walting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85137',
    },
    {
        id: '11956',
        ort: 'Waltrop',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Recklinghausen',
        plz: '45731',
    },
    {
        id: '11957',
        ort: 'Walzbachtal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '75045',
    },
    {
        id: '11958',
        ort: 'Wanderup',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24997',
    },
    {
        id: '11959',
        ort: 'Wandlitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16348',
    },
    {
        id: '11960',
        ort: 'Wanfried',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37281',
    },
    {
        id: '11961',
        ort: 'Wang',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85368',
    },
    {
        id: '11962',
        ort: 'Wangelau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21483',
    },
    {
        id: '11963',
        ort: 'Wangelnstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Holzminden',
        plz: '37627',
    },
    {
        id: '11964',
        ort: 'Wangels',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Ostholstein',
        plz: '23758',
    },
    {
        id: '11965',
        ort: 'Wangen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73117',
    },
    {
        id: '11966',
        ort: 'Wangen im Allgäu',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88239',
    },
    {
        id: '11967',
        ort: 'Wangerland',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Friesland',
        plz: '26434',
    },
    {
        id: '11968',
        ort: 'Wangerooge',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Friesland',
        plz: '26486',
    },
    {
        id: '11969',
        ort: 'Wankendorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24601',
    },
    {
        id: '11970',
        ort: 'Wanna',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21776',
    },
    {
        id: '11971',
        ort: 'Wannweil',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '72827',
    },
    {
        id: '11972',
        ort: 'Wanzleben-Börde',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39164',
    },
    {
        id: '11973',
        ort: 'Wapelfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24594',
    },
    {
        id: '11974',
        ort: 'Warberg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38378',
    },
    {
        id: '11975',
        ort: 'Warburg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Höxter',
        plz: '34414',
    },
    {
        id: '11976',
        ort: 'Wardenburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Oldenburg',
        plz: '26203',
    },
    {
        id: '11977',
        ort: 'Warder',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24646',
    },
    {
        id: '11978',
        ort: 'Wardow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18299',
    },
    {
        id: '11979',
        ort: 'Warendorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Warendorf',
        plz: '48231',
    },
    {
        id: '11980',
        ort: 'Waren (Müritz)',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17192',
    },
    {
        id: '11981',
        ort: 'Warin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19417',
    },
    {
        id: '11982',
        ort: 'Warlitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19230',
    },
    {
        id: '11983',
        ort: 'Warlow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19288',
    },
    {
        id: '11984',
        ort: 'Warmensteinach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95485',
    },
    {
        id: '11985',
        ort: 'Warmsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31606',
    },
    {
        id: '11986',
        ort: 'Warmsroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55442',
    },
    {
        id: '11987',
        ort: 'Warnau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24250',
    },
    {
        id: '11988',
        ort: 'Warngau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miesbach',
        plz: '83627',
    },
    {
        id: '11989',
        ort: 'Warnkenhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '17168',
    },
    {
        id: '11990',
        ort: 'Warnow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23936',
    },
    {
        id: '11991',
        ort: 'Warnow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23948',
    },
    {
        id: '11992',
        ort: 'Warnow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18249',
    },
    {
        id: '11993',
        ort: 'Warpe',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '27333',
    },
    {
        id: '11994',
        ort: 'Warrenzin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17111',
    },
    {
        id: '11995',
        ort: 'Warringholz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25560',
    },
    {
        id: '11996',
        ort: 'Warsow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19075',
    },
    {
        id: '11997',
        ort: 'Warstein',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Soest',
        plz: '59581',
    },
    {
        id: '11998',
        ort: 'Wartenberg',
        bundesland: 'Hessen',
        landkreis: 'Vogelsbergkreis',
        plz: '36367',
    },
    {
        id: '11999',
        ort: 'Wartenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '85456',
    },
    {
        id: '12000',
        ort: 'Wartenberg-Rohrbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67681',
    },
    {
        id: '12001',
        ort: 'Warthausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Biberach',
        plz: '88447',
    },
    {
        id: '12002',
        ort: 'Wartmannsroth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97797',
    },
    {
        id: '12003',
        ort: 'Warwerort',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25761',
    },
    {
        id: '12004',
        ort: 'Wasbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24647',
    },
    {
        id: '12005',
        ort: 'Wasbüttel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38553',
    },
    {
        id: '12006',
        ort: 'Wäschenbeuren',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73116',
    },
    {
        id: '12007',
        ort: 'Wäschenbeuren',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73547',
    },
    {
        id: '12008',
        ort: 'Wasenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56370',
    },
    {
        id: '12009',
        ort: 'Wassenach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '56653',
    },
    {
        id: '12010',
        ort: 'Wassenberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Heinsberg',
        plz: '41849',
    },
    {
        id: '12011',
        ort: 'Wasserburg am Inn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rosenheim',
        plz: '83512',
    },
    {
        id: '12012',
        ort: 'Wasserburg (Bodensee)',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88142',
    },
    {
        id: '12013',
        ort: 'Wasserliesch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54332',
    },
    {
        id: '12014',
        ort: 'Wasserlosen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97535',
    },
    {
        id: '12015',
        ort: 'Wasserthaleben',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '99718',
    },
    {
        id: '12016',
        ort: 'Wassertrüdingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91717',
    },
    {
        id: '12017',
        ort: 'Wasungen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98634',
    },
    {
        id: '12018',
        ort: 'Wathlingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29339',
    },
    {
        id: '12019',
        ort: 'Wattenbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24582',
    },
    {
        id: '12020',
        ort: 'Wattendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96196',
    },
    {
        id: '12021',
        ort: 'Wattenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67319',
    },
    {
        id: '12022',
        ort: 'Watzerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54595',
    },
    {
        id: '12023',
        ort: 'Wawern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54612',
    },
    {
        id: '12024',
        ort: 'Wawern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54441',
    },
    {
        id: '12025',
        ort: 'Waxweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54649',
    },
    {
        id: '12026',
        ort: 'Wechingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86759',
    },
    {
        id: '12027',
        ort: 'Wechselburg',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09306',
    },
    {
        id: '12028',
        ort: 'Weddelbrook',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24576',
    },
    {
        id: '12029',
        ort: 'Weddingstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25795',
    },
    {
        id: '12030',
        ort: 'Wedel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '22880',
    },
    {
        id: '12031',
        ort: 'Wedemark',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30900',
    },
    {
        id: '12032',
        ort: 'Wedendorfersee',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19217',
    },
    {
        id: '12033',
        ort: 'Weede',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23795',
    },
    {
        id: '12034',
        ort: 'Weener',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26826',
    },
    {
        id: '12035',
        ort: 'Wees',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24999',
    },
    {
        id: '12036',
        ort: 'Weesby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24994',
    },
    {
        id: '12037',
        ort: 'Weeze',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Kleve',
        plz: '47652',
    },
    {
        id: '12038',
        ort: 'Wefensleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39365',
    },
    {
        id: '12039',
        ort: 'Wegberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Heinsberg',
        plz: '41844',
    },
    {
        id: '12040',
        ort: 'Wegeleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38828',
    },
    {
        id: '12041',
        ort: 'Wegscheid',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94110',
    },
    {
        id: '12042',
        ort: 'Wehingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78564',
    },
    {
        id: '12043',
        ort: 'Wehlen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01829',
    },
    {
        id: '12044',
        ort: 'Wehnde',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37339',
    },
    {
        id: '12045',
        ort: 'Wehr',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '56653',
    },
    {
        id: '12046',
        ort: 'Wehr',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79664',
    },
    {
        id: '12047',
        ort: 'Wehrbleck',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '27259',
    },
    {
        id: '12048',
        ort: 'Wehrbleck',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49453',
    },
    {
        id: '12049',
        ort: 'Wehretal',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37287',
    },
    {
        id: '12050',
        ort: 'Wehrheim',
        bundesland: 'Hessen',
        landkreis: 'Hochtaunuskreis',
        plz: '61273',
    },
    {
        id: '12051',
        ort: 'Wehringen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86517',
    },
    {
        id: '12052',
        ort: 'Weibern',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '56745',
    },
    {
        id: '12053',
        ort: 'Weibersbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63879',
    },
    {
        id: '12054',
        ort: 'Weichering',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neuburg-Schrobenhausen',
        plz: '86706',
    },
    {
        id: '12055',
        ort: 'Weichs',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dachau',
        plz: '85258',
    },
    {
        id: '12056',
        ort: 'Weida',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07570',
    },
    {
        id: '12057',
        ort: 'Weiden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '12058',
        ort: 'Weidenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91746',
    },
    {
        id: '12059',
        ort: 'Weidenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56355',
    },
    {
        id: '12060',
        ort: 'Weidenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54570',
    },
    {
        id: '12061',
        ort: 'Weidenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bayreuth',
        plz: '95466',
    },
    {
        id: '12062',
        ort: 'Weidenhahn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '12063',
        ort: 'Weiden in der Oberpfalz',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '92637',
    },
    {
        id: '12064',
        ort: 'Weidenstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89197',
    },
    {
        id: '12065',
        ort: 'Weidenthal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67475',
    },
    {
        id: '12066',
        ort: 'Weidhausen b.Coburg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Coburg',
        plz: '96279',
    },
    {
        id: '12067',
        ort: 'Weiding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93495',
    },
    {
        id: '12068',
        ort: 'Weiding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92557',
    },
    {
        id: '12069',
        ort: 'Weidingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '12070',
        ort: 'Weigendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Amberg-Sulzbach',
        plz: '91249',
    },
    {
        id: '12071',
        ort: 'Weigenheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '97215',
    },
    {
        id: '12072',
        ort: 'Weihenzell',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91629',
    },
    {
        id: '12073',
        ort: 'Weiherhammer',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92700',
    },
    {
        id: '12074',
        ort: 'Weiherhammer',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92729',
    },
    {
        id: '12075',
        ort: 'Weihmichl',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84107',
    },
    {
        id: '12076',
        ort: 'Weikersheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Main-Tauber-Kreis',
        plz: '97990',
    },
    {
        id: '12077',
        ort: 'Weil',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86947',
    },
    {
        id: '12078',
        ort: 'Weil am Rhein',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79576',
    },
    {
        id: '12079',
        ort: 'Weilar',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '36457',
    },
    {
        id: '12080',
        ort: 'Weilbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63937',
    },
    {
        id: '12081',
        ort: 'Weilburg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '35781',
    },
    {
        id: '12082',
        ort: 'Weil der Stadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71263',
    },
    {
        id: '12083',
        ort: 'Weilen unter den Rinnen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72367',
    },
    {
        id: '12084',
        ort: 'Weiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '12085',
        ort: 'Weiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56825',
    },
    {
        id: '12086',
        ort: 'Weilerbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kaiserslautern',
        plz: '67685',
    },
    {
        id: '12087',
        ort: 'Weiler bei Bingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55413',
    },
    {
        id: '12088',
        ort: 'Weiler bei Monzingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55627',
    },
    {
        id: '12089',
        ort: 'Weilersbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91365',
    },
    {
        id: '12090',
        ort: 'Weiler-Simmerberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88171',
    },
    {
        id: '12091',
        ort: 'Weilerswist',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Euskirchen',
        plz: '53919',
    },
    {
        id: '12092',
        ort: 'Weilheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79809',
    },
    {
        id: '12093',
        ort: 'Weilheim an der Teck',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73235',
    },
    {
        id: '12094',
        ort: 'Weilheim in Oberbayern',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82362',
    },
    {
        id: '12095',
        ort: 'Weil im Schönbuch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71093',
    },
    {
        id: '12096',
        ort: 'Weilmünster',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '35789',
    },
    {
        id: '12097',
        ort: 'Weilrod',
        bundesland: 'Hessen',
        landkreis: 'Hochtaunuskreis',
        plz: '61276',
    },
    {
        id: '12098',
        ort: 'Weiltingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91744',
    },
    {
        id: '12099',
        ort: 'Weimar',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99423',
    },
    {
        id: '12100',
        ort: 'Weimar',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99425',
    },
    {
        id: '12101',
        ort: 'Weimar',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99427',
    },
    {
        id: '12102',
        ort: 'Weimar',
        bundesland: 'Thüringen',
        landkreis: '',
        plz: '99428',
    },
    {
        id: '12103',
        ort: 'Weimar (Lahn)',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35096',
    },
    {
        id: '12104',
        ort: 'Weinähr',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56379',
    },
    {
        id: '12105',
        ort: 'Weinbach',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Limburg-Weilburg',
        plz: '35796',
    },
    {
        id: '12106',
        ort: 'Weinböhla',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01689',
    },
    {
        id: '12107',
        ort: 'Weingarten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '76356',
    },
    {
        id: '12108',
        ort: 'Weingarten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88250',
    },
    {
        id: '12109',
        ort: 'Weingarten (Pfalz)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '67366',
    },
    {
        id: '12110',
        ort: 'Weinheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69469',
    },
    {
        id: '12111',
        ort: 'Weinolsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55278',
    },
    {
        id: '12112',
        ort: 'Weinsberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74189',
    },
    {
        id: '12113',
        ort: 'Weinsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54595',
    },
    {
        id: '12114',
        ort: 'Weinsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '12115',
        ort: 'Weinstadt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71384',
    },
    {
        id: '12116',
        ort: 'Weira',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07806',
    },
    {
        id: '12117',
        ort: 'Weischlitz',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08538',
    },
    {
        id: '12118',
        ort: 'Weisel',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56348',
    },
    {
        id: '12119',
        ort: 'Weisen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '19322',
    },
    {
        id: '12120',
        ort: 'Weisenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rastatt',
        plz: '76599',
    },
    {
        id: '12121',
        ort: 'Weisendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erlangen-Höchstadt',
        plz: '91085',
    },
    {
        id: '12122',
        ort: 'Weisenheim am Berg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67273',
    },
    {
        id: '12123',
        ort: 'Weisenheim am Sand',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Dürkheim',
        plz: '67256',
    },
    {
        id: '12124',
        ort: 'Weiskirchen',
        bundesland: 'Saarland',
        landkreis: 'Landkreis Merzig-Wadern',
        plz: '66709',
    },
    {
        id: '12125',
        ort: 'Weismain',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lichtenfels',
        plz: '96260',
    },
    {
        id: '12126',
        ort: 'Weissach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Böblingen',
        plz: '71287',
    },
    {
        id: '12127',
        ort: 'Weissach im Tal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71554',
    },
    {
        id: '12128',
        ort: 'Weißbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Hohenlohekreis',
        plz: '74679',
    },
    {
        id: '12129',
        ort: 'Weißbach',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07646',
    },
    {
        id: '12130',
        ort: 'Weißdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95237',
    },
    {
        id: '12131',
        ort: 'Weißenberg - Wóspork',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02627',
    },
    {
        id: '12132',
        ort: 'Weißenborn',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37299',
    },
    {
        id: '12133',
        ort: 'Weißenborn',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07639',
    },
    {
        id: '12134',
        ort: 'Weißenborn/Erzgebirge',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09600',
    },
    {
        id: '12135',
        ort: 'Weißenbrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kronach',
        plz: '96369',
    },
    {
        id: '12136',
        ort: 'Weißenburg i. Bay.',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91781',
    },
    {
        id: '12137',
        ort: 'Weißendorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07950',
    },
    {
        id: '12138',
        ort: 'Weißenfels',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06667',
    },
    {
        id: '12139',
        ort: 'Weißenfels',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06688',
    },
    {
        id: '12140',
        ort: 'Weißenhorn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neu-Ulm',
        plz: '89264',
    },
    {
        id: '12141',
        ort: 'Weißenohe',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91367',
    },
    {
        id: '12142',
        ort: 'Weißensberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Lindau',
        plz: '88138',
    },
    {
        id: '12143',
        ort: 'Weißensee',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99631',
    },
    {
        id: '12144',
        ort: 'Weißenstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Wunsiedel im Fichtelgebirge',
        plz: '95163',
    },
    {
        id: '12145',
        ort: 'Weißenthurm',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56575',
    },
    {
        id: '12146',
        ort: 'Weißkeißel – Wuskidź',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02957',
    },
    {
        id: '12147',
        ort: 'Weißwasser/O.L. - Běła Woda',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02943',
    },
    {
        id: '12148',
        ort: 'Weisweil',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79367',
    },
    {
        id: '12149',
        ort: 'Weitefeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57586',
    },
    {
        id: '12150',
        ort: 'Weitendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19412',
    },
    {
        id: '12151',
        ort: 'Weitenhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18461',
    },
    {
        id: '12152',
        ort: 'Weitenhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17498',
    },
    {
        id: '12153',
        ort: 'Weitersbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55624',
    },
    {
        id: '12154',
        ort: 'Weitersborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55629',
    },
    {
        id: '12155',
        ort: 'Weitersburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56191',
    },
    {
        id: '12156',
        ort: 'Weiterstadt',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Darmstadt-Dieburg',
        plz: '64331',
    },
    {
        id: '12157',
        ort: 'Weitersweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67808',
    },
    {
        id: '12158',
        ort: 'Weitnau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87480',
    },
    {
        id: '12159',
        ort: 'Weitramsdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Coburg',
        plz: '96479',
    },
    {
        id: '12160',
        ort: 'Welcherath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '53539',
    },
    {
        id: '12161',
        ort: 'Welchweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '66887',
    },
    {
        id: '12162',
        ort: 'Welden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86465',
    },
    {
        id: '12163',
        ort: 'Welgesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55576',
    },
    {
        id: '12164',
        ort: 'Welkenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57644',
    },
    {
        id: '12165',
        ort: 'Welle',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21261',
    },
    {
        id: '12166',
        ort: 'Wellen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54441',
    },
    {
        id: '12167',
        ort: 'Wellendingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78669',
    },
    {
        id: '12168',
        ort: 'Wellheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '91809',
    },
    {
        id: '12169',
        ort: 'Welling',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56753',
    },
    {
        id: '12170',
        ort: 'Welmbüttel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25782',
    },
    {
        id: '12171',
        ort: 'Welschbillig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54298',
    },
    {
        id: '12172',
        ort: 'Welschenbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56729',
    },
    {
        id: '12173',
        ort: 'Welschneudorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56412',
    },
    {
        id: '12174',
        ort: 'Welt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25836',
    },
    {
        id: '12175',
        ort: 'Welterod',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '12176',
        ort: 'Weltersburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '12177',
        ort: 'Welver',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Soest',
        plz: '59514',
    },
    {
        id: '12178',
        ort: 'Welzheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '73553',
    },
    {
        id: '12179',
        ort: 'Welzheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '73635',
    },
    {
        id: '12180',
        ort: 'Welzheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '73642',
    },
    {
        id: '12181',
        ort: 'Welzheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '73667',
    },
    {
        id: '12182',
        ort: 'Welzow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03119',
    },
    {
        id: '12183',
        ort: 'Wembach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79677',
    },
    {
        id: '12184',
        ort: 'Wemding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86650',
    },
    {
        id: '12185',
        ort: 'Wendeburg',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Peine',
        plz: '38176',
    },
    {
        id: '12186',
        ort: 'Wendelsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55234',
    },
    {
        id: '12187',
        ort: 'Wendelstein',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Roth',
        plz: '90530',
    },
    {
        id: '12188',
        ort: 'Wenden',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Olpe',
        plz: '57482',
    },
    {
        id: '12189',
        ort: 'Wendisch Baggendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18513',
    },
    {
        id: '12190',
        ort: 'Wendisch Evern',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21403',
    },
    {
        id: '12191',
        ort: 'Wendisch Rietz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15864',
    },
    {
        id: '12192',
        ort: 'Wendlingen am Neckar',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73240',
    },
    {
        id: '12193',
        ort: 'Wendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18442',
    },
    {
        id: '12194',
        ort: 'Wendtorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24235',
    },
    {
        id: '12195',
        ort: 'Weng',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84187',
    },
    {
        id: '12196',
        ort: 'Wennbüttel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25767',
    },
    {
        id: '12197',
        ort: 'Wennigsen (Deister)',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '30974',
    },
    {
        id: '12198',
        ort: 'Wenningstedt-Braderup (Sylt)',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25996',
    },
    {
        id: '12199',
        ort: 'Wensin',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23827',
    },
    {
        id: '12200',
        ort: 'Wentorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23898',
    },
    {
        id: '12201',
        ort: 'Wentorf bei Hamburg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21465',
    },
    {
        id: '12202',
        ort: 'Wenzenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93173',
    },
    {
        id: '12203',
        ort: 'Wenzendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21279',
    },
    {
        id: '12204',
        ort: 'Wenzlow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14778',
    },
    {
        id: '12205',
        ort: 'Werbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Main-Tauber-Kreis',
        plz: '97956',
    },
    {
        id: '12206',
        ort: 'Werben',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03096',
    },
    {
        id: '12207',
        ort: 'Werben (Elbe)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39539',
    },
    {
        id: '12208',
        ort: 'Werben (Elbe)',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39615',
    },
    {
        id: '12209',
        ort: 'Werda',
        bundesland: 'Sachsen',
        landkreis: 'Vogtlandkreis',
        plz: '08223',
    },
    {
        id: '12210',
        ort: 'Werdau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08412',
    },
    {
        id: '12211',
        ort: 'Werder',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19386',
    },
    {
        id: '12212',
        ort: 'Werder',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17089',
    },
    {
        id: '12213',
        ort: 'Werder (Havel)',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14542',
    },
    {
        id: '12214',
        ort: 'Werdohl',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Märkischer Kreis',
        plz: '58791',
    },
    {
        id: '12215',
        ort: 'Werdum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26427',
    },
    {
        id: '12216',
        ort: 'Werkhausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57635',
    },
    {
        id: '12217',
        ort: 'Werl',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Soest',
        plz: '59457',
    },
    {
        id: '12218',
        ort: 'Werlte',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49757',
    },
    {
        id: '12219',
        ort: 'Wermelskirchen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rheinisch-Bergischer Kreis',
        plz: '42929',
    },
    {
        id: '12220',
        ort: 'Wermsdorf',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04779',
    },
    {
        id: '12221',
        ort: 'Wernau (Neckar)',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '73249',
    },
    {
        id: '12222',
        ort: 'Wernberg-Köblitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92533',
    },
    {
        id: '12223',
        ort: 'Wernburg',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07381',
    },
    {
        id: '12224',
        ort: 'Werne',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Unna',
        plz: '59368',
    },
    {
        id: '12225',
        ort: 'Werneck',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97440',
    },
    {
        id: '12226',
        ort: 'Wernersberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76857',
    },
    {
        id: '12227',
        ort: 'Werneuchen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16356',
    },
    {
        id: '12228',
        ort: 'Wernigerode',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38855',
    },
    {
        id: '12229',
        ort: 'Wernigerode',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38875',
    },
    {
        id: '12230',
        ort: 'Wernigerode',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Harz',
        plz: '38879',
    },
    {
        id: '12231',
        ort: 'Werningshausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99634',
    },
    {
        id: '12232',
        ort: 'Weroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56414',
    },
    {
        id: '12233',
        ort: 'Werpeloh',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49751',
    },
    {
        id: '12234',
        ort: 'Werra-Suhl-Tal',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '99837',
    },
    {
        id: '12235',
        ort: 'Wershofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53520',
    },
    {
        id: '12236',
        ort: 'Wertach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87497',
    },
    {
        id: '12237',
        ort: 'Wertheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Main-Tauber-Kreis',
        plz: '97877',
    },
    {
        id: '12238',
        ort: 'Werther',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Nordhausen',
        plz: '99735',
    },
    {
        id: '12239',
        ort: 'Werther (Westf.)',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Gütersloh',
        plz: '33824',
    },
    {
        id: '12240',
        ort: 'Wertingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '86637',
    },
    {
        id: '12241',
        ort: 'Wesel',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '46483',
    },
    {
        id: '12242',
        ort: 'Wesel',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '46485',
    },
    {
        id: '12243',
        ort: 'Wesel',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '46487',
    },
    {
        id: '12244',
        ort: 'Weselberg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66919',
    },
    {
        id: '12245',
        ort: 'Wesenberg',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23858',
    },
    {
        id: '12246',
        ort: 'Wesenberg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17255',
    },
    {
        id: '12247',
        ort: 'Wesendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '29392',
    },
    {
        id: '12248',
        ort: 'Wesertal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34399',
    },
    {
        id: '12249',
        ort: 'Wesselburen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25764',
    },
    {
        id: '12250',
        ort: 'Wesselburener Deichhausen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25764',
    },
    {
        id: '12251',
        ort: 'Wesselburenerkoog',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25764',
    },
    {
        id: '12252',
        ort: 'Wesseling',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Erft-Kreis',
        plz: '50389',
    },
    {
        id: '12253',
        ort: 'Wesseln',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25746',
    },
    {
        id: '12254',
        ort: 'Weßling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Starnberg',
        plz: '82234',
    },
    {
        id: '12255',
        ort: 'Wessobrunn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82405',
    },
    {
        id: '12256',
        ort: 'Weste',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29599',
    },
    {
        id: '12257',
        ort: 'Westendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86707',
    },
    {
        id: '12258',
        ort: 'Westendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ostallgäu',
        plz: '87679',
    },
    {
        id: '12259',
        ort: 'Westensee',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24259',
    },
    {
        id: '12260',
        ort: 'Westerau',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23847',
    },
    {
        id: '12261',
        ort: 'Westerborstel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25782',
    },
    {
        id: '12262',
        ort: 'Westerburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56457',
    },
    {
        id: '12263',
        ort: 'Westerdeichstrich',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25761',
    },
    {
        id: '12264',
        ort: 'Westergellersen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21394',
    },
    {
        id: '12265',
        ort: 'Westerheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87784',
    },
    {
        id: '12266',
        ort: 'Westerheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '72589',
    },
    {
        id: '12267',
        ort: 'Westerhever',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25881',
    },
    {
        id: '12268',
        ort: 'Westerholt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26556',
    },
    {
        id: '12269',
        ort: 'Westerholz',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24977',
    },
    {
        id: '12270',
        ort: 'Westerhorn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Pinneberg',
        plz: '25364',
    },
    {
        id: '12271',
        ort: 'Westerkappeln',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '49492',
    },
    {
        id: '12272',
        ort: 'Westermoor',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25597',
    },
    {
        id: '12273',
        ort: 'Westerngrund',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63825',
    },
    {
        id: '12274',
        ort: 'Westernohe',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '12275',
        ort: 'Wester-Ohrstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25885',
    },
    {
        id: '12276',
        ort: 'Westerrade',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23815',
    },
    {
        id: '12277',
        ort: 'Westerrönfeld',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24784',
    },
    {
        id: '12278',
        ort: 'Westerstede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Ammerland',
        plz: '26655',
    },
    {
        id: '12279',
        ort: 'Westerstetten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Alb-Donau-Kreis',
        plz: '89198',
    },
    {
        id: '12280',
        ort: 'Westertimke',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27412',
    },
    {
        id: '12281',
        ort: 'Westerwalsede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27386',
    },
    {
        id: '12282',
        ort: 'Westgreußen',
        bundesland: 'Thüringen',
        landkreis: 'Kyffhäuserkreis',
        plz: '99718',
    },
    {
        id: '12283',
        ort: 'Westhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73463',
    },
    {
        id: '12284',
        ort: 'Westhausen',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Hildburghausen',
        plz: '98663',
    },
    {
        id: '12285',
        ort: 'Westheide',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39343',
    },
    {
        id: '12286',
        ort: 'Westheide',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39345',
    },
    {
        id: '12287',
        ort: 'Westheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weißenburg-Gunzenhausen',
        plz: '91747',
    },
    {
        id: '12288',
        ort: 'Westheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '67368',
    },
    {
        id: '12289',
        ort: 'Westhofen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '67593',
    },
    {
        id: '12290',
        ort: 'Westoverledingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Leer',
        plz: '26810',
    },
    {
        id: '12291',
        ort: 'Westre',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25926',
    },
    {
        id: '12292',
        ort: 'Wethau',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06618',
    },
    {
        id: '12293',
        ort: 'Wetschen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '49453',
    },
    {
        id: '12294',
        ort: 'Wettenberg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Gießen',
        plz: '35435',
    },
    {
        id: '12295',
        ort: 'Wetter',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35083',
    },
    {
        id: '12296',
        ort: 'Wetter (Ruhr)',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Ennepe-Ruhr-Kreis',
        plz: '58300',
    },
    {
        id: '12297',
        ort: 'Wetterzeube',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06722',
    },
    {
        id: '12298',
        ort: 'Wettin-Löbejün',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Saalekreis',
        plz: '06193',
    },
    {
        id: '12299',
        ort: 'Wettlingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54646',
    },
    {
        id: '12300',
        ort: 'Wettringen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Steinfurt',
        plz: '48493',
    },
    {
        id: '12301',
        ort: 'Wettringen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91631',
    },
    {
        id: '12302',
        ort: 'Wettrup',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '49838',
    },
    {
        id: '12303',
        ort: 'Wettstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Eichstätt',
        plz: '85139',
    },
    {
        id: '12304',
        ort: 'Wetzlar',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35576',
    },
    {
        id: '12305',
        ort: 'Wetzlar',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35578',
    },
    {
        id: '12306',
        ort: 'Wetzlar',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35579',
    },
    {
        id: '12307',
        ort: 'Wetzlar',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35580',
    },
    {
        id: '12308',
        ort: 'Wetzlar',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35581',
    },
    {
        id: '12309',
        ort: 'Wetzlar',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35582',
    },
    {
        id: '12310',
        ort: 'Wetzlar',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35583',
    },
    {
        id: '12311',
        ort: 'Wetzlar',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35584',
    },
    {
        id: '12312',
        ort: 'Wetzlar',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35585',
    },
    {
        id: '12313',
        ort: 'Wetzlar',
        bundesland: 'Hessen',
        landkreis: 'Lahn-Dill-Kreis',
        plz: '35586',
    },
    {
        id: '12314',
        ort: 'Wewelsfleth',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25599',
    },
    {
        id: '12315',
        ort: 'Weyarn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miesbach',
        plz: '83629',
    },
    {
        id: '12316',
        ort: 'Weyer',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56357',
    },
    {
        id: '12317',
        ort: 'Weyerbusch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57635',
    },
    {
        id: '12318',
        ort: 'Weyhausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '38554',
    },
    {
        id: '12319',
        ort: 'Weyhe',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Diepholz',
        plz: '28844',
    },
    {
        id: '12320',
        ort: 'Weyher in der Pfalz',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südliche Weinstraße',
        plz: '76835',
    },
    {
        id: '12321',
        ort: 'Wichmar',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07774',
    },
    {
        id: '12322',
        ort: 'Wickede (Ruhr)',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Soest',
        plz: '58739',
    },
    {
        id: '12323',
        ort: 'Wickenrodt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '12324',
        ort: 'Widdern',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74259',
    },
    {
        id: '12325',
        ort: 'Wiebelsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56291',
    },
    {
        id: '12326',
        ort: 'Wieck a. Darß',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18375',
    },
    {
        id: '12327',
        ort: 'Wied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57629',
    },
    {
        id: '12328',
        ort: 'Wiedemar',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04509',
    },
    {
        id: '12329',
        ort: 'Wieden',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79695',
    },
    {
        id: '12330',
        ort: 'Wiedenborstel',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '24613',
    },
    {
        id: '12331',
        ort: 'Wiedensahl',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31719',
    },
    {
        id: '12332',
        ort: 'Wiedergeltingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '86879',
    },
    {
        id: '12333',
        ort: 'Wiefelstede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Ammerland',
        plz: '26215',
    },
    {
        id: '12334',
        ort: 'Wiegendorf',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Weimarer Land',
        plz: '99510',
    },
    {
        id: '12335',
        ort: 'Wiehl',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Oberbergischer Kreis',
        plz: '51674',
    },
    {
        id: '12336',
        ort: 'Wiek',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18556',
    },
    {
        id: '12337',
        ort: 'Wielen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '49847',
    },
    {
        id: '12338',
        ort: 'Wielenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82407',
    },
    {
        id: '12339',
        ort: 'Wiemersdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24649',
    },
    {
        id: '12340',
        ort: 'Wiemerstedt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25779',
    },
    {
        id: '12341',
        ort: 'Wiendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18258',
    },
    {
        id: '12342',
        ort: 'Wienhausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29342',
    },
    {
        id: '12343',
        ort: 'Wiernsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75446',
    },
    {
        id: '12344',
        ort: 'Wierschem',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56294',
    },
    {
        id: '12345',
        ort: 'Wiersdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '12346',
        ort: 'Wiershop',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21502',
    },
    {
        id: '12347',
        ort: 'Wiesau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Tirschenreuth',
        plz: '95676',
    },
    {
        id: '12348',
        ort: 'Wiesbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66894',
    },
    {
        id: '12349',
        ort: 'Wiesbaden',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '55246',
    },
    {
        id: '12350',
        ort: 'Wiesbaden',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '55252',
    },
    {
        id: '12351',
        ort: 'Wiesbaden',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '65183',
    },
    {
        id: '12352',
        ort: 'Wiesbaden',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '65185',
    },
    {
        id: '12353',
        ort: 'Wiesbaden',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '65187',
    },
    {
        id: '12354',
        ort: 'Wiesbaden',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '65189',
    },
    {
        id: '12355',
        ort: 'Wiesbaden',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '65191',
    },
    {
        id: '12356',
        ort: 'Wiesbaden',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '65193',
    },
    {
        id: '12357',
        ort: 'Wiesbaden',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '65195',
    },
    {
        id: '12358',
        ort: 'Wiesbaden',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '65197',
    },
    {
        id: '12359',
        ort: 'Wiesbaden',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '65199',
    },
    {
        id: '12360',
        ort: 'Wiesbaden',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '65201',
    },
    {
        id: '12361',
        ort: 'Wiesbaden',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '65203',
    },
    {
        id: '12362',
        ort: 'Wiesbaden',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '65205',
    },
    {
        id: '12363',
        ort: 'Wiesbaden',
        bundesland: 'Hessen',
        landkreis: '',
        plz: '65207',
    },
    {
        id: '12364',
        ort: 'Wiesbaum',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54578',
    },
    {
        id: '12365',
        ort: 'Wiesemscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53534',
    },
    {
        id: '12366',
        ort: 'Wiesen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Aschaffenburg',
        plz: '63831',
    },
    {
        id: '12367',
        ort: 'Wiesenau',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15295',
    },
    {
        id: '12368',
        ort: 'Wiesenaue',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14662',
    },
    {
        id: '12369',
        ort: 'Wiesenbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69257',
    },
    {
        id: '12370',
        ort: 'Wiesenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '86519',
    },
    {
        id: '12371',
        ort: 'Wiesenbronn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97355',
    },
    {
        id: '12372',
        ort: 'Wiesenburg/Mark',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14827',
    },
    {
        id: '12373',
        ort: 'Wiesenfeld',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37308',
    },
    {
        id: '12374',
        ort: 'Wiesenfelden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94344',
    },
    {
        id: '12375',
        ort: 'Wiesengrund',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Spree-Neiße',
        plz: '03149',
    },
    {
        id: '12376',
        ort: 'Wiesensteig',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73349',
    },
    {
        id: '12377',
        ort: 'Wiesent',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93109',
    },
    {
        id: '12378',
        ort: 'Wiesenthal',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '36466',
    },
    {
        id: '12379',
        ort: 'Wiesenthau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91369',
    },
    {
        id: '12380',
        ort: 'Wiesentheid',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97353',
    },
    {
        id: '12381',
        ort: 'Wiesenttal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Forchheim',
        plz: '91346',
    },
    {
        id: '12382',
        ort: 'Wieseth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91632',
    },
    {
        id: '12383',
        ort: 'Wiesloch',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69168',
    },
    {
        id: '12384',
        ort: 'Wiesmoor',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26639',
    },
    {
        id: '12385',
        ort: 'Wiesthal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97859',
    },
    {
        id: '12386',
        ort: 'Wiesweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67744',
    },
    {
        id: '12387',
        ort: 'Wietmarschen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '49835',
    },
    {
        id: '12388',
        ort: 'Wietze',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29323',
    },
    {
        id: '12389',
        ort: 'Wietzen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Nienburg/Weser',
        plz: '31613',
    },
    {
        id: '12390',
        ort: 'Wietzendorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Heidekreis',
        plz: '29649',
    },
    {
        id: '12391',
        ort: 'Wiggensbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87487',
    },
    {
        id: '12392',
        ort: 'Wilburgstetten',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91634',
    },
    {
        id: '12393',
        ort: 'Wildau',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15745',
    },
    {
        id: '12394',
        ort: 'Wildberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Calw',
        plz: '72218',
    },
    {
        id: '12395',
        ort: 'Wildberg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17091',
    },
    {
        id: '12396',
        ort: 'Wildeck',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Hersfeld-Rotenburg',
        plz: '36208',
    },
    {
        id: '12397',
        ort: 'Wildenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kelheim',
        plz: '93359',
    },
    {
        id: '12398',
        ort: 'Wildenfels',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08134',
    },
    {
        id: '12399',
        ort: 'Wildeshausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Oldenburg',
        plz: '27793',
    },
    {
        id: '12400',
        ort: 'Wildflecken',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97772',
    },
    {
        id: '12401',
        ort: 'Wildpoldsried',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Oberallgäu',
        plz: '87499',
    },
    {
        id: '12402',
        ort: 'Wildsteig',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Weilheim-Schongau',
        plz: '82409',
    },
    {
        id: '12403',
        ort: 'Wilgartswiesen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '76848',
    },
    {
        id: '12404',
        ort: 'Wilhelmsburg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17379',
    },
    {
        id: '12405',
        ort: 'Wilhelmsdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Aisch-Bad Windsheim',
        plz: '91489',
    },
    {
        id: '12406',
        ort: 'Wilhelmsdorf',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07389',
    },
    {
        id: '12407',
        ort: 'Wilhelmsdorf',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88271',
    },
    {
        id: '12408',
        ort: 'Wilhelmsfeld',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '69259',
    },
    {
        id: '12409',
        ort: 'Wilhelmshaven',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '26382',
    },
    {
        id: '12410',
        ort: 'Wilhelmshaven',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '26384',
    },
    {
        id: '12411',
        ort: 'Wilhelmshaven',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '26386',
    },
    {
        id: '12412',
        ort: 'Wilhelmshaven',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '26388',
    },
    {
        id: '12413',
        ort: 'Wilhelmshaven',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '26389',
    },
    {
        id: '12414',
        ort: 'Wilhelmsthal',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kronach',
        plz: '96352',
    },
    {
        id: '12415',
        ort: 'Wilhermsdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürth',
        plz: '91452',
    },
    {
        id: '12416',
        ort: 'Wilkau-Haßlau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08112',
    },
    {
        id: '12417',
        ort: 'Willanzheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kitzingen',
        plz: '97348',
    },
    {
        id: '12418',
        ort: 'Willebadessen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Höxter',
        plz: '34439',
    },
    {
        id: '12419',
        ort: 'Willenscharen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '24616',
    },
    {
        id: '12420',
        ort: 'Willich',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Viersen',
        plz: '47877',
    },
    {
        id: '12421',
        ort: 'Willingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56479',
    },
    {
        id: '12422',
        ort: 'Willingen (Upland)',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Waldeck-Frankenberg',
        plz: '34508',
    },
    {
        id: '12423',
        ort: 'Willingshausen',
        bundesland: 'Hessen',
        landkreis: 'Schwalm-Eder-Kreis',
        plz: '34628',
    },
    {
        id: '12424',
        ort: 'Willmars',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97647',
    },
    {
        id: '12425',
        ort: 'Willmenrod',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '12426',
        ort: 'Willmering',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93497',
    },
    {
        id: '12427',
        ort: 'Willroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '56594',
    },
    {
        id: '12428',
        ort: 'Willstätt',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77731',
    },
    {
        id: '12429',
        ort: 'Willwerscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54533',
    },
    {
        id: '12430',
        ort: 'Wilnsdorf',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Siegen-Wittgenstein',
        plz: '57234',
    },
    {
        id: '12431',
        ort: 'Wilsdruff',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01723',
    },
    {
        id: '12432',
        ort: 'Wilsdruff',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Sächsische Schweiz-Osterzgebirge',
        plz: '01737',
    },
    {
        id: '12433',
        ort: 'Wilsecker',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54655',
    },
    {
        id: '12434',
        ort: 'Wilstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27412',
    },
    {
        id: '12435',
        ort: 'Wilster',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25554',
    },
    {
        id: '12436',
        ort: 'Wilsum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Grafschaft Bentheim',
        plz: '49849',
    },
    {
        id: '12437',
        ort: 'Wilthen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02681',
    },
    {
        id: '12438',
        ort: 'Wiltingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54459',
    },
    {
        id: '12439',
        ort: 'Wilzenberg-Hußweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55767',
    },
    {
        id: '12440',
        ort: 'Wimbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53518',
    },
    {
        id: '12441',
        ort: 'Wimmelburg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Mansfeld-Südharz',
        plz: '06313',
    },
    {
        id: '12442',
        ort: 'Wimsheim',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '71299',
    },
    {
        id: '12443',
        ort: 'Wincheringen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54457',
    },
    {
        id: '12444',
        ort: 'Windach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landsberg am Lech',
        plz: '86949',
    },
    {
        id: '12445',
        ort: 'Windberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Straubing-Bogen',
        plz: '94336',
    },
    {
        id: '12446',
        ort: 'Windbergen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25729',
    },
    {
        id: '12447',
        ort: 'Windeby',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24340',
    },
    {
        id: '12448',
        ort: 'Windeck',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Rhein-Sieg-Kreis',
        plz: '51570',
    },
    {
        id: '12449',
        ort: 'Windelsbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91635',
    },
    {
        id: '12450',
        ort: 'Winden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56379',
    },
    {
        id: '12451',
        ort: 'Winden',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76872',
    },
    {
        id: '12452',
        ort: 'Winden im Elztal',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79297',
    },
    {
        id: '12453',
        ort: 'Windesheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55452',
    },
    {
        id: '12454',
        ort: 'Windhagen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '53578',
    },
    {
        id: '12455',
        ort: 'Windischeschenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Neustadt an der Waldnaab',
        plz: '92670',
    },
    {
        id: '12456',
        ort: 'Windischleuba',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Altenburger Land',
        plz: '04603',
    },
    {
        id: '12457',
        ort: 'Windorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94575',
    },
    {
        id: '12458',
        ort: 'Windsbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91575',
    },
    {
        id: '12459',
        ort: 'Wingerode',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37327',
    },
    {
        id: '12460',
        ort: 'Wingst',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '21789',
    },
    {
        id: '12461',
        ort: 'Winhöring',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Altötting',
        plz: '84543',
    },
    {
        id: '12462',
        ort: 'Winkelbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '57644',
    },
    {
        id: '12463',
        ort: 'Winkel (Eifel)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Vulkaneifel',
        plz: '54558',
    },
    {
        id: '12464',
        ort: 'Winkelhaid',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Nürnberger Land',
        plz: '90610',
    },
    {
        id: '12465',
        ort: 'Winkelsett',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Oldenburg',
        plz: '27243',
    },
    {
        id: '12466',
        ort: 'Winklarn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schwandorf',
        plz: '92559',
    },
    {
        id: '12467',
        ort: 'Winnemark',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Rendsburg-Eckernförde',
        plz: '24398',
    },
    {
        id: '12468',
        ort: 'Winnen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56459',
    },
    {
        id: '12469',
        ort: 'Winnenden',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '71364',
    },
    {
        id: '12470',
        ort: 'Winnerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53520',
    },
    {
        id: '12471',
        ort: 'Winnert',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25887',
    },
    {
        id: '12472',
        ort: 'Winnigstedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38170',
    },
    {
        id: '12473',
        ort: 'Winningen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56333',
    },
    {
        id: '12474',
        ort: 'Winnweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67722',
    },
    {
        id: '12475',
        ort: 'Winringen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54614',
    },
    {
        id: '12476',
        ort: 'Winseldorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25551',
    },
    {
        id: '12477',
        ort: 'Winsen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '24568',
    },
    {
        id: '12478',
        ort: 'Winsen (Aller)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29229',
    },
    {
        id: '12479',
        ort: 'Winsen (Aller)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Celle',
        plz: '29308',
    },
    {
        id: '12480',
        ort: 'Winsen (Luhe)',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21423',
    },
    {
        id: '12481',
        ort: 'Winterbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rems-Murr-Kreis',
        plz: '73650',
    },
    {
        id: '12482',
        ort: 'Winterbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '89368',
    },
    {
        id: '12483',
        ort: 'Winterbach',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '12484',
        ort: 'Winterbach (Pfalz)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Südwestpfalz',
        plz: '66484',
    },
    {
        id: '12485',
        ort: 'Winterberg',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Hochsauerlandkreis',
        plz: '59955',
    },
    {
        id: '12486',
        ort: 'Winterborn',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67822',
    },
    {
        id: '12487',
        ort: 'Winterburg',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bad Kreuznach',
        plz: '55595',
    },
    {
        id: '12488',
        ort: 'Winterhausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97286',
    },
    {
        id: '12489',
        ort: 'Winterlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72474',
    },
    {
        id: '12490',
        ort: 'Winterrieden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87785',
    },
    {
        id: '12491',
        ort: 'Winterscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54608',
    },
    {
        id: '12492',
        ort: 'Wintersheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '67587',
    },
    {
        id: '12493',
        ort: 'Winterspelt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54616',
    },
    {
        id: '12494',
        ort: 'Winterwerb',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56355',
    },
    {
        id: '12495',
        ort: 'Wintrich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54487',
    },
    {
        id: '12496',
        ort: 'Winzer',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Deggendorf',
        plz: '94577',
    },
    {
        id: '12497',
        ort: 'Wipfeld',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Schweinfurt',
        plz: '97537',
    },
    {
        id: '12498',
        ort: 'Wipperfürth',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Oberbergischer Kreis',
        plz: '51688',
    },
    {
        id: '12499',
        ort: 'Wippingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Emsland',
        plz: '26892',
    },
    {
        id: '12500',
        ort: 'Wirdum',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Aurich',
        plz: '26529',
    },
    {
        id: '12501',
        ort: 'Wirft',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Ahrweiler',
        plz: '53534',
    },
    {
        id: '12502',
        ort: 'Wirfus',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56814',
    },
    {
        id: '12503',
        ort: 'Wirges',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56422',
    },
    {
        id: '12504',
        ort: 'Wirsberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '95339',
    },
    {
        id: '12505',
        ort: 'Wirscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56237',
    },
    {
        id: '12506',
        ort: 'Wirschweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Birkenfeld',
        plz: '55758',
    },
    {
        id: '12507',
        ort: 'Wisch',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24217',
    },
    {
        id: '12508',
        ort: 'Wisch',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25876',
    },
    {
        id: '12509',
        ort: 'Wischhafen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Stade',
        plz: '21737',
    },
    {
        id: '12510',
        ort: 'Wismar',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23966',
    },
    {
        id: '12511',
        ort: 'Wismar',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23968',
    },
    {
        id: '12512',
        ort: 'Wismar',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23970',
    },
    {
        id: '12513',
        ort: 'Wissen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57537',
    },
    {
        id: '12514',
        ort: 'Wißmannsdorf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '12515',
        ort: 'Wistedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21255',
    },
    {
        id: '12516',
        ort: 'Witsum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25938',
    },
    {
        id: '12517',
        ort: 'Wittbek',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25872',
    },
    {
        id: '12518',
        ort: 'Wittdün auf Amrum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25946',
    },
    {
        id: '12519',
        ort: 'Wittelshofen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91749',
    },
    {
        id: '12520',
        ort: 'Witten',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Ennepe-Ruhr-Kreis',
        plz: '58452',
    },
    {
        id: '12521',
        ort: 'Witten',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Ennepe-Ruhr-Kreis',
        plz: '58453',
    },
    {
        id: '12522',
        ort: 'Witten',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Ennepe-Ruhr-Kreis',
        plz: '58454',
    },
    {
        id: '12523',
        ort: 'Witten',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Ennepe-Ruhr-Kreis',
        plz: '58455',
    },
    {
        id: '12524',
        ort: 'Witten',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Ennepe-Ruhr-Kreis',
        plz: '58456',
    },
    {
        id: '12525',
        ort: 'Wittenbeck',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18209',
    },
    {
        id: '12526',
        ort: 'Wittenberg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Wittenberg',
        plz: '06886',
    },
    {
        id: '12527',
        ort: 'Wittenberg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Wittenberg',
        plz: '06888',
    },
    {
        id: '12528',
        ort: 'Wittenberg',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Wittenberg',
        plz: '06889',
    },
    {
        id: '12529',
        ort: 'Wittenberge',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Prignitz',
        plz: '19322',
    },
    {
        id: '12530',
        ort: 'Wittenbergen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25548',
    },
    {
        id: '12531',
        ort: 'Wittenborn',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Segeberg',
        plz: '23829',
    },
    {
        id: '12532',
        ort: 'Wittenburg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19243',
    },
    {
        id: '12533',
        ort: 'Wittendörp',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19243',
    },
    {
        id: '12534',
        ort: 'Wittenförden',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19073',
    },
    {
        id: '12535',
        ort: 'Wittenhagen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18510',
    },
    {
        id: '12536',
        ort: 'Witterda',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99189',
    },
    {
        id: '12537',
        ort: 'Wittgert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56237',
    },
    {
        id: '12538',
        ort: 'Wittibreut',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84384',
    },
    {
        id: '12539',
        ort: 'Wittichenau - Kulow',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Bautzen',
        plz: '02997',
    },
    {
        id: '12540',
        ort: 'Wittighausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Main-Tauber-Kreis',
        plz: '97957',
    },
    {
        id: '12541',
        ort: 'Wittingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '29378',
    },
    {
        id: '12542',
        ort: 'Wittingen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Gifhorn',
        plz: '29379',
    },
    {
        id: '12543',
        ort: 'Wittislingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89426',
    },
    {
        id: '12544',
        ort: 'Wittlich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54516',
    },
    {
        id: '12545',
        ort: 'Wittlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79599',
    },
    {
        id: '12546',
        ort: 'Wittmar',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38329',
    },
    {
        id: '12547',
        ort: 'Wittmoldt',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Plön',
        plz: '24306',
    },
    {
        id: '12548',
        ort: 'Wittmund',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wittmund',
        plz: '26409',
    },
    {
        id: '12549',
        ort: 'Wittnau',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Breisgau-Hochschwarzwald',
        plz: '79299',
    },
    {
        id: '12550',
        ort: 'Wittorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüneburg',
        plz: '21357',
    },
    {
        id: '12551',
        ort: 'Wittstock/Dosse',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16909',
    },
    {
        id: '12552',
        ort: 'Witzeeze',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21514',
    },
    {
        id: '12553',
        ort: 'Witzenhausen',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37213',
    },
    {
        id: '12554',
        ort: 'Witzenhausen',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37214',
    },
    {
        id: '12555',
        ort: 'Witzenhausen',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37215',
    },
    {
        id: '12556',
        ort: 'Witzenhausen',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37216',
    },
    {
        id: '12557',
        ort: 'Witzenhausen',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37217',
    },
    {
        id: '12558',
        ort: 'Witzenhausen',
        bundesland: 'Hessen',
        landkreis: 'Werra-Meißner-Kreis',
        plz: '37218',
    },
    {
        id: '12559',
        ort: 'Witzhave',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '22969',
    },
    {
        id: '12560',
        ort: 'Witzin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19406',
    },
    {
        id: '12561',
        ort: 'Witzleben',
        bundesland: 'Thüringen',
        landkreis: 'Ilm-Kreis',
        plz: '99310',
    },
    {
        id: '12562',
        ort: 'Witzmannsberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Passau',
        plz: '94104',
    },
    {
        id: '12563',
        ort: 'Witzwort',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25889',
    },
    {
        id: '12564',
        ort: 'Wöbbelin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19288',
    },
    {
        id: '12565',
        ort: 'Wobbenbüll',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25856',
    },
    {
        id: '12566',
        ort: 'Woggersin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17039',
    },
    {
        id: '12567',
        ort: 'Wohlde',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Schleswig-Flensburg',
        plz: '24899',
    },
    {
        id: '12568',
        ort: 'Wohltorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21521',
    },
    {
        id: '12569',
        ort: 'Wohnste',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27419',
    },
    {
        id: '12570',
        ort: 'Wohratal',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Marburg-Biedenkopf',
        plz: '35288',
    },
    {
        id: '12571',
        ort: 'Wöhrden',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25797',
    },
    {
        id: '12572',
        ort: 'Wokuhl-Dabelow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17237',
    },
    {
        id: '12573',
        ort: 'Wolde',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17091',
    },
    {
        id: '12574',
        ort: 'Woldegk',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17348',
    },
    {
        id: '12575',
        ort: 'Woldert',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Neuwied',
        plz: '57614',
    },
    {
        id: '12576',
        ort: 'Wolfach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77709',
    },
    {
        id: '12577',
        ort: 'Wolfegg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88364',
    },
    {
        id: '12578',
        ort: 'Wolfenbüttel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38300',
    },
    {
        id: '12579',
        ort: 'Wolfenbüttel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38302',
    },
    {
        id: '12580',
        ort: 'Wolfenbüttel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Wolfenbüttel',
        plz: '38304',
    },
    {
        id: '12581',
        ort: 'Wölferlingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56244',
    },
    {
        id: '12582',
        ort: 'Wolfersdorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85395',
    },
    {
        id: '12583',
        ort: 'Wölfersheim',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '61200',
    },
    {
        id: '12584',
        ort: 'Wolferstadt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Donau-Ries',
        plz: '86709',
    },
    {
        id: '12585',
        ort: 'Wolfertschwenden',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87787',
    },
    {
        id: '12586',
        ort: 'Wolfhagen',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34466',
    },
    {
        id: '12587',
        ort: 'Wolframs-Eschenbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91639',
    },
    {
        id: '12588',
        ort: 'Wolfratshausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Tölz-Wolfratshausen',
        plz: '82515',
    },
    {
        id: '12589',
        ort: 'Wolfsburg',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38440',
    },
    {
        id: '12590',
        ort: 'Wolfsburg',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38442',
    },
    {
        id: '12591',
        ort: 'Wolfsburg',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38444',
    },
    {
        id: '12592',
        ort: 'Wolfsburg',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38446',
    },
    {
        id: '12593',
        ort: 'Wolfsburg',
        bundesland: 'Niedersachsen',
        landkreis: '',
        plz: '38448',
    },
    {
        id: '12594',
        ort: 'Wolfschlugen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Esslingen',
        plz: '72649',
    },
    {
        id: '12595',
        ort: 'Wolfsegg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93195',
    },
    {
        id: '12596',
        ort: 'Wolfsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55578',
    },
    {
        id: '12597',
        ort: 'Wolfstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Kusel',
        plz: '67752',
    },
    {
        id: '12598',
        ort: 'Wolgast',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17438',
    },
    {
        id: '12599',
        ort: 'Wolken',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mayen-Koblenz',
        plz: '56332',
    },
    {
        id: '12600',
        ort: 'Wolkenstein',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09429',
    },
    {
        id: '12601',
        ort: 'Wollbach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97618',
    },
    {
        id: '12602',
        ort: 'Wollbrandshausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37434',
    },
    {
        id: '12603',
        ort: 'Wollershausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37434',
    },
    {
        id: '12604',
        ort: 'Wollin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14778',
    },
    {
        id: '12605',
        ort: 'Wollmerath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56826',
    },
    {
        id: '12606',
        ort: 'Wöllstadt',
        bundesland: 'Hessen',
        landkreis: 'Wetteraukreis',
        plz: '61206',
    },
    {
        id: '12607',
        ort: 'Wöllstein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55597',
    },
    {
        id: '12608',
        ort: 'Wolmersdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25704',
    },
    {
        id: '12609',
        ort: 'Wölmersen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57635',
    },
    {
        id: '12610',
        ort: 'Wolmirsleben',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Salzlandkreis',
        plz: '39435',
    },
    {
        id: '12611',
        ort: 'Wolmirstedt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39326',
    },
    {
        id: '12612',
        ort: 'Wolnzach',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Pfaffenhofen an der Ilm',
        plz: '85283',
    },
    {
        id: '12613',
        ort: 'Wolpertshausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Schwäbisch Hall',
        plz: '74549',
    },
    {
        id: '12614',
        ort: 'Wolpertswende',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Ravensburg',
        plz: '88284',
    },
    {
        id: '12615',
        ort: 'Wölpinghausen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Schaumburg',
        plz: '31556',
    },
    {
        id: '12616',
        ort: 'Wolsdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Helmstedt',
        plz: '38379',
    },
    {
        id: '12617',
        ort: 'Wolsfeld',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54636',
    },
    {
        id: '12618',
        ort: 'Woltersdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15569',
    },
    {
        id: '12619',
        ort: 'Woltersdorf',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21516',
    },
    {
        id: '12620',
        ort: 'Woltersdorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29497',
    },
    {
        id: '12621',
        ort: 'Womrath',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55481',
    },
    {
        id: '12622',
        ort: 'Wonfurt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '97539',
    },
    {
        id: '12623',
        ort: 'Wonneberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Traunstein',
        plz: '83379',
    },
    {
        id: '12624',
        ort: 'Wonsees',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Kulmbach',
        plz: '96197',
    },
    {
        id: '12625',
        ort: 'Wonsheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55599',
    },
    {
        id: '12626',
        ort: 'Woppenroth',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55490',
    },
    {
        id: '12627',
        ort: 'Woringen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Unterallgäu',
        plz: '87789',
    },
    {
        id: '12628',
        ort: 'Worms',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67547',
    },
    {
        id: '12629',
        ort: 'Worms',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67549',
    },
    {
        id: '12630',
        ort: 'Worms',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67550',
    },
    {
        id: '12631',
        ort: 'Worms',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '67551',
    },
    {
        id: '12632',
        ort: 'Wörnersberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Freudenstadt',
        plz: '72299',
    },
    {
        id: '12633',
        ort: 'Wörnitz',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ansbach',
        plz: '91637',
    },
    {
        id: '12634',
        ort: 'Worpswede',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Osterholz',
        plz: '27726',
    },
    {
        id: '12635',
        ort: 'Wörrstadt',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Alzey-Worms',
        plz: '55286',
    },
    {
        id: '12636',
        ort: 'Wört',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ostalbkreis',
        plz: '73499',
    },
    {
        id: '12637',
        ort: 'Worth',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '21502',
    },
    {
        id: '12638',
        ort: 'Wörth',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Erding',
        plz: '85457',
    },
    {
        id: '12639',
        ort: 'Wörth a.d. Isar',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84109',
    },
    {
        id: '12640',
        ort: 'Wörth am Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Miltenberg',
        plz: '63939',
    },
    {
        id: '12641',
        ort: 'Wörth am Rhein',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '76744',
    },
    {
        id: '12642',
        ort: 'Wörth an der Donau',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93086',
    },
    {
        id: '12643',
        ort: 'Wörthsee',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Starnberg',
        plz: '82237',
    },
    {
        id: '12644',
        ort: 'Wrangelsburg',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17495',
    },
    {
        id: '12645',
        ort: 'Wrestedt',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29559',
    },
    {
        id: '12646',
        ort: 'Wriedel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Uelzen',
        plz: '29565',
    },
    {
        id: '12647',
        ort: 'Wriezen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '16269',
    },
    {
        id: '12648',
        ort: 'Wrist',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25563',
    },
    {
        id: '12649',
        ort: 'Wrixum',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25938',
    },
    {
        id: '12650',
        ort: 'Wrohm',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Dithmarschen',
        plz: '25799',
    },
    {
        id: '12651',
        ort: 'Wülfershausen an der Saale',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rhön-Grabfeld',
        plz: '97618',
    },
    {
        id: '12652',
        ort: 'Wülfrath',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Mettmann',
        plz: '42489',
    },
    {
        id: '12653',
        ort: 'Wulfsen',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Harburg',
        plz: '21445',
    },
    {
        id: '12654',
        ort: 'Wulfsmoor',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Steinburg',
        plz: '25563',
    },
    {
        id: '12655',
        ort: 'Wulften am Harz',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Göttingen',
        plz: '37199',
    },
    {
        id: '12656',
        ort: 'Wulkenzin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17039',
    },
    {
        id: '12657',
        ort: 'Wülknitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01609',
    },
    {
        id: '12658',
        ort: 'Wundersleben',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Sömmerda',
        plz: '99610',
    },
    {
        id: '12659',
        ort: 'Wünschendorf/Elster',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07570',
    },
    {
        id: '12660',
        ort: 'Wunsiedel',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Wunsiedel im Fichtelgebirge',
        plz: '95632',
    },
    {
        id: '12661',
        ort: 'Wunstorf',
        bundesland: 'Niedersachsen',
        landkreis: 'Region Hannover',
        plz: '31515',
    },
    {
        id: '12662',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42103',
    },
    {
        id: '12663',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42105',
    },
    {
        id: '12664',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42107',
    },
    {
        id: '12665',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42109',
    },
    {
        id: '12666',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42111',
    },
    {
        id: '12667',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42113',
    },
    {
        id: '12668',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42115',
    },
    {
        id: '12669',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42117',
    },
    {
        id: '12670',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42119',
    },
    {
        id: '12671',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42275',
    },
    {
        id: '12672',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42277',
    },
    {
        id: '12673',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42279',
    },
    {
        id: '12674',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42281',
    },
    {
        id: '12675',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42283',
    },
    {
        id: '12676',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42285',
    },
    {
        id: '12677',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42287',
    },
    {
        id: '12678',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42289',
    },
    {
        id: '12679',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42327',
    },
    {
        id: '12680',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42329',
    },
    {
        id: '12681',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42349',
    },
    {
        id: '12682',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42369',
    },
    {
        id: '12683',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42389',
    },
    {
        id: '12684',
        ort: 'Wuppertal',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: '',
        plz: '42399',
    },
    {
        id: '12685',
        ort: 'Wurmannsquick',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84329',
    },
    {
        id: '12686',
        ort: 'Wurmberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Enzkreis',
        plz: '75449',
    },
    {
        id: '12687',
        ort: 'Wurmlingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Tuttlingen',
        plz: '78573',
    },
    {
        id: '12688',
        ort: 'Wurmsham',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Landshut',
        plz: '84189',
    },
    {
        id: '12689',
        ort: 'Würrich',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56858',
    },
    {
        id: '12690',
        ort: 'Würselen',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Städteregion Aachen',
        plz: '52146',
    },
    {
        id: '12691',
        ort: 'Wurster Nordseeküste',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Cuxhaven',
        plz: '27639',
    },
    {
        id: '12692',
        ort: 'Wurzbach',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07343',
    },
    {
        id: '12693',
        ort: 'Würzburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '97070',
    },
    {
        id: '12694',
        ort: 'Würzburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '97072',
    },
    {
        id: '12695',
        ort: 'Würzburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '97074',
    },
    {
        id: '12696',
        ort: 'Würzburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '97076',
    },
    {
        id: '12697',
        ort: 'Würzburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '97078',
    },
    {
        id: '12698',
        ort: 'Würzburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '97080',
    },
    {
        id: '12699',
        ort: 'Würzburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '97082',
    },
    {
        id: '12700',
        ort: 'Würzburg',
        bundesland: 'Bayern',
        landkreis: '',
        plz: '97084',
    },
    {
        id: '12701',
        ort: 'Wurzen',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04808',
    },
    {
        id: '12702',
        ort: 'Würzweiler',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67808',
    },
    {
        id: '12703',
        ort: 'Wüschheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '55471',
    },
    {
        id: '12704',
        ort: 'Wüstenrot',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '71543',
    },
    {
        id: '12705',
        ort: 'Wusterhausen/Dosse',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16845',
    },
    {
        id: '12706',
        ort: 'Wusterhausen/Dosse',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16866',
    },
    {
        id: '12707',
        ort: 'Wusterhausen/Dosse',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16868',
    },
    {
        id: '12708',
        ort: 'Wusterhusen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17509',
    },
    {
        id: '12709',
        ort: 'Wustermark',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Havelland',
        plz: '14641',
    },
    {
        id: '12710',
        ort: 'Wusterwitz',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14789',
    },
    {
        id: '12711',
        ort: 'Wust-Fischbeck',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39524',
    },
    {
        id: '12712',
        ort: 'Wüstheuterode',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Eichsfeld',
        plz: '37318',
    },
    {
        id: '12713',
        ort: 'Wustrow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18347',
    },
    {
        id: '12714',
        ort: 'Wustrow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17255',
    },
    {
        id: '12715',
        ort: 'Wustrow',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29462',
    },
    {
        id: '12716',
        ort: 'Wutach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79879',
    },
    {
        id: '12717',
        ort: 'Wutha-Farnroda',
        bundesland: 'Thüringen',
        landkreis: 'Wartburgkreis',
        plz: '99848',
    },
    {
        id: '12718',
        ort: 'Wutöschingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Waldshut',
        plz: '79793',
    },
    {
        id: '12719',
        ort: 'Wyhl',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Emmendingen',
        plz: '79369',
    },
    {
        id: '12720',
        ort: 'Wyk auf Föhr',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Nordfriesland',
        plz: '25938',
    },
    {
        id: '12721',
        ort: 'Xanten',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Wesel',
        plz: '46509',
    },
    {
        id: '12722',
        ort: 'Zaberfeld',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Heilbronn',
        plz: '74374',
    },
    {
        id: '12723',
        ort: 'Zachenberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94239',
    },
    {
        id: '12724',
        ort: 'Zahna-Elster',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Wittenberg',
        plz: '06895',
    },
    {
        id: '12725',
        ort: 'Zaisenhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Karlsruhe',
        plz: '75059',
    },
    {
        id: '12726',
        ort: 'Zandt',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93499',
    },
    {
        id: '12727',
        ort: 'Zangberg',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Mühldorf am Inn',
        plz: '84539',
    },
    {
        id: '12728',
        ort: 'Zapel',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19089',
    },
    {
        id: '12729',
        ort: 'Zapfendorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bamberg',
        plz: '96199',
    },
    {
        id: '12730',
        ort: 'Zarnewanz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18195',
    },
    {
        id: '12731',
        ort: 'Zarpen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Stormarn',
        plz: '23619',
    },
    {
        id: '12732',
        ort: 'Zarrendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18510',
    },
    {
        id: '12733',
        ort: 'Zarrentin am Schaalsee',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19246',
    },
    {
        id: '12734',
        ort: 'Zechin',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15328',
    },
    {
        id: '12735',
        ort: 'Zedlitz',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07557',
    },
    {
        id: '12736',
        ort: 'Zehdenick',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oberhavel',
        plz: '16792',
    },
    {
        id: '12737',
        ort: 'Zehna',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18276',
    },
    {
        id: '12738',
        ort: 'Zehnhausen bei Rennerod',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56477',
    },
    {
        id: '12739',
        ort: 'Zehnhausen bei Wallmerod',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Westerwaldkreis',
        plz: '56414',
    },
    {
        id: '12740',
        ort: 'Zehrental',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Stendal',
        plz: '39615',
    },
    {
        id: '12741',
        ort: 'Zeil am Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Haßberge',
        plz: '97475',
    },
    {
        id: '12742',
        ort: 'Zeilarn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Rottal-Inn',
        plz: '84367',
    },
    {
        id: '12743',
        ort: 'Zeiskam',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Germersheim',
        plz: '67378',
    },
    {
        id: '12744',
        ort: 'Zeithain',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Meißen',
        plz: '01619',
    },
    {
        id: '12745',
        ort: 'Zeitlarn',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regensburg',
        plz: '93197',
    },
    {
        id: '12746',
        ort: 'Zeitlofs',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Bad Kissingen',
        plz: '97799',
    },
    {
        id: '12747',
        ort: 'Zeitz',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06711',
    },
    {
        id: '12748',
        ort: 'Zeitz',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Burgenlandkreis',
        plz: '06712',
    },
    {
        id: '12749',
        ort: 'Zell',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Cham',
        plz: '93199',
    },
    {
        id: '12750',
        ort: 'Zella-Mehlis',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Schmalkalden-Meiningen',
        plz: '98544',
    },
    {
        id: '12751',
        ort: 'Zell am Harmersbach',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Ortenaukreis',
        plz: '77736',
    },
    {
        id: '12752',
        ort: 'Zell am Main',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Würzburg',
        plz: '97299',
    },
    {
        id: '12753',
        ort: 'Zellertal',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Donnersbergkreis',
        plz: '67308',
    },
    {
        id: '12754',
        ort: 'Zell im Fichtelgebirge',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Hof',
        plz: '95239',
    },
    {
        id: '12755',
        ort: 'Zell im Wiesental',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Lörrach',
        plz: '79669',
    },
    {
        id: '12756',
        ort: 'Zellingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Main-Spessart',
        plz: '97225',
    },
    {
        id: '12757',
        ort: 'Zell (Mosel)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56856',
    },
    {
        id: '12758',
        ort: 'Zell (Mosel)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56858',
    },
    {
        id: '12759',
        ort: 'Zell (Mosel)',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56859',
    },
    {
        id: '12760',
        ort: 'Zell unter Aichelberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73087',
    },
    {
        id: '12761',
        ort: 'Zell unter Aichelberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Göppingen',
        plz: '73119',
    },
    {
        id: '12762',
        ort: 'Zeltingen-Rachtig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54492',
    },
    {
        id: '12763',
        ort: 'Zeltingen-Rachtig',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Bernkastel-Wittlich',
        plz: '54539',
    },
    {
        id: '12764',
        ort: 'Zemitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17440',
    },
    {
        id: '12765',
        ort: 'Zemmer',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54313',
    },
    {
        id: '12766',
        ort: 'Zempin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17459',
    },
    {
        id: '12767',
        ort: 'Zendscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54655',
    },
    {
        id: '12768',
        ort: 'Zenting',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freyung-Grafenau',
        plz: '94579',
    },
    {
        id: '12769',
        ort: 'Zepelin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18246',
    },
    {
        id: '12770',
        ort: 'Zerbst/Anhalt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '39261',
    },
    {
        id: '12771',
        ort: 'Zerbst/Anhalt',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '39264',
    },
    {
        id: '12772',
        ort: 'Zerf',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54314',
    },
    {
        id: '12773',
        ort: 'Zernien',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Lüchow-Dannenberg',
        plz: '29499',
    },
    {
        id: '12774',
        ort: 'Zernitz-Lohm',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Ostprignitz-Ruppin',
        plz: '16845',
    },
    {
        id: '12775',
        ort: 'Zerrenthin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17309',
    },
    {
        id: '12776',
        ort: 'Zeschdorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Märkisch-Oderland',
        plz: '15326',
    },
    {
        id: '12777',
        ort: 'Zetel',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Friesland',
        plz: '26340',
    },
    {
        id: '12778',
        ort: 'Zettemin',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17153',
    },
    {
        id: '12779',
        ort: 'Zettingen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Cochem-Zell',
        plz: '56761',
    },
    {
        id: '12780',
        ort: 'Zettlitz',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Mittelsachsen',
        plz: '09306',
    },
    {
        id: '12781',
        ort: 'Zeulenroda-Triebes',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07937',
    },
    {
        id: '12782',
        ort: 'Zeulenroda-Triebes',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Greiz',
        plz: '07950',
    },
    {
        id: '12783',
        ort: 'Zeuthen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Dahme-Spreewald',
        plz: '15738',
    },
    {
        id: '12784',
        ort: 'Zeven',
        bundesland: 'Niedersachsen',
        landkreis: 'Landkreis Rotenburg (Wümme)',
        plz: '27404',
    },
    {
        id: '12785',
        ort: 'Zichow',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Uckermark',
        plz: '16306',
    },
    {
        id: '12786',
        ort: 'Zickhusen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '19069',
    },
    {
        id: '12787',
        ort: 'Ziegendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19372',
    },
    {
        id: '12788',
        ort: 'Ziegenhain',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Altenkirchen',
        plz: '57632',
    },
    {
        id: '12789',
        ort: 'Ziegenrück',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Orla-Kreis',
        plz: '07924',
    },
    {
        id: '12790',
        ort: 'Zielitz',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Börde',
        plz: '39326',
    },
    {
        id: '12791',
        ort: 'Ziemetshausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Günzburg',
        plz: '86473',
    },
    {
        id: '12792',
        ort: 'Zierenberg',
        bundesland: 'Hessen',
        landkreis: 'Landkreis Kassel',
        plz: '34289',
    },
    {
        id: '12793',
        ort: 'Zierow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23968',
    },
    {
        id: '12794',
        ort: 'Ziertheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89446',
    },
    {
        id: '12795',
        ort: 'Zierzow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19300',
    },
    {
        id: '12796',
        ort: 'Ziesar',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Potsdam-Mittelmark',
        plz: '14793',
    },
    {
        id: '12797',
        ort: 'Ziesendorf',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Rostock',
        plz: '18059',
    },
    {
        id: '12798',
        ort: 'Ziethen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Barnim',
        plz: '16247',
    },
    {
        id: '12799',
        ort: 'Ziethen',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17390',
    },
    {
        id: '12800',
        ort: 'Ziethen',
        bundesland: 'Schleswig-Holstein',
        landkreis: 'Kreis Herzogtum Lauenburg',
        plz: '23911',
    },
    {
        id: '12801',
        ort: 'Zilshausen',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Hunsrück-Kreis',
        plz: '56288',
    },
    {
        id: '12802',
        ort: 'Ziltendorf',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Oder-Spree',
        plz: '15295',
    },
    {
        id: '12803',
        ort: 'Zimmern',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07778',
    },
    {
        id: '12804',
        ort: 'Zimmern ob Rottweil',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78652',
    },
    {
        id: '12805',
        ort: 'Zimmern ob Rottweil',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Rottweil',
        plz: '78658',
    },
    {
        id: '12806',
        ort: 'Zimmernsupra',
        bundesland: 'Thüringen',
        landkreis: 'Landkreis Gotha',
        plz: '99100',
    },
    {
        id: '12807',
        ort: 'Zimmern unter der Burg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Zollernalbkreis',
        plz: '72369',
    },
    {
        id: '12808',
        ort: 'Zimmerschied',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Rhein-Lahn-Kreis',
        plz: '56379',
    },
    {
        id: '12809',
        ort: 'Zingst',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18374',
    },
    {
        id: '12810',
        ort: 'Zinnowitz',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17454',
    },
    {
        id: '12811',
        ort: 'Zirchow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17419',
    },
    {
        id: '12812',
        ort: 'Zirkow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Rügen',
        plz: '18528',
    },
    {
        id: '12813',
        ort: 'Zirndorf',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Fürth',
        plz: '90513',
    },
    {
        id: '12814',
        ort: 'Zirzow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17039',
    },
    {
        id: '12815',
        ort: 'Zislow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Mecklenburgische Seenplatte',
        plz: '17209',
    },
    {
        id: '12816',
        ort: 'Zittau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02763',
    },
    {
        id: '12817',
        ort: 'Zittau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Görlitz',
        plz: '02788',
    },
    {
        id: '12818',
        ort: 'Zölkow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19374',
    },
    {
        id: '12819',
        ort: 'Zolling',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Freising',
        plz: '85406',
    },
    {
        id: '12820',
        ort: 'Zöllnitz',
        bundesland: 'Thüringen',
        landkreis: 'Saale-Holzland-Kreis',
        plz: '07751',
    },
    {
        id: '12821',
        ort: 'Zörbig',
        bundesland: 'Sachsen-Anhalt',
        landkreis: 'Landkreis Anhalt-Bitterfeld',
        plz: '06780',
    },
    {
        id: '12822',
        ort: 'Zorneding',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Ebersberg',
        plz: '85604',
    },
    {
        id: '12823',
        ort: 'Zornheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55270',
    },
    {
        id: '12824',
        ort: 'Zöschingen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '89447',
    },
    {
        id: '12825',
        ort: 'Zossen',
        bundesland: 'Brandenburg',
        landkreis: 'Landkreis Teltow-Fläming',
        plz: '15806',
    },
    {
        id: '12826',
        ort: 'Zotzenheim',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Mainz-Bingen',
        plz: '55576',
    },
    {
        id: '12827',
        ort: 'Zschepplin',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Nordsachsen',
        plz: '04838',
    },
    {
        id: '12828',
        ort: 'Zschopau',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09405',
    },
    {
        id: '12829',
        ort: 'Zschopau',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '09434',
    },
    {
        id: '12830',
        ort: 'Zschorlau',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '08321',
    },
    {
        id: '12831',
        ort: 'Zülow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Ludwigslust-Parchim',
        plz: '19073',
    },
    {
        id: '12832',
        ort: 'Zülpich',
        bundesland: 'Nordrhein-Westfalen',
        landkreis: 'Kreis Euskirchen',
        plz: '53909',
    },
    {
        id: '12833',
        ort: 'Zurow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23992',
    },
    {
        id: '12834',
        ort: 'Zusamaltheim',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Dillingen an der Donau',
        plz: '86637',
    },
    {
        id: '12835',
        ort: 'Züsch',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Landkreis Trier-Saarburg',
        plz: '54422',
    },
    {
        id: '12836',
        ort: 'Zusmarshausen',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Augsburg',
        plz: '86441',
    },
    {
        id: '12837',
        ort: 'Züsow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Nordwestmecklenburg',
        plz: '23992',
    },
    {
        id: '12838',
        ort: 'Züssow',
        bundesland: 'Mecklenburg-Vorpommern',
        landkreis: 'Landkreis Vorpommern-Greifswald',
        plz: '17495',
    },
    {
        id: '12839',
        ort: 'Zuzenhausen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Rhein-Neckar-Kreis',
        plz: '74939',
    },
    {
        id: '12840',
        ort: 'Zweibrücken',
        bundesland: 'Rheinland-Pfalz',
        landkreis: '',
        plz: '66482',
    },
    {
        id: '12841',
        ort: 'Zweifelscheid',
        bundesland: 'Rheinland-Pfalz',
        landkreis: 'Eifelkreis Bitburg-Prüm',
        plz: '54673',
    },
    {
        id: '12842',
        ort: 'Zweiflingen',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Hohenlohekreis',
        plz: '74639',
    },
    {
        id: '12843',
        ort: 'Zwenkau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Leipzig',
        plz: '04442',
    },
    {
        id: '12844',
        ort: 'Zwickau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08056',
    },
    {
        id: '12845',
        ort: 'Zwickau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08058',
    },
    {
        id: '12846',
        ort: 'Zwickau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08060',
    },
    {
        id: '12847',
        ort: 'Zwickau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08062',
    },
    {
        id: '12848',
        ort: 'Zwickau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08064',
    },
    {
        id: '12849',
        ort: 'Zwickau',
        bundesland: 'Sachsen',
        landkreis: 'Landkreis Zwickau',
        plz: '08066',
    },
    {
        id: '12850',
        ort: 'Zwiefalten',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Landkreis Reutlingen',
        plz: '88529',
    },
    {
        id: '12851',
        ort: 'Zwiesel',
        bundesland: 'Bayern',
        landkreis: 'Landkreis Regen',
        plz: '94227',
    },
    {
        id: '12852',
        ort: 'Zwingenberg',
        bundesland: 'Baden-Württemberg',
        landkreis: 'Neckar-Odenwald-Kreis',
        plz: '69439',
    },
    {
        id: '12853',
        ort: 'Zwingenberg',
        bundesland: 'Hessen',
        landkreis: 'Kreis Bergstraße',
        plz: '64673',
    },
    {
        id: '12854',
        ort: 'Zwönitz',
        bundesland: 'Sachsen',
        landkreis: 'Erzgebirgskreis',
        plz: '08297',
    },
]
