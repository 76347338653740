type UploadCareImageOptions = {
    format?: 'jpeg' | 'png' | 'webp' | 'auto'
    quality?: 'normal' | 'better' | 'best' | 'lighter' | 'lightest' | 'smart' | 'smart_retina'
    preview?: string // e.g. '128x128' | '128x' | 'x128'
    crop?: string // e.g. '128x128' | '128x' | 'x128'
    resize?: string // e.g. '128x128' | '128x' | 'x128'
    zoomObjects?: number // 1 - 100
    fill?: string // e.g. 'ffffff00'
    stretch?: string // on | off | fill
}

const defaultUploadcareImageOptions: UploadCareImageOptions = {
    format: 'auto',
    quality: 'smart',
    fill: 'ffffff00',
}

const propToUploadcareOptionName: { [index: string]: string } = {
    format: 'format',
    crop: 'crop',
    resize: 'resize',
    quality: 'quality',
    preview: 'preview',
    zoomObjects: 'zoom_objects',
    fill: 'setfill',
    stretch: 'stretch',
}

const createUploadcareImageSrc = (imageUrl: string, options?: UploadCareImageOptions) => {
    const optionsString = Object.entries({ ...defaultUploadcareImageOptions, ...options })
        .map(([key, value]) => (value ? `-/${propToUploadcareOptionName[key]}/${value}/` : ''))
        .join('')

    return imageUrl + optionsString
}

export default createUploadcareImageSrc
