import { ProductCategoryId, ProductCategoryNode, ProductTypeId } from './types'

export const productCategories: ProductCategoryNode = {
    id: 0 as ProductCategoryId,
    name: 'All',
    Icon: 'null',
    subItems: [
        {
            id: 201 as ProductTypeId,
            name: 'Kite',
            internalName: 'Kite',
            Icon: 'KiteIcon',
            applicableGuides: [],
            applicableServices: [1, 2, 3, 4, 5, 6, 7, 8],
            applicableAlterations: [],
            views: [
                {
                    label: 'serviceDesk.shared.pinpointStep.views.front',
                    sketchUrl:
                        'https://ucarecdn.com/c30195a4-7994-48e7-afa2-6ccccaebdc7f/-/preview/662x377/',
                    details: [
                        {
                            name: 'Middle Strut', // Left
                            features: [],
                            x1: 0.48,
                            x2: 0.52,
                            y1: 0.15,
                            y2: 0.3,
                        },
                    ],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.bottom',
                    sketchUrl:
                        'https://ucarecdn.com/fcb239b4-3f7d-4693-9705-d1a8d72a7958/-/preview/662x377/',
                    details: [
                        {
                            name: 'Middle Strut',
                            features: [],
                            x1: 0.48,
                            x2: 0.52,
                            y1: 0.29,
                            y2: 0.7,
                        },
                        {
                            name: 'Canopy',
                            features: [],
                            x1: 0.13,
                            x2: 0.47,
                            y1: 0.36,
                            y2: 0.7,
                        },
                        {
                            name: 'Canopy',
                            features: [],
                            x1: 0.53,
                            x2: 0.87,
                            y1: 0.36,
                            y2: 0.7,
                        },
                    ],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.side',
                    sketchUrl:
                        'https://ucarecdn.com/ad4d86c4-4088-4e83-a9f3-e7ee99361836/-/preview/662x377/',
                    details: [
                        {
                            name: 'Canopy',
                            features: [],
                            x1: 0.3,
                            x2: 0.7,
                            y1: 0.2,
                            y2: 0.85,
                        },
                    ],
                },
            ],
        },
        {
            id: 202 as ProductTypeId,
            name: 'Boards',
            internalName: 'Boards',
            Icon: 'KiteboardIcon',
            applicableGuides: [],
            applicableServices: [],
            applicableAlterations: [],
            views: [],
        },
        {
            id: 203 as ProductTypeId,
            name: 'Bar',
            internalName: 'Bar',
            Icon: 'KiteBarIcon',
            applicableGuides: [],
            applicableServices: [],
            applicableAlterations: [],
            views: [],
        },
    ],
}
export default productCategories
