import defaultRepairServices from '../repair-services'
import vaudeRepairGuides from '../repair-guides'
import defaultProductCategoryTree from '../products'
import { alpha } from '@mui/material'
import { FieldType, WhitelabelStatus, type Whitelabel } from './types'

/*
    TODO:
    - Adjust ID
    - Adjust name (variable and brand name)
    - Adjust theme
    - Adjust logo
    - Adjust repair guides DE / EN
    - Adjust repair services DE / EN
    - Define product category tree
    - Define outro links
    - Adjust plugin config (email recipients)
*/
const berghausWhitelabel: Whitelabel = {
    id: '1wxnj0',
    status: WhitelabelStatus.InternalOnly,
    name: 'Berghaus',
    theme: {
        palette: {
            primary: {
                main: '#F7985D',
                contrastText: '#000000',
            },
            secondary: {
                main: '#E2D4C8',
                contrastText: '#282727',
            },
            getCrosshairColors: () => ({ stroke: '#F7985D', fill: alpha('#F7985D', 0.4) }),
            getIllustrationColors: () => ({ primary: '#F7985D', accent: alpha('#F7985D', 0.4) }),
            getProgressbarColor: () => '#F7985D',
            getTextColor: () => '#000000',
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    outlined: {
                        color: '#000000',
                        borderColor: alpha('#F7985D', 0.8),
                    },
                },
            },
        },
    },
    logo: 'https://ucarecdn.com/b4acdc34-a4d7-4872-af20-112223b9e1d7/',

    repairGuides: vaudeRepairGuides,
    repairServices: defaultRepairServices,
    productCategoryTree: defaultProductCategoryTree,
    alterationServices: [],
    config: {
        repair: {
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Comment',
                            label: 'serviceDesk.shared.contactStep.form.commentLabel',
                            type: FieldType.TextField,
                            params: { required: false },
                            fieldProps: {
                                multiline: true,
                                rows: 2,
                            },
                        },
                    ],
                ],
            },
            activateCostEstimation: true,
            includeDiyGuides: true,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [
                    {
                        href: 'https://www.berghaus.com',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: ['marc@brakeable.com', 'stefan@brakeable.com'],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
    },
}

export default berghausWhitelabel
