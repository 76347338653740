import vaudeProductCategoryTree from '../products/vaude-products'
import {
    Field,
    FieldType,
    WarrantyInformationStepType,
    WhitelabelStatus,
    type Whitelabel,
} from './types'
import vaudeRepairGuides from '../repair-guides/vaude-repair-guides'
import repairServices from '../repair-services'

const contactFields: Field[][] = [
    [
        {
            key: 'Firstname',
            label: 'serviceDesk.shared.contactStep.form.firstnameLabel',
            type: FieldType.TextField,
            params: { required: true },
        },
        {
            key: 'Lastname',
            label: 'serviceDesk.shared.contactStep.form.lastnameLabel',
            type: FieldType.TextField,
            params: { required: true },
        },
    ],
    [
        {
            key: 'Email',
            label: 'serviceDesk.shared.contactStep.form.emailLabel',
            type: FieldType.TextField,
            params: { required: true },
            fieldProps: { type: 'email' },
        },
    ],
    [
        {
            key: 'Address',
            label: 'serviceDesk.shared.contactStep.form.addressLabel',
            type: FieldType.TextField,
            params: { required: true },
        },
    ],
    [
        {
            key: 'ZipCode',
            label: 'serviceDesk.shared.contactStep.form.zipCodeLabel',
            type: FieldType.TextField,
            params: { required: true },
            fieldProps: {
                type: 'number',
                fieldControlSx: { width: 150, flexShrink: 0 },
            },
        },
        {
            key: 'City',
            label: 'serviceDesk.shared.contactStep.form.cityLabel',
            type: FieldType.TextField,
            params: { required: true },
        },
    ],
]
/*
    TODO:
    - Adjust ID
    - Adjust name (variable and brand name)
    - Adjust theme
    - Adjust logo
    - Adjust repair guides DE / EN
    - Adjust repair services DE / EN
    - Define product category tree
    - Define outro links
    - Adjust plugin config (email recipients)
*/
const jackwolfskinWhitelabel: Whitelabel = {
    id: '8cdu2n',
    status: WhitelabelStatus.ExternalDemoAccess,
    name: 'Jack Wolfskin',
    theme: {
        palette: {
            primary: {
                main: '#212121',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#FBBB00',
                contrastText: '#212121',
            },
            getCrosshairColors: () => ({ stroke: '#212121', fill: '#ffffff' }),
            getIllustrationColors: () => ({ primary: '#212121', accent: '#FBBB00' }),
            getProgressbarColor: () => '#FBBB00',
            getTextColor: () => '#212121',
        },
    },
    logo: 'https://ucarecdn.com/7869f2f6-a8a8-4984-a53f-9a6498f9a035/',
    links: {
        storeFinder: 'https://www.jack-wolfskin.de/storefinder/',
    },
    repairGuides: vaudeRepairGuides,
    repairServices: repairServices,
    alterationServices: [],
    productCategoryTree: vaudeProductCategoryTree,
    config: {
        repair: {
            storeFinderIframeUrl: 'https://www.jack-wolfskin.de/storefinder/',
            contactForm: {
                fields: contactFields,
            },
            activateCostEstimation: true,
            includeDiyGuides: true,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [
                    {
                        href: 'https://www.jack-wolfskin.com/',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: ['marc@brakeable.com', 'stefan@brakeable.com'],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
        warranty: {
            contactForm: {
                fields: contactFields,
            },
            warrantyInformationSteps: [
                {
                    fieldKey: 'proofOfPurchase',
                    type: WarrantyInformationStepType.Photo,
                    photo: {
                        key: 'proofOfPurchasePhoto',
                        label: 'serviceDesk.warranty.warrantyInformationSteps.warrantyInformationPhotoStep.label',
                        options: { required: true },
                    },
                    alternativeFields: [
                        [
                            {
                                key: 'Orderreference',
                                label: 'serviceDesk.warranty.purchaseConfirmationStep.form.orderReferenceLabel',
                                type: FieldType.TextField,
                                params: { required: true },
                            },
                        ],
                        [
                            {
                                key: 'ArticleNumber',
                                label: 'serviceDesk.warranty.purchaseConfirmationStep.form.articleNumberLabel',
                                type: FieldType.TextField,
                                params: { required: true },
                            },
                        ],
                    ],
                    required: false,
                    translations: {
                        title: 'serviceDesk.warranty.purchaseConfirmationStep.linktree.title',
                        photoTitle:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.photoTitle',
                        photoSubtitle:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.photoSubtitle',
                        textTitle:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.manualTitle',
                        textSubtitle:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.manualSubtitle',
                        notAvailableQuestion:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.notAvailable.delimiter',
                        notAvailableAction:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.notAvailable.button',
                    },
                },
            ],
            activateWarrantyEstimation: false,
            outro: {
                lead: 'serviceDesk.warranty.outroStep.subtitle',
                links: [
                    {
                        href: 'https://www.jack-wolfskin.com/',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: ['marc@brakeable.com', 'stefan@brakeable.com'],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
    },
}

export default jackwolfskinWhitelabel
