import ortliebProductCategoryTree from '../products/ortlieb-products'
import {
    Field,
    FieldType,
    WarrantyInformationStepType,
    WhitelabelStatus,
    type Whitelabel,
} from './types'
import ortliebRepairGuides from '../repair-guides/ortlieb-repair-guide-videos'
import ortliebRepairServices from '../repair-services/ortlieb-support-services'
import ortliebAlterationServices from '../alteration-services/ortlieb-alteration-services'
import { OrderType } from '../../types/warranty/plugins/ortlieb'
import germanZipCodes from '../zip-codes/german-zip-codes'

export enum OrtliebFieldKeys {
    Firstname = 'Firstname',
    Lastname = 'Lastname',
    Email = 'Email',
    Street = 'Street',
    ZipCode = 'ZipCode',
    City = 'City',
    Country = 'Country',
    Phone = 'Phone',
    ProductInformation = 'ProductInformation',
    PurchaseDate = 'PurchaseDate',
    ProductName = 'ProductName',
    ArticleNumber = 'ArticleNumber',
    ReplacementProduct = 'ReplacementProduct',
    ProofOfPurchase = 'ProofOfPurchase',
}

const contactFields: Field[][] = [
    [
        {
            key: OrtliebFieldKeys.Firstname,
            label: 'serviceDesk.shared.forms.firstnameLabel',
            type: FieldType.TextField,
            params: { required: true },
        },
        {
            key: OrtliebFieldKeys.Lastname,
            label: 'serviceDesk.shared.forms.lastnameLabel',
            type: FieldType.TextField,
            params: { required: true },
        },
    ],
    [
        {
            key: OrtliebFieldKeys.Email,
            label: 'serviceDesk.shared.forms.emailLabel',
            type: FieldType.TextField,
            params: { required: true },
            fieldProps: { type: 'email' },
        },
    ],
    [
        {
            key: OrtliebFieldKeys.Phone,
            label: 'serviceDesk.shared.forms.phoneLabel',
            type: FieldType.TextField,
            params: { required: true },
            fieldProps: { type: 'tel' },
        },
    ],
    [
        {
            key: OrtliebFieldKeys.Street,
            label: 'serviceDesk.shared.forms.streetNoLabel',
            type: FieldType.TextField,
            params: { required: true },
        },
    ],
    [
        {
            key: OrtliebFieldKeys.City,
            label: 'serviceDesk.shared.forms.zipAndCityLabel',
            type: FieldType.Autocomplete,
            options: germanZipCodes.map(({ id, plz, ort, bundesland, landkreis }) => ({
                value: id,
                label: `${plz} - ${bundesland} - ${landkreis ? `${landkreis} - ` : ''}${ort}`,
            })),
            params: { required: true },
        },
    ],
]

/*
    TODO:
    - Adjust ID
    - Adjust name (variable and brand name)
    - Adjust theme
    - Adjust logo
    - Adjust repair guides DE / EN
    - Adjust repair services DE / EN
    - Define product category tree
    - Define outro links
    - Adjust plugin config (email recipients)
*/
const ortliebWhitelabel: Whitelabel = {
    id: '1c3fyj',
    status: WhitelabelStatus.ExternalDemoAccess,
    name: 'Ortlieb',
    theme: {
        palette: {
            primary: {
                main: '#EE9000',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#EE9000',
                contrastText: '#ffffff',
            },
            getCrosshairColors: () => ({ stroke: '#EE9000', fill: '#ffffff' }),
            getIllustrationColors: () => ({ primary: '#EE9000', accent: '#23201F' }),
            getProgressbarColor: () => '#EE9000',
            getTextColor: () => '#23201F',
        },
    },
    logo: 'https://ucarecdn.com/dc7efdb0-0fae-4c18-b375-59d3b9dd1db0/',
    repairGuides: ortliebRepairGuides,
    repairServices: ortliebRepairServices,
    alterationServices: ortliebAlterationServices,
    productCategoryTree: ortliebProductCategoryTree,
    config: {
        repair: {
            storeFinderIframeUrl:
                'https://brandwidgets.outtra.com/brand-widget/ortlieb/service-map/?outtrabrandId=193&language=',
            contactForm: {
                fields: contactFields,
            },
            activateCostEstimation: true,
            includeDiyGuides: false,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [
                    {
                        href: '//www.ortlieb.com',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: ['marc@brakeable.com', 'stefan@brakeable.com'],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
        warranty: {
            contactForm: {
                fields: contactFields,
            },
            activateWarrantyEstimation: false,

            warrantyInformationSteps: [
                {
                    fieldKey: OrtliebFieldKeys.ProductInformation,
                    title: 'serviceDesk.warranty.purchaseInformationStep.title',
                    type: WarrantyInformationStepType.Text,
                    fields: [
                        [
                            {
                                key: OrtliebFieldKeys.PurchaseDate,
                                label: 'serviceDesk.shared.forms.purchaseDateLabel',
                                type: FieldType.TextField,
                                params: { required: false },
                                fieldProps: {
                                    type: 'date',
                                },
                            },
                        ],
                        [
                            {
                                key: OrtliebFieldKeys.ProductName,
                                label: 'serviceDesk.shared.forms.productNameLabel',
                                type: FieldType.TextField,
                                params: { required: false },
                            },
                        ],
                        [
                            {
                                key: OrtliebFieldKeys.ArticleNumber,
                                label: 'serviceDesk.shared.forms.articleNumberLabel',
                                type: FieldType.TextField,
                                params: { required: false },
                            },
                        ],
                        [
                            {
                                key: OrtliebFieldKeys.ReplacementProduct,
                                label: 'serviceDesk.shared.forms.replacementProductLabel',
                                type: FieldType.TextField,
                                params: { required: false },
                            },
                        ],
                    ],
                },
                {
                    fieldKey: OrtliebFieldKeys.ProofOfPurchase,
                    type: WarrantyInformationStepType.Photo,
                    photo: {
                        key: OrtliebFieldKeys.ProofOfPurchase,
                        label: 'serviceDesk.warranty.warrantyInformationSteps.warrantyInformationPhotoStep.label',
                    },
                    required: false,
                    translations: {
                        title: 'serviceDesk.warranty.proofOfPurchaseStep.title',
                        photoTitle: 'serviceDesk.warranty.proofOfPurchaseStep.title',
                        photoSubtitle:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.photoSubtitle',
                        textTitle:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.manualTitle',
                        textSubtitle:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.manualSubtitle',
                        notAvailableQuestion:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.notAvailable.delimiter',
                        notAvailableAction:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.notAvailable.button',
                    },
                },
            ],
            outro: {
                lead: 'serviceDesk.warranty.outroStep.subtitle',
                links: [
                    {
                        href: '//www.ortlieb.com',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: ['marc@brakeable.com', 'stefan@brakeable.com'],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
                {
                    name: 'ortlieb',
                    config: {
                        orderType: OrderType.B2C,
                        senderEmail: 'deinservice@ortlieb.com',
                    },
                },
            ],
        },
        alterations: {
            contactForm: {
                fields: contactFields,
            },
            outro: {
                lead: 'serviceDesk.warranty.outroStep.subtitle',
                links: [
                    {
                        href: '//www.ortlieb.com',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: ['marc@brakeable.com', 'stefan@brakeable.com'],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
    },
}

export default ortliebWhitelabel
