import React from 'react'
import { WarrantyMultiStepFormContext } from '../context'
import StepFrameworkWrapper from '../../shared/components/step-framework-wrapper'
import { Stack } from '@mui/material'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import InspectorStepTitle from '../../../../components/typography/inspector-step-title'
import {
    Field as FieldT,
    WarrantyInformationTextStep,
} from '../../../../../shared/data/whitelabel/types'
import Field from '../../shared/components/field'

const DetailsStep = ({
    useFormReturn,
    fields,
}: {
    useFormReturn: UseFormReturn
    fields: FieldT[][]
}) => {
    const [t] = useTranslation()
    return (
        <>
            {fields.map((cells, index) => (
                <Stack
                    direction="row"
                    mb={index === fields.length - 1 ? 0 : 1}
                    gap={2}
                    sx={{ width: '100%' }}
                    key={index}
                >
                    {cells.map((cell) => (
                        <Field key={cell.key} field={cell} useForm={useFormReturn} />
                    ))}
                </Stack>
            ))}
        </>
    )
}

const DetailsStepContext = ({ fieldKey, fields, title }: WarrantyInformationTextStep) => {
    const { props, form } = React.useContext(WarrantyMultiStepFormContext)
    const [allRequiredFieldsFilled, setAllRequiredFieldsFilled] = React.useState(false)
    const formValues = (form.formValues.warrantyInformationForm?.[fieldKey] ?? {}) as Record<
        string,
        any
    >
    const useFormReturn = useForm({ values: formValues })
    const v = useFormReturn.watch()

    const allRequiredFields = fields
        .filter((field) => field.some((cell) => cell.params.required))
        .flatMap((field) => field)

    React.useEffect(() => {
        const filled = allRequiredFields.every((field) => useFormReturn.getValues(field.key))
        setAllRequiredFieldsFilled(filled)
    }, [v, allRequiredFields])

    return (
        <StepFrameworkWrapper
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled: !allRequiredFieldsFilled,
                onValidNextClick: () => {
                    form.setValue('warrantyInformationForm', {
                        ...form.formValues.warrantyInformationForm,
                        [fieldKey]: useFormReturn.getValues(),
                    })
                    props.nextSlide()
                },
            }}
        >
            <InspectorStepTitle i18nKey={title as any} mb={4} />
            <DetailsStep useFormReturn={useFormReturn} fields={fields} />
        </StepFrameworkWrapper>
    )
}

export default DetailsStepContext
