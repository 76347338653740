import { RepairGuideType } from './types'

export default [
    {
        id: 151451,
        url: 'https://www.youtube.com/embed/tjisKQ334RU',
        title: 'Seat-Pack Reparaturvideo',
        summary: 'How to replace the hook-and-loop closure on your Seat-Pack yourself.',
        image: 'https://i.ytimg.com/vi/tjisKQ334RU/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLBWrPKUvLQ5zfaaUC0UvtjsuNdmGA',
        brandId: '1c3fyj',
        translations: {
            de: 'Seat-Pack Reparaturvideo',
            en: 'Seat-Pack Repair Video',
        },
        type: RepairGuideType.VIDEO,
    },
    {
        id: 151453,
        url: 'https://www.youtube.com/embed/4QkjZjieYOY',
        title: 'Quick-Lock Reparaturvideo',
        summary: 'How to repair the Quick-Lock system on your bike bag yourself.',
        image: 'https://i.ytimg.com/vi/4QkjZjieYOY/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLBRxJxcjdmx9E6yDd53FfS75uXc7A',
        brandId: '1c3fyj',
        translations: {
            de: 'Quick-Lock Reparaturvideo',
            en: 'Quick-Lock Repair Video',
        },
        type: RepairGuideType.VIDEO,
    },
    {
        id: 169514,
        url: 'https://www.youtube.com/embed/oFiKgNYcLk8',
        title: 'Top-Lock Reparaturvideo',
        summary: 'How to replace the lever of a Top-Lock product yourself.',
        image: 'https://i.ytimg.com/vi/4QkjZjieYOY/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLBRxJxcjdmx9E6yDd53FfS75uXc7A',
        brandId: '1c3fyj',
        translations: {
            de: 'Top-Lock Reparaturvideo',
            en: 'Top-Lock Repair Video',
        },
        type: RepairGuideType.VIDEO,
    },
    {
        id: 169515,
        url: 'https://www.youtube.com/embed/rRqSyq5owwY',
        title: 'Ultimate Reparaturvideo',
        summary: 'How to change the lid and the frame of an ORTLIEB handlebar bag.',
        image: 'https://i.ytimg.com/vi/4QkjZjieYOY/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLBRxJxcjdmx9E6yDd53FfS75uXc7A',
        brandId: '1c3fyj',
        translations: {
            de: 'Ultimate Reparaturvideo',
            en: 'Ultimate Repair Video',
        },
        type: RepairGuideType.VIDEO,
    },
    {
        id: 169517,
        url: 'https://www.youtube.com/embed/n8puotTYeX4',
        title: 'Buckles Reparaturvideo',
        summary: 'How to replace the buckles on your ORTLIEB bag yourself.',
        image: 'https://i.ytimg.com/vi/4QkjZjieYOY/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLBRxJxcjdmx9E6yDd53FfS75uXc7A',
        brandId: '1c3fyj',
        translations: {
            de: 'Buckles Reparaturvideo',
            en: 'Buckles Repair Video',
        },
        type: RepairGuideType.VIDEO,
    },
]
