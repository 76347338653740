import { Navigate } from 'react-router-dom'
import { Role } from '../../../../shared/auth/role'
import useAuth from '../../../mixins/use-auth'
import {
    Page,
    Header,
    WidthContainer,
    BrandLogo,
    Content,
    Footer,
    PBB,
    PoweredBy,
    PoweredByBrakeable,
} from '../../../components/layout'
import brkWhitelabel from '../../../../shared/data/whitelabel/brakeable-whitelabel'
import { useQuery, getAllAISuggestions } from 'wasp/client/operations'
import { styled, Stack, Box, Typography, Chip } from '@mui/material'
import React from 'react'
import routeBuilder from '../../../routes'

const SuggestionContainer = styled(Box)(
    ({ theme }) => `
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(2)};
    border: 1px solid ${theme.palette.primary.main};
    border-radius: ${theme.shape.borderRadius}px;
    background-color: ${theme.palette.background.paper};

    ul {
        list-style: disc;
        margin-bottom: ${theme.spacing(1)};
    }
`
)

const Logo = styled('img')(
    ({ theme }) => `
    width: 100px;
    object-fit: contain;
`
)
const AIVerifier = () => {
    const [selectedBrands, setSelectedBrands] = React.useState<string[]>([])
    const { data, error } = useQuery(getAllAISuggestions)

    const allBrands = React.useMemo(
        () =>
            data
                ? Array.from(
                      new Map(
                          data.map((logEntry) => [
                              logEntry.brandId,
                              {
                                  id: logEntry.brandId,
                                  name: logEntry.brandName,
                              },
                          ])
                      ).values()
                  )
                : [],
        [data]
    )

    React.useEffect(() => {
        setSelectedBrands(
            allBrands.map((brand) => brand.id).filter((id) => !['6pzljy', '1iutwg'].includes(id))
        )
    }, [allBrands])

    if (error) {
        return 'error'
    }
    if (!data) {
        return 'loading'
    }

    return (
        <Page>
            <Header>
                <WidthContainer $wide sx={{ justifyContent: 'space-between' }}>
                    <a href={routeBuilder.base()}>
                        <BrandLogo src={brkWhitelabel.logo} alt={brkWhitelabel.name} />
                    </a>
                </WidthContainer>
            </Header>
            <Content sx={{ justifyContent: 'center' }}>
                <WidthContainer>
                    <Stack direction="row" gap={1} my={2} flexWrap="wrap">
                        {allBrands.map((brand) => {
                            const isSelected = selectedBrands.includes(brand.id)
                            return (
                                <Chip
                                    key={brand.id}
                                    label={brand.name}
                                    onClick={() =>
                                        isSelected
                                            ? setSelectedBrands(
                                                  selectedBrands.filter((id) => id !== brand.id)
                                              )
                                            : setSelectedBrands([...selectedBrands, brand.id])
                                    }
                                    color={isSelected ? 'primary' : 'default'}
                                />
                            )
                        })}
                    </Stack>
                    <Stack gap={2}>
                        {data
                            .filter((suggestion) => {
                                if (selectedBrands.length === 0) return true
                                return selectedBrands.includes(suggestion.brandId)
                            })
                            .map((suggestion) => (
                                <SuggestionContainer key={suggestion.id}>
                                    <Stack
                                        direction="row"
                                        gap={2}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Stack direction="row" gap={2} alignItems="center">
                                            <Logo
                                                src={suggestion.logoUrl}
                                                alt={suggestion.brandId}
                                            />
                                            <Typography variant="h5" component="h2">
                                                {suggestion.productCategory}
                                            </Typography>
                                        </Stack>
                                        <Typography component="span">
                                            {suggestion.userName}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" gap={4} alignItems="flex-start">
                                        <Box sx={{ width: '50%', flexShrink: 1 }}>
                                            <Stack direction="row" gap={1}>
                                                <Typography fontWeight="bold" component="h3">
                                                    Description:
                                                </Typography>
                                                <Typography fontStyle="italic" component="span">
                                                    "{suggestion.defectDescription}"
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" gap={1}>
                                                <Typography fontWeight="bold" component="h3">
                                                    Component:
                                                </Typography>
                                                <Typography component="span">
                                                    {suggestion.productComponent}
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" gap={1}>
                                                <Typography fontWeight="bold" component="h3">
                                                    Timestamp:
                                                </Typography>
                                                <Typography component="span">
                                                    {suggestion.displayDate}
                                                </Typography>
                                            </Stack>
                                        </Box>
                                        <Box sx={{ width: '50%', flexShrink: 1 }}>
                                            <Typography fontWeight="bold" component="h3">
                                                Services:
                                            </Typography>
                                            <ul>
                                                {suggestion.services.map((service) => (
                                                    <li key={service}>{service}</li>
                                                ))}
                                            </ul>
                                            <Typography fontWeight="bold" component="h3">
                                                Guides:
                                            </Typography>
                                            <ul>
                                                {suggestion.guides.map((guide) => (
                                                    <li key={guide}>{guide}</li>
                                                ))}
                                            </ul>
                                        </Box>
                                    </Stack>
                                </SuggestionContainer>
                            ))}
                    </Stack>
                </WidthContainer>
            </Content>
            <Footer>
                <WidthContainer $wide sx={{ justifyContent: 'flex-end' }}>
                    <PBB>
                        <PoweredBy>Powered by</PoweredBy>{' '}
                        <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                    </PBB>
                </WidthContainer>
            </Footer>
        </Page>
    )
}

const RoleSwitch = () => {
    const { user } = useAuth()
    switch (user?.role) {
        case Role.Admin:
            return <AIVerifier />
        default:
            return <Navigate to="/" />
    }
}

export default RoleSwitch
