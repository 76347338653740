import React from 'react'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import styled from 'styled-components'
import * as PasswordValidation from '../../../../shared/password/validate-secure-password'
import T from '../../../components/typography/t'

const StyledCheckbox = styled(Checkbox)`
    cursor: default !important;
`

const PasswordRequirements = ({ password }: { password: string }) => {
    return (
        <List>
            <ListSubheader>
                <T i18nKey="company.onboarding.form.requirements.title" />
            </ListSubheader>
            <ListItem disablePadding>
                <ListItemIcon>
                    <StyledCheckbox
                        edge="start"
                        color="primary"
                        checked={PasswordValidation.isLongEnough(password)}
                        tabIndex={-1}
                        disableRipple
                    />
                </ListItemIcon>
                <ListItemText>
                    <T
                        i18nKey="company.onboarding.form.requirements.length"
                        variant="body2"
                        component="p"
                    />
                </ListItemText>
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon>
                    <StyledCheckbox
                        edge="start"
                        color="primary"
                        checked={
                            PasswordValidation.hasLowerCase(password) &&
                            PasswordValidation.hasUpperCase(password)
                        }
                        tabIndex={-1}
                        disableRipple
                    />
                </ListItemIcon>
                <ListItemText>
                    <T
                        i18nKey="company.onboarding.form.requirements.casing"
                        variant="body2"
                        component="p"
                    />
                </ListItemText>
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon>
                    <StyledCheckbox
                        edge="start"
                        color="primary"
                        checked={PasswordValidation.hasNumber(password)}
                        tabIndex={-1}
                        disableRipple
                    />
                </ListItemIcon>
                <ListItemText>
                    <T
                        i18nKey="company.onboarding.form.requirements.number"
                        variant="body2"
                        component="p"
                    />
                </ListItemText>
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon>
                    <StyledCheckbox
                        edge="start"
                        color="primary"
                        checked={PasswordValidation.hasSpecialCharacter(password)}
                        tabIndex={-1}
                        disableRipple
                    />
                </ListItemIcon>
                <ListItemText>
                    <T
                        i18nKey="company.onboarding.form.requirements.special"
                        variant="body2"
                        component="p"
                    />
                </ListItemText>
            </ListItem>
        </List>
    )
}

export default PasswordRequirements
