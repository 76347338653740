import React from 'react'
import { type Whitelabel } from '../../../../shared/data/whitelabel/types'
import { ProductCategoryNode, ProductTypeId } from '../../../../shared/data/products/types'
import { MultiStepFormContextType, getDefaultContextValues } from '../shared/context-types'

export type WhitelabelWithAlterationsConfig = Whitelabel & {
    config: Required<Pick<Whitelabel['config'], 'alterations'>> &
        Omit<Whitelabel['config'], 'alterations'>
}

export type StepCtx = {
    productCategoryTree: ProductCategoryNode
    whitelabel: WhitelabelWithAlterationsConfig
    isIframe: boolean
}

export type FormValues = {
    selectedProduct?: ProductTypeId
    selectedProductView: number
    description?: string
    selectedAlterations: number[]
    contactFormValues: Record<string, string | number | boolean>
}

export const AlterationsMultiStepFormContext = React.createContext<
    MultiStepFormContextType<StepCtx, FormValues>
>(getDefaultContextValues())
