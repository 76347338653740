import Typography from '@mui/material/Typography'
import {
    OverridableComponent,
    OverridableTypeMap,
    OverrideProps,
} from '@mui/material/OverridableComponent'
import styled from 'styled-components'
import BrushStroke from '../../assets/img/brush-stroke.svg'

const Typo: OverridableComponent<OverridableTypeMap> = styled(Typography)`
    position: relative;
    display: inline-block;

    img {
        z-index: 0;
        position: absolute;
        width: 120%;
        height: 160%;
        left: -15%;
        bottom: -50%;
        opacity: 0.7;
        transform: rotate(-5deg);
    }

    span {
        position: relative;
        z-index: 1;
    }
`

const CardTitle = ({
    children,
    ...props
}: OverrideProps<OverridableTypeMap, React.ElementType>) => {
    return (
        <Typo variant="h4" component="h2" mb={0} ml={0} {...props}>
            <img src={BrushStroke} />
            <span>{children}</span>
        </Typo>
    )
}

export default CardTitle
