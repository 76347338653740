import { createRouteBuilder } from '../shared/routes'

const basePath = (import.meta as any).env?.REACT_APP_BASE_URL
const backendPath = (import.meta as any).env?.REACT_APP_BACKEND_URL

const routeBuilder = createRouteBuilder(basePath)

export const copilotKitEndpoint = `${backendPath}/api/copilotKit`

export default routeBuilder
