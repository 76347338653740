import React from 'react'
import { UseFormReturn, Controller } from 'react-hook-form'
import { Field as FieldT, FieldType } from '../../../../../shared/data/whitelabel/types'
import InputField from '../../../../components/form/input-field'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete'
import { ClearIcon } from '@mui/x-date-pickers/icons'

const Field = ({ field, useForm }: { field: FieldT; useForm: UseFormReturn<any> }) => {
    const [t] = useTranslation()
    const { register, watch, setValue, control } = useForm
    if (field.type === FieldType.TextField) {
        return (
            <InputField
                key={field.key}
                id={field.key}
                fullWidth
                {...register(field.key as any, field.params)}
                {...(field.fieldProps as any)}
                required={field.params?.required}
                label={t(field.label as any)}
            />
        )
    }
    if (field.type === FieldType.Autocomplete) {
        const watchedValue = watch(field.key)?.value
        const value = React.useMemo(
            () => field.options.find((o) => o.value === watchedValue) || null,
            [field.options, watchedValue]
        )

        return (
            <Controller
                name={field.key}
                control={control}
                render={({ field: { onChange, onBlur } }) => (
                    <Autocomplete
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(field.key, newValue)
                            onChange(newValue)
                        }}
                        onBlur={onBlur}
                        autoComplete
                        autoHighlight
                        autoSelect
                        options={field.options}
                        getOptionLabel={(option) => option.label || ''}
                        renderInput={(params) => (
                            <InputField
                                {...params}
                                label={t(field.label as any)}
                                {...(field.fieldProps as any)}
                            />
                        )}
                        sx={{ width: '100%' }}
                        clearIcon={<ClearIcon />}
                    />
                )}
            />
        )
    }
    return null
}

export default Field
