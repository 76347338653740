import { updateCompanyLogoUrl, addCompanyAccount } from 'wasp/client/operations'
import * as React from 'react'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import CompanySettingsViewModel from '../../../../shared/types/company/company-information-view-model'
import LogoForm from './form-parts/logo-form'
import CardTitle from '../../../components/typography/card-title'
import T from '../../../components/typography/t'
import { useIsMobile } from '../../../mixins/media-query'
import NewEmployeeForm from './form-parts/new-employee-form'
import CopyToClipboard from '../../../components/copy-to-clipboard'
import RoleProtector from '../../../components/auth/role-protector'
import { Role } from '../../../../shared/auth/role'
import HeaderBar from '../inspector/products/components/header-bar'

const CompanyAccount = ({ companySettings }: { companySettings: CompanySettingsViewModel }) => {
    const isMobile = useIsMobile()

    const saveLogoUrl = async (logoUrl: string, onSave: () => void, onError: () => void) => {
        try {
            await updateCompanyLogoUrl({ logoUrl, companyId: companySettings.id })
            onSave()
        } catch (e) {
            onError()
        }
    }

    const inviteCompanyAccount = async (formData: {
        email: string
        name: string
        lang: string
    }) => {
        try {
            await addCompanyAccount({
                companyId: companySettings.id,
                employeeName: formData.name,
                employeeEmail: formData.email,
                employeeLang: formData.lang,
            })
        } catch (e) {
            console.error('Error adding company account:', e)
        }
    }

    return (
        <Box>
            <HeaderBar>
                <CardTitle>
                    <T i18nKey="company.companySettings.account.title" />
                </CardTitle>
            </HeaderBar>
            <Card>
                <CardContent>
                    <Box
                        display="flex"
                        alignItems="center"
                        px={isMobile ? 0 : 2}
                        flexDirection={isMobile ? 'column' : 'row'}
                    >
                        <LogoForm
                            logoUrl={companySettings.settings.logoUrl}
                            onFileUpload={saveLogoUrl}
                        />
                        <Box ml={isMobile ? 0 : 5} mt={isMobile ? 2 : 0} width="100%">
                            {companySettings.employees.map((employee) => (
                                <Typography key={employee.email} variant="body1" component="div">
                                    {employee.name} - {employee.email}
                                    {employee.verificationLink && (
                                        <>
                                            {' - '}
                                            <CopyToClipboard
                                                label="Einladungslink"
                                                copiedLabel="Kopiert!"
                                                link={employee.verificationLink}
                                            />
                                        </>
                                    )}
                                </Typography>
                            ))}
                            <RoleProtector roles={[Role.Admin]}>
                                <Box mt={1}>
                                    <NewEmployeeForm onSubmit={inviteCompanyAccount} />
                                </Box>
                            </RoleProtector>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}

export default CompanyAccount
