import { ProductCategoryId, ProductCategoryNode, ProductTypeId } from './types'

export const productCategories: ProductCategoryNode = {
    id: 0 as ProductCategoryId,
    name: 'All',
    Icon: 'null',
    subItems: [
        {
            id: 201 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.penguin.menShellPants',
            internalName: 'Männer Shell Hose',
            Icon: 'https://ucarecdn.com/79b3b621-7199-45d2-81da-6aeb99bb96ed/-/preview/46x200/',
            applicableGuides: [
                // Intersport
                169514, 169517, 169519, 169520, 169521, 169522, 169525, 169526, 169527, 169531,
                169940, 170249,
                // Vaude
                69099, 69101, 69102, 69105, 69115, 69137, 69138, 69141, 69150, 69155, 74532, 74517,
            ],
            applicableServices: [
                // Gore-Tex
                3, 6, 9, 11, 14, 16, 17,
            ],
            applicableAlterations: [],
            views: [
                {
                    label: 'serviceDesk.shared.pinpointStep.views.front',
                    sketchUrl: 'https://ucarecdn.com/ec35c8bd-ea66-433a-9626-0488059481e2/',
                    details: [
                        // Top
                        {
                            name: 'Suspender', // left
                            features: ['Elastic', 'Buckles'],
                            x1: 0.345,
                            x2: 0.45,
                            y1: 0,
                            y2: 0.15,
                        },
                        {
                            name: 'Suspender', // right
                            features: ['Elastic', 'Buckles'],
                            x1: 0.56,
                            x2: 0.655,
                            y1: 0,
                            y2: 0.15,
                        },
                        {
                            name: 'Front Zipper',
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.525,
                            x2: 0.59,
                            y1: 0.14,
                            y2: 0.275,
                        },
                        {
                            name: 'Pocket',
                            features: ['Zipper', 'Zipper Slider', 'Key Holder'],
                            x1: 0.325,
                            x2: 0.41,
                            y1: 0.155,
                            y2: 0.245,
                        },
                        {
                            name: 'Connection Salopettes', // left
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.125,
                            x2: 0.35,
                            y1: 0.24,
                            y2: 0.265,
                        },
                        {
                            name: 'Connection Salopettes', // right
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.625,
                            x2: 0.88,
                            y1: 0.24,
                            y2: 0.265,
                        },
                        // pants
                        {
                            name: 'Waist',
                            features: [
                                'Velcro',
                                'Snap Button',
                                'Waist loops',
                                'Zipper',
                                'Zipper Slider',
                            ],
                            x1: 0.125,
                            x2: 0.88,
                            y1: 0.285,
                            y2: 0.345,
                        },
                        {
                            name: 'Fly',
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.47,
                            x2: 0.6,
                            y1: 0.345,
                            y2: 0.46,
                        },
                        {
                            name: 'Leg Pocket', // left
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.09,
                            x2: 0.41,
                            y1: 0.47,
                            y2: 0.5,
                        },
                        {
                            name: 'Leg Pocket', // right
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.59,
                            x2: 0.91,
                            y1: 0.465,
                            y2: 0.495,
                        },
                        {
                            name: 'Leg Adjustment', // left
                            features: ['Zipper', 'Zipper Slider', 'elastic', 'Snap Button'],
                            x1: 0,
                            x2: 0.41,
                            y1: 0.8,
                            y2: 1,
                        },
                        {
                            name: 'Leg Adjustment', // right
                            features: ['Zipper', 'Zipper Slider', 'elastic', 'Snap Button'],
                            x1: 0.6,
                            x2: 1,
                            y1: 0.8,
                            y2: 1,
                        },
                    ],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.back',
                    sketchUrl: 'https://ucarecdn.com/a717c19d-54e5-4427-80c8-e9f2e0c59a60/',
                    details: [
                        // Top
                        {
                            name: 'Suspender',
                            features: ['Elastic', 'Buckles'],
                            x1: 0.2,
                            x2: 0.8,
                            y1: 0,
                            y2: 0.235,
                        },
                        // pants
                        {
                            name: 'Waist',
                            features: ['Waist loops'],
                            x1: 0.14,
                            x2: 0.86,
                            y1: 0.29,
                            y2: 0.36,
                        },
                        {
                            name: 'Ventilation Opening', // left
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.04,
                            x2: 0.22,
                            y1: 0.39,
                            y2: 0.76,
                        },
                        {
                            name: 'Ventilation Opening', // right
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.78,
                            x2: 0.96,
                            y1: 0.39,
                            y2: 0.76,
                        },
                        {
                            name: 'Leg Adjustment', // left
                            features: ['Zipper', 'Zipper Slider', 'elastic', 'Snap Button'],
                            x1: 0,
                            x2: 0.41,
                            y1: 0.8,
                            y2: 1,
                        },
                        {
                            name: 'Leg Adjustment', // right
                            features: ['Zipper', 'Zipper Slider', 'elastic', 'Snap Button'],
                            x1: 0.6,
                            x2: 1,
                            y1: 0.8,
                            y2: 1,
                        },
                    ],
                },
            ],
        },
        {
            id: 202 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.penguin.womenShellPants',
            internalName: 'Frauen Shell Hose',
            Icon: 'https://ucarecdn.com/84cb0160-1ee2-47da-b5ad-8106d2d96b2f/-/preview/46x200/',
            applicableGuides: [
                // Intersport
                169514, 169517, 169519, 169520, 169521, 169522, 169525, 169526, 169527, 169531,
                169940, 170249,
                // Vaude
                69099, 69101, 69102, 69105, 69115, 69137, 69138, 69141, 69150, 69155, 74532, 74517,
            ],
            applicableServices: [
                // Gore-Tex
                3, 6, 9, 11, 14, 16, 17,
            ],
            applicableAlterations: [],
            views: [
                {
                    label: 'serviceDesk.shared.pinpointStep.views.front',
                    sketchUrl: 'https://ucarecdn.com/2cb0c99a-28f9-4400-a5fd-90b14a1a854e/',
                    details: [
                        // Top
                        {
                            name: 'Suspender', // left
                            features: ['Elastic', 'Buckles'],
                            x1: 0.35,
                            x2: 0.45,
                            y1: 0.07,
                            y2: 0.185,
                        },
                        {
                            name: 'Suspender', // right
                            features: ['Elastic', 'Buckles'],
                            x1: 0.54,
                            x2: 0.63,
                            y1: 0.07,
                            y2: 0.185,
                        },
                        {
                            name: 'Front Zipper',
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.51,
                            x2: 0.59,
                            y1: 0.175,
                            y2: 0.31,
                        },
                        {
                            name: 'Pocket',
                            features: ['Zipper', 'Zipper Slider', 'Key Holder'],
                            x1: 0.325,
                            x2: 0.41,
                            y1: 0.19,
                            y2: 0.28,
                        },
                        {
                            name: 'Connection Salopettes', // left
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.15,
                            x2: 0.35,
                            y1: 0.275,
                            y2: 0.3,
                        },
                        {
                            name: 'Connection Salopettes', // right
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.625,
                            x2: 0.83,
                            y1: 0.275,
                            y2: 0.3,
                        },
                        // pants
                        {
                            name: 'Waist',
                            features: [
                                'Velcro',
                                'Snap Button',
                                'Waist loops',
                                'Zipper',
                                'Zipper Slider',
                            ],
                            x1: 0.125,
                            x2: 0.88,
                            y1: 0.305,
                            y2: 0.375,
                        },
                        {
                            name: 'Leg Pocket', // left
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.09,
                            x2: 0.41,
                            y1: 0.45,
                            y2: 0.5,
                        },
                        {
                            name: 'Leg Pocket', // right
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.59,
                            x2: 0.91,
                            y1: 0.45,
                            y2: 0.5,
                        },
                        {
                            name: 'Leg Adjustment', // left
                            features: ['Zipper', 'Zipper Slider', 'elastic', 'Snap Button'],
                            x1: 0.05,
                            x2: 0.44,
                            y1: 0.8,
                            y2: 1,
                        },
                        {
                            name: 'Leg Adjustment', // right
                            features: ['Zipper', 'Zipper Slider', 'elastic', 'Snap Button'],
                            x1: 0.55,
                            x2: 0.945,
                            y1: 0.8,
                            y2: 1,
                        },
                    ],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.back',
                    sketchUrl: 'https://ucarecdn.com/e64444c1-e8fd-4809-9c7a-5a36cfe62df0/',
                    details: [
                        // Top
                        {
                            name: 'Suspender',
                            features: ['Elastic', 'Buckles'],
                            x1: 0.25,
                            x2: 0.75,
                            y1: 0,
                            y2: 0.235,
                        },
                        // pants
                        {
                            name: 'Waist',
                            features: ['Waist loops'],
                            x1: 0.17,
                            x2: 0.83,
                            y1: 0.285,
                            y2: 0.35,
                        },
                        {
                            name: 'Ventilation Opening', // left
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.13,
                            x2: 0.3,
                            y1: 0.365,
                            y2: 0.75,
                        },
                        {
                            name: 'Ventilation Opening', // right
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.7,
                            x2: 0.88,
                            y1: 0.365,
                            y2: 0.75,
                        },
                        {
                            name: 'Leg Adjustment', // left
                            features: ['Zipper', 'Zipper Slider', 'elastic', 'Snap Button'],
                            x1: 0.13,
                            x2: 0.46,
                            y1: 0.8,
                            y2: 1,
                        },
                        {
                            name: 'Leg Adjustment', // right
                            features: ['Zipper', 'Zipper Slider', 'elastic', 'Snap Button'],
                            x1: 0.55,
                            x2: 0.88,
                            y1: 0.8,
                            y2: 1,
                        },
                    ],
                },
            ],
        },
        {
            id: 203 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.penguin.menShellJacket',
            internalName: 'Männer Shell Jacke',
            Icon: 'https://ucarecdn.com/6b382e3e-d31d-4ce8-8ec3-4e1733b8417a/-/preview/158x200/',
            applicableGuides: [
                // Intersport
                169514, 169517, 169519, 169520, 169521, 169522, 169525, 169526, 169527, 169531,
                169940, 170249,
                // Vaude
                69099, 69101, 69102, 69105, 69115, 69137, 69138, 69141, 69150, 69155, 74532, 74517,
            ],
            applicableServices: [
                // Gore-Tex
                1, 2, 4, 5, 7, 8, 10, 12, 13, 15, 18,
            ],
            applicableAlterations: [],
            views: [
                {
                    label: 'serviceDesk.shared.pinpointStep.views.front',
                    sketchUrl: 'https://ucarecdn.com/64142f25-c3b3-4657-a7ff-46fffd71d245/',
                    details: [
                        {
                            name: 'Hood Adjustment', // left
                            features: ['Drawcord', 'Drawcord stopper'],
                            x1: 0.38,
                            x2: 0.45,
                            y1: 0.18,
                            y2: 0.3,
                        },
                        {
                            name: 'Hood Adjustment', // right
                            features: ['Drawcord', 'Drawcord stopper'],
                            x1: 0.54,
                            x2: 0.6,
                            y1: 0.18,
                            y2: 0.3,
                        },
                        {
                            name: 'Neck Zip',
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.55,
                            x2: 0.58,
                            y1: 0.19,
                            y2: 0.37,
                        },
                        {
                            name: 'Zip',
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.48,
                            x2: 0.52,
                            y1: 0.17,
                            y2: 0.89,
                        },
                        {
                            name: 'Pocket Zip', // left
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.34,
                            x2: 0.43,
                            y1: 0.41,
                            y2: 0.72,
                        },
                        {
                            name: 'Pocket Zip', // right
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.57,
                            x2: 0.66,
                            y1: 0.41,
                            y2: 0.72,
                        },
                        {
                            name: 'Waist adjustment',
                            features: [
                                'Drawcord',
                                'Drawcord stopper',
                                'elastic snow stopper',
                                'Snap Button',
                            ],
                            x1: 0.255,
                            x2: 0.74,
                            y1: 0.86,
                            y2: 1,
                        },
                        {
                            name: 'Cuff', // right
                            features: ['Velcro', 'Elastic with thumb hole'],
                            x1: 0,
                            x2: 0.18,
                            y1: 0.88,
                            y2: 0.98,
                        },
                        {
                            name: 'Cuff', // left
                            features: ['Velcro', 'Elastic with thumb hole'],
                            x1: 0.82,
                            x2: 1,
                            y1: 0.88,
                            y2: 0.98,
                        },
                        {
                            name: 'Under arm ventilation', // left
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.16,
                            x2: 0.32,
                            y1: 0.47,
                            y2: 0.76,
                        },
                        {
                            name: 'Under arm ventilation', // right
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.68,
                            x2: 0.82,
                            y1: 0.47,
                            y2: 0.76,
                        },
                        {
                            name: 'Arm pocket', // right
                            features: ['Zipper', 'Zipper Slider', 'Key Holder'],
                            x1: 0.82,
                            x2: 0.87,
                            y1: 0.7,
                            y2: 0.83,
                        },
                    ],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.back',
                    sketchUrl: 'https://ucarecdn.com/e19f25d5-9206-4a4e-8a50-801741e1cf5a/',
                    details: [
                        {
                            name: 'Hood Adjustment',
                            features: ['Drawcord', 'Drawcord stopper'],
                            x1: 0.43,
                            x2: 0.57,
                            y1: 0.06,
                            y2: 0.28,
                        },
                        {
                            name: 'Waist adjustment',
                            features: [
                                'Drawcord',
                                'Drawcord stopper',
                                'elastic snow stopper',
                                'Snap Button',
                            ],
                            x1: 0.255,
                            x2: 0.74,
                            y1: 0.86,
                            y2: 1,
                        },
                        {
                            name: 'Cuff', // right
                            features: ['Velcro', 'Elastic with thumb hole'],
                            x1: 0,
                            x2: 0.18,
                            y1: 0.88,
                            y2: 0.98,
                        },
                        {
                            name: 'Cuff', // left
                            features: ['Velcro', 'Elastic with thumb hole'],
                            x1: 0.82,
                            x2: 1,
                            y1: 0.88,
                            y2: 0.98,
                        },
                        {
                            name: 'Under arm ventilation', // left
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.16,
                            x2: 0.32,
                            y1: 0.47,
                            y2: 0.76,
                        },
                        {
                            name: 'Under arm ventilation', // right
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.68,
                            x2: 0.82,
                            y1: 0.47,
                            y2: 0.76,
                        },
                    ],
                },
            ],
        },
        {
            id: 204 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.penguin.womenShellJacket',
            internalName: 'Frauen Shell Jacke',
            Icon: 'https://ucarecdn.com/aaf25029-fb8c-43a2-a63c-32c23651fdb1/-/preview/131x200/',
            applicableGuides: [
                // Intersport
                169514, 169517, 169519, 169520, 169521, 169522, 169525, 169526, 169527, 169531,
                169940, 170249,
                // Vaude
                69099, 69101, 69102, 69105, 69115, 69137, 69138, 69141, 69150, 69155, 74532, 74517,
            ],
            applicableServices: [
                // Gore-Tex
                1, 2, 4, 5, 7, 8, 10, 12, 13, 15, 18,
            ],
            applicableAlterations: [],
            views: [
                {
                    label: 'serviceDesk.shared.pinpointStep.views.front',
                    sketchUrl: 'https://ucarecdn.com/e2845cf1-7aee-4af6-84a6-14edffda6eda/',
                    details: [
                        {
                            name: 'Hood Adjustment', // left
                            features: ['Drawcord', 'Drawcord stopper'],
                            x1: 0.36,
                            x2: 0.45,
                            y1: 0.2,
                            y2: 0.3,
                        },
                        {
                            name: 'Hood Adjustment', // right
                            features: ['Drawcord', 'Drawcord stopper'],
                            x1: 0.56,
                            x2: 0.64,
                            y1: 0.2,
                            y2: 0.3,
                        },
                        {
                            name: 'Zip',
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.48,
                            x2: 0.525,
                            y1: 0.19,
                            y2: 0.97,
                        },
                        {
                            name: 'Chest Pocket', // left
                            features: ['Zipper', 'Zipper Slider', 'Key Holder'],
                            x1: 0.44,
                            x2: 0.475,
                            y1: 0.36,
                            y2: 0.57,
                        },
                        {
                            name: 'Chest Pocket', // right
                            features: ['Zipper', 'Zipper Slider', 'Key Holder'],
                            x1: 0.53,
                            x2: 0.57,
                            y1: 0.335,
                            y2: 0.6,
                        },
                        {
                            name: 'Pocket Zip', // left
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.26,
                            x2: 0.33,
                            y1: 0.64,
                            y2: 0.87,
                        },
                        {
                            name: 'Pocket Zip', // right
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.67,
                            x2: 0.74,
                            y1: 0.64,
                            y2: 0.87,
                        },
                        {
                            name: 'Waist adjustment',
                            features: [
                                'Drawcord',
                                'Drawcord stopper',
                                'elastic snow stopper',
                                'Snap Button',
                            ],
                            x1: 0.2,
                            x2: 0.8,
                            y1: 0.9,
                            y2: 1,
                        },
                        {
                            name: 'Cuff', // right
                            features: ['Velcro', 'Elastic with thumb hole'],
                            x1: 0.05,
                            x2: 0.2,
                            y1: 0.82,
                            y2: 0.92,
                        },
                        {
                            name: 'Cuff', // left
                            features: ['Velcro', 'Elastic with thumb hole'],
                            x1: 0.8,
                            x2: 0.95,
                            y1: 0.82,
                            y2: 0.92,
                        },
                        {
                            name: 'Under arm ventilation', // left
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.12,
                            x2: 0.32,
                            y1: 0.47,
                            y2: 0.63,
                        },
                        {
                            name: 'Under arm ventilation', // right
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.68,
                            x2: 0.88,
                            y1: 0.47,
                            y2: 0.63,
                        },
                        {
                            name: 'Arm pocket', // right
                            features: ['Zipper', 'Zipper Slider', 'Key Holder'],
                            x1: 0.85,
                            x2: 0.91,
                            y1: 0.66,
                            y2: 0.79,
                        },
                    ],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.back',
                    sketchUrl: 'https://ucarecdn.com/abbb85e4-435d-4170-8e49-3e02224c4d4d/',
                    details: [
                        {
                            name: 'Hood Adjustment',
                            features: ['Drawcord', 'Drawcord stopper'],
                            x1: 0.4,
                            x2: 0.6,
                            y1: 0.12,
                            y2: 0.25,
                        },
                        {
                            name: 'Waist adjustment',
                            features: [
                                'Drawcord',
                                'Drawcord stopper',
                                'elastic snow stopper',
                                'Snap Button',
                            ],
                            x1: 0.2,
                            x2: 0.8,
                            y1: 0.92,
                            y2: 1,
                        },
                        {
                            name: 'Cuff', // right
                            features: ['Velcro', 'Elastic with thumb hole'],
                            x1: 0.04,
                            x2: 0.21,
                            y1: 0.84,
                            y2: 0.92,
                        },
                        {
                            name: 'Cuff', // left
                            features: ['Velcro', 'Elastic with thumb hole'],
                            x1: 0.79,
                            x2: 0.96,
                            y1: 0.84,
                            y2: 0.92,
                        },
                        {
                            name: 'Under arm ventilation', // left
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.12,
                            x2: 0.27,
                            y1: 0.51,
                            y2: 0.65,
                        },
                        {
                            name: 'Under arm ventilation', // right
                            features: ['Zipper', 'Zipper Slider'],
                            x1: 0.73,
                            x2: 0.88,
                            y1: 0.51,
                            y2: 0.65,
                        },
                    ],
                },
            ],
        },
    ],
}
export default productCategories
