import styled from 'styled-components'
import Typography from '@mui/material/Typography'
import createUploadcareImageSrc from '../../mixins/create-uploadcare-image-src'
import InspectorLogo from '../../assets/img/inspector-logo.png'

const ColabContainer = styled.div<{ $scale?: number }>`
    display: flex;
    transform: scale(${({ $scale = 1 }) => $scale});
    align-items: center;
`

const Logo = styled.img`
    width: 96px;
    height: 96px;
`

const CompanyLogo = styled(Logo)`
    object-fit: contain;
    object-position: center right;
`

const ColabLogos = ({ companyLogoUrl, scale = 1 }: { companyLogoUrl: string; scale?: number }) => {
    return (
        <ColabContainer $scale={scale}>
            <CompanyLogo src={createUploadcareImageSrc(companyLogoUrl, { preview: '192x192' })} />
            <Typography variant="h4" component="p" mx={2} color={'primary'}>
                X
            </Typography>
            <Logo src={InspectorLogo} />
        </ColabContainer>
    )
}

export default ColabLogos
