import React from 'react'
import styled, { keyframes } from 'styled-components'
import BrushStroke from '../../../assets/img/brush-stroke.png'
import theme from '../../../theme'

const strike = keyframes`
from {
    clip-path: inset(0 100% 0 0);
}
to {
    clip-path: inset(0 0 0 0);
}
`

const VisualWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${theme.palette.primary.main};

    ${theme.breakpoints.down('sm')} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
`

const Visual = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    width: 75%;
    max-width: 600px;
    font-family: 'Bebas Neue';
`

const Letter = styled.div`
    font-size: 170px;
    width: 33%;
    text-align: center;
    flex-shrink: 0;
    color: #ff00bf;
    position: relative;

    ${theme.breakpoints.down('sm')} {
        font-size: 140px;
    }
`

const Logo = styled.img`
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%) rotate(-20deg);
    clip-path: inset(0 100% 0 0);
    animation: ${strike} 1s ease-in-out 0.3s forwards 1;
`

const ByBrakeable = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 20px;
    letter-spacing: 0.2em;
    color: #e4ff03;
`

const SplashScreen = ({
    duration = 2000,
    onAnimationDone,
}: {
    duration?: number
    onAnimationDone: () => void
}) => {
    React.useEffect(() => {
        setTimeout(() => {
            onAnimationDone()
        }, duration)
    }, [])
    return (
        <VisualWrapper>
            <Visual>
                <Letter>
                    I<Logo src={BrushStroke}></Logo>
                </Letter>
                <Letter>N</Letter>
                <Letter>S</Letter>
                <Letter>P</Letter>
                <Letter>E</Letter>
                <Letter>C</Letter>
                <Letter>T</Letter>
                <Letter>O</Letter>
                <Letter>R</Letter>
                <ByBrakeable>BY BRAKEABLE</ByBrakeable>
            </Visual>
        </VisualWrapper>
    )
}

export default SplashScreen
