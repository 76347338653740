import { useQuery, getCompanySettings } from 'wasp/client/operations'
import CompanyId from '../../../../shared/types/company/id'
import React from 'react'
import CompanySettings from './company-settings'
import LoadingSkeleton from '../../../components/loading/skeleton'

const CompanySettingsWrapper = ({ companyId }: { companyId?: CompanyId }) => {
    const { data: companySettings, isFetching, error } = useQuery(getCompanySettings, { companyId })

    if (isFetching && !companySettings) {
        return <LoadingSkeleton />
    }
    if (error || !companySettings)
        return <div> Es gibt ein Problem mit dem Laden des Firmeneinstellungen! </div>

    return <CompanySettings companySettings={companySettings} />
}

export default CompanySettingsWrapper
