import React from 'react'
import DialogContent from '@mui/material/DialogContent'
import DesktopSubmissionDetailContent from './desktop-submission-detail-content'
import SubmissionDetail from '../../../../../shared/types/product/submission-detail'
import { useIsMobile } from '../../../../mixins/media-query'
import MobileSubmissionDetailContent from './mobile-submission-detail-content'
import SubmissionId from '../../../../../shared/types/submission/id'

const SubmissionDialog = ({
    submission,
    deleteSubmission,
}: {
    submission?: SubmissionDetail
    deleteSubmission?: (submissionId: SubmissionId) => void
}) => {
    const isMobile = useIsMobile()
    return (
        <>
            <DialogContent>
                {!!submission ? (
                    isMobile ? (
                        <MobileSubmissionDetailContent
                            submission={submission!}
                            productImgUrl={submission.productImgUrl!}
                            deleteSubmission={deleteSubmission}
                        />
                    ) : (
                        <DesktopSubmissionDetailContent
                            submission={submission!}
                            deleteSubmission={deleteSubmission}
                            productImgUrl={submission.productImgUrl!}
                        />
                    )
                ) : null}
            </DialogContent>
        </>
    )
}

export default SubmissionDialog
