import React from 'react'
import Typography from '@mui/material/Typography'
import { keyframes } from 'styled-components'
import BrushStroke from '../../../../../assets/img/brush-stroke.png'
import { SupportMultiStepFormContext } from '../context'
import routeBuilder from '../../../../../routes'
import T from '../../../../../components/typography/t'
import styled from '@mui/material/styles/styled'
import { Link } from '@mui/material'
import CompanyLogo from '../../../../../components/colab-logos/single-logo'
import ColabLogos from '../../../../../components/colab-logos'
import logo from '../../../../../components/layout/navigation/logo'
import createUploadcareImageSrc from '../../../../../mixins/create-uploadcare-image-src'

const PageContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`

const Outro = () => {
    const { ctx, props, form } = React.useContext(SupportMultiStepFormContext)

    const onClick = () => {
        location.replace(location.href)
    }

    const logo = ctx.whitelabel?.logo

    return (
        <PageContainer>
            {!!logo ? (
                <CompanyLogo src={createUploadcareImageSrc(logo, { preview: '450x450' })} />
            ) : (
                <ColabLogos companyLogoUrl="https://ucarecdn.com/eb01faa4-cbb2-4fc1-ac03-01abe2fbb6cd/" />
            )}
            <T
                i18nKey="poc.support.feedbackOutroStep.title"
                variant="h4"
                component="h1"
                mt={8}
                mb={1}
                color={'primary'}
            />
            <T
                i18nKey="poc.support.feedbackOutroStep.subtitle"
                variant="body1"
                component="p"
                align="center"
                mb={8}
                mx={2}
            />
            <Link
                onClick={() => {
                    form.setDescription('')
                    form.setPinpoint(undefined)
                    form.setUploadedPhotoUrl(undefined)
                    props.setSlide(1)
                }}
                color={'primary'}
                underline="always"
                mb={1}
            >
                <T i18nKey="poc.support.repairOutroStep.restartAction" variant="body2" />
            </Link>
            <Link onClick={onClick} color={'primary'} underline="always">
                <T i18nKey="poc.support.feedbackOutroStep.action" variant="body2" />
            </Link>
        </PageContainer>
    )
}

export default Outro
