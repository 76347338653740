import { ProductCategoryId, ProductCategoryNode, ProductTypeId } from './types'

export const productCategories: ProductCategoryNode = {
    id: 0 as ProductCategoryId,
    name: 'serviceDesk.shared.productStep.categories.all',
    Icon: 'SneakerIcon',
    subItems: [
        {
            id: 1 as ProductCategoryId,
            name: 'serviceDesk.shared.productStep.categories.bikeBag',
            Icon: 'BikeBagIcon',
            subItems: [
                {
                    id: 112 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.bikeBagBack',
                    internalName: 'bikeBagBack',
                    Icon: 'https://ucarecdn.com/60231793-2f0c-44c4-b9af-05d343876e40/',
                    applicableGuides: [151453, 169517],
                    applicableServices: [3, 5, 6, 8, 9, 14],
                    applicableAlterations: [1, 2, 3, 4, 5, 6, 7, 8, 9],
                    views: [
                        {
                            label: 'serviceDesk.shared.pinpointStep.views.front',
                            sketchUrl: 'https://ucarecdn.com/29e58b72-ee69-4e73-a20e-a880f96ee90f/',
                            details: [
                                {
                                    name: 'Buckle', // Left
                                    features: [],
                                    x1: 0,
                                    x2: 0.05,
                                    y1: 0.27,
                                    y2: 0.405,
                                },
                                {
                                    name: 'Buckle', // Right
                                    features: [],
                                    x1: 0.85,
                                    x2: 0.98,
                                    y1: 0.225,
                                    y2: 0.42,
                                },
                            ],
                        },
                        {
                            label: 'serviceDesk.shared.pinpointStep.views.back',
                            sketchUrl: 'https://ucarecdn.com/74ee7fce-2578-4fbe-ab20-5d8d5c0a24c6/',
                            details: [
                                {
                                    name: 'Quick Lock Schiene',
                                    features: [],
                                    x1: 0.08,
                                    x2: 0.775,
                                    y1: 0.275,
                                    y2: 0.4,
                                },
                            ],
                        },
                        {
                            label: 'serviceDesk.shared.pinpointStep.views.inside',
                            sketchUrl: 'https://ucarecdn.com/ed93770a-f75c-40e5-9209-0003059b3cfa/',
                            details: [],
                        },
                    ],
                },
                {
                    id: 113 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.bikeBagFront',
                    internalName: 'bikeBagFront',
                    Icon: 'https://ucarecdn.com/72aabff0-8737-4bc1-a7bb-5ae2ee14f6aa/',
                    applicableGuides: [],
                    applicableServices: [],
                    applicableAlterations: [],
                    views: [],
                },
                {
                    id: 111 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.saddleBag',
                    internalName: 'saddleBag',
                    Icon: 'https://ucarecdn.com/a0ccb761-c233-463a-a4b7-7307e951c03c/',
                    applicableGuides: [],
                    applicableServices: [],
                    applicableAlterations: [],
                    views: [],
                },
                {
                    id: 121 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.luggageRackBag',
                    internalName: 'luggageRackBag',
                    Icon: 'https://ucarecdn.com/65991fe7-d12c-4eaf-bbbf-5a7d7db02921/',
                    applicableGuides: [],
                    applicableServices: [],
                    applicableAlterations: [],
                    views: [],
                },
                {
                    id: 122 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.handlebarBag',
                    internalName: 'handlebarBag',
                    Icon: 'https://ucarecdn.com/de3cbee9-a46f-4258-b3b7-338e48b54927/',
                    applicableGuides: [],
                    applicableServices: [],
                    applicableAlterations: [],
                    views: [],
                },
                {
                    id: 123 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.frameBag',
                    internalName: 'frameBag',
                    Icon: 'https://ucarecdn.com/9831a9fe-52b4-4a2f-9518-52e00a97f493/',
                    applicableGuides: [],
                    applicableServices: [],
                    applicableAlterations: [],
                    views: [],
                },
            ],
        },
        {
            id: 2 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.travelBag',
            internalName: 'travelBag',
            Icon: 'TravelBagIcon',
            applicableGuides: [],
            applicableServices: [],
            applicableAlterations: [],
            views: [],
        },
        {
            id: 3 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.backpack',
            internalName: 'backpack',
            Icon: 'BackpackIcon',
            applicableGuides: [],
            applicableServices: [],
            applicableAlterations: [],
            views: [],
        },
        {
            id: 4 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.luggageRack',
            internalName: 'luggageRack',
            Icon: 'BikeRackIcon',
            applicableGuides: [],
            applicableServices: [],
            applicableAlterations: [],
            views: [],
        },
    ],
}

export default productCategories
