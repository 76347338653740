import React from 'react'
import { getFollowUpQuestion as getFollowUpQuestionServerAction } from 'wasp/client/operations'
import CategoryStep from './shared-steps/category-selection-step'
import EmailStep from './feedback-steps/email-step'
import FollowUpStep from './feedback-steps/follow-up-step'
import DescriptionStep from './feedback-steps/description-step'
import PhotoStep from './shared-steps/photo-step'
import PinpointStep from './shared-steps/pinpoint-step'
import OutroStep from './feedback-steps/outro'
import { UploadClient } from '@uploadcare/upload-client'
import Coord from '../../../../../shared/types/common/coord'
import {
    FormValues,
    MultiStepFormError,
    SupportMultiStepFormContext,
    defaultContextValues,
} from './context'
import { Whitelabel } from './data/whitelabel'
import defaultRepairGuides from '../../../../../shared/data/repair-guides'
import {
    Page,
    Header,
    WidthContainer,
    BrandLogo,
    Content,
    Footer,
    PBB,
    PoweredBy,
    PoweredByBrakeable,
    ProgressBar,
} from '../../layout'
import { findProductTypeById } from './data/products/helpers'
import { ProductTypeId } from './data/products/types'
import { t } from 'i18next'

let slides: Array<() => JSX.Element> = [
    () => <CategoryStep />,
    () => <PinpointStep />,
    () => <PhotoStep />,
    () => <DescriptionStep />,
    () => <EmailStep />,
    () => <OutroStep />,
]
const initialSlidesLength = [...slides].length

const SupportMultiStepForm = ({
    onSubmit,
    uploadCareClient,
    whitelabel,
    goHome,
}: {
    onSubmit: (formValues: any) => Promise<void>
    uploadCareClient: UploadClient
    whitelabel: Whitelabel
    goHome: () => void
}) => {
    const [meta, setMeta] = React.useState<any>({})
    const [slideIndex, setSlideIndex] = React.useState(0)
    const [errors, setErrors] = React.useState<MultiStepFormError[]>([])
    const formDefaultValues = {
        customerEmail: '',
        selectedProductCategory: undefined,
        selectedProduct: undefined,
        selectedProductView: 0,
        selectedServices: [],
        aiSelectedServices: [],
        aiSelectedRepairGuides: [],
        aiRelatedServices: [],
        description: undefined,
        uploadedPhotoUrl: undefined,
        pinpoint: undefined,
        followUpQuestion: undefined,
        selectedComponent: undefined,
    }
    const [formValues, setFormValues] = React.useState<FormValues>(formDefaultValues)
    const nextSlide = () => setSlideIndex(Math.min(slideIndex + 1, maxSteps - 1))
    const prevSlide = () => {
        if (slideIndex === 0) {
            goHome()
        } else {
            setSlideIndex(Math.max(slideIndex - 1, 0))
        }
    }
    const setSlide = (index: number) => setSlideIndex(index)
    const maxSteps = slides.length
    const gotoEnd = () => setSlideIndex(maxSteps - 1)
    const currentStep = slideIndex

    const setCustomerEmail = (email: string) =>
        setFormValues((v) => ({ ...v, customerEmail: email }))
    const setSelectedProductCategory = (categoryId: number) =>
        setFormValues((v) => ({ ...v, selectedProductCategory: categoryId }))
    const setSelectedServices = (serviceIds: number[]) =>
        setFormValues((v) => ({ ...v, selectedServices: serviceIds }))
    const setAiSelectedServices = (serviceIds: number[]) =>
        setFormValues((v) => ({ ...v, aiSelectedServices: serviceIds }))
    const setAiSelectedRepairGuides = (guideIds: number[]) =>
        setFormValues((v) => ({ ...v, aiSelectedRepairGuides: guideIds }))
    const setAiRelatedServices = (serviceIds: number[]) =>
        setFormValues((v) => ({ ...v, aiRelatedServices: serviceIds }))

    const setSelectedProduct = (productId?: number) =>
        setFormValues((v) => ({ ...v, selectedProduct: productId }))
    const setSelectedProductView = (productViewId: number) =>
        setFormValues((v) => ({ ...v, selectedProductView: productViewId }))
    const setDescription = (description: string) =>
        setFormValues((v) => ({ ...v, description: description }))
    const setUploadedPhotoUrl = (url?: string) =>
        setFormValues((v) => ({ ...v, uploadedPhotoUrl: url }))
    const setPinpoint = (coords?: Coord) => setFormValues((v) => ({ ...v, pinpoint: coords }))
    const setClaimProbability = (probability: number) =>
        setFormValues((v) => ({ ...v, probability: probability }))
    const setAIFollowUpQuestion = (question: string) => {
        setFormValues((v) => ({ ...v, followUpQuestion: question }))
    }
    const setFollowUpAnswer = (answer: string) => {
        setFormValues((v) => ({ ...v, followUpAnswer: answer }))
    }
    const setSelectedComponent = (component?: string) => {
        setFormValues((v) => ({ ...v, selectedComponent: component }))
    }

    const resetForm = () => {
        setFormValues(formDefaultValues)
        setMeta({})
    }

    const submitForm = async () => {
        await onSubmit(formValues)
    }
    const pushError = (error: MultiStepFormError) => {
        setErrors((errors) => [...errors, error])
    }
    const popError = () => {
        setErrors((errors) => errors.slice(0, errors.length - 1))
    }

    const followUpStepIndex = 4

    const addFollowUpStep = () => {
        if (slides.length > initialSlidesLength) {
            return
        }
        slides = [
            ...slides.slice(0, followUpStepIndex),
            () => <FollowUpStep />,
            ...slides.slice(followUpStepIndex),
        ]
    }
    const removeFollowUpStep = () => {
        if (slides.length < initialSlidesLength + 1) {
            return
        }
        slides = [...slides.slice(0, followUpStepIndex), ...slides.slice(followUpStepIndex + 1)]
    }

    const productCategoryTree =
        whitelabel?.productCategoryTree ?? defaultContextValues.ctx.productCategoryTree
    const repairGuides = whitelabel?.repairGuides ?? defaultRepairGuides

    const selectedProductNode = findProductTypeById(
        (formValues.selectedProduct ?? (-1 as any)) as ProductTypeId,
        productCategoryTree
    )

    const getFollowUpQuestion = async () => {
        if (selectedProductNode) {
            const followUpQuestion = await getFollowUpQuestionServerAction({
                problemDescription: formValues.description!,
                product: t(selectedProductNode.name as any),
            })
            if (followUpQuestion === undefined) {
                removeFollowUpStep()
            } else {
                setAIFollowUpQuestion(followUpQuestion)
                addFollowUpStep()
            }
        }
    }

    return (
        <SupportMultiStepFormContext.Provider
            value={{
                ctx: {
                    ...defaultContextValues.ctx,
                    whitelabel,
                    isWhiteLabelled: !!whitelabel,
                    pinpointImage:
                        whitelabel?.pinpointImg ?? defaultContextValues.ctx.pinpointImage,
                    repairGuides: repairGuides,
                    productCategoryTree: productCategoryTree,
                    getFollowUpQuestion: getFollowUpQuestion,
                },
                props: {
                    currentStep,
                    maxSteps,
                    nextSlide,
                    prevSlide,
                    setSlide,
                    gotoEnd,
                },
                form: {
                    setCustomerEmail,
                    setSelectedProductCategory,
                    setSelectedServices,
                    setAiSelectedServices,
                    setAiSelectedRepairGuides,
                    setAiRelatedServices,
                    setSelectedProduct,
                    setSelectedProductView,
                    setDescription,
                    setUploadedPhotoUrl,
                    setPinpoint,
                    setClaimProbability,
                    setAIFollowUpQuestion,
                    setFollowUpAnswer,
                    setSelectedComponent,
                    formValues,
                    submitForm,
                    resetForm,
                },
                meta: {
                    value: meta,
                    setMetaValue: setMeta,
                },
                error: {
                    errors,
                    pushError,
                    popError,
                },
                uploadCareClient,
            }}
        >
            <Page>
                <Header>
                    <WidthContainer sx={{ justifyContent: 'space-between' }}>
                        <BrandLogo src={whitelabel.logo} alt="logo" />
                    </WidthContainer>
                </Header>
                <ProgressBar $progress={currentStep / (maxSteps - 2)} />
                <Content sx={{ mt: 1, mb: 4 }}>{slides[slideIndex]()}</Content>
                <Footer>
                    <WidthContainer sx={{ justifyContent: 'flex-end' }}>
                        <PBB>
                            <PoweredBy>Powered by</PoweredBy>{' '}
                            <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                        </PBB>
                    </WidthContainer>
                </Footer>
            </Page>
        </SupportMultiStepFormContext.Provider>
    )
}

export default SupportMultiStepForm
