import { setupCompanyAccount } from 'wasp/client/operations'
import React, { FormEvent } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import styled from 'styled-components'
import CardTitle from '../../../../components/typography/card-title'
import HeaderBar from '../../../company/inspector/products/components/header-bar'
import T from '../../../../components/typography/t'
import { useTranslation } from 'react-i18next'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

const Form = styled.form`
    display: flex;
    margin-top: 1rem;
`
const InputBox = styled(Box)`
    margin-right: 1rem;
`

const NewCompanyRepresentativeForm = () => {
    const { t } = useTranslation()

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault()

        try {
            const companyName = event.currentTarget.companyName.value
            const employeeName = event.currentTarget.employeeName.value
            const employeeEmail = event.currentTarget.employeeEmail.value
            const employeeLang = event.currentTarget.employeeLang.value
            event.currentTarget.reset()
            await setupCompanyAccount({ companyName, employeeName, employeeEmail, employeeLang })
        } catch (err: any) {
            window.alert('Error: ' + err?.message)
        }
    }

    return (
        <Box>
            <HeaderBar>
                <CardTitle>
                    <T i18nKey="admin.newCompanyRepresentative.title" />
                </CardTitle>
            </HeaderBar>
            <Card>
                <CardContent>
                    <Form onSubmit={handleSubmit}>
                        <InputBox>
                            <TextField
                                name="companyName"
                                type="text"
                                label={t('admin.newCompanyRepresentative.companyNameLabel' as any)}
                                variant="standard"
                            />
                        </InputBox>
                        <InputBox>
                            <TextField
                                name="employeeName"
                                type="text"
                                label={t(
                                    'admin.newCompanyRepresentative.companyRepresentativeNameLabel' as any
                                )}
                                variant="standard"
                            />
                        </InputBox>
                        <InputBox>
                            <TextField
                                name="employeeEmail"
                                type="email"
                                label={t(
                                    'admin.newCompanyRepresentative.companyRepresentativeEmailLabel' as any
                                )}
                                variant="standard"
                            />
                        </InputBox>

                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" variant="standard">
                            <InputLabel id="language-swticher-label">
                                <T i18nKey="common.language" />
                            </InputLabel>
                            <Select
                                labelId="language-swticher-label"
                                id="language-swticher-select"
                                defaultValue="de"
                                label={t('common.language' as any)}
                                autoWidth
                                variant="standard"
                                name="employeeLang"
                            >
                                <MenuItem value="de">Deutsch</MenuItem>
                                <MenuItem value="en">English</MenuItem>
                            </Select>
                        </FormControl>
                        <Button type="submit" variant="contained" sx={{ alignSelf: 'center' }}>
                            <T i18nKey="admin.newCompanyRepresentative.buttonLabel" />
                        </Button>
                    </Form>
                </CardContent>
            </Card>
        </Box>
    )
}

export default NewCompanyRepresentativeForm
