import React from 'react'
import guides from './data/guides'
import {
    Page,
    Header,
    WidthContainer,
    PoweredByBrakeable,
    Content,
    Footer,
    PBB,
    PoweredBy,
} from '../layout'

const GuideList = () => {
    return (
        <Page>
            <Header>
                <WidthContainer sx={{ justifyContent: 'space-between' }}>
                    <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                </WidthContainer>
            </Header>
            <Content>
                <WidthContainer>
                    <ul>
                        {Object.keys(guides).map((guideId) => {
                            const guide = guides[guideId]
                            return (
                                <li key={guideId}>
                                    <a href={`/ufixit/${guideId}`}>{guide.title}</a>
                                </li>
                            )
                        })}
                    </ul>
                </WidthContainer>
            </Content>
            <Footer>
                <WidthContainer sx={{ justifyContent: 'flex-end' }}>
                    <PBB>
                        <PoweredBy>Powered by</PoweredBy>{' '}
                        <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                    </PBB>
                </WidthContainer>
            </Footer>
        </Page>
    )
}

export default GuideList
