import { Role } from '../../../shared/auth/role'
import useAuth from '../../mixins/use-auth'
import AdminDashboard from '../admin/dashboard'
import Inspector from '../company/inspector/inspector'

const RoleSwitch = () => {
    const { user } = useAuth()
    switch (user?.role) {
        case Role.Admin:
            return <AdminDashboard />
        case Role.CompanyRepresentative:
            return <Inspector />
        case Role.Customer:
            return <div> You are a customer! </div>
        default:
            return <div> You are not logged in! </div>
    }
}

const Home = () => {
    return <RoleSwitch />
}
export default Home
