export function getSurroundingElementIds<IdT>(arr: Array<IdT>, id: IdT): Array<IdT> {
    const index = arr.findIndex((element) => element === id)
    const length = arr.length

    if (index === -1) {
        return []
    } else if (length === 1) {
        return arr
    } else if (length === 2) {
        return [arr[index], arr[index === 0 ? 1 : 0]]
    } else if (index === 0) {
        return [arr[index], arr[index + 1], arr[length - 1]]
    } else if (index === length - 1) {
        return [arr[index], arr[0], arr[index - 1]]
    } else {
        return [arr[index], arr[index + 1], arr[index - 1]]
    }
}

export function rotateArrayById<IdT, T extends { id: IdT }>(arr: Array<T>, id: IdT): Array<T> {
    const index = arr.findIndex((element) => element.id === id)
    if (index >= 0) {
        return arr.slice(index).concat(arr.slice(0, index))
    }
    return arr
}
