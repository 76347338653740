import React from 'react'
import InfoStep from './steps/info-step'
import PhotoStep from './steps/photo-step'
import SummaryStep from './steps/summary-step'
import { UploadClient } from '@uploadcare/upload-client'

const slides: Array<() => JSX.Element> = [
    () => <PhotoStep />,
    () => <InfoStep />,
    () => <SummaryStep />,
]

export type StepProps = {
    currentStep: number
    maxSteps: number
    setSlide: (index: number) => void
    nextSlide: () => void
    prevSlide: () => void
    gotoEnd: () => void
}

export type Form = {
    setProductName: (name: string) => void
    setOriginalImageUrl: (url?: string) => void
    setCroppedImageUrl: (url?: string) => void
    setUseCroppedImage: (flag: boolean) => void
    setGender: (gender?: string) => void
    setAge: (age?: number) => void
    setLaminate: (laminate?: string) => void
    setBrand: (brand?: string) => void
    submitForm: () => Promise<void>
    formValues: FormValues
}

export type FormValues = {
    productName?: string
    originalImageUrl?: string
    croppedImageUrl?: string
    useCroppedImage: boolean
    gender?: string
    age?: number
    laminate?: string
    brand?: string
}

export type Meta = {
    value?: any
    setMetaValue: (value: any) => void
}

export type MultiStepFormError = {
    id?: string
    message: string
    action?: {
        label: string
        onClick: () => void
    }
}

export type ErrorCtx = {
    pushError: (error: MultiStepFormError) => void
    popError: () => void
    errors: MultiStepFormError[]
}

export type SetupMultiStepFormContextType = {
    props: StepProps
    form: Form
    meta: Meta
    error: ErrorCtx
    uploadCareClient: UploadClient
}

const defaultContextValues: SetupMultiStepFormContextType = {
    props: {} as StepProps,
    form: {} as Form,
    meta: {} as Meta,
    error: {} as ErrorCtx,
    uploadCareClient: {} as UploadClient,
}

export const SetupMultiStepFormContext =
    React.createContext<SetupMultiStepFormContextType>(defaultContextValues)

const SetupMultiStepForm = ({
    onSubmit,
    uploadCareClient,
}: {
    onSubmit: (formValues: FormValues) => Promise<void>
    uploadCareClient: UploadClient
}) => {
    const [meta, setMeta] = React.useState<any>({})
    const [slideIndex, setSlideIndex] = React.useState(0)
    const [errors, setErrors] = React.useState<MultiStepFormError[]>([])
    const [formValues, setFormValues] = React.useState<FormValues>({
        productName: undefined,
        originalImageUrl: undefined,
        croppedImageUrl: undefined,
        useCroppedImage: false,
        gender: undefined,
        age: undefined,
        laminate: undefined,
        brand: undefined,
    })
    const nextSlide = () => setSlideIndex(Math.min(slideIndex + 1, maxSteps - 1))
    const prevSlide = () => setSlideIndex(Math.max(slideIndex - 1, 0))
    const setSlide = (index: number) => setSlideIndex(index)
    const maxSteps = slides.length
    const gotoEnd = () => setSlideIndex(maxSteps - 1)
    const currentStep = slideIndex

    const setProductName = (name: string) => setFormValues((v) => ({ ...v, productName: name }))
    const setOriginalImageUrl = (url?: string) =>
        setFormValues((v) => ({ ...v, originalImageUrl: url }))
    const setCroppedImageUrl = (url?: string) =>
        setFormValues((v) => ({ ...v, croppedImageUrl: url }))
    const setUseCroppedImage = (flag: boolean) =>
        setFormValues((v) => ({ ...v, useCroppedImage: flag }))
    const setGender = (gender?: string) => setFormValues((v) => ({ ...v, gender: gender }))
    const setAge = (age?: number) => setFormValues((v) => ({ ...v, age: age }))
    const setLaminate = (laminate?: string) => setFormValues((v) => ({ ...v, laminate: laminate }))
    const setBrand = (brand?: string) => setFormValues((v) => ({ ...v, brand: brand }))

    const submitForm = async () => {
        await onSubmit(formValues)
    }
    const pushError = (error: MultiStepFormError) => {
        setErrors((errors) => [...errors, error])
    }
    const popError = () => {
        setErrors((errors) => errors.slice(0, errors.length - 1))
    }

    return (
        <SetupMultiStepFormContext.Provider
            value={{
                props: {
                    currentStep,
                    maxSteps,
                    nextSlide,
                    prevSlide,
                    setSlide,
                    gotoEnd,
                },
                form: {
                    setProductName,
                    setOriginalImageUrl,
                    setCroppedImageUrl,
                    setUseCroppedImage,
                    setGender,
                    setAge,
                    setLaminate,
                    setBrand,
                    submitForm,
                    formValues,
                },
                meta: {
                    value: meta,
                    setMetaValue: setMeta,
                },
                error: {
                    errors,
                    pushError,
                    popError,
                },
                uploadCareClient,
            }}
        >
            {slides[slideIndex]()}
        </SetupMultiStepFormContext.Provider>
    )
}

export default SetupMultiStepForm
