import { trackQuestionairePageViews, trackServiceDeskEvents } from 'wasp/client/operations'
import {
    InspectorPageViewDesktopEvent,
    InspectorPageViewMobileEvent,
    RepairRequestPageViewEvent,
    WarrantyRequestPageViewEvent,
    RepairRequestStepChangeEvent,
    WarrantyRequestStepChangeEvent,
    RepairRequestAiSuggestedGuidesEvent,
    RepairRequestAiSuggestedServicesEvent,
} from '../../shared/analytics/index'
import QuestionaireUuid from '../../shared/types/questionaire/uuid'

type TrackParams<PropsT> = {
    payload: {
        event: string
        userId: string
        anonymousId: string
        properties: PropsT
    }
}

type InspectorPageViewProperties = { questionaireUuid: QuestionaireUuid; source: string }
type POCEventProperties = { isMobile: boolean }

export default function inspectorAnalyticsPlugin() {
    // return object for analytics to use
    return {
        /* All plugins require a name */
        name: 'inspector-analytics-plugin',
        initialize: () => {
            // load provider script to page
        },
        page: () => {},
        track: ({
            payload: event,
        }: TrackParams<InspectorPageViewProperties | POCEventProperties>) => {
            if (
                event.event === InspectorPageViewMobileEvent ||
                event.event === InspectorPageViewDesktopEvent
            ) {
                event.properties as InspectorPageViewProperties
                const userId = parseInt(event.userId)
                trackQuestionairePageViews({
                    event: event.event,
                    anonymousId: event.anonymousId,
                    questionaireUuid: (event.properties as InspectorPageViewProperties)
                        .questionaireUuid,
                    source: (event.properties as InspectorPageViewProperties).source,
                    userId: isNaN(userId) ? undefined : userId,
                })
            }

            if (
                event.event === RepairRequestPageViewEvent ||
                event.event === WarrantyRequestPageViewEvent ||
                event.event === RepairRequestStepChangeEvent ||
                event.event === WarrantyRequestStepChangeEvent ||
                event.event === RepairRequestAiSuggestedGuidesEvent ||
                event.event === RepairRequestAiSuggestedServicesEvent
            ) {
                trackServiceDeskEvents({
                    event: event.event,
                    anonymousId: event.anonymousId,
                    json: JSON.stringify(event.properties),
                })
            }
        },
        identify: () => {},
        loaded: () => {
            // return boolean so analytics knows when it can send data to third party
            return true
        },
    }
}
