import React from 'react'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import { Stack } from '@mui/material'
import T from '../../../../../components/typography/t'
import { WidthContainer } from '../../../../../components/layout'
import LinktreeEntry from '../../../shared/components/linktree-entry'
import type RepairService from '../../../../../../shared/data/repair-services/types'
import useDialog from '../../../../../mixins/use-dialog'
import StoreFinderIFrameDialog from '../../../shared/components/store-finder-iframe-dialog'

const Linktree: React.FC<{
    onDiyClick: () => void
    onServicesClick: () => void
    repairGuideCount: number
    selectedServices: RepairService[]
    storeFinderIframeUrl?: string
    logo: string
}> = ({
    onDiyClick,
    onServicesClick,
    repairGuideCount,
    selectedServices,
    storeFinderIframeUrl,
    logo,
}) => {
    const { isOpen, open, close } = useDialog()

    return (
        <>
            <InspectorStepTitle i18nKey="serviceDesk.repair.solutionStep.linktree.title" mb={4} />
            <WidthContainer>
                <Stack gap={2} sx={{ width: '100%', justifyContent: 'center' }}>
                    {repairGuideCount > 0 && (
                        <LinktreeEntry onClick={onDiyClick}>
                            <T
                                variant="h3"
                                mb={1}
                                i18nKey="serviceDesk.repair.solutionStep.linktree.diyTitle"
                            />
                            <T
                                variant="body1"
                                i18nKey="serviceDesk.repair.solutionStep.linktree.diySubtitle"
                            />
                        </LinktreeEntry>
                    )}
                    <LinktreeEntry onClick={onServicesClick}>
                        {selectedServices.length > 0 ? (
                            <>
                                <T
                                    variant="h3"
                                    mb={1}
                                    i18nKey="serviceDesk.repair.solutionStep.linktree.servicesTitle"
                                />
                                <T
                                    variant="body1"
                                    i18nKey="serviceDesk.repair.solutionStep.linktree.servicesSubtitle"
                                />
                            </>
                        ) : (
                            <>
                                <T
                                    variant="h3"
                                    mb={1}
                                    i18nKey="serviceDesk.repair.solutionStep.linktree.contactFormTitle"
                                />
                                <T
                                    variant="body1"
                                    i18nKey="serviceDesk.repair.solutionStep.linktree.contactFormSubtitle"
                                />
                            </>
                        )}
                    </LinktreeEntry>
                    {storeFinderIframeUrl && (
                        <LinktreeEntry onClick={open}>
                            <T
                                variant="h3"
                                mb={1}
                                i18nKey="serviceDesk.repair.solutionStep.linktree.storeFinderTitle"
                            />
                            <T
                                variant="body1"
                                i18nKey="serviceDesk.repair.solutionStep.linktree.storeFinderSubtitle"
                            />
                        </LinktreeEntry>
                    )}
                </Stack>
            </WidthContainer>
            <StoreFinderIFrameDialog
                storeFinderIframeUrl={storeFinderIframeUrl!}
                isOpen={isOpen}
                close={close}
                logo={logo}
            />
        </>
    )
}

export default Linktree
