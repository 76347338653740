import { useQuery, getUnverifiedUsers } from 'wasp/client/operations'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import CopyToClipboard from '../../../../components/copy-to-clipboard'
import LoadingSkeleton from '../../../../components/loading/skeleton'
import CardTitle from '../../../../components/typography/card-title'
import HeaderBar from '../../../company/inspector/products/components/header-bar'
import T from '../../../../components/typography/t'
import { useTranslation } from 'react-i18next'

const UnverifiedUsers = () => {
    const { data: unverifiedUsers, isFetching, error } = useQuery(getUnverifiedUsers)
    const { t } = useTranslation()

    if (isFetching) return <LoadingSkeleton />
    if (error) return <div> Error! </div>
    if (unverifiedUsers?.length === 0) return null
    return (
        <Box>
            <HeaderBar>
                <CardTitle>
                    <T i18nKey="admin.unverifiedUsers.title" />
                </CardTitle>
            </HeaderBar>
            <Card sx={{ overflowX: 'auto' }}>
                <CardContent>
                    <Table size="small" aria-label="product table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <T i18nKey="admin.unverifiedUsers.table.company" />
                                </TableCell>
                                <TableCell>
                                    <T i18nKey="admin.unverifiedUsers.table.representative" />
                                </TableCell>
                                <TableCell>
                                    <T i18nKey="admin.unverifiedUsers.table.email" />
                                </TableCell>
                                <TableCell>
                                    <T i18nKey="admin.unverifiedUsers.table.link" />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {unverifiedUsers &&
                                unverifiedUsers.map((user) => (
                                    <TableRow
                                        key={user.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {user.companyName}
                                        </TableCell>
                                        <TableCell>{user.userName}</TableCell>
                                        <TableCell>{user.userEmail}</TableCell>
                                        <TableCell>
                                            <CopyToClipboard
                                                label={t('admin.unverifiedUsers.table.link' as any)}
                                                copiedLabel={t('common.copied' as any)}
                                                link={user.verificationLink}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </Box>
    )
}

export default UnverifiedUsers
