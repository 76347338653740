const environment = (import.meta as any).env.REACT_APP_ENV

export enum Env {
    PROD = 'prod',
    TEST = 'test',
    LOCAL = 'local',
}

const useEnv = (): {
    environment: Env
    isProd: boolean
    isTest: boolean
    isLocal: boolean
} => {
    return {
        environment,
        isProd: environment === Env.PROD,
        isTest: environment === Env.TEST,
        isLocal: environment === Env.LOCAL,
    }
}

export default useEnv
