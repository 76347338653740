import { onboardCompanyRepresentative } from 'wasp/client/operations'
import { verifyEmail, resetPassword } from 'wasp/client/auth'
import { useForm, SubmitHandler } from 'react-hook-form'
import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { login } from 'wasp/client/auth'
import styled from 'styled-components'
import PasswordRequirements from './password-requirements'
import * as PasswordValidation from '../../../../shared/password/validate-secure-password'
import StyledErrorAlert from '../../../components/alert/styled-error-alert'
import routeBuilder from '../../../routes'
import T from '../../../components/typography/t'
import { useTranslation } from 'react-i18next'

type Inputs = {
    email: string
    password: string
    token: number
}

const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`
const TextFieldWrapper = styled.div`
    margin-bottom: 1rem;
    align-self: stretch;
`

const StyledTextField = styled(TextField)`
    width: 100%;
`

const OnboardingForm = ({ token }: { token: string }) => {
    const [t] = useTranslation()
    const [error, setError] = React.useState<string | null>(null)
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors, isValid },
    } = useForm<Inputs>()

    const submit: SubmitHandler<Inputs> = async (data) => {
        setError(null)
        const { email, password } = data
        const strippedEmail = email.trim()
        try {
            await verifyEmail({ token })
            await resetPassword({ token, password })
            await onboardCompanyRepresentative({ email: strippedEmail, token })
            reset()
            await login({ email: strippedEmail, password })
            window.location.href = routeBuilder.companyIntroduction()
        } catch (error: any) {
            setError(error.message)
        }
    }

    const watchPassword = watch('password')

    return (
        <Form method="post" onSubmit={handleSubmit(submit)}>
            <TextFieldWrapper>
                <StyledTextField
                    variant="outlined"
                    label={<T i18nKey="company.onboarding.form.email.label" />}
                    placeholder={t('company.onboarding.form.email.placeholder' as any)}
                    type="email"
                    {...register('email', { required: true })}
                    helperText={<T i18nKey="company.onboarding.form.email.helperText" />}
                />
            </TextFieldWrapper>
            <TextFieldWrapper>
                <StyledTextField
                    variant="outlined"
                    label={<T i18nKey="company.onboarding.form.password.label" />}
                    type="password"
                    placeholder={t('company.onboarding.form.password.placeholder' as any)}
                    {...register('password', { required: true })}
                />
            </TextFieldWrapper>
            <PasswordRequirements password={watchPassword ?? ''} />
            <input type="hidden" name="token" value={token} />
            <Button
                variant="contained"
                type="submit"
                disabled={!!PasswordValidation.validateSecurePassword(watchPassword ?? '')}
            >
                <T i18nKey="company.onboarding.form.action" />
            </Button>
            {!!error && (
                <Box mt={2}>
                    <StyledErrorAlert severity="error">{error}</StyledErrorAlert>
                </Box>
            )}
        </Form>
    )
}

export default OnboardingForm
