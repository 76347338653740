import AlterationService from './types'

const ortliebAlterationServices: AlterationService[] = [
    {
        id: 1,
        title: 'Quick-Lock1',
        description: 'Umrüsten',
        imgUrl: 'https://ucarecdn.com/3044757e-f2cd-4584-ab00-f6a79c89bb7d/',
        translations: {
            title: {
                de: 'Quick-Lock1',
            },
            description: {
                de: 'Umrüsten',
            },
        },
        price: [23],
    },
    {
        id: 2,
        title: 'Quick-Lock2',
        description: 'Nachrüsten',
        imgUrl: 'https://ucarecdn.com/f4490c8b-5b4b-4a11-9bbc-03e1d2dd3c9e/',
        translations: {
            title: {
                de: 'Quick-Lock2',
            },
            description: {
                de: 'Nachrüsten',
            },
        },
        price: [18],
    },
    {
        id: 3,
        title: 'Nachrüsten der Halbellipse',
        description: 'inkl. Haken',
        imgUrl: 'https://ucarecdn.com/867282f7-5e6f-4435-ac61-0e65c0519b69/',
        translations: {
            title: {
                de: 'Nachrüsten der Halbellipse',
            },
            description: {
                de: 'inkl. Haken',
            },
        },
        price: [16],
    },
    {
        id: 4,
        title: 'Nachrüsten einer Vollellipse',
        description: 'inkl. Haken',
        imgUrl: 'https://ucarecdn.com/29c5da37-bde3-44cc-a756-11485727f405/',
        translations: {
            title: {
                de: 'Nachrüsten einer Vollellipse',
            },
            description: {
                de: 'inkl. Haken',
            },
        },
        price: [17],
    },
    {
        id: 5,
        title: 'Nachrüstung der Haltevorrichtung',
        description: 'für Schultergurt bei Sport- und Bike-Packer Modellen vor 2008',
        imgUrl: 'https://ucarecdn.com/0cc462e4-b7f7-4f07-9d99-9ad37429bdf4/',
        translations: {
            title: {
                de: 'Nachrüstung der Haltevorrichtung',
            },
            description: {
                de: 'für Schultergurt bei Sport- und Bike-Packer Modellen vor 2008',
            },
        },
        price: [20],
    },
    {
        id: 6,
        title: 'Mittlerer Gurtband-Steckverschluss',
        description: 'Nachrüsten',
        imgUrl: 'https://ucarecdn.com/e8984a79-f2df-46cc-b63f-93553db02bfb/',
        translations: {
            title: {
                de: 'Mittlerer Gurtband-Steckverschluss',
            },
            description: {
                de: 'Nachrüsten',
            },
        },
        price: [20],
    },
    {
        id: 7,
        title: 'Kantenschutz',
        description: 'bei Office-Bag, Velocity und Kuriertasche',
        imgUrl: 'https://ucarecdn.com/c19d31d7-a8f9-4a3d-8829-5ab70d74061d/',
        translations: {
            title: {
                de: 'Kantenschutz',
            },
            description: {
                de: 'bei Office-Bag, Velocity und Kuriertasche',
            },
        },
        price: [20],
    },
    {
        id: 8,
        title: 'Kantenschutz',
        description: 'für ältere, symmetrische Back-Roller, Bike-Packer, etc.',
        imgUrl: 'https://ucarecdn.com/c19d31d7-a8f9-4a3d-8829-5ab70d74061d/',
        translations: {
            title: {
                de: 'Kantenschutz',
            },
            description: {
                de: 'für ältere, symmetrische Back-Roller, Bike-Packer, etc.',
            },
        },
        price: [20],
    },
    {
        id: 9,
        title: 'Außentasche',
        description: 'Nachrüsten',
        imgUrl: 'https://ucarecdn.com/50089512-d0f5-4bb2-a537-e38ba0fbb793/',
        translations: {
            title: {
                de: 'Außentasche',
            },
            description: {
                de: 'Nachrüsten',
            },
        },
        price: [12],
    },
]

export default ortliebAlterationServices
