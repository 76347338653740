import Alert from '@mui/material/Alert'
import { OverridableComponent, OverridableTypeMap } from '@mui/material/OverridableComponent'
import styled from 'styled-components'
import theme from '../../theme'

const StyledErrorAlert: OverridableComponent<OverridableTypeMap> = styled(Alert)`
    border: 1px solid ${theme.palette.error.dark}};
`

export default StyledErrorAlert
