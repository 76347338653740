import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import styled from 'styled-components'
import { KeyFacts as KeyFactsT } from '../../../../../shared/types/product/product-dashboard'
import theme from '../../../../theme'
import T from '../../../../components/typography/t'

const KeyFactContainer = styled.div`
    margin: 20px 0;
    width: 100%;
    display: flex;
    flex-diretion: row;
    justify-content: space-between;
`

const KeyFactCard = styled(Card)`
    width: calc(25% - 20px);
`

const KeyFactContent = styled(CardContent)`
    text-align: center;
`

const KeyFactNumber = styled.p`
    color: ${theme.palette.primary.main};
    font-size: 3rem;
    font-weight: bold;
    font-family: 'Bebas Neue';
    margin: 0;
`

const KeyFactLabel = styled(T)`
    color: ${theme.palette.grey[400]};
    font-size: 1rem;
    font-weight: bold;
    margin: 5px 0 0;
`

const KeyFacts = ({ keyFacts }: { keyFacts: KeyFactsT }) => (
    <KeyFactContainer>
        <KeyFactCard>
            <KeyFactContent>
                <KeyFactNumber>{keyFacts.pageViews}</KeyFactNumber>
                <KeyFactLabel i18nKey="company.inspector.productDashboard.keyFacts.pageViews" />
            </KeyFactContent>
        </KeyFactCard>
        <KeyFactCard>
            <KeyFactContent>
                <KeyFactNumber>{keyFacts.inspectionCount}</KeyFactNumber>
                <KeyFactLabel i18nKey="company.inspector.productDashboard.keyFacts.problems" />
            </KeyFactContent>
        </KeyFactCard>
        <KeyFactCard>
            <KeyFactContent>
                <KeyFactNumber>{keyFacts.happySubmissions}</KeyFactNumber>
                <KeyFactLabel i18nKey="company.inspector.productDashboard.keyFacts.happySubmissionFeedback" />
            </KeyFactContent>
        </KeyFactCard>
        <KeyFactCard>
            <KeyFactContent>
                <KeyFactNumber>
                    {keyFacts.pageViews === 0 ? 0 : keyFacts.conversion} %
                </KeyFactNumber>
                <KeyFactLabel i18nKey="company.inspector.productDashboard.keyFacts.conversion" />
            </KeyFactContent>
        </KeyFactCard>
    </KeyFactContainer>
)

export default KeyFacts
