import React from 'react'
import { InspectorMultiStepFormContext } from '../../inspector-multi-step-form'
import styled from 'styled-components'
import theme from '../../../../../theme'
import T, { I18nKey } from '../../../../../components/typography/t'
import { StepProps } from '../../types'
import BrushStroke from '../../../../../assets/img/brush-stroke.svg'

const MultiStepFormNav = styled.div`
    width: 100%;
    height: 80px;
    background-color: ${theme.palette.primary.main};
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const NavLink = styled.button<{ $disabled: boolean }>`
    width: 33.33%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ $disabled }) => ($disabled ? '#6c6271' : '#ffffff')};
    text-decoration: none;
    font-size: 1.2em;
    outline: none;
    border: none;
    background: transparent;
`

const PrimaryNavLink = styled(NavLink)<{ $disabled: boolean }>`
    color: ${({ $disabled }) => ($disabled ? '#6c6271' : '#000000')};
    background: ${({ $disabled }) => ($disabled ? 'transparent' : `url(${BrushStroke})`)};
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
`

const NavProgress = styled.div`
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    height: auto;
    padding: 0.5em 0.5em;
    border-radius: 2px;
    font-size: 0.8em;
`

export type MultiStepNavigationProps = {
    prevButtonDisabled: boolean
    prevButtonLabelKey: I18nKey
    nextButtonDisabled: boolean
    nextButtonLabelKey: I18nKey
    onValidNextClick?: () => Promise<void>
}

const noop = async () => {}

export const DumbMultiStepNavigation = ({
    prevSlide,
    currentStep,
    maxSteps,
    nextSlide,
    prevButtonDisabled,
    prevButtonLabelKey,
    nextButtonDisabled,
    nextButtonLabelKey,
    onValidNextClick = noop,
}: StepProps & MultiStepNavigationProps) => {
    return (
        <MultiStepFormNav>
            <NavLink
                $disabled={prevButtonDisabled}
                disabled={prevButtonDisabled}
                onClick={prevButtonDisabled ? noop : prevSlide}
                data-testid="back-button"
            >
                <T i18nKey={prevButtonLabelKey} />
            </NavLink>
            <NavProgress>
                {currentStep} <T i18nKey="inspector.mobile.navigation.from" /> {maxSteps - 2}
            </NavProgress>
            <PrimaryNavLink
                data-testid="next-button"
                $disabled={nextButtonDisabled}
                disabled={nextButtonDisabled}
                onClick={
                    nextButtonDisabled
                        ? noop
                        : async () => {
                              await onValidNextClick()

                              nextSlide()
                          }
                }
            >
                <T i18nKey={nextButtonLabelKey} />
            </PrimaryNavLink>
        </MultiStepFormNav>
    )
}

const MultiStepNavigation = ({
    prevButtonDisabled = false,
    prevButtonLabelKey = 'common.back',
    nextButtonDisabled = false,
    nextButtonLabelKey = 'common.next',
    onValidNextClick = noop,
}: Partial<MultiStepNavigationProps>) => {
    const { props } = React.useContext(InspectorMultiStepFormContext)
    return (
        <DumbMultiStepNavigation
            {...props}
            prevButtonDisabled={prevButtonDisabled}
            prevButtonLabelKey={prevButtonLabelKey}
            nextButtonDisabled={nextButtonDisabled}
            nextButtonLabelKey={nextButtonLabelKey}
            onValidNextClick={onValidNextClick}
        />
    )
}

export default MultiStepNavigation
