import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import NoSuggestionsContactForm from './no-suggestions-contact-form'
import { type ServiceDeskContactForm } from '../../../../../../shared/data/whitelabel/types'
import FormData from '../../../../../../shared/types/common/form-data'
const NoEstimationScreen: React.FC<{
    onSubmit: (data: FormData) => void
    onAddMoreDefectsClick: () => void
    contactForm: ServiceDeskContactForm
    useForm: UseFormReturn<FormData>
    showDiySolutions?: () => void
}> = ({ onSubmit, onAddMoreDefectsClick, contactForm, useForm, showDiySolutions }) => {
    return (
        <>
            <InspectorStepTitle i18nKey="serviceDesk.repair.contactStep.title" mb={4} />
            <NoSuggestionsContactForm
                onSubmit={onSubmit}
                onAddMoreDefectsClick={onAddMoreDefectsClick}
                contactForm={contactForm}
                useForm={useForm}
                onPreferDiySolution={showDiySolutions}
            />
        </>
    )
}

export default NoEstimationScreen
