import React from 'react'
import Typo from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Divider from '@mui/material/Divider'
import styled from 'styled-components'
import HighlightedSubmissionId from '../../../../../../shared/types/submission/highlighted-submissions-id'
import Submission from '../../../../../../shared/types/product/dashboard-submission'
import createUploadcareImageSrc from '../../../../../mixins/create-uploadcare-image-src'
import BrushStroke from '../../../../../assets/img/brush-stroke.svg'
import SubmissionId from '../../../../../../shared/types/submission/id'
import Badge from '../../../../../components/badge/badge'

const ListEntryImagePreview = styled.img`
    width: 64px;
    height: 64px;
    object-fit: contain;
`

const StyledListItemButton = styled(ListItemButton)<{ $selected: boolean }>`
    position: relative;
    z-index: 1;
    background-color: inherit !important;

    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 50%;
        left: 0;
        width: 75%;
        height: 42px;
        ${({ $selected }) => $selected && `background-image: url(${BrushStroke})`};
        background-size: cover;
        background-position: center;
        z-index: -1;
        opacity: 0.6;
        transform: translateY(50%) rotate(-5deg);
    }
`

const DescriptionBadgeWrapper = styled.div`
    display: flex;
    align-items: center;
`

const SubmissionList = ({
    submissions,
    highlightedSubmissionId,
    highlightSubmission,
    openSubmission,
}: {
    submissions: Array<Submission>
    highlightedSubmissionId?: HighlightedSubmissionId
    highlightSubmission: (arg?: HighlightedSubmissionId) => void
    openSubmission: (submissionId: SubmissionId) => void
}) => {
    const refs = React.useRef<any>(submissions.map(() => React.createRef()))
    const scrollToItem = (index: number) => {
        refs.current[index].current?.scrollIntoView({ behavior: 'smooth' })
    }
    React.useEffect(() => {
        if (!highlightedSubmissionId || highlightedSubmissionId.source === 'list') return

        const index = submissions.indexOf(
            submissions.find((s) => s.id === highlightedSubmissionId.id)!
        )
        if (index !== -1) {
            scrollToItem(index)
        }
    }, [highlightedSubmissionId])

    return (
        <List>
            {submissions.map((submission, index) => (
                <React.Fragment key={submission.id}>
                    {index > 0 && <Divider component="li" />}
                    <ListItem ref={refs.current[index]}>
                        <StyledListItemButton
                            selected={highlightedSubmissionId?.id === submission.id}
                            $selected={highlightedSubmissionId?.id === submission.id}
                            onMouseEnter={() =>
                                highlightSubmission({ source: 'list', id: submission.id })
                            }
                            onMouseLeave={() => highlightSubmission(undefined)}
                            onClick={() => openSubmission(submission.id)}
                        >
                            <ListItemText
                                primary={
                                    <DescriptionBadgeWrapper>
                                        {submission.unseenByUser && (
                                            <Badge color="error" variant="dot"></Badge>
                                        )}
                                        <Typo fontWeight={submission.unseenByUser ? 800 : 400}>
                                            {submission.description}
                                        </Typo>
                                    </DescriptionBadgeWrapper>
                                }
                                secondary={submission.createdAt}
                            />

                            <ListItemAvatar>
                                <ListEntryImagePreview
                                    src={createUploadcareImageSrc(submission.image, {
                                        preview: '128x128',
                                    })}
                                />
                            </ListItemAvatar>
                        </StyledListItemButton>
                    </ListItem>
                </React.Fragment>
            ))}
        </List>
    )
}

export default SubmissionList
