import React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { UserInspectionsViewModelEntry } from '../../../../../shared/types/user-management/inspections-by-email'
import Collapse from '@mui/material/Collapse'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import ProductInspectionsByEmail from './product-inspections-by-email'
import useUrlQuery from '../../../../mixins/use-url-query'
import SubmissionId from '../../../../../shared/types/submission/id'
import { useTranslation } from 'react-i18next'

const InspectionByEmailRow: React.FC<{
    entry: UserInspectionsViewModelEntry
    markSubmissionAsSeenByUser: ({ submissionId }: { submissionId: SubmissionId }) => void
}> = ({ entry, markSubmissionAsSeenByUser }) => {
    const { t } = useTranslation()
    const [openAccordionEntryFromUrl, setccordionEntryInUrl] = useUrlQuery('user')
    const [openAccordionEntry, setOpenAccordionEntry] = React.useState(
        openAccordionEntryFromUrl() === entry.user.email ? true : false
    )

    const toggleAccordion = () => {
        setOpenAccordionEntry(!openAccordionEntry)
        setccordionEntryInUrl(openAccordionEntry ? undefined : entry.user.email)
    }

    const inspectionCount = entry.products.flatMap((product) => product.submissions).length
    const uniqueProductCount = entry.products.length
    const hasSubmissions = entry.products.flatMap((product) => product.submissions).length > 0

    const labelsWithCount = entry.products
        .flatMap((product) => product.submissions)
        .flatMap((submission) => submission.labels)
        .reduce((acc: { value: string; count: number }[], label) => {
            const existingLabel = acc.find((l) => l.value === label.value)
            if (existingLabel) {
                existingLabel.count++
            } else {
                acc.push({ value: label.value, count: 1 })
            }
            return acc
        }, [])
        .filter((label) => label.count > 1)
        .sort((a, b) => b.count - a.count)
    return (
        <>
            <ListItemButton onClick={toggleAccordion}>
                <ListItemText
                    primary={entry.user.email}
                    secondary={t('company.userManagement.users.userList.subtitle' as any, {
                        productCount: uniqueProductCount,
                        inspectionCount,
                    })}
                />
                <Stack
                    spacing={1}
                    direction="row"
                    mr={1}
                    sx={{ display: { xs: 'none', sm: 'block' } }}
                >
                    {labelsWithCount.map((label) => (
                        <Chip label={`${label.value} (${label.count})`} key={label.value} />
                    ))}
                </Stack>
                {openAccordionEntry ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </ListItemButton>
            {hasSubmissions && (
                <Collapse
                    in={openAccordionEntry}
                    timeout="auto"
                    unmountOnExit
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                    }}
                >
                    <Stack spacing={1} mt={1}>
                        {entry.products.map((product) => (
                            <ProductInspectionsByEmail
                                key={product.id}
                                product={product}
                                markSubmissionAsSeenByUser={markSubmissionAsSeenByUser}
                            />
                        ))}
                    </Stack>
                </Collapse>
            )}
        </>
    )
}

export default InspectionByEmailRow
