export enum DifficultyEnum {
    veryEasy = 'Very easy',
    easy = 'Easy',
    moderate = 'Moderate',
    difficult = 'Difficult',
}

export type Difficulty = 'Very easy' | 'Easy' | 'Moderate' | 'Difficult'

export interface IfixitGuide {
    brandId: string
    conclusion_raw: string
    conclusion_rendered: string
    difficulty: Difficulty
    documents: any[]
    flags?: FlagsItem[]
    guideid: number
    image: IfixitImage
    introduction_raw: string
    introduction_rendered: string
    featured_document_embed_url?: string
    featured_document_thumbnail_url?: string
    locale: string
    parts: any[]
    prerequisites: any[]
    steps: StepsItem[]
    subject: string
    summary: string
    time_required: string
    time_required_min: number
    time_required_max: number
    title: string
    tools: ToolsItem[]
    type: string
    revisionid?: number
    created_date?: number
    published_date?: number
    modified_date?: number
    prereq_modified_date?: number
    public?: boolean
    comments?: CommentsItem[]
    category: string
    url: string
    patrol_threshold?: number
    can_edit?: boolean
    favorited?: boolean
    author?: Author
    intro_video_url: null
    intro_video: null
    completed?: boolean
    langid?: string
    featured_documentid?: string
}
interface FlagsItem {
    title: string
    flagid: string
    text: string
}
export interface IfixitImage {
    id?: number
    guid?: string
    mini?: string
    thumbnail?: string
    '140x105'?: string
    '200x150'?: string
    standard?: string
    '440x330'?: string
    medium?: string
    large?: string
    huge?: string
    original?: string
    image?: IfixitImage
    srcid?: null
    width?: number
    height?: number
    ratio?: string
    markup?: null
    exif?: any[]
}
export interface StepsItem {
    title?: string
    lines: LinesItem[]
    guideid: number
    stepid: number
    orderby: number
    revisionid: number
    media: Media
    comments: any[]
}
interface LinesItem {
    text_raw: string
    bullet: string
    level: number
    lineid: null
    text_rendered: string
}
interface Media {
    type: string
    data: MediaDataType
}
type MediaDataType = IfixitImage[] | VideoData

export const mediaDataIsImage = (mediaData: MediaDataType): mediaData is IfixitImage[] => {
    return (<VideoData>mediaData).videoid === undefined
}
export const mediaDataIsVideo = (mediaData: MediaDataType): mediaData is VideoData[] => {
    return (<VideoData>mediaData).videoid !== undefined
}

interface DataItem {
    id: number
    guid: string
    mini: string
    thumbnail: string
    '140x105': string
    '200x150': string
    standard: string
    '440x330': string
    medium: string
    large: string
    huge: string
    original: string
}
export interface VideoData {
    objectid: number
    videoid: number
    srcid: null
    image: IfixitImage
    filename: string
    duration: number
    width: number
    height: number
    meta: Meta
    encodings: EncodingsItem[]
}
interface Meta {
    id: number
    format: string
    frame_rate: number
    duration_in_ms: number
    audio_sample_rate: number
    audio_bitrate_in_kbps: number
    audio_codec: string
    height: number
    width: number
    file_size_in_bytes: number
    video_codec: string
    total_bitrate_in_kbps: number
    channels: string
    video_bitrate_in_kbps: number
    state: string
    md5_checksum: null
}
interface EncodingsItem {
    column: string
    label: string
    width: number
    height: number
    codecs: string
    ffmpeg_args: string
    mime: string
    always_generate: boolean
    url: string
    format: string
}
interface ToolsItem {
    type: string
    quantity: number
    text: string
    notes: null
    url: string
    thumbnail: string | null
    isoptional: boolean
}
interface CommentsItem {
    commentid: number
    locale?: string
    context: string
    contextid: number
    parentid: null
    author: Author
    title: string
    text_raw: string
    text_rendered: string
    rating: number
    date: number
    modified_date: number
    status: string
    replies: any[]
}
interface Author {
    userid: number
    username: string
    unique_username: string
    join_date?: number
    image: IfixitImage
    reputation: number
    url: string
    teams: number[]
}
