import React from 'react'
import styled from 'styled-components'
import Typo from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import Submission from '../../../../../../../shared/types/product/dashboard-submission'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import TravelExploreIcon from '@mui/icons-material/TravelExplore'
import ImageMarker from '../../../../../../components/image-marker'
import createUploadcareImageSrc from '../../../../../../mixins/create-uploadcare-image-src'
import SubmissionId from '../../../../../../../shared/types/submission/id'
import { FilterLabel } from '../../../../../../../shared/types/submission/label'
import { CountedOriginFilter } from '../../../../../../../shared/types/submission/origin-filter'
import DesktopInspectionImageGrid from './desktop-inspection-image-grid'
import SubmissionHighlighter from '../../../../common/submission-highlighter'
import T from '../../../../../../components/typography/t'

const SubmissionViewer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 800px;
`

const LeftSideWrapper = styled(Stack)`
    max-width: 400px;
`

const SubmissionListWrapper = styled.div`
    overflow-y: auto;
    flex-grow: 1;
    border-left: 1px solid #e0e0e0;
    padding-left: 25px;
    margin-left: 25px;
`

const ProductImage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 400px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 25px;
`

const DesktopDashboard = ({
    productImgUrl,
    submissions,
    openSubmission,
    filterLabels,
    filterOrigins,
    setLabelFilter,
    setOriginFilter,
}: {
    productImgUrl: string
    submissions: Array<Submission>
    openSubmission: (submissionId: SubmissionId) => void
    filterLabels: FilterLabel[]
    filterOrigins: CountedOriginFilter[]
    setLabelFilter: (label: FilterLabel) => void
    setOriginFilter: (origin: CountedOriginFilter) => void
}) => {
    return (
        <SubmissionHighlighter entities={submissions}>
            {({ refs, highlightedSubmissionId, setHighlightedSubmissionId }) => (
                <SubmissionViewer>
                    <LeftSideWrapper>
                        <ProductImage>
                            <ImageMarker
                                imageUrl={createUploadcareImageSrc(productImgUrl, {
                                    preview: '800x800',
                                })}
                                highlightedSubmissionId={highlightedSubmissionId}
                                highlightSubmission={setHighlightedSubmissionId}
                                openSubmission={openSubmission}
                                markers={submissions.map((sub) => ({
                                    id: sub.id,
                                    x: sub.imagePinpoint.x,
                                    y: sub.imagePinpoint.y,
                                }))}
                            />
                        </ProductImage>
                        <Stack>
                            <Typo variant="h5" component="h3" mt={2}>
                                <AutoAwesomeIcon fontSize="small" sx={{ mr: 1 }} />
                                <T i18nKey="company.inspector.productDashboard.aiFilter" />
                            </Typo>
                            <Stack direction="row" spacing={1} my={1} useFlexGap flexWrap="wrap">
                                {[...filterLabels].slice(0, 5).map((label) => (
                                    <Chip
                                        key={label.id}
                                        label={`${label.value} (${
                                            label.selectedCount ?? label.count
                                        })`}
                                        color={label.isSelected ? 'primary' : 'default'}
                                        disabled={label.selectedCount === 0 || label.count === 0}
                                        onClick={() => {
                                            setLabelFilter(label)
                                        }}
                                    />
                                ))}
                            </Stack>
                            <Typo variant="h5" component="h3" mt={2}>
                                <TravelExploreIcon fontSize="small" sx={{ mr: 1 }} />
                                <T i18nKey="company.inspector.productDashboard.originFilter" />
                            </Typo>
                            <Stack direction="row" spacing={1} my={1} useFlexGap flexWrap="wrap">
                                {filterOrigins.map((origin) => (
                                    <Chip
                                        key={origin.id}
                                        label={`${origin.value} (${
                                            origin.selectedCount ?? origin.count
                                        })`}
                                        color={origin.isSelected ? 'primary' : 'default'}
                                        disabled={origin.selectedCount === 0 || origin.count === 0}
                                        onClick={() => {
                                            setOriginFilter(origin)
                                        }}
                                    />
                                ))}
                            </Stack>
                        </Stack>
                    </LeftSideWrapper>
                    <SubmissionListWrapper>
                        <DesktopInspectionImageGrid
                            refs={refs}
                            submissions={submissions}
                            highlightedSubmissionId={highlightedSubmissionId}
                            highlightSubmission={setHighlightedSubmissionId}
                            openSubmission={openSubmission}
                        />
                    </SubmissionListWrapper>
                </SubmissionViewer>
            )}
        </SubmissionHighlighter>
    )
}
export default DesktopDashboard
