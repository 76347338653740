import styled from 'styled-components'
import theme from '../../theme'

const PageContent = styled.main<{ $centered: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${({ $centered }) => ($centered ? 'center' : 'flex-start')};
    min-height: 100vh;
    width: calc(100% - 20px);
    max-width: 1300px;
    padding: 40px 0 10px;
    margin: 0 auto;

    ${theme.breakpoints.down('sm')} {
        padding: 5px 0 10px;
    }
`

export default PageContent
