import { useState } from 'react'
import Chip from '@mui/material/Chip'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import styled from 'styled-components'
import useCopyToClipboard from '../mixins/use-copy-to-clipboard'

const StyledChip = styled(Chip)`
    cursor: pointer;
`

const StyledContentCopyIcon = styled(ContentCopyIcon)`
    height: 0.7em !important;
`

const CopyToClipboard = ({
    label,
    copiedLabel,
    link,
    successDuration = 1000,
}: {
    label: React.ReactNode
    copiedLabel: React.ReactNode
    link: string
    successDuration?: number
}) => {
    const [textJustCopied, setTextJustCopied] = useState(false)
    const copyToClipboard = useCopyToClipboard()

    const onClick = () => {
        copyToClipboard(link).then(() => {
            setTextJustCopied(true)
            setTimeout(() => setTextJustCopied(false), successDuration)
        })
    }

    return (
        <StyledChip
            onClick={onClick}
            label={textJustCopied ? copiedLabel : label}
            color={textJustCopied ? 'success' : 'default'}
            icon={<StyledContentCopyIcon />}
        />
    )
}

export default CopyToClipboard
