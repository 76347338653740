import {
    useQuery,
    getInspectionsByUsers,
    markSubmissionAsSeenByUser,
    useAction,
    OptimisticUpdateDefinition,
} from 'wasp/client/operations'
import React from 'react'
import LoadingSkeleton from '../../../../components/loading/skeleton'
import InspectionsByEmail from './inspections-by-email'
import CompanyId from '../../../../../shared/types/company/id'
import { UserInspectionsViewModelEntry } from '../../../../../shared/types/user-management/inspections-by-email'
import SubmissionId from '../../../../../shared/types/submission/id'

const InspectionsByEmailWrapper = ({ companyId }: { companyId?: CompanyId }) => {
    const { data, isFetching, error } = useQuery(getInspectionsByUsers, { companyId })

    const markSubmissionAsSeenByUserOptimistically = useAction(markSubmissionAsSeenByUser, {
        optimisticUpdates: [
            {
                getQuerySpecifier: () => [getInspectionsByUsers, { companyId }],
                updateQuery: (payload, oldData = []) =>
                    oldData.map((entry) => ({
                        ...entry,
                        products: entry.products.map((product) => ({
                            ...product,
                            submissions: product.submissions.map((submission) => ({
                                ...submission,
                                unseenByUser:
                                    submission.id === payload.submissionId
                                        ? false
                                        : submission.unseenByUser,
                            })),
                        })),
                    })),
            } as OptimisticUpdateDefinition<
                { submissionId: SubmissionId },
                UserInspectionsViewModelEntry[]
            >,
        ],
    })

    if (isFetching) return <LoadingSkeleton large />
    if (error) return <div>Error: {error.message}</div>

    return (
        <InspectionsByEmail
            inspectionsByEmail={data!}
            markSubmissionAsSeenByUser={markSubmissionAsSeenByUserOptimistically}
        />
    )
}

export default InspectionsByEmailWrapper
