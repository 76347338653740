import React from 'react'
import styled from 'styled-components'
import AppSuite from '../../../../shared/app-suite'
import theme from '../../../theme'

const NavLinkEl = styled.a<{ $isActive: boolean; $color: string }>`
    font-family: 'Bebas neue', sans-serif;
    color: #000;
    text-decoration: none;
    font-size: 1.2em;
    letter-spacing: 0.1em;
    border-bottom: 2px solid transparent;
    transition: border-color 0.2s ease-in-out;
    border-color: ${({ $isActive, $color }) => ($isActive ? $color : 'transparent')};

    &:hover {
        opacity: 1;
        border-color: ${({ $color }) => $color};
    }

    ${theme.breakpoints.down('sm')} {
        font-size: 1em;
    }
`
const NavLink: React.FC<{ app: AppSuite; isActive: boolean; href: string }> = ({
    app,
    isActive,
    href,
}) => {
    const title = React.useMemo(() => {
        switch (app) {
            case AppSuite.Inspector:
                return 'Products'
            case AppSuite.Report:
                return 'Inspections'
            case AppSuite.UserManagement:
                return 'Users'
            case AppSuite.Support:
                return 'Support'
        }
    }, [app])

    const color = React.useMemo(() => {
        switch (app) {
            case AppSuite.Home:
                return '#E4FF03'
            case AppSuite.Inspector:
                return '#FF00BF'
            case AppSuite.Report:
                return '#007BFF'
            case AppSuite.UserManagement:
                return '#F2A63B'
            case AppSuite.Support:
                return '#60E033'
        }
    }, [app])

    return (
        <NavLinkEl $isActive={isActive} $color={color} href={href}>
            {title}
        </NavLinkEl>
    )
}

export default NavLink
