import React from 'react'
import Fab from '@mui/material/Fab'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { useIsMobile } from '../../../../mixins/media-query'
import T from '../../../../components/typography/t'

const AddNewUser = ({ openNewProductDialog }: { openNewProductDialog: () => void }) => {
    const isMobile = useIsMobile()
    return isMobile ? (
        <Fab color="primary" aria-label="add" onClick={openNewProductDialog} size="small">
            <AddIcon />
        </Fab>
    ) : (
        <Button color="primary" aria-label="add" onClick={openNewProductDialog}>
            <T i18nKey="company.userManagement.users.action" />
        </Button>
    )
}
export default AddNewUser
