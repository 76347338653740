import styled from 'styled-components'
import theme from '../../../../../theme'

const MarkerWrapper = styled.a`
    max-width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    > * {
        transition: transform 0.3s;
    }

    ${theme.breakpoints.down('sm')} {
        height: 150px;
    }

    &:hover {
        > * {
            transform: scale(1.1);
        }
    }
`

export default MarkerWrapper
