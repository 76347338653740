import React from 'react'
import styled from 'styled-components'
import theme from '../../../../../../../theme'

const Label = styled.label`
    margin-top: 1.5rem;
    display: block;
    background: ${theme.palette.primary.main};
    color: #e4ff03;
    padding: 1rem;
    border-radius: 0.5rem;
    text-align: center;
    cursor: pointer;
    font-size: 1.2em;

    input {
        opacity: 0;
        position: absolute;
        pointer-events: none;
        width: 0;
    }
`

const PhotoInput: React.FC<{
    chosePhoto: (e: React.ChangeEvent<HTMLInputElement>) => void
    children: React.ReactNode
    allowGalleryUpload?: boolean
}> = ({ chosePhoto, allowGalleryUpload = false, children }) => {
    return (
        <Label>
            <span>{children}</span>
            <input
                type="file"
                accept="image/*"
                capture={allowGalleryUpload ? undefined : 'environment'}
                onChange={chosePhoto}
                data-testid="photo-input"
            />
        </Label>
    )
}

export default PhotoInput
