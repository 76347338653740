import React from 'react'
import Paper from '@mui/material/Paper'
import Typo from '@mui/material/Typography'
import theme from '../../../../theme'
import { ProductInspectionsByUser } from '../../../../../shared/types/user-management/inspections-by-email'
import styled from 'styled-components'
import Stack from '@mui/material/Stack'
import ImageMarker from '../../../../components/image-marker'
import createUploadcareImageSrc from '../../../../mixins/create-uploadcare-image-src'
import SubmissionHighlighter from '../../common/submission-highlighter'
import InspectionImageTile from '../../common/inspection-image-tile'
import SubmissionDialogWrapper from '../../inspector/product-dashboard/overview/submission-dialog-wrapper'
import SubmissionDetail from '../../common/submission-detail'
import SubmissionId from '../../../../../shared/types/submission/id'
import { deleteSubmission } from 'wasp/client/operations'

const SubmissionViewer = styled.div`
    display: flex;
    flex-direction: row;
    max-height: 800px;
    width: 100%;
`

const LeftSideWrapper = styled(Stack)`
    width: 200px;
    flex-shrink: 0;
    padding: 20px 20px;
`

const ProductImage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 200px;
`

const SubmissionListWrapper = styled.div`
    overflow-x: auto;
    border-left: 1px solid #e0e0e0;
    padding-left: 20px;
    display: flex;
    align-items: center;
    flex-grow: 1;
`

const InspectionByEmailRow: React.FC<{
    product: ProductInspectionsByUser
    markSubmissionAsSeenByUser: ({ submissionId }: { submissionId: SubmissionId }) => void
}> = ({ product, markSubmissionAsSeenByUser }) => {
    return (
        <SubmissionHighlighter key={product.id} entities={product.submissions}>
            {({ highlightedSubmissionId, setHighlightedSubmissionId, refs }) => (
                <SubmissionDialogWrapper
                    onOpenSubmission={(submissionId) =>
                        markSubmissionAsSeenByUser({ submissionId })
                    }
                >
                    {({ openedSubmissionId, setOpenedSubmissionId }) => (
                        <>
                            <Paper
                                sx={{
                                    backgroundColor: theme.palette.grey[50],
                                    mx: 1,
                                }}
                            >
                                <SubmissionViewer>
                                    <LeftSideWrapper>
                                        <Stack spacing={1} alignItems="center">
                                            <ProductImage>
                                                <ImageMarker
                                                    imageUrl={createUploadcareImageSrc(
                                                        product.imgUrl,
                                                        {
                                                            preview: '800x800',
                                                        }
                                                    )}
                                                    markers={product.submissions.map((sub) => ({
                                                        id: sub.id,
                                                        x: sub.imagePinpoint.x,
                                                        y: sub.imagePinpoint.y,
                                                    }))}
                                                    highlightSubmission={setHighlightedSubmissionId}
                                                    highlightedSubmissionId={
                                                        highlightedSubmissionId
                                                    }
                                                />
                                            </ProductImage>
                                            <Typo textAlign="center">{product.name}</Typo>
                                        </Stack>
                                    </LeftSideWrapper>
                                    <SubmissionListWrapper>
                                        <Stack
                                            direction="row"
                                            sx={{
                                                width: '100%',
                                                overflowX: 'auto',
                                                height: '200px',
                                            }}
                                        >
                                            {product.submissions.map((submission, index) => (
                                                <InspectionImageTile
                                                    ref={refs.current[index]}
                                                    key={submission.id}
                                                    onClick={() =>
                                                        setOpenedSubmissionId(submission.id)
                                                    }
                                                    onMouseEnter={() =>
                                                        setHighlightedSubmissionId({
                                                            id: submission.id,
                                                            source: 'list',
                                                        })
                                                    }
                                                    onMouseLeave={() =>
                                                        setHighlightedSubmissionId(undefined)
                                                    }
                                                    imageUrl={createUploadcareImageSrc(
                                                        submission.image,
                                                        {
                                                            preview: '500x660',
                                                        }
                                                    )}
                                                    isUnseenByUser={submission.unseenByUser}
                                                    labels={submission.labels}
                                                    isFadedOut={
                                                        highlightedSubmissionId?.id !==
                                                            submission.id &&
                                                        highlightedSubmissionId?.source === 'marker'
                                                    }
                                                    inspectionOrigin={submission.inspectionOrigin}
                                                />
                                            ))}
                                        </Stack>
                                    </SubmissionListWrapper>
                                </SubmissionViewer>
                            </Paper>

                            {!!openedSubmissionId &&
                                product.submissions.find(
                                    (sub) => sub.id === openedSubmissionId
                                ) && (
                                    <SubmissionDetail
                                        submissions={product.submissions.map((sub) => ({
                                            ...sub,
                                            productId: product.id,
                                            productImgUrl: product.imgUrl,
                                            productName: product.name,
                                        }))}
                                        openedSubmissionId={openedSubmissionId}
                                        setOpenedSubmissionId={setOpenedSubmissionId}
                                        close={() => setOpenedSubmissionId(undefined)}
                                        productImgUrl={product.imgUrl}
                                        deleteSubmission={async (id) => {
                                            await deleteSubmission({ submissionId: id })
                                            setOpenedSubmissionId(undefined)
                                        }}
                                    />
                                )}
                        </>
                    )}
                </SubmissionDialogWrapper>
            )}
        </SubmissionHighlighter>
    )
}

export default InspectionByEmailRow
