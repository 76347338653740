import duotoneRepairGuides from '../repair-guides'
import duotoneRepairServices from '../repair-services/duotone-support-services'
import duotoneProductCategoryTree from '../products/duotone-products'
import {
    Field,
    FieldType,
    WarrantyInformationStepType,
    WhitelabelStatus,
    type Whitelabel,
} from './types'

const contactFields: Field[][] = [
    [
        {
            key: 'Firstname',
            label: 'serviceDesk.shared.contactStep.form.firstnameLabel',
            type: FieldType.TextField,
            params: { required: true },
        },
    ],
    [
        {
            key: 'Lastname',
            label: 'serviceDesk.shared.contactStep.form.lastnameLabel',
            type: FieldType.TextField,
            params: { required: true },
        },
    ],
    [
        {
            key: 'Email',
            label: 'serviceDesk.shared.contactStep.form.emailLabel',
            type: FieldType.TextField,
            params: { required: true },
            fieldProps: { type: 'email' },
        },
    ],
    [
        {
            key: 'Phone',
            label: 'serviceDesk.shared.contactStep.form.phoneLabel',
            type: FieldType.TextField,
            params: { required: true },
        },
    ],
]

/*
    TODO:
    - Adjust ID
    - Adjust name (variable and brand name)
    - Adjust theme
    - Adjust logo
    - Adjust repair guides DE / EN
    - Adjust repair services DE / EN
    - Define product category tree
    - Define outro links
    - Adjust plugin config (email recipients)
*/
const duotoneWhitelabel: Whitelabel = {
    id: '8ijarl',
    status: WhitelabelStatus.ExternalDemoAccess,
    name: 'Duotone',
    theme: {
        palette: {
            primary: {
                main: '#0d242b',
                contrastText: '#fff',
            },
            secondary: {
                main: '#05A8C4',
                contrastText: '#000',
            },
            getCrosshairColors: () => ({ stroke: '#0d242b', fill: '#05A8C4' }),
            getIllustrationColors: () => ({
                primary: '#0d242b',
                accent: '#05A8C4',
            }),
            getProgressbarColor: () => '#05A8C4',
            getTextColor: () => '#0d242b',
        },
    },
    logo: 'https://ucarecdn.com/e5c6c239-3df7-4e27-bf23-c386f5a4149f/',
    repairGuides: duotoneRepairGuides,
    repairServices: duotoneRepairServices,
    alterationServices: [],
    productCategoryTree: duotoneProductCategoryTree,
    config: {
        repair: {
            contactForm: {
                fields: contactFields,
            },
            activateCostEstimation: true,
            includeDiyGuides: false,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle2',
                links: [
                    {
                        label: 'poc.support.repairOutroStep.locationAction',
                        href: 'https://maps.app.goo.gl/xTPxhvH59A67JAhPA',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: ['marc@brakeable.com', 'stefan@brakeable.com'],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
        warranty: {
            activateWarrantyEstimation: false,
            contactForm: {
                fields: contactFields,
            },
            warrantyInformationSteps: [
                {
                    fieldKey: 'purchaseConfirmation',
                    title: 'serviceDesk.warranty.purchaseInformationStep.title',
                    type: WarrantyInformationStepType.Text,
                    fields: [
                        [
                            {
                                key: 'PurchaseDate',
                                label: 'serviceDesk.shared.forms.purchaseDateLabel',
                                type: FieldType.TextField,
                                params: { required: true },
                                fieldProps: {
                                    type: 'date',
                                },
                            },
                        ],
                        [
                            {
                                key: 'PurchaseLocation',
                                label: 'serviceDesk.shared.contactStep.form.purchaseLocationLabel',
                                type: FieldType.TextField,
                                params: { required: false },
                                fieldProps: {},
                            },
                        ],
                        [
                            {
                                key: 'ProductName',
                                label: 'serviceDesk.shared.forms.productNameLabel',
                                type: FieldType.TextField,
                                params: { required: false },
                            },
                        ],
                        [
                            {
                                key: 'ProductSize',
                                label: 'serviceDesk.shared.forms.productSizeLabel',
                                type: FieldType.TextField,
                                params: { required: false },
                            },
                        ],
                    ],
                },
                {
                    fieldKey: 'serialNumber',
                    type: WarrantyInformationStepType.Photo,
                    photo: {
                        key: 'serialNumberPhoto',
                        label: 'serviceDesk.warranty.warrantyInformationSteps.warrantyInformationPhotoStep.label',
                        options: {
                            required: true,
                            helperPopup: {
                                title: 'serviceDesk.shared.forms.serialNumberWhereToFindTitle',
                                imgUrl: 'https://ucarecdn.com/d9a9ad9d-840f-4073-b2c8-9193bbeb96b3/-/preview/842x595/',
                            },
                        },
                    },
                    required: false,
                    translations: {
                        title: 'serviceDesk.warranty.serialNumberStep.title',
                        photoTitle: 'serviceDesk.warranty.serialNumberStep.title',
                        photoSubtitle:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.photoSubtitle',
                        textTitle:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.manualTitle',
                        textSubtitle:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.manualSubtitle',
                        notAvailableQuestion:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.notAvailable.delimiter',
                        notAvailableAction:
                            'serviceDesk.warranty.purchaseConfirmationStep.linktree.notAvailable.button',
                    },
                },
            ],
            outro: {
                lead: 'serviceDesk.warranty.outroStep.subtitle',
                links: [
                    {
                        href: '//www.duotonesports.com/',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: ['marc@brakeable.com', 'stefan@brakeable.com'],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
    },
}

export default duotoneWhitelabel
