import * as React from 'react'

const TabPanel = ({
    children,
    open,
    name,
    ...other
}: {
    children?: React.ReactNode
    name: string
    open: boolean
}) => {
    return (
        <div
            role="tabpanel"
            hidden={!open}
            id={`${name}-panel`}
            aria-labelledby={`${name}-tab`}
            {...other}
        >
            {open && <>{children}</>}
        </div>
    )
}

export default TabPanel
