import React from 'react'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import InspectorLogo from '../../../../../assets/img/inspector-logo.png'
import ReportLogo from '../../../../../assets/img/report-logo.png'
import UserManagementLogo from '../../../../../assets/img/user-management-logo.png'
import SupportManagementLogo from '../../../../../assets/img/support-logo.png'
import SettingsIcon from '@mui/icons-material/Settings'

import AdminCompanyListEntryViewModel from '../../../../../../shared/types/company/admin-company-list-entry-view-model'
import routeBuilder from '../../../../../routes'
import styled from 'styled-components'

const AppLogo = styled.img`
    width: 32px;
    border-radius: 50%;
`

const CompanyRowEntry = ({ company }: { company: AdminCompanyListEntryViewModel }) => {
    return (
        <>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell
                    sx={{
                        display: { xs: 'none', md: 'table-cell' },
                    }}
                >
                    {company.id}
                </TableCell>
                <TableCell
                    component="th"
                    scope="row"
                    sx={{
                        fontSize: { xs: '0.7em', md: '1em' },
                        px: { xs: 0, md: 1 },
                    }}
                >
                    <a
                        href={routeBuilder.companyProductOverview(company.uuid)}
                        target="_blank"
                        style={{ color: 'inherit' }}
                    >
                        {company.name}
                    </a>
                </TableCell>
                <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                    {company.representativeName}
                </TableCell>
                <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                    {company.representativeEmail}
                </TableCell>
                <TableCell sx={{ px: { xs: 0, md: 1 } }}>
                    <IconButton
                        size="small"
                        color="primary"
                        href={routeBuilder.inspectorApp(company.id)}
                    >
                        <AppLogo src={InspectorLogo} />
                    </IconButton>
                    <IconButton
                        size="small"
                        color="primary"
                        href={routeBuilder.reportApp(company.id)}
                    >
                        <Badge badgeContent={company.unseenSubmissionCount} color="error">
                            <AppLogo src={ReportLogo} />
                        </Badge>
                    </IconButton>
                    <IconButton
                        size="small"
                        color="primary"
                        href={routeBuilder.userApp(company.id)}
                    >
                        <AppLogo src={UserManagementLogo} />
                    </IconButton>
                    <IconButton
                        size="small"
                        color="primary"
                        href={routeBuilder.supportApp(company.id)}
                    >
                        <AppLogo src={SupportManagementLogo} />
                    </IconButton>
                    <IconButton
                        size="small"
                        color="primary"
                        href={routeBuilder.companySettings(company.id)}
                    >
                        <SettingsIcon />
                    </IconButton>
                </TableCell>
                <TableCell
                    align="center"
                    sx={{ display: { xs: 'none', md: 'table-cell' }, padding: 0 }}
                >
                    {company.productCount}
                </TableCell>
            </TableRow>
        </>
    )
}

export default CompanyRowEntry
