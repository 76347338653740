import React from 'react'
import { WarrantyMultiStepFormContext } from '../../context'
import StepFrameworkWrapper from '../../../shared/components/step-framework-wrapper'
import PhotoStep, {
    type PhotoStepHandle,
    type UploadedFileInfo,
} from '../../../shared/steps/photo-step'
import useCookie from '../../../../../mixins/use-cookie'
import generateRandomCode from '../../../shared/helper/generate-random-code'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import { useTranslation } from 'react-i18next'
import { WidthContainer } from '../../../../../components/layout'
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Link, Stack } from '@mui/material'
import LinktreeEntry from '../../../shared/components/linktree-entry'
import T from '../../../../../components/typography/t'
import LabelledDelimiter from '../../../shared/components/labelled-delimiter'
import ManualProofOfPurchaseInput from './manual-proof-of-purchase-input'
import { useForm } from 'react-hook-form'
import {
    Field,
    WarrantyInformationPhotoStep,
    WarrantyInformationStepType,
} from '../../../../../../shared/data/whitelabel/types'
import CloseIcon from '@mui/icons-material/Close'
import useDialog from '../../../../../mixins/use-dialog'

const Linktree: React.FC<{
    onManualClick: () => void
    onPhotoClick: () => void
    onNotAvailableClick: () => void
    translations: {
        title: string
        photoTitle: string
        photoSubtitle: string
        textTitle: string
        textSubtitle: string
        notAvailableQuestion?: string
        notAvailableAction?: string
    }
    required: boolean
}> = ({ onManualClick, onPhotoClick, onNotAvailableClick, translations, required }) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle i18nKey={translations.title as any} mb={4} />
            <WidthContainer>
                <Stack gap={2} sx={{ width: '100%', justifyContent: 'center' }}>
                    <LinktreeEntry onClick={onPhotoClick}>
                        <T variant="h3" mb={1} i18nKey={translations.photoTitle as any} />
                        <T variant="body1" i18nKey={translations.photoSubtitle as any} />
                    </LinktreeEntry>
                    <LinktreeEntry onClick={onManualClick}>
                        <T variant="h3" mb={1} i18nKey={translations.textTitle as any} />
                        <T variant="body1" i18nKey={translations.textSubtitle as any} />
                    </LinktreeEntry>
                </Stack>
                {!required && (
                    <>
                        <LabelledDelimiter sx={{ my: 4 }}>
                            <T
                                component="span"
                                i18nKey={translations.notAvailableQuestion as any}
                            />
                        </LabelledDelimiter>
                        <Button onClick={onNotAvailableClick}>
                            <T i18nKey={translations.notAvailableAction as any} />
                        </Button>
                    </>
                )}
            </WidthContainer>
        </>
    )
}

const PurchaseConfirmationStep = ({
    fieldKey,
    translations,
    required,
    alternativeFields,
    photo,
}: WarrantyInformationPhotoStep) => {
    const showLinktree = !!alternativeFields
    const childRef = React.useRef<PhotoStepHandle>(null)
    const [mobilePhotoUploadStarted, setMobilePhotoUploadStarted] = React.useState<boolean>(false)
    const { isOpen, open, close } = useDialog()
    const [selectedApproach, setSelectedApproach] = React.useState(showLinktree ? 0 : 1)
    const { props, form, meta, error, uploadCareClient, ctx } = React.useContext(
        WarrantyMultiStepFormContext
    )
    const formValues = (form.formValues.warrantyInformationForm?.[fieldKey] ?? {}) as Record<
        string,
        any
    >
    const useFormReturn = useForm({
        values: formValues,
    })

    const onPhotoUploadFailed = (err: any) => {
        console.error(err)
        meta.setMetaValue((m: any) => ({
            ...m,
            photos: {
                [fieldKey]: {
                    progress: 0,
                },
            },
        }))
        error.pushError({
            message: 'Foto konnte nicht hochgeladen werden',
            action: {
                label: 'Erneut versuchen',
                onClick: () => {
                    error.popError()
                },
            },
        })
    }

    const setUploadProgress = (progression: number) => {
        meta.setMetaValue((m: any) => ({
            ...m,
            photos: {
                ...m.photos,
                [fieldKey]: {
                    ...m.photos?.[fieldKey],
                    progress: progression,
                },
            },
        }))
    }

    const setUploadedFileInfo = (info?: UploadedFileInfo) => {
        meta.setMetaValue((m: any) => ({
            ...m,
            photos: {
                ...m.photos,
                [fieldKey]: {
                    ...m.photos?.[fieldKey],
                    info,
                },
            },
        }))
    }

    const uploadedPhotoUrl = form.formValues.warrantyInformationForm?.[fieldKey]?.photo
    const uploadedFileInfo = meta.value?.photos?.[fieldKey]?.info
    const uploadProgress = meta.value?.photos?.[fieldKey]?.progress

    const [getMobileUploadIdFromCookies, setMobileUploadIdToCookies] = useCookie(
        'mobile-photo-upload-id-' + fieldKey
    )
    const mobileUploadId =
        getMobileUploadIdFromCookies() ??
        setMobileUploadIdToCookies(generateRandomCode(), { expires: 1 / 96 })

    return (
        <StepFrameworkWrapper
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled:
                    required === false
                        ? false
                        : selectedApproach === 0 ||
                          (selectedApproach === 1 &&
                              (mobilePhotoUploadStarted ||
                                  (!uploadedPhotoUrl && !uploadProgress))) ||
                          (selectedApproach === 2 &&
                              !(alternativeFields ?? ([] as Field[][]))
                                  .flat()
                                  .filter((field) => field.params?.required)
                                  .every((field) => !!useFormReturn.watch(field.key))),
                onValidNextClick: () => {
                    form.setValue('warrantyInformationForm', {
                        ...form.formValues.warrantyInformationForm,
                        [fieldKey]: useFormReturn.getValues(),
                    })
                    childRef.current?.goNextInterceptor()
                    props.nextSlide()
                },
                onValidPrevClick: () => {
                    if (!showLinktree || selectedApproach === 0) {
                        props.prevSlide()
                    } else {
                        setSelectedApproach(0)
                    }
                },
            }}
        >
            {selectedApproach === 0 && (
                <Linktree
                    onPhotoClick={() => setSelectedApproach(1)}
                    onManualClick={() => setSelectedApproach(2)}
                    onNotAvailableClick={props.nextSlide}
                    translations={translations}
                    required={required}
                />
            )}
            {selectedApproach === 1 && (
                <>
                    <InspectorStepTitle i18nKey={translations.photoTitle as any} mb={2} />

                    {photo.options?.helperPopup && (
                        <Link onClick={open} mb={4}>
                            <T i18nKey={photo.options.helperPopup.title as any} />
                        </Link>
                    )}
                    <PhotoStep
                        ref={childRef}
                        setOriginalImageUrl={(url) => {
                            form.setValue('warrantyInformationForm', {
                                ...form.formValues.warrantyInformationForm,
                                [fieldKey]: {
                                    __type: WarrantyInformationStepType.Photo,
                                    photo: url,
                                },
                            })
                        }}
                        onPhotoUploadFailed={onPhotoUploadFailed}
                        setUploadProgress={setUploadProgress}
                        setUploadedFileInfo={setUploadedFileInfo}
                        uploadedPhotoUrl={uploadedPhotoUrl}
                        uploadCareClient={uploadCareClient}
                        brandId={ctx.whitelabel.id}
                        brandName={ctx.whitelabel.name}
                        mobileUploadId={mobileUploadId}
                        uploadProgress={uploadProgress}
                        uploadedFileInfo={uploadedFileInfo}
                        mobilePhotoUploadStarted={mobilePhotoUploadStarted}
                        setMobilePhotoUploadStarted={setMobilePhotoUploadStarted}
                    />

                    <Dialog open={isOpen} onClose={close}>
                        {photo.options?.helperPopup && (
                            <>
                                <DialogTitle>
                                    <T i18nKey={photo.options.helperPopup?.title as any} />
                                </DialogTitle>
                                <IconButton
                                    aria-label="close"
                                    onClick={close}
                                    sx={(theme) => ({
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: theme.palette.grey[500],
                                    })}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <DialogContent>
                                    {photo.options.helperPopup.text && (
                                        <T i18nKey={photo.options.helperPopup.text as any} />
                                    )}
                                    {photo.options.helperPopup.imgUrl && (
                                        <img
                                            src={photo.options.helperPopup.imgUrl}
                                            alt="helper popup"
                                        />
                                    )}
                                </DialogContent>
                            </>
                        )}
                    </Dialog>
                </>
            )}
            {selectedApproach === 2 && (
                <ManualProofOfPurchaseInput
                    fields={alternativeFields ?? []}
                    useFormReturn={useFormReturn}
                />
            )}
        </StepFrameworkWrapper>
    )
}

export default PurchaseConfirmationStep
