import React from 'react'
import StepContainer from './step-container'
import { ErrorStack } from './error-stack'
import { styled } from '@mui/material'
import MultiStepNavigation, {
    MultiStepNavigationProps,
} from '../../../../service-desk/shared/components/navigation'

export const StepContentContainer = styled('div')<{ $squeezeInScreen?: boolean; $wide?: boolean }>`
    height: ${({ $squeezeInScreen }) => ($squeezeInScreen ? '100px' : 'auto')};
    padding: 0 ${({ theme }) => theme.spacing(2)};
    flex-grow: 1;
    width: 100%;
    max-width: ${({ $wide }) => ($wide ? '1200px' : '650px')};
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`
const ErrorStackWrapper = styled('div')`
    position: absolute;
    bottom: 100px;
    left: 5%;
    width: 90%;
`

type StepFrameworkWrapperProps = {
    children: React.ReactNode
    navigationProps: MultiStepNavigationProps
}

const StepFrameworkWrapper = ({ navigationProps, children }: StepFrameworkWrapperProps) => {
    return (
        <StepContainer>
            <MultiStepNavigation {...navigationProps} />
            <StepContentContainer>{children}</StepContentContainer>
            <ErrorStackWrapper>
                <ErrorStack />
            </ErrorStackWrapper>
        </StepContainer>
    )
}

export default StepFrameworkWrapper
