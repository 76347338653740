import { ProductCategoryId, ProductCategoryNode, ProductTypeId } from './types'

export const productCategories: ProductCategoryNode = {
    id: 0 as ProductCategoryId,
    name: 'serviceDesk.shared.productStep.categories.all',
    Icon: 'FreitagBagsIcon',
    subItems: [
        {
            id: 1 as ProductCategoryId,
            name: 'serviceDesk.shared.productStep.categories.bags',
            Icon: 'FreitagBagsIcon',
            subItems: [
                {
                    id: 112 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.backpack',
                    internalName: 'backpack',
                    Icon: 'BackpackIcon',
                    applicableGuides: [],
                    applicableServices: [],
                    applicableAlterations: [],
                    views: [],
                },
                {
                    id: 113 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.messengerBag',
                    internalName: 'messengerBag',
                    Icon: 'MessengerBagIcon',
                    applicableGuides: [10001, 10002],
                    applicableServices: [1, 2, 3, 5, 6],
                    applicableAlterations: [],
                    views: [
                        {
                            label: 'serviceDesk.shared.pinpointStep.views.front',
                            sketchUrl: 'https://ucarecdn.com/59d3969f-6601-4920-bae4-34606bf51dbe/',
                            details: [
                                {
                                    name: 'Buckle',
                                    features: [],
                                    x1: 0.73,
                                    x2: 0.93,
                                    y1: 0,
                                    y2: 0.15,
                                },
                                {
                                    name: 'Buckle',
                                    features: [],
                                    x1: 0.71,
                                    x2: 0.83,
                                    y1: 0.72,
                                    y2: 0.91,
                                },
                                {
                                    name: 'Buckle',
                                    features: [],
                                    x1: 0.14,
                                    x2: 0.26,
                                    y1: 0.72,
                                    y2: 0.91,
                                },
                                {
                                    name: 'zipper',
                                    features: [],
                                    x1: 0.265,
                                    x2: 0.32,
                                    y1: 0.38,
                                    y2: 0.9,
                                },
                                {
                                    name: 'zipper slider',
                                    features: [],
                                    x1: 0.265,
                                    x2: 0.32,
                                    y1: 0.36,
                                    y2: 0.47,
                                },
                            ],
                        },
                        {
                            label: 'serviceDesk.shared.pinpointStep.views.inside',
                            sketchUrl: 'https://ucarecdn.com/69fb760f-8c96-440e-8593-616d4efb2492/',
                            details: [
                                {
                                    name: 'Velcro',
                                    features: [],
                                    x1: 0.0125,
                                    x2: 0.9,
                                    y1: 0.49,
                                    y2: 0.57,
                                },
                                {
                                    name: 'Velcro',
                                    features: [],
                                    x1: 0.013,
                                    x2: 0.88,
                                    y1: 0.77,
                                    y2: 0.85,
                                },
                                {
                                    name: 'Velcro',
                                    features: [],
                                    x1: 0.17,
                                    x2: 0.26,
                                    y1: 0.02,
                                    y2: 0.35,
                                },
                                {
                                    name: 'Velcro',
                                    features: [],
                                    x1: 0.63,
                                    x2: 0.72,
                                    y1: 0.02,
                                    y2: 0.35,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 111 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.freitagTravelBag',
                    internalName: 'travelBag',
                    Icon: 'FreitagTravelBagIcon',
                    applicableGuides: [],
                    applicableServices: [],
                    applicableAlterations: [],
                    views: [],
                },
                {
                    id: 122 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.laptopBag',
                    internalName: 'laptopBag',
                    Icon: 'LaptopBagIcon',
                    applicableGuides: [],
                    applicableServices: [],
                    applicableAlterations: [],
                    views: [],
                },
                {
                    id: 123 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.toteBag',
                    internalName: 'toteBag',
                    Icon: 'ToteBagIcon',
                    applicableGuides: [],
                    applicableServices: [],
                    applicableAlterations: [],
                    views: [],
                },
            ],
        },
        {
            id: 2 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.accessories',
            internalName: 'accessories',
            Icon: 'FreitagAccessoriesIcon',
            applicableGuides: [],
            applicableServices: [],
            applicableAlterations: [],
            views: [],
        },
    ],
}

export default productCategories
