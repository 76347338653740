import React from 'react'
import { SetupMultiStepFormContext } from '../setup-multi-step-form'
import StepFrameworkWrapper from './components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../../components/typography/inspector-step-subtitle'
import { useTranslation } from 'react-i18next'
import { DevicePreviewWrapper } from '../styles'
import DevicePreview from '../../../../../../components/device-preview'
import InspectorCustomerPreview from '../../../../../../components/inspector-customer-preview'
import { useIsMobile } from '../../../../../../mixins/media-query'
import InspectorStepTitle from '../../../../../../components/typography/inspector-step-title'
import T from '../../../../../../components/typography/t'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

export const SummaryStep = ({
    originalImageUrl,
    croppedImageUrl,
    imageOptimizationFailed,
    useCroppedImage,
    setUseCroppedImage,
}: {
    originalImageUrl?: string
    croppedImageUrl?: string
    imageOptimizationFailed?: boolean
    useCroppedImage?: boolean
    setUseCroppedImage: (flag: boolean) => void
}) => {
    const [t] = useTranslation()
    const isMobile = useIsMobile()

    if (!croppedImageUrl && imageOptimizationFailed === undefined) {
        return <T i18nKey="company.inspector.home.products.newProduct.form.summaryStep.loading" />
    }

    const previewScale = 0.5
    const previewImgUrl = useCroppedImage ? croppedImageUrl : originalImageUrl
    return (
        <>
            <InspectorStepTitle
                i18nKey="company.inspector.home.products.newProduct.form.summaryStep.title"
                Icon={undefined}
            />
            <InspectorStepSubtitle
                mb={4}
                i18nKey="company.inspector.home.products.newProduct.form.summaryStep.subtitle"
            />
            <DevicePreviewWrapper>
                <DevicePreview scale={previewScale} key={previewImgUrl}>
                    <InspectorCustomerPreview imageUrl={previewImgUrl} />
                </DevicePreview>
            </DevicePreviewWrapper>
            {!!croppedImageUrl && (
                <Box my={2}>
                    <Button onClick={() => setUseCroppedImage(!useCroppedImage)}>
                        {useCroppedImage ? (
                            <T i18nKey="company.inspector.home.products.newProduct.form.summaryStep.useOriginalBtn" />
                        ) : (
                            <T i18nKey="company.inspector.home.products.newProduct.form.summaryStep.useOptimizedBtn" />
                        )}
                    </Button>
                </Box>
            )}
        </>
    )
}

const SummaryStepContext = () => {
    const { form, meta } = React.useContext(SetupMultiStepFormContext)
    return (
        <StepFrameworkWrapper
            navigationProps={{
                nextButtonDisabled: !form.formValues.productName,
                nextButtonLabelKey:
                    'company.inspector.home.products.newProduct.form.summaryStep.nextBtn',
                onValidNextClick: form.submitForm,
            }}
        >
            <SummaryStep
                originalImageUrl={form.formValues.originalImageUrl}
                croppedImageUrl={form.formValues.croppedImageUrl}
                imageOptimizationFailed={meta.value?.imageOptimizationFailed}
                useCroppedImage={form.formValues.useCroppedImage}
                setUseCroppedImage={form.setUseCroppedImage}
            />
        </StepFrameworkWrapper>
    )
}

export default SummaryStepContext
