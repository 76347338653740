import React from 'react'
import useUrlQuery from './use-url-query'

const useUrlQueryState = (
    name: string,
    defaultValue?: string
): [string | null | undefined, (value?: string) => void] => {
    const [getQueryParam, setQueryParam] = useUrlQuery(name)
    const [value, setValue] = React.useState<string | null | undefined>(
        getQueryParam() ?? defaultValue
    )

    React.useEffect(() => {
        const value = getQueryParam()
        if (value) {
            setValue(value)
        }
    }, [getQueryParam()])

    const persistValue = React.useCallback(
        (value?: string) => {
            setQueryParam(value)
            setValue(value)
        },
        [setQueryParam]
    )

    return [value, persistValue]
}

export default useUrlQueryState
