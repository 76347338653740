import freitagProductCategoryTree from '../products/freitag-products'
import { FieldType, WhitelabelStatus, type Whitelabel } from './types'
import freitagFakeRepairGuides from '../repair-guides/fake-freitag-repair-guides'
import freitagFakeSupportServices from '../repair-services/freitag-fake-support-services'
/*
    TODO:
    - Adjust ID
    - Adjust name (variable and brand name)
    - Adjust theme
    - Adjust logo
    - Adjust repair guides DE / EN
    - Adjust repair services DE / EN
    - Define product category tree
    - Define outro links
    - Adjust plugin config (email recipients)
*/
const freitagWhitelabel: Whitelabel = {
    id: '6aopb0',
    status: WhitelabelStatus.ExternalDemoAccess,
    name: 'Freitag',
    theme: {
        palette: {
            primary: {
                main: '#000',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#000',
                contrastText: '#ffffff',
            },
            getCrosshairColors: () => ({ stroke: '#000', fill: '#ffffff' }),
            getIllustrationColors: () => ({ primary: '#000', accent: '#23201F' }),
            getProgressbarColor: () => '#000',
            getTextColor: () => '#000',
        },
    },
    logo: 'https://ucarecdn.com/dcac8124-55c1-4ffe-9072-8804e7329af7/',
    repairGuides: freitagFakeRepairGuides,
    repairServices: freitagFakeSupportServices,
    alterationServices: [],
    productCategoryTree: freitagProductCategoryTree,
    config: {
        repair: {
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Address',
                            label: 'serviceDesk.shared.contactStep.form.addressLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'ZipCode',
                            label: 'serviceDesk.shared.contactStep.form.zipCodeLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                            fieldProps: {
                                type: 'number',
                                fieldControlSx: { width: 150, flexShrink: 0 },
                            },
                        },
                        {
                            key: 'City',
                            label: 'serviceDesk.shared.contactStep.form.cityLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Country',
                            label: 'serviceDesk.shared.contactStep.form.countryLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                        },
                    ],
                ],
            },
            displayRepairOptions: true,
            activateCostEstimation: true,
            includeDiyGuides: true,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [
                    {
                        href: 'https://freitag.ch/en_CH/products',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: [
                            'peter.hollenstein@freitag.ch',
                            'marc@brakeable.com',
                            'stefan@brakeable.com',
                        ],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
        ufixit: {
            outro: {
                assistanceIframeUrl: 'https://freitag.ch/de_DE/stores?',
            },
        },
    },
}

export default freitagWhitelabel
