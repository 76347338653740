export default [
    {
        id: 1,
        title: 'Replace Buckle',
        translations: {
            en: 'Replace Buckle',
            de: 'Austausch der Schnalle',
        },
        price: [24],
    },
    {
        id: 2,
        title: 'Replace Zipper Slider',
        translations: {
            en: 'Replace Zipper Slider',
            de: 'Austausch des Reißverschluss-Schiebers',
        },
        price: [24],
    },
    {
        id: 3,
        title: 'New Zipper',
        translations: {
            en: 'New Zipper',
            de: 'Neuer Reißverschluss',
        },
        price: [60],
    },
    {
        id: 4,
        title: 'Replace Loop Buckle',
        translations: {
            en: 'Replace Loop Buckle',
            de: 'Austausch der Loop Schnalle',
        },
        price: [28],
    },
    {
        id: 5,
        title: 'Hole-, Tear-, Rip-Patch',
        translations: {
            en: 'Hole-, Tear-, Rip-Patch',
            de: 'Loch-, Reiß-, Riss-Reparatur',
        },
        price: [30],
    },
    {
        id: 6,
        title: 'Seam Repair',
        translations: {
            en: 'Seam Repair',
            de: 'Nähstelle reparieren',
        },
        price: [25],
    },
]
