import defaultProductTree from '../products/index'
import { alpha } from '@mui/material'
import { FieldType, WhitelabelStatus, type Whitelabel } from './types'
import intersportRepairGuides from '../repair-guides/intersport-repair-guides'
import vaudeRepairGuides from '../repair-guides/vaude-repair-guides'

/*
    TODO:
    - Adjust ID
    - Adjust name (variable and brand name)
    - Adjust theme
    - Adjust logo
    - Adjust repair guides DE / EN
    - Adjust repair services DE / EN
    - Define product category tree
    - Define outro links
    - Adjust plugin config (email recipients)
*/
const intersportWhitelabel: Whitelabel = {
    id: '2dh79y',
    status: WhitelabelStatus.ExternalDemoAccess,
    name: 'Intersport',
    theme: {
        palette: {
            primary: {
                main: '#154194',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#E30814',
                contrastText: '#ffffff',
            },
            getCrosshairColors: () => ({ stroke: '#154194', fill: alpha('#154194', 0.4) }),
            getIllustrationColors: () => ({ primary: '#154194', accent: '#E30814' }),
            getProgressbarColor: () => '#154194',
        },
    },
    logo: 'https://ucarecdn.com/cd919cdd-50dc-4538-9194-83018accc2d4/',
    links: {
        storeFinder: 'https://www.intersport.de/haendlersuche/',
    },
    repairGuides: [...intersportRepairGuides, ...vaudeRepairGuides],
    repairServices: [],
    alterationServices: [],
    productCategoryTree: defaultProductTree,
    config: {
        repair: {
            storeFinderIframeUrl: 'https://www.intersport.de/haendlersuche/?lang=',
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Address',
                            label: 'serviceDesk.shared.contactStep.form.addressLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'ZipCode',
                            label: 'serviceDesk.shared.contactStep.form.zipCodeLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                            fieldProps: {
                                type: 'number',
                                fieldControlSx: { width: 150, flexShrink: 0 },
                            },
                        },
                        {
                            key: 'City',
                            label: 'serviceDesk.shared.contactStep.form.cityLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Country',
                            label: 'serviceDesk.shared.contactStep.form.countryLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Comment',
                            label: 'serviceDesk.shared.contactStep.form.commentLabel',
                            type: FieldType.TextField,
                            params: { required: false },
                            fieldProps: {
                                multiline: true,
                                rows: 2,
                            },
                        },
                    ],
                ],
            },
            activateCostEstimation: false,
            includeDiyGuides: true,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: ['marc@brakeable.com', 'stefan@brakeable.com'],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
    },
}

export default intersportWhitelabel
