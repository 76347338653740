import React from 'react'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IntroCarousel from './carousel'
import styled from 'styled-components'
import T from '../../../../components/typography/t'

const StyledDialogContent = styled(DialogContent)`
    padding-top: 24px !important;
`

const IntroOverlay: React.FC<{ isOpen: boolean; close: () => void }> = ({ isOpen, close }) => {
    return (
        <Dialog open={isOpen} onClose={close}>
            <IconButton
                aria-label="close"
                onClick={close}
                sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    zIndex: 1,
                }}
            >
                <CloseIcon />
            </IconButton>
            <StyledDialogContent>
                <T
                    i18nKey="publicCompany.introCarousel.title"
                    component="h2"
                    variant="h4"
                    textAlign="center"
                    color="primary"
                />
                <IntroCarousel closeOverlay={close} />
            </StyledDialogContent>
        </Dialog>
    )
}

export default IntroOverlay
