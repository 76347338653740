import React from 'react'

const useDialog = (defaultState: boolean = false) => {
    const [isOpen, setOpen] = React.useState(defaultState)

    const open = () => {
        setOpen(true)
    }

    const close = () => {
        setOpen(false)
    }

    return { isOpen, open, close }
}

export default useDialog
