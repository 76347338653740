export enum OrderType {
    B2B = 'B2B',
    B2C = 'B2C',
}

type OrtliebPluginConfig = {
    orderType: OrderType
    senderEmail: string
}

export default OrtliebPluginConfig
