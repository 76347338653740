import React from 'react'
import { RepairMultiStepFormContext } from '../context'
import StepFrameworkWrapper from '../../shared/components/step-framework-wrapper'
import { ProductTypeId } from '../../../../../shared/data/products/types'
import { findProductTypeById } from '../../../../../shared/data/products/helpers'
import PinpointStep from '../../shared/steps/pinpoint-step'
import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import useDialog from '../../../../mixins/use-dialog'
import T from '../../../../components/typography/t'

const PinpointStepContext = () => {
    const { ctx, form, props } = React.useContext(RepairMultiStepFormContext)
    const { isOpen, open, close } = useDialog()

    const portrait = window.matchMedia('(orientation: portrait)')
    const [isInPortraitMode, setIsInPortraitMode] = React.useState<boolean>(portrait.matches)

    const orientationChanged = (e: MediaQueryListEvent) => {
        setIsInPortraitMode(e.matches)
    }

    React.useEffect(() => {
        portrait.addEventListener('change', orientationChanged)

        return () => {
            portrait.removeEventListener('change', orientationChanged)
        }
    }, [])

    const productNode = findProductTypeById(
        (form.formValues.selectedProduct ?? (-1 as any)) as ProductTypeId,
        ctx.productCategoryTree
    )!

    const resetAndGoBack = () => {
        form.resetForm()
        props.prevSlide()
    }

    return (
        <StepFrameworkWrapper
            squeezeInScreen={!ctx.isIframe}
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled: !form.formValues.pinpoint,
                onValidPrevClick: () => {
                    if (form.formValues.pinpoint !== undefined) {
                        open()
                    } else {
                        resetAndGoBack()
                    }
                },
            }}
        >
            <PinpointStep
                setInspectionImagePinpoint={(pinpoint) => {
                    form.setValue('pinpoint', pinpoint)
                }}
                setSelectedComponent={(component) => form.setValue('selectedComponent', component)}
                setSelectedProductView={(view) => form.setValue('selectedProductView', view)}
                productViews={productNode.views}
                initialCoords={form.formValues.pinpoint}
                initialSelectedProductView={form.formValues.selectedProductView}
            />
            <Dialog open={isOpen} onClose={close}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <T i18nKey="serviceDesk.repair.pinpointStep.progressResetWarning" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', pb: 2, px: 3 }}>
                    <Button
                        color="error"
                        onClick={() => {
                            close()
                            resetAndGoBack()
                        }}
                        autoFocus
                    >
                        <T i18nKey="serviceDesk.repair.pinpointStep.backToOverview" />
                    </Button>
                    <Button onClick={close} variant="contained">
                        <T i18nKey="common.cancel" />
                    </Button>
                </DialogActions>
            </Dialog>
        </StepFrameworkWrapper>
    )
}

export default PinpointStepContext
