import { IconButton, Typography, LinearProgress, styled } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import createUploadcareImageSrc from '../../../../mixins/create-uploadcare-image-src'
// @ts-ignore
import PhotoIcon from '../../../../assets/img/photo.svg?react'

const PreviewImg = styled('img')`
    max-width: 100%;
    max-height: 100%;
`

const PreviewBox = styled('div')`
    padding: ${({ theme }) => theme.spacing(4)};
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing(4)};
    width: 100%;
`

const PhotoPlaceholder = styled('div')`
    width: 72px;
    height: 72px;
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
`

const PreviewPhotoIcon = styled(PhotoIcon)`
    width: 60%;
    height: 60%;
    fill: ${({ theme }) => theme.palette.primary.main};
`

const PreviewContent = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 1;
    color: ${({ theme }) => theme.palette.primary.main};
`

const DeleteWrapper = styled('div')`
    width: 50px;
    flex-shrink: 0;
`

const PhotoPreview: React.FC<{
    uploadedPhotoUrl?: string
    uploadedFileInfo?: { name: string; size: number }
    uploadProgress: number
    progressUnknown?: boolean
    resetUpload?: () => void
}> = ({ uploadedPhotoUrl, uploadedFileInfo, uploadProgress, progressUnknown, resetUpload }) => {
    return (
        <PreviewBox>
            <PhotoPlaceholder>
                {!!uploadedPhotoUrl ? (
                    <PreviewImg
                        src={createUploadcareImageSrc(uploadedPhotoUrl, {
                            preview: '380x600',
                        })}
                    />
                ) : (
                    <PreviewPhotoIcon />
                )}
            </PhotoPlaceholder>
            <PreviewContent>
                {!!uploadedFileInfo && (
                    <>
                        <Typography sx={{ fontWeight: 600, mb: 0 }}>
                            {uploadedFileInfo.name}
                        </Typography>
                        <Typography sx={{ mb: 1 }}>
                            {Math.floor(uploadedFileInfo.size / 1000)} Kb
                        </Typography>
                    </>
                )}
                {uploadProgress < 1 && (
                    <LinearProgress
                        variant={progressUnknown ? undefined : 'determinate'}
                        value={progressUnknown ? undefined : uploadProgress * 100}
                    />
                )}
            </PreviewContent>
            {!!resetUpload && (
                <DeleteWrapper>
                    {!!uploadedPhotoUrl && (
                        <IconButton onClick={resetUpload}>
                            <DeleteIcon />
                        </IconButton>
                    )}
                </DeleteWrapper>
            )}
        </PreviewBox>
    )
}

export default PhotoPreview
