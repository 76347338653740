import React from 'react'
import useEnv from '../mixins/use-env'
import routeBuilder from '../routes'
import { Page, Text, Image, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#fff',
        fontSize: 16,
    },
    row: {
        flexDirection: 'row',
    },
    cell: {
        border: '1px solid black',
        padding: 5,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
})

interface DataI {
    orderNo: string
    productType: string
    defectPhotoUrl: string
    description: string
    pinpoint: Pinpoint
    pinpointImageUrl: string
    contactForm: ContactForm
}
interface Pinpoint {
    x: number
    y: number
}
interface ContactForm {
    [index: string]: string
}

// Create Document Component
const MyDocument = ({ data, logoUrl }: { data: DataI; logoUrl: string }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.row}>
                <View style={styles.section}>
                    <Text>Order No: {data.orderNo}</Text>
                    <Text style={{ marginBottom: 10 }}>
                        Date:{' '}
                        {new Date().toLocaleString('de', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            timeZone: 'utc',
                        })}
                    </Text>
                    {Object.keys(data.contactForm).map((key) => (
                        <Text>
                            {key}: {data.contactForm[key]}
                        </Text>
                    ))}
                </View>
                <View style={{ ...styles.section, width: '30%' }}>
                    <Image src={logoUrl} />
                </View>
            </View>
            <View style={styles.row}>
                <View style={{ ...styles.section, border: '1px solid black' }}>
                    <View style={{ marginBottom: 16 }}>
                        <Text>Item: {data.productType}</Text>
                        <Text>Price: 110€</Text>
                    </View>
                    <View style={{ ...styles.row, alignItems: 'center', gap: 10 }}>
                        <Image src={data.defectPhotoUrl} style={{ width: '30%' }} />
                        <Image src={data.pinpointImageUrl} style={{ width: '30%' }} />
                    </View>
                </View>
            </View>
            <View style={{ borderBottom: '1px dashed black', margin: '20px 0' }}></View>
            <View style={{ ...styles.row, alignItems: 'center', margin: 10, gap: 10 }}>
                <View>
                    <Text style={{ margin: '10px 0 0 10px' }}>
                        Kite Owner: {data.contactForm.Name}
                    </Text>
                    <Text style={{ margin: 10 }}>Order No: {data.orderNo}</Text>
                </View>
                <Image src={data.defectPhotoUrl} style={{ width: '20%' }} />
                <Image src={data.pinpointImageUrl} style={{ width: '20%' }} />
            </View>
            {/* <View style={{ ...styles.row, justifyContent: 'center', gap: 30 }}>
                <View style={{ flexDirection: 'row' }}>
                    <View
                        style={{
                            border: '1px solid black',
                            width: 20,
                            height: 20,
                            marginRight: 10,
                        }}
                    ></View>
                    <Text>Funky</Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <View
                        style={{
                            border: '1px solid black',
                            width: 20,
                            height: 20,
                            marginRight: 10,
                        }}
                    ></View>
                    <Text>Functional</Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <View
                        style={{
                            border: '1px solid black',
                            width: 20,
                            height: 20,
                            marginRight: 10,
                        }}
                    ></View>
                    <Text>Invisible</Text>
                </View>
            </View>
            <View style={{ ...styles.section, ...styles.row, gap: 10 }}>
                <View style={{ flexGrow: 1, flexShrink: 0, width: '30%', alignSelf: 'stretch' }}>
                    <Text>Quote</Text>
                    <View style={styles.row}>
                        <View style={{ ...styles.cell, width: '70%' }}>
                            <Text>Service</Text>
                        </View>
                        <View style={{ ...styles.cell, width: '30%' }}>
                            <Text>Est</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={{ ...styles.cell, height: 120, width: '70%' }}></View>
                        <View style={{ ...styles.cell, width: '30%' }}></View>
                    </View>
                </View>
                <View style={{ flexGrow: 1, flexShrink: 0, width: '30%', alignSelf: 'stretch' }}>
                    <Text>Time</Text>
                    <View style={styles.row}>
                        <View style={{ ...styles.cell, height: 30, width: '50%' }}></View>
                        <View style={{ ...styles.cell, width: '50%' }}></View>
                    </View>
                    <View style={styles.row}>
                        <View style={{ ...styles.cell, height: 30, width: '50%' }}></View>
                        <View style={{ ...styles.cell, width: '50%' }}></View>
                    </View>
                    <View style={styles.row}>
                        <View style={{ ...styles.cell, height: 30, width: '50%' }}></View>
                        <View style={{ ...styles.cell, width: '50%' }}></View>
                    </View>
                    <View style={styles.row}>
                        <View style={{ ...styles.cell, height: 30, width: '50%' }}></View>
                        <View style={{ ...styles.cell, width: '50%' }}></View>
                    </View>
                    <View style={styles.row}>
                        <View style={{ ...styles.cell, height: 30, width: '50%' }}></View>
                        <View style={{ ...styles.cell, width: '50%' }}></View>
                    </View>
                </View>
                <View style={{ flexGrow: 1, flexShrink: 0, width: '30%', alignSelf: 'stretch' }}>
                    <Text>Initials</Text>
                    <View
                        style={{
                            border: '1px solid black',
                            gap: 5,
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            padding: '24 10',
                        }}
                    >
                        <View style={{ ...styles.row, gap: 5 }}>
                            <Text>booked IN</Text>
                            <View style={{ ...styles.cell, width: 60, height: 30 }}></View>
                        </View>
                        <View style={{ ...styles.row, gap: 5 }}>
                            <Text>repaired</Text>
                            <View style={{ ...styles.cell, width: 60, height: 30 }}></View>
                        </View>
                        <View style={{ ...styles.row, gap: 5 }}>
                            <Text>booked OUT</Text>
                            <View style={{ ...styles.cell, width: 60, height: 30 }}></View>
                        </View>
                    </View>
                </View>
            </View>
            <View style={{ ...styles.row, justifyContent: 'center', gap: 30, marginBottom: 10 }}>
                <View style={{ flexDirection: 'row' }}>
                    <View
                        style={{
                            border: '1px solid black',
                            width: 20,
                            height: 20,
                            marginRight: 10,
                        }}
                    ></View>
                    <Text>Post</Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <View
                        style={{
                            border: '1px solid black',
                            width: 20,
                            height: 20,
                            marginRight: 10,
                        }}
                    ></View>
                    <Text>JB Llanberis</Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <View
                        style={{
                            border: '1px solid black',
                            width: 20,
                            height: 20,
                            marginRight: 10,
                        }}
                    ></View>
                    <Text>JB Capel Curig</Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <View
                        style={{
                            border: '1px solid black',
                            width: 20,
                            height: 20,
                            marginRight: 10,
                        }}
                    ></View>
                    <Text>Collect</Text>
                </View>
            </View> */}
        </Page>
    </Document>
)

const Playground: React.FC = () => {
    const { isProd } = useEnv()
    if (isProd) {
        location.href = routeBuilder.base()
    }

    const data = {
        orderNo: '123456',
        defectPhotoUrl: 'https://ucarecdn.com/154dc59b-2555-4cd3-8cd7-67dc42b3d2c0/',
        productType: 'Slingshot Fuel 13m',
        description: 'args.description',
        pinpoint: { x: 0.2, y: 0.5 },
        pinpointImageUrl: 'https://ucarecdn.com/57ce3830-961b-4e7b-b4d1-1931f71d9e7a/',
        contactForm: {
            Name: 'Jane Smith',
            Phone: '678437642',
            'E-Mail': 'marc@brakeable.com',
        },
    }

    return (
        <>
            <PDFViewer width="100%" height="100%">
                <MyDocument
                    data={data}
                    logoUrl="https://ucarecdn.com/faa9ee51-eaf7-44f7-80b5-d498cb2435cf/"
                />
            </PDFViewer>
        </>
    )
}

export default Playground
