import React from 'react'
import ImageList from '@mui/material/ImageList'
import Submission from '../../../../../../../shared/types/product/dashboard-submission'
import SubmissionId from '../../../../../../../shared/types/submission/id'
import createUploadcareImageSrc from '../../../../../../mixins/create-uploadcare-image-src'
import HighlightedSubmissionId from '../../../../../../../shared/types/submission/highlighted-submissions-id'
import InspectionImageTile from '../../../../common/inspection-image-tile'

const DesktopInspectionImageGrid = ({
    submissions,
    openSubmission,
    highlightedSubmissionId,
    refs,
    highlightSubmission = () => {},
}: {
    submissions: Array<Submission>
    openSubmission: (submissionId: SubmissionId) => void
    highlightedSubmissionId?: HighlightedSubmissionId
    highlightSubmission?: (arg?: HighlightedSubmissionId) => void
    refs: React.MutableRefObject<any[]>
}) => {
    const rowHeight = 320
    return (
        <ImageList cols={4} rowHeight={rowHeight} sx={{ width: '100%' }}>
            {submissions.map((submission, index) => (
                <InspectionImageTile
                    ref={refs.current[index]}
                    key={submission.id}
                    onClick={() => openSubmission(submission.id)}
                    onMouseEnter={() => highlightSubmission({ id: submission.id, source: 'list' })}
                    onMouseLeave={() => highlightSubmission(undefined)}
                    imageUrl={createUploadcareImageSrc(submission.image, {
                        preview: '500x660',
                    })}
                    isUnseenByUser={submission.unseenByUser}
                    labels={submission.labels}
                    isFadedOut={
                        highlightedSubmissionId?.id !== submission.id &&
                        highlightedSubmissionId?.source === 'marker'
                    }
                    inspectionOrigin={submission.inspectionOrigin}
                />
            ))}
        </ImageList>
    )
}

export default DesktopInspectionImageGrid
