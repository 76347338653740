import React from 'react'
import T from '../typography/t'
import styled from 'styled-components'

const VisualWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const SubtleFullPageLoadingScreen = ({
    duration = 0,
    isLoading,
    children,
}: {
    duration?: number
    isLoading: boolean
    children: React.ReactNode
}) => {
    const [isDone, setIsDone] = React.useState(false)

    React.useEffect(() => {
        setTimeout(() => {
            setIsDone(true)
        }, duration)
    }, [])

    if (isDone && !isLoading) return <>{children}</>

    return (
        <VisualWrapper className="blurry-background">
            <T i18nKey="common.loading" />
        </VisualWrapper>
    )
}

export default SubtleFullPageLoadingScreen
