import { deleteProduct } from 'wasp/client/operations'
import * as React from 'react'
import ProductId from '../../../../../shared/types/product/id'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import useDialog from '../../../../mixins/use-dialog'
import CompanyId from '../../../../../shared/types/company/id'
import T from '../../../../components/typography/t'

const DeleteButton = ({
    productId,
    productName,
    companyId,
}: {
    productId: ProductId
    productName: string
    companyId?: CompanyId
}) => {
    const { isOpen, open, close } = useDialog()

    const confirmDelete = () => {
        deleteProduct({ productId, companyId })
        close()
    }

    return (
        <>
            <IconButton color={'error'} onClick={open} size="small">
                <DeleteIcon fontSize="inherit" />
            </IconButton>

            <Dialog open={isOpen} onClose={close}>
                <DialogTitle>
                    <T i18nKey="company.inspector.home.products.productList.deleteProduct.headline" />
                </DialogTitle>
                <DialogContent>
                    <T
                        i18nKey="company.inspector.home.products.productList.deleteProduct.confirmText"
                        values={{ productName }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>
                        <T i18nKey="company.inspector.home.products.productList.deleteProduct.cancel" />
                    </Button>
                    <Button variant="contained" color="error" onClick={confirmDelete}>
                        <T i18nKey="company.inspector.home.products.productList.deleteProduct.delete" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DeleteButton
