import Button from '@mui/material/Button'
import styled from 'styled-components'
import 'react-multi-carousel/lib/styles.css'
import T from '../../../../../components/typography/t'

const ButtonGroupContainer = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 1;
`

const ButtonGroup = ({ next, previous, goToSlide, closeOverlay, ...rest }: any) => {
    const {
        carouselState: { currentSlide, totalItems },
    } = rest

    const onLastSlide = currentSlide + 1 === totalItems

    return (
        <ButtonGroupContainer>
            <Button variant="outlined" disabled={currentSlide === 0} onClick={() => previous()}>
                <T i18nKey="common.back" />
            </Button>
            <Button variant="contained" onClick={onLastSlide ? closeOverlay : () => next()}>
                {onLastSlide ? <T i18nKey="common.go" /> : <T i18nKey="common.next" />}
            </Button>
        </ButtonGroupContainer>
    )
}

export default ButtonGroup
