import * as React from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { useTranslation } from 'react-i18next'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'
import 'dayjs/locale/de'
import 'dayjs/locale/en'

export default function InspectorDateTimePicker(props: any) {
    const t = useTranslation()
    const locale = t[1]?.language ?? 'en-gb'
    const localeShort = locale.substring(0, 2)

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localeShort}>
            <DemoContainer components={['DateTimePicker']}>
                <DateTimePicker
                    ampm={false}
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: null,
                    }}
                    {...props}
                />
            </DemoContainer>
        </LocalizationProvider>
    )
}
