import styled from 'styled-components'
import InspectorCustomerPreview from '../../../../components/inspector-customer-preview'
import DevicePreview from '../../../../components/device-preview'
import CopyToClipboard from '../../../../components/copy-to-clipboard'
import T from '../../../../components/typography/t'

const Wrapper = styled.div`
    display: flex;
`

const LeftHandSide = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4em;
`

const RightHandSide = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

const NoSubmissionsHelper = ({
    productImgUrl,
    inspectorUrl,
}: {
    productImgUrl: string
    inspectorUrl: string
}) => {
    return (
        <Wrapper>
            <LeftHandSide>
                <T
                    i18nKey="company.inspector.productDashboard.noSubmissions.title"
                    variant="h5"
                    component="h4"
                    color="primary.dark"
                    align="center"
                    mb={1}
                />
                <T
                    i18nKey="company.inspector.productDashboard.noSubmissions.subTitle"
                    variant="body1"
                    component="p"
                    color="grey.400"
                    align="center"
                    mb={2}
                />
                <CopyToClipboard
                    label={
                        <T i18nKey="company.inspector.productDashboard.noSubmissions.copyInspectionLink" />
                    }
                    link={inspectorUrl}
                    copiedLabel={
                        <T i18nKey="company.inspector.productDashboard.noSubmissions.copied" />
                    }
                />
            </LeftHandSide>
            <RightHandSide>
                <DevicePreview scale={0.5}>
                    <InspectorCustomerPreview imageUrl={productImgUrl} />
                </DevicePreview>
            </RightHandSide>
        </Wrapper>
    )
}

export default NoSubmissionsHelper
