import React from 'react'
import SubmissionId from '../../../../../../shared/types/submission/id'
import useUrlQuery from '../../../../../mixins/use-url-query'

const SubmissionDialogWrapper: React.FC<{
    onOpenSubmission: (submissionId: SubmissionId) => void
    children: (props: {
        openedSubmissionId?: SubmissionId
        setOpenedSubmissionId: (subId?: SubmissionId) => void
    }) => React.ReactNode
}> = ({ children, onOpenSubmission }) => {
    const [submissionIdFromUrl, setSubmissionIdInUrl] = useUrlQuery('submissionId')
    const [openedSubmissionId, setOpenedSubmissionIdState] = React.useState<
        SubmissionId | undefined
    >(submissionIdFromUrl() ? parseInt(submissionIdFromUrl()!) : undefined)

    const setOpenedSubmissionId = (submissionId?: SubmissionId) => {
        setSubmissionIdInUrl(submissionId?.toString())
        setOpenedSubmissionIdState(submissionId)
        if (submissionId) {
            onOpenSubmission(submissionId)
        }
    }

    return children({ openedSubmissionId, setOpenedSubmissionId })
}

export default SubmissionDialogWrapper
