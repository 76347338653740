import T, { TProps } from './t'

const InspectorStepSubtitle = ({ children, ...props }: TProps) => {
    return (
        <T variant="body2" component="h2" textAlign="center" color="grey.500" {...props}>
            {children}
        </T>
    )
}

export default InspectorStepSubtitle
