const useCopyToClipboard = () => {
    const copyToClipboard = (text: string) =>
        navigator.clipboard.writeText(text).catch((err) => {
            alert('Failed to copy: ' + err + '. Maybe missing permissions?')
        })

    return copyToClipboard
}

export default useCopyToClipboard
