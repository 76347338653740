import { ReactNode } from 'react'
import styled from 'styled-components'

const CarouselStepContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
`

const CarouselStep = ({
    Icon,
    Title,
    Lead,
}: {
    Icon: ReactNode
    Title: ReactNode
    Lead: ReactNode
}) => {
    return (
        <CarouselStepContainer>
            {Icon}
            {Title}
            {Lead}
        </CarouselStepContainer>
    )
}

export default CarouselStep
