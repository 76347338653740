import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import styled from 'styled-components'
import routeBuilder from '../../../routes'
import Title from '../../../components/typography/title'
import T from '../../../components/typography/t'
import AdminPageWrapper from '../../../components/layout/admin-page-wrapper'

const TitleContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
`

const Text = styled.div`
    width: 100%;
    text-align: center;
`

const IntroductionPage = () => {
    return (
        <AdminPageWrapper centered>
            <TitleContainer>
                <Title>INSPECTOR</Title>
                <Typography variant="h4" component="h2" ml={2}>
                    by BRAKEABLE
                </Typography>
            </TitleContainer>

            <Text>
                <T
                    i18nKey="company.onboarding.introduction.title"
                    variant="h5"
                    component="p"
                    mt={4}
                />
            </Text>

            <Box mt={4} display="flex" justifyContent="center">
                <Button component="a" href={routeBuilder.companyDashboard()} variant="contained">
                    <T i18nKey="company.onboarding.introduction.action" />
                </Button>
            </Box>
        </AdminPageWrapper>
    )
}

export default IntroductionPage
