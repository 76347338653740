import React from 'react'
import TextField from '@mui/material/TextField'
import { InspectorMultiStepFormContext } from '../../inspector-multi-step-form'
import FileUploadProgress from '../components/file-upload-progress'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../components/typography/inspector-step-subtitle'
import { useTranslation } from 'react-i18next'
import useInspectorEmailcookie from '../../../email-cookie'
import ContactIcon from '../../../../../assets/img/inspector-icons/contact-icon.svg'
import T from '../../../../../components/typography/t'

export const ContactStep = ({
    onEmailChange,
    fileUploadProgress = 0,
    initialEmail,
}: {
    onEmailChange: (mail: string) => void
    fileUploadProgress: number
    initialEmail?: string
}) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle mb={2} i18nKey="support.mobile.contact.title" Icon={ContactIcon} />
            <InspectorStepSubtitle mb={2} i18nKey="support.mobile.contact.description" />
            <TextField
                fullWidth
                label={t('support.mobile.contact.label' as any)}
                placeholder={t('support.mobile.contact.placeholder' as any)}
                type="email"
                defaultValue={initialEmail}
                onChange={(e) => onEmailChange(e.target.value)}
                helperText={t('support.mobile.contact.hint' as any)}
            ></TextField>
            {fileUploadProgress < 1 && (
                <>
                    <T i18nKey="support.mobile.contact.photoUploadLabel" mt={3} mb={1} />
                    <FileUploadProgress value={fileUploadProgress} />
                </>
            )}
        </>
    )
}

const ContactStepContext = () => {
    const { props, meta, form, ctx } = React.useContext(InspectorMultiStepFormContext)
    const [_, saveInspectorEmailInCookies] = useInspectorEmailcookie()

    const submitForm = async () => {
        await form.submitForm()
        saveInspectorEmailInCookies(form.formValues.inspectionEmail!)
        props.nextSlide()
    }

    const imageUploaded = !!form.formValues.inspectionImageUrl
    const emailProvided = !!form.formValues.inspectionEmail
    const emailRequired = ctx.isEmailMandatory

    const nextButtonEnabled = imageUploaded && (!emailRequired || emailProvided)

    return (
        <StepFrameworkWrapper
            navigationProps={{
                nextButtonDisabled: !nextButtonEnabled,
                onValidNextClick: submitForm,
                nextButtonLabelKey: 'support.mobile.navigation.submit',
            }}
        >
            <ContactStep
                onEmailChange={form.setInspectionEmail}
                initialEmail={form.formValues.inspectionEmail}
                fileUploadProgress={meta.value?.fileUpload?.progress}
            />
        </StepFrameworkWrapper>
    )
}

export default ContactStepContext
