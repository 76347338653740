import { updateBasicSettings, updateCustomerSettings } from 'wasp/client/operations'
import * as React from 'react'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import CompanySettingsViewModel from '../../../../shared/types/company/company-information-view-model'
import CardTitle from '../../../components/typography/card-title'
import T from '../../../components/typography/t'
import Divider from '@mui/material/Divider'
import Alert from '@mui/material/Alert'
import BasicSettingsForm from './form-parts/basic-settings-form'
import CustomerSettingsForm from './form-parts/customer-settings-form'
import HeaderBar from '../inspector/products/components/header-bar'
import useAuth from '../../../mixins/use-auth'

const Settings = ({ companySettings }: { companySettings: CompanySettingsViewModel }) => {
    const { isAdmin, isCompanyRepresentative } = useAuth()
    const canEditCustomerSettings =
        isAdmin || (isCompanyRepresentative && companySettings.hasCustomerSubscription)

    const saveBasicSettings = async (
        data: {
            allowGalleryUpload: boolean
            isEmailMandatory: boolean
            enableEmailNotifications: boolean
        },
        onSaved: () => void,
        onFailed: () => void
    ) => {
        try {
            await updateBasicSettings({
                companyId: companySettings.id,
                allowGalleryUpload: data.allowGalleryUpload,
                isEmailMandatory: data.isEmailMandatory,
                enableEmailNotifications: data.enableEmailNotifications,
            })
            onSaved()
        } catch (e) {
            onFailed()
        }
    }

    const saveCustomerSettings = async (
        data: {
            postInspectionStrategy: number
            allowHappySubmissions: boolean
            allowRemindMeLater: boolean
            allowContactRequests: boolean
            showDashboardAnalytics: boolean
            websiteUrl: string
        },
        onSaved: () => void,
        onFailed: () => void
    ) => {
        try {
            await updateCustomerSettings({
                companyId: companySettings.id,
                postInspectionStrategy: data.postInspectionStrategy,
                allowHappySubmissions: data.allowHappySubmissions,
                allowRemindMeLater: data.allowRemindMeLater,
                allowContactRequests: data.allowContactRequests,
                showDashboardAnalytics: data.showDashboardAnalytics,
                websiteUrl: data.websiteUrl,
            })
            onSaved()
        } catch (e) {
            onFailed()
        }
    }

    return (
        <Box>
            <HeaderBar>
                <CardTitle>
                    <T i18nKey="company.companySettings.settings.title" />
                </CardTitle>
            </HeaderBar>
            <Card>
                <CardContent>
                    <Box mb={4} px={1}>
                        <BasicSettingsForm
                            allowGalleryUpload={companySettings.settings.allowGalleryUpload}
                            isEmailMandatory={companySettings.settings.isEmailMandatory}
                            enableEmailNotifications={
                                companySettings.settings.enableEmailNotifications === 1
                            }
                            onSave={saveBasicSettings}
                        />
                    </Box>
                    <Divider />
                    {!canEditCustomerSettings && (
                        <Alert severity="info" sx={{ mt: 2 }}>
                            <T i18nKey="company.companySettings.settings.customerSettingsOnlyWarning" />
                        </Alert>
                    )}
                    <Box mt={4} px={1} sx={{ opacity: canEditCustomerSettings ? 1 : 0.4 }}>
                        <CustomerSettingsForm
                            postInspectionStrategy={companySettings.settings.postInspectionStrategy}
                            allowHappySubmissions={companySettings.settings.allowHappySubmissions}
                            allowRemindMeLater={companySettings.settings.allowRemindMeLater}
                            allowContactRequests={companySettings.settings.allowContactRequests}
                            showDashboardAnalytics={companySettings.settings.showDashboardAnalytics}
                            websiteUrl={companySettings.settings.websiteUrl}
                            canEdit={canEditCustomerSettings}
                            onSave={saveCustomerSettings}
                        ></CustomerSettingsForm>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}

export default Settings
