import * as React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import Snackbar from '@mui/material/Snackbar'
import StyledSuccessAlert from '../../../../components/alert/styled-success-alert'
import StyledErrorAlert from '../../../../components/alert/styled-error-alert'
import { useForm } from 'react-hook-form'
import T from '../../../../components/typography/t'
import { useTranslation } from 'react-i18next'

type FormData = {
    allowGalleryUpload: boolean
    isEmailMandatory: boolean
    enableEmailNotifications: boolean
}

const BasicSettingsForm: React.FC<
    FormData & { onSave: (data: FormData, onSaved: () => void, onFailed: () => void) => void }
> = ({ allowGalleryUpload, isEmailMandatory, enableEmailNotifications, onSave }) => {
    const { t } = useTranslation()
    const [wasRecentlySaved, setWasRecentlySaved] = React.useState(false)
    const [hasRecentlyFailed, setHasRecentlyFailed] = React.useState(false)
    const [isSaving, setIsSaving] = React.useState(false)

    const { register, watch, handleSubmit } = useForm<FormData>({
        defaultValues: { allowGalleryUpload, isEmailMandatory, enableEmailNotifications },
    })

    const onSubmit = (data: FormData) => {
        setIsSaving(true)
        onSave(
            data,
            () => {
                setIsSaving(false)
                setWasRecentlySaved(true)
                setTimeout(() => setWasRecentlySaved(false), 3000)
            },
            () => {
                setIsSaving(false)
                setHasRecentlyFailed(true)
                setTimeout(() => setHasRecentlyFailed(false), 3000)
            }
        )
    }

    return (
        <>
            <Box mb={2}>
                <T
                    i18nKey="company.companySettings.settings.basicSettings.photoOrigin.title"
                    variant="h5"
                    component="h2"
                />
                <T
                    i18nKey="company.companySettings.settings.basicSettings.photoOrigin.lead"
                    variant="body2"
                    component="p"
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={watch('allowGalleryUpload')}
                            {...register('allowGalleryUpload')}
                        />
                    }
                    label={
                        watch('allowGalleryUpload')
                            ? t(
                                  'company.companySettings.settings.basicSettings.photoOrigin.cameraAndGallery' as any
                              )
                            : t(
                                  'company.companySettings.settings.basicSettings.photoOrigin.cameraOnly' as any
                              )
                    }
                />
            </Box>
            <Box mb={2}>
                <T
                    i18nKey="company.companySettings.settings.basicSettings.emailMandatory.title"
                    variant="h5"
                    component="h2"
                />
                <T
                    i18nKey="company.companySettings.settings.basicSettings.emailMandatory.lead"
                    variant="body2"
                    component="p"
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={watch('isEmailMandatory')}
                            {...register('isEmailMandatory')}
                        />
                    }
                    label={
                        watch('isEmailMandatory')
                            ? t(
                                  'company.companySettings.settings.basicSettings.emailMandatory.mandatory' as any
                              )
                            : t(
                                  'company.companySettings.settings.basicSettings.emailMandatory.optional' as any
                              )
                    }
                />
            </Box>
            <Box>
                <T
                    i18nKey="company.companySettings.settings.basicSettings.emailNotifications.title"
                    variant="h5"
                    component="h2"
                />
                <T
                    i18nKey="company.companySettings.settings.basicSettings.emailNotifications.lead"
                    variant="body2"
                    component="p"
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={watch('enableEmailNotifications')}
                            {...register('enableEmailNotifications')}
                        />
                    }
                    label={
                        watch('enableEmailNotifications')
                            ? t(
                                  'company.companySettings.settings.basicSettings.emailNotifications.enabled' as any
                              )
                            : t(
                                  'company.companySettings.settings.basicSettings.emailNotifications.disabled' as any
                              )
                    }
                />
            </Box>
            <Box mt={1}>
                <Button disabled={isSaving} variant="contained" onClick={handleSubmit(onSubmit)}>
                    {isSaving
                        ? t('company.companySettings.settings.saving' as any)
                        : t('company.companySettings.settings.save' as any)}
                </Button>
            </Box>
            <Snackbar
                open={wasRecentlySaved}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <StyledSuccessAlert>
                    <T i18nKey="company.companySettings.settings.success" />
                </StyledSuccessAlert>
            </Snackbar>
            <Snackbar
                open={hasRecentlyFailed}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <StyledErrorAlert severity="error">
                    <T i18nKey="company.companySettings.settings.error" />
                </StyledErrorAlert>
            </Snackbar>
        </>
    )
}

export default BasicSettingsForm
