import styled from 'styled-components'
import theme from '../../../theme'

const LogoWrapper = styled.a`
    display: block;
    width: 48px;
    height: 48px;

    ${theme.breakpoints.down('sm')} {
        width: 32px;
        height: 32px;
        border-radius: 4px;
    }
`

export default LogoWrapper
