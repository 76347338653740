import { useParams } from 'react-router-dom'
import React from 'react'
import useUrlQuery from '../../mixins/use-url-query'
import { useSocket, useSocketListener } from 'wasp/client/webSocket'
import ConfettiExplosion from 'react-confetti-explosion'
import { type Theme, createTheme, Button, styled, ThemeProvider, Stack, Box } from '@mui/material'
import whitelabels from '../../../shared/data/whitelabel'
import {
    Page,
    Header,
    WidthContainer,
    BrandLogo,
    Content,
    Footer,
    PBB,
    PoweredBy,
    PoweredByBrakeable,
} from '../../components/layout'
import uploadcareClient from '../../mixins/uploadcare-client'
import MobilePhotoInput from './shared/components/photo-input/mobile-photo-input'
import T from '../../components/typography/t'
import PhotoPreview from './shared/components/photo-preview'

const Input = styled('input')`
    opacity: 0;
    position: absolute;
    pointer-events: none;
    width: 0;
`

const MobilePhotoUploadPage = () => {
    const { company } = useParams()
    const companyId = company?.split('-')[0]

    const whitelabel = whitelabels.find((w) => w.id === companyId)!
    const [isExploding, setIsExploding] = React.useState(false)
    const [getPhotoUploadId] = useUrlQuery('i')
    const { socket, isConnected } = useSocket()
    const photoUploadId = getPhotoUploadId()
    const [uploadProgress, setUploadProgress] = React.useState(0)
    const [uploadedFileInfo, setUploadedFileInfo] = React.useState<
        { name: string; size: number } | undefined
    >()
    const [uploadedFileUrl, setUploadedFileUrl] = React.useState<string | undefined>(undefined)

    React.useEffect(() => {
        if (!isConnected) {
            socket.connect()
        }

        return () => {
            socket.disconnect()
        }
    }, [])

    React.useEffect(() => {
        if (isConnected && photoUploadId) {
            socket.emit('joinMobileUploadRoom', photoUploadId)
        }
    }, [isConnected, photoUploadId])

    useSocketListener('peerDisconnected', () => {
        try {
            setIsExploding(true)
            setTimeout(window.close, 2000)
        } catch (e) {
            console.error(e)
        }
    })

    if (!photoUploadId) {
        return 'Error!'
    }
    if (!photoUploadId || !isConnected) {
        return 'Connecting...'
    }

    const onFileUploadFailed = () => {}

    const onProgress = ({ isComputable, value }: any) => {
        if (isComputable) {
            setUploadProgress(Math.min(value, 0.99))
        }
    }
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const photo = e.target.files?.[0]
        choosePhoto(photo)
    }

    const choosePhoto = (photo?: File) => {
        if (!photo) {
            return
        }
        resetUpload()
        setUploadedFileInfo({ name: photo.name, size: photo.size })
        setUploadProgress(0.001)

        socket.emit('startUploadingPhoto', {
            clientId: photoUploadId,
            photoName: photo.name,
            photoSize: photo.size,
        })

        uploadcareClient
            .uploadFile(photo, { onProgress })
            .then(async (file) => {
                if (file.cdnUrl === null) {
                    onFileUploadFailed()
                } else {
                    setUploadedFileUrl(file.cdnUrl)
                    socket.emit('uploadPhoto', {
                        clientId: photoUploadId,
                        photoUrl: file.cdnUrl,
                        photoName: photo.name,
                        photoSize: photo.size,
                    })
                    setUploadProgress(1)
                }
            })
            .catch(onFileUploadFailed)
        // onPhotoChosen()
    }

    const resetUpload = () => {
        setUploadProgress(0)
        setUploadedFileInfo(undefined)
        setUploadedFileUrl(undefined)
    }

    return (
        <ThemeProvider
            theme={(theme: Theme) =>
                createTheme({
                    ...theme,
                    palette: {
                        ...theme.palette,
                        ...whitelabel?.theme.palette,
                    },
                } as any)
            }
        >
            <Page>
                <Header>
                    <WidthContainer sx={{ justifyContent: 'space-between' }}>
                        <BrandLogo src={whitelabel.logo} alt={whitelabel.name} />
                    </WidthContainer>
                </Header>
                <Content sx={{ justifyContent: 'center' }}>
                    <WidthContainer>
                        {!uploadProgress && !uploadedFileUrl && (
                            <MobilePhotoInput chosePhoto={onChange} />
                        )}
                        {(uploadProgress ?? 0) > 0 && (
                            <PhotoPreview
                                uploadedPhotoUrl={uploadedFileUrl}
                                uploadedFileInfo={uploadedFileInfo}
                                uploadProgress={uploadProgress}
                                progressUnknown={false}
                            />
                        )}
                        <Stack direction="row" justifyContent="center" mt={4}>
                            {!!uploadedFileUrl && (
                                <Button component={'label'} sx={{ mt: 4 }}>
                                    <T i18nKey="serviceDesk.mobilePhotoUpload.retakeAction" />
                                    <Input
                                        type="file"
                                        accept="image/*"
                                        capture="environment"
                                        onChange={onChange}
                                        data-testid="photo-input"
                                    />
                                </Button>
                            )}
                        </Stack>
                        {isExploding && (
                            <Box
                                sx={{
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    position: 'absolute',
                                    zIndex: 1000,
                                    pointerEvents: 'none',
                                }}
                            >
                                <ConfettiExplosion
                                    width={document.body.offsetWidth}
                                    duration={4400}
                                />
                            </Box>
                        )}
                    </WidthContainer>
                </Content>
                <Footer>
                    <WidthContainer sx={{ justifyContent: 'flex-end' }}>
                        <PBB>
                            <PoweredBy>Powered by</PoweredBy>{' '}
                            <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                        </PBB>
                    </WidthContainer>
                </Footer>
            </Page>
        </ThemeProvider>
    )
}

export default MobilePhotoUploadPage
