import React from 'react'
import { productSetupRemoveBg } from 'wasp/client/operations'
import { SetupMultiStepFormContext } from '../../setup-multi-step-form'
import InspectorStepTitle from '../../../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../../../components/typography/inspector-step-subtitle'
import T from '../../../../../../../components/typography/t'
import PhotoInput from './photo-input'
import { useTranslation } from 'react-i18next'
import { UploadClient } from '@uploadcare/upload-client'
import { Stack } from '@mui/material'
import { useIsMobile } from '../../../../../../../mixins/media-query'

export const PhotoStep = ({
    onPhotoChosen,
    setOriginalImageUrl,
    onPhotoOptimized,
    onPhotoUploadFailed,
    onPhotoOptimizationFailed,
    setUseCroppedImage,
    setUploadProgress,
    uploadCareClient,
}: {
    onPhotoChosen: () => void
    setOriginalImageUrl: (url?: string) => void
    onPhotoOptimized: (url?: string) => void
    onPhotoUploadFailed: (msg: string) => void
    onPhotoOptimizationFailed: () => void
    setUseCroppedImage: (flag: boolean) => void
    setUploadProgress: (progression: number) => void
    uploadCareClient: UploadClient
}) => {
    const isMobile = useIsMobile()
    const onProgress = ({ isComputable, value }: any) => {
        if (isComputable) {
            setUploadProgress(Math.min(value, 0.99))
        }
    }

    const chosePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
        const photo = e.target.files?.[0]
        if (!photo) {
            return
        }
        setUploadProgress(0)
        setOriginalImageUrl(undefined)
        onPhotoOptimized(undefined)
        uploadCareClient
            .uploadFile(photo, { onProgress })
            .then(async (file) => {
                if (file.cdnUrl === null) {
                    onPhotoUploadFailed('Uploadcare returned null CDN URL')
                } else {
                    setOriginalImageUrl(file.cdnUrl!)
                    setUploadProgress(1)
                    const result = await productSetupRemoveBg({ originalImageUuid: file.uuid })
                    if (!result.error) {
                        const optimizedImageUrl = `https://ucarecdn.com/${result.data.fileId}/`
                        onPhotoOptimized(optimizedImageUrl)
                        setUseCroppedImage(true)
                    } else {
                        onPhotoOptimizationFailed()
                    }
                }
            })
            .catch(onPhotoUploadFailed)
        onPhotoChosen()
    }

    return (
        <>
            <InspectorStepTitle i18nKey="company.inspector.home.products.newProduct.form.photoStep.title" />
            <InspectorStepSubtitle i18nKey="company.inspector.home.products.newProduct.form.photoStep.subtitle" />
            <Stack spacing={2} mt={4} direction="row">
                {isMobile && (
                    <PhotoInput chosePhoto={chosePhoto}>
                        <T i18nKey="company.inspector.home.products.newProduct.form.photoStep.takePhotoButton" />
                    </PhotoInput>
                )}
                <PhotoInput chosePhoto={chosePhoto} allowGalleryUpload>
                    <T i18nKey="company.inspector.home.products.newProduct.form.photoStep.uploadPhotoButton" />
                </PhotoInput>
            </Stack>
        </>
    )
}

const PhotoStepContext = () => {
    const { i18n } = useTranslation()
    const { props, form, meta, error, uploadCareClient } =
        React.useContext(SetupMultiStepFormContext)
    const onPhotoChosen = () => {
        props.nextSlide()
    }

    const onPhotoUploadFailed = (err: any) => {
        console.error(err)
        meta.setMetaValue({ ...meta, fileUpload: { progress: 0 } })
        error.pushError({
            message: 'Foto konnte nicht hochgeladen werden',
            action: {
                label: 'Erneut versuchen',
                onClick: () => {
                    error.popError()
                    props.setSlide(0)
                },
            },
        })
    }

    const onPhotoOptimizationFailed = () => {
        meta.setMetaValue({ ...meta, imageOptimizationFailed: true })
    }

    const setUploadProgress = (progression: number) => {
        meta.setMetaValue({ ...meta, fileUpload: { progress: progression } })
    }

    const onPhotoOptimized = (url?: string) => {
        form.setCroppedImageUrl(url)
        meta.setMetaValue({ ...meta, imageOptimizationFailed: false })
    }

    return (
        <StepFrameworkWrapper
            navigationProps={{
                prevButtonDisabled: true,
                nextButtonDisabled:
                    !form.formValues.originalImageUrl && !meta.value?.fileUpload?.progress,
            }}
        >
            <PhotoStep
                onPhotoChosen={onPhotoChosen}
                setOriginalImageUrl={form.setOriginalImageUrl}
                onPhotoOptimized={onPhotoOptimized}
                onPhotoUploadFailed={onPhotoUploadFailed}
                onPhotoOptimizationFailed={onPhotoOptimizationFailed}
                setUploadProgress={setUploadProgress}
                setUseCroppedImage={form.setUseCroppedImage}
                uploadCareClient={uploadCareClient}
            />
        </StepFrameworkWrapper>
    )
}

export default PhotoStepContext
