import { configureQueryClient } from "wasp/client/operations";

const clientSetupfn = () => {
    configureQueryClient({
        defaultOptions: {
            queries: {
                staleTime: 1000 * 60 * 5, // 5 minutes
            },
        },
    })
}

export default clientSetupfn
