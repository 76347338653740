import React from 'react'
import TextField from '@mui/material/TextField'
import { InspectorMultiStepFormContext } from '../../inspector-multi-step-form'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../components/typography/inspector-step-subtitle'
import { useTranslation } from 'react-i18next'

export const ContextDescriptionStep = ({
    onContextDescriptionChange,
    initialContextDescription,
}: {
    onContextDescriptionChange: (description: string) => void
    initialContextDescription?: string
}) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle mb={2} i18nKey="inspector.mobile.contextDescription.title" />
            <InspectorStepSubtitle
                mb={2}
                i18nKey="inspector.mobile.contextDescription.description"
            />
            <TextField
                multiline
                fullWidth
                defaultValue={initialContextDescription}
                label={t('inspector.mobile.contextDescription.label' as any)}
                placeholder={t('inspector.mobile.contextDescription.placeholder' as any)}
                minRows={5}
                maxRows={15}
                sx={{ fontSize: 16, textarea: { fontSize: 16 } }}
                onChange={(e) => onContextDescriptionChange(e.target.value)}
            ></TextField>
        </>
    )
}

const ContextDescriptionStepContext = () => {
    const { form, props } = React.useContext(InspectorMultiStepFormContext)
    const submitForm = async () => {
        await form.submitForm()
        props.nextSlide()
    }
    const imageUploaded = !!form.formValues.inspectionImageUrl
    const contextProvided = !!form.formValues.inspectionContextDescription
    const nextButtonEnabled = contextProvided && imageUploaded

    return (
        <StepFrameworkWrapper
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled: !nextButtonEnabled,
                onValidNextClick: submitForm,
                nextButtonLabelKey: 'common.submit',
            }}
        >
            <ContextDescriptionStep
                onContextDescriptionChange={form.setInspectionContextDescription}
                initialContextDescription={form.formValues.inspectionContextDescription}
            />
        </StepFrameworkWrapper>
    )
}

export default ContextDescriptionStepContext
