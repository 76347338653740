import vaudeProductCategoryTree from '../products/vaude-products'
import { Field, FieldType, WhitelabelStatus, type Whitelabel } from './types'
import vaudeRepairGuides from '../repair-guides/vaude-repair-guides'
import repairServices from '../repair-services/index'

const contactFields: Field[][] = [
    [
        {
            key: 'Firstname',
            label: 'serviceDesk.shared.contactStep.form.firstnameLabel',
            type: FieldType.TextField,
            params: { required: true },
        },
        {
            key: 'Lastname',
            label: 'serviceDesk.shared.contactStep.form.lastnameLabel',
            type: FieldType.TextField,
            params: { required: true },
        },
    ],
    [
        {
            key: 'Email',
            label: 'serviceDesk.shared.contactStep.form.emailLabel',
            type: FieldType.TextField,
            params: { required: true },
            fieldProps: { type: 'email' },
        },
    ],
    [
        {
            key: 'Address',
            label: 'serviceDesk.shared.contactStep.form.addressLabel',
            type: FieldType.TextField,
            params: { required: true },
        },
    ],
    [
        {
            key: 'ZipCode',
            label: 'serviceDesk.shared.contactStep.form.zipCodeLabel',
            type: FieldType.TextField,
            params: { required: true },
            fieldProps: {
                type: 'number',
                fieldControlSx: { width: 150, flexShrink: 0 },
            },
        },
        {
            key: 'City',
            label: 'serviceDesk.shared.contactStep.form.cityLabel',
            type: FieldType.TextField,
            params: { required: true },
        },
    ],
]
/*
    TODO:
    - Adjust ID
    - Adjust name (variable and brand name)
    - Adjust theme
    - Adjust logo
    - Adjust repair guides DE / EN
    - Adjust repair services DE / EN
    - Define product category tree
    - Define outro links
    - Adjust plugin config (email recipients)
*/
const vaudeWhitelabel: Whitelabel = {
    id: '7s9l1b',
    status: WhitelabelStatus.InternalOnly,
    name: 'Patagonia',
    theme: {
        palette: {
            primary: {
                main: '#000000',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#000000',
                contrastText: '#ffffff',
            },
            getCrosshairColors: () => ({ stroke: '#000000', fill: '#ffffff' }),
            getIllustrationColors: () => ({ primary: '#000000', accent: '#DC5D3C' }),
            getProgressbarColor: () => '#DC5D3C',
            getTextColor: () => '#000000',
        },
    },
    logo: 'https://ucarecdn.com/51cfdf8a-e4ab-439c-8f8d-ac2b73ddd63a/',
    links: {
        storeFinder: 'https://www.vaude.com/de/de/haendlersuche.html',
    },
    repairGuides: vaudeRepairGuides,
    repairServices: repairServices,
    alterationServices: [],
    productCategoryTree: vaudeProductCategoryTree,
    config: {
        repair: {
            storeFinderIframeUrl:
                'https://brandwidgets.outtra.com/brand-widget/vaude-23/shop-map/?outtrabrandId=158&language=',
            contactForm: {
                fields: contactFields,
            },
            activateCostEstimation: true,
            includeDiyGuides: true,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [
                    {
                        href: 'https://www.vaude.com',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: ['marc@brakeable.com', 'stefan@brakeable.com'],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
        ufixit: {
            outro: {
                assistanceIframeUrl:
                    'https://brandwidgets.outtra.com/brand-widget/vaude-23/shop-map/?outtrabrandId=158&language=',
            },
        },
    },
}

export default vaudeWhitelabel
