import Carousel from 'react-multi-carousel'
import styled from 'styled-components'
import ProductSelectionIcon from '../../../../../assets/img/inspector-icons/product-selection-icon.svg'
import CrosshairIcon from '../../../../../assets/img/inspector-icons/crosshair-icon.svg'
import CameraIcon from '../../../../../assets/img/inspector-icons/camera-icon.svg'
import DefectDescIcon from '../../../../../assets/img/inspector-icons/description-icon.svg'
import HistoryIcon from '../../../../../assets/img/inspector-icons/history-icon.svg'
import ButtonGroup from './button-group'
import CarouselStepTitle from './carousel-step-title'
import CarouselStepLead from './carousel-step-lead'
import CarouselStep from './carousel-step'
import CarouselStepIcon from './carousel-step-icon'
import 'react-multi-carousel/lib/styles.css'
import './style.scss'

const StyledCarousel = styled(Carousel)`
    width: 300px;
    max-width: 100%;
`

const responsive = {
    all: {
        breakpoint: { max: 10000, min: 0 },
        items: 1,
    },
}

const IntroCarousel = ({ closeOverlay }: { closeOverlay: () => void }) => {
    return (
        <StyledCarousel
            showDots={true}
            responsive={responsive}
            keyBoardControl={true}
            removeArrowOnDeviceType={['all']}
            dotListClass="intro-carousel-dot-list"
            customButtonGroup={<ButtonGroup closeOverlay={closeOverlay} />}
        >
            <CarouselStep
                Icon={<CarouselStepIcon src={ProductSelectionIcon} alt="" />}
                Title={<CarouselStepTitle i18nKey="publicCompany.introCarousel.s1.title" />}
                Lead={<CarouselStepLead i18nKey="publicCompany.introCarousel.s1.lead" />}
            />
            <CarouselStep
                Icon={<CarouselStepIcon src={CrosshairIcon} alt="" />}
                Title={<CarouselStepTitle i18nKey="publicCompany.introCarousel.s2.title" />}
                Lead={<CarouselStepLead i18nKey="publicCompany.introCarousel.s2.lead" />}
            />
            <CarouselStep
                Icon={<CarouselStepIcon src={CameraIcon} alt="" />}
                Title={<CarouselStepTitle i18nKey="publicCompany.introCarousel.s3.title" />}
                Lead={<CarouselStepLead i18nKey="publicCompany.introCarousel.s3.lead" />}
            />
            <CarouselStep
                Icon={<CarouselStepIcon src={DefectDescIcon} alt="" />}
                Title={<CarouselStepTitle i18nKey="publicCompany.introCarousel.s4.title" />}
                Lead={<CarouselStepLead i18nKey="publicCompany.introCarousel.s4.lead" />}
            />
            <CarouselStep
                Icon={<CarouselStepIcon src={HistoryIcon} alt="" />}
                Title={<CarouselStepTitle i18nKey="publicCompany.introCarousel.s5.title" />}
                Lead={<CarouselStepLead i18nKey="publicCompany.introCarousel.s5.lead" />}
            />
        </StyledCarousel>
    )
}

export default IntroCarousel
