import React from 'react'
import Coord from '../../../../shared/types/common/coord'
import type RepairService from '../../../../shared/data/repair-services/types'
import { RepairGuide } from '../../../../shared/data/repair-guides/types'
import { type Whitelabel } from '../../../../shared/data/whitelabel/types'
import { ProductCategoryNode, ProductTypeId } from '../../../../shared/data/products/types'
import { MultiStepFormContextType, getDefaultContextValues } from '../shared/context-types'

export type WhitelabelWithRepairConfig = Whitelabel & {
    config: Required<Pick<Whitelabel['config'], 'repair'>> & Omit<Whitelabel['config'], 'repair'>
}

export enum RepairType {
    INVISIBLE = 1,
    VISIBLE = 2,
    FUNKY = 3,
}

export type StepCtx = {
    repairServices: RepairService[]
    repairGuides: RepairGuide[]
    productCategoryTree: ProductCategoryNode
    whitelabel: WhitelabelWithRepairConfig
    getRepairGuideSuggestions: () => void
    getRepairServiceAssignments: () => void
    isIframe: boolean
}

export type FormValues = {
    selectedProduct?: ProductTypeId
    selectedProductView: number
    aiSelectedServices: number[]
    aiSelectedRepairGuides: number[]
    acceptedServices: number[]
    description?: string
    repairType?: RepairType
    uploadedPhotoUrl?: string
    pinpoint?: Coord
    selectedComponent?: string
    contactFormValues: any
}

export const RepairMultiStepFormContext = React.createContext<
    MultiStepFormContextType<StepCtx, FormValues>
>(getDefaultContextValues())
