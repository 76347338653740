import React from 'react'
import Stack from '@mui/material/Stack'
import Dialog from '@mui/material/Dialog'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import SubmissionDetailT from '../../../../../shared/types/product/submission-detail'
import { useIsMobile } from '../../../../mixins/media-query'
import SubmissionDialog from './submission-dialog'
import SubmissionId from '../../../../../shared/types/submission/id'
import styled from 'styled-components'
import routeBuilder from '../../../../routes'
import { getSurroundingElementIds, rotateArrayById } from './array-helper'
import SubmissionCarousel, { CarouselRef } from './submission-carousel'
import NewBadge from '../../../../components/badge/new-badge'

const TitleBar = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledTitle = styled(DialogTitle)`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: calc(100% - 64px);
`

const SubmissionDetail = ({
    submissions,
    setOpenedSubmissionId,
    close,
    openedSubmissionId,
    deleteSubmission,
}: {
    submissions: SubmissionDetailT[]
    setOpenedSubmissionId: (submissionId: SubmissionId) => void
    close: () => void
    openedSubmissionId?: SubmissionId
    productImgUrl?: string
    deleteSubmission?: (submissionId: SubmissionId) => void
}) => {
    const isMobile = useIsMobile()
    const [rotatedSubmissions, setRotatedSubmissions] = React.useState<SubmissionDetailT[] | null>(
        null
    )
    const carouselRef = React.useRef<CarouselRef>(null)

    const previous = () => {
        if (!carouselRef.current) return
        carouselRef.current.previous()
    }
    const next = () => {
        if (!carouselRef.current) return
        carouselRef.current?.next()
    }

    React.useEffect(() => {
        if (openedSubmissionId) {
            setRotatedSubmissions(rotateArrayById(submissions, openedSubmissionId))
        }
    }, [])

    if (!openedSubmissionId || !rotatedSubmissions) {
        return null
    }

    const surroundingSubmissionIds = getSurroundingElementIds<SubmissionId>(
        rotatedSubmissions.map((s) => s.id),
        openedSubmissionId!
    )

    return (
        <Dialog
            open={!!openedSubmissionId}
            onClose={close}
            fullWidth
            maxWidth="md"
            fullScreen={isMobile}
        >
            <Box
                sx={{
                    position: 'absolute',
                    zIndex: 1,
                    right: 16,
                    top: 8,
                    background: '#fff',
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={{
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <SubmissionCarousel
                ref={carouselRef}
                afterSlideChange={(dataset) =>
                    setOpenedSubmissionId(parseInt(dataset.submissionId!))
                }
            >
                {rotatedSubmissions.map((submission) => (
                    <div key={submission.id} data-submission-id={submission.id}>
                        {surroundingSubmissionIds.includes(submission.id) ? (
                            <div style={{ maxHeight: 'calc(100vh - 64px)' }}>
                                <TitleBar>
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: 16,
                                            transform: 'translateY(-50%)',
                                        }}
                                    >
                                        <NewBadge show={submission.unseenByUser} positionRelative />
                                    </Stack>
                                    <StyledTitle>
                                        <IconButton onClick={previous}>
                                            <NavigateBeforeIcon />
                                        </IconButton>
                                        <Box mx={1} maxWidth="100%">
                                            <Link
                                                href={routeBuilder.productDashboardLink(
                                                    submission.productId
                                                )}
                                                maxWidth="100%"
                                                display="inline-block"
                                                textAlign="center"
                                            >
                                                {submission.productName} #{submission.id}
                                            </Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="grey.400"
                                                component="p"
                                                textAlign="center"
                                            >
                                                {submission.createdAt}
                                            </Typography>
                                        </Box>
                                        <IconButton onClick={next}>
                                            <NavigateNextIcon />
                                        </IconButton>
                                    </StyledTitle>
                                </TitleBar>
                                <SubmissionDialog
                                    submission={submission}
                                    deleteSubmission={deleteSubmission}
                                ></SubmissionDialog>
                            </div>
                        ) : null}
                    </div>
                ))}
            </SubmissionCarousel>
        </Dialog>
    )
}

export default SubmissionDetail
