export default [
    {
        id: 1,
        title: 'Reparatur der Klettplatte',
        description: 'z.B. Kuriertasche oder Velocity',
        translations: {
            en: 'Repair of the velcro plate',
            de: 'Reparatur der Klettplatte',
        },
        price: [23],
    },
    {
        id: 2,
        title: 'Austausch / Reparatur des Schneefangs',
        description: 'Bike- oder Sport-Packer',
        translations: {
            en: 'Replacement / repair of the snow guard',
            de: 'Austausch / Reparatur des Schneefangs',
        },
        price: [18],
    },
    {
        id: 3,
        title: 'Austausch gebrochener Schnalle',
        description: '',
        translations: {
            en: 'Replacement of broken buckle',
            de: 'Austausch gebrochener Schnalle',
        },
        price: [8],
    },
    {
        id: 4,
        title: 'Radwechsel "Shuttle',
        description: '',
        translations: {
            en: 'Wheel change "Shuttle',
            de: 'Radwechsel "Shuttle',
        },
        price: [50],
    },
    {
        id: 5,
        title: 'Reparatur defekter Schweißnaht',
        description: '',
        translations: {
            en: 'Repair of defective weld',
            de: 'Reparatur defekter Schweißnaht',
        },
        price: [12],
    },
    {
        id: 6,
        title: 'Verschließen von Löchern / Rissen / Scheuerstellen',
        description: '',
        translations: {
            en: 'Closing of holes / tears / scuff marks',
            de: 'Verschließen von Löchern / Rissen/ Scheuerstellen',
        },
        price: [12],
    },
    {
        id: 7,
        title: 'Ersetzen eines Druckknopfs Ultimate Lenkertasche',
        description: '',
        translations: {
            en: 'Replacement of a press button Ultimate Lenkertasche',
            de: 'Ersetzen eines Druckknopfs Ultimate Lenkertasche',
        },
        price: [8],
    },
    {
        id: 8,
        title: 'Austausch der oberen Quick-Lock-Halterung',
        description: 'z.B. bei Schienenbruch',
        translations: {
            en: 'Replacement of the upper Quick-Lock-System',
            de: 'Austausch der oberen Quick-Lock-Halterung',
        },
        price: [20],
    },
    {
        id: 9,
        title: 'Nacharbeit der Nähnaht',
        description: '',
        translations: {
            en: 'Post-processing of the sewing seam',
            de: 'Nacharbeit der Nähnaht',
        },
        price: [8],
    },
    {
        id: 10,
        title: 'Austausch der inneren Versteifungsplatte bei Office-Bag (einfach)',
        description: '',
        translations: {
            en: 'Replacement of the inner stiffening plate for the Office Bag (single)',
            de: 'Austausch der inneren Versteifungsplatte bei Office-Bag (einfach)',
        },
        price: [14],
    },
    {
        id: 11,
        title: 'Austausch der inneren Versteifungsplatte bei Office-Bag (doppelt)',
        description: '',
        translations: {
            en: 'Replacement of the inner stiffening plate for the Office Bag (double)',
            de: 'Austausch der inneren Versteifungsplatte bei Office-Bag (doppelt)',
        },
        price: [30],
    },
    {
        id: 12,
        title: 'Reparatur von abgelöster Aufschweißplatte',
        description: 'z.B. abgelöster Handgriff',
        translations: {
            en: 'Repair of detached welding plate',
            de: 'Reparatur von abgelöster Aufschweißplatte',
        },
        price: [8],
    },
    {
        id: 13,
        title: 'Austausch Teleskopstange Duffle RG',
        description: '',
        translations: {
            en: 'Replacement of the telescopic handle Duffle RG',
            de: 'Austausch Teleskopstange Duffle RG',
        },
        price: [65],
    },
    {
        id: 14,
        title: 'Wassertest',
        description: '',
        translations: {
            en: 'Water test',
            de: 'Wassertest',
        },
        price: [20],
    },
]
