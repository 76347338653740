import MBadge, { BadgeProps } from '@mui/material/Badge'
import { styled as mStyled } from '@mui/material/styles'

const Badge = mStyled(MBadge)<BadgeProps>(({ theme }) => ({
    '&': {
        marginRight: theme.spacing(1),
    },
    '& .MuiBadge-badge': {
        display: 'block',
        position: 'relative',
        transform: 'none',
    },
}))

export default Badge
