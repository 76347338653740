import React from 'react'
import HighlightedSubmissionId from '../../../../shared/types/submission/highlighted-submissions-id'

const SubmissionHighlighter: React.FC<{
    entities: { id: number }[]
    children: (props: {
        highlightedSubmissionId: HighlightedSubmissionId | undefined
        setHighlightedSubmissionId: (highlightedSubmissionId?: HighlightedSubmissionId) => void
        refs: React.MutableRefObject<any>
    }) => React.ReactNode
}> = ({ children, entities }) => {
    const refs = React.useRef<any>(entities.map(() => React.createRef()))
    const [highlightedSubmissionId, setHighlightedSubmissionId] = React.useState<
        HighlightedSubmissionId | undefined
    >(undefined)
    const scrollToItem = (index: number) => {
        refs.current[index].current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
    React.useEffect(() => {
        if (!highlightedSubmissionId || highlightedSubmissionId.source === 'list') return

        const index = entities.indexOf(entities.find((s) => s.id === highlightedSubmissionId.id)!)
        if (index !== -1) {
            scrollToItem(index)
        }
    }, [highlightedSubmissionId])

    return children({ highlightedSubmissionId, setHighlightedSubmissionId, refs })
}

export default SubmissionHighlighter
