import React from 'react'
import CompanyId from '../../../../shared/types/company/id'
import nullableParseInt from '../../../mixins/nullable-parse-int'
import Inspector from './inspector'
import { useParams } from 'react-router-dom'

const InspectorPage = () => {
    const { companyId } = useParams()
    const compId = nullableParseInt(companyId) as CompanyId | undefined
    return <Inspector companyId={compId} />
}
export default InspectorPage
