import React from 'react'
import Analytics from 'analytics'
import { AnalyticsProvider } from 'use-analytics'
import inspectorAnalyticsPlugin from './inspector-analytics'
import { UnknownUserId } from '../../shared/analytics/index'
import useAuth from '../mixins/use-auth'

const analytics = Analytics({
    app: 'inspector',
    plugins: [inspectorAnalyticsPlugin()],
})

const AnalyticsContainer = ({ children }: { children: React.ReactNode }) => {
    const { user } = useAuth()
    React.useEffect(() => {
        analytics.identify(user?.id ? user.id + '' : UnknownUserId)
    }, [user])
    return <AnalyticsProvider instance={analytics}>{children}</AnalyticsProvider>
}

export default AnalyticsContainer
