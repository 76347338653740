import React from 'react'
import TextField from '@mui/material/TextField'
import { InspectorMultiStepFormContext } from '../../inspector-multi-step-form'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../components/typography/inspector-step-subtitle'
import { useTranslation } from 'react-i18next'
import HistoryIcon from '../../../../../assets/img/inspector-icons/history-icon.svg'

export const ContextDescriptionStep = ({
    onContextDescriptionChange,
    initialContextDescription,
}: {
    onContextDescriptionChange: (description: string) => void
    initialContextDescription?: string
}) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle
                mb={2}
                i18nKey="support.mobile.orderReference.title"
                Icon={HistoryIcon}
            />
            <InspectorStepSubtitle mb={2} i18nKey="support.mobile.orderReference.description" />
            <TextField
                type="text"
                fullWidth
                defaultValue={initialContextDescription}
                label={t('support.mobile.orderReference.label' as any)}
                placeholder={t('support.mobile.orderReference.placeholder' as any)}
                onChange={(e) => onContextDescriptionChange(e.target.value)}
            ></TextField>
        </>
    )
}

const ContextDescriptionStepContext = () => {
    const { form } = React.useContext(InspectorMultiStepFormContext)
    return (
        <StepFrameworkWrapper
            navigationProps={{
                nextButtonDisabled: !form.formValues.inspectionContextDescription,
            }}
        >
            <ContextDescriptionStep
                onContextDescriptionChange={form.setInspectionContextDescription}
                initialContextDescription={form.formValues.inspectionContextDescription}
            />
        </StepFrameworkWrapper>
    )
}

export default ContextDescriptionStepContext
