import React from 'react'

function useUrlQuery(name: string): [() => string | null, (value?: string) => void] {
    const setQueryParam = React.useCallback(
        (value?: string) => {
            const url = new URL(window.location.href)
            if (!!value) {
                url.searchParams.set(name, value)
            } else {
                url.searchParams.delete(name)
            }
            window.history.replaceState({}, '', url.toString())
        },
        [name]
    )

    const getQueryParam = React.useCallback(() => {
        const url = new URL(window.location.href)
        return url.searchParams.get(name)
    }, [name])

    return [getQueryParam, setQueryParam]
}

export default useUrlQuery
