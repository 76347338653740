import { useQuery, getProductsByCompany } from 'wasp/client/operations'
import CompanyId from '../../../../../shared/types/company/id'
import React from 'react'
import ProductOnboarding from './product-onboarding'
import LoadingSkeleton from '../../../../components/loading/skeleton'
import useDialog from '../../../../mixins/use-dialog'
import NewProductDialog from '../products/new-product-dialog'
import T from '../../../../components/typography/t'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Typo from '@mui/material/Typography'
import createUploadcareImageSrc from '../../../../mixins/create-uploadcare-image-src'
import { useIsMobile } from '../../../../mixins/media-query'
import AddNewProduct from './add-new-product'
import ImageMarker from '../../../../components/image-marker'
import CardTitle from '../../../../components/typography/card-title'
import DeleteButton from './delete-button'
import theme from '../../../../theme'
import ShareSpeedDial from './share-speed-dial'
import NewBadge from '../../../../components/badge/new-badge'
import DescriptionBadgeWrapper from './components/description-badge-wrapper'
import MarkerWrapper from './components/marker-wrapper'
import HeaderBar from './components/header-bar'
import DifferentOriginCount from './components/different-origin-count'
import { useTranslation } from 'react-i18next'
import { Button, IconButton, InputAdornment, TextField } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { Controller, useForm } from 'react-hook-form'

export type FormData = {
    searchTerm: string
}

const Products = ({ companyId }: { companyId: CompanyId }) => {
    const isMobile = useIsMobile()
    const { t } = useTranslation()
    const { data, isFetching, error } = useQuery(getProductsByCompany, { companyId })
    const { isOpen, open, close } = useDialog()
    const { watch, resetField, control, setValue } = useForm<FormData>({
        defaultValues: {
            searchTerm: '',
        },
    })

    if (isFetching && !data) {
        return <LoadingSkeleton />
    }
    if (error || !data) {
        return (
            <div>
                {' '}
                <T i18nKey="common.error" />
            </div>
        )
    }

    const filteredProducts = data.products.filter(
        (product) =>
            product.name.toLowerCase().includes(watch('searchTerm').toLowerCase()) ||
            product.configuration?.brand
                ?.toLowerCase()
                .includes(watch('searchTerm').toLowerCase()) ||
            product.configuration?.gender
                ?.toLowerCase()
                .includes(watch('searchTerm').toLowerCase()) ||
            product.configuration?.age + '' === watch('searchTerm').toLowerCase() ||
            product.configuration?.laminate
                ?.toLowerCase()
                .includes(watch('searchTerm').toLowerCase())
    )

    return (
        <>
            {data.products.length > 0 ? (
                <>
                    <HeaderBar>
                        <CardTitle>
                            <T i18nKey="company.inspector.home.products.title" />
                        </CardTitle>

                        <Controller
                            control={control}
                            name="searchTerm"
                            render={({ field }) => {
                                return (
                                    <TextField
                                        {...field}
                                        label={t('company.common.filterbar.search.label' as any)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {!!watch('searchTerm') ? (
                                                        <IconButton
                                                            onClick={() => {
                                                                resetField('searchTerm')
                                                            }}
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton disabled>
                                                            <SearchIcon />
                                                        </IconButton>
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )
                            }}
                        />
                        <AddNewProduct openNewProductDialog={open} />
                    </HeaderBar>
                    <Stack
                        spacing={{ xs: 1, sm: 2 }}
                        direction="row"
                        useFlexGap
                        flexWrap="wrap"
                        sx={{ width: '100%' }}
                    >
                        {filteredProducts.map((product) => (
                            <Card
                                key={product.id}
                                sx={{
                                    width: isMobile ? '45%' : '22.5%',
                                    flexGrow: 2,
                                }}
                            >
                                <CardContent sx={{ position: 'relative' }}>
                                    <NewBadge
                                        show={!!product.unseenSubmissionCount}
                                        unseenCount={product.unseenSubmissionCount}
                                    />
                                    <DifferentOriginCount
                                        repairCount={product.repairCount}
                                        feedbackCount={product.feedbackCount}
                                    />

                                    <MarkerWrapper href={product.dashboardLink}>
                                        <ImageMarker
                                            imageUrl={createUploadcareImageSrc(
                                                product.productImgUrl,
                                                {
                                                    preview: '500x660',
                                                }
                                            )}
                                            markers={product.markers}
                                        />
                                    </MarkerWrapper>
                                    <DescriptionBadgeWrapper>
                                        <Typo
                                            component="a"
                                            fontWeight={!!product.unseenSubmissionCount ? 800 : 400}
                                            href={product.dashboardLink}
                                            sx={{
                                                textDecoration: 'none',
                                                color: 'inherit',
                                                ml: !!product.unseenSubmissionCount ? 1 : 0,
                                            }}
                                        >
                                            {product.configuration?.brand &&
                                                `${product.configuration?.brand} - `}
                                            {product.name}
                                        </Typo>
                                        <Box
                                            sx={{
                                                ml: 1,
                                                display: 'fled',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                background: theme.palette.grey[100],
                                                width: '1.8em',
                                                height: '1.8em',
                                                borderRadius: '50%',
                                                fontSize: '0.8em',
                                                fontWeight: 500,
                                                flexShrink: 0,
                                            }}
                                        >
                                            {product.totalSubmissionCount}
                                        </Box>
                                    </DescriptionBadgeWrapper>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'space-between' }}>
                                    <Button
                                        href={`${product.questionaireLink}?test=feature-test`}
                                        size="small"
                                        target="_blank"
                                    >
                                        <T i18nKey="company.inspector.home.products.productList.openFeatureTest" />
                                    </Button>
                                    <Button
                                        href={`${product.questionaireLink}?test=rain-test`}
                                        target="_blank"
                                        size="small"
                                    >
                                        <T i18nKey="company.inspector.home.products.productList.openRainTest" />
                                    </Button>
                                    <DeleteButton
                                        productId={product.id}
                                        productName={product.name}
                                        companyId={companyId}
                                    />
                                </CardActions>
                            </Card>
                        ))}
                    </Stack>
                    <ShareSpeedDial
                        companyOverviewLink={data.publicCompanyProductOverviewLink}
                        companySupportLink={data.publicCompanySupportOverviewLink}
                    />
                </>
            ) : (
                <ProductOnboarding openNewProductDialog={open} />
            )}
            <NewProductDialog isOpen={isOpen} handleClose={close} companyId={companyId} />
        </>
    )
}

export default Products
