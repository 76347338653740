import { Role } from '../../../shared/auth/role'
import useAuth from '../../mixins/use-auth'
import useEnv from '../../mixins/use-env'

const RoleProtector: React.FC<{
    children: React.ReactNode
    roles: Role[]
    showInDevEnv?: boolean
}> = ({ children, roles, showInDevEnv = false }) => {
    const { user } = useAuth()
    const { isProd } = useEnv()

    if ((showInDevEnv && !isProd) || (!!user && roles.includes(user.role))) {
        return children
    }

    return null
}

export default RoleProtector
