import React from 'react'
import TextField from '@mui/material/TextField'
import { SetupMultiStepFormContext } from '../setup-multi-step-form'
import InspectorStepTitle from '../../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from './components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../../components/typography/inspector-step-subtitle'
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material'
import T from '../../../../../../components/typography/t'

export const InfoStep = ({
    onProductNameChange,
    initialDescription,
    onGenderChange,
    initialGender,
    onAgeChange,
    initialAge,
    onLaminateChange,
    initialLaminate,
    onBrandChange,
    initialBrand,
}: {
    onProductNameChange: (description: string) => void
    initialDescription?: string
    onGenderChange: (gender: string) => void
    initialGender?: string
    onAgeChange: (age: number) => void
    initialAge?: number
    onLaminateChange: (laminate: string) => void
    initialLaminate?: string
    onBrandChange: (brand: string) => void
    initialBrand?: string
}) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle
                i18nKey="company.inspector.home.products.newProduct.form.infoStep.title"
                Icon={undefined}
            />
            <InspectorStepSubtitle
                mb={4}
                i18nKey="company.inspector.home.products.newProduct.form.infoStep.subtitle"
            />
            <Stack spacing={2}>
                <TextField
                    defaultValue={initialDescription}
                    label={t(
                        'company.inspector.home.products.newProduct.form.infoStep.name.label' as any
                    )}
                    placeholder={t(
                        'company.inspector.home.products.newProduct.form.infoStep.name.placeholder' as any
                    )}
                    sx={{ width: '300px', input: { fontSize: 16 } }}
                    onChange={(e) => onProductNameChange(e.target.value)}
                ></TextField>
                <TextField
                    defaultValue={initialBrand}
                    label={t(
                        'company.inspector.home.products.newProduct.form.infoStep.brand.label' as any
                    )}
                    placeholder={t(
                        'company.inspector.home.products.newProduct.form.infoStep.brand.placeholder' as any
                    )}
                    sx={{ width: '300px', input: { fontSize: 16 } }}
                    onChange={(e) => onBrandChange(e.target.value)}
                ></TextField>
                <FormControl sx={{ div: { fontSize: 16 } }}>
                    <InputLabel id="demo-simple-select-label">
                        {t(
                            'company.inspector.home.products.newProduct.form.infoStep.gender.label' as any
                        )}
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={initialGender}
                        label={t(
                            'company.inspector.home.products.newProduct.form.infoStep.gender.label' as any
                        )}
                        onChange={(e) => onGenderChange(e.target.value)}
                    >
                        <MenuItem value={'M'}>
                            <T i18nKey="company.inspector.home.products.newProduct.form.infoStep.gender.men" />
                        </MenuItem>
                        <MenuItem value={'F'}>
                            <T i18nKey="company.inspector.home.products.newProduct.form.infoStep.gender.women" />
                        </MenuItem>
                        <MenuItem value={'D'}>
                            <T i18nKey="company.inspector.home.products.newProduct.form.infoStep.gender.diverse" />
                        </MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    defaultValue={initialAge}
                    label={t(
                        'company.inspector.home.products.newProduct.form.infoStep.age.label' as any
                    )}
                    placeholder={t(
                        'company.inspector.home.products.newProduct.form.infoStep.age.placeholder' as any
                    )}
                    sx={{ width: '300px', input: { fontSize: 16 } }}
                    type="number"
                    onChange={(e) => onAgeChange(parseInt(e.target.value))}
                ></TextField>

                <FormControl sx={{ div: { fontSize: 16 } }}>
                    <InputLabel id="demo-simple-select-label">
                        {t(
                            'company.inspector.home.products.newProduct.form.infoStep.laminate.label' as any
                        )}
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={initialLaminate}
                        label={t(
                            'company.inspector.home.products.newProduct.form.infoStep.laminate.label' as any
                        )}
                        onChange={(e) => onLaminateChange(e.target.value)}
                    >
                        <MenuItem value={'2-Layer'}>
                            <T i18nKey="company.inspector.home.products.newProduct.form.infoStep.laminate.two" />
                        </MenuItem>
                        <MenuItem value={'3-Layer'}>
                            <T i18nKey="company.inspector.home.products.newProduct.form.infoStep.laminate.three" />
                        </MenuItem>
                        <MenuItem value={'2 + Liner'}>
                            <T i18nKey="company.inspector.home.products.newProduct.form.infoStep.laminate.twoLiner" />
                        </MenuItem>
                        <MenuItem value={'Otter'}>
                            <T i18nKey="company.inspector.home.products.newProduct.form.infoStep.laminate.otter" />
                        </MenuItem>
                    </Select>
                </FormControl>
            </Stack>
        </>
    )
}

const InfoStepContext = () => {
    const { form } = React.useContext(SetupMultiStepFormContext)
    return (
        <StepFrameworkWrapper
            navigationProps={{
                nextButtonDisabled: !form.formValues.productName,
            }}
        >
            <InfoStep
                onProductNameChange={form.setProductName}
                initialDescription={form.formValues.productName}
                onGenderChange={form.setGender}
                initialGender={form.formValues.gender}
                onAgeChange={form.setAge}
                initialAge={form.formValues.age}
                onLaminateChange={form.setLaminate}
                initialLaminate={form.formValues.laminate}
                onBrandChange={form.setBrand}
                initialBrand={form.formValues.brand}
            />
        </StepFrameworkWrapper>
    )
}

export default InfoStepContext
