import React from 'react'
import TextField from '@mui/material/TextField'
import { SupportMultiStepFormContext } from '../context'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../shared-steps//components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../components/typography/inspector-step-subtitle'
import { useTranslation } from 'react-i18next'

export const InfoStep = ({
    onCustomerEmailChange,
    initialValue,
}: {
    onCustomerEmailChange: (description: string) => void
    initialValue?: string
}) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle i18nKey="poc.support.emailStep.title" Icon={undefined} />
            <InspectorStepSubtitle mb={2} i18nKey="poc.support.emailStep.feedbackSubtitle" />
            <TextField
                defaultValue={initialValue}
                label={t('poc.support.emailStep.label' as any)}
                placeholder={t('poc.support.emailStep.placeholder' as any)}
                sx={{ width: '300px' }}
                onChange={(e) => onCustomerEmailChange(e.target.value)}
                type="email"
            ></TextField>
        </>
    )
}

const InfoStepContext = () => {
    const { form } = React.useContext(SupportMultiStepFormContext)
    return (
        <StepFrameworkWrapper
            navigationProps={{
                nextButtonDisabled: !form.formValues.customerEmail,
            }}
        >
            <InfoStep
                onCustomerEmailChange={form.setCustomerEmail}
                initialValue={form.formValues.customerEmail}
            />
        </StepFrameworkWrapper>
    )
}

export default InfoStepContext
