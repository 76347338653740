import React from 'react'
import PageWrapper from '../../../components/layout/page-wrapper'
import ReportWrapper from './report-wrapper'
import CompanyId from '../../../../shared/types/company/id'
import AppSuite from '../../../../shared/app-suite'
import nullableParseInt from '../../../mixins/nullable-parse-int'
import Box from '@mui/material/Box'
import { CopilotKit } from '@copilotkit/react-core'
import { CopilotSidebar } from '@copilotkit/react-ui'
import '@copilotkit/react-ui/styles.css'
import theme from '../../../theme'
import { copilotKitEndpoint } from '../../../routes'
import useAuth from '../../../mixins/use-auth'
import { useParams } from 'react-router-dom'

const RoleProtectedCopilot = ({ children }: any) => {
    const { isAdmin } = useAuth()
    return isAdmin ? (
        <CopilotKit runtimeUrl={copilotKitEndpoint}>
            <CopilotSidebar
                Header={() => (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            background: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            padding: '20px',
                            fontFamily: theme.typography.h4.fontFamily,
                            fontSize: theme.typography.h4.fontSize,
                            letterSpacing: '2px',
                        }}
                    >
                        Ronald AI
                    </Box>
                )}
            >
                {children}
            </CopilotSidebar>
        </CopilotKit>
    ) : (
        children
    )
}

const ReportPage = () => {
    const { companyId } = useParams()
    if (!companyId) {
        return null
    }
    return (
        <PageWrapper activeLogo={AppSuite.Report} companyId={nullableParseInt(companyId)}>
            <RoleProtectedCopilot>
                <ReportWrapper companyId={parseInt(companyId) as CompanyId} />
            </RoleProtectedCopilot>
        </PageWrapper>
    )
}

export default ReportPage
