import {
    useAction,
    type OptimisticUpdateDefinition,
    markSubmissionAsSeenByUser,
    useQuery,
    getProductDashboard,
} from 'wasp/client/operations'

import React from 'react'
import LoadingSkeleton from '../../../../components/loading/skeleton'
import ProductDashboard from './product-dashboard'
import ProductId from '../../../../../shared/types/product/id'
import SubmissionId from '../../../../../shared/types/submission/id'
import ProductDashboardT from '../../../../../shared/types/product/product-dashboard'

const ProductDashboardWrapper = ({ productId }: { productId: ProductId }) => {
    const {
        data: productDashboard,
        isFetching,
        error,
    } = useQuery(getProductDashboard, { productId })
    const markSubmissionAsSeenByUserOptimistically = useAction(markSubmissionAsSeenByUser, {
        optimisticUpdates: [
            {
                getQuerySpecifier: () => [getProductDashboard, { productId }],
                updateQuery: (_payload, oldData) => ({
                    ...oldData,
                    submissions: oldData?.submissions.map((sub) => ({
                        ...sub,
                        unseenByUser: sub.id === _payload.submissionId ? false : sub.unseenByUser,
                    })),
                }),
            } as OptimisticUpdateDefinition<{ submissionId: SubmissionId }, ProductDashboardT>,
        ],
    })

    if (isFetching) return <LoadingSkeleton large />
    if (error) return <div>Error: {error.message}</div>

    return (
        <ProductDashboard
            productDashboard={productDashboard!}
            markSubmissionAsSeen={markSubmissionAsSeenByUserOptimistically}
        />
    )
}

export default ProductDashboardWrapper
