const de = {
    common: {
        next: 'Weiter',
        back: 'Zurück',
        cancel: 'Abbrechen',
        submit: 'Absenden',
        go: 'Los',
        or: 'Oder',
        logout: 'Abmelden',
        loading: 'Laden...',
        error: 'Etwas ist schiefgelaufen',
        copied: 'Kopiert!',
        language: 'Sprache',
        requestTypes: {
            all: 'Alle',
            warranty: 'Garantie',
            repair: 'Reparatur',
        },
    },
    admin: {
        tatajuba: {
            title: 'Service Desk',
        },
        newCompanyRepresentative: {
            title: 'Neue*r Kund*in einladen',
            companyNameLabel: 'Firmenname',
            companyRepresentativeNameLabel: 'Mitarbeiter*in Name',
            companyRepresentativeEmailLabel: 'Mitarbeiter*in E-Mail',
            buttonLabel: 'Einladen',
        },
        unverifiedUsers: {
            title: 'Unverifizierte Benutzer*innen',
            table: {
                company: 'Firma',
                representative: 'Repräsentant',
                email: 'E-Mail',
                link: 'Einladungslink',
            },
        },
        companies: {
            title: 'Firmen',
            table: {
                id: 'Id',
                company: 'Firma',
                representative: 'Repräsentant',
                representativeEmail: 'E-Mail',
                apps: 'Apps',
                productCount: 'Anzahl Produkte',
            },
        },
    },
    company: {
        onboarding: {
            form: {
                welcome: 'Willkommen!',
                lead: 'Wir sind froh, euch an Bord zu haben.',
                subLead: 'Bitte aktiviere deinen Account:',
                email: {
                    label: 'E-Mail',
                    placeholder: 'E-Mail',
                    helperText:
                        'Bitte verwende die E-Mail, auf die du diesen Einladungslink erhalten hast.',
                },
                password: {
                    label: 'Passwort',
                    placeholder: 'Setze ein neues, sicheres Passwort',
                },
                requirements: {
                    title: 'Passwortanforderungen:',
                    length: 'Mindestens 8 Zeichen',
                    casing: 'Klein- und Grossbuchstaben',
                    number: 'Mindestens eine Zahl',
                    special: 'Mindestens ein Sonderzeichen',
                },
                action: 'Account erstellen',
            },
            introduction: {
                title: 'Willkommen auf der Inspector Platform. Ihre Reise zu einer verbesserten Produktqualität und bereicherten Nutzererlebnissen beginnt hier. Wir freuen uns, Sie an Bord zu haben, und freuen uns darauf, Ihren Erfolg zu unterstützen.',
                action: 'Los geht’s',
            },
        },
        common: {
            filterbar: {
                search: {
                    label: 'Suche',
                    placeholder:
                        "Suche nach E-Mail, Beschreibung oder Datum (Bsp. '11. November' oder 'Januar')",
                },
                unseen: {
                    showOnlyUnseen: 'Zeige nur neue Inspections',
                    showAll: 'Zeige alle Inspections',
                },
                share: 'Teilen',
            },
        },
        inspector: {
            home: {
                products: {
                    title: 'Produkte',
                    action: 'Neues Produkt anlegen',
                    productList: {
                        openFeatureTest: 'Feature Test',
                        openRainTest: 'Rain Test',
                        copyInspectorLink: {
                            copyDesktop: 'Inspector-Link kopieren',
                            copyMobile: 'Inspector-Link',
                            copied: 'Inspector-Link kopiert',
                        },
                        deleteProduct: {
                            headline: 'Bist du sicher?',
                            confirmText:
                                'Willst du das Produkt <strong>"{{productName}}"</strong> wirklich <strong>unwiderruflich</strong> löschen? Alle Daten werden gelöscht und können nicht wiederhergestellt werden.',
                            cancel: 'Abbrechen',
                            delete: 'Löschen',
                        },
                    },
                    newProduct: {
                        title: 'Neues Produkt anlegen',
                        form: {
                            name: {
                                label: 'Produktname',
                                required: 'Produktname ist erforderlich',
                            },
                            cancelButton: {
                                label: 'Abbrechen',
                            },
                            saveButton: {
                                label: 'Produkt anlegen',
                                saving: 'Speichern...',
                                validation: {
                                    nameRequired: 'Produktname ist erforderlich',
                                    imageRequired: 'Produktbild ist erforderlich',
                                },
                            },
                            photoStep: {
                                title: 'Produktbild hochladen',
                                subtitle:
                                    'Nimm ein Foto mit deiner Kamera auf oder lade ein Foto aus deiner Galerie hoch.',
                                takePhotoButton: 'Foto aufnehmen',
                                uploadPhotoButton: 'Foto hochladen',
                            },
                            infoStep: {
                                title: 'Produktinformationen',
                                subtitle: 'Gib deinem Produkt einen Namen.',
                                name: {
                                    label: 'Produktname*',
                                    placeholder: 'Produktname',
                                },
                                brand: {
                                    label: 'Marke',
                                    placeholder: 'Marke',
                                },
                                gender: {
                                    label: 'Geschlecht',
                                    men: 'Männer',
                                    women: 'Frauen',
                                    diverse: 'Divers',
                                },
                                age: {
                                    label: 'Alter',
                                    placeholder: 'Alter',
                                },
                                laminate: {
                                    label: 'Laminat',
                                    two: '2 Layer',
                                    three: '3 Layer',
                                    twoLiner: '2 + Liner',
                                    otter: 'Otter',
                                },
                            },
                            summaryStep: {
                                title: 'Zusammenfassung',
                                subtitle:
                                    'Bitte überprüfe die Angaben und bestätige die Erstellung des Produktes.',
                                loading:
                                    'Wir optimieren gerade dein Produktbild, warte bitte einen Moment...',
                                unhappy: 'Nicht zufrieden?',
                                useOriginalBtn: 'Originalbild stattdessen verwenden',
                                useOptimizedBtn: 'Optimiertes Bild verwenden',
                                nextBtn: 'Anlegen',
                            },
                        },
                    },
                },
                share: {
                    exportProductList: 'Produktliste exportieren',
                    printProductCards: 'Produktkarten drucken',
                    orderCompanyCard: 'Firmenkarte bestellen',
                    copyCompanyLink: 'Firmenübersichtslink kopieren',
                    copyCompanySupportLink: 'Firmen Reparaturlink kopieren',
                    companyLinkCopied: 'Link kopiert',
                },
                onboarding: {
                    title: 'Produkte',
                    action: 'Produkt anlegen',
                },
            },
            productDashboard: {
                backToOverview: 'Zurück zur Übersicht',
                configuration: {
                    gender: 'Geschlecht',
                    age: 'Alter',
                    laminate: 'Laminat',
                },
                keyFacts: {
                    pageViews: 'Seitenaufrufe',
                    problems: 'Probleme',
                    happySubmissionFeedback: 'Keine Anmerkung',
                    conversion: 'Conversion',
                },
                noDescription: 'Keine Beschreibung vorhanden',
                aiFilter: 'AI Filter',
                originFilter: 'Herkunft',
                detail: {
                    defectDescription: 'Defektbeschreibung',
                    contextDescription: 'Produktnutzung',
                    contact: 'Kontakt E-Mail für Rückfragen:',
                },
                noSubmissions: {
                    title: 'Noch keine Inspections',
                    subTitle:
                        'Sobald deine Nutzer:innen eine Inspection durchgeführt haben, werden sie hier angezeigt.',
                    copyInspectionLink: 'Inspector-Link kopieren',
                    copied: 'Link kopiert',
                },
            },
        },
        report: {
            title: 'Inspections',
        },
        userManagement: {
            users: {
                title: 'Users',
                action: 'Neuer User anlegen',
                userList: {
                    subtitle: 'Produkte: {{ productCount }} | Inspections: {{ inspectionCount }}',
                },
            },
        },
        support: {
            supportRequests: {
                title: 'Supportanfragen',
                kanban: {
                    open: 'Offen',
                    inProgress: 'In Bearbeitung',
                    done: 'Erledigt',
                },
                noSupportRequestsFallback: 'Du hast noch keine Supportanfragen. Gut gemacht!',
            },
        },
        companySettings: {
            account: {
                title: 'Account',
                newEmployeeForm: {
                    name: 'Name',
                    email: 'E-Mail',
                    button: 'Einladen',
                },
                logoForm: {
                    title: 'Firmenlogo hochladen',
                    helperText: 'Idealerweise ein Logo ohne Abstand zum Rand',
                    loading: 'Datei wird hochgeladen...',
                    success: 'Logo erfolgreich hochgeladen',
                    failed: 'Irgendetwas ist schiefgelaufen',
                },
            },
            settings: {
                title: 'Einstellungen',
                basicSettings: {
                    photoOrigin: {
                        title: 'Fotoherkunft',
                        lead: 'Sollen die User Fotos nur mit der Kamera aufnehmen oder auch aus der Galerie hochladen können?',
                        cameraOnly: 'Nur Kamera möglich',
                        cameraAndGallery: 'Kamera und Galerie möglich',
                    },
                    emailMandatory: {
                        title: 'E-Mail als Pflichfeld',
                        lead: 'Sollen die User ihre E-Mail-Adresse angeben müssen oder soll dies freiwillig sein?',
                        mandatory: 'E-Mail ist ein Pflichtfeld',
                        optional: 'E-Mail ist freiwillig',
                    },
                    emailNotifications: {
                        title: 'E-Mail Benachrichtigung',
                        lead: 'Willst du eine E-Mail erhalten, wenn eine neue Inspection abgegeben wurde?',
                        enabled: 'E-Mail Benarichtigungen eingeschalten',
                        disabled: 'E-Mail Benarichtigungen ausgeschalten',
                    },
                },
                customerSettingsOnlyWarning:
                    'Diese Einstellungen können nur in der Customer Version der Inspector App verwendet werden.',
                customerSettings: {
                    postInspectionRedirect: {
                        title: 'Weiterleitung nach Inspection',
                        lead: 'Soll der User nach einer Inspection auf eine spezifische Seite weitergeleitet werden?',
                        label: 'Weiterleitung nach Inspection',
                        restart: 'Startbildschirm',
                        companyPage: 'Unternehmensseite',
                        companyPageValidation: {
                            required: 'Unternehmensseite muss angegeben werden.',
                            invalid: 'Bitte eine gültige Unternehmensseite angeben.',
                        },
                    },
                    allowHappySubmissions: {
                        title: '"Keine Anmerkung" erlauben',
                        lead: 'Sollen die User die Möglichkeit haben, eine Inspection ohne Anmerkung zu speichern?',
                        enabled: '"Keine Anmerkung" möglich',
                        disabled: '"Keine Anmerkung" nicht möglich',
                    },
                    allowRemindMeLater: {
                        title: 'Später erinnern" erlauben',
                        lead: 'Sollen die User die Möglichkeit haben, sich per E-Mail an ihre Inspection erinnern zu lassen?',
                        enabled: '"Später erinnern" möglich',
                        disabled: '"Später erinnern" nicht möglich',
                    },
                    allowContactRequests: {
                        title: 'Kontaktanfragen erlauben',
                        lead: 'Sollen die User die Möglichkeit haben, Kontaktanfragen zu beantragen, damit du mit ihnen in Kontakt treten kannst?',
                        enabled: 'Kontaktanfragen möglich',
                        disabled: 'Kontaktanfragen nicht möglich',
                    },
                    showDashboardAnalytics: {
                        title: 'Analysedaten anzeigen',
                        lead: 'Willst du im Dashboard Analysedaten sehen?',
                        enabled: 'Analysedaten anzeigen',
                        disabled: 'Analysedaten nicht anzeigen',
                    },
                },
                save: 'Speichern',
                saving: 'Speichern...',
                success: 'Einstellungen gespeichert',
                error: 'Beim Speichern der Einstellungen ist ein Fehler aufgetreten',
            },
        },
    },
    publicCompany: {
        profile: {
            title: 'Product Inspection',
            lead: 'Danke, dass du uns Hilfst, unsere Produkte langlebiger zu machen. Wähle unten das zu inspizierende Produkt aus:',
            help: 'Hilfe',
        },
        introCarousel: {
            title: 'Intro zu Inspector',
            s1: {
                title: 'Produkt auswählen',
                lead: 'Wähle das Produkt aus, für das du eine Inspection durchführen möchtest.',
            },
            s2: {
                title: 'Bereich markieren',
                lead: 'Markiere den Bereich des Defektes oder des Verbesserungsvorschlags.',
            },
            s3: {
                title: 'Foto aufnehmen',
                lead: 'Nimm ein Foto von der Problemstelle auf',
            },
            s4: {
                title: 'Problem beschreiben',
                lead: 'Beschreibe den Defekt oder Verbesserungsvorschlag.',
            },
            s5: {
                title: 'Geschichte erzählen',
                lead: 'Erzähle uns die Geschichte deines Produktes und des Problems.',
            },
        },
        support: {
            title: 'Reparaturanfrage',
            lead: 'Wenn du ein Problem mit deinem Produkt hast, starte eine Reparaturanfrage, damit wir gemeinsam eine Lösung finden können.',
        },
    },
    inspector: {
        desktop: {
            title: 'Bitte öffne die INSPECTION auf deinem Handy',
            description: 'Scanne hierzu den QR-Code mit deinem Handy',
        },
        mobile: {
            navigation: {
                from: 'von',
                submit: 'Absenden',
            },
            intro: {
                title: 'Produkt Inspection',
                description:
                    'Wenn du einen Verbesserungsvorschlag oder Defekt an deinem Produkt hast, mach eine  Inspection.',
                action: 'Inspection starten',
                nothingToReport: {
                    action: 'Keine Anmerkungen',
                },
            },
            pinpoint: {
                title: 'Problemstelle markieren',
                description:
                    'Klicke auf die entsprechende Stelle im Bild, um einen <strong>Verbesserungsvorschlag</strong> oder <strong>Defekt</strong> zu markieren',
            },
            photo: {
                title: 'Problem Aufnehmen',
                description: 'Mach ein <strong>Foto</strong> von der eben markierten Stelle.',
                preview: 'Vorschau',
                action: 'Foto aufnehmen',
                reAction: 'Neues Foto aufnehmen',
                itemNotAvailable: 'Produkt gerade nicht zur Hand',
                cancelled: {
                    description:
                        'Du hast die Aufnahme abgebrochen. Wenn es daran lag, dass du Schwarz gesehen hast, liegt das möglicherweise an den Berechtigungen. Unter folgendem Link kannst du sehen, wie du diese anpassen kannst.',
                    showMe: 'Zeig mir wie',
                    dismiss: 'Schliessen',
                },
                remindMeLater: {
                    title: 'Inspection später durchführen',
                    lead: 'Du hast das Produkt gerade nicht zur Hand? Kein Problem! Wir erinnern dich später per E-Mail daran, eine Inspection durchzuführen.',
                    emailLabel: 'E-Mail',
                    datetimeLabel: 'Erinnerungszeitpunkt',
                    outro: 'Deine E-Mail wird ausschliesslich für diesen Zweck verwendet.',
                    submit: 'Erinnere mich später',
                    cancel: 'Abbrechen',
                },
            },
            description: {
                title: 'Problem beschreiben',
                description:
                    'Beschreibe deinen <strong>Defekt</strong> oder <strong>Verbesserungsvorschlag</strong>.',
                label: 'Beschreibung',
                placeholder: 'Problembeschrieb',
            },
            contextDescription: {
                title: 'Mehr Informationen',
                description:
                    'Beschreibe die <strong>Geschichte</strong> deines <strong>Produktes</strong> und <strong>Problems</strong>.',
                label: 'Beschreibung',
                placeholder: 'Produkt-/Problemgeschichte',
            },
            contact: {
                title: 'Kontakt angeben',
                description:
                    'Bitte hinterlasse uns deine E-Mail, damit wir dich für <strong>Rückfragen zu deiner Inspection</strong> kontaktieren können.',
                label: 'E-Mail',
                placeholder: 'E-Mail',
                requestContact: 'Bitte kontaktiert mich mit einer Lösung für mein Problem',
                photoUploadLabel: 'Foto-Upload',
            },
            outro: {
                title: 'Dankeschön!',
            },
        },
    },
    support: {
        desktop: {
            title: 'Bitte öffne die Reparaturanfrage auf deinem Handy',
            description: 'Scanne hierzu den QR-Code mit deinem Handy',
        },
        mobile: {
            navigation: {
                from: 'von',
                submit: 'Absenden',
            },
            intro: {
                title: 'Reparaturanfrage',
                description:
                    'Wenn du ein Problem mit deinem Produkt hast, starte eine Reparaturanfrage.',
                action: 'Anfrage starten',
                nothingToReport: {
                    action: 'Keine Anmerkungen',
                },
            },
            pinpoint: {
                title: 'Problemstelle markieren',
                description:
                    'Klicke auf die entsprechende Stelle im Bild, um einen <strong>Defekt</strong> zu markieren',
            },
            photo: {
                title: 'Problem Aufnehmen',
                description: 'Mach ein <strong>Foto</strong> von der eben markierten Stelle.',
                preview: 'Vorschau',
                action: 'Foto aufnehmen',
                reAction: 'Neues Foto aufnehmen',
                itemNotAvailable: 'Produkt gerade nicht zur Hand',
                cancelled: {
                    description:
                        'Du hast die Aufnahme abgebrochen. Wenn es daran lag, dass du Schwarz gesehen hast, liegt das möglicherweise an den Berechtigungen. Unter folgendem Link kannst du sehen, wie du diese anpassen kannst.',
                    showMe: 'Zeig mir wie',
                    dismiss: 'Schliessen',
                },
                remindMeLater: {
                    title: 'Inspection später durchführen',
                    lead: 'Du hast das Produkt gerade nicht zur Hand? Kein Problem! Wir erinnern dich später per E-Mail daran, eine Inspection durchzuführen.',
                    emailLabel: 'E-Mail',
                    datetimeLabel: 'Erinnerungszeitpunkt',
                    outro: 'Deine E-Mail wird ausschliesslich für diesen Zweck verwendet.',
                    submit: 'Erinnere mich später',
                    cancel: 'Abbrechen',
                },
            },
            description: {
                title: 'Problem beschreiben',
                description: 'Beschreibe den <strong>Defekt</strong> deines Produkts.',
                label: 'Beschreibung',
                placeholder: 'Problembeschrieb',
            },
            orderReference: {
                title: 'Auftragsreferenz eingeben',
                description:
                    'Gib deine <strong>Bestellnummer</strong> (online) oder deine <strong>Auftragsnummer</strong> (im Laden) ein.',
                label: 'Auftragsreferenz',
                placeholder: 'Bestellnummer / Auftragsnummer',
            },
            contact: {
                title: 'Kontakt angeben',
                description:
                    'Bitte hinterlasse uns deine E-Mail, damit wir dich für <strong>Rückfragen zu deiner Anfrage</strong> kontaktieren können.',
                hint: 'Verwende wenn möglich die E-Mail Adresse, die du beim Kauf angebeben hast.',
                label: 'E-Mail',
                placeholder: 'E-Mail',
                requestContact: 'Bitte kontaktiert mich mit einer Lösung für mein Problem',
                photoUploadLabel: 'Foto-Upload',
            },
            outro: {
                title: 'Dankeschön!',
            },
        },
    },

    poc: {
        support: {
            linktree: {
                title: 'Wie können wir Ihnen heute helfen?',
                subtitle: 'Wähle unten eine Option aus',
                repair: 'Reparaturanfrage',
                repairSub: 'Eine Reparaturanfrage für ein Produktproblem stellen',
                warranty: 'Garantiefall',
                warrantySub: 'Ein Problem mit Ihrem Produkt unter Garantie melden',
                feedback: 'Produkt-Feedback',
                feedbackSub: 'Vorschläge oder Kritik zu unseren Produkten mitteilen',
                diy: 'Reparaturanleitung finden',
                diySub: 'Defekte an Ihrem Produkt mit unseren DIY-Anleitungen selber lösen',
            },
            repairIntroStep: {
                title: 'Reparaturanfrage',
                description:
                    'Haben Sie einen Defekt an Ihrem Produkt? Stellen Sie hier eine Reparaturanfrage.',
                action: 'Reparaturanfrage starten',
                backAction: 'Zurück zum Start',
                multipleCapturingInfo:
                    'Um mehrere Reparaturen anzufordern, können Sie zusätzliche Anfragen am Ende anhängen.',
            },
            feedbackIntroStep: {
                title: 'Produktfeedback',
                description:
                    'Haben Sie einen Verbesserungsvorschlag oder eine Kritik an Ihrem Produkt haben, können Sie uns dies hier mitteilen.',
                action: 'Produktfeedback abgeben',
                backAction: 'Zurück zum Start',
                multipleCapturingInfo:
                    'Um Mehrere Feedbacks abzugeben, können Sie am Ende weitere Feedbacks anhängen.',
            },
            warrantyIntroStep: {
                title: 'Garantiefall melden',
                description:
                    'Haben Sie ein Problem mit Ihrem Produkt? Melden Sie hier einen Garantiefall.',
                action: 'Garantiefall melden',
                backAction: 'Zurück zum Start',
            },
            diyIntroStep: {
                title: 'Selber reparieren',
                description:
                    'Haben Sie ein Problem mit Ihrem Produkt? Finden Sie die passende Reparaturanleitung.',
                action: 'Reparaturanleitung finden',
                backAction: 'Zurück zum Start',
            },
            emailStep: {
                title: 'E-Mail',
                subtitle:
                    'Bitte geben Sie Ihre E-Mail-Adresse an, damit wir Ihre Anfrage identifizieren und Ihnen antworten können.',
                repairSubtitle:
                    'Bitte geben Sie Ihre E-Mail-Adresse an, damit wir die Bestellbestätigung und das Versandetikett senden können.',
                feedbackSubtitle:
                    'Bitte hinterlassen Sie uns Ihre E-Mail, damit wir Sie für mögliche Rückfragen kontaktieren können.',
                label: 'E-Mail-Adresse',
                placeholder: '',
            },
            productStep: {
                title: 'Für welches Produkt benötigen Sie Hilfe?',
                subtitle: '*Falls Sie mehrere Probleme haben, können Sie diese am Ende hinzufügen',
                form: {
                    productReference: 'Produktreferenz eingeben',
                },
                categories: {
                    all: 'Alle',
                    apparel: 'Bekleidung',
                    upperwear: 'Oberteile',
                    tshirt: 'T-Shirts',
                    pullover: 'Pullover',
                    hoodie: 'Hoodie',
                    longsleeve: 'Langarm Shirt',
                    tanktop: 'Tanktop',
                    top: 'Top',
                    jacket: 'Jacken',
                    bikini: 'Bikini',
                    pants: 'Hosen',
                    shorts: 'Shorts',
                    leggings: 'Leggings',
                    skiingPants: 'Skihosen',
                    accessories: 'Accessoires',
                    beanie: 'Mützen',
                    gloves: 'Handschuhe',
                    shoes: 'Schuhe',
                    hikingShoes: 'Wanderschuhe',
                    snowBoots: 'Schneestiefel',
                    sportShoes: 'Sportschuhe',
                    sneakers: 'Sneakers',
                    flipflop: 'Flip Flop',
                    hardware: 'Hardware',
                    camping: 'Camping',
                    tents: 'Zelte',
                    campingChairs: 'Campingstühle',
                    mats: 'Matten und Matratzen',
                    sleepingBags: 'Schlafsäcke',
                    hiking: 'Wandern',
                    backpack: 'Rucksäcke',
                    hikingPole: 'Wanderstöcke',
                    watersport: 'Wassersport',
                    sup: 'SUP',
                    penguin: {
                        menShellPants: 'Männer Shell Hose',
                        womenShellPants: 'Frauen Shell Hose',
                        menShellJacket: 'Männer Shell Jacke',
                        womenShellJacket: 'Frauen Shell Jacke',
                    },
                },
                views: {
                    front: 'Vorne',
                    back: 'Hinten',
                    side: 'Seite',
                    bottom: 'Unten',
                    top: 'Oben',
                    inside: 'Innen',
                },
            },
            descriptionStep: {
                title: 'Problem beschreiben',
                subtitle:
                    'Bitte beschreiben Sie das Problem mit Ihrem Produkt, damit wir Ihnen effektiv helfen können.',
                label: 'Problembeschreibung',
                placeholder: '',
            },
            PhotoStep: {
                title: 'Foto des Problems hochladen',
                QrCodeAlternativeTitle: 'QR Code Scannen',
                QrCodeAlternative:
                    'Alternativ können Sie auch den QR-Code scannen, um mit dem Handy ein Foto aufzunehmen.',
                dropzone: {
                    label: 'Foto hierhin ziehen zum Hochladen',
                    description: 'Ein Hochauflösendes, klares Foto des Problems ist von Vorteil',
                    dropLabel: 'Platziere das Foto hier',
                    button: 'Foto auswählen',
                },
                mobile: {
                    label: 'Foto aufnehmen',
                    description: 'Wähle vorzugsweise hochauflösende, klare Fotos des Problems',
                    takePhotoButton: 'Foto aufnehmen',
                    uploadPhotoButton: 'Foto hochladen',
                    retakeAction: 'Foto erneut aufnehmen',
                },
            },
            diyStep: {
                title: 'Reparaturanleitungen',
                subtitle:
                    'Basierend auf Ihrer Beschreibung könnten Sie das Problem mit den folgenden Anleitungen selbst lösen:',
                guides: {
                    howToWash: {
                        title: 'Wie wasche und trockne ich meine Membran-Jacke/-Hose?',
                    },
                    howToRepairHole: {
                        title: 'Wie repariert man ein Loch in einem gewebtem Textil?',
                    },
                    howToRepairQuickLock: {
                        title: 'Wie man das Quick-Lock-System an seiner Fahrradtasche selbst repariert',
                    },
                    clickHere: 'Klicke hier für einen Step-by-Step Guide',
                },
                skip: 'Überspringen',
                guideBy_one: 'Anleitung von',
                guideBy_other: 'Anleitungen von',
                checkoutGuideAction: 'Anleitung ansehen',
                notMatching: 'Keine passende Anleitung dabei?',
                nothingFoundSubtitle:
                    'Leider konnten wir keine passende Reparaturanleitung finden.',
                requestNewGuideAction: 'Eine neue Anleitung anfragen',
                newGuideActionRequested: 'Anfrage gesendet',
                findeRepairCenterAction: 'Expertenhilfe in der Nähe finden',
                goBackAction: 'Problembeschreibung anpassen',
                checkoutRepairOfferAction: 'Reparaturangebot ansehen',
            },
            solutionStep: {
                linktree: {
                    title: 'Reparaturoptionen',
                    diyTitle: 'Entdecken Sie unsere DIY-Lösungen',
                    diySubtitle:
                        'Versuchen Sie, das Problem selbst mit unseren Anleitungen zu beheben.',
                    servicesTitle: 'Überprüfen Sie unsere Kostenschätzung',
                    servicesSubtitle:
                        'Überprüfen Sie die geschätzten Reparaturkosten basierend auf Ihrer Fehlerbeschreibung.',
                },
                waitingForPhotoUpload: 'Das Foto wird noch hochgeladen...',
            },
            costEstimationStep: {
                title: 'Kostenschätzung',
                costRange: {
                    title: 'Geschätzte Kosten',
                    title_other: 'Reparaturkostenbereich',
                    subtitle: 'Diese geschätzten Reparaturkosten basieren auf Ihrer Beschreibung.',
                },
                assignedServices: {
                    title: 'Zugewiesene Reparaturdienste',
                    subtitle:
                        'Hier sind die potenziellen Reparaturdienste, die wir für Sie identifiziert haben.',
                },
                diyAction: 'Ich bevorzuge eine DIY-Lösung.',
                repairAction: 'Ich möchte meinen Artikel reparieren lassen',
            },
            contactStep: {
                title: 'Kontaktangaben',
                form: {
                    title: 'Persönliche Angaben',
                    nameLabel: 'Name',
                    emailLabel: 'E-Mail',
                    phoneLabel: 'Telefon',
                    maxRepairCostLabel: 'Maximale Reparaturkosten',
                    addressLabel: 'Adresse',
                    zipCodeLabel: 'PLZ',
                    cityLabel: 'Ort',
                    countryLabel: 'Land',
                    commentLabel: 'Anmerkung',
                    purchaseLocationLabel: 'Kaufort',
                    purchaseDateLabel: 'Kaufdatum',
                    orderReferenceLabel: 'Bestellreferenz',
                    acceptTermsLabel:
                        'Ich bin mit einer Reparatur bis zur angegebenen Preisobergrenze einverstanden',
                    addMoreDefects: 'Weitere Reparatur hinzufügen',
                    submit: 'Reparatur-Angebot Anfragen',
                },
            },
            servicesStep: {
                title: 'Kostenschätzung',
                subtitle:
                    'Basierend auf Ihrer Problembeschreibung kostet die Reparatur voraussichtlich:',
                price: 'Preis: {{ price }}',
                aiSuggestionLead: 'Zugewiesene Dienste anzeigen',
                remainingLead: 'Dienste manuell auswählen',
                costConfirmationPre:
                    'Ich bin mit einer Reparatur bis zu folgendem Maximalbetrag einverstanden',
                costConfirmationLabel: 'Kostendach',
            },
            pinpointStep: {
                progressResetWarning:
                    'Wenn Sie zurückgehen, werden alle Ihre eingegebenen Daten zurückgesetzt',
                backToOverview: 'Zurück zum Start',
            },
            claimProbabilityStep: {
                title: 'Garantieabdeckung',
                subtitle:
                    'Basierend auf Ihrer Beschreibung ist die Wahrscheinlichkeit, dass dieses Problem von unserer Garantie abgedeckt wird:',
                knowWhy: 'Erfahre wieso',
                whyNot: 'Geringe Wahrscheinlichkeit: Dieses Problem wird wahrscheinlich nicht von der Garantie abgedeckt. Wir empfehlen, die folgenden Selbsthilfe-Lösungen auszuprobieren oder die Reparaturkosten zu überprüfen.',
                why: 'Hohe Wahrscheinlichkeit: Wir bieten eine lebenslange Garantie auf Wasserdichtigkeit und übernehmen die Reparaturkosten, wenn Ihr Produkt nicht mehr wasserdicht ist.',
                alternativeSteps:
                    'Aufgrund der geringen Erfolgsaussichten zeigen wir dir mögliche Lösungen, die du selbst ausprobieren kannst, oder nennen dir potenzielle Reparaturkosten.',
            },
            warrantyCostEstimationStep: {
                title: 'Kostenschätzung',
                subtitle:
                    'Wenn das Problem nicht von der Garantie abgedeckt ist, würde die Reparatur voraussichtlich kosten:',
            },
            repairOutroStep: {
                title: 'Dankeschön',
                subtitle: 'Wir werden uns bald mit einem Angebot bei Ihnen melden.',
                subtitle2: 'Bringen Sie den zu reparierenden Kite bei uns im Shop vorbei.',
                locationAction: 'Google Maps',
                restartAction: 'Einen weiteren Defekt erfassen',
                action: 'Zurück zur Übersicht',
            },
            feedbackOutroStep: {
                title: 'Dankeschön',
                subtitle:
                    'Vielen Dank für das wertvolle Feedback, wir schätzen das und versuchen, uns zu verbessern.',
                restartAction: 'Ein weiteres Feedback hinterlassen',
                action: 'Zurück zur Übersicht',
            },
            warrantyOutroStep: {
                title: 'Dankeschön',
                subtitle: 'Wir melden uns bald bei dir.',
                action: 'Zurück zum Start',
            },
        },
    },
    serviceDesk: {
        repair: {
            productStep: {
                title: 'Für welches Produkt benötigst du Hilfe?',
                productNotFoundLabel: 'Produkt nicht gefunden?',
                productNotFoundAction: 'Reparaturangebot für ein anderes Produkt anfordern',
            },
            pinpointStep: {
                progressResetWarning:
                    'Wenn Sie zurückgehen, werden alle Ihre eingegebenen Daten zurückgesetzt',
                backToOverview: 'Zurück zum Start',
            },
            solutionStep: {
                linktree: {
                    title: 'Reparaturoptionen',
                    diyTitle: 'Entdecke unsere DIY-Lösungen',
                    diySubtitle: 'Versuche, das Problem selbst mit unseren Anleitungen zu beheben.',
                    servicesTitle: 'Überprüfe unsere Kostenschätzung',
                    servicesSubtitle:
                        'Überprüfe die geschätzten Reparaturkosten basierend auf deiner Fehlerbeschreibung.',
                    contactFormTitle: 'Fordere ein Reparaturangebot an',
                    contactFormSubtitle: 'Wir werden uns bei dir mit einem Angebot melden.',
                    storeFinderTitle: 'Servicehändler in deiner Nähe',
                    storeFinderSubtitle:
                        'Hol dir Unterstützung vor Ort von einem unserer Serviceexperten',
                },
                waitingForPhotoUpload: 'Das Foto wird noch hochgeladen...',
            },
            costEstimationStep: {
                title: 'Kostenschätzung',
                costRange: {
                    lead: 'Die Kosten können sich je nach Umfang der Reparatur und Zustand deines Produktes in folgendem Bereich bewegen:',
                    lead_other:
                        'Die Kosten können sich je nach Umfang der Reparatur und Zustand deines Produktes in folgendem Bereich bewegen:',
                },
                singleRepair: {
                    lead: 'Basierend auf deinen Angaben ergeben sich voraussichtlich folgende Reparaturkosten:',
                },
                costApproval: {
                    title: 'Kostenfreigabe',
                    lead: 'Bis zu welcher Reparatur möchtest du uns eine Preisfreigabe erteilen, damit dein Produkt schnellstmöglich bearbeitet wird?',
                },
                approvalCheckbox:
                    'Ich bin mit Reparaturkosten bis maximal <bold>{{ maxPrice }} €</bold> und den <repairTerms>Reparaturbestimmungen</repairTerms> einverstanden.',
                diyAction: 'Ich bevorzuge eine DIY-Lösung.',
                repairAction: 'Ich möchte meinen Artikel reparieren lassen',
                customerSupportAction: 'Ich will mit dem Kundenservice in Kontakt treten',
            },
            contactStep: {
                title: 'Kontaktangaben',
                formTitle: 'Persönliche Angaben',
                requestContactForm: {
                    submit: 'Reparatur-Angebot Anfragen',
                },
                submitOrderForm: {
                    submit: 'Reparaturauftrag abschicken',
                },
            },
            outroStep: {
                title: 'Dankeschön!',
                subtitle: 'Wir werden uns bald mit einem Angebot bei dir melden.',
                subtitle2: 'Bringen Sie den zu reparierenden Kite bei uns im Shop vorbei.',
                links: {
                    backToStore: 'Zurück zum Shop',
                },
            },
        },
        warranty: {
            productStep: {
                title: 'Für welches Produkt willst du einen Garantiefall melden?',
                productNotFoundLabel: 'Produkt nicht gefunden?',
                productNotFoundAction: 'Garantieanfrage für ein anderes Produkt stellen',
            },
            purchaseConfirmationStep: {
                linktree: {
                    title: 'Kaufbestätigung nachweisen',
                    photoTitle: 'Foto des Kaufbelegs hochladen',
                    photoSubtitle: 'Füge eine Foto des Kaufbelegs an',
                    manualTitle: 'Bestellnummer manuell eingeben',
                    manualSubtitle:
                        'Diese findest du in deiner Onlinebestellung oder deinem Kaufbeleg',
                    notAvailable: {
                        delimiter: 'Du hast keine Kaufbestätigung?',
                        button: 'Ohne Kaufbestätigung fortfahren',
                    },
                },
                photo: {
                    title: 'Foto des Kaufbelegs hochladen',
                },
                form: {
                    title: 'Kaufnachweis eingeben',
                    orderReferenceLabel: 'Bestellnummer',
                    articleNumberLabel: 'Artikelnummer',
                    articleNumberWhereToFindTitle: 'Wo finde ich die Artikelnummer?',
                },
            },
            purchaseInformationStep: {
                title: 'Kaufinformationen',
            },
            serialNumberStep: {
                title: 'Foto der Seriennummer hochladen',
            },
            proofOfPurchaseStep: {
                title: 'Foto des Kaufbelegs hochladen',
            },
            contactStep: {
                title: 'Kontaktangaben',
                form: {
                    title: 'Persönliche Angaben',
                    submit: 'Garantiefall melden',
                },
            },
            outroStep: {
                title: 'Dankeschön!',
                subtitle: 'Wir werden uns bald mit dir in Verbindung setzen.',
            },
            solutionStep: {
                waitingForPhotoUpload: 'Die Fotos werden noch hochgeladen...',
            },
        },
        alteration: {
            productStep: {
                title: 'Für welches Produkt willst du eine Umrüstung anfragen?',
                productNotFoundLabel: 'Produkt nicht gefunden?',
                productNotFoundAction: 'Umrüstungsanfrage für ein anderes Produkt stellen',
            },
            alterationSelectionStep: {
                title: 'Umrüstung(en) auswählen',
                total: 'Total',
            },
            contactStep: {
                title: 'Kontaktangaben',
                form: {
                    title: 'Persönliche Angaben',
                    submit: 'Umrüstung bestellen',
                },
            },
        },
        shared: {
            productStep: {
                form: {
                    productReferenceLabel: 'Produktreferenz eingeben',
                },
                categories: {
                    all: 'Alle',
                    apparel: 'Bekleidung',
                    upperwear: 'Oberteile',
                    tshirts: 'T-Shirts',
                    tshirt: 'T-Shirt',
                    pullovers: 'Pullover',
                    pullover: 'Pullover',
                    hoodie: 'Hoodie',
                    longsleeve: 'Langarm Shirt',
                    tanktop: 'Tanktop',
                    top: 'Top',
                    jackets: 'Jacken',
                    jacket: 'Jacke',
                    bikini: 'Bikini',
                    pants: 'Hosen',
                    pant: 'Hose',
                    shorts: 'Shorts',
                    leggings: 'Leggings',
                    skiingPants: 'Skihosen',
                    skiingPant: 'Skihose',
                    accessories: 'Accessoires',
                    beanie: 'Mütze',
                    gloves: 'Handschuhe',
                    shoes: 'Schuhe',
                    hikingShoes: 'Wanderschuh',
                    snowBoots: 'Schneestiefel',
                    sportShoes: 'Sportschuh',
                    sneakers: 'Sneaker',
                    flipflop: 'Flip Flop',
                    hardware: 'Hardware',
                    camping: 'Camping',
                    tents: 'Zelte',
                    tent: 'Zelt',
                    campingChairs: 'Campingstühle',
                    campingChair: 'Campingstuhl',
                    mats: 'Matten und Matratzen',
                    mat: 'Matte oder Matratze',
                    sleepingBags: 'Schlafsäcke',
                    sleepingBag: 'Schlafsack',
                    hiking: 'Wandern',
                    backpacks: 'Rucksäcke',
                    backpack: 'Rucksack',
                    hikingPoles: 'Wanderstöcke',
                    hikingPole: 'Wanderstock',
                    watersport: 'Wassersport',
                    sup: 'SUP',
                    penguin: {
                        menShellPants: 'Männer Shell Hose',
                        womenShellPants: 'Frauen Shell Hose',
                        menShellJacket: 'Männer Shell Jacke',
                        womenShellJacket: 'Frauen Shell Jacke',
                    },
                    bikeBag: 'Fahrradtasche',
                    travelBag: 'Reise- & Expiditionstasche',
                    luggageRack: 'Gepäckträger',
                    handlebarBag: 'Lenker-Tasche',
                    frameBag: 'Rahmen-Tasche',
                    saddleBag: 'Satteltasche',
                    bikeBagBack: 'Fahrradtasche hinten',
                    bikeBagFront: 'Fahrradtasche vorne',
                    luggageRackBag: 'Gepäckträgertasche',
                    bags: 'Taschen',
                    shoulderBag: 'Schultertasche',
                    messengerBag: 'Messenger-Tasche',
                    laptopBag: 'Laptop-Tasche',
                    toteBag: 'Tasche',
                    freitagTravelBag: 'Reise- & Sporttasche',
                    victorinox: {
                        toolsAndKnives: 'Messer & Tools',
                        watch: 'Uhren',
                        pocketKnife: 'Taschenmesser',
                        multiTool: 'Swiss Tools',
                        luggage: 'Reisegepäck',
                        knife: 'Küchenmesser',
                        card: 'Swiss Cards',
                    },
                },
            },
            pinpointStep: {
                title: 'Problemstelle markieren',
                views: {
                    front: 'Vorne',
                    back: 'Hinten',
                    side: 'Seite',
                    bottom: 'Unten',
                    top: 'Oben',
                    inside: 'Innen',
                },
            },
            photoStep: {
                title: 'Foto des Problems hochladen',
                mobilePhotoInput: {
                    label: 'Foto aufnehmen',
                    description: 'Wähle vorzugsweise hochauflösende, klare Fotos des Problems',
                    takePhotoButton: 'Foto aufnehmen',
                    uploadPhotoButton: 'Foto hochladen',
                },
                photoDragDrop: {
                    label: 'Foto vom Computer hochladen',
                    description: 'Ein Hochauflösendes, klares Foto erleichtert uns die Verabeitung',
                    dropLabel: 'Platziere das Foto hier',
                    button: 'Foto auswählen',
                },
                qrPhotoUpload: {
                    title: 'Foto vom Handy hochladen oder aufnehmen',
                    text: 'Dazu einfach den QR-Code scannen und ein Foto hochladen',
                },
            },
            descriptionStep: {
                title: 'Problem beschreiben',
                label: 'Problembeschreibung',
                placeholder: '',
            },
            repairTypeStep: {
                title: 'Art der Reparatur',
                invisible: 'Unsichtbar',
                visible: 'Sichtbar',
                funky: 'Funky',
            },
            diyStep: {
                title: 'Reparaturanleitungen',
                nothingFoundSubtitle:
                    'Leider konnten wir keine passende Reparaturanleitung finden.',
                guideBy_one: 'Anleitung von',
                guideBy_other: 'Anleitungen von',
                checkoutGuideAction: 'Anleitung ansehen',
                notMatching: 'Keine passende Anleitung dabei?',
                checkoutRepairOfferAction: 'Stattdessen reparieren lassen',
            },
            contactStep: {
                form: {
                    title: 'Persönliche Angaben',
                    nameLabel: 'Name',
                    firstnameLabel: 'Vorname',
                    lastnameLabel: 'Nachname',
                    emailLabel: 'E-Mail',
                    phoneLabel: 'Telefon',
                    maxRepairCostLabel: 'Maximale Reparaturkosten',
                    addressLabel: 'Adresse',
                    zipCodeLabel: 'PLZ',
                    cityLabel: 'Ort',
                    countryLabel: 'Land',
                    commentLabel: 'Anmerkung',
                    purchaseLocationLabel: 'Kaufort',
                    purchaseDateLabel: 'Kaufdatum',
                    orderReferenceLabel: 'Bestellreferenz',
                    acceptTermsLabel:
                        'Ich bin mit einer Reparatur bis zur angegebenen Preisobergrenze einverstanden',
                    addMoreDefects: 'Weitere Reparatur hinzufügen',
                    submit: 'Reparatur-Angebot Anfragen',
                },
            },
            forms: {
                nameLabel: 'Name',
                firstnameLabel: 'Vorname',
                lastnameLabel: 'Nachname',
                emailLabel: 'E-Mail',
                phoneLabel: 'Telefon',
                maxRepairCostLabel: 'Maximale Reparaturkosten',
                addressLabel: 'Adresse',
                zipCodeLabel: 'PLZ',
                cityLabel: 'Ort',
                streetNoLabel: 'Straße und Hausnummer',
                zipAndCityLabel: 'PLZ und Ort',
                countryLabel: 'Land',
                commentLabel: 'Anmerkung',
                purchaseLocationLabel: 'Kaufort',
                purchaseDateLabel: 'Kaufdatum',
                orderReferenceLabel: 'Bestellreferenz',
                articleNumberLabel: 'Artikelnummer',
                serialnumberLabel: 'Seriennummer',
                serialNumberWhereToFindTitle: 'Wo finde ich die Seriennummer?',
                uploadFromComputer: 'Foto vom Computer hochladen',
                uploadFromPhone: 'Scannen um Foto mit dem Handy aufzunehmen',
                productNameLabel: 'Produktname',
                productSizeLabel: 'Produktgröße',
                productColorLabel: 'Produktfarbe',
                replacementProductLabel: 'Ersatzprodukt',
            },
        },
        mobilePhotoUpload: {
            retakeAction: 'Foto erneut aufnehmen',
        },
        ufixit: {
            intro: {
                toolsNeededLink: 'Werkzeuge, die für diesen Leitfaden benötigt werden',
                toolsNeededTitle: 'Benötigte Werkzeuge',
                sparePartsNeededLink: 'Ersatzteile, die für diesen Leitfaden benötigt werden',
                sparePartsNeededTitle: 'Benötigte Ersatzteile',
                startAction: 'Reparatur starten',
            },
            common: {
                backToRequest: 'Zurück zu Ihrer Anfrage',
                step: 'Schritt',
            },
            outro: {
                enjoy: 'Viel Spaß mit Ihrem neu reparierten Produkt!',
                didYouManage: 'Haben Sie Ihr Produkt repariert?',
                furtherAssistance: 'Nein, ich brauche weitere Hilfe',
                yesIAmHappy: 'Ja, ich bin zufrieden!',
                findNearestStore: 'Finde deinen nächsten Laden',
            },
        },
    },
    analysator: {
        keyfacts: {
            pageViews: 'Seitenaufrufe',
            uniqueVisitors: 'Einzelne Besucher',
            submissions: 'Anfragen',
        },
    },
}

export type TranslationsT = typeof de

export default de
