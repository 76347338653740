import React from 'react'
import {
    Button,
    Card,
    CardContent,
    Divider,
    IconButton,
    Stack,
    ThemeProvider,
    Typography,
    createTheme,
    styled,
} from '@mui/material'

import ExploreIcon from '@mui/icons-material/Explore'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import LocalPoliceIcon from '@mui/icons-material/LocalPolice'
import HandymanIcon from '@mui/icons-material/Handyman'
import IFrameIcon from '@mui/icons-material/FilterFrames'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'

import whitelabels from '../../../../../shared/data/whitelabel'
import routeBuilder from '../../../../routes'
import CardTitle from '../../../../components/typography/card-title'
import T from '../../../../components/typography/t'
import HeaderBar from '../../../company/inspector/products/components/header-bar'
import { Whitelabel, WhitelabelStatus } from '../../../../../shared/data/whitelabel/types'

const TatajubaRow = ({ entry }: { entry: Whitelabel }) => {
    return (
        <ThemeProvider
            theme={(theme: any) =>
                createTheme({
                    ...theme,
                    palette: {
                        ...theme.palette,
                        ...entry?.theme.palette,
                    },
                } as any)
            }
        >
            <Stack
                direction="row"
                sx={{ width: '100%', borderBottom: '1px solid #e0e0e0' }}
                gap={1}
                mt={2}
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h6" color="primary">
                    {entry.name}
                </Typography>

                <Stack direction="row" gap={1} alignItems="center">
                    {entry.config.repair && (
                        <IconButton
                            color="primary"
                            href={routeBuilder.serviceDesk(entry.id, entry.name, 'repair')}
                        >
                            <HandymanIcon />
                        </IconButton>
                    )}
                    {entry.config.warranty && (
                        <IconButton
                            color="primary"
                            href={routeBuilder.serviceDesk(entry.id, entry.name, 'warranty')}
                        >
                            <LocalPoliceIcon />
                        </IconButton>
                    )}
                    {entry.config.alterations && (
                        <IconButton
                            color="primary"
                            href={routeBuilder.serviceDesk(entry.id, entry.name, 'alteration')}
                        >
                            <SettingsSuggestIcon />
                        </IconButton>
                    )}
                    <Divider orientation="vertical" flexItem />
                    <IconButton href={routeBuilder.explorer(entry.id, entry.name)} color="primary">
                        <ExploreIcon />
                    </IconButton>
                    <IconButton
                        href={routeBuilder.analysator(entry.id, entry.name)}
                        color="primary"
                    >
                        <AutoGraphIcon />
                    </IconButton>
                    <IconButton
                        href={routeBuilder.iframeDemo(entry.id, entry.name)}
                        color="primary"
                    >
                        <IFrameIcon />
                    </IconButton>
                </Stack>
            </Stack>
        </ThemeProvider>
    )
}

const TatajubaSectionTitle = styled(Typography)(
    ({ theme }) => `
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
    padding: ${theme.spacing(1)};
    border-radius: ${theme.shape.borderRadius}px;
`
)

const Tatajuba = () => {
    return (
        <>
            <HeaderBar>
                <CardTitle>
                    <T i18nKey="admin.tatajuba.title" />
                </CardTitle>
                <Stack direction="row" gap={1}>
                    <Button href={routeBuilder.analyticsLog()}>Analytics Log</Button>
                    <Button href={routeBuilder.aiVerifier()}>AI Verifier</Button>
                </Stack>
            </HeaderBar>
            <Card>
                <CardContent>
                    <TatajubaSectionTitle variant="h6">Live 🔥</TatajubaSectionTitle>
                    {whitelabels
                        .filter((entry) => entry.status === WhitelabelStatus.Live)
                        .map((entry, index) => (
                            <TatajubaRow key={index} entry={entry} />
                        ))}

                    <TatajubaSectionTitle variant="h6" mt={8}>
                        External Demo Access 📺
                    </TatajubaSectionTitle>
                    {whitelabels
                        .filter((entry) => entry.status === WhitelabelStatus.ExternalDemoAccess)
                        .map((entry, index) => (
                            <TatajubaRow key={index} entry={entry} />
                        ))}
                    <TatajubaSectionTitle variant="h6" mt={8}>
                        Internal Only 🔐
                    </TatajubaSectionTitle>
                    {whitelabels
                        .filter((entry) => entry.status === WhitelabelStatus.InternalOnly)
                        .map((entry, index) => (
                            <TatajubaRow key={index} entry={entry} />
                        ))}
                </CardContent>
            </Card>
        </>
    )
}

export default Tatajuba
