import React from 'react'
import QuestionaireUuid from '../../../../shared/types/questionaire/uuid'
import { useAnalytics } from 'use-analytics'
import routeBuilder from '../../../routes'
import { QRCode } from 'react-qrcode-logo'
import InspectorLogo from '../../../assets/img/inspector-logo.png'
import CustomerPageWrapper from '../../../components/layout/customer-page-wrapper'
import styled from 'styled-components'
import theme from '../../../theme'
import T from '../../../components/typography/t'
import { InspectorPageViewDesktopEvent } from '../../../../shared/analytics/index'

const DesktopPageContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const QrCodeWrapper = styled.div`
    margin: 20px;
`

const InstructionText = styled(T)`
    text-align: center;
`

const DesktopFallback = ({
    questionaireUuid,
    source,
}: {
    questionaireUuid: QuestionaireUuid
    source: string
}) => {
    const analytics = useAnalytics()
    React.useEffect(() => {
        analytics.track(InspectorPageViewDesktopEvent, { questionaireUuid, source })
    }, [])
    return (
        <CustomerPageWrapper centered>
            <DesktopPageContent>
                <T i18nKey="support.desktop.title" variant="h4" component="h1" />
                <QrCodeWrapper>
                    <QRCode
                        value={`${routeBuilder.supportInspectorUrl(
                            questionaireUuid
                        )}?source=${source}`}
                        logoImage={InspectorLogo}
                        logoWidth={60}
                        logoHeight={60}
                        size={256}
                        fgColor={theme.palette.primary.main}
                    />
                </QrCodeWrapper>
                <InstructionText
                    i18nKey="support.desktop.description"
                    variant="body1"
                    component="p"
                />
            </DesktopPageContent>
        </CustomerPageWrapper>
    )
}

export default DesktopFallback
