import React from 'react'
import {
    TextField,
    styled,
    FormControl,
    InputLabel,
    alpha,
    type OutlinedTextFieldProps,
    type SxProps,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import T from '../typography/t'
import useDialog from '../../mixins/use-dialog'
import CloseIcon from '@mui/icons-material/Close'

const FieldControl = styled(FormControl)(
    ({ theme }) => `
    .MuiInputLabel-root {
        position: static;
        color: ${theme.palette.grey[900]};
        margin-bottom: ${theme.spacing(1)};
        transform: none;
    }
        
    input, textarea {
        font-size: 16px;
    }
    
    &.Mui-focused .MuiInputLabel-root {
        font-weight: 600;
    }

    .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${theme.palette.primary.main};
        transition: box-shadow 0.1s ease-in-out;
    }

    input:not(:disabled):hover ~ .MuiOutlinedInput-notchedOutline,
    textarea:not(:disabled):hover ~ .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.palette.primary.main};
        box-shadow: -1px 1px 0 1px ${alpha(theme.palette.primary.main, 0.15)};
    }
`
)

interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {
    fieldControlSx?: SxProps
    helperPopup?: {
        title: string
        text?: string
        imgUrl?: string
    }
}

const InputField = React.forwardRef<HTMLInputElement, Props>(
    ({ label, fullWidth, id, fieldControlSx, required, helperPopup, ...rest }, ref) => {
        const { isOpen, open, close } = useDialog()
        return (
            <>
                <FieldControl variant="standard" fullWidth={fullWidth} sx={fieldControlSx}>
                    <InputLabel htmlFor={id}>
                        {label}
                        {required ? '*' : ''}
                        {helperPopup && (
                            <a onClick={open}>
                                <HelpIcon sx={{ fontSize: 16 }} color="info" />
                            </a>
                        )}
                    </InputLabel>
                    <TextField id={id} fullWidth={fullWidth} ref={ref} {...rest}></TextField>
                </FieldControl>

                <Dialog open={isOpen} onClose={close}>
                    {helperPopup && (
                        <>
                            <DialogTitle>
                                <T i18nKey={helperPopup?.title as any} />
                            </DialogTitle>
                            <IconButton
                                aria-label="close"
                                onClick={close}
                                sx={(theme) => ({
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: theme.palette.grey[500],
                                })}
                            >
                                <CloseIcon />
                            </IconButton>
                            <DialogContent>
                                {helperPopup.text && <T i18nKey={helperPopup.text as any} />}
                                {helperPopup.imgUrl && (
                                    <img src={helperPopup.imgUrl} alt="helper popup" />
                                )}
                            </DialogContent>
                        </>
                    )}
                </Dialog>
            </>
        )
    }
)

export default InputField
