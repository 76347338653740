import { Role } from '../../shared/auth/role'

export type PersonWithRole = { role: Role }

export function isLoggedIn(user?: PersonWithRole) {
    return Boolean(user)
}

export function hasRole(user: PersonWithRole, role: Role) {
    return user.role === role
}

export function isAdmin(user: PersonWithRole) {
    return hasRole(user, Role.Admin)
}
export function isCompanyRepresentative(user: PersonWithRole) {
    return hasRole(user, Role.CompanyRepresentative)
}
