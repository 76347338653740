import {
    ProductCategoryNode,
    ProductType,
    ProductCategory,
    ProductCategoryId,
    ProductTypeId,
} from './types'

export const isProductType = (item: ProductCategoryNode): item is ProductType => {
    return (<ProductType>item).applicableGuides !== undefined
}
export const isProductCategory = (item: ProductCategoryNode): item is ProductCategory => {
    return (<ProductCategory>item).subItems !== undefined
}
type FindParentById = (
    id: ProductCategoryId | ProductTypeId,
    root: ProductCategoryNode
) => ProductCategory | ProductType | null

export const findParentById: FindParentById = (id, root) => {
    // Helper function to recursively search for the parent
    const search = (
        current: ProductCategoryNode,
        parent: ProductCategoryNode | null
    ): ProductCategory | ProductType | null => {
        // Check if current item is the target
        if (current.id === id) {
            return parent // Return the parent if found
        }

        // If it's a ProductCategory, search its subItems
        if ('subItems' in current) {
            for (const subItem of current.subItems) {
                const result = search(subItem, current)
                if (result) {
                    return result // If found in subItems, return the result
                }
            }
        }

        return null // Return null if not found in this branch
    }

    return search(root, null) // Start search with the root and no parent
}

export const findProductTypeById = (
    id: ProductTypeId,
    root: ProductCategoryNode
): ProductType | null => {
    // If the current node is a ProductType, check if its ID matches
    if ('subItems' in root) {
        // If it's a ProductCategory, check its subItems
        for (const subItem of root.subItems) {
            const result = findProductTypeById(id, subItem) // Recursively search in subItems
            if (result) {
                return result // Return the found ProductType
            }
        }
    } else if (root.id === id) {
        return root // Return the ProductType if found
    }

    return null // Return null if not found in this branch
}
