import React from 'react'
import TextField from '@mui/material/TextField'
import { SupportMultiStepFormContext } from '../context'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../shared-steps/components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../components/typography/inspector-step-subtitle'
import { useTranslation } from 'react-i18next'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useCopilotChat } from '@copilotkit/react-core'
import InputField from '../../../../../components/form/input-field'

export const DescriptionStep = ({
    onDescriptionChange,
    initialDescription,
}: {
    onDescriptionChange: (description: string) => void
    initialDescription?: string
}) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle i18nKey="poc.support.descriptionStep.title" mb={4} />
            <InputField
                id="problem-description"
                multiline
                fullWidth
                defaultValue={initialDescription}
                label={t('poc.support.descriptionStep.label' as any)}
                placeholder={t('poc.support.descriptionStep.placeholder' as any)}
                minRows={5}
                maxRows={15}
                onChange={(e) => onDescriptionChange(e.target.value)}
            ></InputField>
        </>
    )
}

const DescriptionStepContext = () => {
    const { form, props, ctx } = React.useContext(SupportMultiStepFormContext)
    const [isLoading, setIsloading] = React.useState(false)
    const { append } = useCopilotChat({})

    return (
        <StepFrameworkWrapper
            navigationProps={{
                nextButtonDisabled: !form.formValues.description,
                onValidNextClick: async () => {
                    setIsloading(true)
                    try {
                        await ctx.getFollowUpQuestion()
                    } catch (error) {
                        setIsloading(false)
                        console.error(error)
                    } finally {
                        setIsloading(false)
                        props.nextSlide()
                    }
                },
            }}
        >
            <DescriptionStep
                onDescriptionChange={form.setDescription}
                initialDescription={form.formValues.description}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </StepFrameworkWrapper>
    )
}

export default DescriptionStepContext
