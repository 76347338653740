import { RepairGuideType } from './types'

export default [
    {
        id: 20001,
        url: 'https://inspector.brakeable.com/ufixit/20001',
        title: 'Ölen des Taschenmessers',
        image: 'https://ucarecdn.com/a15b39f5-0caf-42d2-9f3b-c4c15f174c6c/',
        translations: {
            en: 'Oil the Pocket Knife',
            de: 'Ölen des Taschenmessers',
        },
        brandId: '409p8d',
        type: RepairGuideType.TEXT,
    },
    {
        id: 20002,
        url: 'https://inspector.brakeable.com/ufixit/20002',
        title: 'Schärfen der Klinge',
        image: 'https://ucarecdn.com/51fa1502-de12-4f0a-a6f9-87235aed87fd/',
        translations: {
            en: 'Sharpen the Blade',
            de: 'Schärfen der Klinge',
        },
        brandId: '409p8d',
        type: RepairGuideType.TEXT,
    },
]
