import React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardTitle from '../../../../components/typography/card-title'
import HeaderBar from '../../inspector/products/components/header-bar'
import T from '../../../../components/typography/t'

const NoContactRequestsFallback: React.FC = ({}) => {
    return (
        <Box>
            <HeaderBar>
                <CardTitle>
                    <T i18nKey="company.support.supportRequests.title" />
                </CardTitle>
            </HeaderBar>

            <Card sx={{ overflowX: 'auto', width: '100%' }}>
                <CardContent>
                    <Stack p={4} alignItems="center" justifyContent="center">
                        <T i18nKey="company.support.supportRequests.noSupportRequestsFallback" />
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    )
}

export default NoContactRequestsFallback
