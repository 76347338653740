import React from 'react'
import { styled } from '@mui/material'
import SubmissionId from '../../../shared/types/submission/id'
import CrosshairSvg from '../../assets/img/brakeable-crosshair.svg'
import HighlightedSubmissionId from '../../../shared/types/submission/highlighted-submissions-id'
import { Marker } from '../../../shared/types/product/marker'

const ImageWrapper = styled('div')`
    width: 100%;
    height: 100%;
    position: relative;
`

const Image = styled('img')`
    max-width: 100%;
    max-height: 100%;
`

const CrosshairContainer = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
`

const Crosshair = styled('img')<{ $isActive: boolean; $isClickable: boolean }>`
    position: absolute;
    transform: translate(-50%, -50%);
    cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};

    filter: ${({ $isActive }) => ($isActive ? 'grayscale(0)' : 'grayscale(1) brightness(1)')};
    opacity: ${({ $isActive }) => ($isActive ? 1 : 0.7)};
    z-index: ${({ $isActive }) => ($isActive ? 1 : 0)};
`

const ImageMarker = ({
    imageUrl,
    markers,
    highlightedSubmissionId,
    highlightSubmission,
    openSubmission,
}: {
    imageUrl: string
    markers: Array<Marker>
    highlightedSubmissionId?: HighlightedSubmissionId
    highlightSubmission?: (arg?: HighlightedSubmissionId) => void
    openSubmission?: (submissionId: SubmissionId) => void
}) => {
    const imageContainerRef = React.useRef<HTMLDivElement>(null)
    const imageRef = React.useRef<HTMLImageElement>(null)
    const [imageContainerWidth, setImageContainerWidth] = React.useState<number | null>(null)
    const [imageContainerHeight, setImageContainerHeight] = React.useState<number | null>(null)
    const [imageLoaded, setImageLoaded] = React.useState<boolean>(false)

    const handleResize = () => {
        if (!imageContainerRef.current || !imageRef.current || imageRef.current.offsetWidth === 0)
            return
        const width = imageRef.current.offsetWidth
        const height = imageRef.current.offsetHeight
        setImageContainerWidth(width)
        setImageContainerHeight(height)
        imageContainerRef.current.style.width = `${width}px`
        imageContainerRef.current.style.height = `${height}px`
    }

    const handleWindowResize = () => {
        if (!imageContainerRef.current || !imageRef.current || imageRef.current.offsetWidth === 0)
            return

        imageContainerRef.current.style.width = '100%'
        imageContainerRef.current.style.height = '100%'
        handleResize()
    }

    React.useEffect(() => {
        handleResize()
        setImageLoaded(true)

        return () => {
            imageContainerRef.current?.removeEventListener('load', handleResize)
        }
    }, [imageRef.current])

    React.useEffect(() => {
        if (!imageRef.current) return
        const onImageLoaded = () => {
            setImageLoaded(true)
        }
        imageRef.current.addEventListener('load', onImageLoaded)

        return () => {
            imageRef.current?.removeEventListener('load', onImageLoaded)
        }
    }, [imageRef])

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowResize)
        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    return (
        <ImageWrapper ref={imageContainerRef}>
            <Image src={imageUrl} onLoad={handleResize} ref={imageRef} />
            <CrosshairContainer>
                {markers.map((marker) => {
                    return (
                        <Crosshair
                            key={marker.id}
                            src={CrosshairSvg}
                            $isActive={highlightedSubmissionId?.id === marker.id}
                            $isClickable={!!openSubmission}
                            sx={{
                                top: marker.y * (imageContainerHeight ?? 1),
                                left: marker.x * (imageContainerWidth ?? 1),
                                opacity: imageLoaded ? 1 : 0,
                            }}
                            onMouseEnter={() =>
                                highlightSubmission?.({ source: 'marker', id: marker.id })
                            }
                            onMouseLeave={() => highlightSubmission?.(undefined)}
                            onClick={() => openSubmission?.(marker.id)}
                        />
                    )
                })}
            </CrosshairContainer>
        </ImageWrapper>
    )
}

export default ImageMarker
