import * as React from 'react'
import Stack from '@mui/material/Stack'
import CompanySettingsViewModel from '../../../../shared/types/company/company-information-view-model'
import Settings from './settings'
import CompanyAccount from './company-account'

const CompanySettings = ({ companySettings }: { companySettings: CompanySettingsViewModel }) => {
    return (
        <Stack spacing={3} sx={{ width: '100%' }}>
            <CompanyAccount companySettings={companySettings} />
            <Settings companySettings={companySettings} />
        </Stack>
    )
}

export default CompanySettings
