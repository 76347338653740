import React from 'react'
import { AlterationsMultiStepFormContext } from '../../context'
import StepFrameworkWrapper from '../../../shared/components/step-framework-wrapper'
import NoEstimationScreen from './no-estimation-screen'
import { useForm } from 'react-hook-form'
import FormData from '../../../../../../shared/types/common/form-data'

const SolutionStepContext = () => {
    const { form, props, ctx, meta } = React.useContext(AlterationsMultiStepFormContext)
    const useFormReturn = useForm<FormData>({ values: form.formValues.contactFormValues })
    const [hasSubmitted, setHasSubmitted] = React.useState(false)

    const formSubmit = () => {
        if (!hasSubmitted) {
            form.submitForm()
            props.nextSlide()
        }
    }

    const onRepairFormSubmit = (data: any) => {
        formSubmit()
        setHasSubmitted(true)
    }

    return (
        <StepFrameworkWrapper
            wide
            navigationProps={{
                nextButtonLabelKey: 'common.next',
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled: true,
                onValidPrevClick: () => {
                    form.setValue('contactFormValues', useFormReturn.getValues())
                    props.prevSlide()
                },
            }}
        >
            <NoEstimationScreen
                onSubmit={onRepairFormSubmit}
                contactForm={ctx.whitelabel.config.alterations.contactForm}
                useForm={useFormReturn}
            />
        </StepFrameworkWrapper>
    )
}

export default SolutionStepContext
