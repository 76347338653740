import {
    useAction,
    type OptimisticUpdateDefinition,
    markSubmissionAsSeenByUser,
    useQuery,
    getReport,
} from 'wasp/client/operations'

import React from 'react'
import LoadingSkeleton from '../../../components/loading/skeleton'
import Report from './report'
import ReportT from '../../../../shared/types/report/report'
import CompanyId from '../../../../shared/types/company/id'
import SubmissionId from '../../../../shared/types/submission/id'

const ReportWrapper = ({ companyId }: { companyId?: CompanyId }) => {
    const { data, isFetching, error } = useQuery(getReport, { companyId })

    const markSubmissionAsSeenByUserOptimistically = useAction(markSubmissionAsSeenByUser, {
        optimisticUpdates: [
            {
                getQuerySpecifier: () => [getReport, { companyId }],
                updateQuery: (_payload, oldData) => ({
                    ...oldData,
                    entries: oldData?.entries.map((entry) => ({
                        ...entry,
                        submission: {
                            ...entry.submission,
                            unseenByUser:
                                entry.submission.id === _payload.submissionId
                                    ? false
                                    : entry.submission.unseenByUser,
                        },
                    })),
                }),
            } as OptimisticUpdateDefinition<{ submissionId: SubmissionId }, ReportT>,
        ],
    })

    if (isFetching) return <LoadingSkeleton large />
    if (error) return <div>Error: {error.message}</div>

    return <Report data={data!} markSubmissionAsSeen={markSubmissionAsSeenByUserOptimistically} />
}

export default ReportWrapper
