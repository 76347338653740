import { styled } from '@mui/material'
import { OverridableTypeMap, OverrideProps } from '@mui/material/OverridableComponent'

const LabelledDelimiter = styled('div')(
    ({ theme }) => `
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    &::before, &::after {
        content: '';
        display: block;
        width: 100%;
        border-bottom: 1px solid ${theme.palette.grey[200]};
        z-index: -1;
        flex-grow: 1;
        align-self: center;
    }

    > * {
        color: ${theme.palette.grey[200]};
        padding: ${theme.spacing(1, 2)};
        width: auto;
        text-transform: uppercase;
        flex-shrink: 0;
        align-self: center;
    }
`
)

export default LabelledDelimiter
