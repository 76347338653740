import { ProductCategoryId, ProductCategoryNode, ProductTypeId } from './types'

export const productCategories: ProductCategoryNode = {
    id: 0 as ProductCategoryId,
    name: 'All',
    Icon: 'null',
    subItems: [
        {
            id: 1 as ProductCategoryId,
            name: 'serviceDesk.shared.productStep.categories.victorinox.toolsAndKnives',
            Icon: 'VictorinoxPocketKnifeIcon',
            subItems: [
                {
                    id: 11 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.victorinox.multiTool',
                    internalName: 'Multi Tool',
                    Icon: 'VictorinoxMultiToolIcon',
                    applicableGuides: [],
                    applicableServices: [],
                    applicableAlterations: [],
                    views: [],
                },
                {
                    id: 12 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.victorinox.pocketKnife',
                    internalName: 'Pocket Knife',
                    Icon: 'VictorinoxPocketKnifeIcon',
                    applicableGuides: [20001, 20002],
                    applicableServices: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                    applicableAlterations: [],
                    views: [
                        {
                            label: 'serviceDesk.shared.pinpointStep.views.front',
                            sketchUrl: 'https://ucarecdn.com/c60e1ca6-2c66-4738-8b8e-bae8ef666a5a/',
                            details: [
                                {
                                    name: 'Scissor',
                                    features: [],
                                    x1: 0.125,
                                    x2: 0.4,
                                    y1: 0.2,
                                    y2: 0.5,
                                },
                                {
                                    name: 'Blade (Big)',
                                    features: [],
                                    x1: 0.33,
                                    x2: 0.8,
                                    y1: 0.175,
                                    y2: 0.275,
                                },
                                {
                                    name: 'Corkscrew',
                                    features: [],
                                    x1: 0.725,
                                    x2: 0.875,
                                    y1: 0.44,
                                    y2: 0.72,
                                },
                                {
                                    name: 'Blade (Small)',
                                    features: [],
                                    x1: 0.15,
                                    x2: 0.5,
                                    y1: 0.6,
                                    y2: 0.725,
                                },
                                {
                                    name: 'Case',
                                    features: [],
                                    x1: 0.5,
                                    x2: 0.725,
                                    y1: 0.44,
                                    y2: 0.6,
                                },
                                {
                                    name: 'Case',
                                    features: [],
                                    x1: 0.55,
                                    x2: 0.87,
                                    y1: 0.275,
                                    y2: 0.44,
                                },
                                {
                                    name: 'Case',
                                    features: [],
                                    x1: 0.8,
                                    x2: 0.92,
                                    y1: 0.16,
                                    y2: 0.275,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 13 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.victorinox.knife',
                    internalName: 'Knife',
                    Icon: 'VictorinoxKnifeIcon',
                    applicableGuides: [],
                    applicableServices: [],
                    applicableAlterations: [],
                    views: [],
                },
                {
                    id: 14 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.victorinox.card',
                    internalName: 'Card',
                    Icon: 'VictorinoxCardIcon',
                    applicableGuides: [],
                    applicableServices: [],
                    applicableAlterations: [],
                    views: [],
                },
            ],
        },
        {
            id: 2 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.victorinox.watch',
            internalName: 'Watch',
            Icon: 'VictorinoxWatchIcon',
            applicableGuides: [],
            applicableServices: [],
            applicableAlterations: [],
            views: [],
        },
        {
            id: 3 as ProductTypeId,
            name: 'serviceDesk.shared.productStep.categories.victorinox.luggage',
            internalName: 'Luggage',
            Icon: 'VictorinoxLuggageIcon',
            applicableGuides: [],
            applicableServices: [],
            applicableAlterations: [],
            views: [],
        },
    ],
}
export default productCategories
