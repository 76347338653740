import React from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardTitle from '../../../../components/typography/card-title'
import { UserInspectionsViewModelEntry } from '../../../../../shared/types/user-management/inspections-by-email'
import InspectionByEmailRow from './inspection-by-email-row'
import HeaderBar from '../../inspector/products/components/header-bar'
import SubmissionId from '../../../../../shared/types/submission/id'
import AddNewUser from './add-new-user'
import RoleProtector from '../../../../components/auth/role-protector'
import { Role } from '../../../../../shared/auth/role'
import T from '../../../../components/typography/t'

const InspectionsByEmail: React.FC<{
    inspectionsByEmail: UserInspectionsViewModelEntry[]
    markSubmissionAsSeenByUser: ({ submissionId }: { submissionId: SubmissionId }) => void
}> = ({ inspectionsByEmail, markSubmissionAsSeenByUser }) => {
    return (
        <Box>
            <HeaderBar>
                <CardTitle>
                    <T i18nKey="company.userManagement.users.title" />
                </CardTitle>
                <RoleProtector roles={[Role.Admin]}>
                    <AddNewUser openNewProductDialog={() => {}} />
                </RoleProtector>
            </HeaderBar>
            <Card>
                <CardContent>
                    <List>
                        {inspectionsByEmail.map((entry) => {
                            return (
                                <InspectionByEmailRow
                                    entry={entry}
                                    key={entry.user.email}
                                    markSubmissionAsSeenByUser={markSubmissionAsSeenByUser}
                                />
                            )
                        })}
                    </List>
                </CardContent>
            </Card>
        </Box>
    )
}

export default InspectionsByEmail
