import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import {
    Box,
    Stack,
    Button,
    IconButton,
    styled,
    Typography,
    Drawer,
    Link,
    Switch,
} from '@mui/material'
import T from '../../../../../components/typography/t'
// import DIYStep from './diy-step'
import type RepairService from '../../../../../../shared/data/repair-services/types'
import useDialog from '../../../../../mixins/use-dialog'
import { useIsDesktop } from '../../../../../mixins/media-query'
import CloseIcon from '@mui/icons-material/Close'
import SuggestionsContactForm from './suggestions-contact-form'
import { type ServiceDeskContactForm } from '../../../../../../shared/data/whitelabel/types'
import LabelledDelimiter from '../../../shared/components/labelled-delimiter'
import { useTranslation } from 'react-i18next'
import FormData from '../../../../../../shared/types/common/form-data'

const InnerDrawer = styled('div')`
    padding: ${({ theme }) => theme.spacing(4, 4)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1em;
`

const DetailsCard = styled('div')`
    padding: ${({ theme }) => theme.spacing(4, 4)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1em;
    flex-grow: 1;
    box-shadow: ${({ theme }) => theme.shadows[4]};
    border-radius: ${({ theme }) => theme.spacing(1)};
    position: relative;
`
const CostEstimation: React.FC<{
    selectedServices: RepairService[]
    onDiyClick: () => void
    repairGuideCount: number
    onSubmit: (data: FormData) => void
    onAddMoreDefectsClick: () => void
    contactForm: ServiceDeskContactForm
    useForm: UseFormReturn<FormData>
    acceptedServiceIds: number[]
    handleServiceAcceptance: (serviceId: number) => void
    handleServiceRejection: (serviceId: number) => void
}> = ({
    selectedServices,
    onDiyClick,
    repairGuideCount,
    onSubmit,
    onAddMoreDefectsClick,
    contactForm,
    useForm,
    acceptedServiceIds,
    handleServiceAcceptance,
    handleServiceRejection,
}) => {
    const { t, i18n } = useTranslation()
    const isDesktop = useIsDesktop()
    const { isOpen, close, open } = useDialog()

    const acceptedServices = selectedServices.filter((s) => acceptedServiceIds.includes(s.id))
    const maxPrice =
        selectedServices.length > 0
            ? Math.max(...selectedServices.flatMap((service) => service.price))
            : 0
    const maxAcceptedPrice =
        acceptedServices.length > 0
            ? Math.max(...acceptedServices.flatMap((service) => service.price))
            : 0
    const minPrice =
        selectedServices.length > 0
            ? Math.min(...selectedServices.flatMap((service) => service.price))
            : 0
    const isPriceRange = maxPrice - minPrice > 0
    const dynamicModalChildren = (
        <>
            <IconButton
                onClick={(e: any) => {
                    e.target.blur()
                    close()
                }}
                sx={{
                    position: 'absolute',
                    top: (theme) => theme.spacing(1),
                    right: (theme) => theme.spacing(1),
                }}
            >
                <CloseIcon />
            </IconButton>
            <T
                variant="h3"
                fontWeight={500}
                color="primary"
                i18nKey="serviceDesk.repair.contactStep.formTitle"
            />
            <SuggestionsContactForm
                onSubmit={onSubmit}
                contactForm={contactForm}
                maxAcceptedPrice={maxAcceptedPrice}
                useForm={useForm}
            />
        </>
    )

    const language = i18n.language.slice(0, 2)
    const getRepairServiceTitle = (repairService: RepairService) => {
        if (repairService.translations && repairService.translations[language]) {
            return repairService.translations[language]
        }
        return repairService.title
    }

    return (
        <>
            <InspectorStepTitle i18nKey="serviceDesk.repair.costEstimationStep.title" mb={4} />
            <Stack
                direction="row"
                gap={8}
                justifyContent="center"
                width="100%"
                alignItems="flex-start"
            >
                <Box
                    sx={{
                        maxWidth: (theme) =>
                            isDesktop ? `calc(50% - ${theme.spacing(4)})` : '100%',
                    }}
                >
                    <T
                        i18nKey="serviceDesk.repair.costEstimationStep.costRange.lead"
                        component="p"
                        variant="body1"
                        mb={2}
                        count={selectedServices.length}
                    />
                    <Typography variant="h2" color="primary" textAlign="center">
                        {isPriceRange
                            ? `${minPrice.toFixed(2)} € - ${maxPrice.toFixed(2)} €`
                            : `${maxPrice.toFixed(2)} €`}
                    </Typography>
                    <LabelledDelimiter sx={{ my: 4 }} />
                    <T
                        i18nKey="serviceDesk.repair.costEstimationStep.costApproval.title"
                        component="h2"
                        variant="h3"
                        color="primary"
                        mb={1}
                    />
                    <T
                        i18nKey="serviceDesk.repair.costEstimationStep.costApproval.lead"
                        component="p"
                        variant="body1"
                        mb={5}
                    />
                    <Stack gap={2} mb={4}>
                        {selectedServices
                            .sort(
                                (a, b) => a.price[a.price.length - 1] - b.price[b.price.length - 1]
                            )
                            .map((s) => {
                                const isAccepted = acceptedServiceIds.includes(s.id)
                                const declinedStyle = {
                                    color: 'grey.200',
                                    textDecoration: 'line-through',
                                }
                                const fontStyle = isAccepted ? {} : declinedStyle
                                return (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        key={s.id}
                                    >
                                        <Stack>
                                            <Typography sx={fontStyle}>
                                                {getRepairServiceTitle(s)}
                                            </Typography>
                                            <Typography fontWeight={500} sx={fontStyle}>
                                                {s.price.length > 1
                                                    ? `${s.price[0].toFixed(
                                                          2
                                                      )} - ${s.price[1].toFixed(2)}`
                                                    : s.price[0].toFixed(2)}
                                                €
                                            </Typography>
                                        </Stack>

                                        <Switch
                                            checked={isAccepted}
                                            onChange={() =>
                                                isAccepted
                                                    ? handleServiceRejection(s.id)
                                                    : handleServiceAcceptance(s.id)
                                            }
                                            disabled={isOpen}
                                        />
                                    </Stack>
                                )
                            })}
                    </Stack>
                    {!isOpen && (
                        <>
                            <Stack mt={6} gap={2} justifyContent="stretch" width="100%">
                                <Button
                                    variant="contained"
                                    onClick={open}
                                    disabled={maxAcceptedPrice <= 0}
                                >
                                    <T i18nKey="serviceDesk.repair.costEstimationStep.repairAction" />
                                </Button>
                                {repairGuideCount > 0 && (
                                    <Button onClick={onDiyClick}>
                                        <T i18nKey="serviceDesk.repair.costEstimationStep.diyAction" />
                                    </Button>
                                )}
                                <LabelledDelimiter sx={{ my: 2 }}>
                                    <T component="span" i18nKey="common.or" />
                                </LabelledDelimiter>
                                <Link
                                    sx={{
                                        alignSelf: 'center',
                                        color: 'grey.200',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <T i18nKey="serviceDesk.repair.costEstimationStep.customerSupportAction" />
                                </Link>
                            </Stack>
                        </>
                    )}
                </Box>
                {!isDesktop ? (
                    <Drawer
                        open={isOpen}
                        onClose={close}
                        PaperProps={{ sx: { borderRadius: '24px 24px 0 0' } }}
                    >
                        <InnerDrawer>{dynamicModalChildren}</InnerDrawer>
                    </Drawer>
                ) : (
                    isOpen && <DetailsCard>{dynamicModalChildren}</DetailsCard>
                )}
            </Stack>
        </>
    )
}

export default CostEstimation
