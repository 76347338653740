import React from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Button } from '@mui/material'
import T from '../../../../../components/typography/t'
import { UseFormReturn } from 'react-hook-form'
import { type ServiceDeskContactForm } from '../../../../../../shared/data/whitelabel/types'
import Field from '../../../shared/components/field'
import FormData from '../../../../../../shared/types/common/form-data'

const ContactForm: React.FC<{
    onSubmit: (data: FormData) => void
    onAddMoreDefectsClick: () => void
    contactForm: ServiceDeskContactForm
    useForm: UseFormReturn<FormData>
}> = ({ onSubmit, onAddMoreDefectsClick, contactForm, useForm }) => {
    const [t] = useTranslation()
    const { handleSubmit, formState } = useForm
    return (
        <>
            {contactForm.fields.map((cells, index) => (
                <Stack
                    direction="row"
                    mb={index === contactForm.fields.length - 1 ? 0 : 1}
                    gap={2}
                    sx={{ width: '100%' }}
                    key={index}
                >
                    {cells.map((cell) => (
                        <Field key={cell.key} field={cell} useForm={useForm} />
                    ))}
                </Stack>
            ))}

            <Stack
                direction={{ sm: 'column', md: 'row' }}
                gap={2}
                justifyContent="space-between"
                sx={{ width: '100%', flexWrap: 'wrap' }}
                mt={4}
            >
                {/* <Button onClick={onAddMoreDefectsClick}>
                    <T i18nKey="serviceDesk.repair.contactStep.form.addMoreDefects" />
                </Button> */}
                <div />
                <Button
                    onClick={() => {
                        handleSubmit(onSubmit)()
                    }}
                    variant="contained"
                >
                    <T i18nKey="serviceDesk.warranty.contactStep.form.submit" />
                </Button>
            </Stack>
        </>
    )
}
export default ContactForm
