import InspectorLogo from '../../assets/img/inspector-logo.png'
import styled from 'styled-components'
import theme from '../../theme'
import InspectorBranding from '../branding/inspector'

const PageWrapper = styled.div`
    display: flex;
    height: 100%;
`

const Logo = styled.img`
    position: absolute;
    margin: 20px;
    width: 64px;
    height: 64px;
`

const ContentWrapper = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${theme.breakpoints.down('sm')} {
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% - 20px);
        height: auto;
        background-color: white;
        border-radius: 10px;
        transform: translate(-50%, -50%);
        padding-bottom: 1.5rem;
    }
`
const VisualWrapper = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${theme.palette.primary.main};

    ${theme.breakpoints.down('sm')} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
`

const Visual = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    width: 75%;
    max-width: 600px;
    font-family: 'Bebas Neue';
`

const Letter = styled.div`
    font-size: 170px;
    width: 33%;
    text-align: center;
    flex-shrink: 0;
    color: #ff00bf;

    ${theme.breakpoints.down('sm')} {
        font-size: 140px;
    }
`

const ByBrakeable = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 20px;
    letter-spacing: 0.2em;
    color: #e4ff03;
`

const AuthLayout = ({ children }: { children: React.ReactNode }) => {
    return (
        <PageWrapper>
            <Logo src={InspectorLogo} />
            <ContentWrapper>{children}</ContentWrapper>
            <VisualWrapper>
                <InspectorBranding />
            </VisualWrapper>
        </PageWrapper>
    )
}

export default AuthLayout
