import PageContent from './page-content'

const AdminPageWrapper: React.FC<{
    children: React.ReactNode
    centered?: boolean
}> = ({ children, centered = false }) => {
    return (
        <div className="blurry-background">
            <PageContent $centered={centered}>{children}</PageContent>
        </div>
    )
}

export default AdminPageWrapper
