import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Submission from '../../../../../shared/types/product/dashboard-submission'
import ImageMarker from '../../../../components/image-marker'
import createUploadcareImageSrc from '../../../../mixins/create-uploadcare-image-src'
import T from '../../../../components/typography/t'
import styled from 'styled-components'
import theme from '../../../../theme'
import TabPanel from '../../../../components/tabs/tab-panel'
import RoleProtector from '../../../../components/auth/role-protector'
import { Role } from '../../../../../shared/auth/role'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import SubmissionId from '../../../../../shared/types/submission/id'

const SubmissionContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
`

const Description = styled.p`
    margin: 0;
    font-size: 1.2rem;
    font-style: italic;
    color: ${() => theme.palette.grey[500]};

    &::before {
        content: '"';
    }

    &::after {
        content: '"';
    }
`

const Email = styled.div`
    margin-top: 1em;
    font-size: 0.8em;
`

const EmailLink = styled.a`
    color: ${theme.palette.grey[500]};
    text-decoration: none;

    &:hover {
        color: ${theme.palette.grey[800]};
        text-decoration: underline;
    }
`

const MarkerWrapper = styled.div`
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
`

const DefectImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`

const MobileSubmissionDetailContent = ({
    submission,
    productImgUrl,
    deleteSubmission,
}: {
    submission: Submission
    productImgUrl: string
    deleteSubmission?: (submissionId: SubmissionId) => void
}) => {
    const [value, setValue] = React.useState(0)
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }
    return (
        <>
            <Box display="flex" flexWrap="wrap">
                {submission.labels.map((label) => (
                    <Chip
                        sx={{ marginRight: theme.spacing(1) }}
                        label={label.value}
                        key={label.id}
                        color="primary"
                    />
                ))}

                {deleteSubmission && (
                    <RoleProtector roles={[Role.Admin]}>
                        <Box sx={{ ml: 4 }}>
                            <IconButton
                                color={'error'}
                                onClick={() => deleteSubmission(submission.id)}
                                size="small"
                            >
                                <DeleteIcon fontSize="inherit" />
                            </IconButton>
                        </Box>
                    </RoleProtector>
                )}
            </Box>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="product-dashboard-tabs"
                sx={{ mb: 1 }}
            >
                <Tab label="Photo" id="photo-tab" aria-controls="photo-panel" />
                <Tab label="Location" id="location-tab" aria-controls="location-panel" />
                <Tab label="Description" id="description-tab" aria-controls="description-panel" />
            </Tabs>
            <TabPanel name="photo" open={value === 0}>
                <DefectImage
                    src={createUploadcareImageSrc(submission.image, { preview: '900x1200' })}
                />
            </TabPanel>
            <TabPanel name="location" open={value === 1}>
                <MarkerWrapper>
                    <ImageMarker
                        markers={[{ id: submission.id, ...submission.imagePinpoint }]}
                        imageUrl={createUploadcareImageSrc(productImgUrl, { preview: '800x800' })}
                        highlightedSubmissionId={{ id: submission.id, source: 'marker' }}
                    />
                </MarkerWrapper>
            </TabPanel>
            <TabPanel name="description" open={value === 2}>
                <SubmissionContent>
                    <T
                        i18nKey="company.inspector.productDashboard.detail.defectDescription"
                        variant="h6"
                        component="h2"
                    />
                    <Description>
                        {submission.description ?? (
                            <T i18nKey="company.inspector.productDashboard.noDescription" />
                        )}
                    </Description>
                    <T
                        i18nKey="company.inspector.productDashboard.detail.contextDescription"
                        variant="h6"
                        component="h2"
                        mt={2}
                    />
                    <Description>
                        {submission.contextDescription ?? (
                            <T i18nKey="company.inspector.productDashboard.noDescription" />
                        )}
                    </Description>

                    {!!submission.email && (
                        <Email>
                            <T i18nKey="company.inspector.productDashboard.detail.contact" />{' '}
                            <EmailLink href={`mailto:${submission.email}`}>
                                {submission.email}
                            </EmailLink>
                        </Email>
                    )}
                </SubmissionContent>
            </TabPanel>
        </>
    )
}

export default MobileSubmissionDetailContent
