import React from 'react'
import { getRepairGuideSuggestions as getRepairGuideSuggestionsServerAction } from 'wasp/client/operations'
import CategoryStep from './shared-steps/category-selection-step'
import DescriptionStep from './diy-steps/description-step'
import DIYStep from './diy-steps/diy-step'
import PhotoStep from './shared-steps/photo-step'
import PinpointStep from './shared-steps/pinpoint-step'
import { UploadClient } from '@uploadcare/upload-client'
import Coord from '../../../../../shared/types/common/coord'
import {
    FormValues,
    MultiStepFormError,
    SupportMultiStepFormContext,
    defaultContextValues,
} from './context'
import { Whitelabel } from './data/whitelabel'
import { ProductTypeId } from './data/products/types'
import { findProductTypeById } from './data/products/helpers'
import defaultRepairGuides from '../../../../../shared/data/repair-guides'
import {
    Page,
    Header,
    WidthContainer,
    BrandLogo,
    Content,
    Footer,
    PBB,
    PoweredBy,
    PoweredByBrakeable,
    ProgressBar,
} from '../../layout'
import { useTranslation } from 'react-i18next'

let slides: Array<() => JSX.Element> = [
    () => <CategoryStep />,
    () => <PinpointStep />,
    () => <PhotoStep />,
    () => <DescriptionStep />,
    () => <DIYStep />,
]

const DIYMultiStepForm = ({
    onSubmit,
    uploadCareClient,
    whitelabel,
    goHome,
}: {
    onSubmit: (formValues: any) => Promise<void>
    uploadCareClient: UploadClient
    whitelabel: Whitelabel
    goHome: () => void
}) => {
    const [t] = useTranslation()
    const [meta, setMeta] = React.useState<any>({})
    const [slideIndex, setSlideIndex] = React.useState(0)
    const [errors, setErrors] = React.useState<MultiStepFormError[]>([])
    const formDefaultValues = {
        customerEmail: '',
        selectedProductCategory: undefined,
        selectedProduct: undefined,
        selectedProductView: 0,
        selectedServices: [],
        aiSelectedServices: [],
        aiSelectedRepairGuides: [],
        aiRelatedServices: [],
        description: undefined,
        uploadedPhotoUrl: undefined,
        pinpoint: undefined,
        followUpQuestion: undefined,
        selectedComponent: undefined,
    }
    const [formValues, setFormValues] = React.useState<FormValues>(formDefaultValues)
    const [addedStep, setAddedStep] = React.useState({ diy: false, costEstimate: false })
    const nextSlide = () => setSlideIndex(Math.min(slideIndex + 1, maxSteps - 1))
    const prevSlide = () => {
        if (slideIndex === 0) {
            goHome()
        } else {
            setSlideIndex(Math.max(slideIndex - 1, 0))
        }
    }
    const setSlide = (index: number) => setSlideIndex(index)
    const maxSteps = slides.length
    const gotoEnd = () => setSlideIndex(maxSteps - 1)
    const currentStep = slideIndex

    const setCustomerEmail = (email: string) =>
        setFormValues((v) => ({ ...v, customerEmail: email }))
    const setSelectedProductCategory = (categoryId: number) =>
        setFormValues((v) => ({ ...v, selectedProductCategory: categoryId }))
    const setSelectedServices = (serviceIds: number[]) =>
        setFormValues((v) => ({ ...v, selectedServices: serviceIds }))
    const setAiSelectedServices = (serviceIds: number[]) =>
        setFormValues((v) => ({ ...v, aiSelectedServices: serviceIds }))
    const setAiSelectedRepairGuides = (guideIds: number[]) =>
        setFormValues((v) => ({ ...v, aiSelectedRepairGuides: guideIds }))
    const setAiRelatedServices = (serviceIds: number[]) =>
        setFormValues((v) => ({ ...v, aiRelatedServices: serviceIds }))

    const setSelectedProduct = (productId?: number) =>
        setFormValues((v) => ({ ...v, selectedProduct: productId }))
    const setSelectedProductView = (productViewId: number) =>
        setFormValues((v) => ({ ...v, selectedProductView: productViewId }))
    const setDescription = (description: string) =>
        setFormValues((v) => ({ ...v, description: description }))
    const setUploadedPhotoUrl = (url?: string) =>
        setFormValues((v) => ({ ...v, uploadedPhotoUrl: url }))
    const setPinpoint = (coords?: Coord) => setFormValues((v) => ({ ...v, pinpoint: coords }))
    const setClaimProbability = (probability: number) =>
        setFormValues((v) => ({ ...v, claimProbability: probability }))
    const setAIFollowUpQuestion = (question: string) => {
        setFormValues((v) => ({ ...v, followUpQuestion: question }))
    }
    const setFollowUpAnswer = (answer: string) => {
        setFormValues((v) => ({ ...v, followUpAnswer: answer }))
    }
    const setSelectedComponent = (component?: string) => {
        setFormValues((v) => ({ ...v, selectedComponent: component }))
    }

    const resetForm = () => {
        setFormValues(formDefaultValues)
        setMeta({})
    }

    const submitForm = async () => {
        await onSubmit({
            userInput: {
                selectedProduct: formValues.selectedProduct,
                selectedProductView: formValues.selectedProductView,
                description: formValues.description,
                uploadedPhotoUrl: formValues.uploadedPhotoUrl,
                pinpoint: formValues.pinpoint,
            },
            resources: {
                selectedComponent: formValues.selectedComponent,
                availableRepairGuides: repairGuides
                    .filter((g) => selectedProductNode?.applicableGuides.includes(g.id))
                    .map((g) => g.id),
            },
            result: {
                aiSelectedRepairGuides: formValues.aiSelectedRepairGuides,
            },
        })
    }
    const pushError = (error: MultiStepFormError) => {
        setErrors((errors) => [...errors, error])
    }
    const popError = () => {
        setErrors((errors) => errors.slice(0, errors.length - 1))
    }

    const productCategoryTree =
        whitelabel?.productCategoryTree ?? defaultContextValues.ctx.productCategoryTree
    const repairGuides = whitelabel?.repairGuides ?? defaultRepairGuides

    const selectedProductNode = findProductTypeById(
        (formValues.selectedProduct ?? (-1 as any)) as ProductTypeId,
        productCategoryTree
    )

    const getRepairGuideSuggestions = async () => {
        if (selectedProductNode) {
            const selectedRepairGuideIds = await getRepairGuideSuggestionsServerAction({
                productCategory: t(selectedProductNode.name as any),
                problemDescription: formValues.description!,
                selectedProductComponent: !!formValues.selectedComponent
                    ? {
                          name: formValues.selectedComponent,
                          potentialFeatures: [],
                      }
                    : undefined,
                availableGuides: selectedProductNode.applicableGuides,
                brandId: whitelabel.id,
            })
            setAiSelectedRepairGuides(selectedRepairGuideIds)
        }
    }

    return (
        <SupportMultiStepFormContext.Provider
            value={{
                ctx: {
                    ...defaultContextValues.ctx,
                    whitelabel,
                    isWhiteLabelled: !!whitelabel,
                    pinpointImage:
                        whitelabel?.pinpointImg ?? defaultContextValues.ctx.pinpointImage,
                    repairGuides: repairGuides,
                    productCategoryTree: productCategoryTree,
                    getRepairGuideSuggestions,
                },
                props: {
                    currentStep,
                    maxSteps,
                    nextSlide,
                    prevSlide,
                    setSlide,
                    gotoEnd,
                },
                form: {
                    setCustomerEmail,
                    setSelectedProductCategory,
                    setSelectedServices,
                    setAiSelectedServices,
                    setAiSelectedRepairGuides,
                    setAiRelatedServices,
                    setSelectedProduct,
                    setSelectedProductView,
                    setDescription,
                    setUploadedPhotoUrl,
                    setPinpoint,
                    setClaimProbability,
                    setAIFollowUpQuestion,
                    setFollowUpAnswer,
                    setSelectedComponent,
                    formValues,
                    submitForm,
                    resetForm,
                },
                meta: {
                    value: meta,
                    setMetaValue: setMeta,
                },
                error: {
                    errors,
                    pushError,
                    popError,
                },
                uploadCareClient,
            }}
        >
            <Page>
                <Header>
                    <WidthContainer sx={{ justifyContent: 'space-between' }}>
                        <BrandLogo src={whitelabel.logo} alt="logo" />
                    </WidthContainer>
                </Header>
                <ProgressBar $progress={(currentStep + 1) / maxSteps} />
                <Content sx={{ mt: 1, mb: 4 }}>{slides[slideIndex]()}</Content>
                <Footer>
                    <WidthContainer sx={{ justifyContent: 'flex-end' }}>
                        <PBB>
                            <PoweredBy>Powered by</PoweredBy>{' '}
                            <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                        </PBB>
                    </WidthContainer>
                </Footer>
            </Page>
        </SupportMultiStepFormContext.Provider>
    )
}

export default DIYMultiStepForm
