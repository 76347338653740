import React from 'react'
import PageWrapper from '../../../components/layout/page-wrapper'
import Products from './products'
import AppSuite from '../../../../shared/app-suite'
import CompanyId from '../../../../shared/types/company/id'

const Inspector = ({ companyId }: { companyId?: CompanyId }) => {
    return (
        <PageWrapper activeLogo={AppSuite.Inspector} companyId={companyId}>
            <Products companyId={companyId!} />
        </PageWrapper>
    )
}
export default Inspector
