import { RepairGuide } from '../../../../../../shared/data/repair-guides/types'
import defaultRepairGuides from '../../../../../../shared/data/repair-guides'
import intersportRepairGuides from '../../../../../../shared/data/repair-guides/intersport-repair-guides'
import vaudeRepairGuidesEn from '../../../../../../shared/data/repair-guides/vaude-repair-guides-en'

import { ProductCategoryNode } from './products/types'
import defaultProductCategoryTree from './products'
import penguinProductCategoryTree from './products/penguin-products'
import theme from '../../../../../theme'
import { type ThemeOptions, alpha } from '@mui/material'

export type Whitelabel = {
    id: string
    name: string
    theme: Partial<ThemeOptions>
    logo: string
    links: {
        storeFinder: string
    }
    linktreeLinks: {
        name: string
        url: string
    }[]
    pinpointImg?: string
    disabledMainFlows: number[]
    repairGuides: RepairGuide[]
    productCategoryTree: ProductCategoryNode
    hideFakeFeatures: boolean
}

export const brkWhitelabel: Whitelabel = {
    id: '1',
    name: 'Brakeable',
    theme: theme,
    logo: 'https://ucarecdn.com/9e0bb50d-8d9f-4779-ae66-f32c5b04d9ad/',
    links: {
        storeFinder: 'https://www.intersport.de/haendlersuche/',
    },
    linktreeLinks: [],
    disabledMainFlows: [],
    repairGuides: [...intersportRepairGuides, ...vaudeRepairGuidesEn],
    productCategoryTree: defaultProductCategoryTree,
    hideFakeFeatures: false,
}

const whitelabels: Whitelabel[] = [
    brkWhitelabel,
    {
        id: '43574',
        name: 'intersport',
        theme: {
            palette: {
                primary: {
                    main: '#154194',
                    contrastText: '#ffffff',
                },
                secondary: {
                    main: '#E30814',
                    contrastText: '#ffffff',
                },
                getCrosshairColors: () => ({ stroke: '#154194', fill: alpha('#154194', 0.4) }),
                getIllustrationColors: () => ({ primary: '#154194', accent: '#E30814' }),
                getProgressbarColor: () => '#154194',
            },
        },
        logo: 'https://ucarecdn.com/cd919cdd-50dc-4538-9194-83018accc2d4/',
        links: {
            storeFinder: 'https://www.intersport.de/haendlersuche/',
        },
        linktreeLinks: [],
        disabledMainFlows: [],
        repairGuides: [...intersportRepairGuides, ...vaudeRepairGuidesEn],
        productCategoryTree: defaultProductCategoryTree,
        hideFakeFeatures: false,
    },
    {
        id: '6aopb0',
        name: 'Freitag',
        theme: {
            palette: {
                primary: {
                    main: '#000',
                    contrastText: '#ffffff',
                },
                secondary: {
                    main: '#000',
                    contrastText: '#ffffff',
                },
                getCrosshairColors: () => ({ stroke: '#000', fill: '#ffffff' }),
                getIllustrationColors: () => ({ primary: '#000', accent: '#23201F' }),
                getProgressbarColor: () => '#000',
                getTextColor: () => '#000',
            },
        },
        logo: 'https://ucarecdn.com/dcac8124-55c1-4ffe-9072-8804e7329af7/',
        links: {
            storeFinder: 'https://www.intersport.de/haendlersuche/',
        },
        linktreeLinks: [],
        disabledMainFlows: [],
        repairGuides: [...intersportRepairGuides, ...vaudeRepairGuidesEn],
        productCategoryTree: defaultProductCategoryTree,
        hideFakeFeatures: false,
    },
    {
        id: '23478',
        name: 'nikin',
        theme: {
            palette: {
                primary: {
                    main: '#749580',
                    contrastText: '#ffffff',
                },
                secondary: {
                    main: '#749580',
                    contrastText: '#ffffff',
                },
                getCrosshairColors: () => ({ stroke: '#749580', fill: '#ffffff' }),
                getIllustrationColors: () => ({
                    primary: '#749580',
                    accent: alpha('#749580', 0.6),
                }),
                getProgressbarColor: () => '#749580',
            },
        },
        logo: 'https://ucarecdn.com/03f37053-e6cf-4537-b144-277b1e739e6a/',
        links: {
            storeFinder: '',
        },
        linktreeLinks: [
            {
                name: 'TreeTracker',
                url: 'https://nikin.ch/pages/treetracker',
            },
            {
                name: 'FactoryTracker',
                url: 'https://nikin.ch/pages/hersteller',
            },
        ],
        disabledMainFlows: [],
        pinpointImg: 'https://ucarecdn.com/10dc0278-aef7-4e86-80fe-75b1fddf9b42/',
        repairGuides: defaultRepairGuides,
        productCategoryTree: defaultProductCategoryTree,
        hideFakeFeatures: false,
    },
    {
        id: '54384',
        name: 'mammut',
        theme: {
            palette: {
                primary: {
                    main: '#23293c',
                    contrastText: '#ffffff',
                },
                secondary: {
                    main: '#23293c',
                    contrastText: '#ffffff',
                },
                getCrosshairColors: () => ({ stroke: '#23293c', fill: '#EE011B' }),
                getIllustrationColors: () => ({
                    primary: '#EE011B',
                    accent: '#23293c',
                }),
                getProgressbarColor: () => '#EE011B',
            },
        },
        logo: 'https://ucarecdn.com/9573a707-ba9d-43f3-a800-6f12e08f0fcd/',
        links: {
            storeFinder: '',
        },
        linktreeLinks: [],
        disabledMainFlows: [],
        repairGuides: defaultRepairGuides,
        productCategoryTree: defaultProductCategoryTree,
        hideFakeFeatures: false,
    },
    {
        id: '29593',
        name: 'lululemon',
        theme: {
            palette: {
                primary: {
                    main: '#C8102E',
                    contrastText: '#ffffff',
                },
                secondary: {
                    main: '#C8102E',
                    contrastText: '#ffffff',
                },
                getCrosshairColors: () => ({ stroke: '#C8102E', fill: '#ffffff' }),
                getIllustrationColors: () => ({
                    primary: '#C8102E',
                    accent: alpha('#C8102E', 0.4),
                }),
                getProgressbarColor: () => '#C8102E',
            },
        },
        logo: 'https://ucarecdn.com/2acfd24d-ade0-4709-94cd-5ccc3aafb2a4/',
        links: {
            storeFinder: '',
        },
        linktreeLinks: [],
        pinpointImg: 'https://ucarecdn.com/f72ed36d-47e1-46ab-afe5-bdb432a4c62b/',
        disabledMainFlows: [],
        repairGuides: defaultRepairGuides,
        productCategoryTree: defaultProductCategoryTree,
        hideFakeFeatures: false,
    },
    {
        id: '17796',
        name: 'duotone (Bar)',
        theme: {
            palette: {
                primary: {
                    main: '#0d242b',
                    contrastText: '#ffffff',
                },
                secondary: {
                    main: '#00a8c4',
                    contrastText: '#ffffff',
                },
                getCrosshairColors: () => ({ stroke: '#00a8c4', fill: '#0d242b' }),
                getIllustrationColors: () => ({ primary: '#00a8c4', accent: '#0d242b' }),
                getProgressbarColor: () => '#00a8c4',
            },
        },
        logo: 'https://ucarecdn.com/e031d12b-53bd-4c6e-aad1-dc6033de8d43/',
        links: {
            storeFinder: '',
        },
        linktreeLinks: [],
        pinpointImg: 'https://ucarecdn.com/b606c93b-e449-4dd8-9bb3-e8d59c311b76/',
        disabledMainFlows: [],
        repairGuides: defaultRepairGuides,
        productCategoryTree: defaultProductCategoryTree,
        hideFakeFeatures: false,
    },
    {
        id: '17797',
        name: 'duotone (Textile)',
        theme: {
            palette: {
                primary: {
                    main: '#0d242b',
                    contrastText: '#ffffff',
                },
                secondary: {
                    main: '#00a8c4',
                    contrastText: '#ffffff',
                },
                getCrosshairColors: () => ({ stroke: '#00a8c4', fill: '#0d242b' }),
                getIllustrationColors: () => ({ primary: '#00a8c4', accent: '#0d242b' }),
                getProgressbarColor: () => '#00a8c4',
            },
        },
        logo: 'https://ucarecdn.com/e031d12b-53bd-4c6e-aad1-dc6033de8d43/',
        links: {
            storeFinder: '',
        },
        linktreeLinks: [
            {
                name: 'Register your Product',
                url: 'https://www.duotonesports.com/en/kiteboarding/more/ask-us/warranty-registration',
            },
            {
                name: 'Duotone Academy App',
                url: 'https://www.duotonesports.com/de/kiteboarding/mehr/folge-uns/academy-app',
            },
        ],
        pinpointImg: 'https://ucarecdn.com/4468715c-b8a7-41a7-8301-f8b715e382ae/',
        disabledMainFlows: [],
        repairGuides: defaultRepairGuides,
        productCategoryTree: defaultProductCategoryTree,
        hideFakeFeatures: false,
    },
    {
        id: '124578',
        name: 'ortlieb',
        theme: {
            palette: {
                primary: {
                    main: '#EE9000',
                    contrastText: '#ffffff',
                },
                secondary: {
                    main: '#EE9000',
                    contrastText: '#ffffff',
                },
                getCrosshairColors: () => ({ stroke: '#EE9000', fill: '#ffffff' }),
                getIllustrationColors: () => ({ primary: '#EE9000', accent: '#463c32' }),
                getProgressbarColor: () => '#EE9000',
                getTextColor: () => '#23201F',
            },
        },
        logo: 'https://ucarecdn.com/dc7efdb0-0fae-4c18-b375-59d3b9dd1db0/',
        links: {
            storeFinder: '',
        },
        linktreeLinks: [
            { name: 'Zum Ortlieb Shop', url: 'https://www.ortlieb.com/de-de' },
            {
                name: 'Zu den Ersatzteilen',
                url: 'https://www.ortlieb.com/ch_de/produkte/ersatzteile',
            },
        ],
        disabledMainFlows: [],
        repairGuides: defaultRepairGuides,
        productCategoryTree: defaultProductCategoryTree,
        hideFakeFeatures: true,
    },
    {
        id: '122278',
        name: 'Vaude',
        theme: {
            palette: {
                primary: {
                    main: '#463c32',
                    contrastText: '#ffffff',
                },
                secondary: {
                    main: '#FF5900',
                    contrastText: '#ffffff',
                },
                getCrosshairColors: () => ({ stroke: '#FF5900', fill: '#ffffff' }),
                getIllustrationColors: () => ({ primary: '#FF5900', accent: '#463c32' }),
                getProgressbarColor: () => '#FF5900',
            },
        },
        logo: 'https://ucarecdn.com/89eaea03-b46b-4d80-b3f2-9e2c05d6925b/',
        links: {
            storeFinder: 'https://www.vaude.com/de/de/haendlersuche.html',
        },
        linktreeLinks: [],
        disabledMainFlows: [],
        repairGuides: defaultRepairGuides,
        productCategoryTree: defaultProductCategoryTree,
        hideFakeFeatures: false,
    },
    {
        id: '221265',
        name: 'Schoeffel',
        theme: {
            palette: {
                primary: {
                    main: '#000000',
                    contrastText: '#ffffff',
                },
                secondary: {
                    main: '#29ff71',
                    contrastText: '#000000',
                },
                getCrosshairColors: () => ({ stroke: '#29ff71', fill: '#000000' }),
                getIllustrationColors: () => ({ primary: '#000000', accent: '#29ff71' }),
                getProgressbarColor: () => '#29ff71',
            },
        },
        logo: 'https://ucarecdn.com/49948cc6-8472-4bd6-8578-981c73ca0a40/',
        links: {
            storeFinder: '',
        },
        linktreeLinks: [
            {
                name: 'Zum Shop',
                url: 'https://www.schoeffel.com/ch/de/',
            },
            {
                name: 'Imprägnierservice',
                url: 'https://pflege.schoeffel.com/?sPartner=86830',
            },
        ],
        disabledMainFlows: [],
        repairGuides: defaultRepairGuides,
        productCategoryTree: defaultProductCategoryTree,
        hideFakeFeatures: false,
    },
    {
        id: '011263',
        name: 'Bergzeit',
        theme: {
            palette: {
                primary: {
                    main: '#ba941a',
                    contrastText: '#ffffff',
                },
                secondary: {
                    main: '#0370c9',
                    contrastText: '#ffffff',
                },
                getCrosshairColors: () => ({ stroke: '#ba941a', fill: '#ffffff' }),
                getIllustrationColors: () => ({ primary: '#ba941a', accent: '#0370c9' }),
                getProgressbarColor: () => '#ba941a',
            },
        },
        logo: 'https://ucarecdn.com/6214a87f-02c2-459d-b2a5-51b9f0769069/',
        links: {
            storeFinder: '',
        },
        linktreeLinks: [],
        disabledMainFlows: [],
        repairGuides: defaultRepairGuides,
        productCategoryTree: defaultProductCategoryTree,
        hideFakeFeatures: false,
    },
    {
        id: '270598',
        name: 'Deuter',
        theme: {
            palette: {
                primary: {
                    main: '#003a60',
                    contrastText: '#ffffff',
                },
                secondary: {
                    main: '#003a60',
                    contrastText: '#ffffff',
                },
                getCrosshairColors: () => ({ stroke: '#003a60', fill: '#ffffff' }),
                getIllustrationColors: () => ({
                    primary: alpha('#003a60', 0.6),
                    accent: '#003a60',
                }),
                getProgressbarColor: () => '#003a60',
            },
        },
        logo: 'https://ucarecdn.com/e1fdffd6-2531-409b-8752-b5a2fa4adc33/',
        links: {
            storeFinder: '',
        },
        linktreeLinks: [],
        disabledMainFlows: [],
        repairGuides: defaultRepairGuides,
        productCategoryTree: defaultProductCategoryTree,
        hideFakeFeatures: false,
    },
    {
        id: '260897',
        name: 'Penguin',
        theme: {
            palette: {
                primary: {
                    main: '#282727',
                    contrastText: '#E2D4C8',
                },
                secondary: {
                    main: '#E2D4C8',
                    contrastText: '#282727',
                },
                getCrosshairColors: () => ({ stroke: '#E2D4C8', fill: '#282727' }),
                getIllustrationColors: () => ({ primary: '#282727', accent: '#E2D4C8' }),
                getProgressbarColor: () => '#282727',
            },
        },
        logo: 'https://ucarecdn.com/eb01faa4-cbb2-4fc1-ac03-01abe2fbb6cd/',
        links: {
            storeFinder: '',
        },
        linktreeLinks: [],
        disabledMainFlows: [],
        repairGuides: defaultRepairGuides,
        productCategoryTree: penguinProductCategoryTree,
        hideFakeFeatures: false,
    },
    {
        id: '260893',
        name: 'Head',
        theme: {
            palette: {
                primary: {
                    main: '#121212',
                    contrastText: '#ffffff',
                },
                secondary: {
                    main: '#d5400a',
                    contrastText: '#121212',
                },
                getCrosshairColors: () => ({ stroke: '#121212', fill: '#d5400a' }),
                getIllustrationColors: () => ({ primary: '#282727', accent: '#d5400a' }),
                getProgressbarColor: () => '#d5400a',
            },
        },
        logo: 'https://ucarecdn.com/e6678264-031b-4c52-ac8b-bb56c2695686/',
        links: {
            storeFinder: '',
        },
        linktreeLinks: [],
        disabledMainFlows: [],
        repairGuides: defaultRepairGuides,
        productCategoryTree: defaultProductCategoryTree,
        hideFakeFeatures: false,
    },
    {
        id: '241200',
        name: 'Victorinox',
        theme: {
            palette: {
                primary: {
                    main: '#B20034',
                    contrastText: '#ffffff',
                },
                secondary: {
                    main: '#B20034',
                    contrastText: '#ffffff',
                },
                getCrosshairColors: () => ({ stroke: '#B20034', fill: '#ffffff' }),
                getIllustrationColors: () => ({
                    primary: alpha('#B20034', 0.4),
                    accent: '#B20034',
                }),
                getProgressbarColor: () => '#B20034',
            },
        },
        logo: 'https://ucarecdn.com/290a55f5-148e-4882-83a4-9310d17adb4b/',
        links: {
            storeFinder: '',
        },
        linktreeLinks: [],
        disabledMainFlows: [],
        repairGuides: defaultRepairGuides,
        productCategoryTree: defaultProductCategoryTree,
        hideFakeFeatures: false,
    },
]

export default whitelabels
