import { updateUserLanguage } from 'wasp/client/operations'
import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import useAuth from '../../mixins/use-auth'
import { useTranslation } from 'react-i18next'
import useCookie from '../../mixins/use-cookie'
import T from '../typography/t'

const LanguageSwitcher = () => {
    const [_, setLangCookie] = useCookie('i18next')
    const { t, i18n } = useTranslation()
    const { user } = useAuth()
    const onLanguageSettingChange = (e: SelectChangeEvent) => {
        updateUserLanguage({ lang: e.target.value })
        setLangCookie(e.target.value)
    }

    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="language-swticher-label">
                <T i18nKey="common.language" />
            </InputLabel>
            <Select
                labelId="language-swticher-label"
                id="language-swticher-select"
                value={user?.lang ?? i18n.language.substring(0, 2)}
                label={t('common.language' as any)}
                autoWidth
                onChange={onLanguageSettingChange}
            >
                <MenuItem value="de">Deutsch</MenuItem>
                <MenuItem value="en">English</MenuItem>
            </Select>
        </FormControl>
    )
}

export default LanguageSwitcher
