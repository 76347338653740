import React from 'react'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../shared-steps/components/step-framework-wrapper'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { SupportMultiStepFormContext } from '../context'
import InputField from '../../../../../components/form/input-field'

export const FollowUpStep = ({
    onContextDescriptionChange,
    initialContextDescription,
    followUpQuestion,
}: {
    onContextDescriptionChange: (description: string) => void
    initialContextDescription?: string
    followUpQuestion: string
}) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle i18nKey="inspector.mobile.contextDescription.title" />
            <Typography variant="body2" component="h2" textAlign="center" color="grey.500" mb={2}>
                {followUpQuestion}
            </Typography>
            <InputField
                id="follow-up-question"
                multiline
                fullWidth
                defaultValue={initialContextDescription}
                label={t('inspector.mobile.contextDescription.label' as any)}
                placeholder={t('inspector.mobile.contextDescription.placeholder' as any)}
                minRows={5}
                maxRows={15}
                onChange={(e) => onContextDescriptionChange(e.target.value)}
            ></InputField>
        </>
    )
}

const FollowUpStepContext = () => {
    const { form } = React.useContext(SupportMultiStepFormContext)
    return (
        <StepFrameworkWrapper
            navigationProps={{
                nextButtonDisabled: !form.formValues.followUpAnswer,
            }}
        >
            <FollowUpStep
                onContextDescriptionChange={form.setFollowUpAnswer}
                initialContextDescription={form.formValues.followUpAnswer}
                followUpQuestion={form.formValues.followUpQuestion!}
            />
        </StepFrameworkWrapper>
    )
}

export default FollowUpStepContext
