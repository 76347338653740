import styled from 'styled-components'

export const DevicePreviewWrapper = styled.div`
    height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    > * {
        transform: scale(0.6);
    }
`
