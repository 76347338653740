import { RepairGuideType } from './types'

export default [
    {
        id: 10001,
        url: 'https://inspector.brakeable.com/ufixit/10001',
        title: 'Steckschnalle austauschen',
        image: 'https://ucarecdn.com/0bf2e11b-15c0-460e-930a-5556c72f10f1/',
        translations: {
            en: 'Replace Buckle',
            de: 'Steckschnalle austauschen',
        },
        brandId: '6aopb0',
        type: RepairGuideType.TEXT,
    },
]
