import { type Whitelabel } from './types'
import penguinWhitelabel from './penguin-whitelabel'
import zenitramWhitelabel from './zenitram-whitelabel'
import snowdoniaWhitelabel from './snowdonia-whitelabel'
import vidarWhitelabel from './vidar-whitelabel'
import brakeableWhitelabel from './brakeable-whitelabel'
import vaudeWhitelabel from './vaude-whitelabel'
import ortliebWhitelabel from './ortlieb-whitelabel'
import intersportWhitelabel from './intersport-whitelabel'
import berghausWhitelabel from './berghaus-whitelabel'
import mammutWhitelabel from './mammut-whitelabel'
import duotoneWhitelabel from './duotone-whitelabel'
import freitagWhitelabel from './freitag-whitelabel'
import einsteinWhitelabel from './einstein-whitelabel'
import einsteinB2bWhitelabel from './einstein-b2b-whitelabel'
import ortliebB2bWhitelabel from './ortlieb-b2b-whitelabel'
import victorinoxWhitelabel from './victorinox-whitelabel'
import deuterWhitelabel from './deuter-whitelabel'
import jackwolfskinWhitelabel from './jack-wolfskin-whitelabel'
import patagoniaWhitelabel from './patagonia-whitelabel'
import salewaWhitelabel from './salewa-whitelabel'
import ortovoxWhitelabel from './ortovox-whitelabel'
import sport2000Whitelabel from './sport2000-whitelabel'
import rabWhitelabel from './rab-whitelabel'
import brakeableExternalWhitelabel from './brakeable-external-whitelabel'

export const whitelabels: Whitelabel[] = [
    brakeableWhitelabel,
    penguinWhitelabel,
    vidarWhitelabel,
    zenitramWhitelabel,
    ortliebWhitelabel,
    ortliebB2bWhitelabel,
    mammutWhitelabel,
    vaudeWhitelabel,
    duotoneWhitelabel,
    berghausWhitelabel,
    snowdoniaWhitelabel,
    intersportWhitelabel,
    freitagWhitelabel,
    einsteinWhitelabel,
    einsteinB2bWhitelabel,
    victorinoxWhitelabel,
    deuterWhitelabel,
    jackwolfskinWhitelabel,
    patagoniaWhitelabel,
    salewaWhitelabel,
    ortovoxWhitelabel,
    sport2000Whitelabel,
    rabWhitelabel,
    brakeableExternalWhitelabel,
]

export default whitelabels
