import React from 'react'
import Typography from '@mui/material/Typography'
import styled from 'styled-components'
import OnboardingForm from './onboarding-form'
import AuthLayout from '../../../components/layout/auth-layout'
import T from '../../../components/typography/t'

const FormWrapper = styled.div`
    width: 100%;
    max-width: 340px;
`

const OnboardingPage = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    return (
        <AuthLayout>
            <FormWrapper>
                <T
                    variant="h5"
                    component="h2"
                    color="primary"
                    sx={{ marginTop: '1.5rem' }}
                    i18nKey="company.onboarding.form.welcome"
                />
                <T variant="h6" component="p" i18nKey="company.onboarding.form.lead" />
                <T variant="subtitle1" component="p" i18nKey="company.onboarding.form.subLead" />
                <OnboardingForm token={urlParams.get('token') ?? ''} />
            </FormWrapper>
        </AuthLayout>
    )
}

export default OnboardingPage
