import React from 'react'
import styled from 'styled-components'
import theme from '../../../theme'
import HomeLogo from '../../../assets/img/home-logo.png'
import InspectorLogo from '../../../assets/img/inspector-logo.png'
import ReportLogo from '../../../assets/img/report-logo.png'
import UserManagementLogo from '../../../assets/img/user-management-logo.png'
import AppSuite from '../../../../shared/app-suite'

const LogoEl = styled.img<{ $isActive: boolean; $color: string }>`
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 3px solid transparent;
    border-radius: 8px;
    opacity: ${({ $isActive }) => ($isActive ? 1 : 0.75)};
    transition: opacity 0.2s ease-in-out, border-color 0.2s ease-in-out;
    border-color: ${({ $isActive, $color }) => ($isActive ? $color : 'transparent')};

    &:hover {
        opacity: 1;
        border-color: ${({ $color }) => $color};
    }

    ${theme.breakpoints.down('sm')} {
        border-width: 2px;
        border-radius: 4px;
    }
`
const Logo: React.FC<{ app: AppSuite; isActive: boolean }> = ({ app, isActive }) => {
    const icon = React.useMemo(() => {
        switch (app) {
            case AppSuite.Home:
                return HomeLogo
            case AppSuite.Inspector:
                return InspectorLogo
            case AppSuite.Report:
                return ReportLogo
            case AppSuite.UserManagement:
                return UserManagementLogo
        }
    }, [app])

    const altText = React.useMemo(() => {
        switch (app) {
            case AppSuite.Home:
                return 'Home logo'
            case AppSuite.Inspector:
                return 'Inspector logo'
            case AppSuite.Report:
                return 'Report logo'
            case AppSuite.UserManagement:
                return 'User management logo'
        }
    }, [app])

    const color = React.useMemo(() => {
        switch (app) {
            case AppSuite.Home:
                return '#E4FF03'
            case AppSuite.Inspector:
                return '#FF00BF'
            case AppSuite.Report:
                return '#007BFF'
            case AppSuite.UserManagement:
                return '#F2A63B'
        }
    }, [app])

    return <LogoEl src={icon} alt={altText} $isActive={isActive} $color={color} />
}

export default Logo
