import React from 'react'
import styled from 'styled-components'
import Coord from '../../../../../../shared/types/common/coord'
import { InspectorMultiStepFormContext } from '../../inspector-multi-step-form'
import Pinpoint from './pinpoint'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../components/typography/inspector-step-subtitle'
import createUploadcareImageSrc from '../../../../../mixins/create-uploadcare-image-src'
import PinpointStep from '../../../../service-desk/shared/steps/pinpoint-step'

const ImageWrapperAligner = styled.div`
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    flex-shrink: 1;
    min-height: 0; // too shrink element to fit image inside flexbox
    flex-grow: 1;
    width: 100%;
`

export const PreviewPinpointStep = ({
    productImageUrl,
    setInspectionImagePinpoint,
    initialCoords,
}: {
    productImageUrl: string
    setInspectionImagePinpoint: (pinpoint: Coord) => void
    initialCoords?: Coord
}) => {
    return (
        <>
            <InspectorStepTitle mb={2} i18nKey="inspector.mobile.pinpoint.title" />
            <InspectorStepSubtitle i18nKey="inspector.mobile.pinpoint.description" />
            <ImageWrapperAligner>
                <Pinpoint
                    productImageUrl={productImageUrl}
                    onPinpointChanged={setInspectionImagePinpoint}
                    initialCoords={initialCoords}
                />
            </ImageWrapperAligner>
        </>
    )
}

const PinpointStepContext = () => {
    const { ctx, form, props } = React.useContext(InspectorMultiStepFormContext)

    const portrait = window.matchMedia('(orientation: portrait)')
    const [isInPortraitMode, setIsInPortraitMode] = React.useState<boolean>(portrait.matches)

    const orientationChanged = (e: MediaQueryListEvent) => {
        setIsInPortraitMode(e.matches)
    }

    React.useEffect(() => {
        portrait.addEventListener('change', orientationChanged)

        return () => {
            portrait.removeEventListener('change', orientationChanged)
        }
    }, [])

    return (
        <StepFrameworkWrapper
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled: !form.formValues.inspectionImagePinpoint,
            }}
        >
            {/* <PinpointStep
                setInspectionImagePinpoint={form.setInspectionImagePinpoint}
                productImageUrl={createUploadcareImageSrc(ctx.productImageUrl, {
                    preview: '700x1400',
                })}
                initialCoords={form.formValues.inspectionImagePinpoint}
            /> */}
            <PinpointStep
                setInspectionImagePinpoint={(coord) => form.setInspectionImagePinpoint(coord!)}
                setSelectedComponent={() => {}}
                setSelectedProductView={() => {}}
                productViews={[
                    {
                        label: '',
                        sketchUrl: createUploadcareImageSrc(ctx.productImageUrl, {
                            preview: '700x1400',
                        }),
                        details: [],
                    },
                ]}
                initialCoords={form.formValues.inspectionImagePinpoint}
                initialSelectedProductView={0}
            />
        </StepFrameworkWrapper>
    )
}

export default PinpointStepContext
