import styled from 'styled-components'

const Logo = styled.img`
    width: 300px;
`

const CompanyLogo = styled(Logo)`
    object-fit: contain;
    object-position: center right;
`

export default CompanyLogo
