import React from 'react'
import Typo from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import ImageList from '@mui/material/ImageList'
import ReportT from '../../../../shared/types/report/report'
import createUploadcareImageSrc from '../../../mixins/create-uploadcare-image-src'
import SubmissionDetail from '../common/submission-detail/index'
import { useIsMobile } from '../../../mixins/media-query'
import useUrlQuery from '../../../mixins/use-url-query'
import SubmissionId from '../../../../shared/types/submission/id'
import Filterbar, { FormData } from '../common/filterbar'
import { isSubmissionWithoutPhoto } from '../../../mixins/submission-without-photo'
import { FilterLabel } from '../../../../shared/types/submission/label'
import { OriginFilter } from '../../../../shared/types/submission/origin-filter'
import HeaderBar from '../inspector/products/components/header-bar'
import CardTitle from '../../../components/typography/card-title'
import SubmissionDialogWrapper from '../inspector/product-dashboard/overview/submission-dialog-wrapper'
import InspectionImageTile from '../common/inspection-image-tile'
import T from '../../../components/typography/t'
import { deleteSubmission } from 'wasp/client/operations'
import { useCopilotReadable, useCopilotAction } from '@copilotkit/react-core'
import InspectionOrigin from '../../../../shared/inspection/inspection-origin'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import TravelExploreIcon from '@mui/icons-material/TravelExplore'

const sortSubmissions = (
    a: { submission: { image: string } },
    b: { submission: { image: string } }
) => {
    if (
        isSubmissionWithoutPhoto(a.submission.image) &&
        !isSubmissionWithoutPhoto(b.submission.image)
    ) {
        return 1
    }
    if (
        !isSubmissionWithoutPhoto(a.submission.image) &&
        isSubmissionWithoutPhoto(b.submission.image)
    ) {
        return -1
    }
    return 0
}

function areAllFlagsFalse(obj: { [index: number]: boolean }) {
    for (const flag in obj) {
        if (obj[flag]) {
            return false
        }
    }
    return true
}

const Report: React.FC<{
    data: ReportT
    markSubmissionAsSeen: ({ submissionId }: { submissionId: SubmissionId }) => void
}> = ({ data, markSubmissionAsSeen }) => {
    const isMobile = useIsMobile()

    const [filterLabelFromUrl, setFilterLabelInUrl] = useUrlQuery('labels')
    const [filterOriginFromUrl, setFilterOriginInUrl] = useUrlQuery('origins')

    const parsedFilterLabelFromUrl = (filterLabelFromUrl()?.split(',') ?? []).map((l) =>
        parseInt(l)
    )
    const parsedFilterOriginFromUrl = (filterOriginFromUrl()?.split(',') ?? []).map((o) =>
        parseInt(o)
    )

    const [selectedLabelFilters, setSelectedLabelFilters] =
        React.useState<number[]>(parsedFilterLabelFromUrl)
    const [selectedOriginFilters, setSelectedOriginFilters] =
        React.useState<number[]>(parsedFilterOriginFromUrl)

    const setLabelFilter = (label: FilterLabel) => {
        if (selectedLabelFilters.find((l) => l === label.id)) {
            setSelectedLabelFilters((prev) => prev.filter((l) => l !== label.id))
            setFilterLabelInUrl(selectedLabelFilters.filter((l) => l !== label.id).join(','))
        } else {
            setSelectedLabelFilters((prev) => [...prev, label.id])
            setFilterLabelInUrl([...selectedLabelFilters, label.id].join(','))
        }
    }

    const setOriginFilter = (origin: OriginFilter) => {
        if (selectedOriginFilters.find((l) => l === origin.id)) {
            setSelectedOriginFilters((prev) => prev.filter((l) => l !== origin.id))
            setFilterOriginInUrl(selectedOriginFilters.filter((l) => l !== origin.id).join(','))
        } else {
            setSelectedOriginFilters((prev) => [...prev, origin.id])
            setFilterOriginInUrl([...selectedOriginFilters, origin.id].join(','))
        }
    }

    const onOpenSubmission = (submissionId: SubmissionId) => {
        const entry = data.entries.find((entry) => entry.submission.id === submissionId)
        if (entry?.submission.unseenByUser) {
            markSubmissionAsSeen({ submissionId })
        }
    }

    const filterFn = ({ searchTerm, showOnlyUnseen, categories }: FormData) => {
        return data.entries
            .filter((entry) => (showOnlyUnseen ? entry.submission.unseenByUser : true))

            .filter((entry) => {
                if (selectedLabelFilters.length === 0) {
                    return true
                }
                return selectedLabelFilters.every((labelId) =>
                    entry.submission.labels.some((l) => l.id === labelId)
                )
            })
            .filter((entry) => {
                if (selectedOriginFilters.length === 0) {
                    return true
                }
                return selectedOriginFilters.includes(entry.submission.inspectionOrigin)
            })
            .filter((entry) => {
                if (areAllFlagsFalse(categories)) {
                    return true
                }
                for (const category in categories) {
                    if (categories[category] && entry.product.category?.id === parseInt(category)) {
                        return true
                    }
                }
            })
            .filter((entry) => {
                if (searchTerm === '') {
                    return true
                } else {
                    return (
                        entry.submission.email?.toLocaleLowerCase().includes(searchTerm) ||
                        entry.submission.contextDescription
                            .toLocaleLowerCase()
                            .includes(searchTerm) ||
                        entry.submission.description.toLocaleLowerCase().includes(searchTerm) ||
                        entry.submission.createdAt.toLocaleLowerCase().includes(searchTerm) ||
                        entry.product.name.toLocaleLowerCase().includes(searchTerm)
                    )
                }
            })
    }

    const maxCols = isMobile ? 2 : 4

    useCopilotReadable({
        description: "All issues the company's products currently face",
        value: data.entries.map((e) => ({
            product: {
                id: e.product.id,
                name: e.product.name,
                category: e.product.category,
            },
            description: e.submission.description,
            usageContext: e.submission.contextDescription,
            labels: e.submission.labels,
            createdAt: e.submission.createdAt,
        })),
    })

    useCopilotAction({
        name: 'setLabelFilter',
        description: 'Set the label filters',
        parameters: [
            {
                name: 'labelIds',
                type: 'number[]',
                description: 'List of label ids to set active',
            },
        ],
        handler: async ({ labelIds }) => {
            setSelectedLabelFilters(labelIds)
            setFilterLabelInUrl(labelIds.join(','))
        },
    })

    const toOriginObject = (inspectionOrigin: InspectionOrigin) => ({
        id: inspectionOrigin,
        value: inspectionOrigin === InspectionOrigin.Feedback ? 'Feedback' : 'Repair',
    })

    return (
        <Box width="100%">
            <HeaderBar>
                <CardTitle>
                    <T i18nKey="company.report.title" />
                </CardTitle>
            </HeaderBar>
            <Card>
                <CardContent>
                    <Filterbar
                        filterFn={filterFn}
                        getLabelsFromEntry={(entry) => entry.submission.labels}
                        getOriginsFromEntry={(entry) => [
                            toOriginObject(entry.submission.inspectionOrigin),
                        ]}
                        selectedLabelIds={selectedLabelFilters}
                        selectedOriginIds={selectedOriginFilters}
                        categories={data.categories}
                        resetLabels={() => setSelectedLabelFilters([])}
                        allLabels={data.entries.map((entry) => entry.submission.labels).flat()}
                        allOrigins={data.entries.map((entry) =>
                            toOriginObject(entry.submission.inspectionOrigin)
                        )}
                    >
                        {(filteredSubmissions, filteredLabels, filteredOrigins) => {
                            return (
                                <SubmissionDialogWrapper onOpenSubmission={onOpenSubmission}>
                                    {({ openedSubmissionId, setOpenedSubmissionId }) => {
                                        const openedSubmission = filteredSubmissions.find(
                                            (entry) => entry.submission.id === openedSubmissionId
                                        )
                                        return (
                                            <>
                                                <Typo variant="h5" component="h3" mt={2}>
                                                    <AutoAwesomeIcon
                                                        fontSize="small"
                                                        sx={{ mr: 1 }}
                                                    />
                                                    <T i18nKey="company.inspector.productDashboard.aiFilter" />
                                                </Typo>
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    useFlexGap
                                                    flexWrap="wrap"
                                                    justifyContent="flex-start"
                                                    mb={2}
                                                >
                                                    {filteredLabels
                                                        .filter((l) => l.count > 1)
                                                        .map((label) => (
                                                            <Chip
                                                                label={`${label.value} (${
                                                                    label.selectedCount ??
                                                                    label.count
                                                                })`}
                                                                key={label.id}
                                                                disabled={
                                                                    label.selectedCount === 0 ||
                                                                    label.count === 0
                                                                }
                                                                color={
                                                                    label.isSelected
                                                                        ? 'primary'
                                                                        : 'default'
                                                                }
                                                                onClick={() => {
                                                                    setLabelFilter(label)
                                                                }}
                                                            />
                                                        ))}
                                                </Stack>
                                                <Typo variant="h5" component="h3" mt={2}>
                                                    <TravelExploreIcon
                                                        fontSize="small"
                                                        sx={{ mr: 1 }}
                                                    />
                                                    <T i18nKey="company.inspector.productDashboard.originFilter" />
                                                </Typo>
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    useFlexGap
                                                    flexWrap="wrap"
                                                    justifyContent="flex-start"
                                                    mb={2}
                                                >
                                                    {filteredOrigins.map((origin) => (
                                                        <Chip
                                                            label={`${origin.value} (${
                                                                origin.selectedCount ?? origin.count
                                                            })`}
                                                            key={origin.id}
                                                            disabled={
                                                                origin.selectedCount === 0 ||
                                                                origin.count === 0
                                                            }
                                                            color={
                                                                origin.isSelected
                                                                    ? 'primary'
                                                                    : 'default'
                                                            }
                                                            onClick={() => {
                                                                setOriginFilter(origin)
                                                            }}
                                                        />
                                                    ))}
                                                </Stack>
                                                <ImageList cols={maxCols} sx={{ mb: 0 }}>
                                                    {filteredSubmissions
                                                        .sort(sortSubmissions)
                                                        .map((entry) => (
                                                            <InspectionImageTile
                                                                key={entry.submission.id}
                                                                imageUrl={createUploadcareImageSrc(
                                                                    entry.submission.image,
                                                                    {
                                                                        preview: '500x660',
                                                                    }
                                                                )}
                                                                isUnseenByUser={
                                                                    entry.submission.unseenByUser
                                                                }
                                                                labels={entry.submission.labels}
                                                                onClick={() =>
                                                                    setOpenedSubmissionId(
                                                                        entry.submission.id
                                                                    )
                                                                }
                                                                itemBar={
                                                                    <Typo
                                                                        fontWeight={
                                                                            entry.submission
                                                                                .unseenByUser
                                                                                ? 800
                                                                                : 400
                                                                        }
                                                                    >
                                                                        {entry.product.name}
                                                                    </Typo>
                                                                }
                                                                inspectionOrigin={
                                                                    entry.submission
                                                                        .inspectionOrigin
                                                                }
                                                            />
                                                        ))}
                                                </ImageList>
                                                {!!openedSubmissionId && (
                                                    <SubmissionDetail
                                                        submissions={filteredSubmissions.map(
                                                            (entry) => ({
                                                                ...entry.submission,
                                                                productId: entry.product.id,
                                                                productName: entry.product.name,
                                                                productImgUrl:
                                                                    entry.product.productImgUrl,
                                                            })
                                                        )}
                                                        setOpenedSubmissionId={
                                                            setOpenedSubmissionId
                                                        }
                                                        openedSubmissionId={openedSubmissionId}
                                                        close={() =>
                                                            setOpenedSubmissionId(undefined)
                                                        }
                                                        productImgUrl={
                                                            openedSubmission?.product.productImgUrl
                                                        }
                                                        deleteSubmission={async (id) => {
                                                            await deleteSubmission({
                                                                submissionId: id,
                                                            })
                                                            setOpenedSubmissionId(undefined)
                                                        }}
                                                    />
                                                )}
                                            </>
                                        )
                                    }}
                                </SubmissionDialogWrapper>
                            )
                        }}
                    </Filterbar>
                </CardContent>
            </Card>
        </Box>
    )
}

export default Report
