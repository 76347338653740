import React from 'react'
import { SupportMultiStepFormContext } from '../context'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../shared-steps/components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../components/typography/inspector-step-subtitle'
import { useTranslation } from 'react-i18next'
import T from '../../../../../components/typography/t'
import { RepairGuide } from '../../../../../../shared/data/repair-guides/types'
import whitelabels from '../data/whitelabel'
import { Button, styled, Typography, useTheme, Stack, Box } from '@mui/material'
import NotFoundIllustration from './poc-illustrations/not-found-illustration'
import HappyIllustration from './poc-illustrations/happy-illustration'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const BrandLogo = styled('img')`
    max-width: 120px;
    max-height: 60px;
`

const GuideList = styled('ul')`
    gap: ${({ theme }) => theme.spacing(2)};
    display: flex;
    flex-direction: column;
`

const GuideListEntry = styled('li')`
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    display: flex;
    flex-direction: row;
    align-items: stretch;
`

const GuideImage = styled('div')<{ $src: string }>`
    background-size: cover;
    width: 20%;
    background-image: url('${({ $src }) => $src}');
    border-radius: 8px 0 0 8px;
    flex-shrink: 0;
`

const GuideContent = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1em;
    gap: 0.5em;
`

const LabelledDelimiter = styled('div')`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 0;
        top: 50%;
        left: 0;
        border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
        z-index: -1;
    }
`

const DelimiterLabel = styled(T)`
    color: ${({ theme }) => theme.palette.primary.main};
    padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
    background: #fff;
    width: auto;
    text-transform: uppercase;
`

export const DIYStep = ({
    repairGuides,
    brandId,
    storefinderLink,
}: {
    repairGuides: RepairGuide[]
    brandId?: string
    storefinderLink?: string
}) => {
    const [t] = useTranslation()
    const theme = useTheme()
    const [guideRequested, setGuideRequested] = React.useState(false)

    const selectedBrandIds = repairGuides.map((repairGuide) => repairGuide.brandId)
    const selectedWhitelabels = whitelabels.filter((brand) => selectedBrandIds.includes(brand.id))
    const selectedBrands = selectedWhitelabels.map((brand) => ({
        id: brand.id,
        name: brand.name,
        logo: brand.logo,
    }))

    const repairGuidesByBrand = repairGuides.reduce((acc, repairGuide) => {
        const brand = selectedBrands.find((brand) => brand.id === repairGuide.brandId)
        if (brand) {
            if (!acc[brand.id]) {
                acc[brand.id] = {
                    brand: brand,
                    guides: [],
                }
            }
            acc[brand.id].guides.push(repairGuide)
        }
        return acc
    }, {} as { [brandId: string]: { brand: { id: string; name: string; logo: string }; guides: RepairGuide[] } })

    const ownGuides = brandId ? repairGuidesByBrand[brandId] : undefined
    const otherBrandedGuides = Object.keys(repairGuidesByBrand)
        .filter((k) => k !== brandId)
        .map((k) => repairGuidesByBrand[k])
    const guidesFound = repairGuides.length > 0

    return (
        <>
            <InspectorStepTitle
                i18nKey="poc.support.diyStep.title"
                Icon={undefined}
                mb={guidesFound ? 4 : 0}
            />
            {!guidesFound && (
                <InspectorStepSubtitle
                    mb={2}
                    i18nKey={'poc.support.diyStep.nothingFoundSubtitle'}
                />
            )}
            {guidesFound ? (
                <>
                    <Box sx={{ width: '100%' }}>
                        {ownGuides && (
                            <>
                                {otherBrandedGuides.length > 0 && (
                                    <Stack direction="row" gap={2} alignItems="center" mb={2}>
                                        <T
                                            i18nKey="poc.support.diyStep.guideBy"
                                            count={ownGuides.guides.length}
                                            fontSize=".85em"
                                        />
                                        <BrandLogo src={ownGuides.brand.logo}></BrandLogo>
                                    </Stack>
                                )}
                                <GuideList
                                    sx={{
                                        mb: otherBrandedGuides.length > 0 ? 4 : 0,
                                    }}
                                >
                                    {ownGuides.guides.map((repairGuide) => (
                                        <GuideListEntry key={repairGuide.id}>
                                            <GuideImage $src={repairGuide.image} />
                                            <GuideContent>
                                                <Typography>{repairGuide.title}</Typography>
                                                <Button
                                                    component="a"
                                                    variant="outlined"
                                                    sx={{ alignSelf: 'flex-end' }}
                                                    endIcon={<ArrowForwardIcon />}
                                                    href={repairGuide.url}
                                                    target="_blank"
                                                >
                                                    <T i18nKey="poc.support.diyStep.checkoutGuideAction" />
                                                </Button>
                                            </GuideContent>
                                        </GuideListEntry>
                                    ))}
                                </GuideList>
                            </>
                        )}
                        {otherBrandedGuides.map(({ brand, guides }, index) => (
                            <React.Fragment key={brand.id}>
                                <Stack direction="row" gap={1} alignItems="center" mb={2}>
                                    <T
                                        i18nKey="poc.support.diyStep.guideBy"
                                        count={guides.length}
                                        fontSize=".85em"
                                    />
                                    <BrandLogo src={brand.logo}></BrandLogo>
                                </Stack>
                                <GuideList
                                    sx={{
                                        mb: index < otherBrandedGuides.length - 1 ? 4 : 0,
                                    }}
                                >
                                    {guides.map((repairGuide) => (
                                        <GuideListEntry key={repairGuide.id}>
                                            <GuideImage $src={repairGuide.image} />
                                            <GuideContent>
                                                <Typography>{repairGuide.title}</Typography>
                                                <Button
                                                    variant="outlined"
                                                    sx={{ alignSelf: 'flex-end' }}
                                                    endIcon={<ArrowForwardIcon />}
                                                    href={repairGuide.url}
                                                    target="_blank"
                                                >
                                                    <T i18nKey="poc.support.diyStep.checkoutGuideAction" />
                                                </Button>
                                            </GuideContent>
                                        </GuideListEntry>
                                    ))}
                                </GuideList>
                            </React.Fragment>
                        ))}
                    </Box>
                    <LabelledDelimiter sx={{ mt: 4 }}>
                        <DelimiterLabel i18nKey="poc.support.diyStep.notMatching" />
                    </LabelledDelimiter>
                </>
            ) : (
                <>
                    {guideRequested ? (
                        <HappyIllustration theme={theme} />
                    ) : (
                        <NotFoundIllustration theme={theme} />
                    )}
                </>
            )}
            <Stack direction={{ sm: 'row', xs: 'column' }} gap={2} alignItems="center" mt={4}>
                <Button disabled={guideRequested} onClick={() => setGuideRequested(true)}>
                    {guideRequested ? (
                        <T i18nKey="poc.support.diyStep.newGuideActionRequested" />
                    ) : (
                        <T i18nKey="poc.support.diyStep.requestNewGuideAction" />
                    )}
                </Button>
                {storefinderLink && (
                    <Button
                        component="a"
                        href={storefinderLink}
                        variant="contained"
                        target="_blank"
                    >
                        <T i18nKey="poc.support.diyStep.findeRepairCenterAction" />
                    </Button>
                )}
            </Stack>
        </>
    )
}

const DIYStepContext = () => {
    const { ctx, props, form } = React.useContext(SupportMultiStepFormContext)

    const repairGuides = ctx.repairGuides.filter((repairGuide) =>
        form.formValues.aiSelectedRepairGuides.includes(repairGuide.id)
    )

    React.useEffect(() => {
        form.submitForm()
    }, [])

    return (
        <StepFrameworkWrapper
            navigationProps={{
                nextButtonDisabled: true,
                hideNextButton: true,
            }}
        >
            <DIYStep
                repairGuides={repairGuides}
                brandId={ctx.whitelabel?.id}
                storefinderLink={ctx.whitelabel.links.storeFinder}
            />
        </StepFrameworkWrapper>
    )
}

export default DIYStepContext
