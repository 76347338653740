import Alert from '@mui/material/Alert'
import { OverridableComponent, OverridableTypeMap } from '@mui/material/OverridableComponent'
import styled from 'styled-components'
import theme from '../../theme'

const StyledSuccessAlert: OverridableComponent<OverridableTypeMap> = styled(Alert)`
    border: 1px solid ${theme.palette.success.dark}};
`

export default StyledSuccessAlert
