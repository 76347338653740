import React from 'react'
import { UploadClient } from '@uploadcare/upload-client'
import {
    FormValues,
    AlterationsMultiStepFormContext,
    WhitelabelWithAlterationsConfig,
} from './context'
import { MultiStepLayout } from '../../../components/layout'
import { useTranslation } from 'react-i18next'
import ProductSelectionStep from './steps/product-selection'
import AlterationSelectionStep from './steps/alteration-selection'
import SolutionStep from './steps/solution/index'
import OutroStep from './steps/outro/index'
import useSlider, { type Slide } from '../shared/helper/use-slider'
import useMultiStepForm from '../shared/helper/use-multi-step-form'
import useMultiStepError from '../shared/helper/use-multi-step-error'
import { useAnalytics } from 'use-analytics'
import { WarrantyRequestStepChangeEvent } from '../../../../shared/analytics'
import useUrlQuery from '../../../mixins/use-url-query'

const AlterationsMultiStep = ({
    onSubmit,
    uploadCareClient,
    whitelabel,
}: {
    onSubmit: (formValues: FormValues) => Promise<void>
    uploadCareClient: UploadClient
    whitelabel: WhitelabelWithAlterationsConfig
}) => {
    const [getIFrameQueryParam] = useUrlQuery('iframe')
    const isIframe = getIFrameQueryParam() === 'true'
    const slideDefinition: Slide[] = [
        { name: 'ProductSelectionStep', Component: () => <ProductSelectionStep />, skip: false },
        {
            name: 'AlterationSelectionStep',
            Component: () => <AlterationSelectionStep />,
            skip: false,
        },
        { name: 'SolutionStep', Component: () => <SolutionStep />, skip: false },
        { name: 'OutroStep', Component: () => <OutroStep />, skip: false },
    ]

    const analytics = useAnalytics()
    const [t] = useTranslation()
    const [meta, setMeta] = React.useState<any>({})
    const {
        slides,
        slideIndex,
        nextSlide,
        prevSlide,
        setSlide,
        gotoEnd,
        currentStep,
        maxSteps,
        setSlideSkip,
    } = useSlider(slideDefinition, 0)

    const { errors, pushError, popError } = useMultiStepError()
    const formDefaultValues: FormValues = {
        selectedProduct: undefined,
        selectedProductView: 0,
        description: undefined,
        selectedAlterations: [],
        contactFormValues: {},
    }
    const { form, resetForm, submitForm, formValues } = useMultiStepForm<FormValues>(
        formDefaultValues,
        onSubmit
    )

    const productCategoryTree = whitelabel.productCategoryTree

    React.useEffect(() => {
        analytics.track(WarrantyRequestStepChangeEvent, {
            brandId: whitelabel.id,
            slide: slides[slideIndex].name,
        })
    }, [slideIndex])

    return (
        <AlterationsMultiStepFormContext.Provider
            value={{
                ctx: {
                    whitelabel,
                    productCategoryTree,
                    isIframe,
                },
                props: {
                    currentStep,
                    maxSteps,
                    nextSlide,
                    prevSlide,
                    setSlide,
                    gotoEnd,
                    setSlideSkip,
                },
                form: {
                    setValue: form.setValue,
                    formValues,
                    submitForm,
                    resetForm,
                },
                meta: {
                    value: meta,
                    setMetaValue: setMeta,
                },
                error: {
                    errors,
                    pushError,
                    popError,
                },
                uploadCareClient,
            }}
        >
            <MultiStepLayout logo={whitelabel.logo} progress={currentStep / (maxSteps - 1)}>
                {slides[slideIndex].Component()}
            </MultiStepLayout>
        </AlterationsMultiStepFormContext.Provider>
    )
}

export default AlterationsMultiStep
