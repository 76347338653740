import React from 'react'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Badge from '@mui/material/Badge'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ShareIcon from '@mui/icons-material/Share'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew'
import FilterIcon from '@mui/icons-material/FilterAlt'
import FilterOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import InputAdornment from '@mui/material/InputAdornment'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useUrlQuery from '../../../mixins/use-url-query'
import styled from 'styled-components'
import theme from '../../../theme'
import { useIsMobile } from '../../../mixins/media-query'
import RoleProtector from '../../../components/auth/role-protector'
import { Role } from '../../../../shared/auth/role'
import { Category } from '../../../../shared/types/product/category'
import { FilterLabel, Label } from '../../../../shared/types/submission/label'
import {
    OriginFilter,
    CountedOriginFilter,
} from '../../../../shared/types/submission/origin-filter'
import T from '../../../components/typography/t'
import useCopyToClipboard from '../../../mixins/use-copy-to-clipboard'

const FilterBarContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 8px 0 16px;
`

function getTrueFlags(obj: FlagFilterForm) {
    const flags = []
    for (const flag in obj) {
        if (obj[flag]) {
            flags.push(flag)
        }
    }
    return flags
}

type FlagFilterForm = {
    [number: string]: boolean
}

export type FormData = {
    searchTerm: string
    categories: FlagFilterForm
    showOnlyUnseen: boolean
}

type FunctionWithReturnType<P, T> = (p: P) => T

function Filterbar<T>({
    children,
    filterFn,
    categories = [],
    resetLabels = () => {},
    backButtonUrl,
    getLabelsFromEntry,
    getOriginsFromEntry,
    allLabels,
    allOrigins,
    selectedLabelIds = [],
    selectedOriginIds = [],
}: {
    children: (p: T[], labels: FilterLabel[], origins: CountedOriginFilter[]) => React.ReactNode
    filterFn: FunctionWithReturnType<FormData, T[]>
    categories?: Category[]
    resetLabels?: () => void
    backButtonUrl?: string
    getLabelsFromEntry: (entry: T) => Label[]
    getOriginsFromEntry: (entry: T) => OriginFilter[]
    allLabels?: Label[]
    allOrigins?: OriginFilter[]
    selectedLabelIds: number[]
    selectedOriginIds: number[]
}) {
    const copyToClipboard = useCopyToClipboard()
    const isMobile = useIsMobile()
    const [searchQueryFromUrl, setSearchQueryInUrl] = useUrlQuery('q')
    const [filterCategoriesFromUrl, setFilterCategoriesInUrl] = useUrlQuery('categories')
    const parsedFilterCategoryFromUrl = filterCategoriesFromUrl()?.split(',') ?? []

    const categoryFilterDefaultValues = categories
        .map((category) => category.id)
        .reduce((acc: FlagFilterForm, category) => {
            acc[category] = parsedFilterCategoryFromUrl.includes(`${category}`)
            return acc
        }, {})

    const { watch, resetField, control, setValue } = useForm<FormData>({
        defaultValues: {
            searchTerm: searchQueryFromUrl() ?? '',
            categories: categoryFilterDefaultValues,
            showOnlyUnseen: false,
        },
    })

    const toggleShowOnlyUnseen = () => {
        setValue('showOnlyUnseen', !watch('showOnlyUnseen'))
    }

    setSearchQueryInUrl(watch('searchTerm'))

    const [t] = useTranslation()

    const filteredData = filterFn({
        searchTerm: watch('searchTerm'),
        showOnlyUnseen: watch('showOnlyUnseen'),
        categories: watch('categories'),
    })

    const toggleCategoryFilter = (categoryId: number) => {
        if (!watch('categories')[categoryId]) {
            resetLabels()
        }

        setValue('categories', {
            ...watch('categories'),
            [categoryId]: !watch('categories')[categoryId],
        })
        const selectedCategories = getTrueFlags(watch('categories'))
        if (selectedCategories.length > 0) {
            setFilterCategoriesInUrl(selectedCategories.join(','))
        } else {
            setFilterCategoriesInUrl(undefined)
        }
    }

    const allFilteredLabels = filteredData.map(getLabelsFromEntry).flat()
    const allFilteredOrigins = filteredData.map(getOriginsFromEntry).flat()
    const labels = allLabels ?? allFilteredLabels
    const origins = allOrigins ?? allFilteredOrigins
    const filteredLabelsWithCount = allFilteredLabels.reduce(
        (acc: { [index: number]: FilterLabel }, label) => {
            acc[label.id] = {
                ...label,
                count: (acc[label.id]?.count ?? 0) + 1,
                isSelected: !!selectedLabelIds.find((labelId) => labelId === label.id),
            }
            return acc
        },
        {}
    )
    const filteredOriginsWithCount = allFilteredOrigins.reduce(
        (acc: { [index: number]: CountedOriginFilter }, origin) => {
            acc[origin.id] = {
                ...origin,
                count: (acc[origin.id]?.count ?? 0) + 1,
                isSelected: !!selectedOriginIds.find((originId) => originId === origin.id),
            }
            return acc
        },
        {}
    )
    const allLabelsWithCount = labels.reduce((acc: { [index: number]: FilterLabel }, label) => {
        acc[label.id] = {
            ...label,
            count: (acc[label.id]?.count ?? 0) + 1,
            selectedCount: filteredLabelsWithCount[label.id]?.count ?? 0,
            isSelected: !!selectedLabelIds.find((labelId) => labelId === label.id),
        }
        return acc
    }, {})
    const allOriginsWithCount = origins.reduce(
        (acc: { [index: number]: CountedOriginFilter }, origin) => {
            acc[origin.id] = {
                ...origin,
                count: (acc[origin.id]?.count ?? 0) + 1,
                selectedCount: filteredOriginsWithCount[origin.id]?.count ?? 0,
                isSelected: !!selectedOriginIds.find((originId) => originId === origin.id),
            }
            return acc
        },
        {}
    )
    const filteredLabels = Object.values(allLabelsWithCount).sort((a, b) => {
        if (a.count !== b.count) {
            return b.count - a.count
        }
        return a.value.localeCompare(b.value)
    })
    const filteredOrigins = Object.values(allOriginsWithCount).sort((a, b) => {
        if (a.count !== b.count) {
            return b.count - a.count
        }
        return a.value.localeCompare(b.value)
    })

    return (
        <>
            <Stack
                direction="row"
                spacing={1}
                mb={1}
                flexWrap="wrap"
                useFlexGap
                justifyContent="center"
            >
                {categories.map((category) => (
                    <Button
                        key={category.id}
                        variant={watch('categories')[category.id] ? 'contained' : 'outlined'}
                        onClick={() => toggleCategoryFilter(category.id)}
                    >
                        {category.name}
                    </Button>
                ))}
            </Stack>
            <FilterBarContainer>
                {!!backButtonUrl && (
                    <Tooltip title={t('company.inspector.productDashboard.backToOverview' as any)}>
                        <IconButton
                            sx={{ marginRight: theme.spacing(isMobile ? 0.5 : 1) }}
                            href={backButtonUrl}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    </Tooltip>
                )}
                <Controller
                    control={control}
                    name="searchTerm"
                    render={({ field }) => {
                        return (
                            <TextField
                                {...field}
                                fullWidth
                                label={t('company.common.filterbar.search.label' as any)}
                                placeholder={t(
                                    'company.common.filterbar.search.placeholder' as any
                                )}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {!!watch('searchTerm') ? (
                                                <IconButton
                                                    onClick={() => {
                                                        resetField('searchTerm')
                                                    }}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            ) : (
                                                <IconButton disabled>
                                                    <SearchIcon />
                                                </IconButton>
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )
                    }}
                />
                <Badge
                    variant="dot"
                    color="error"
                    badgeContent={watch('showOnlyUnseen') ? 1 : 0}
                    overlap="circular"
                >
                    <Tooltip
                        title={
                            watch('showOnlyUnseen')
                                ? t('company.common.filterbar.unseen.showAll' as any)
                                : t('company.common.filterbar.unseen.showOnlyUnseen' as any)
                        }
                    >
                        <IconButton
                            sx={{ marginLeft: theme.spacing(isMobile ? 0.5 : 1) }}
                            onClick={toggleShowOnlyUnseen}
                        >
                            {watch('showOnlyUnseen') ? <FilterIcon /> : <FilterOutlinedIcon />}
                        </IconButton>
                    </Tooltip>
                </Badge>
                <RoleProtector roles={[Role.Admin]} showInDevEnv>
                    {isMobile ? (
                        <IconButton
                            sx={{ marginLeft: theme.spacing(isMobile ? 0.5 : 1) }}
                            color="primary"
                            onClick={() => copyToClipboard(location.href)}
                        >
                            <ShareIcon />
                        </IconButton>
                    ) : (
                        <Button
                            startIcon={<ShareIcon />}
                            sx={{ flexShrink: 0 }}
                            variant="contained"
                            onClick={() => copyToClipboard(location.href)}
                        >
                            <T i18nKey="company.common.filterbar.share" />
                        </Button>
                    )}
                </RoleProtector>
            </FilterBarContainer>
            {children(filteredData, filteredLabels, filteredOrigins)}
        </>
    )
}

export default Filterbar
