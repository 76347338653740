import { Navigate } from 'react-router-dom'
import { Role } from '../../../../shared/auth/role'
import useAuth from '../../../mixins/use-auth'
import {
    Page,
    Header,
    WidthContainer,
    BrandLogo,
    Content,
    Footer,
    PBB,
    PoweredBy,
    PoweredByBrakeable,
} from '../../../components/layout'
import brkWhitelabel from '../../../../shared/data/whitelabel/brakeable-whitelabel'
import { useQuery, getAnalyticsLog } from 'wasp/client/operations'
import { styled, Stack, Box, Typography, Chip } from '@mui/material'
import React from 'react'
import routeBuilder from '../../../routes'
import {
    RepairRequestPageViewEvent,
    WarrantyRequestPageViewEvent,
} from 'wasp/ext-src/shared/analytics/index'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import LaptopIcon from '@mui/icons-material/Laptop'

const SuggestionContainer = styled(Box)(
    ({ theme }) => `
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(2)};
    border: 1px solid ${theme.palette.primary.main};
    border-radius: ${theme.shape.borderRadius}px;
    background-color: ${theme.palette.background.paper};

    ul {
        list-style: disc;
        margin-bottom: ${theme.spacing(1)};
    }
`
)

const Logo = styled('img')(
    ({ theme }) => `
    width: 100px;
    object-fit: contain;
`
)
const AnalyticsLog = () => {
    const [selectedBrands, setSelectedBrands] = React.useState<string[]>([])
    const { data, error } = useQuery(getAnalyticsLog)

    const allBrands = React.useMemo(
        () =>
            data
                ? Array.from(
                      new Map(
                          data.map((logEntry) => [
                              logEntry.brandId,
                              {
                                  id: logEntry.brandId,
                                  name: logEntry.brandName,
                              },
                          ])
                      ).values()
                  )
                : [],
        [data]
    )

    React.useEffect(() => {
        setSelectedBrands(
            allBrands.map((brand) => brand.id).filter((id) => !['6pzljy', '1iutwg'].includes(id))
        )
    }, [allBrands])

    const eventToTitle = (
        event: typeof RepairRequestPageViewEvent | typeof WarrantyRequestPageViewEvent
    ) => {
        switch (event) {
            case RepairRequestPageViewEvent:
                return 'Repair Pageview'
            case WarrantyRequestPageViewEvent:
                return 'Warranty Pageview'
        }
    }

    if (error) {
        return 'error'
    }
    if (!data) {
        return 'loading'
    }

    return (
        <Page>
            <Header>
                <WidthContainer $wide sx={{ justifyContent: 'space-between' }}>
                    <a href={routeBuilder.base()}>
                        <BrandLogo src={brkWhitelabel.logo} alt={brkWhitelabel.name} />
                    </a>
                </WidthContainer>
            </Header>
            <Content sx={{ justifyContent: 'center' }}>
                <WidthContainer>
                    <Stack direction="row" gap={1} my={2} flexWrap="wrap">
                        {allBrands.map((brand) => {
                            const isSelected = selectedBrands.includes(brand.id)
                            return (
                                <Chip
                                    key={brand.id}
                                    label={brand.name}
                                    onClick={() =>
                                        isSelected
                                            ? setSelectedBrands(
                                                  selectedBrands.filter((id) => id !== brand.id)
                                              )
                                            : setSelectedBrands([...selectedBrands, brand.id])
                                    }
                                    color={isSelected ? 'primary' : 'default'}
                                />
                            )
                        })}
                    </Stack>
                    <Stack gap={2}>
                        {data
                            .filter((logEntry) => {
                                if (selectedBrands.length === 0) return true
                                return selectedBrands.includes(logEntry.brandId)
                            })
                            .map((logEntry) => (
                                <SuggestionContainer key={logEntry.id}>
                                    <Stack
                                        direction="row"
                                        gap={2}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Stack direction="row" gap={2} alignItems="center">
                                            <Logo src={logEntry.logoUrl} alt={logEntry.brandId} />
                                            <Typography variant="h5" component="h2">
                                                {eventToTitle(logEntry.event)}
                                            </Typography>
                                            {logEntry.isMobile ? (
                                                <SmartphoneIcon />
                                            ) : (
                                                <LaptopIcon />
                                            )}
                                        </Stack>
                                    </Stack>
                                    <Stack gap={1}>
                                        <Stack direction="row" gap={1}>
                                            <Typography fontWeight="bold" component="h3">
                                                Timestamp:
                                            </Typography>
                                            <Typography component="span">
                                                {logEntry.displayDate}
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" gap={1} alignItems="center">
                                            <Typography fontWeight="bold" component="h3">
                                                UserId:
                                            </Typography>
                                            <Typography component="span">
                                                {logEntry.anonymousUserId}
                                            </Typography>
                                            {logEntry.isFirstOccurrence && (
                                                <Chip label="New" color="primary" size="small" />
                                            )}
                                        </Stack>
                                    </Stack>
                                </SuggestionContainer>
                            ))}
                    </Stack>
                </WidthContainer>
            </Content>
            <Footer>
                <WidthContainer $wide sx={{ justifyContent: 'flex-end' }}>
                    <PBB>
                        <PoweredBy>Powered by</PoweredBy>{' '}
                        <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                    </PBB>
                </WidthContainer>
            </Footer>
        </Page>
    )
}

const RoleSwitch = () => {
    const { user } = useAuth()
    switch (user?.role) {
        case Role.Admin:
            return <AnalyticsLog />
        default:
            return <Navigate to="/" />
    }
}

export default RoleSwitch
